import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { FocusableElementsUtil } from 'ngx/go-modules/src/utilities';

/**
 * Description: goMonitorTabEvent will watch the tab events inside the container element
 * and it will emit an event when the tab focus exited the container.
 *
 * `onTabbedOut` when tabbing forward and `onShiftTabbedOut` when tabbing backward.
 */
@Directive({
	selector: '[goMonitorTabEvent]'
})
export class GoMonitorTabEventDirective {
	@Output()
	public onTabbedOut = new EventEmitter<KeyboardEvent>();

	@Output()
	public onShiftTabbedOut = new EventEmitter<KeyboardEvent>();

	constructor (private elementRef: ElementRef) {
	}

	@HostListener('keydown', ['$event'])
	public onKeyDown ($event: KeyboardEvent) {
		if($event.key === 'Tab') {
			if ($event.shiftKey) {
				if (FocusableElementsUtil.getFirstFocusableElement(this.elementRef.nativeElement) ===
					document.activeElement) {
					this.onShiftTabbedOut.emit($event);
				}
			} else {
				if (FocusableElementsUtil.getLastFocusableElement(this.elementRef.nativeElement) ===
					document.activeElement) {
					this.onTabbedOut.emit($event);
				}
			}
		}
	}
}
