import * as angular from 'angular';

/* @ngInject */
export function attachmentServiceTransformer ($http, MediaModel) {

	/**
	 * Transform response data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformResponseData (data) {

		if (data.hasOwnProperty('media')) {
			data.media = MediaModel.model(data.media);
		}

		return data;
	}

	/**
	 * Transform response
	 *
	 * @param response
	 * @returns {*}
	 */
	function transformResponse (response) {
		if (angular.isArray(response)) {
			angular.forEach(response, function (data) {
				transformResponseData(data);
			});
		} else {
			transformResponseData(response);
		}

		return response;
	}

	return {
		response: [$http.defaults.transformResponse[0], transformResponse]
	};
}
