import * as angular from 'angular';
import { IHttpService } from 'angular';
import { DataTransformer } from '../common/data-transformer';

export class RubricTemplateDataTransformerService extends DataTransformer {

	/* @ngInject */
	constructor ($http: IHttpService) {
		super($http);
	}

	/**
	 * Transform request data
	 */
	protected transformRequestData (data: any): any {
		// Make a copy of the payload so that the `clean` method below doesn't remove
		// the $$hashKey property from each element in the original elements array.
		// Otherwise, angularjs's ng-repeat directive will generate a new hash key
		// for those items and cause all of the dom elements to be recreated.
		const payload = angular.copy(data);
		return this.clean(payload);
	}

	/**
	 * Transform response data
	 */
	protected transformResponseData (data: any): any {
		// Convert total to numeric type
		data.total = isNaN(parseFloat(data.total)) ? null : parseFloat(data.total);
		return this.clean(data);
	}

	/**
	 * Clean rubric session data
	 */
	private clean (rubricTemplate: any): any {
		if (!Array.isArray(rubricTemplate.elements)) {
			rubricTemplate.elements = [];
		}

		// clean the array
		for (let i = 0; i < rubricTemplate.elements.length; i++) {
			if (!rubricTemplate.elements[i]) {
				rubricTemplate.elements.splice(i, 1);
				i--;
			}
		}

		rubricTemplate.elements.forEach((element) => {
			if (element.hasOwnProperty('points')) {
				element.points = null;
			}
			if (element.hasOwnProperty('option')) {
				element.option = null;
			}
			if (element.hasOwnProperty('$$hashKey')) {
				// TODO: Should find the parent that's using $$hashkey and track on id instead
				// eslint-disable-next-line angular/no-private-call
				delete element.$$hashKey;
			}
			if (element.hasOwnProperty('options')) {
				element.options.forEach((option) => {
					if (option.hasOwnProperty('$$hashKey')) {
						// eslint-disable-next-line angular/no-private-call
						delete option.$$hashKey;
					}
				});
			}
		});

		return rubricTemplate;
	}
}
