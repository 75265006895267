/* @ngInject */
export class UmcInstancesService {
	public instances: any = [];
	public file: any;

	/* @ngInject */
	constructor (
		private confirmModal,
		private $q
	) {
	}

	//register instance
	public registerMedia (media, type) {
		if (this.instances.indexOf(media) === -1) {
			media.resource_type = type;
			this.instances.push(media);
		}
	};

	//unregister instance
	public unregisterMedia (media) {
		const index = this.instances.indexOf(media);
		this.instances.splice(index, 1);
	};

	public setMediaSpokenFor (media) {
		const toSet = this.instances.find((instance) => instance.media_id === media.media_id);
		if (toSet) {
			toSet.isSpokenFor = true;
		}
	}

	public isMediaSpokenFor () {
		return this.instances.find((instance) => instance.isSpokenFor && instance.resource_type !== 'session');
	}

	public cleanupOrphanedMedia () {
		this.instances.map((media) => {
			if (!media.isSpokenFor && media.resource_type !== 'comment') {
				media.$delete();
			}
		});
	}

	//register file
	public registerFile (file) {
		this.file = file;
	};

	public canChangeTab () {
		const deferred = this.$q.defer();
		const mediaSpokenFor = this.isMediaSpokenFor();
		if (this.file?.hasOwnProperty('$uploading')) {
			this.confirmModal.open({
				size: 'sm',
				modalData: {
					title: 'common_warning',
					message: 'modal-umc_switch-tabs-uploading',
					yes: 'common_ok',
					no: 'common_cancel'
				}
			}).result.then(() => {
				this.file.$cancel();
				deferred.resolve();
			}).catch(() => {
				deferred.reject();
			});
		} else if (mediaSpokenFor) {
			this.confirmModal.open({
				size: 'sm',
				modalData: {
					title: 'common_warning',
					message: 'modal-umc_switch-tabs-recording',
					yes: 'common_ok',
					no: 'common_cancel'
				}
			}).result.then(() => {
				mediaSpokenFor.$delete();
				deferred.resolve();
			}).catch(() => {
				deferred.reject();
			});
		} else {
			deferred.resolve();
		}
		return deferred.promise;
	}
};
