import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as angularAnimate from 'angular-animate';
import * as FullStory from '@fullstory/browser';

import { detectModule } from '../detect';
import { goBannerModule } from '../go-banner';
import { allSettledModule } from '../all-settled';
import { announcementModelModule } from '../models/announcement';
import { animateProviderConfig } from './animate.provider.config';
import { unhandledRejectionConfig } from './unhandled-rejection.config';
import { goOfflineModeModule } from '../go-offline-mode';
import { FetchInterceptor } from './fetch-interceptor';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { featureFlagModule } from 'go-modules/feature-flag';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { updateManagerModule } from 'go-modules/update-manager';
import { UpdateManagerService } from 'go-modules/update-manager/update-manager.service';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';

const getAbsoluteUrl = (input: RequestInfo | URL): string => {
	if (input instanceof URL) {
		return input.toString();
	}
	return new URL(input instanceof Request ? input.url : input, document.baseURI).toString();
};

// Method for adding header for inteceptors
const interceptForFeatureFlags = <I extends RequestInfo | URL, C extends RequestInit | ng.IRequestConfig>(
	featureFlag: FeatureFlag, input: I, config?: C
): [I, C] => {
	const url = getAbsoluteUrl(input);

	if (!url.startsWith(getAbsoluteUrl(clientSettings.GoReactV2API)) &&
		!url.startsWith(getAbsoluteUrl(clientSettings.GoReactV1API))) {
		return [input, config];
	}


	const flags = featureFlag.getSynchronizedFlags();
	if (flags.length > 0) {
		config.headers = config.headers ?? {};
		config.headers['Goreact-Active-Feature-Flags'] = flags.join(',');
	}
	return [input, config];
};

// Url lives outside the fetch request object, so if there's a url, it has to be ng1
const isNgRequest = (config: RequestInit | ng.IRequestConfig): config is ng.IRequestConfig => {
	return 'url' in config;
};

// Method for adding withCredentials for interceptors
// Make sure that we are passing cookies back and forth:
//     The XMLHttpRequest.withCredentials property is a boolean value that indicates
//     whether or not cross-site Access-Control requests should be made using credentials
//     such as cookies, authorization headers or TLS client certificates.
// See https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
const interceptForWithCredentials = <I extends RequestInfo | URL, C extends RequestInit | ng.IRequestConfig>(
	input: I, config?: C
): [I, C] => {
	const url = getAbsoluteUrl(input);

	if (!url.startsWith(getAbsoluteUrl(clientSettings.GoReactV2API)) &&
		!url.startsWith(getAbsoluteUrl(clientSettings.GoReactV1API))) {
		return [input, config];
	}

	if (isNgRequest(config)) {
		config.withCredentials = config.withCredentials ?? true;
	} else {
		config.credentials = config.credentials ?? 'include';
	}

	return [input, config];
};

// Define a regular expression for URL validation
const sanitizationTrustedUrlRegex = new RegExp(
	'^\s*' +                                                                      // Anchor against the entire string, but allow any empty space before.
	'https?:\\/\\/' +                                                             // scheme
	'(?![^\\\\\\/?#]*@)' +                                                        // userInfo disallowed
	'(' +
		'((?:(?:[^\\\\\\/?#\\.]+?)\\.)*goreact\\.(?:com|eu)(?::[0-9]+)?)' + '|' + // goreact domain + port
		'((?:([a-zA-Z]+)\\.)*youtu(?:\\.be|be\\.com))' + '|' +                    // youtube domain
		'((?:(?:[^\\\\\\/?#\\.]+?)\\.)*zoom\\.(?:com|us))' +                      // zoom domain
	')' +
	'(?=[\\\\\\/?#]|$)'                                                           // authority-terminating character.
);

// This module exists for things we want to run
// on start up of goReact apps. For now this
// means LTI and Dashboard
export const appRunModule = angular.module('go.app-run', [
	goOfflineModeModule,
	detectModule,
	goBannerModule,
	angularTranslate,
	updateManagerModule,
	allSettledModule,
	announcementModelModule,
	angularAnimate,
	featureFlagModule,
	goLocalizeHelperModule
])
	.config(animateProviderConfig)
	.config(unhandledRejectionConfig)

	/** @ngInject */
	.config(($httpProvider: ng.IHttpProvider) => {
		// Setup angular.js interceptor

		/** @ngInject */
		$httpProvider.interceptors.push((featureFlag: FeatureFlag) => {
			return {
				request: (config: ng.IRequestConfig) => {
					let [, newConfig] = interceptForFeatureFlags(featureFlag, config.url, config);
					[, newConfig] = interceptForWithCredentials(config.url, newConfig);
					return newConfig;
				}
			};
		});
	})
	.config(($compileProvider: ng.ICompileProvider) => {
		$compileProvider.imgSrcSanitizationTrustedUrlList(sanitizationTrustedUrlRegex);
		$compileProvider.aHrefSanitizationTrustedUrlList(sanitizationTrustedUrlRegex);
	})
	.config(
		function ($sceDelegateProvider: ng.ISCEDelegateProvider) {
			$sceDelegateProvider.resourceUrlWhitelist([
				'self',
				clientSettings.GoReactV2API + '/**'
			]);
		}
	)
	.run((featureFlag: FeatureFlag, GoVersionManager: UpdateManagerService) => {
		// Initing FullStory outside of the class so non angular parts of the code
		// may continue using FullStory
		FullStory.init({ orgId: clientSettings.FullstoryOrgId });

		// Setup fetch interceptor
		FetchInterceptor.install();
		FetchInterceptor.register({request: interceptForFeatureFlags.bind(null, featureFlag)});
		FetchInterceptor.register({request: interceptForWithCredentials});

		GoVersionManager.init();
	})
	.name;
