/**
 * Convert size to bytes
 *
 * @param integer size  Number of units to convert
 * @param integer from  Units to convert from
 * @return int
 */
export function toBytesFilter () {
	return function (size, from) {
		const kilobyte = 1024;
		const megabyte = kilobyte * 1024;
		const gigabyte = megabyte * 1024;
		const terabyte = gigabyte * 1024;

		switch (from.toUpperCase()) {
			case 'TB':
				return size * terabyte;
			case 'GB':
				return size * gigabyte;
			case 'MB':
				return size * megabyte;
			case 'KB':
				return size * kilobyte;
			default:
				return size;
		}
	};
}
