import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { TranscriptionService } from 'ngx/go-modules/src/services/transcription/transcription.service';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'edit-transcription-speakers-dialog',
	template: require('./edit-transcription-speakers-dialog.component.html'),
	styles: [require('./edit-transcription-speakers-dialog.component.scss')]
})
export class EditTranscriptionSpeakersDialogComponent implements OnInit {
	public form: FormGroup = new FormGroup({});
	public submitting$ = new BehaviorSubject(false);

	constructor (
		public dialogRef: GoDialogRef,
		private ngxGoToastService: NgxGoToastService,
		private transcriptionService: TranscriptionService,
		@Inject(GO_MODAL_DATA) public data: {
			transcriptionId: number;
			speakers: string[];
		}
	) { }

	public ngOnInit (): void {
		this.data.speakers.forEach((speaker) => {
			this.form.addControl(speaker, new FormControl(speaker, [Validators.required, Validators.maxLength(25)]));
		});
	}

	public cancel (): void {
		this.dialogRef.close(false);
	}

	public submit (): void {
		if (this.form.pristine) {
			return this.dialogRef.close(false);
		}
		if (this.form.valid) {
			this.submitting$.next(true);
			const updatedSpeakers = Object.keys(this.form.controls).map((key) => {
				const control = this.form.controls[key];
				const oldValue = this.data.speakers.find((speaker) => speaker === key);
				const newValue = control.dirty ? control.value : null;
				return { old: oldValue, new: newValue };
			}).filter((speaker) => speaker.new !== null);

			this.transcriptionService.updateSpeakers(
				this.data.transcriptionId,
				{speakers: updatedSpeakers}
			).subscribe({
				next: () => {
					this.submitting$.next(false);
					this.dialogRef.close(true);
				},
				error: () => {
					this.submitting$.next(false);
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: 'edit-transcription-speakers_error'
					});
				}
			});
		}
	}
}
