import { VolumeIndicatorHelper } from './volume-indicator.helper';

export interface Bindings {
	audioContext: AudioContext;
	mediaStream: MediaStream;
	active: boolean;
}

export class VolumeIndicatorController implements Bindings, ng.IOnChanges {
	public audioContext: AudioContext;
	public mediaStream: MediaStream;
	public active: boolean;
	public numberOfCells: number;
	public noAudioClass = 'grey';

	private audioSourceNode: MediaStreamAudioSourceNode;
	private gainNode: GainNode;
	private gainLevel: number = 0;
	private volumeIndicatorHelper: VolumeIndicatorHelper;
	private processor: AudioWorkletNode;

	/* @ngInject */
	constructor (
		private $q: ng.IQService
	) {}

	public $onInit (): void {
		this.numberOfCells = 25;
		this.volumeIndicatorHelper = new VolumeIndicatorHelper();
		this.gainLevel = this.gainLevel ?? 0;
	}

	public $onChanges (changes: ng.IOnChangesObject) {
		if (changes.audioContext?.isFirstChange() && changes.mediaStream?.isFirstChange()) {
			return;
		}

		this.cleanup();

		if (!this.active) {
			document
				.querySelectorAll('div.volume-cell')
				.forEach((element) => element.classList.add(this.noAudioClass));
			return;
		}

		if (
			this.audioContext &&
			this.mediaStream &&
			this.mediaStream.getAudioTracks().length
		) {
			let promise = this.$q.resolve();

			try {
				this.processor = new AudioWorkletNode(this.audioContext, 'audio-processor');
			} catch (err) {
				promise = this.$q.when(
					this.audioContext.audioWorklet.addModule(
						URL.createObjectURL(new Blob([this.volumeIndicatorHelper.generateAudioWorkletProcessor()], {type: 'application/javascript'}))
					)
				).then(() => {
					this.processor = new AudioWorkletNode(this.audioContext, 'audio-processor');
				});
			}
			promise.then(() => {
				this.gainNode = this.audioContext.createGain();
				this.audioSourceNode = this.audioContext.createMediaStreamSource(this.mediaStream);
				this.audioSourceNode.connect(this.gainNode);
				this.audioSourceNode.connect(this.processor);
				this.processor.connect(this.audioContext.destination);
				this.processor.port.onmessage = this.onAudioProcessHandler.bind(this);

				this.gainLevel = this.gainNode.gain.value;
			});
		}
	}

	public $onDestroy (): void {
		this.cleanup();
	}

	private cleanup (): void {
		// Ensure all existing audio nodes are disconnected
		if (this.processor) {
			this.processor.disconnect();
		}
		if (this.audioSourceNode) {
			this.audioSourceNode.disconnect();
		}
		if (this.gainNode) {
			this.gainNode.disconnect();
		}
	}

	private onAudioProcessHandler (event): void {
		if (!this.active) return;
		const inputPercentLevel = event.data.volume;
		const trueInputLevel = inputPercentLevel * this.gainLevel;
		const blackIndexStop = Math.floor(
			(1 - trueInputLevel) * this.numberOfCells
		);

		try {
			document
				.querySelectorAll('div.volume-cell')
				.forEach((element) => element.classList.remove(this.noAudioClass));
			document
				.querySelectorAll(
					`div.volume-cell:nth-last-child(-n+${blackIndexStop})`
				)
				.forEach((element) => element.classList.add(this.noAudioClass));
		} catch (error) {
			// Do Nothing
		}
	}
}
