import { Action } from './action';

export enum IconClass {
	PREVIEW = 'ficon-app-preview',
	EDIT = 'ficon-app-edit',
	REMOVE = 'ficon-times'
}

export interface ActionIconClassMap {
	[action: string]: IconClass;
}

export const ActionToIconClassMap: ActionIconClassMap = {
	[Action.PREVIEW]: IconClass.PREVIEW,
	[Action.EDIT]: IconClass.EDIT,
	[Action.REMOVE]: IconClass.REMOVE
};
