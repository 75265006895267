import { UserActivityHistoryItemRendererController } from './item-renderer.controller';

export const userActivityHistoryItemRenderer = function () {
	return {
		restrict: 'E',
		require: '^userActivityHistory',
		template: '<ng-include src="controller.getTemplateUrl()"/>',
		controller: UserActivityHistoryItemRendererController,
		controllerAs: 'controller',
		link: (scope, _element, _attrs, controller) => {
			scope.$watch(function () {
				return controller.expanded;
			}, function (value) {
				scope.controller.toggleExpand(value);
			});
		},
		scope: {
			item: '='
		},
		bindToController: true
	};
};
