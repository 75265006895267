import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import { activityModel } from '../../models/activity';

import { startTestActivityModal } from './modal.factory';
import { detectModule } from 'go-modules/detect';
import './style.less';

export const startTestActivityModule = angular.module('modal.start-test-activity', [
	angularTranslate,
	goModalBootstrapModule,
	activityModel,
	detectModule
])
	.service(startTestActivityModal.NG1_INJECTION_NAME, startTestActivityModal)
	.name;
