import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import { featureFlagModule } from 'go-modules/feature-flag';
import { AuthService } from './auth.service';

export const authServiceModule = angular.module('AuthServiceModule', [
	featureFlagModule,
	uiRouter
])
	.service('authService', AuthService)
	.name;
