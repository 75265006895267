import * as angular from 'angular';
import { RubricTemplateDataTransformerService } from './rubric-template-transformer.service';
import { clientSettings } from '../common/client.settings';

/* @ngInject */
export function RubricTemplateModel (
	$resource,
	$filter,
	rubricTemplateDataTransformerService: RubricTemplateDataTransformerService
) {
	const baseUrl = `${clientSettings.GoReactV2API}/rubric_templates`;
	const resourceUrl = `${baseUrl}/:id`;

	const rubricTemplateModel = $resource(resourceUrl, {
		id: '@id',
		group_id: '@group_id',
		org_id: '@org_id'
	}, {
		get: {
			method: 'GET',
			responseType: 'json',
			transformResponse: rubricTemplateDataTransformerService.responseTransformers
		},
		save: {
			method: 'POST',
			responseType: 'json',
			transformRequest: rubricTemplateDataTransformerService.requestTransformers,
			transformResponse: rubricTemplateDataTransformerService.responseTransformers
		},
		update: {
			method: 'PUT',
			responseType: 'json',
			transformRequest: rubricTemplateDataTransformerService.requestTransformers,
			transformResponse: rubricTemplateDataTransformerService.responseTransformers
		},
		checkUsage: {
			url: resourceUrl + '/usage',
			method: 'GET',
			responseType: 'json'
		},
		downloadReport: {
			url: resourceUrl + '/orgs/rubric_report?org_id:=:org_id&timezone=:timezone',
			method: 'GET'
		},
		copy: {
			url: resourceUrl + '/copy',
			method: 'POST',
			responseType: 'json',
			transformResponse: rubricTemplateDataTransformerService.responseTransformers
		}
	});

	// constants
	rubricTemplateModel.ElementType = {
		NUMERIC: 'numeric',
		QUALITATIVE: 'qualitative',
		CHECKBOX: 'checkbox',
		POINTS: 'points'
	};

	// properties
	rubricTemplateModel.prototype.kind = 'rubric';
	rubricTemplateModel.prototype.saving = false;

	/**
	 * Get rubric template id
	 *
	 * @returns {int}
	 */
	rubricTemplateModel.prototype.getId = function () {
		return this.id;
	};

	/**
	 * Set whether rubric template is saving
	 *
	 * @returns {boolean}
	 */
	rubricTemplateModel.prototype.setSaving = function (value) {
		this.saving = !!value;
	};

	/**
	 * Whether rubric template is saving
	 *
	 * @returns {boolean}
	 */
	rubricTemplateModel.prototype.isSaving = function () {
		return this.saving;
	};

	/**
	 * Whether rubric template set is saved
	 *
	 * @returns {boolean}
	 */
	rubricTemplateModel.prototype.isSaved = function () {
		return this.getId() > 0;
	};

	/**
	 * Set rubric template total
	 */
	rubricTemplateModel.prototype.setTotal = function (value) {
		const result = parseFloat(value && value.toString()); // remove trailing zeros
		this.total = isNaN(result) ? null : result;
	};

	/**
	 * Add item
	 *
	 * @param item
	 * @param index
	 * @param duplicate
	 */
	rubricTemplateModel.prototype.addItem = function (item, index, duplicate) {
		const copy = angular.copy(item);
		if (duplicate) {
			// Because checkboxes currently use the label field as their title we have to
			// special case them to avoid data issues while saving cloned rubrics.
			if (copy.type === 'checkbox') {
				copy.label = $filter('translate')('common_copy-of', {title: copy.label});
			} else {
				copy.title = $filter('translate')('common_copy-of', {title: copy.title});
			}
		}
		copy.id = new Date().getTime();
		if (angular.isUndefined(index)) {
			this.elements.push(copy);
		} else {
			this.elements.splice(index, 0, copy);
		}
		// In the case of modifying categories/breaks, we need to update the breaks
		if (item.type === 'category' || item.type === 'break') {
			this.updateBreaks();
		}
	};

	/**
	 * Move item
	 *
	 * @param newIndex
	 * @param item
	 */
	rubricTemplateModel.prototype.moveItem = function (item, newIndex) {
		const oldIndex = this.elements.indexOf(item);
		if (oldIndex < 0) {
			throw new Error('rubricTemplate.moveItem: Item not found in schema.elements array');
		} else if (newIndex < 0) {
			throw new Error('rubricTemplate.moveItem: "newIndex" must be greater than 0');
		}

		this.elements.splice(oldIndex, 1);
		this.elements.splice(newIndex, 0, item);
		// In the case of modifying categories/breaks, we need to update the breaks
		if (item.type === 'category' || item.type === 'break') {
			this.updateBreaks();
		}
	};

	/**
	 * Remove item
	 *
	 * @param item
	 */
	rubricTemplateModel.prototype.removeItem = function (item) {
		const index = this.elements.indexOf(item);
		if (index !== -1) {
			this.elements.splice(index, 1);

			// In the case of modifying categories/breaks, we need to update the breaks
			if (item.type === 'category' || item.type === 'break') {
				this.updateBreaks();
			}
		}
	};

	/**
	 * Duplicate item
	 *
	 * @param item
	 */
	rubricTemplateModel.prototype.duplicateItem = function (item) {
		const index = this.elements.indexOf(item);
		if (index !== -1) {
			// copy down
			this.addItem(item, index + 1, true);

			// In the case of modifying categories/breaks, we need to update the breaks
			if (item.type === 'category' || item.type === 'break') {
				this.updateBreaks();
			}
		}

	};

	/**
	 * Rollback rubric to last version
	 *
	 * @param template
	 */
	rubricTemplateModel.prototype.rollback = function (template) {
		angular.extend(this, template);
	};

	function filterElementsInsideCategory (elements, category) {
		let inCategory = false;
		return elements.filter((element) => {
			// We found a category or break
			if (element.type === 'category' || element.type === 'break') {
				// We are in the category if it matches
				inCategory = element === category;
				// But don't include the category itself
				return false;
			}
			return inCategory;
		});
	}

	/**
	 * Calculate total
	 *
	 * Not all elements have point values,
	 * so this function may return null.
	 *
	 * @param category
	 * @param numDecimals
	 * @returns {number|null}
	 */
	rubricTemplateModel.prototype.calculateTotalPoints = function (category, numDecimals) {
		let total = null;
		let elements = this.elements;
		numDecimals = numDecimals > 0 ? numDecimals : 2;

		// Scale type elements
		const scaleElements = [
			rubricTemplateModel.ElementType.NUMERIC,
			rubricTemplateModel.ElementType.QUALITATIVE
		];

		// Filter elements by category if given
		if (category) {
			elements = filterElementsInsideCategory(elements, category);
		}

		angular.forEach(elements, function (element) {
			if (scaleElements.indexOf(element.type) >= 0 || element.type === '') {
				let max = null;
				angular.forEach(element.options, function (option) {
					if (option.value > max) {
						max = Number(option.value);
					}
				});

				if (max > 0) {
					total = add(total, max);
				}
			} else if (element.type === rubricTemplateModel.ElementType.CHECKBOX) {
				if (element.value > 0) {
					total = add(total, element.value);
				}
			} else if (element.type === rubricTemplateModel.ElementType.POINTS) {
				if (element.total > 0) {
					total = add(total, element.total);
				}
			}
		});

		if (total < 0) { // bounds check
			total = 0;
		}

		if (typeof total === 'number') {
			total = parseFloat(total.toFixed(numDecimals));
		}

		return total;
	};

	/**
	 * Calculate total points earned
	 *
	 * Not all elements have point values,
	 * so this function may return null.
	 *
	 * @param category
	 * @param numDecimals
	 * @returns {number}
	 */
	rubricTemplateModel.prototype.calculatePointsEarned = function (category, numDecimals) {
		let total = null,
			elements = this.elements;

		numDecimals = numDecimals > 0 ? numDecimals : 2;

		// filter elements by category id if given
		if (category) {
			elements = filterElementsInsideCategory(elements, category);
		}

		// Sum points together
		angular.forEach(elements, function (element) {
			total = add(total, element.points);
		});

		if (typeof total === 'number') {
			total = parseFloat(total.toFixed(numDecimals));
		}

		return total;
	};

	/**
	 * Set element values from session data
	 *
	 * @param sessionData
	 */
	rubricTemplateModel.prototype.setValues = function (sessionData) {
		const tmpCache = {};
		angular.forEach(this.elements, function (item) {
			if (item) {
				tmpCache[item.id] = item;
			}
		});

		angular.forEach(sessionData, function (item) {
			if (item) {
				// check if item exists in cache before trying to extend
				if (tmpCache[item.id]) {
					angular.extend(tmpCache[item.id], item);
				}
			}
		});
	};

	/**
	 * Because the rubric template doesn't support nested
	 * elements, we have to treat this flat structure as
	 * if it supported nesting.
	 */
	rubricTemplateModel.prototype.validate = function () {
		this.updateBreaks();
	};

	/**
	 * Simple add function
	 *
	 * @param value1
	 * @param value2
	 * @returns {number|null}
	 */
	function add (...args) {
		let result = null,
			values = Array.prototype.slice.call(args);

		if (values.length < 2) {
			throw Error('Add function requires that at least two values are passed');
		}

		// Ensure all values are numbers
		values = values.map(function (value) {
			return parseFloat(value);
		});

		// Remove all non-numbers
		values = values.filter(function (value) {
			return !isNaN(value);
		});

		// Sum values
		angular.forEach(values, function (val) {
			result += val;
		});

		return result;
	}

	/**
	 * Update break elements
	 */
	rubricTemplateModel.prototype.updateBreaks = function () {
		let currentCategory = null;

		// Loop through and ensure all breaks are consistent
		for (let i = 0; i < this.elements.length; i++) {
			const element = this.elements[i];
			if (element.type === 'category') {
				if (currentCategory == null) {
					currentCategory = element;
				} else {
					// We are encountering a different category without a break inbetween
					// Add a break at the current index, and move the index up
					this.elements.splice(i++, 0, {
						type: 'break'
					});
				}
			} else if (element.type === 'break') {
				if (currentCategory != null) {
					currentCategory = null;
				} else {
					// We are encountering a break, even though we aren't in a category
					// This can happen if we move the category. Just remove the break
					this.elements.splice(i--, 1);
				}
			}
		}

		// We didn't end with a break, so add it now
		if (currentCategory != null) {
			this.elements.push({
				type: 'break'
			});
		}
	};

	rubricTemplateModel.prototype.getCategory = function (element) {
		if (element.type === 'category' || element.type === 'break') {
			return null;
		}
		let index = this.elements.indexOf(element);
		while (--index >= 0) {
			const prevElement = this.elements[index];
			if (prevElement.type === 'category') {
				return prevElement;
			} else if (prevElement.type === 'break') {
				return null;
			}
		}
		return null;
	};

	return rubricTemplateModel;
}
