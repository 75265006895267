import template from './multi-media-display.component.html';
import { MultiMediaDisplayController as controller } from './multi-media-display.controller';

export const multiMediaDisplay = {
	template,
	require: {
		mainController: '^feedbackSession',
		mediaDisplayController: '^mediaDisplay',
		contentController: '^feedbackSessionContent'
	},
	controller,
	bindings: {
		media: '<?',
		sourceMedia: '<'
	}
};
