import * as angular from 'angular';
import { YoutubeProcessor } from './youtube.processor';
import { MediaSource } from './';
import type { MediaFactory, MediaResource } from './';
import { clientSettings } from '../common/client.settings';
import type { StreamingConnectionParameters } from './media-streaming';
import type { OpenTokSessionFactory, OpenTokSessionModel } from '../opentok-session';
import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export interface DownloadData {
	link: string;
	filename: string;
}

/* @ngInject */
export function MediaModel (
	$resource,
	mediaServiceTransformer,
	MediaVTT,
	OpenTokSession: OpenTokSessionFactory,
	$injector,
	UADetect: UADetectClass,
	mediaModelInterceptor,
	messageModal
): MediaFactory {
	const baseUrl = `${clientSettings.GoReactV1API}/media`;

	const mediaModel = $resource(baseUrl + '/index/:media_id', {
		media_id: '@media_id',
		group_id: '@group_id'
	}, {
		save: {
			method: 'POST',
			responseType: 'json',
			transformRequest: mediaServiceTransformer.request,
			transformResponse: mediaServiceTransformer.response,
			interceptor: mediaModelInterceptor
		},
		delete: {
			method: 'DELETE',
			responseType: 'json',
			url: `${clientSettings.GoReactV2API}/media/:media_id`
		},
		getDownloadData: {
			url: `${clientSettings.GoReactV2API}/media/:media_id/download`,
			method: 'GET'
		},
		swapMedia: {
			url: `${clientSettings.GoReactV2API}/media/:media_id/swap`,
			method: 'PUT'
		}
	});

	// media status
	mediaModel.PENDING = 'pending';
	mediaModel.READY = 'ready';
	mediaModel.FAIL = 'fail';

	// media formats
	mediaModel.FORMAT_FLV = 'flv';
	mediaModel.FORMAT_MP4 = 'mp4';

	mediaModel.TYPE = {
		DOCUMENT: 'document',
		VIDEO: 'video',
		AUDIO: 'audio',
		IMAGE: 'image'
	};

	// comes from encoding.com
	mediaModel.AUDIO_EXTS = [
		'm4a', 'mp3', 'flac', 'ram', 'mp2'
	];
	mediaModel.VIDEO_EXTS = [
		'mp4', 'm4v', 'wav', 'mov',
		'flv', 'wmv', 'f4v', 'asf',
		'mpg', 'avi', 'webm', 'ogg',
		'ogv', 'r3d', 'rm', 'qt'
	];
	mediaModel.DOCUMENT_EXTS = [
		'pdf', 'doc', 'docx', 'odt', 'txt'
	];
	mediaModel.IMAGE_EXTS = [
		'jpeg', 'jpg', 'gif', 'png', 'bmp', 'svg'
	];
	mediaModel.PREVIEWABLE_DOC_EXTS = ['pdf', 'jpeg', 'jpg', 'gif', 'png', 'bmp', 'svg'];
	mediaModel.PREVIEWABLE = mediaModel.PREVIEWABLE_DOC_EXTS
		.concat(mediaModel.VIDEO_EXTS)
		.concat(mediaModel.AUDIO_EXTS);

	// Files that can be playable on the browser
	mediaModel.ACCEPTABLE_FOR_DOWNLOAD_EXTS = ['.m4a', '.m4v', '.mp4'];

	/**
	 * Converts an object to a query string
	 */
	mediaModel.toQueryString = (object: any): string => {
		return Object.keys(object)
			.reduce((a, k) => {
				a.push(`${k}=${encodeURIComponent(object[k])}`);
				return a;
			}, [])
			.join('&');
	};

	/**
	 * Transform media vtt caption into media
	 *
	 * @param mediaVtt
	 * @returns {MediaModel}
	 */
	mediaModel.transformMediaVttCaptionToMedia = (mediaVtt) => {
		return mediaModel.model({
			media_id: mediaVtt.media_id,
			filename: mediaVtt.file,
			title: 'Captions',
			media_type: mediaModel.TYPE.DOCUMENT,
			media_url: mediaVtt.media_url,
			media_status: mediaVtt.getId() ? mediaModel.READY : mediaModel.PENDING
		});
	};

	/**
	 * Determine if download link contains a file name with one of the given extensions
	 *
	 * @param link
	 */
	mediaModel.isMediaPlayableOnClient = function (link) {
		if (link) {
			const strippedQueryParams = link.split('?')[1];
			const queryParams = new URLSearchParams(strippedQueryParams);
			const fileName = queryParams.get('fileName');

			return fileName ? this.ACCEPTABLE_FOR_DOWNLOAD_EXTS.some((fileType) => fileName.includes(fileType)) : false;
		}

		return false;
	};

	mediaModel.download = (mediaId) => {
		mediaModel.getDownloadData({ media_id: mediaId })
			.$promise
			.then((downloadData: DownloadData) => {
				const element = document.createElement('a');
				element.setAttribute('href', downloadData.link);
				element.setAttribute('download', downloadData.filename);

				// Target to _self for mobile safari (_blank is not supported) - this is to fix the media player from
				// breaking when download button is clicked AND having the download itself to work just fine
				if (UADetect.browserDetector.isMobileSafari()) {
					element.setAttribute('target', '_self');
				} else {
					element.setAttribute('target', '_blank');
				}

				element.style.display = 'none';
				document.body.appendChild(element);

				element.click();
				element.remove();
			}).catch(() => {
				messageModal.open({
					modalData: {
						title: 'modal-message_download-failed-title',
						message: 'modal-message_download-failed-message',
						resolveBtnText: 'common_close',
						resolveBtnClass: 'primary-btn'
					}
				});
			});
	};

	mediaModel.swap = (mediaId) => {
		return mediaModel.swapMedia({ media_id: mediaId }).$promise;
	};

	/**
	 * Creates a new media instance
	 *
	 * @param data
	 * @returns {MediaModel}
	 */
	mediaModel.model = (data) => {
		return new mediaModel(mediaServiceTransformer.response[1](data || {}));
	};

	/**
	 * Given a source, create the media
	 */
	mediaModel.createFor = async (source: MediaSource, groupId: number, mediaType?: string): Promise<any> => {
		const media = mediaModel.model({
			group_id: groupId,
			media_status: mediaModel.PENDING,
			media_type: mediaType || mediaModel.TYPE.VIDEO,
			media_url: null,
			source
		});
		await media.$save();

		if (media.source === MediaSource.OPENTOK || media.source === MediaSource.MEDIARECORDER) {
			await OpenTokSession.create({
				media_id: media.media_id
			}).$promise;
		}

		return media;
	};

	// properties
	mediaModel.prototype.kind = 'media';
	mediaModel.prototype.saving = false;

	/**
	 * Get media id
	 */
	mediaModel.prototype.getId = function () {
		return this.media_id;
	};

	/**
	 * Set whether media is saving
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.setSaving = function (value) {
		this.saving = !!value;
	};

	/**
	 * Whether media is saving
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isSaving = function () {
		return this.saving;
	};

	/**
	 * Whether media is saved
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isSaved = function () {
		return this.getId() > 0;
	};

	/**
	 * Whether media is encoded
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isReady = function () {
		return this.media_status === mediaModel.READY;
	};

	/**
	 * Whether media is being encoded
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isPending = function () {
		return this.media_status === mediaModel.PENDING;
	};

	/**
	 * Whether media failed to encode
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isFailed = function () {
		return this.media_status === mediaModel.FAIL;
	};

	/**
	 * Whether media was the result of an upload
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isUpload = function () {
		return this.media_upload_id > 0;
	};

	/**
	 * Whether filename has an mp4 extension
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isMp4 = function () {
		return this.filename ? this.filename.indexOf('.mp4') >= 0 : false;
	};

	/**
	 * Returns the file name extension
	 *
	 * @returns {string}
	 */
	mediaModel.prototype.getFileExtension = function () {
		return angular.isString(this.filename) ?
			this.filename.substr(this.filename.lastIndexOf('.') + 1).toLowerCase() : '';
	};

	/**
	 * Set media duration
	 *
	 * @param value
	 */
	mediaModel.prototype.setDuration = function (value) {
		this.duration = parseInt(value, 10);
	};

	/**
	 * Whether media is a video
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isVideo = function () {
		return this.media_type === mediaModel.TYPE.VIDEO;
	};

	/**
	 * Whether media is audio
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isAudio = function () {
		return this.media_type === mediaModel.TYPE.AUDIO;
	};

	/**
	 * Whether media is a document
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isDocument = function () {
		return this.media_type === mediaModel.TYPE.DOCUMENT;
	};

	mediaModel.prototype.isPreviewableDocument = function () {
		return this.filename != null && mediaModel.PREVIEWABLE_DOC_EXTS.indexOf(this.filename.split('.').pop().toLowerCase()) !== -1;
	};

	/**
	 * Whether media has audio track
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.hasAudio = function () {
		return this.isAudio() || this.isVideo();
	};

	mediaModel.prototype.isYoutube = function () {
		return YoutubeProcessor.isYoutubeUrl(this.media_url);
	};

	mediaModel.isValidYoutubeUrl = YoutubeProcessor.isYoutubeUrl;

	mediaModel.makeYoutubeThumbnail = YoutubeProcessor.makeYoutubeThumbnailUrl;

	mediaModel.prototype.canZoom = function () {
		if (!this.isReady() || this.media_type !== mediaModel.TYPE.DOCUMENT || !this.filename) {
			return false;
		}

		return mediaModel.PREVIEWABLE_DOC_EXTS.indexOf(this.filename.split('.').pop().toLowerCase()) !== -1;
	};

	/**
	 * Whether media is previewable
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.isPreviewable = function () {
		if (!this.isReady()) {
			return false;
		}

		if (this.media_type === mediaModel.TYPE.DOCUMENT && this.filename) {
			return mediaModel.PREVIEWABLE_DOC_EXTS.indexOf(this.filename.split('.').pop().toLowerCase()) !== -1;
		}

		if (this.media_type === mediaModel.TYPE.VIDEO || this.media_type === mediaModel.TYPE.AUDIO) {
			return true;
		}

		return false;
	};

	/**
	 * Whether closed captions are available
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.hasMediaVTTCaptions = function () {
		return this.getMediaVTTCaptions().length > 0;
	};

	/**
	 * Returns a list of media vtt captions
	 *
	 * @returns {Array}
	 */
	mediaModel.prototype.getMediaVTTCaptions = function () {
		if (!angular.isArray(this.media_vtt)) {
			return [];
		}
		return this.media_vtt.filter((mediaVTT) => {
			return mediaVTT.isCaption();
		});
	};

	/**
	 * Add media vtt
	 *
	 * @param mediaVTT
	 */
	mediaModel.prototype.addMediaVTT = function (mediaVTT) {
		if (!(mediaVTT instanceof MediaVTT)) {
			throw new Error('Parameter `mediaVTT` must be an instance of `MediaVTT`');
		} else if (!angular.isArray(this.media_vtt)) {
			this.media_vtt = [];
		}
		this.media_vtt.push(mediaVTT);
	};

	/**
	 * Removes a given media vtt
	 *
	 * @param mediaVTT
	 * @returns {number}
	 */
	mediaModel.prototype.removeMediaVTT = function (mediaVTT) {
		let index = -1;
		if (!(mediaVTT instanceof MediaVTT)) {
			throw new Error('Parameter `mediaVTT` must be an instance of `MediaVTT`');
		} else if (angular.isArray(this.media_vtt)) {
			index = this.media_vtt.indexOf(mediaVTT);
			if (index !== -1) {
				this.media_vtt.splice(index, 1);
			}
		}
		return index;
	};

	/**
	 * Deletes a given supplementary media
	 *
	 * @param mediaVTT
	 * @returns {Promise}
	 */
	mediaModel.prototype.deleteMediaVTT = function (mediaVTT) {
		const vm = this;
		if (!(mediaVTT instanceof MediaVTT)) {
			throw new Error('Parameter `mediaVTT` must be an instance of `MediaVTT`');
		}
		return mediaVTT.$delete()
			.then(() => {
				vm.removeMediaVTT(mediaVTT);
			});
	};

	/**
	 * Whether supplementary audio is available
	 *
	 * @returns {boolean}
	 */
	mediaModel.prototype.hasSupplementaryAudio = function () {
		return this.getSupplementaryAudio().filter((media) => {
			return media.hasAudio();
		}).length > 0;
	};

	/**
	 * Returns a list of supplementary audio media
	 *
	 * @returns {Array}
	 */
	mediaModel.prototype.getSupplementaryAudio = function () {
		if (!angular.isArray(this.supplementary_media)) {
			return [];
		}
		return this.supplementary_media.filter((suppMedia) => {
			return suppMedia.hasAudio();
		});
	};

	/**
	 * Add supplementary media
	 *
	 * @param supplementaryMedia
	 */
	mediaModel.prototype.addSupplementaryMedia = function (supplementaryMedia) {
		const SupplementaryMedia = $injector.get('SupplementaryMedia');
		if (!(supplementaryMedia instanceof SupplementaryMedia)) {
			throw new Error('Parameter `supplementaryMedia` must be an instance of `SupplementaryMedia`');
		} else if (!angular.isArray(this.supplementary_media)) {
			this.supplementary_media = [];
		}
		this.supplementary_media.push(supplementaryMedia);
	};

	/**
	 * Removes a given supplementary media
	 *
	 * @param supplementaryMedia
	 * @returns {number}
	 */
	mediaModel.prototype.removeSupplementaryMedia = function (supplementaryMedia) {
		let index = -1;
		const SupplementaryMedia = $injector.get('SupplementaryMedia');
		if (!(supplementaryMedia instanceof SupplementaryMedia)) {
			throw new Error('Parameter `supplementaryMedia` must be an instance of `SupplementaryMedia`');
		} else if (angular.isArray(this.supplementary_media)) {
			index = this.supplementary_media.indexOf(supplementaryMedia);
			if (index !== -1) {
				this.supplementary_media.splice(index, 1);
			}
		}
		return index;
	};

	/**
	 * Deletes a given supplementary media
	 *
	 * @param supplementaryMedia
	 * @returns {Promise}
	 */
	mediaModel.prototype.deleteSupplementaryMedia = function (supplementaryMedia) {
		const vm = this;
		const SupplementaryMedia = $injector.get('SupplementaryMedia');
		if (!(supplementaryMedia instanceof SupplementaryMedia)) {
			throw new Error('Parameter `supplementaryMedia` must be an instance of `SupplementaryMedia`');
		}
		return supplementaryMedia.$delete()
			.then(() => {
				vm.removeSupplementaryMedia(supplementaryMedia);
			});
	};

	/**
	 * Connection info for streaming that changes depending on what media source is used
	 */
	mediaModel.prototype.getStreamingConnectionInfo = function (): ng.IPromise<any> {
		switch (this.source) {
			case MediaSource.MEDIARECORDER:
			case MediaSource.OPENTOK:
				return OpenTokSession.create({
					media_id: this.getId()
				}).$promise
					.then((openTokSession: OpenTokSessionModel) => {
						return {
							session_id: openTokSession.id,
							api_key: openTokSession.token.api_key,
							token: openTokSession.token.value,
							hasUsedScreenCapture: openTokSession.hasUsedScreenCapture
						};
					});
			default:
				throw new Error(`Media source '${this.source}' is not supported!`);
		}
	};

	/**
	 * Build the required streaming connection parameters
	 */
	mediaModel.prototype.buildStreamingConnectionParams =
		function (groupId: number, userIp: string, resource?: MediaResource): StreamingConnectionParameters {

			const params: StreamingConnectionParameters = {
				group_id: groupId,
				media_id: this.media_id,
				user_id: this.created_by,
				user_ip: userIp
			};

			if (resource) {
				params.resource_id = resource.id;
				params.resource_type = resource.type;
			}

			return params;
		};

	return mediaModel;
}

MediaModel.NG1_INJECTION_NAME = 'MediaModel' as const;
export const mediaToken = upgradeNg1Dependency(MediaModel);
