import { Component, Input } from '@angular/core';

/**
 * A downgraded NGX wrapper for the goCopyToClipboard directive
 * NOTE: Do not use this wrapper if in NGX; use the directive directly instead
 */
@Component({
	selector: 'copy-to-clipboard-wrapper',
	template: `
		<section [goCopyToClipboard]="text" style="display: inline-flex; height: 100%;">
			<ng-content></ng-content>
		</section>
	`
})
export class CopyToClipboardWrapperComponent {
	@Input() public text?: string | null;
}
