import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { common } from '../common';
import { feedbackNode } from '../feedback-node';
import { base } from '../base';
import { mediaModel } from '../media';
import { tagModel } from '../tag';
import { syncEventModel } from '../sync-event';

import { CommentModel } from './comment.factory';
import { COMMENT_RESOURCE_TYPES } from './comment.constant';
import { commentModelTransformer } from './comment-transformer.service';
import { commentInterceptorService } from './comment-interceptor.service';
import { commentModelSetOwnerService } from './comment-set-owner.service';

export const commentModel = angular.module('go.models.comment', [
	angularResource,
	common,
	feedbackNode,
	base,
	mediaModel,
	tagModel,
	syncEventModel
])
	.service('commentModelSetOwnerService', commentModelSetOwnerService)
	.service('commentInterceptorService', commentInterceptorService)
	.service('commentModelTransformer', commentModelTransformer)
	.constant('CommentResourceTypes', COMMENT_RESOURCE_TYPES)
	.factory(CommentModel.NG1_INJECTION_NAME, CommentModel)
	.name;
