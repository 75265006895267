import { clientSettings } from 'go-modules/models/common/client.settings';
import { BraintreeTransformer } from './braintree.transformer';
import * as angular from 'angular';
import { create, Dropin, PaymentMethodPayload } from 'braintree-web-drop-in';
import { Address } from 'go-modules/payment-panel/address-details-form/address-details-form.controller';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export interface BraintreeToken {
	token: string;
}

export interface CardPurchasePayload {
	nonce: string;
	user_id: number;
	subtotal: string;
	tax_amount?: string;
	total: string;
	transactionProducts: TransactionProduct[];
	address?: Address;
}

export interface TransactionProduct {
	line_subtotal: string;
	line_tax_amount?: string;
	product_id: number;
	qty?: number;
}

export interface BraintreePaymentError {
	group_id: number;
	message: string;
	user_id: number;
	result: {
		[key: string]: string;
	};
}

export interface PaymentValidationError {
	errors: {
		[key: string]: string[];
	};
	message: string;
}

export interface TransactionStatus {
	startAt: string;
}

export interface Transaction {
	trans_id: number;
	user_id: number;
	group_id: number;
	billing_entity_id: number;
	subtotal: string;
	total: string;
	tax_amount: string;
	method: string;
	payment_type: string;
	memo: string;
	ref_id: string;
	related_trans_id: number;
	created_by: number;
	deleted_by: number;
	created_at: Date;
	modified_at: Date;
	deleted_at: Date;
}

/* @ngInject */
export class BraintreeService {
	public static readonly NG1_INJECTION_NAME = 'BraintreeService';
	constructor (private $http: ng.IHttpService, private $q: ng.IQService) {}

	public generateClientToken (): ng.IPromise<BraintreeToken> {
		return this.$http.post(`${clientSettings.GoReactV2API}/users/self/braintree_token`, null)
			.then((response: ng.IHttpResponse<BraintreeToken>) => {
				return response.data;
			});
	}

	public cardPurchase (groupId: number, purchase: CardPurchasePayload): ng.IPromise<Transaction> {
		const appendTransform = (defaults, transform) => {
			// We can't guarantee that the default transformation is an array
			defaults = angular.isArray(defaults) ? defaults : [defaults];
			// Append the new transformation to the defaults
			return defaults.concat(transform);
		};

		return this.$http({
			url: `${clientSettings.GoReactV2API}/groups/${groupId}/payment/card`,
			method: 'POST',
			data: purchase,
			transformResponse: appendTransform(this.$http.defaults.transformResponse, BraintreeTransformer.response)
		}).then((response: ng.IHttpResponse<Transaction>) => {
			return response.data;
		});
	}

	public codePurchase (groupId: number, code: string): ng.IPromise<Transaction> {
		return this.$http.post(`${clientSettings.GoReactV2API}/groups/${groupId}/payment/code`, {code}).then((response: ng.IHttpResponse<Transaction>) => {
			return response.data;
		});
	}

	public createBraintreeForm (options): ng.IPromise<Dropin> {
		return this.$q.when(create(options));
	}

	public requestPaymentMethod (dropinInstance: Dropin): ng.IPromise<PaymentMethodPayload> {
		return this.$q.when(dropinInstance.requestPaymentMethod());
	}

	public getPaymentTransactionStatus (groupId: number): ng.IPromise<TransactionStatus> {
		return this.$http.get(`${clientSettings.GoReactV2API}/groups/${groupId}/payment/transaction/status`)
			.then((response: ng.IHttpResponse<TransactionStatus>) => {
				return response.data;
			});
	}
}

export const braintreeServiceToken = upgradeNg1Dependency(BraintreeService);
