import * as angular from 'angular';

/* @ngInject */
export function uiDecimalDirective ($parse) {
	const zeroDecimalRegex = /^[-]?[0-9]*$/;

	const getFractionalRegex = function getFractionalRegex (fractional) {
		return new RegExp('^[-]?[0-9]*[.]?[0-9]{0,' + fractional + '}$');
	};

	return {
		require: 'ngModel',
		link (scope, _element, attr, modelController) {

			const reset = function reset (val) {
				modelController.$setViewValue(val);
				modelController.$render();
			};

			const isNumeric = function isNumeric (val) {
				return !isNaN(parseFloat(val)) && isFinite(parseFloat(val));
			};

			modelController.$parsers.push(function (value) {
				let fractional = $parse(attr.uiDecimal)(scope);
				fractional = angular.isDefined(fractional) && fractional !== '' ? parseInt(fractional, 10) : 2;
				if (value) {
					let regex;
					if (fractional === 0) {
						regex = zeroDecimalRegex;
					} else {
						regex = getFractionalRegex(fractional);
					}

					if (!regex.test(value)) {
						const newVal = value.slice(0, -1);
						reset(newVal);
						return isNumeric(newVal) ? Number(newVal) : null;
					}

					return isNumeric(value) ? Number(value) : null;
				} else {
					return value;
				}

			});
		}
	};
}
