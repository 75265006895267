/* @ngInject */
import { clientSettings } from '../common/client.settings';

/** @ngInject */
export function mediaModelInterceptorService () {
	return {
		request: (config: ng.IRequestConfig) => {
			const parts = config.url.split('/');
			const last = parts[parts.length - 1];

			if (last === 'index') {
				config.url = `${clientSettings.GoReactV2API}/groups/${config.params.group_id}/media`;
				config.method = 'POST';
			}

			if (last !== 'index') {
				config.url = `${clientSettings.GoReactV2API}/media/${last}`;
				config.method = 'PUT';
			}

			return config;
		}
	} as ng.IHttpInterceptor;
}
