import * as angular from 'angular';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { clientSettings } from '../common/client.settings';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { PaginationFilter } from 'ngx/go-modules/src/enums/score-filter';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { TranscriptionService } from 'ngx/go-modules/src/services/transcription/transcription.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY } from 'rxjs';

/* @ngInject */
export function Session (
	$resource: ng.resource.IResourceService,
	modelConfig,
	sessionModelInterceptor,
	sessionModelTransformer,
	MediaModel,
	$q: ng.IQService,
	cacheManager,
	$http: ng.IHttpService,
	SessionConstants,
	CommentModel,
	SyncEvent,
	ngxGoToastService: NgxGoToastService,
	eventService: EventService,
	$window,
	featureFlag: FeatureFlag,
	selectedService: SelectedService,
	transcriptionService: TranscriptionService

) {
	const baseUrl = modelConfig.defaults.serviceBaseUrl + '/sessions',
		sessionCache = cacheManager('session'),

		defaultTransformer = $http.defaults.transformResponse[0],

		SessionModel = $resource<any, any>(baseUrl, {
			session_id: '@session_id'
		}, {
			get: {
				url: `${clientSettings.GoReactV2API}/submissions/:session_id`,
				method: 'GET',
				isArray: false,
				interceptor: sessionModelInterceptor,
				transformResponse: [defaultTransformer, sessionModelTransformer.response]
			},
			getExternalSession: {
				url: `${clientSettings.GoReactV2API}/sessions/external/:uuid`,
				method: 'GET',
				isArray: false
			},
			queryByGroup: {
				isArray: true,
				url: `${clientSettings.GoReactV2API}/groups/:group_id/sessions`,
				method: 'GET',
				interceptor: sessionModelInterceptor,
				transformResponse: [defaultTransformer, sessionModelTransformer.response]
			},
			getSummaryReport: {
				url: `${clientSettings.GoReactV2API}/groups/:group_id/activity-report`,
				method: 'GET',
				isArray: true
			},
			getForensics: {
				url: `${clientSettings.GoReactV2API}/forensics.examine?sessionId=:session_id`,
				method: 'GET',
				isArray: false
			},
			markAsViewed: {
				url: `${clientSettings.GoReactV2API}/submissions/:session_id/views`,
				method: 'POST',
				isArray: false
			},
			markAsUnviewed: {
				url: `${clientSettings.GoReactV2API}/submissions/:session_id/views`,
				method: 'DELETE',
				isArray: false
			},
			saveCommentViews: {
				url: `${clientSettings.GoReactV2API}/sessions/:session_id/comment-views`,
				method: 'POST'
			},
			getStats: {
				url: `${clientSettings.GoReactV2API}/submissions/:session_id/stats`,
				method: 'GET'
			},
			sharePublic: {
				url: `${clientSettings.GoReactV2API}/sessions/:session_id/share/public`,
				method: 'POST'
			},
			sharePrivate: {
				url: `${clientSettings.GoReactV2API}/sessions/:session_id/share/private`,
				method: 'POST'
			},
			restoreGuestReviewer: {
				url: `${clientSettings.GoReactV2API}/sessions/:session_id/external-user/:guest_id/restore`,
				method: 'POST'
			},
			expirePublicLinkAccess: {
				url: `${clientSettings.GoReactV2API}/sessions/:session_id/share/public/expire`,
				method: 'PUT'
			},
			deleteGuestReviewer: {
				url: `${clientSettings.GoReactV2API}/sessions/:session_id/external-user/:guest_id`,
				method: 'DELETE'
			}
		});

	const gradeResourceUrl = `${clientSettings.GoReactV2API}/submissions/:submissionId/grade`;
	const GradeResource = $resource(gradeResourceUrl, {
		submissionId: '@submissionId'
	});

	// Constants
	SessionModel.CANCELLED = SessionConstants.STATUS.CANCELLED;
	SessionModel.WAITING = SessionConstants.STATUS.WAITING;
	SessionModel.LIVE = SessionConstants.STATUS.LIVE;
	SessionModel.RECORDED = SessionConstants.STATUS.RECORDED;
	SessionModel.MUTLI_CAMERA_MAX_PRESENTER_COUNT = SessionConstants.MUTLI_CAMERA_MAX_PRESENTER_COUNT;

	SessionModel.RESOURCE_TYPE = 'session';
	SessionModel.SLIDES_RESOURCE_TYPE = 'slides';

	SessionModel.FILTER_RECORDING = PaginationFilter.RECORDING;
	SessionModel.FILTER_USER = PaginationFilter.USER;
	SessionModel.FILTER_PEER = PaginationFilter.PEER;
	SessionModel.FILTER_UNGRADED = PaginationFilter.UNGRADED;
	SessionModel.FILTER_GRADED = PaginationFilter.GRADED;
	SessionModel.FILTER_ARCHIVED = PaginationFilter.ARCHIVED;
	SessionModel.FILTER_SEARCH = PaginationFilter.SEARCH;

	SessionModel.pagination = {
		recordings: {},
		user: {},
		peer: {},
		ungraded: {},
		graded: {},
		archived: {},
		search: {}
	};

	SessionModel.activeFilter = SessionModel.FILTER_RECORDING;
	SessionModel.list = [];

	SessionModel.clear = () => {
		SessionModel.list = [];
		Object.keys(SessionModel.pagination).forEach((key) => {
			SessionModel.pagination[key] = {};
		});
	};

	SessionModel.getPaginationByFilter = (filter) => {
		if (SessionModel.pagination.hasOwnProperty(filter)) {
			return SessionModel.pagination[filter];
		}
		return false;
	};

	SessionModel.setActivePaginationFilter = (filter) => {
		SessionModel.activeFilter = filter;
		$window.sessionStorage.setItem('currentFilter', filter);
		eventService.broadcast(EVENT_NAMES.SESSION_ACTIVE_PAGINATION_FILTER, filter);
	};

	/**
	 * We store current filter into session storage
	 * so when user reloaded the page in the feedback view
	 * we will be able to know what is the current filter before clicked the session
	 * or else it will display all recordings
	 *
	 * NOTE (routes.run.ts): currentFilter will be removed when user reloaded in any page except in feedback view
	 */
	SessionModel.getActivePaginationFilter = () => {
		const currentFilter = $window.sessionStorage.getItem('currentFilter');
		if (currentFilter) SessionModel.activeFilter = currentFilter;

		return SessionModel.activeFilter;
	};

	SessionModel.model = function model (session) {
		return sessionModelInterceptor.response({
			resource: new SessionModel(sessionModelTransformer.response(session || {}))
		});
	};

	const transformSessions = (data) => {
		const sessions = sessionModelTransformer.response(data);
		return sessions.map(SessionModel.model);
	};

	const handleSessions = (result, filter) => {
		let sessions = result;
		sessions = transformSessions(result.data);
		// add to masterlist
		sessions.map((session) => {
			if (SessionModel.list.indexOf(session) === -1) {
				SessionModel.list.push(session);
			}
		});
		// add pagination data
		SessionModel.pagination[filter] = {meta: result.meta, links: result.links};
		return sessions;
	};

	SessionModel.getRecordings = (activityId, plainTextQuery, pageQuery, sortQuery) => {
		const path = `${clientSettings.GoReactV2API}/activities/${activityId}/submissions`;
		const queryParams = new URLSearchParams();
		let filter = SessionModel.FILTER_RECORDING;

		if (plainTextQuery) {
			queryParams.append('query', plainTextQuery);
			filter = SessionModel.FILTER_SEARCH;
		}
		if (pageQuery) {
			queryParams.append('page', pageQuery);
		}
		if (sortQuery) {
			queryParams.append('sort_by', sortQuery);
		}
		const query = (queryParams.toString()) ? `?${queryParams}` : '';
		return $http.get(`${path}${query}`)
			.then(({data}) => handleSessions(data, filter));
	};

	/**
	 * Specifically get recordings without managing the master list. This handles the usages for accesing
	 * sessions of an unselected activity.
	 */
	SessionModel.getRecordingsByActivity = (activityId) => {
		return $http.get(`${clientSettings.GoReactV2API}/activities/${activityId}/submissions`).
			then(({data}: any) => {
				let list: any = [];
				list = data.data;
				return transformSessions(list);
			});
	};

	SessionModel.getByUser = (activityId, userIdToInclude, plainTextQuery, pageQuery, sortQuery) => {
		const path = `${clientSettings.GoReactV2API}/activities/${activityId}/submissions`;
		const queryParams = new URLSearchParams({
			filter_by_user_id: userIdToInclude
		});
		let filter = SessionModel.FILTER_USER;

		if (plainTextQuery) {
			queryParams.append('query', plainTextQuery);
			filter = SessionModel.FILTER_SEARCH;
		}
		if (pageQuery) {
			queryParams.append('page', pageQuery);
		}
		if (sortQuery) {
			queryParams.append('sort_by', sortQuery);
		}
		const query = `?${queryParams}`;
		return $http.get(`${path}${query}`)
			.then(({data}) => handleSessions(data, filter));
	};

	SessionModel.getPeers = (activityId, userIdToExclude, plainTextQuery, pageQuery, sortQuery) => {
		const path = `${clientSettings.GoReactV2API}/activities/${activityId}/submissions`;
		const queryParams = new URLSearchParams({
			filter_by_user_id: userIdToExclude,
			exclude_by_user_id: 'true'
		});
		let filter = SessionModel.FILTER_PEER;

		if (plainTextQuery) {
			queryParams.append('query', plainTextQuery);
			filter = SessionModel.FILTER_SEARCH;
		}
		if (pageQuery) {
			queryParams.append('page', pageQuery);
		}
		if (sortQuery) {
			queryParams.append('sort_by', sortQuery);
		}
		const query = `?${queryParams}`;
		return $http.get(`${path}${query}`)
			.then(({data}) => handleSessions(data, filter));
	};

	SessionModel.getArchived = (activityId, filterByUserId, plainTextQuery, pageQuery, sortQuery) => {
		const path = `${clientSettings.GoReactV2API}/activities/${activityId}/submissions`;

		const queryParams = new URLSearchParams({
			only_archived: 'true'
		});

		if (filterByUserId) {
			queryParams.append('filter_by_user_id', filterByUserId);
		}

		let filter = SessionModel.FILTER_ARCHIVED;

		if (plainTextQuery) {
			queryParams.append('query', plainTextQuery);
			filter = SessionModel.FILTER_SEARCH;
		}
		if (pageQuery) {
			queryParams.append('page', pageQuery);
		}
		if (sortQuery) {
			queryParams.append('sort_by', sortQuery);
		}
		const query = `?${queryParams}`;
		return $http.get(`${path}${query}`)
			.then(({data}) => handleSessions(data, filter));
	};

	SessionModel.getUngraded = (activityId, filterByUserId, plainTextQuery, pageQuery, sortQuery) => {
		const path = `${clientSettings.GoReactV2API}/activities/${activityId}/submissions`;
		const queryParams = new URLSearchParams({
			graded: 'false'
		});

		if (filterByUserId) {
			queryParams.append('filter_by_user_id', filterByUserId);
		}

		let filter = SessionModel.FILTER_UNGRADED;

		if (plainTextQuery) {
			queryParams.append('query', plainTextQuery);
			filter = SessionModel.FILTER_SEARCH;
		}
		if (pageQuery) {
			queryParams.append('page', pageQuery);
		}
		if (sortQuery) {
			queryParams.append('sort_by', sortQuery);
		}
		const query = `?${queryParams}`;
		return $http.get(`${path}${query}`)
			.then(({data}) => handleSessions(data, filter));
	};

	SessionModel.getGraded = (activityId, filterByUserId, plainTextQuery, pageQuery, sortQuery) => {
		const path = `${clientSettings.GoReactV2API}/activities/${activityId}/submissions`;
		const queryParams = new URLSearchParams({
			graded: 'true'
		});

		if (filterByUserId) {
			queryParams.append('filter_by_user_id', filterByUserId);
		}

		let filter = SessionModel.FILTER_GRADED;

		if (plainTextQuery) {
			queryParams.append('query', plainTextQuery);
			filter = SessionModel.FILTER_SEARCH;
		}
		if (pageQuery) {
			queryParams.append('page', pageQuery);
		}
		if (sortQuery) {
			queryParams.append('sort_by', sortQuery);
		}
		const query = `?${queryParams}`;
		return $http.get(`${path}${query}`)
			.then(({data}) => handleSessions(data, filter));
	};

	SessionModel.find = function find (sessionId) {
		const session = sessionCache.get(parseInt(sessionId, 10));

		if (session && session.isDeepLoaded()) {
			return $q.when(session);
		}

		return SessionModel.get({
			session_id: sessionId
		}).$promise;
	};

	SessionModel.getReadableStatus = (session) => {
		switch (session.status) {
			case SessionModel.LIVE:
				return 'models-session_factory-in-progress';
			case SessionModel.RECORDED:
				return 'models-session_factory-recorded';
			case SessionModel.CANCELLED:
				return 'models-session_factory-discarded';
			case SessionModel.WAITING:
				return 'models-session_factory-awaiting-start';
			default:
				return '';
		}
	};

	SessionModel.prototype.archive = function archive () {
		return $http.patch(`${clientSettings.GoReactV2API}/sessions/${this.getId()}/archive`, this)
			.then(function () {
				this.archived_at = new Date();
			}.bind(this));
	};

	SessionModel.prototype.deletePermanently = function deletePermanently () {
		return $http.delete(clientSettings.GoReactV2API + '/sessions/' + this.session_id);
	};

	SessionModel.prototype.setViewedRecordingInstructions = function setViewedRecordingInstructions () {
		this.viewed_recording_instructions_at = new Date();
		return $http.post(`${clientSettings.GoReactV2API}/submissions/${this.session_id}/viewed_instructions`, this);
	};

	SessionModel.prototype.updateStatus = function updateStatus (status) {
		// set the status
		this.setStatus(status);

		// save new status
		return $http.post(`${clientSettings.GoReactV2API}/submissions/${this.session_id}/status`, {
			status: this.status
		});
	};

	SessionModel.prototype.unarchive = function unarchive () {
		this.archived_at = null;
		return $http.patch(
			`${clientSettings.GoReactV2API}/sessions/${this.session_id}/unarchive`,
			this
		);
	};

	SessionModel.prototype.post = function post (postData = {} as any) {
		const postedAt = new Date();

		this.status = SessionModel.RECORDED;
		this.description = postData.title || this.description;
		this.setPosted(postedAt);

		postData.trim = postData.trim ||
			(this.trim && this.trim.start != null && this.trim.end != null ? this.trim : undefined);

		const result = $http.put(`${clientSettings.GoReactV2API}/submissions/${this.getId()}/post`, {
			session_id: this.getId(),
			title: this.description,
			trim: postData.trim
		}).then(() => {
			const license = selectedService.getLicense();
			const activity = selectedService.getActivity();
			const enabledInTheLicense = license && license.salesforce_license.ai_prompts_enabled;
			const enabledInTheActivity = activity && activity.ai_prompts?.length && activity.ai_prompts_enabled;

			if (!this.media?.isYoutube() && enabledInTheLicense && enabledInTheActivity) {
				transcriptionService.getTranscription(this.media)
					.pipe(catchError((error: HttpErrorResponse) => {
						if (error.status === 404) {
							ngxGoToastService.createToast({
								type: GoToastStatusType.SUCCESS,
								message: 'session-successfully-posted-ai-assistant'
							});
						}

						return EMPTY;
					}))
					.subscribe(() => {
						if(this.media?.media_status === MediaModel.READY) {
							ngxGoToastService.createToast({
								type: GoToastStatusType.SUCCESS,
								message: 'session-successfully-posted'
							});
						}
					});
			} else if(this.media?.media_status === MediaModel.READY) {
				ngxGoToastService.createToast({
					type: GoToastStatusType.SUCCESS,
					message: 'session-successfully-posted'
				});
			}
		});

		result.catch(function () {
			this.setUnposted();
			ngxGoToastService.createToast({
				type: GoToastStatusType.ERROR,
				message: 'session-failed-post'
			});
		}.bind(this));

		return result;
	};

	/**
	 * Post a grade to a session
	 */
	SessionModel.prototype.postGrade = function (score: number) {
		return GradeResource.save({
			submissionId: this.getId(),
			score
		}).$promise.then(() => {
			this.setScore(score);
		});
	};

	/**
	 * Retract a grade from a session
	 */
	SessionModel.prototype.retractGrade = function () {
		return GradeResource.delete({
			submissionId: this.getId()
		}).$promise.then(() => {
			this.setScore(null);
		});
	};

	SessionModel.prototype.save = function save () {
		// Build payload
		const payload = {
			session_id: this.session_id,
			group_id: this.group_id,
			activity_id: this.activity_id,
			media_id: this.media_id,
			status: this.status,
			description: this.description,
			presenters: this.presenters,
			tag_set_id: this.tag_set_id,
			source_media_id: this.source_media_id,
			is_activity_placeholder: this.is_activity_placeholder || undefined,
			batch: this.batch,
			extraData: null
		};

		// This is a temporary solution that allows for additional
		// params to be passed that only apply to a specific environment.
		// e.g., in LTI we need to store a user's `sourceId` with
		// individual sessions in order for an instructor to later assign
		// a grade and have the grade show up in the LMS gradebook.
		if (this.hasOwnProperty('extraData')) {
			payload.extraData = this.extraData;
		}

		if (payload.batch) {
			const batchSessionCreateUrl = `${clientSettings.GoReactV2API}/activities/${payload.activity_id}/submissions:batch`;
			return $http.post(batchSessionCreateUrl, payload)
				.then((response: ng.IHttpResponse<any>) => {
					if (response.data.batch) {
						return response.data;
					}
				});
		}

		if (!payload.session_id) {
			const createUrl =
				`${clientSettings.GoReactV2API}/activities/${payload.activity_id}/submissions`;
			return $http.post(createUrl, payload)
				.then((response: ng.IHttpResponse<any>) => this.extend(response.data));
		}

		return $http.put(
			`${clientSettings.GoReactV2API}/activities/${payload.activity_id}/submissions/${payload.session_id}`,
			payload
		).then(() => {
			return this;
		});
	};

	SessionModel.prototype.isDeepLoaded = function isDeepLoaded () {
		return this.media && angular.isObject(this.media) &&
			this.media.media_url && this.media.media_id && this.settings;
	};

	SessionModel.prototype.isOwner = function isOwner (user) {
		return parseInt(this.created_by, 10) === parseInt(user.user_id, 10) ||
			this.isPresenter(user);
	};

	SessionModel.prototype.setMedia = function setMedia (media) {
		this.media_id = angular.isObject(media) ? media.getId() : null;
		this.media = angular.isObject(media) ? media : null;
	};

	SessionModel.prototype.isRecorded = function isRecorded () {
		return this.status >= SessionModel.RECORDED;
	};

	SessionModel.prototype.isLive = function isLive () {
		return this.status === SessionModel.LIVE;
	};

	SessionModel.prototype.isAwaitingStart = function isAwaitingStart () {
		return this.status === SessionModel.WAITING;
	};

	SessionModel.prototype.isCancelled = function isCancelled () {
		return this.status === SessionModel.CANCELLED;
	};

	SessionModel.prototype.isScoreAssigned = function isScoreAssigned () {
		return !isNaN(parseFloat(this.score));
	};

	SessionModel.prototype.setStatus = function (value) {
		this.status = parseInt(value, 10);

		// if session is live and doesn't have a start date
		if (this.status === SessionModel.LIVE && !this.hasStartDate()) {
			this.started_at = new Date();
		}
	};

	SessionModel.prototype.deleteSlideDeck = function deleteSlideDeck () {
		const trackNumber = 2; // Slides are always track 2

		// Clear out syncEvents from sync_events in memory
		this.removeSyncEventsByTrack(trackNumber);

		// Delete comments
		return $q.all([
			CommentModel.deleteSyncEventComments({
				session_id: this.getId()
			}).$promise,
			// Delete all sync events for slides
			SyncEvent.deleteAll({
				session_id: this.getId()
			}).$promise,
			// Remove source media
			this.deleteSourceMedia()
		]);
	};

	SessionModel.prototype.getSyncEventsByTrack = function getSyncEventsByTrack (trackNumber) {
		if (!angular.isObject(this.sync_events)) {
			this.sync_events = {};
		}

		if (!angular.isArray(this.sync_events[trackNumber])) {
			this.sync_events[trackNumber] = [];
		}

		return this.sync_events[trackNumber];
	};

	SessionModel.prototype.getOwner = function getOwner () {
		return !!this.presenters && !!this.presenters.length &&
			this.presenters.reduce(function (user, nextUser) {
				return nextUser.user_id === this.created_by ?
					nextUser : user;
			}.bind(this), this.presenters[0]);
	};

	SessionModel.prototype.isPresenter = function isPresenter (user) {
		if (!this.presenters.length || !user) {
			return false;
		}

		return this.presenters.some((presenter) => {
			return parseInt(presenter.user_id, 10) === parseInt(user.user_id, 10);
		});
	};

	SessionModel.prototype.isPosted = function isPosted () {
		return angular.isDate(this.posted_at);
	};

	SessionModel.prototype.addPresenter = function addPresenter (user) {
		if(this.isPresenter(user)) return;
		this.presenters.push(user);
		return this.save();
	};

	SessionModel.prototype.removePresenter = function removePresenter (user) {
		if(!this.isPresenter(user)) return;
		const index = this.presenters.findIndex((presenter) => {
			return parseInt(presenter.user_id, 10) === parseInt(user.user_id, 10);
		});

		this.presenters.splice(index, 1);
		return this.save();
	};

	SessionModel.prototype.setScore = function setScore (value) {
		// TODO DEV-15860 INCREMENT/DECREMENT events never get dispatched. The previous score is already overwritten by
		// the time this method is called because of the direct score reference in header.component.html using ng-model
		const noScoreBefore = this.score === null || this.score === undefined;
		value = parseFloat(value);
		this.score = isNaN(value) ? null : value;

		if (this.score !== null) {
			eventService.broadcast(EVENT_NAMES.SESSION_GRADED, this);
		} else {
			eventService.broadcast(EVENT_NAMES.SESSION_UNGRADED, this);
		}

		if (noScoreBefore && this.score) {
			eventService.broadcast(EVENT_NAMES.ACTIVTY_INCREMENT_NUM_GRADED, this.activity_id);
		} else if (!noScoreBefore && this.score === null) {
			eventService.broadcast(EVENT_NAMES.ACTIVTY_DECREMENT_NUM_GRADED, this.activity_id);
		}
	};

	SessionModel.prototype.removeSyncEventsByTrack = function removeSyncEventsByTrack (trackNumber) {
		if (angular.isArray(this.sync_events[trackNumber])) {
			this.sync_events[trackNumber].splice(0, this.sync_events[trackNumber].length);
		}
	};

	SessionModel.prototype.deleteSourceMedia = function deleteSourceMedia () {
		// Clear session source media
		this.setSourceMedia(null);

		// Save the session
		return this.save();
	};

	SessionModel.prototype.hasStartDate = function hasStartDate () {
		return angular.isDate(this.started_at);
	};

	SessionModel.prototype.hasViewedRecordingInstructions = function hasViewedRecordingInstructions () {
		return angular.isDate(this.viewed_recording_instructions_at);
	};

	SessionModel.prototype.mayDelete = function mayDelete (user, group, activity) {
		if (!this.mayEdit(user, group)) {
			return false;
		}

		if (this.is_activity_placeholder) {
			return false;
		}

		if (group.hasInstructorRole(true)) {
			return true;
		}

		if (!group.hasPresenterRole()) {
			return false;
		}

		if (!this.isOwner(user)) {
			return false;
		}

		if (activity.has_single_recording_attempt && this.viewed_recording_instructions_at) {
			return false;
		}

		if (activity.has_single_recording_attempt && !this.isAwaitingStart()) {
			return false;
		}

		return true;
	};

	SessionModel.prototype.isLimitCommentOnlySingleAttempt = function (activity: any, user: any) {
		return activity.isCommentOnlySingleAttempt() &&
			this.isOwner(user) &&
			this.have_i_commented;
	};

	/**
	 * Whether a user is allowed to give feedback on a recorded session
	 */
	SessionModel.prototype.mayReview = function (user: any, group: any, activity: any): boolean {
		// The user may not review activities that are associated with a default activity
		if (activity.isDefault()) {
			return false;
		}

		// Rule: only session owners can review an unposted session.
		// Exception: live review and single recording attempt activities.
		if (
			!this.isPosted() &&
			!this.isOwner(user) &&
			!activity.isLiveSessionEnabled() &&
			!this.is_activity_placeholder
		) {
			return activity.has_single_recording_attempt && group.hasInstructorRole(true);
		}

		const atLeastReviewer = group.hasReviewerRole(true);

		// Stimulus Comment only placeholder sessions with invdividual graded turned on are not
		// reviewable by reviewers and above
		if (
			atLeastReviewer &&
			!activity.has_response_media &&
			activity.is_individual_graded &&
			this.is_activity_placeholder
		) {
			return false;
		}

		// Session owners and users with a reviewer role or higher are allowed to review
		if (this.isOwner(user) || atLeastReviewer) {
			return true;
		}

		// Peers can review others sessions if peer review is enabled.
		// They can also click on placeholders to enable creating their own session.
		return activity.isPeerReview() || this.is_activity_placeholder || activity.peer_rubric_critique_enabled;
	};

	/**
	 * Whether a user is allowed to publish media streams
	 */
	SessionModel.prototype.mayPublish = function (user: any, group: any, activity: any): boolean {
		if (this.isRecorded()) {
			return false;
		}

		// Default and placeholder activities are not applicable
		if (activity.isDefault() || this.is_activity_placeholder) {
			return false;
		}

		// Users with a reviewer role are not allowed to publish
		if (group.hasReviewerRole()) {
			return false;
		}

		// Users with an instructor role are allowed to publish live event assignments
		if (group.hasInstructorRole(true) && activity.isLiveSessionEnabled()) {
			return true;
		}

		// Session owners are allowed publish
		if (this.isOwner(user)) {
			return true;
		}

		// Peers can publish media streams when conversation and peer review is enabled.
		if (activity.isConversation() && activity.isPeerReview()) {
			return true;
		}

		return false;
	};

	SessionModel.prototype.mayEdit = function mayEdit (user, group) {
		// instructors and above can edit
		if (group && group.hasInstructorRole(true)) {
			return true;
		}

		// or you can edit your own
		if (user && this.isOwner(user)) {
			return true;
		}

		return false;
	};

	// Instance methods
	SessionModel.prototype.getId = function getId () {
		return this.session_id;
	};

	SessionModel.prototype.setPosted = function setPosted (date) {
		this.posted_at = angular.isDate(date) ? date : new Date();
	};

	SessionModel.prototype.setUnposted = function setUnposted () {
		this.posted_by = null;
		this.posted_at = null;
	};

	SessionModel.prototype.setSourceMedia = function setSourceMedia (sourceMedia) {
		const isObject = angular.isObject(sourceMedia);
		this.source_media_id = isObject ? sourceMedia.media_id : null;
		this.source_media = isObject ? sourceMedia : null;
	};

	/**
	 * Determine whether a session (currently just multi camera) has the maximum allowed presenters.
	 */
	SessionModel.prototype.hasMaxPresenterCount = function hasMaxPresenterCount (activity) {
		if (!this.presenters.length || !activity) {
			return false;
		}

		return activity.is_conversation &&
			this.presenters.length >= SessionConstants.MUTLI_CAMERA_MAX_PRESENTER_COUNT;
	};

	/**
	 * Set have_i_submitted and attention_needed
	 */
	SessionModel.prototype.setHaveISubmitted = function setHaveISubmitted (group, activity, haveSubmitted) {
		if (group.hasPresenterRole() && activity.hasDueDate()) {
			activity.have_i_submitted = haveSubmitted;
			group.attention_needed = group.activities.some((groupActivity) => {
				if (groupActivity.isAlmostDue()) {
					return !groupActivity.viewed_by_me || !groupActivity.have_i_submitted;
				}
				return !groupActivity.viewed_by_me;
			});
		}
	};

	SessionModel.prototype.getPresenterNames = function getPresenterNames () {
		return this.presenters.map((item) => item.fullname);
	};

	SessionModel.prototype.getFormattedPresenters = function getFormattedPresenters () {
		return this.getPresenterNames().join(' · ');
	};

	// This method is only responsible for merging
	// 2 sessionModel instances together
	// This method never sets source media to null if it wasnt null to begin with
	// This needs to be the case because a session has a source set when the
	// activity has a source, even though the session has no source media relation
	// its not safe to un-set just because you are extending a session that has none
	SessionModel.prototype.extend = function extend (otherSession) {
		const existingSourceMedia = this.source_media;
		let ip;

		// This preserves the user_ip if it is present
		if (this.settings && this.settings.user_ip) {
			ip = this.settings.user_ip;
		}

		const session = angular.extend(this, sessionModelTransformer.response(otherSession));
		session.setSourceMedia(existingSourceMedia);

		// Restore ip after extension
		if (ip) {
			session.settings.user_ip = ip;
		}

		// Never unset source media, but set it to the other one if its there
		if (otherSession.source_media && existingSourceMedia instanceof MediaModel) {
			angular.extend(existingSourceMedia, MediaModel.model(otherSession.source_media));
			session.setSourceMedia(existingSourceMedia);
		} else if (otherSession.source_media) {
			session.setSourceMedia(MediaModel.model(otherSession.source_media));
		}

		return session;
	};

	SessionModel.prototype.isPractice = function () {
		return featureFlag.isAvailable('PRACTICE_VIDEOS') &&
			this.isUnpostedRecording();
	};

	SessionModel.prototype.isUnpostedRecording = function () {
		return this.status === SessionModel.RECORDED &&
			this.posted_at === null;
	};

	SessionModel.prototype.restart = function () {
		return $http.put(`${clientSettings.GoReactV2API}/sessions/${this.session_id}/restart`, this);
	};

	return SessionModel;
}

Session.NG1_INJECTION_NAME = 'Session' as const;
export const sessionToken = upgradeNg1Dependency(Session);
