import * as angular from 'angular';
import type { IModalInstanceService } from 'angular-ui-bootstrap';

export interface GoSidePanelOptions extends ng.ui.bootstrap.IModalSettings {
	/**
	 * Properties to bind to the side panel controller instance
	 */
	bindings?: any;
}

/* @ngInject */
export class SidePanelService {

	constructor (
		private $uibModal: ng.ui.bootstrap.IModalService,
		private $rootScope: ng.IRootScopeService,
		private $document: ng.IDocumentService,
		private $log: ng.ILogService
	) {}

	public open (options: GoSidePanelOptions): IModalInstanceService {
		options.keyboard = false;
		options.bindToController = true;
		options.controllerAs = '$ctrl';
		options.windowClass = options.windowClass ?
			options.windowClass + ' ' + 'side-panel' :
			'side-panel';

		const scope = this.$rootScope.$new();
		angular.extend(scope, options.bindings || {});
		options.scope = scope;
		options.animation = false;

		const instance = this.$uibModal.open(options);

		instance.rendered.then(()=> {
			const focusSelector = options.bindings?.options?.firstFocusSelector;
			if (focusSelector != null) {
				const el: HTMLElement | null = this.$document[0].querySelector(focusSelector);
				if (el != null) {
					el.focus();
				}
			}
		}).catch(this.$log.error);

		return instance;
	}
}
