/* @ngInject */
export function labelInputFormatterDirective ($filter) {
	return {
		require: 'ngModel',
		link: function link (_scope, _element, attrs, ngModelController) {
			if (!attrs[this.name]) {
				return;
			}

			const formatter: Function = (value: any) => {
				return $filter(attrs[this.name])(value);
			};

			ngModelController.$formatters.push(formatter);
		}
	};
}
