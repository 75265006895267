import { VirtualScrollStrategy, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Sentence } from 'ngx/go-modules/src/components/feedback-session/transcript-viewer/transcript-viewer.component';
import { Observable, Subject, distinctUntilChanged } from 'rxjs';

interface MessageHeight {
	value: number;
	source: 'predicted' | 'actual';
}

/**
 * Custom Scroll Strategy for the Transcript Viewer Component
 * This is a slightly improved version of the following implementation that handles dynamic height elements:
 * https://dev.to/georgii/virtual-scrolling-of-content-with-variable-height-with-angular-3a52
 *
 * The main difference being that this implementation clears the cache on viewport resizes, and handles different
 * layout calculations depending on if the transcript is multi-speaker or not.
 */
export class TranscriptScrollStrategy implements VirtualScrollStrategy {
	private viewport!: CdkVirtualScrollViewport | null;
	private viewportWidth: number = 0;
	private sentences: Sentence[] = [];
	private wrapper!: ChildNode | null;

	// The number of sentences to render above and below the viewport for smoother scrolling
	private readonly NumSentencesToRenderOutsideViewport = 15;
	// top, right, bottom, left padding
	private readonly SentenceContainerPadding = 10;
	private readonly SentenceRowHeight = 21;
	private readonly TimeContainerWidth = 40;
	private readonly TimeContainerMarginRightPlusLeft = 25;
	private readonly MultiSpeakerLabelHeight = 18;
	private readonly MultiSpeakerLabelMarginBottom = 2;
	private readonly SentenceExcessWidth =
	this.TimeContainerWidth + this.TimeContainerMarginRightPlusLeft + (this.SentenceContainerPadding * 2);

	private _scrolledIndexChange$ = new Subject<number>();
	public scrolledIndexChange: Observable<number> = this._scrolledIndexChange$.pipe(distinctUntilChanged());
	public heightCache = new Map<number, MessageHeight>();

	constructor (private isMultiSpeaker: boolean) {}

	/** VirtualScrollStrategy Interface Overrides */
	public onContentRendered (): void {}
	public onRenderedOffsetChanged (): void {}

	public attach (viewport: CdkVirtualScrollViewport): void {
		this.viewport = viewport;
		this.viewportWidth = viewport.getElementRef().nativeElement.clientWidth;
		this.wrapper = viewport.getElementRef().nativeElement.childNodes[0];

		if (this.sentences) {
			this.viewport.setTotalContentSize(this.getTotalHeight());
			this.updateRenderedRange();
		}
	}

	/**
	 * The scroll strategy will automatically detach when the component its' on gets destroyed
	 */
	public detach (): void {
		this.viewport = null;
		this.wrapper = null;
		this.heightCache.clear();
		this._scrolledIndexChange$.complete();
	}

	public onContentScrolled (): void {
		if (this.viewport) {
			this.updateRenderedRange();
		}
	}

	public onDataLengthChanged (): void {
		if (!this.viewport) {
			return;
		}

		this.viewport.setTotalContentSize(this.getTotalHeight());
		this.updateRenderedRange();
	}

	/**
	 * Scrolls to a sentence by a provided index
	 */
	public scrollToIndex (index: number, behavior: ScrollBehavior): void {
		if (!this.viewport) {
			return;
		}

		const offset = this.getOffsetByMsgIdx(index);
		this.viewport.scrollToOffset(offset, behavior);
	}
	/** VirtualScrollStrategy Interface Overrides End */

	/**
	 * Update the sentences that are being rendered
	 */
	public updateSentences (sentences: Sentence[]) {
		this.sentences = sentences;

		if (this.viewport) {
			this.viewport.checkViewportSize();
		}
	}

	/**
	 * Updates layout calculations based on the number of speakers
	 */
	public updateLayout (isMultiSpeaker: boolean) {
		// If already in the desired layout, do nothing
		if (this.isMultiSpeaker === isMultiSpeaker) return;

		this.isMultiSpeaker = isMultiSpeaker;
		this.heightCache.clear();

		this.updateTotalContentSize();
		this.updateRenderedRange();
	}

	/**
	 * Returns the predicted height of a sentence element
	 */
	private predictSentenceHeight = (sentence: Sentence): number => {
		// 7px avg character width for 14px font size
		const messageRowCharCount = (this.viewportWidth - this.SentenceExcessWidth) / 7;

		const textHeight =
            Math.ceil(sentence.parsedText.length / messageRowCharCount) * this.SentenceRowHeight;

		let height = 0;
		if (this.isMultiSpeaker) {
			height += this.MultiSpeakerLabelHeight + this.MultiSpeakerLabelMarginBottom;
		}
		height += textHeight + (this.SentenceContainerPadding * 2);

		return height;
	};

	/**
	 * Returns the cached or predicted height of a sentence
	 */
	private getSentenceHeight (sentence: Sentence): number {
		const cachedHeight = this.heightCache.get(sentence.id);
		if (cachedHeight) return cachedHeight.value;

		const predictedHeight = this.predictSentenceHeight(sentence);
		this.heightCache.set(
			sentence.id,
			{ value: predictedHeight, source: 'predicted' }
		);

		return predictedHeight;
	}

	/**
	 * Returns the total height of all supplied sentences
	 */
	private measureSentencesHeight (sentences: Sentence[]): number {
		return sentences
			.map((s) => this.getSentenceHeight(s))
			.reduce((a, b) => a + b, 0);
	}

	/**
	 * Returns the total height of all sentence elements (the scrollable container)
	 */
	private getTotalHeight (): number {
		return this.measureSentencesHeight(this.sentences);
	}

	/**
	 * Returns the offset relative to the top of the container by a provided sentence index
	 */
	private getOffsetByMsgIdx (idx: number): number {
		return this.measureSentencesHeight(this.sentences.slice(0, idx));
	}

	/**
	 * Returns the sentence index by a provided offset.
	 */
	private getSentenceIdxByOffset (offset: number): number {
		let accumOffset = 0;

		for (let i = 0; i < this.sentences.length; i++) {
			const msg = this.sentences[i];
			const msgHeight = this.getSentenceHeight(msg);
			accumOffset += msgHeight;

			if (accumOffset >= offset) {
				return i;
			}
		}

		return 0;
	}

	/**
	 * Re-calculates the total size of the viewport
	 */
	private updateTotalContentSize () {
		if (!this.viewport) return;
		this.viewport.setTotalContentSize(this.getTotalHeight());
	}

	/**
	 * Returns the number of sentences that can fit in the viewport
	 */
	private determineSentencesCountInViewport (startIdx: number): number {
		let totalSize = 0;
		// That is the height of the scrollable container
		const viewportSize = this.viewport.getViewportSize();

		for (let i = startIdx; i < this.sentences.length; i++) {
			const msg = this.sentences[i];
			totalSize += this.getSentenceHeight(msg);

			if (totalSize >= viewportSize) {
				return i - startIdx + 1;
			}
		}

		return 0;
	}

	/**
	 * Gets called on every viewport update (scrolling, resizing)
	 */
	private updateRenderedRange () {
		if (!this.viewport) {
			return;
		}

		const scrollOffset = this.viewport.measureScrollOffset();
		const scrollIdx = this.getSentenceIdxByOffset(scrollOffset);
		const dataLength = this.viewport.getDataLength();
		const renderedRange = this.viewport.getRenderedRange();
		const range = {
			start: renderedRange.start,
			end: renderedRange.end
		};

		// Instruct the virtual scroll to render extra messages above and below the viewport
		range.start = Math.max(0, scrollIdx - this.NumSentencesToRenderOutsideViewport);
		range.end = Math.min(
			dataLength,
			scrollIdx + this.determineSentencesCountInViewport(scrollIdx) + this.NumSentencesToRenderOutsideViewport
		);

		this.viewport.setRenderedRange(range);
		this.viewport.setRenderedContentOffset(
			this.getOffsetByMsgIdx(range.start)
		);
		this._scrolledIndexChange$.next(scrollIdx);
		this.updateHeight();
	}

	/**
	 * Updates the height of each sentence element while caching queried results
	 */
	private updateHeight () {
		if (!this.wrapper) {
			return;
		}

		if (this.viewportWidth !== this.viewport.getElementRef().nativeElement.clientWidth) {
			this.viewportWidth = this.viewport.getElementRef().nativeElement.clientWidth;
			this.heightCache.clear();
		}

		// Get a reference to the ul element child node
		const ulNode = this.wrapper.childNodes;
		if (ulNode == null || ulNode.length === 0) return;

		// Get the UL child nodes, this should contain li nodes
		const childUlNodes = this.wrapper.childNodes[0].childNodes;

		let cacheUpdated: boolean = false;

		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < childUlNodes.length; i++) {
			const node = childUlNodes[i] as HTMLElement;

			// Check if the node is actually an li element
			if (node && node.nodeName === 'LI') {
				// Get the message ID
				const id = node.getAttribute('data-sentence-id') as string;
				const cachedHeight = this.heightCache.get(Number(id));

				// Update the height cache, if the existing height is predicted
				if (!cachedHeight || cachedHeight.source !== 'actual') {
					const height = node.clientHeight;
					this.heightCache.set(Number(id), { value: height, source: 'actual' });
					cacheUpdated = true;
				}
			}
		}

		// Reset the total content size only if there has been a cache change
		if (cacheUpdated) {
			this.viewport.setTotalContentSize(this.getTotalHeight());
		}
	}
}

