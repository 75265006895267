import * as angular from 'angular';
import { AuthService } from 'go-modules/services/auth/auth.service';

export class SessionManagerProvider {

	private config = {};
	private accessTokenGetter: () => void = angular.noop;
	private refreshTokenGetter = null;

	constructor () {}

	public set = (key, value) => {
		this.config[key] = value;
	};

	public get = (key) => {
		if (key) {
			return this.config[key];
		}

		return this.config;
	};

	/** @ngInject */
	public $get = ($q: ng.IQService, $injector: ng.auto.IInjectorService) => {

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const parent = this;
		return {
			set: parent.set,
			get: parent.get,
			accessTokenGetter () {
				return $q.when($injector.invoke(parent.accessTokenGetter, true));
			},
			refreshTokenGetter (currentAccessToken) {
				if (parent.refreshTokenGetter) {
					return $q.when(
						$injector.invoke(parent.refreshTokenGetter, true, {accessToken: currentAccessToken})
					);
				}

				const deferred = $q.defer();

				$injector.invoke(['authService', (authService: AuthService) => {
					authService.refresh({
						refresh_token: currentAccessToken,
						skipAuthorization: false
					}).then((response) => {
						deferred.resolve(response.data.access_token);
					}).catch(() => {
						deferred.reject();
					});
				}], true);

				return deferred.promise;
			}
		};
	};
}
