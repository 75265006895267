import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as angulartics from 'angulartics';

import { group } from '../models/group';
import { userGroupModel } from '../models/user-group';
import { CoursePaymentWallComponent } from './course-payment-wall.component';
import { confirmModalModule } from '../modals/confirm';
import { messageModalModule } from 'go-modules/modals/message';

import './style.less';
import { braintreeModule } from 'go-modules/braintree';
import { featureFlagModule } from 'go-modules/feature-flag';
import { ngxGoModalServiceModule } from 'ngx/go-modules/src/services/go-modal';
import { groupDepModel } from 'go-modules/models/group-dep';
import { ngxZeroStateModule } from 'ngx/go-modules/src/components/zero-state';

export const coursePaymentWallModule = angular.module('course-payment-wall', [
	userGroupModel,
	group,
	groupDepModel,
	angularTranslate,
	confirmModalModule,
	messageModalModule,
	braintreeModule,
	angulartics,
	featureFlagModule,
	ngxGoModalServiceModule,
	ngxZeroStateModule
])
	.component('coursePaymentWall', CoursePaymentWallComponent)
	.name;
