import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { common } from '../common';
import { userModel } from '../user';
import { sessionModel } from '../session';

import { Group } from './group.factory';
import { groupModelTransformer } from './group.service';
import { groupServiceModule } from 'go-modules/services/group';

import { CourseModule } from 'go-modules/services/course';
import { userGroupModel } from '../user-group';
import { ngxGroupServiceModule } from 'ngx/go-modules/src/services/group';
import { group } from 'go-modules/models/group';

export const groupDepModel = angular.module('go.models.group.dep', [
	angularResource,
	common,
	userModel,
	userGroupModel,
	sessionModel,
	groupServiceModule,
	CourseModule,
	ngxGroupServiceModule,
	group
])
	.factory(Group.NG1_INJECTION_NAME, Group)
	.service('groupModelTransformer', groupModelTransformer)
	.name;
