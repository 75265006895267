/* @ngInject */
export const activitiesWithIdFilter = () => {
	return (activities) => {
		if (!Array.isArray(activities)) {
			throw new Error('activitiesWithIdFilter: Activities With ID Filter input must be of type Array');
		}
		return activities.filter((activity) => {
			return activity.hasOwnProperty('activity_id');
		});
	};
};
