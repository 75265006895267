import * as angular from 'angular';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as angularMaterial from 'angular-material';
import * as angulartics from 'angulartics';
import * as angularTranslate from 'angular-translate';
import * as ngAria from 'angular-aria';

import { detectModule } from '../detect';
import { timerModule } from '../timer';
import { globalEvent } from '../global-event';
import { goHotKeys } from '../hotkeys';
import { playerSyncModule } from '../player-sync';
import { playerControlsComponent } from './player-controls.component';
import { playerControllsSettingsComponent } from './player-controls-settings/player-controls-settings.component';
import { timeFormatFilter } from './player-controls.filter';
import { slideToggleModule } from 'go-modules/slide-toggle';
import { allPlayersServiceModule } from 'ngx/go-modules/src/services/all-players';

import 'angular-material/modules/layouts/angular-material.layouts.css';
import 'angular-material/modules/js/core/core.css';
import 'angular-material/modules/js/button/button.css';
import 'angular-material/modules/js/switch/switch.css';
import 'angular-material/modules/js/icon/icon.css';
import 'nouislider/distribute/nouislider.css';

import './style.less';

export const goPlayerControls = angular.module('go.controls', [
	angularMaterial,
	detectModule,
	timerModule,
	angularTranslate,
	playerSyncModule,
	globalEvent,
	angulartics,
	uiTooltip,
	goHotKeys,
	slideToggleModule,
	allPlayersServiceModule,
	ngAria
])
	.filter('timeFormat', timeFormatFilter)
	.component('playerControlsSettings', playerControllsSettingsComponent)
	.component('playerControls', playerControlsComponent)
	.name;
