/* @ngInject */
export function cacheManager ($cacheFactory) {
	const repository = {};
	const getCache = function (id) {
		if (!repository[id]) {
			repository[id] = $cacheFactory(id);
		}
		return repository[id];
	};
	return function (id) {
		return getCache(id);
	};
};
