import * as dayjs from 'dayjs';
import humanizeDuration from 'humanize-duration';

/* @ngInject */
export function timeFormatFilter ($translate: ng.translate.ITranslateService) {
	return function (time: string | number, aria: boolean = false): string {
		time = parseInt(time as string, 10);

		// In aria we want to speak aloud "1 minute, 10 seconds"
		if (aria) {
			let lang = $translate.proposedLanguage() || $translate.use();
			// We need to convert to ISO 639-1 without a subcode
			lang = lang?.split('-')[0];
			return humanizeDuration(time, {
				language: lang,
				fallbacks: ['en'],
				round: true
			});
		}

		// In non-aria we want to display 01:00
		const format = time >= 3600000 ? 'hh:mm:ss' : 'mm:ss';
		return dayjs('1970-01-01T00:00:00.000').add(time, 'ms').format(format);
	};
}
