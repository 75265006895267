import Sortable, { GroupOptions } from 'sortablejs';
import angular from 'angular';

export interface AriaSortableOptions extends Sortable.AngularLegacySortableOptions {
	putSpeakerAdditionalText?: SpeakerAdditionalText;
	speakerId?: string;
}
export interface SortableScope extends ng.IScope {
	ngSortable: AriaSortableOptions;
};

export interface SpeakerAdditionalText {
	append?: string;
	prepend?: string;
};

export interface GrabData {
	handle: HTMLElement;
	speakerAdditionalText?: SpeakerAdditionalText;
};


// Our screen-reader element
export const SPEAKER_ID = 'aria-ng-sortable-speaker';

// Internal consts that we need to copy here to gleen secrets
const EXPANDO = 'Sortable:ng-sortable';
const NG_ANIMATE_ATTR_NAME = 'data-ng-animate';

// Copied from _dispatchEvent, very simplifed
// https://github.com/SortableJS/Sortable/blob/1.9.0/Sortable.js#L1896
function createSortableEvent (
	type: string,
	rootEl: HTMLElement,
	item: HTMLElement,
	oldIndex: number,
	newIndex: number
): Sortable.SortableEvent {
	return  Object.assign(new CustomEvent(type, {
		bubbles: true,
		cancelable: true
	}) as CustomEvent & {target: HTMLElement}, {
		item,
		items: [],
		// We don't do cross lists, so to/from is the same
		to: rootEl,
		from: rootEl,
		clone: null,
		pullMode: undefined,
		oldIndex,
		newIndex,
		// Not sure how these are any different than index
		oldDraggableIndex: oldIndex,
		newDraggableIndex: newIndex,
		// We don't use multidrag
		oldIndicies: [],
		newIndicies: [],
		// We don't use swap
		swapItem: null
	});
}

// Adapted from angular-legacy-sortable who wraps certain events
function createNgSortableEvent (
	type: string,
	rootEl: HTMLElement,
	modelDom: HTMLElement,
	oldIndex: number,
	newIndex: number,
	model: any,
	models: any[]
): Sortable.AngularLegacySortableEvent {
	return {
		model,
		models,
		oldIndex,
		newIndex,
		originalEvent: createSortableEvent(type, rootEl, modelDom, oldIndex, newIndex)
	};
}

/* @ngInject */
const ariaNgSortableDecoratorFn = (
	$delegate: ng.IDirective[],
	$window: ng.IWindowService,
	$timeout: ng.ITimeoutService,
	$translate: ng.translate.ITranslateService
) => {
	const $ngSortable = $delegate[0];

	// Monkey patch it
	$ngSortable.compile = ((oldCompile) => {
		return function newCompile (tElem: ng.IAugmentedJQuery, tAttrs: ng.IAttributes) {
			// Get the old linkingFn
			const oldLinkingFn = oldCompile.call(this, tElem, tAttrs);
			// In the case that there is no ng-repeat ng-sortable returns null, to which we should emulate
			if (oldLinkingFn == null) {
				return null;
			}

			// Now monkey patch that
			return function newLinkingFn (scope: SortableScope, iElement: ng.IAugmentedJQuery, iAttrs: ng.IAttributes) {
				// And we're in. Attach sortablejs the normal way
				oldLinkingFn.call(this, scope, iElement, iAttrs);

				// Some state params
				let oldIndex = null;
				let grabbed = false;
				let moved = false;
				let refocusHack = false;
				let lastHandle = null;

				const getLocationInfo = (handle: HTMLElement) => {
					// Who are we? Where are we?
					const item = Sortable.utils.closest(handle, '[ng-repeat]', iElement[0]);
					const items = Array.from(item.parentElement.querySelectorAll(':scope > [ng-repeat]'));
					const index = items.indexOf(item);
					const count = items.length;
					const name = handle.getAttribute('aria-label');

					return { item, index, count, name };
				};

				/**
				 * Get All Except for self
				 */
				const getAllSortableElements = (): HTMLElement[] => {
					return [...$window.document.querySelectorAll('[ng-sortable]')]
						.filter((elm: HTMLElement) => {
							return elm !== iElement[0];
						}) as HTMLElement[];
				};

				const pullModeKeydownListener = (e: JQueryEventObject) => {
					if(e.key !== 'Enter' && e.key !== ' ' || scope.ngSortable.disabled) {
						return;
					}

					getAllSortableElements().forEach((elm: HTMLElement) => {
						angular.element(elm).triggerHandler('getScope', (_scope) => {
							const sortableScope = _scope;
							const sortableOptions = sortableScope.ngSortable;
							const sortableGroup: GroupOptions = sortableOptions?.group;

							if(sortableGroup === undefined) {
								return;
							}

							if (sortableGroup.name !==
								(scope.ngSortable.group as GroupOptions).name ||
								!sortableGroup.put) {
								return;
							}

							const ownModels = iElement[0][EXPANDO]();
							const targetModels = elm[EXPANDO]();
							const ownModelLocationInfo = getLocationInfo(e.target as HTMLElement);
							let addedElement = ownModels[ownModelLocationInfo.index];

							if((scope.ngSortable.group as GroupOptions).pull === 'clone') {
								addedElement = angular.copy(addedElement);
							} else {
								ownModels.splice(ownModelLocationInfo.index, 1);

							};

							targetModels.push(addedElement);
							sortableScope.$evalAsync();

							$timeout(() => {
								const targetModelLength = targetModels.length;
								const handle = elm.querySelectorAll(`${sortableOptions.handle || '[ng-repeat]'}`)[targetModelLength - 1] as HTMLElement;

								sortableOptions.onAdd?.(createNgSortableEvent(
									'add', elm, addedElement, targetModelLength - 1, targetModelLength, addedElement, targetModels
								));

								angular.element(elm).triggerHandler('grabHandle', {handle, speakerAdditionalText: scope.ngSortable.putSpeakerAdditionalText} as GrabData);
							});
						});
					});
				};

				const pullModeEnabled = (scope.ngSortable.group as GroupOptions)?.name !== null
					&& ((scope.ngSortable.group as GroupOptions)?.pull === 'clone' || (scope.ngSortable.group as GroupOptions)?.pull === true);

				if(pullModeEnabled) {
					iElement.on('keydown', pullModeKeydownListener);
					iElement[0].setAttribute('role', 'application');
					return;
				}

				// Add a screen-reader only element to the page. If multiple drag-drop elements,
				// reuse the old live element
				// Has to be on document (not in iElement) otherwise SortableJS
				// freaks out and has weird mouse drag-drop twitchiness.
				let ariaLiveElement = $window.document.getElementById(scope.ngSortable.speakerId || SPEAKER_ID);

				if(ariaLiveElement === null && scope.ngSortable.speakerId !== undefined) {
					throw new Error(`Cannot Find element with an Id of {${scope.ngSortable.speakerId}}`);
				}

				if (ariaLiveElement == null) {
					ariaLiveElement = $window.document.createElement('span');
					ariaLiveElement.id = SPEAKER_ID;
					ariaLiveElement.setAttribute('count', '1');
					$window.document.body.appendChild(ariaLiveElement);
				} else if (scope.ngSortable.speakerId === undefined) {
					ariaLiveElement.setAttribute('count', `${parseInt(ariaLiveElement.getAttribute('count'), 10) + 1}`);
				}

				ariaLiveElement.classList.add('sr-only');
				ariaLiveElement.setAttribute('aria-live', 'polite');

				// There is a issue with ng-repeat: Focus lost when item
				// in ng-repeat moves to an earlier position in an array
				// See https://github.com/angular/angular.js/issues/6859
				// So if we are moving to an earlier position, hack the focus back
				const refocus = (handle, item) => {
					// Disable blur/focus listeners
					refocusHack = true;
					// Don't let aria reread the handle when focus is regained
					handle.setAttribute('aria-hidden', 'true');

					// Watch the $animate library's ng-animate attribute modification
					const observer = new MutationObserver(
						(mutations: MutationRecord[]) => {
							for (const mutation of mutations) {
								// We know the the animation has finish when we
								// remove the NG_ANIMATE_ATTR_NAME attribute, so
								// we are waiting for that mutation
								if (
									mutation.type === 'attributes' &&
									mutation.attributeName === NG_ANIMATE_ATTR_NAME &&
									!item.hasAttribute(NG_ANIMATE_ATTR_NAME)
								) {
									// Set focus back to the handle
									handle.focus();
									// We can now listen to focus events again
									refocusHack = false;
									// After the next tick, we'll unhide the handle from aria.
									// If we don't wait for at least one jount though the event loop
									// then the screen reader will reread the handle
									$timeout(
										() => {
											handle.removeAttribute('aria-hidden');
										},
										/*delay*/ 0,
										/*$apply*/ false
									);
									// And no longer observe
									observer.disconnect();
									break;
								}
							}
						}
					);
					observer.observe(item, {
						attributeFilter: [NG_ANIMATE_ATTR_NAME]
					});
				};

				const grab = (handle: HTMLElement, addionalText?: SpeakerAdditionalText) => {
					const { item, index, count, name } = getLocationInfo(handle);

					// This is important!
					ariaLiveElement.setAttribute('aria-live', 'assertive');
					// We are reordering!
					handle.setAttribute('aria-grab', 'true');
					handle.setAttribute('aria-grabbed', 'true');

					// Let the user know. Set to empty and back to ensure it's always read
					ariaLiveElement.textContent = '';
				    const grabText =  $translate.instant('aria-ng-sortable_grabbed', {
						name,
						position: index + 1,
						count
					});

					const prepend = addionalText?.prepend ? `${addionalText.prepend} ` : '';
					const append = addionalText?.append ? ` ${addionalText.append}` : '';

					ariaLiveElement.textContent = `${prepend}${grabText}${append}`;

					// Fire the event that SortableJs does
					scope.ngSortable.onChoose?.(createSortableEvent(
						'choose', iElement[0], item, oldIndex, index
					));

					// Set internal state for other listners
					grabbed = true;
					oldIndex = index;
				};

				const drop = (handle: HTMLElement) => {
					const { item, index, count, name } = getLocationInfo(handle);

					// Let the user know
					ariaLiveElement.textContent = '';
					ariaLiveElement.textContent = $translate.instant('aria-ng-sortable_dropped', {
						name,
						position: index + 1,
						count
					});

					// No longer important
					ariaLiveElement.setAttribute('aria-live', 'polite');
					// End reordering
					handle.setAttribute('aria-grab', 'supported');
					handle.setAttribute('aria-grabbed', 'false');

					// Fire all the events that SortableJs does
					const models = iElement[0][EXPANDO]();
					const model = models[index];
					scope.ngSortable.onUnchoose?.(createSortableEvent(
						'unchoose', iElement[0], item, oldIndex, index
					));

					if (oldIndex !== index) {
						scope.ngSortable.onUpdate?.(createNgSortableEvent(
							'update', iElement[0], item, oldIndex, index, model, models
						));
						scope.ngSortable.onSort?.(createNgSortableEvent(
							'sort', iElement[0], item, oldIndex, index, model, models
						));
					}
					if (moved) {
						scope.ngSortable.onEnd?.(createNgSortableEvent(
							'end', iElement[0], item, oldIndex, index, model, models
						));
					}

					// Reset the state
					grabbed = false;
					oldIndex = null;
					moved = false;
				};

				const cancel = (handle: HTMLElement) => {
					const { item, name, index } = getLocationInfo(handle);

					const items = iElement[0][EXPANDO]();
					items.splice(oldIndex, 0, items.splice(index, 1)[0]);

					// Let the user know
					ariaLiveElement.textContent = '';
					ariaLiveElement.textContent = $translate.instant('aria-ng-sortable_cancelled', {
						name
					});

					// No longer important
					ariaLiveElement.setAttribute('aria-live', 'polite');
					// End reordering
					handle.setAttribute('aria-grab', 'supported');
					handle.setAttribute('aria-grabbed', 'false');

					// Fire all the events that SortableJs does
					const models = iElement[0][EXPANDO]();
					const model = models[index];
					scope.ngSortable.onUnchoose?.(createSortableEvent(
						'unchoose', iElement[0], item, oldIndex, index
					));
					if (moved) {
						scope.ngSortable.onEnd?.(createNgSortableEvent(
							'end', iElement[0], item, oldIndex, index, model, models
						));
					}

					// Reset the state
					grabbed = false;
					oldIndex = null;
					moved = false;
				};

				const move = (handle: HTMLElement, forward: boolean) => {
					const { item, name, index, count } = getLocationInfo(handle);

					// Move the item in the ng-repeat collection either up or down one
					const items = iElement[0][EXPANDO]();
					// Modulo (%) here is used to wrap to the start/end
					const newIndex = ((index + (forward ? 1 : -1)) + count) % count;
					items.splice(newIndex, 0, items.splice(index, 1)[0]);

					// Let the user know
					ariaLiveElement.textContent = '';
					ariaLiveElement.textContent = $translate.instant('aria-ng-sortable_moved', {
						name,
						position: newIndex + 1,
						count
					});

					// Fire all the events that SortableJs does
					if (!moved) {
						moved = true;
						scope.ngSortable.onStart?.(createNgSortableEvent(
							'start', iElement[0], item, oldIndex, index, items[index], items
						));
					}
					scope.ngSortable.onChange?.(createSortableEvent(
						'change', iElement[0], item, oldIndex, index
					));

					if (newIndex < index) {
						refocus(handle, item);
					} else {
						handle.scrollIntoView();
					}
				};

				const jump = (handle: HTMLElement, forward: boolean) => {
					const { item, name, index, count } = getLocationInfo(handle);

					// Move the item in the ng-repeat collection to the front or back of the array
					const items = iElement[0][EXPANDO]();
					const newIndex = forward ? count - 1 : 0;
					items.splice(newIndex, 0, items.splice(index, 1)[0]);

					// Let the user know
					ariaLiveElement.textContent = '';
					ariaLiveElement.textContent = $translate.instant('aria-ng-sortable_moved', {
						name,
						position: newIndex + 1,
						count
					});

					// Fire all the events that SortableJs does
					if (!moved) {
						moved = true;
						scope.ngSortable.onStart?.(createNgSortableEvent(
							'start', iElement[0], item, oldIndex, index, items[index], items
						));
					}
					scope.ngSortable.onChange?.(createSortableEvent(
						'change', iElement[0], item, oldIndex, index
					));

					if(!forward) {
						refocus(handle, item);
					} else {
						handle.scrollIntoView();
					}
				};

				const onFocusinListener = (event: JQueryEventObject) => {
					if (refocusHack) {
						return;
					}

					// Find the handle. If we aren't given a handle, then use the entire item
					const handle = Sortable.utils.closest(event.target as HTMLElement, scope.ngSortable.handle || '[ng-repeat]', iElement[0]);
					if (handle == null) {
						// But it wasn't on a handle, so abort
						return;
					}

					// If the event originated from a form control, don't tell the screenreader that this is a handle
					if (['INPUT', 'BUTTON', 'SELECT', 'OPTION', 'TEXTAREA'].indexOf(event.target.tagName) !== -1) {
						if (handle === event.target) {
							throw new Error('A form control cannot also be a drag-drop handle!');
						}
						return;
					}

					if (scope.ngSortable.disabled != null) {
						handle.setAttribute('aria-disabled', scope.ngSortable.disabled.toString());
						if (scope.ngSortable.disabled) {
							return;
						}
					} else {
						handle.removeAttribute('aria-disabled');
					}

					lastHandle = handle;

					// Tell aria we support grabbing this handle
					// Currently aria-grab is transitioning to aria-grabbed, so we'll define both
					// 'supported' was changed to 'false' and 'false' was changed to undefined
					handle.setAttribute('aria-grab', 'supported');
					handle.setAttribute('aria-grabbed', 'false');
					handle.setAttribute('aria-dropeffect', 'move');
					ariaLiveElement.textContent = '';
					ariaLiveElement.textContent = $translate.instant('aria-ng-sortable_inform-reorderable');
				};

				const onKeydownListener = (event: JQueryEventObject) => {
					if (scope.ngSortable.disabled) {
						return;
					}

					// If we don't care about the key, don't do the work
					if ([' ', 'Enter'].concat(grabbed ?
						['Escape', 'Tab', 'Home', 'End', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'] :
						[]
					).indexOf(event.key) === -1) {
						return;
					}

					const handle = Sortable.utils.closest(event.target as HTMLElement, scope.ngSortable.handle || '[ng-repeat]', iElement[0]);
					if (handle == null) {
						// But it wasn't on a handle, so abort
						return;
					}

					// If the event originated from a form control, the form control doesn't stopPropogation
					// We should allow native controls to fire (custom controls should stopPropogation themselves)
					if (['INPUT', 'BUTTON', 'SELECT', 'OPTION', 'TEXTAREA'].indexOf(event.target.tagName) !== -1) {
						if (handle === event.target) {
							throw new Error('A form control cannot also be a drag-drop handle!');
						}
						return;
					}

					lastHandle = handle;

					event.stopPropagation();
					event.preventDefault();
					scope.$apply(() => {
						if (event.key === ' ' || event.key === 'Enter') {
							if (grabbed) {
								return drop(handle);
							} else {
								return grab(handle);
							}
						}

						if (event.key === 'Escape') {
							return cancel(handle);
						}

						if (event.key === 'Tab' ||
							event.key === 'ArrowUp' ||
							event.key === 'ArrowDown' ||
							event.key === 'ArrowLeft' ||
							event.key === 'ArrowRight') {

							const forward = event.key === 'ArrowDown'
								|| event.key === 'ArrowRight'
								|| (event.key === 'Tab' && !event.shiftKey);

							return move(handle, forward);
						}

						if (grabbed && (
							event.key === 'Home' ||
							event.key === 'End')) {

							const forward = event.key === 'End';

							return jump(handle, forward);
						}
					});
				};

				const onFocusoutListener = (event: JQueryEventObject) => {
					if (refocusHack || scope.ngSortable.disabled) {
						return;
					}

					const handle = Sortable.utils.closest(event.target as HTMLElement, scope.ngSortable.handle || '[ng-repeat]', iElement[0]);
					if (handle == null) {
						// But it wasn't on a handle, so abort
						return;
					}

					lastHandle = handle;

					if (grabbed) {
						scope.$apply(() => {
							cancel(handle);
						});
					}
				};

				const disabledListener = (disabled: boolean) => {
					if (disabled && grabbed) {
						// no apply since we'll already be in a digest cycle from $watch
						cancel(lastHandle);
						lastHandle.setAttribute('aria-disabled', 'true');
					}
				};

				const onGrabHandleKeyDownListener = (_evt, grabData: GrabData) => {
					grabData.handle.focus();

					const speakerAdditionalText = {
						prepend: grabData.speakerAdditionalText?.prepend,
						append: grabData.speakerAdditionalText?.append
					} as SpeakerAdditionalText;


					grab(grabData.handle, speakerAdditionalText);
				};

				const onGetScopeListener = (_evt, cb) => {
					cb(scope);
				};

				iElement.on('focusin', onFocusinListener);
				iElement.on('keydown', onKeydownListener);
				iElement.on('focusout', onFocusoutListener);
				iElement.on('grabHandle', onGrabHandleKeyDownListener);
				iElement.on('getScope', onGetScopeListener);

				const unregisterDisabled = scope.$watch('ngSortable.disabled', disabledListener);

				scope.$on('$destroy', () => {
					if(scope.ngSortable.speakerId === undefined) {
						const count = parseInt(ariaLiveElement.getAttribute('count'), 10) - 1;
						if (count > 0) {
							ariaLiveElement.setAttribute('count', `${count}`);
						} else {
							ariaLiveElement.remove();
						}
					}

					iElement.off('focusin', onFocusinListener);
					iElement.off('keydown', onKeydownListener);
					iElement.off('focusout', onFocusoutListener);
					iElement.off('grabHandle', onGrabHandleKeyDownListener);
					iElement.off('getScope', onGetScopeListener);
					unregisterDisabled();
				});
			};
		};
	})($ngSortable.compile);
	return $delegate;
};

export const ariaNgSortableDecorator: [string, ng.Injectable<Function>] = [
	'ngSortableDirective',
	ariaNgSortableDecoratorFn
];
