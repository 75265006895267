import * as angular from 'angular';
import { FeedbackBaseModelClass } from '../base/base.factory';
import { clientSettings } from '../common/client.settings';
import { FeedbackNodeModel as FeedbackNodeModelClass } from '../feedback-node/feedback-node.factory';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export interface CommentModelClass extends FeedbackBaseModelClass, FeedbackNodeModelClass {
	directed_at_me: boolean;
	viewed_by_me: boolean;
	resource: any[];
	views: any[];
}

/* @ngInject */
export function CommentModel (
	$q,
	$resource,
	BaseModel,
	FeedbackNodeModel,
	commentModelTransformer,
	commentInterceptorService,
	commentModelSetOwnerService,
	CommentResourceTypes,
	SyncEvent,
	cacheManager
) {
	const baseUrl = `${clientSettings.GoReactV1API}/comments`;

	const commentModel = $resource(baseUrl + '/index/:id', {
		id: '@id',
		sessionId: '@sessionId'
	}, {
		getForSession: {
			method: 'GET',
			transformResponse: commentModelTransformer.response,
			isArray: true,
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/comments`
		},
		save: {
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/comments`,
			params: { session_id: '@session_id' },
			method: 'POST',
			responseType: 'json',
			transformRequest: commentModelTransformer.request,
			transformResponse: commentModelTransformer.response,
			interceptor: commentInterceptorService
		},
		deleteSyncEventComments: {
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/comments/sync-event`,
			method: 'DELETE'
		},
		delete: {
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/comments/:id`,
			method: 'DELETE',
			transformRequest: commentModelTransformer.request
		},
		getViewers: {
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/comments/:comment_id/views`,
			method: 'GET',
			isArray: true
		}
	});

	// extend prototype with base model prototype
	angular.extend(commentModel.prototype, BaseModel.prototype);

	// extend prototype with feedback node model prototype
	// Can't use angular extend because es6 class functions are nonenumerable
	// See https://stackoverflow.com/a/45332959
	const feedbackNodeProperties = Object.getOwnPropertyNames(FeedbackNodeModel.prototype)
		.filter((prop) => !prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/));
	for (const prop of feedbackNodeProperties) {
		const value = Object.getOwnPropertyDescriptor(FeedbackNodeModel.prototype, prop);
		Object.defineProperty(commentModel.prototype, prop, value);
	}

	/**
	 * Creates a new comment instance.
	 *
	 * @param data
	 * @returns {CommentModel}
	 */
	commentModel.newInstance = function (data) {
		let instance = new commentModel(commentModelTransformer.response[1](data || {}));
		// Since FeedbackNodeModel is a class now, its constructor doesn't run
		instance.generation = 0;
		instance.numChildren = instance.numChildren || 0;
		const children = instance.getChildren();
		instance.children = null;
		children.forEach((child) => instance.addChild(commentModel.newInstance(child)));

		// Check cache
		if (instance.getId()) {
			const cachedInstance = commentModel.getCache().get(instance.getId());

			// If instance is already in the cache,
			// the new instance should use that reference
			if (cachedInstance) {
				angular.extend(cachedInstance, instance);
				instance = cachedInstance;
			} else {
				commentModel.getCache().put(instance.getId(), instance);
			}
		}

		if (!angular.isArray(instance.children)) {
			instance.children = [];
		}

		return instance;
	};

	/**
	 * Returns a comment cache manager instance
	 * for interacting with cached comments.
	 *
	 * @returns {CommentModel}
	 */
	commentModel.getCache = function () {
		return cacheManager('comment');
	};

	/**
	 * Get comment reply stub
	 *
	 * @param parentComment
	 * @param user
	 * @returns {CommentModel}
	 */
	commentModel.getReplyStub = function (parentComment, user) {
		const comment = commentModel.newInstance();
		comment.parent_id = parentComment.comment_id;
		comment.thread_id = parentComment.thread_id || parentComment.comment_id;
		comment.session_id = parentComment.session_id;
		comment.created_at = new Date();
		comment.viewed_by_me = true;
		comment.directed_at_me = false;
		comment.time = null; // Make sure replies on non time based comments order correctly

		if (parentComment.time !== null) {
			comment.time = parentComment.time;
		}

		// set owner
		comment.setOwner(user);

		return comment;
	};

	// properties
	commentModel.prototype.id = null;
	commentModel.prototype.comment_id = null;
	commentModel.prototype.parent_id = null;
	commentModel.prototype.thread_id = null;
	commentModel.prototype.session_id = null;
	commentModel.prototype.created_by = null;
	commentModel.prototype.created_at = null;
	commentModel.prototype.text = '';
	commentModel.prototype.time = null;
	commentModel.prototype.kind = 'comment';

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	commentModel.prototype.getId = function () {
		return this.comment_id;
	};

	/**
	 * Whether or not this is saved
	 *
	 * @returns {boolean}
	 */
	commentModel.prototype.isSaved = function () {
		return this.getId() > 0;
	};

	/**
	 * Set owner
	 *
	 * @param user
	 */
	commentModel.prototype.setOwner = function (user) {
		commentModelSetOwnerService(this, user);
	};

	/**
	 * Whether user is owner
	 *
	 * @param user
	 * @returns {boolean}
	 */
	commentModel.prototype.isOwner = function (user) {
		return parseInt(this.created_by, 10) === parseInt(user.user_id, 10);
	};

	/**
	 * Set time
	 *
	 * @param value
	 */
	commentModel.prototype.setTime = function (value) {
		this.time = value;
	};

	/**
	 * Set text
	 *
	 * @param value
	 */
	commentModel.prototype.setText = function (value) {
		this.text = value;
	};

	/**
	 * Set resource
	 *
	 * @param resource
	 */
	commentModel.prototype.setResource = function (resource, type, id) {
		if (!this.resource){
			this.resource = [];
		}

		this.resource.push({
			resource_type: type,
			resource_id: id,
			item: resource
		});

		if (resource.tag_preset_comment != null) {
			this.setText(resource.tag_preset_comment);
		}
	};


	/**
	 * Whether comment is of type 'audio' or 'video'
	 *
	 * @returns {boolean}
	 */
	commentModel.prototype.hasMediaResource = function () {
		if (!this.resource) {
			return false;
		}
		return this.resource.some((res) => {
			return res.resource_type === CommentResourceTypes.MEDIA;
		});
	};

	/**
	 * Whether comment is of type 'tag'
	 *
	 * @returns {boolean}
	 */
	commentModel.prototype.hasMarkerResource = function () {
		if (!this.resource) {
			return false;
		}
		return this.resource.some((res) => {
			return res.resource_type === CommentResourceTypes.TAG;
		});
	};

	/**
	 * Whether comment is of type 'end'
	 *
	 * @returns {boolean}
	 */
	commentModel.prototype.isEndType = function () {
		return this.time === null;
	};

	/**
	 * Whether comment is of type 'sync_event'
	 *
	 * @returns {boolean}
	 */
	commentModel.prototype.isSyncEventType = function () {
		if (!this.resource) {
			return false;
		}
		return this.resource.some((res) => {
			return res.resource_type === CommentResourceTypes.SYNC_EVENT;
		});
	};

	/**
	 * Save comment
	 */
	commentModel.prototype.save = function () {
		const vm = this,
			resourceDeferred = $q.defer(),
			deferred = $q.defer();

		vm.setSaving(true);

		// In the case that the comment resource is a sync event
		if (vm.resource?.[0]?.item instanceof SyncEvent) {
			// Keep the sync event master time synced with the comment time
			vm.resource[0].item.setMasterTime(vm.time);
			// We need to ensure that the resource gets saved
			// first so that the service will return the correct
			// resource when it returns with a response.
			vm.resource[0].item
				.save()
				.then(function () {
					resourceDeferred.resolve();
				})
				.catch(function () {
					resourceDeferred.reject();
				});
		} else {
			resourceDeferred.resolve();
		}

		// After the resource is saved, save the comment
		resourceDeferred.promise
			.then(function () {
				commentModel.save(vm).$promise
					.then(function (data) {
						angular.extend(vm, data);
						deferred.resolve(vm);
					})
					.catch(function (data) {
						deferred.reject(data);
					});
			})
			.catch(function (data) {
				deferred.reject(data);
			});

		deferred.promise
			.finally(function () {
				vm.setSaving(false);
			});

		return deferred.promise;
	};

	/**
	 * Remove this comment
	 *
	 * @returns {object}
	 */
	commentModel.prototype.remove = function () {
		const vm = this;

		if (vm.hasParent()) {
			vm.parent.removeChild(vm);
		}

		return vm;
	};

	/**
	 * Delete this comment without overwriting
	 * it with what is returned in the server response.
	 *
	 * @returns {Promise}
	 */
	commentModel.prototype.delete = function () {
		const vm = this;
		const promise = commentModel.delete({id: this.getId(), session_id: this.session_id}).$promise;

		// If a comment resource is a sync event,
		// delete the sync event as well.
		if (vm.resource?.[0]?.item instanceof SyncEvent) {
			promise.then(function () {
				vm.resource[0].item.$delete();
			});
		}

		return promise;
	};

	/**
	 * Whether comment is deleted
	 *
	 * @returns {boolean}
	 */
	commentModel.prototype.isDeleted = function () {
		return angular.isDate(this.deleted_at);
	};

	return commentModel;
}
CommentModel.NG1_INJECTION_NAME = 'CommentModel' as const;
export const commentModelToken = upgradeNg1Dependency(CommentModel);
