import type { SessionCreationWizardOptions } from '../options';
import type { SessionCreationWizardStep } from './';

export class InitiateStep implements SessionCreationWizardStep {

	/** @ngInject */
	constructor (private $q: ng.IQService) {}

	/**
	 * Run this step
	 */
	public run (_options: SessionCreationWizardOptions): ng.IPromise<void> {
		return this.$q.resolve();
	}

	/**
	 * Determine whether this step is required
	 */
	public isRequired (_options: SessionCreationWizardOptions): boolean {
		return true;
	}
}
