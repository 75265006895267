import * as angular from 'angular';

import { common } from '../common';

import { UseTypeModelFactory } from './use-type.factory';

export const useTypeModel = angular.module('go.models.useType', [
	common
])
	.factory('UseTypeModel', UseTypeModelFactory)
	.name;
