import * as angular from 'angular';
import template from './activity-editor-panel.template.html';

import { ActivityEditorPanelController as controller } from './activity-editor-panel.controller';
import { SidePanelService } from 'go-modules/side-panel/side-panel.service';
import type { GoSidePanelOptions } from 'go-modules/side-panel/side-panel.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class ActivityEditorPanel {
	public static readonly NG1_INJECTION_NAME = 'ActivityEditorPanel';

	/* @ngInject */
	constructor (
		private SidePanel: SidePanelService,
		private $q: ng.IQService,
		private $document: ng.IDocumentService
	) {}

	public open (options) {
		const activityEditorPanelTemplate = template;
		const windowClass: string = 'activity-editor-panel';

		const sidePanelData = {
			template: activityEditorPanelTemplate,
			bindings: {
				options
			},
			resolve: {
				activityTemplates: ['ActivityTemplateModel', (ActivityTemplateModel) => {
					/**
					 * When we're using the activity editor, we always need to
					 * load the conversation activity type. Delete this payload
					 * creation and support for the includeConversation query param
					 * when activity_template->is_public == 1 in the DB.
					 */
					const payload = { includeConversation: true };
					return ActivityTemplateModel.query(payload).$promise;
				}],
				deferredOptions: ['Group', (Group) => {

					const deferred = this.$q.defer();
					Group.get(options.activity.group_id, (group) => {

						deferred.resolve();

						// The rest here can happen after modal opens
						group.getData().then(() => {
							options.uploadLimit = group.upload_limit;
						});

					}, /*fail*/ null, /*ignoreCache*/ false);

					return deferred.promise;
				}]
			},
			controller,
			windowClass,
			keyboard: false,
			backdrop: 'static'
		} as GoSidePanelOptions;

		if (options.libraryMode) {
			const appendToElem = this.$document[0].querySelector('.modal.library-collection-viewer .modal-content') ||
				this.$document[0].querySelector('library-collection-viewer');
			sidePanelData.appendTo = angular.element(appendToElem) as ng.IAugmentedJQuery;
		}

		return this.SidePanel.open(sidePanelData);
	}
}

export const activityEditorPanelToken = upgradeNg1Dependency(ActivityEditorPanel);
