import template from './comment-sync-event-viewer.component.html';
import { FeedbackSessionCommentSyncEventViewer as controller } from './comment-sync-event-viewer.controller';

export const commentSyncEventViewer = {
	template,
	require: {
		mainController: '^feedbackSession',
		contentController: '^feedbackSessionContent'
	},
	controller
};
