import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { DataTransformer } from 'go-modules/models/common/data-transformer';
import type { CourseFolder } from './interfaces/course-folder.interface';
import { Observable } from 'rxjs';
import { CurrentFolderListParams } from '../folder-list-datasource/folder-list-datasource';
import { FolderStats } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';

export interface FolderStatsResponse {
	[name: string]: FolderStats;
}

@Injectable({
	providedIn: 'root'
})
export class NgxCourseService {
	constructor (private http: HttpClient) {}

	public getCourses (
		orgId: number,
		params: CurrentFolderListParams = {} as CurrentFolderListParams
	): Observable<CourseFolder[]> {
		let url = `${clientSettings.GoReactV2API}/orgs/${orgId}/folders`;
		const searchParams = new URLSearchParams();

		if (Object.keys(params).length) {
			Object.keys(params).forEach((key) => {
				if (params[key] !== null) {
					searchParams.append(key, params[key]);
				}
			});
			url += `?${searchParams.toString()}`;
		}

		return this.http.get<CourseFolder[]>(url)
			.pipe(map((response: CourseFolder[]) => {
				response.forEach((data) => {
					if (data.end_date) {
						data.end_date = DataTransformer.transformFromDate(data, 'end_date');
					}
				});

				return response;
			}));
	}

	public getFolderStats (
		orgId: number,
		params: CurrentFolderListParams = {} as CurrentFolderListParams
	): Observable<FolderStatsResponse> {
		let url = `${clientSettings.GoReactV2API}/orgs/${orgId}/folders/stats`;
		const searchParams = new URLSearchParams();

		if (Object.keys(params).length) {
			Object.keys(params).forEach((key) => {
				if (params[key] !== null) {
					searchParams.append(key, params[key]);
				}
			});
			url += `?${searchParams.toString()}`;
		}

		return this.http.get<FolderStatsResponse>(url);
	}

	public getCourse (groupId: number): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/courses/${groupId}`)
			.pipe(map((course: any) => {
				if (course.license) {
					['starts_at', 'ends_at'].forEach((prop: string) => course.license[prop] = DataTransformer.transformToDate(course.license, prop));
				}

				return course;
			}));
	}

	public saveCourse (payload: any): Observable<any> {
		if (payload.group_id > 0) {
			return this.updateCourse(payload);
		}

		return this.createCourse(payload);
	}

	public createCourse (payload: any): Observable<any> {
		return this.http.post(
			`${clientSettings.GoReactV2API}/accounts/${payload.parent_id}/courses`,
			payload
		);
	}

	public updateCourse (payload: any): Observable<any> {
		return this.http.put(
			`${clientSettings.GoReactV2API}/courses/${payload.group_id}`,
			payload
		);
	}

	public updateCourseLicense (courseId: number): Observable<any> {
		return this.http.patch(`${clientSettings.GoReactV2API}/courses/${courseId}/license`, {});
	}

	public archiveCourse (courseId: number): Observable<any> {
		return this.http.patch(`${clientSettings.GoReactV2API}/courses/${courseId}/archive`, {});
	}

	public restoreCourse (courseId: number): Observable<any> {
		return this.http.patch(`${clientSettings.GoReactV2API}/courses/${courseId}/restore`, {});
	}

	public deleteCourse (courseId: number): Observable<any> {
		return this.http.delete(`${clientSettings.GoReactV2API}/folders/${courseId}`);
	}

	public getCourseLicense (courseId: number): Observable<void> {
		return this.http.get<void>(`${clientSettings.GoReactV2API}/courses/${courseId}/license`);
	}
}
