export class VolumeIndicatorHelper {

	public generateAudioWorkletProcessor () {
		return (`
			let maxLevel_L = 0.0;
			let oldLevel_L = 0.0;

			registerProcessor('audio-processor', class extends AudioWorkletProcessor {
			
				_volume
				_updateIntervalInMS
				_nextUpdateFrame
				
				constructor () {
					super();
					this._volume = 0;
					this._updateIntervalInMS = 25;
					this._nextUpdateFrame = this._updateIntervalInMS;
					this.port.onmessage = event => {
					if (event.data.updateIntervalInMS)
						this._updateIntervalInMS = event.data.updateIntervalInMS;
					}
				}
				
				get intervalInFrames () {
					return this._updateIntervalInMS / 1000 * sampleRate;
				}
				
				process (inputs, outputs, parameters) {
					const input = inputs[0];
				
					// Note that the input will be down-mixed to mono
					// however, if no inputs are connected then zero channels will be passed in
					if (input.length > 0) {
						const inputs = input[0];
						let sum = 0.0;
						let rms = 0.0;
					
						// Calculate the squared-sum
						for (let i = 0; i < inputs.length; ++i)
							sum += inputs[i] * inputs[i];
					
						// Calculate the RMS level and update the volume
						rms = Math.sqrt(sum / inputs.length);
						maxLevel_L = Math.max(maxLevel_L, rms);
						rms = Math.max(rms, oldLevel_L - 0.008);
						oldLevel_L = rms;
						this._volume = rms / maxLevel_L;

						// Update and sync the volume property with the main thread
						this._nextUpdateFrame -= inputs.length;
						if (this._nextUpdateFrame < 0) {
							this._nextUpdateFrame += this.intervalInFrames;
							this.port.postMessage({volume: this._volume});
						}
					}
					
					return true;
				}
			});
		`);
	}
}
