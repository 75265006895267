import { EventEmitter } from 'events';

export abstract class TimerBase extends EventEmitter {
	/**
	 * This method is an alias for `emit` and is only supported for backwards functionality.
	 */
	public trigger (event: string | symbol, ...args: any[]): boolean {
		return this.emit(event, ...args);
	}

	/**
	 * This method is a combination of `removeAllListeners` and `removeListener` and is
	 * only supported for backwards functionality
	 */
	public off (event?: string | symbol, listener: (...args: any[]) => void = null): this {
		if (!listener) {
			return this.removeAllListeners(event);
		}
		return this.removeListener(event, listener);
	}

	public destroy (): void {
		this.removeAllListeners();
	}

	public abstract start (): void;
	public abstract pause (): void;
	public abstract isPaused (): boolean;
	public abstract isComplete (): boolean;
	public abstract resume (): void;
	public abstract stop (): void;
	public abstract setTime (value: number): void;
	public abstract getTime (): number;
	public abstract getDuration (): number;
}
