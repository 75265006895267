import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { mediaPreviewModal, mediaPreviewToken } from 'go-modules/modals/media-preview/modal.factory';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { Types } from 'ngx/go-modules/src/services/media/media-types';
import { NgxMediaService } from 'ngx/go-modules/src/services/media/media.service';
import { filter } from 'rxjs';

@Component({
	selector: 'ngx-media-thumbnail',
	template: require('./media-thumbnail.component.html'),
	styles: [require('./media-thumbnail.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class NgxMediaThumbnailComponent implements OnInit, OnDestroy {
	@Input() public media: any;
	@Output() public removeEvent: EventEmitter<any> = new EventEmitter();
	public type: any;
	private eventSubscription;

	constructor (
		@Inject(mediaPreviewToken) private MediaPreviewModal: ReturnType<typeof mediaPreviewModal>,
		private mediaService: NgxMediaService,
		private eventService: EventService,
		private cdr: ChangeDetectorRef
	) {}

	public ngOnInit (): void {
		// if no filename then it should be YouTube
		this.type = this.media.filename ? this.mediaService.determineMediaType(this.media.filename) : Types.VIDEO;
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				if (parseInt(this.media.media_id, 10) === parseInt(ev.data.media_id, 10)) {
					Object.assign(this.media, ev.data);
					this.cdr.detectChanges();
				}
			});
	}
	public ngOnDestroy (): void {
		this.eventSubscription.unsubscribe();
	}

	public openMediaPreview () {
		this.MediaPreviewModal.open({
			modalData: {
				media: this.media,
				edit: false,
				allowDownload: false
			}
		});
	}
}
