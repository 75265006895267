import { IFilterService } from 'angular';
import { Action } from './action';
import { IconClass, ActionToIconClassMap } from './icon-class';
import { TranslationKeyMap } from './translation';
import { ToolbarController } from '../toolbar.controller';

export interface Bindings {
	action: Action;
	handler: () => void;
	disabled: string;
	toolbarController: ToolbarController;
	ariaLabel: string;
	ariaLabelledby: string;
}

export class ToolbarButtonController implements Bindings {

	// Bindings
	public action: Action;
	public disabled: string;
	public handler: () => void;
	public toolbarController: ToolbarController;
	public ariaLabel: string;
	public ariaLabelledby: string;
	public iconClass: IconClass;

	/* @ngInject */
	constructor (
		private $filter: IFilterService,
		private $element: ng.IAugmentedJQuery,
		private $timeout
	) {}

	public $onInit (): void {
		if (!this.action) {
			throw new Error('Property binding `action` is required!');
		} else if (!ActionToIconClassMap[this.action]) {
			throw new Error('Property binding `action` is invalid!');
		}

		this.iconClass = ActionToIconClassMap[this.action];

		if (!this.ariaLabelledby) {
			this.$timeout(() => this.$element[0].firstElementChild.removeAttribute('aria-labelledby'), 100);
		};

		if (!this.ariaLabel) {
			this.ariaLabel = this.$filter('translate')(TranslationKeyMap[this.action]);
		};
	}

	public getTooltipText (): string {
		return this.$filter('translate')(TranslationKeyMap[this.action]);
	}

	public isDisabled (): boolean {
		return !!this.disabled || this.toolbarController.isDisabled();
	}
}
