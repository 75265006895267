import * as angular from 'angular';
import { userModel } from 'go-modules/models/user';
import { authServiceModule } from 'go-modules/services/auth';
import { sessionManager } from '../session-manager';
import { Masquerade } from './masquerade.service';

export const masqueradeModule = angular.module('go.masquerade', [
	authServiceModule,
	sessionManager,
	userModel
])
	.service(Masquerade.NG1_INJECTION_NAME, Masquerade)
	.name;
