import template from './label-input.directive.html';
import { LabelInputController } from './label-input.controller';

export function labelInput () {
	return {
		restrict: 'A',
		require: 'ngModel',
		template,
		transclude: true,
		replace: true,
		controller: LabelInputController,
		controllerAs: 'labelInputController',
		bindToController: true,
		scope: {
			value: '=ngModel',
			type: '@labelInput',
			onInputChange: '&labelInputChange',
			selectAll: '=labelInputSelectAll',
			format: '@labelInputFormat',
			ariaLabel: '@',
			fractional: '<'
		},
		link (scope, _element, attr) {
			attr.$observe('disabled', function (value) {
				scope.labelInputController.toggleDisabled(value);
			});
		}
	};
}
