import { StateEmitter } from '../state-emitter/state-emitter';
import { DrawDimensions, GoScene } from '../go-scene/go-scene';

enum PositionPreset {
	FULL = 'FULL',
	BR = 'BR',
	TWOTHIRD = 'TWOTHIRD',
	ONETHIRD = 'ONETHIRD',
	LEFTHALF = 'LEFTHALF',
	RIGHTHALF = 'RIGHTHALF',
	TOPHALFCENTERED = 'TOPHALFCENTERED',
	TOPLEFTHALF = 'TOPLEFTHALF',
	TOPRIGHTHALF = 'TOPRIGHTHALF',
	BOTTOMLEFTHALF = 'BOTTOMLEFTHALF',
	BOTTOMRIGHTHALF = 'BOTTOMRIGHTHALF',
	CUSTOM = 'CUSTOM'
}

enum ErrorMessage {
	PERMISSION_DENIED = 'Permission denied',
	NOT_ALLOWED = 'NotAllowedError'
}

export interface Position {
	width?: number;
	height?: number;
	resourceWidth?: number;
	resourceHeight?: number;
	x?: number;
	y?: number;
	halign?: 'left' | 'center' | 'right';
	valign?: 'top' | 'middle' | 'bottom';
	maxOverscanX?: number;
	maxOverscanY?: number;
}

const PositionMap: Partial<Record<PositionPreset, Position>> = {
	[PositionPreset.FULL]: { x: 0, y: 0, width: 100, height: 100, halign: 'center', valign: 'middle'},
	[PositionPreset.BR]: { x: 74, y: 74, width: 25, height: 25},
	[PositionPreset.TWOTHIRD]: { x: 100/3, y: 0, width: 100*2/3, height: 100, halign: 'left', valign: 'middle'},
	[PositionPreset.ONETHIRD]: { x: 0, y: 0, width: 100/3, height: 100, halign: 'right', valign: 'middle'},
	[PositionPreset.LEFTHALF]: { x: 0, y: 0, width: 100/2, height: 100, halign: 'right', valign: 'middle'},
	[PositionPreset.RIGHTHALF]: { x: 100/2, y: 0, width: 100/2, height: 100, halign: 'left', valign: 'middle'},
	[PositionPreset.TOPHALFCENTERED]: { x: 100/3, y: 0, width: 100/2, height: 100/2, halign: 'left', valign: 'top'},
	[PositionPreset.TOPLEFTHALF]: { x: 0, y: 0, width: 100/2, height: 100/2, halign: 'right', valign: 'top'},
	[PositionPreset.TOPRIGHTHALF]: { x: 100/2, y: 0, width: 100/2, height: 100/2, halign: 'left', valign: 'top'},
	[PositionPreset.BOTTOMLEFTHALF]: { x: 0, y: 100/2, width: 100/2, height: 100/2, halign: 'right', valign: 'bottom'},
	[PositionPreset.BOTTOMRIGHTHALF]: { x: 100/2, y: 100/2, width: 100/2, height: 100/2, halign: 'left', valign: 'bottom'}
};

export interface GoSourceConfig {
	resource?: string;
	position?: PositionPreset | Position;
	appendElement?: HTMLElement;
	width?: number;
	height?: number;
	muted?: boolean;
}

export interface SourceInitOptions {
	continueWithoutAudio?: boolean;
}

export abstract class GoSource extends StateEmitter {
	public static readonly POSITIONS = PositionPreset;
	public static readonly ERRORS = ErrorMessage;

	public resource?: string;
	public displayName?: {firstName: string; lastName: string};

	private _position: PositionPreset | Position;
	private _width: number = 0;
	private _height: number = 0;
	private _resourceWidth: number = 0;
	private _resourceHeight: number = 0;
	private _x: number = 0;
	private _y: number = 0;
	private _halign: 'left' | 'center' | 'right' = 'center';
	private _valign: 'top' | 'middle' | 'bottom' = 'middle';
	private _maxOverscanX: number = 1;
	private _maxOverscanY: number = 1;

	constructor (config: GoSourceConfig) {
		super();
		this.resource = config?.resource;
		this._position = config?.position || GoSource.POSITIONS.FULL;

		this.setPosition(this._position);
	}

	public setActive (active: boolean): void {
		this.setState(active ? GoSource.STATES.ACTIVE : GoSource.STATES.INACTIVE);
	}

	public destroy (): void {
		if (this.state === GoSource.STATES.DESTROYED) return;
		super.destroy();
	}

	public get active (): boolean {
		return this.state === GoSource.STATES.ACTIVE;
	}

	public get position (): PositionPreset | Position {
		return this._position;
	}

	public get width (): number {
		return this._width;
	}

	public get height (): number {
		return this._height;
	}

	public get resourceWidth (): number {
		return this._resourceWidth;
	}

	public get resourceHeight (): number {
		return this._resourceHeight;
	}

	public get x (): number {
		return this._x;
	}

	public get y (): number {
		return this._y;
	}

	public get halign (): 'left' | 'center' | 'right' {
		return this._halign;
	}

	public get valign (): 'top' | 'middle' | 'bottom' {
		return this._valign;
	}

	public get maxOverscanX (): number {
		return this._maxOverscanX;
	}

	public get maxOverscanY (): number {
		return this._maxOverscanY;
	}

	public setPosition (preset: PositionPreset | Position): void {
		let positionValues;
		if (this.isPreset(preset)) {
			positionValues = PositionMap[preset];
			this._position = preset;
		} else {
			positionValues = preset;
			this._position = PositionPreset.CUSTOM;
		}
		this._width = positionValues.width ?? this._width;
		this._height = positionValues.height ?? this._height;
		this._resourceWidth = positionValues.resourceWidth ?? this._resourceWidth;
		this._resourceHeight = positionValues.resourceHeight ?? this._resourceHeight;
		this._x = positionValues.x ?? this._x;
		this._y = positionValues.y ?? this._y;
		this._halign = positionValues.halign ?? this._halign;
		this._valign = positionValues.valign ?? this._valign;
		this._maxOverscanX = positionValues.maxOverscanX ?? this._maxOverscanX;
		this._maxOverscanY = positionValues.maxOverscanY ?? this._maxOverscanY;
	}

	private isPreset (preset: PositionPreset | Position): preset is PositionPreset {
		return preset as PositionPreset in PositionMap;
	}

	public abstract init (options?: SourceInitOptions): Promise<void>;
	public abstract test (): Promise<boolean>;
	public abstract render (scene: GoScene, container?: DrawDimensions): void;
}
