import template from './rubric.directive.html';
import { RubricController as controller } from './rubric.controller';

/* @ngInject */
export function rubricDirective () {
	return {
		restrict: 'AE',
		scope: {
			options: '='
		},
		replace: true,
		template,
		controller,
		controllerAs: 'rubricController'
	};
}
