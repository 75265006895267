export const datetimepickerTimepickerDirective = (): ng.IDirective => {
	return {
		restrict: 'EA',
		require: 'ngModel',
		templateUrl: 'datetimepicker/datetimepicker-timepicker.directive.html',
		replace: false,
		controller () {},
		controllerAs: 'controller',
		scope: {
			date: '=ngModel',
			options: '=options'
		},
		bindToController: true
	};
};
