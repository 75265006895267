import 'form-request-submit-polyfill';

/* @ngInject */
export function formSubmitDirective ($timeout: ng.ITimeoutService) {
	return {
		require: 'form',
		restrict: 'E',
		link: function link (_scope, elem, _attrs, form) {
			form.$submit = function $submit () {
				// We can't trigger submit immediately, or we get
				// $digest already in progress error :-[ (because ng-submit does an $apply of its own)
				// https://stackoverflow.com/a/11837152/1248889
				$timeout(() => elem[0].requestSubmit());
			};
		}
	};
}
