type fetchFn = typeof window['fetch'];

interface FetchInterceptorConfig {
	request: (...args: Parameters<fetchFn>) => Parameters<fetchFn>;
}

class FetchInterceptorUtil {

	private interceptors: FetchInterceptorConfig[] = [];
	private oldFetch;

	public install () {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;
		this.oldFetch = this.oldFetch || window.fetch;
		window.fetch = (function (fetchFn: fetchFn) {
			return function (...args: Parameters<fetchFn>) {
				return self.interceptFetch(fetchFn, ...args);
			};
		})(window.fetch);
	}

	public uninstall () {
		window.fetch = this.oldFetch || window.fetch;
	}

	public register (interceptor: FetchInterceptorConfig): void {
		this.interceptors.unshift(interceptor);
	}

	private async interceptFetch (fetchFn: fetchFn, ...args: Parameters<fetchFn>) {
		for (const interceptor of this.interceptors) {
			args = interceptor.request(...args);
		}
		return fetchFn(...args);
	}
}

export const FetchInterceptor = new FetchInterceptorUtil();
