/* @ngInject */
export function timerDirective (Timer, Time) {
	return {
		restrict: 'A',
		link (scope, element, attrs) {

			// the timer
			const timer = Timer.get(attrs.id);
			timer.on('time', function (time) {
				element.text(Time.formatTime(time));
			});

			// set initial text
			element.text(Time.formatTime(timer.getTime()));

			// watch for input change
			scope.$watch(attrs.uiTimer, function (value) {
				if (value) {
					timer.start();
				} else {
					timer.pause();
				}
			});
		}
	};
}
