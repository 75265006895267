import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Observable } from 'rxjs';
import type { Product } from 'go-modules/services/group/product';
import type { LicensePurchasePayload } from './license-purchase-payload';
import type { Transaction } from 'go-modules/braintree/braintree.service';
import type { LicensePurchaseStatus } from './license-purchase-status';
import type { LicensePurchaseTransactionKey } from 'ngx/go-modules/src/interfaces/selfpay/license-purchase-transaction-key';

@Injectable({
	providedIn: 'root'
})
export class SelfPayService {
	constructor (private http: HttpClient) {}

	public getProducts (orgId: number): Observable<Product[]> {
		return this.http.get<Product[]>(`${clientSettings.GoReactV2API}/orgs/${orgId}/self-pay/products`);
	}

	public purchaseLicense (groupId: number, payload: LicensePurchasePayload): Observable<Transaction> {
		return this.http.post<Transaction>(`${clientSettings.GoReactV2API}/self-pay/${groupId}/licenses`, payload);
	}

	public upgradeLicense (groupId: number, licenseId: number, payload): Observable<Transaction> {
		return this.http.patch<Transaction>(`${clientSettings.GoReactV2API}/self-pay/${groupId}/licenses/${licenseId}`, payload);
	}

	public getUserOrgsEligibleForSelfpay (userId: number): Observable<any> {
		return this.http.get<any>(`${clientSettings.GoReactV2API}/self-pay/users/${userId}/eligible-orgs`);
	}

	public generateLicensePurchaseTransactionKey (groupId: number): Observable<LicensePurchaseTransactionKey> {
		return this.http.get<LicensePurchaseTransactionKey>(`${clientSettings.GoReactV2API}/self-pay/${groupId}/licenses/purchase-transaction-key`);
	}

	public getPurchaseLicenseStatus (
		groupId: number,
		transactionKey: LicensePurchaseTransactionKey
	): Observable<LicensePurchaseStatus> {
		const payload = Object.keys(transactionKey).map((key) => `${key}=${transactionKey[key]}`).join('&');
		return this.http.get<LicensePurchaseStatus>(`${clientSettings.GoReactV2API}/self-pay/${groupId}/licenses/purchase-status?${payload}`);
	}

	public requestInvoice (groupId: number, payload): Observable<any> {
		return this.http.post(`${clientSettings.GoReactV2API}/self-pay/${groupId}/licenses/invoice-request`, payload);
	}

	public requestBeta (groupId: number): Observable<HttpResponse<void>> {
		return this.http.post<HttpResponse<void>>(`${clientSettings.GoReactV2API}/self-pay/${groupId}/licenses/request-beta`, {});
	}
}
