/**
 * Convert number of bytes into human readable format
 *
 * @param integer bytes     Number of bytes to convert
 * @param integer precision Number of digits after the decimal separator
 * @return string
 */
export function bytesToSizeFilter () {
	return function (bytes, precision) {
		const kilobyte = 1024;
		const megabyte = kilobyte * 1024;
		const gigabyte = megabyte * 1024;
		const terabyte = gigabyte * 1024;

		if (bytes >= 0 && bytes < kilobyte) {
			return bytes + 'B';

		} else if (bytes >= kilobyte && bytes < megabyte) {
			return (bytes / kilobyte).toFixed(precision) + 'KB';

		} else if (bytes >= megabyte && bytes < gigabyte) {
			return (bytes / megabyte).toFixed(precision) + 'MB';

		} else if (bytes >= gigabyte && bytes < terabyte) {
			return (bytes / gigabyte).toFixed(precision) + 'GB';

		} else if (bytes >= terabyte) {
			return (bytes / terabyte).toFixed(precision) + 'TB';
		}

		return bytes + ' B';
	};
}
