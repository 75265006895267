import * as angular from 'angular';

import { goPdfModule } from '../go-pdf';
import { mediaPlayerModule } from '../media-player';
import { playerSyncModule } from '../player-sync';

import { mediaModel } from '../models/media';
import { MediaPreviewComponentDef } from './media-preview.component';
import { AudioDescriptionComponentDef } from './audio-description/audio-description.component';
import './style.less';

export const mediaPreviewModule = angular.module('go.media-preview', [
	mediaModel,
	goPdfModule,
	mediaPlayerModule,
	playerSyncModule
])
	.component('mediaPreview', MediaPreviewComponentDef)
	.component('audioDescription', AudioDescriptionComponentDef)
	.name;
