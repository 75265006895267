import * as angular from 'angular';
import { FeedbackBaseModelClass } from 'go-modules/models/base/base.factory';
import { CommentModelClass } from 'go-modules/models/comment/comment.factory';

export enum Kind {
	COMMENT = 'comment',
	RUBRIC = 'rubric',
	SLIDE_TIMINGS = 'slide-timings'
}

export enum FeedbackTabs {
	COMMENT = 'comment',
	RUBRIC = 'rubric',
	INFORMATION = 'information',
	TRANSCRIPTION = 'transcription',
	ANALYTICS = 'analytics'
}

export declare class SessionFeedbackClass {
	public static KIND: typeof Kind;
	public static KINDS: Kind[];
	public static TAB: typeof FeedbackTabs;
	public static TABS: FeedbackTabs[];
	public session: any;
	public user: any;
	public comment: any;
	constructor (session, user);

	public getFeedbackByKind (kind): FeedbackBaseModelClass[];
	// TODO: The next three should be of their respective types
	public getComments (): CommentModelClass[];
	public getRubrics (): FeedbackBaseModelClass[];
	public add (feedbackItem: FeedbackBaseModelClass): void;
	public remove (feedbackItem: FeedbackBaseModelClass): void;
	public save (feedbackItem: FeedbackBaseModelClass): ng.IPromise<FeedbackBaseModelClass>;
	public delete (feedbackItem: FeedbackBaseModelClass): ng.IPromise<FeedbackBaseModelClass>;
	public hasFeedback (): boolean;
	public get (): ng.IPromise<{[type: string]: FeedbackBaseModelClass[]}>;
	public getFeedbackByUser (user, kind): FeedbackBaseModelClass[];
}

/* @ngInject */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SessionFeedback = function (Session, CommentModel, RubricSessionModel, $q) {

	// Feedback kind mappings
	sessionFeedback.KIND = Kind;

	// Feedback kinds
	sessionFeedback.KINDS = [
		sessionFeedback.KIND.COMMENT,
		sessionFeedback.KIND.RUBRIC,
		sessionFeedback.KIND.SLIDE_TIMINGS
	];

	// Feedback tab mappings
	sessionFeedback.TAB = FeedbackTabs;

	// Feedback tabs
	sessionFeedback.TABS = [
		sessionFeedback.TAB.COMMENT,
		sessionFeedback.TAB.RUBRIC,
		sessionFeedback.TAB.INFORMATION,
		sessionFeedback.TAB.TRANSCRIPTION,
		sessionFeedback.TAB.ANALYTICS
	];

	/**
	 * Constructor
	 *
	 * @param session
	 * @param user
	 * @constructor
	 */
	function sessionFeedback (this: SessionFeedbackClass, session, user) {
		if (!session) {
			throw new Error('Required param session was not supplied');
		}
		if (!user) {
			throw new Error('Required param user was not supplied');
		}

		this.session = session;
		this.user = user;
		this[sessionFeedback.KIND.COMMENT] = CommentModel.newInstance();
		this[sessionFeedback.KIND.RUBRIC] = RubricSessionModel.newInstance();
	}

	/**
	 * Get feedback by kind
	 *
	 * @returns {Array}
	 */
	 sessionFeedback.prototype.getFeedbackByKind = function (this: SessionFeedbackClass, kind) {
		return this[kind].children;
	};

	/**
	 * Get all comments
	 *
	 * @returns {Array}
	 */
	sessionFeedback.prototype.getComments = function (this: SessionFeedbackClass) {
		return this.getFeedbackByKind(sessionFeedback.KIND.COMMENT) as CommentModelClass[];
	};

	/**
	 * Get all rubrics
	 *
	 * @returns {Array}
	 */
	sessionFeedback.prototype.getRubrics = function (this: SessionFeedbackClass) {
		return this.getFeedbackByKind(sessionFeedback.KIND.RUBRIC);
	};

	/**
	 * Add feedback item
	 *
	 * @param feedbackItem
	 */
	sessionFeedback.prototype.add = function (this: SessionFeedbackClass, feedbackItem) {
		// Set additional info
		feedbackItem.session_id = this.session.getId();
		feedbackItem.created_at = feedbackItem.created_at || new Date();

		// When no creator is specified, default to current user
		if (!feedbackItem.created_by ||
			parseInt(feedbackItem.created_by, 10) === parseInt(this.user.getId(), 10)) {
			feedbackItem.setOwner(this.user);
		}

		// Add item
		const rootFeedbackItem = this[feedbackItem.kind];
		if (!rootFeedbackItem.hasChild(feedbackItem)) {
			rootFeedbackItem.addChild(feedbackItem);
		}
	};

	/**
	 * Remove feedback item
	 *
	 * @param feedbackItem
	 */
	sessionFeedback.prototype.remove = function (this: SessionFeedbackClass, feedbackItem) {
		const rootFeedbackItem = this[feedbackItem.kind];
		if (rootFeedbackItem.hasChild(feedbackItem)) {
			rootFeedbackItem.removeChild(feedbackItem);
		}
	};

	/**
	 * Save feedback item
	 *
	 * @param feedbackItem
	 * @param params
	 * @returns Promise
	 */
	sessionFeedback.prototype.save = function (feedbackItem) {
		// Set additional info
		feedbackItem.session_id = this.session.getId();
		feedbackItem.group_id = this.session.group_id;

		return feedbackItem.save();
	};

	/**
	 * Delete feedback item
	 *
	 * @param feedbackItem
	 * @returns Promise
	 */
	sessionFeedback.prototype.delete = function (feedbackItem) {
		return feedbackItem.$delete();
	};

	/**
	 * Whether there is any feedback at all
	 *
	 * @returns {boolean}
	 */
	sessionFeedback.prototype.hasFeedback = function (this: SessionFeedbackClass) {
		return this.getFeedbackByKind(sessionFeedback.KIND.COMMENT).filter(function (feedbackItem) {
			// SyncEvent doesn't count as feedback
			return feedbackItem.isSaved() && !(feedbackItem as any).isSyncEventType();
		}).length > 0 ||

			this.getFeedbackByKind(sessionFeedback.KIND.RUBRIC).filter(function (feedbackItem) {
				return feedbackItem.isSaved();
			}).length > 0;
	};

	/**
	 * Get all session feedback
	 *
	 * @returns {Object}
	 */
	sessionFeedback.prototype.get = function (this: SessionFeedbackClass) {
		const rubrics = RubricSessionModel.getForSession({
			session_id: this.session.getId()
		}).$promise.then((result) => {
			this[sessionFeedback.KIND.RUBRIC].setChildren(result);
			return result;
		});

		const comments = CommentModel.getForSession({
			session_id: this.session.getId()
		}).$promise.then((result) => {
			// We need to say new instance on all of these to make sure nested
			// children get modelized
			this[sessionFeedback.KIND.COMMENT].setChildren(result.map((item) => CommentModel.newInstance(item)));
			return result;
		});

		return $q.all({
			rubric: rubrics,
			comment: comments
		});
	};

	/**
	 * Get feedback by user
	 *
	 * @param user
	 * @param kind
	 * @returns {Array}
	 */
	sessionFeedback.prototype.getFeedbackByUser = function (this: SessionFeedbackClass, user, kind) {
		const feedbackByUser = [];
		angular.forEach(this.getFeedbackByKind(kind), function (feedbackItem) {
			if (feedbackItem.isOwner(user)) {
				feedbackByUser.push(feedbackItem);
			}
		});
		return feedbackByUser;
	};

	return sessionFeedback;
};
