import * as angular from 'angular';

/* @ngInject */
export function activityModelInterceptor ($q, cacheManager) {

	/**
	 * Check cache
	 *
	 * @param resource
	 * @returns {Object}
	 */
	function cache (resource) {
		if (resource.getId()) {
			let cachedInstance = cacheManager('activity').get(resource.getId());
			if (cachedInstance) {
				angular.extend(cachedInstance, resource);
			} else {
				cachedInstance = resource;
				cacheManager('activity').put(resource.getId(), resource);
			}
			return cachedInstance;
		}
		return resource;
	}

	return {

		// response method
		response (response) {

			// check cache for resource
			if (angular.isArray(response.resource)) {
				angular.forEach(response.resource, function (resource, index) {
					response.resource[index] = cache(resource);
				});
			} else {
				response.resource = cache(response.resource);
			}

			return response.resource;
		},

		// response error method
		responseError (rejection) {
			return $q.reject(rejection);
		}
	};
}
