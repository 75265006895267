export class LiveHelper {

	/**
	 * Constructor
	 */
	constructor (private session: any) {}

	/**
	 * Whether live stream playback is advised.
	 *
	 * When the recorder's ip address matches the current user's ip address,
	 * live playback is not advised (since they may be in the same room).
	 */
	public isLiveStreamPlaybackAdvised (): boolean {
		return !!this.session.recording_user_ip &&
			this.session.recording_user_ip !== this.session.settings.user_ip;
	}
}
