import { DBSchema } from 'idb';

export interface UploadManagerDB extends DBSchema {
	uploads: {
		value: Upload;
		key: string;
		indexes: { 'mediaId': number };
	};
	parts: {
		value: Part;
		key: string;
		indexes: { 'uploadId': number };
	};
}

export interface Upload {
	id: number;
	mediaId: number;
	format: string;
	groupId: number;
	createdAt: Date;
	doneAddingParts?: Date;
	completedAt?: Date;
	reportedAt?: Date;
};

export interface Part {
	id?: number; // Auto created by IndexDB
	remoteId?: number; // V2 part id returned from signing the part
	uploadId: number; // passed in uploadId
	blob: Blob; // part of the data for the file to be uploaded
	partNumber: number; // will be updated with the returned value from V2
	signedUrl?: string; // will be updated with the returned value from V2
	etag?: string; // set once blob has been saved on S3
	size?: number; // size of the blob
	createdAt: Date; // set at the time of the part creation locally
	signedAt?: Date;
	uploadStartedAt?: Date; // set when the upload begins
	uploadedCompletedAt?: Date; // set once the blob has been saved on S3 and the etag has been returned
	reportedAt?: Date; // set once eTag has been saved to V2
}

export enum Status {
	UPLOADING,
	COMPLETE
}

export interface UploadStatus {
	uploadId: number;
	status: Status;
	progress: number;
	totalPart: number;
	uploadedParts: number;
	allPartsAdded?: Date;
}
