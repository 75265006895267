import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { feedbackNode } from '../feedback-node';
import { base } from '../base';

import { RubricSessionModel } from './rubric-session.factory';
import { RubricSessionDataTransformerService } from './rubric-session-transformer.service';
import { groupDepModel } from '../group-dep';

export const rubricSessionModel = angular.module('go.models.rubricSession', [
	angularResource,
	feedbackNode,
	groupDepModel,
	base
])
	.factory('RubricSessionModel', RubricSessionModel)
	.service('rubricSessionDataTransformerService', RubricSessionDataTransformerService)
	.name;
