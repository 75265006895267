import {
	TranscriptScrollStrategy
} from 'ngx/go-modules/src/components/feedback-session/transcript-viewer/transcript-scroller/transcript-scroll-strategy';

/**
 * Factory class to create and retrieve a scroll strategy
 */
export class TranscriptScrollStrategyFactory {

	public static create (isMultiSpeaker: boolean): TranscriptScrollStrategy {
		return new TranscriptScrollStrategy(isMultiSpeaker);
	}
}
