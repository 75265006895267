import activityUserActivityHistoryItemTemplate from './item-renderer/templates/activity.html';
import commentUserActivityHistoryItemTemplate from './item-renderer/templates/comment.html';
import courseUserActivityHistoryItemTemplate from './item-renderer/templates/course.html';
import mediaRecordingUserActivityHistoryItemTemplate from './item-renderer/templates/media_recording.html';
import mediaUploadUserActivityHistoryItemTemplate from './item-renderer/templates/media_upload.html';
import sessionUserActivityHistoryItemTemplate from './item-renderer/templates/session.html';
import transactionUserActivityHistoryItemTemplate from './item-renderer/templates/transaction.html';
import userLoginUserActivityHistoryItemTemplate from './item-renderer/templates/user_login.html';
import userRoleUserActivityHistoryItemTemplate from './item-renderer/templates/user_role.html';

/* @ngInject */
export const templates = function ($templateCache) {
	$templateCache.put('user-activity-history/item-renderer/templates/activity.html', activityUserActivityHistoryItemTemplate);
	$templateCache.put('user-activity-history/item-renderer/templates/comment.html', commentUserActivityHistoryItemTemplate);
	$templateCache.put('user-activity-history/item-renderer/templates/course.html', courseUserActivityHistoryItemTemplate);
	$templateCache.put('user-activity-history/item-renderer/templates/media_recording.html', mediaRecordingUserActivityHistoryItemTemplate);
	$templateCache.put('user-activity-history/item-renderer/templates/media_upload.html', mediaUploadUserActivityHistoryItemTemplate);
	$templateCache.put('user-activity-history/item-renderer/templates/session.html', sessionUserActivityHistoryItemTemplate);
	$templateCache.put('user-activity-history/item-renderer/templates/transaction.html', transactionUserActivityHistoryItemTemplate);
	$templateCache.put('user-activity-history/item-renderer/templates/user_login.html', userLoginUserActivityHistoryItemTemplate);
	$templateCache.put('user-activity-history/item-renderer/templates/user_role.html', userRoleUserActivityHistoryItemTemplate);
};
