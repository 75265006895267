export interface TimeDuration {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	milliseconds: number;
	microseconds: number;
	nanoseconds: number;
}

export class TimeUtilsService {

	public millisecondToDays (milliseconds: number): number {
		return milliseconds / 1000 / 60 / 60 / 24;
	}

	public millisecondsToMins (milliseconds: number): number {
		return this.millisecondsToSeconds(milliseconds) / 60;
	}

	public millisecondsToHrs (milliseconds: number): number {
		return this.millisecondsToSeconds(milliseconds) / 60 / 60;
	}

	public millisecondsToSeconds (milliseconds): number {
		return milliseconds / 1000;
	}

	public formatTime (ms: number, trim = true, dontPad = false): string {
		const parsed = this.parseDuration(ms);

		// We don't care about days, roll them into hours and get rid of them
		parsed.hours += parsed.days * 24;
		delete parsed.days;

		// Convert to padded strings
		let hours: string = Math.abs(parsed.hours).toString().padStart(2, '0');
		let minutes: string = Math.abs(parsed.minutes).toString().padStart(2, '0');
		const seconds: string = Math.abs(parsed.seconds).toString().padStart(2, '0');
		if (dontPad) {
			hours = Math.abs(parsed.hours).toString();
			minutes = Math.abs(parsed.minutes).toString();
			if (hours !== '0') {
				minutes = minutes.padStart(2, '0');
			}
		}

		// Format timestamp string
		let formatted = `${minutes}:${seconds}`;

		// Only add hours if not trimming or if trimming but not 00
		let zeroHours = '00';
		if (dontPad) {
			zeroHours = '0';
		}
		if (!trim || hours !== zeroHours) {
			formatted = `${hours}:${formatted}`;
		}

		// Correctly handle negative inputs
		if (ms < 0) {
			formatted = `-${formatted}`;
		}

		return formatted;
	}

	public parseDuration (ms: number): TimeDuration {
		const roundTowardsZero = ms > 0 ? Math.floor : Math.ceil;

		return {
			days: roundTowardsZero(ms / 86400000),
			hours: roundTowardsZero(ms / 3600000) % 24,
			minutes: roundTowardsZero(ms / 60000) % 60,
			seconds: roundTowardsZero(ms / 1000) % 60,
			milliseconds: roundTowardsZero(ms) % 1000,
			microseconds: roundTowardsZero(ms * 1000) % 1000,
			nanoseconds: roundTowardsZero(ms * 1e6) % 1000
		};
	}
}
