import { Reasons } from 'go-modules/video-scene/state-emitter/state-emitter';
import { StatusOverlayable } from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay.component';

export class StatusOverlayReason implements StatusOverlayable {
	constructor (
		public readonly reason: Reasons,
		public readonly browser: string = null,
		public readonly action: (options?: object) => void = null
	) {}
}
