
/* @ngInject */
export const SlideDeckViewerSyncEventToastController = function ($mdToast, isUpdate) {
	const vm = this;
	vm.isUpdate = isUpdate;

	vm.close = function () {
		$mdToast.hide();
	};
};
