import * as angular from 'angular';
import * as uiDatePicker from 'angular-ui-bootstrap/src/datepicker';
import * as uiDatePickerPopup from 'angular-ui-bootstrap/src/datepickerPopup';
import * as uiTimepickerPopup from 'angular-ui-bootstrap/src/timepicker';
import * as angularTranslate from 'angular-translate';
import 'bootstrap-css-only';


import { datetimepickerDirective } from './datetimepicker.directive';
import { datetimepickerTimepickerDirective } from './datetimepicker-timepicker.directive';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';

import './style.less';
import { templates } from './templates';

export const goDateTimePickerModule = angular.module('go.datetimepicker', [
	uiDatePicker,
	uiDatePickerPopup,
	uiTimepickerPopup,
	angularTranslate,
	goLocalizeHelperModule
])
	.directive('datetimepicker', datetimepickerDirective)
	.directive('datetimepickerTimepicker', datetimepickerTimepickerDirective)
	.run(templates)
	.name;
