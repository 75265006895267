export default class MediaPermissions {

	public static readonly AUTOREJECT_THRESHOLD = 100;

	private permissionsApi: Permissions|null;
	private mediaDevicesApi: MediaDevices;

	constructor (permissionsApi: Permissions|null, mediaDevicesApi: MediaDevices) {
		this.permissionsApi = permissionsApi;
		this.mediaDevicesApi = mediaDevicesApi;
	}

	/**
	 * Makes sure that the real devices have/prompt for permission
	 * Returns a combinded PermissionState:
	 *   'granted' all requested media has been granted (either automatically or via prompt)
	 *   'denied' if any requested media has been denied automatically (the user previously denied permanently)
	 *   'prompt' if any requested media was prompted for, but the user did not grant
	 *
	 * @param video If video permissions should be prompted for
	 * @param audio If audio permissions should be prompted for
	 * @returns PermissionState
	 */
	public async ensureMediaPermissions (video: boolean, audio: boolean):
	Promise<PermissionState> {

		const promptFor = await this.getPromptConstraints(video, audio);
		// no way in typescript to use a string literal type in runtime, so we need to spell out all options
		if (['granted', 'prompt', 'denied'].includes(promptFor as PermissionState)) {
			return promptFor as PermissionState;
		}

		let stream: MediaStream = null;
		const promptTime = performance.now();
		try {
			stream = await this.mediaDevicesApi.getUserMedia(promptFor as MediaStreamConstraints);
			return 'granted';
		} catch (rejected) {
			if (performance.now() - promptTime < MediaPermissions.AUTOREJECT_THRESHOLD) {
				return 'denied';
			}
			return 'prompt';
		} finally {
			if (stream != null) {
				stream.getTracks().forEach((track: MediaStreamTrack) => {
					track.stop();
					stream.removeTrack(track);
				});
			}
		}
	}

	/**
	 * Returns the MediaStreamConstraints that should be used for requesting permissions
	 * or the PermissionState if no prompting is needed
	 * @param video If video permissions should be prompted for
	 * @param audio If audio permissions should be prompted for
	 */
	private async getPromptConstraints (video: boolean, audio: boolean):
	Promise<MediaStreamConstraints|PermissionState> {
		// If there's an implementation of permissions API, let's use it (basically only chrome for now)
		// https://caniuse.com/#feat=permissions-api
		if (this.permissionsApi != null) {
			try {
				let cameraPermissions = 'granted';
				let microphonePermissions = 'granted';

				if (video) {
					cameraPermissions = (await this.permissionsApi.query({name: 'camera' as PermissionName})).state;
				}
				if (audio) {
					microphonePermissions = (await this.permissionsApi.query({name: 'microphone' as PermissionName})).state;
				}

				if (cameraPermissions === 'granted' && microphonePermissions === 'granted') {
					return 'granted';
				}
				if (cameraPermissions === 'denied' || microphonePermissions === 'denied') {
					return 'denied';
				}

				return {
					video: cameraPermissions === 'prompt',
					audio: microphonePermissions === 'prompt'
				};
			} catch (unsupportedQuery) {
				// Firefox throws an error because they haven't implemented camera yet
			}
		}

		// Otherwise we are going to need to do some tests to see if we have permissions
		// We look at the devices and if the label is empty string we say we don't have permission
		// Note that this might be denied instead of prompt, but we can't know until we prompt
		const devices: MediaDeviceInfo[] = await this.mediaDevicesApi.enumerateDevices();
		const promptForVideo = video && devices.some((device) => device.kind === 'videoinput' && device.label === '');
		const promptForAudio = audio && devices.some((device) => device.kind === 'audioinput' && device.label === '');

		if (!promptForVideo && !promptForAudio) {
			return 'granted';
		}

		return {
			video: promptForVideo,
			audio: promptForAudio
		};
	}
}
