import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import type { User } from 'ngx/go-modules/src/interfaces/user';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from 'ngx/go-modules/src/services/go-modal/go-modal.tokens';
import { catchError, from, map, Observable, of, Subject, switchMap, take } from 'rxjs';

@Component({
	selector: 'drop-user-dialog',
	template: require('./drop-user-dialog.component.html'),
	styles: [require('./drop-user-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropUserDialogComponent implements OnInit, OnDestroy {

	public dropping$: Observable<boolean> = of(false);
	public drop$$: Subject<boolean> = new Subject();

	constructor (
		public dialogRef: GoDialogRef,
		@Inject(GO_MODAL_DATA) public data: {
			group: any;
			user: User;
		}
	) {}

	public ngOnDestroy (): void {
		this.drop$$.complete();
	}

	public ngOnInit (): void {
		this.drop$$.pipe(

			// Only allow clicking cancel or submit once
			take(1),

			switchMap((drop): Observable<boolean> => {

				this.dropping$ = of(true);

				const deleteUser$ = drop ? from(this.data.group.deleteUser(this.data.user)).pipe(
					map(() => true)
				) : of(null);

				return deleteUser$.pipe(
					catchError(() => of(false))
				);
			})
		).subscribe((drop) => this.dialogRef.close({ drop }));
	}

	public close () {
		this.drop$$.next(true);
	}

	public cancel () {
		this.drop$$.next(null);
	}
}
