import { IAnalyticsService } from 'angulartics';
import { AttachmentService as AttachService } from 'go-modules/services/attachment/attachment.service';

export interface Bindings {
	session: any;
	user: any;
	group: any;
	readonlyMode: boolean;
	analyticsCategory: string;
	analyticsLabel: string;
	activityName: string;
}

export class SessionAttachmentsController implements Bindings {

	// Bindings
	public session: any;
	public user: any;
	public group: any;
	public readonlyMode: boolean;
	public analyticsCategory: string;
	public analyticsLabel: string;
	public activityName: string;
	public options: any;

	public attachmentOptions: any;
	public attachments: any[];
	public analyticsData: {category: string; label: string};

	/* @ngInject */
	constructor (
		private $analytics: IAnalyticsService,
		private Session: any,
		private AttachmentModel: any,
		private AttachmentService: AttachService
	) {
		this.attachments = [];
	}

	/**
	 * Handles init life-cycle hook
	 */
	public $onInit (): void {
		this.attachmentOptions = {
			groupId: this.session.group_id,
			hideTitle: true,
			hideDuration: true,
			hideLoading: (this.options && this.options.hideLoading) ? true : false,
			showAdd: !this.readonlyMode,
			showDelete: (attachment: any) => {
				if (this.readonlyMode) {
					return false;
				}
				return this.mayEditOrDeleteAttachment(attachment);
			},
			showEdit: (attachment: any) => {
				if (this.readonlyMode) {
					return false;
				}
				return this.mayEditOrDeleteAttachment(attachment);
			},
			showPreview: () => !this.readonlyMode,
			resourceId: this.session.session_id,
			resourceType: this.Session.RESOURCE_TYPE,
			userFirstName: this.user.first_name,
			userLastName: this.user.last_name,
			activityName: this.activityName
		};

		// Load session attachments
		if (this.session.num_attachments > 0) {
			this.AttachmentService.getSessionAttachments(this.session.session_id)
				.then((attachments) => {
					this.attachments = attachments;
				}).catch((err) => {
					// eslint-disable-next-line no-console
					console.error('Error getting attachments', err);
				});
		}

		this.analyticsData = {
			category: this.analyticsCategory,
			label: this.analyticsLabel
		};
	}

	/**
	 * Whether an attachment may be edited or deleted
	 */
	public mayEditOrDeleteAttachment (attachment: any): boolean {
		return attachment.created_by === this.user.user_id
			|| this.group.hasInstructorRole(true)
			|| this.session.isOwner(this.user);
	}

	/**
	 * Handles attachment added event
	 */
	public onAttachmentAdded (attachments: any[]): void {
		this.session.num_attachments = attachments.length;

		this.$analytics.eventTrack('add attachment', this.analyticsData);
	}

	/**
	 * Handles attachment preview event
	 */
	public onAttachmentPreview (): void {
		this.$analytics.eventTrack('preview attachment', this.analyticsData);
	}

	/**
	 * Handles attachment download event
	 */
	public onAttachmentDownload (): void {
		this.$analytics.eventTrack('download attachment', this.analyticsData);
	}

	/**
	 * Handles attachment edit event
	 */
	public onAttachmentEdit (): void {
		this.$analytics.eventTrack('edit attachment', this.analyticsData);
	}

	/**
	 * Handles attachment deleted event
	 */
	public onAttachmentDeleted (attachments: any[]): void {
		this.session.num_attachments = attachments.length;

		this.$analytics.eventTrack('delete attachment', this.analyticsData);
	}
}
