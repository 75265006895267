interface Bindings {
	templateUrl: string;
	data: any;
}

export class GoPopoverController implements Bindings {

	public templateUrl: string;
	public data: any;
	public disablePopover: boolean;
	public popoverAppendToBody: boolean = true;

	/* @ngInject */
	constructor () {}
}
