/* @ngInject */
export function invalidEmailsDirective ($translate) {

	function getInvalidEmails (emailsField) {
		const badEmails = [];
		if (!emailsField || emailsField === '') {
			return;
		}
		const emails = emailsField.split(/[\n,;]+/);
		emails.forEach(function (email) {
			if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(email)) {
				badEmails.push(email);
			}
		});
		return badEmails.join(', ');
	};

	function updateText (element, value) {
		const badEmails = getInvalidEmails(value);
		const message = !!badEmails ? $translate.instant('common-validation_emails-invalid', { emails: getInvalidEmails(value) }) : '';
		element.text(message);
	}

	return {
		link: function link (scope, elem, attrs) {

			// Watch changes to passed property
			const watcher = scope.$watch(attrs.invalidEmails, function (value) {
				updateText(elem, value);
			});

			// un-register watcher
			scope.$on('$destroy', watcher);
		}
	};
}
