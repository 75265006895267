import { MediaStreamFactory, MediaStreamKind } from './media-stream-factory';

export default class DeviceMediaStreamFactory implements MediaStreamFactory {

	private deviceInfo: MediaDeviceInfo;
	private mediaDevicesApi: MediaDevices;

	constructor (deviceInfo: MediaDeviceInfo, mediaDevicesApi: MediaDevices) {
		this.deviceInfo = deviceInfo;
		this.mediaDevicesApi = mediaDevicesApi;
	}

	// Opentok calls `.stop()` on a stream's tracks when you destroy a publisher
	// One option is to clone the stream, but that would require eventing or similar.
	// Instead, we're nooping `MediaStreamTrack.stop` for GoScene tracks.
	// This means we must call `track.stopTrack` anywhere we want to stop a track.
	// https://stackoverflow.com/a/50454758/2895909
	public static aliasTrackStopMethod (track: MediaStreamTrack): void {
		track.stopTrack = track.stop;
		track.stop = () => {};
	}

	public getId (): string {
		return this.deviceInfo.deviceId;
	}

	public getType (): MediaStreamKind {
		if (this.deviceInfo.kind === 'videoinput') return 'video';
		if (this.deviceInfo.kind === 'audioinput') return 'audio';
		return 'other';
	}

	public getLabel (): string {
		return this.deviceInfo.label;
	}

	public async getStream (options: MediaTrackConstraints = {}, otherFactories: MediaStreamFactory[] = []):
	Promise<MediaStream> {

		// If we are combining factories, take the first party factories and combine their media constraints
		const otherDeviceFactories = otherFactories.filter(
			(otherStream) => otherStream instanceof DeviceMediaStreamFactory
		) as DeviceMediaStreamFactory[];

		const combindedMediaConstraints = otherDeviceFactories.reduce(
			(constraints, otherDeviceStream) => ({...otherDeviceStream.getMediaStreamConstraints(), ...constraints}),
			this.getMediaStreamConstraints(options)
		);

		const stream = await this.mediaDevicesApi.getUserMedia(combindedMediaConstraints);

		// Take the third party factories, and merge in their stream tracks
		const otherNonDeviceFactories = otherFactories.filter(
			(otherStream) => !(otherStream instanceof DeviceMediaStreamFactory)
		) as DeviceMediaStreamFactory[];

		if (otherNonDeviceFactories.length !== 0) {
			const otherStream = await otherNonDeviceFactories[0].getStream({}, otherNonDeviceFactories.slice(1));

			otherStream.getTracks().forEach((track: MediaStreamTrack) => {
				stream.addTrack(track);
			});
		}

		stream?.getTracks().forEach((track: MediaStreamTrack) => {
			DeviceMediaStreamFactory.aliasTrackStopMethod(track);
		});

		return stream;
	}

	private getMediaStreamConstraints (options: MediaTrackConstraints = {}) {
		return {
			[this.getType()]: {
				deviceId: {
					exact: this.deviceInfo.deviceId
				},
				...options
			}
		};
	}
}
