import { IAnalyticsService } from 'angulartics';

export interface Bindings {
	group: any;
	activity: any;
	attachments: any[];
	readonlyMode: boolean;
	canDownloadAttachment: boolean;
	analyticsCategory: string;
	analyticsLabel: string;
	onChange: (data: any) => void;
}

export class ActivityAttachmentsController implements Bindings {
	public group: any;
	public activity: any;
	public attachments: any[];
	public readonlyMode: boolean;
	public canDownloadAttachment: boolean;
	public analyticsCategory: string;
	public analyticsLabel: string;
	public onChange: (data: any) => void;

	public attachmentOptions: any;
	public analyticsData: {category: string; label: string};

	/* @ngInject */
	constructor (
		private $filter: ng.IFilterService,
		private $analytics: IAnalyticsService,
		private ActivityModel: any,
		private MediaModel: any
	) {}

	/**
	 * Handles init life-cycle hook
	 */
	public $onInit (): void {
		if (!Array.isArray(this.attachments)) {
			throw new Error('Required binding `attachments` must be of type Array.');
		}

		const uploadMessageFn = [(file: any) => {
			const fileExtension: string = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
			if (this.MediaModel.PREVIEWABLE.indexOf(fileExtension) === -1) {
				return this.$filter('translate')('activity-editor-attachment_controller-please-convert');
			}
			return null;
		}];

		const canDownload = (attachment: any): boolean => {
			// Using the readonly flag to imply that the user has the significant
			// privileges to download the attachment media in this context.
			// Otherwise, we have to bring in group permission logic.
			if (!this.readonlyMode || this.canDownloadAttachment) {
				return true;
			}

			return !attachment.media.isPreviewable() && !this.activity.has_single_recording_attempt;
		};

		this.attachmentOptions = {
			addText: 'activity-attachments_add_resource',
			canDownload,
			confirmDeleteModal: {
				message: 'modal-confirm-delete-activity-resource_sure-delete',
				title: 'modal-confirm-delete-activity-resource_remove-resource'
			},
			groupId: this.activity.group_id,
			groupName: this.group.name,
			activityName: this.activity.name,
			resourceId: this.activity.activity_id,
			resourceType: this.ActivityModel.RESOURCE_TYPE,
			showAdd: !this.readonlyMode,
			showDelete: () => !this.readonlyMode,
			showEdit: () => !this.readonlyMode,
			uploadMessageFn,
			disableAutoSave: true
		};

		this.analyticsData = {
			category: this.analyticsCategory,
			label: this.analyticsLabel
		};
	}

	/**
	 * Handles attachment preview event
	 */
	public onAttachmentPreview (): void {
		this.$analytics.eventTrack('preview resource', this.analyticsData);
	}

	/**
	 * Handles attachment download event
	 */
	public onAttachmentDownload (): void {
		this.$analytics.eventTrack('download resource', this.analyticsData);
	}

	/**
	 * Handles attachment edit event
	 */
	public onAttachmentEdit (): void {
		this.$analytics.eventTrack('edit resource', this.analyticsData);
	}

	/**
	 * Handles attachment edited event
	 */
	public onAttachmentEdited (attachment: any): void {
		this.onChange({attachments: this.attachments, attachment});
	}

	/**
	 * Handles attachment added event
	 */
	public onAttachmentAdded (attachment: any): void {
		this.activity.setNumAttachments(this.attachments.length);
		this.onChange({attachments: this.attachments, attachment});

		this.$analytics.eventTrack('add resource', this.analyticsData);
	}

	/**
	 * Handles attachment deleted event
	 */
	public onAttachmentDeleted (attachment: any): void {
		this.activity.setNumAttachments(this.attachments.length);
		this.onChange({attachments: this.attachments, attachment});

		this.$analytics.eventTrack('delete resource', this.analyticsData);
	}
}
