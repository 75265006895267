import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { LibraryCollectionViewerModal } from './modal.service';
import { ngxLibraryCollectionViewerModule } from 'ngx/go-modules/src/components/library/library-collections-viewer';
// tslint:disable-next-line:import-spacing
import /*{ goModal } from*/ 'go-modules/modals';
import './style.less';

export const libraryCollectionViewerModalModule = angular.module('modal.library-collection-viewer' ,[
	angularTranslate,
	ngxLibraryCollectionViewerModule,
	'go.modal'
])
	.service(LibraryCollectionViewerModal.NG1_INJECTION_NAME, LibraryCollectionViewerModal)
	.name;
