import * as dayjs from 'dayjs';
import * as angular from 'angular';

export const dateDiffExceedsLimit = function (date, compareDate, limitInMonths) {

	if (
		!angular.isDate(date) ||
		!angular.isDate(compareDate) ||
		!limitInMonths
	) {
		return false;
	}

	date = dayjs(date);
	compareDate = dayjs(compareDate);

	const actualStartDate = date.isBefore(compareDate) ? date : compareDate;
	const actualEndDate = actualStartDate === date ? compareDate : date;
	const soonestStartDate = dayjs(actualEndDate).subtract(limitInMonths, 'month');
	const largestEndDate = dayjs(actualStartDate).add(limitInMonths, 'month');
	return actualEndDate.isAfter(largestEndDate) || actualStartDate.isBefore(soonestStartDate);
};
