import template from './button.component.html';
import { ToolbarButtonController as controller } from './button.controller';
import './style.less';

export default {
	bindings: {
		action: '@',
		disabled: '@',
		handler: '&',
		ariaLabel: '@',
		ariaLabelledby: '@'
	},
	controller,
	require: {
		toolbarController: '^toolbar'
	},
	template
};
