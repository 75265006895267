export class MediaCardController {
	public media: any;
	public ariaLabelTitleKey: string;
	public label: string;
	public thumbnailOptions = {
		actions: false,
		hideTitle: true,
		groupId: '',
		hideDuration: true,
		preventDownload: true
	};
	public tooltipString: string = '';
	public showTooltip: boolean = false;

	/* @ngInject */
	constructor (private MediaModel) {}

	public $onInit () {
		this.media = this.MediaModel.model(this.media);
	}

	public getTitle (): string {
		return this.tooltipString;
	}

	public toggleToolTip ($event) {
		this.tooltipString = '';
		this.showTooltip = false;
		if ($event.target.offsetWidth < $event.target.scrollWidth) {
			this.tooltipString = this.media.title;
			this.showTooltip = true;
		}
	}
}
