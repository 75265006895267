import { MediaPreviewModalController } from './modal.controller';
import fullScreen from './full-screen.html';
import template from './modal.html';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

/* @ngInject */
export function mediaPreviewModal (goModalBootstrap) {

	function open (options) {
		options.controller = MediaPreviewModalController;
		options.name = 'media-preview';
		options.controllerAs = '$ctrl';
		options.template = fullScreen;
		options.size = 'full';
		options.windowClass = 'media-preview-full';
		options.animation = false;
		options.goreact = true;
		options.backdrop = 'static';

		if (options.modalData && options.modalData.edit) {
			options.template = template;
			options.windowClass = 'media-preview';
			delete options.size;
			options.animation = true;
		}

		return goModalBootstrap.open(options);
	}

	return {
		open
	};
}

mediaPreviewModal.NG1_INJECTION_NAME = 'mediaPreviewModal' as const;
export const mediaPreviewToken = upgradeNg1Dependency(mediaPreviewModal);
