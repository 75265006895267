import template from './activity-templates.component.html';

export const activityTemplatesComponent = {
	bindings: {
		activity: '=',
		onSelect: '&',
		templates: '='
	},
	template,
	controller: () => {}
} as ng.IComponentOptions;
