/* @ngInject */
export function SingleMediaDisplayController (
	feedbackSession
) {
	const vm = this;

	/**
	 * Whether comment captions is enabled
	 *
	 * @returns {boolean}
	 */
	vm.isCommentCaptionsEnabled = function () {
		return feedbackSession.options.commentCaptionsEnabled &&
			feedbackSession.isPlaybackMode();
	};
}
