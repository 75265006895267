import * as angular from 'angular';
import { common } from 'go-modules/models/common';

import { AppEnvService } from './app-env.service';

export const goAppEnvModule = angular.module('appEnv', [
	common
])
	.service('appEnv', AppEnvService)
	.name;
