import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { activityModel } from 'go-modules/models/activity';
import { ngxGroupServiceModule } from 'ngx/go-modules/src/services/group';

import { SessionActivitySelectorComponent } from './selector.component';


import './style.less';

export const sessionActivitySelectorModule = angular.module('go.session-activity-selector', [
	angularTranslate,
	activityModel,
	ngxGroupServiceModule
])
	.component('sessionActivitySelector', SessionActivitySelectorComponent)
	.name;
