import { RealtimeTranscript } from 'assemblyai';
import { RealTimeCaptionsService } from 'go-modules/captions/real-time-captions.service';
import { STATES } from 'go-modules/media-player';
import { SessionManagerService } from 'go-modules/session-manager/session-manager.service';

interface Bindings {
	audioStream: MediaStream;
	playerState: STATES;
	mediaId: number;
}

export class RealTimeCaptionsController implements Bindings, ng.IController {
	// Bindings
	public audioStream: MediaStream;
	public playerState: STATES = null;
	public mediaId: number;

	// Public Values
	public captionString: string = '';

	// Private Values
	private captionService: RealTimeCaptionsService = new RealTimeCaptionsService();
	private finalizedCaptionText: string = '';

	/* @ngInject */
	constructor (
		private $scope: ng.IScope,
		private $translate: ng.translate.ITranslateService,
		private sessionManager: SessionManagerService
	) {
		this.captionService.setAccessToken(this.sessionManager.getAccessToken());
	}

	public $onInit () {
		this.captionService.on('data', this.onCaptionData);
		this.captionService.on('error', this.onCaptionError);
	}

	public $onChanges (onChangesObj: ng.IOnChangesObject): void {
		switch(this.playerState) {
			case STATES.BUFFERING:
				this.captionService.setPaused(true);

				break;
			case STATES.PAUSED:
				this.captionService.setPaused(true);

				break;
			case STATES.PLAYING:
				this.captionService.setPaused(false);

				break;
			case STATES.COMPLETE:
				this.captionService.stop();
				this.finalizedCaptionText = '';
				this.captionString = '';
		}

		if (('audioStream' in onChangesObj)) {
			if(!this.playerState) {
				this.captionService.setPaused(false);
			}

			if (this.audioStream != null) {
				this.captionService.startTranscribingStream(this.audioStream, this.mediaId);
			} else {
				this.captionService.stopTranscribingStream();
				this.finalizedCaptionText = '';
				this.captionString = '';
			}
		}
	}

	public $onDestroy () {
		this.captionService.off('data', this.onCaptionData);
		this.captionService.off('error', this.onCaptionError);
		this.captionService.destroy();
	}

	private onCaptionData = (data: RealtimeTranscript|Watson.Speech.RecognitionResults): void => {

		if ('text' in data) {
			const transcript = data.text;
			this.captionString = this.finalizedCaptionText + ' ' + (transcript || '');

			if (data.message_type === 'FinalTranscript') {
				this.finalizedCaptionText += ' ' + data.text;
			}
		} else {
			const transcript = data.results[0]?.alternatives[0].transcript;
			this.captionString = this.finalizedCaptionText + (transcript || '');

			if (data.results[0]?.final) {
				this.finalizedCaptionText += data.results[0].alternatives[0].transcript;
			}
		}

		this.$scope.$digest();
	};

	private onCaptionError = (error: Error): void => {
		if (error.message.includes('No speech detected for')) {
			this.captionString = this.$translate.instant('real-time-caption_error-captions-disabled-due-to-inactivity');
		} else {
			this.captionString = this.$translate.instant('real-time-caption_error-service-cannot-connect');
		}

		this.$scope.$digest();
	};
}
