import template from './session-editor.directive.html';
import { throttle } from 'lodash';

/* @ngInject */
export const sessionEditorDirective = ($window) => {
	return {
		restrict: 'E',
		require: {
			formController: '^form',
			ngModelController: 'ngModel'
		},
		scope: {
			session: '=ngModel',
			currentMode: '=mode',
			user: '=',
			users: '=?',
			group: '=',
			activity: '=',
			options: '=',
			onStimulusSaved: '&'
		},
		template,
		controller: 'SessionEditorController',
		controllerAs: 'editController',
		bindToController: true,
		link (scope, elem) {
			// Handle complex layouts for session-editor
			let resize = null;
			if ($window.onresize) {
				resize = $window.onresize;
			}

			// TODO: remove this, if possible, or at least use `addEventListener` to remove on destroy
			$window.onresize = throttle(() => {
				scope.$apply();
				if (resize) {
					resize();
				}
			}, 200); // Don't call too often

			const breakpoints = {score: 740};

			scope.$watch(() => {
				return elem[0].offsetWidth;
			}, (width) => {
				if (width >= breakpoints.score) {
					elem.removeClass('score-break');
				} else {
					elem.addClass('score-break');
				}
			});
		}
	};
};
