import * as angular from 'angular';
import * as uiModal from 'angular-ui-bootstrap/src/modal';
import * as angularTranslate from 'angular-translate';
import 'angular-material/modules/js/core/core.css';
import 'angular-material/modules/layouts/angular-material.layouts.css';
import 'bootstrap-css-only';

import './style.less';
import { uibModalDecoratorModule } from 'go-modules/$uib-modal-decorator';
import type {
	IModalInstanceService,
	IModalService,
	IModalSettings,
	IModalStackService
} from 'angular-ui-bootstrap';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

export interface GoModalSettings extends IModalSettings {
	name?: string;
	goreact?: boolean;
	modalData?: any;
	setup?: (modalData: any, options: GoModalSettings) => any;
};

export class GoModalBootstrap {
	private openModals = {};

	/* @ngInject */
	constructor (
		private $uibModal: IModalService,
		private $rootScope: ng.IRootScopeService,
		private $uibModalStack: IModalStackService,
		private $log: ng.ILogService,
		private $document: ng.IDocumentService,
		private $injector
	) {}

	public open = (options: GoModalSettings): IModalInstanceService => {
		options.scope = options.scope || this.$rootScope.$new();

		if (options.modalData) {
			angular.extend(options.scope, options.modalData);
		}

		(options.setup || angular.noop)(options.modalData, options);

		// Apply new goreact modal styles?
		if (options.goreact) {
			options.windowClass = options.windowClass || '';
			options.windowClass += ' goreact-modal';
		}

		const instance = this.$uibModal.open(options);
		this.openModals[options.name] = instance;

		instance.opened.then(() => {
			this.$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_OPENED, options);
		}).catch(this.$log.error);

		instance.rendered.then(()=> {
			const selector = options.modalData?.firstFocusSelector;
			if (selector != null) {
				const el: HTMLElement | null = this.$document[0].querySelector(selector);
				if (el != null) el.focus();
			}
		}).catch(this.$log.error);

		// success function (modal closed promise resolved)
		instance.result.then(() => {
			delete this.openModals[options.name];
			this.$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_CLOSED, options);

			if (!this.$uibModalStack.getTop()) {
				this.$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_ALL_CLOSED, options);
			}

			options.scope.$destroy();
		// fail function (modal dismissed promise rejected)
		}, () => {
			delete this.openModals[options.name];
			this.$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_CLOSED, options);

			if (!this.$uibModalStack.getTop()) {
				this.$injector.get('eventService').broadcast(EVENT_NAMES.GO_MODAL_ALL_CLOSED, options);
			}

			options.scope.$destroy();
		});

		instance.opened.catch((err) => this.$log.error(err));

		return instance;
	};

	public dismissAll = () => this.$uibModalStack.dismissAll();
	public getTop = () => this.$uibModalStack.getTop();
}

// TODO: When all modals are in own modules, this becomes index.ts
export const goModalBootstrapModule = angular.module('modal.bootstrap', [
	uiModal,
	angularTranslate,
	uibModalDecoratorModule
])
	.factory('goModalBootstrap', GoModalBootstrap)
	.name;
