import { clientSettings } from 'go-modules/models/common/client.settings';

/* @ngInject */
export function AttachmentModel ($resource) {
	const basePath = `${clientSettings.GoReactV2API}/attachments/:id`;

	const attachmentModel = $resource(basePath, {id: '@id'});

	attachmentModel.RESOURCE_TYPE = 'attachment';

	return attachmentModel;
}
