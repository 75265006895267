import template from './session-media-display.component.html';
import { SessionMediaDisplayController } from './session-media-display.controller';

export const sessionMediaDisplay = {
	template,
	require: {
		mainController: '^feedbackSession',
		mediaDisplayController: '^mediaDisplay',
		singleMediaDisplayController: '?^singleMediaDisplay'
	},
	controller: SessionMediaDisplayController,
	bindings: {media: '<'}
};
