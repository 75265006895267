import { GrabToPan } from 'ngx/go-modules/src/services/grab-to-pan/index';
import * as angular from 'angular';
import template from './go-pdf.directive.html';

/* @ngInject */
export function goPdfPage ($q, $timeout, goPdfLoader, EventChannel) {
	const RENDERING_THRESHOLD = 300;

	const isolateScope = {
		doc: '=?',
		onLoad: '&',
		onPageRender: '&',
		scale: '=?',
		url: '@'
	};

	const setCanvasDimensions = function (canvas, w, h) {
		canvas.width = Math.floor(w);
		canvas.height = Math.floor(h);
		return canvas;
	};

	function link (scope, elem, attr, goPdfPreviewCtrl) {
		const channel = new EventChannel();

		if (!attr.url && !scope.doc) {
			throw new Error('must provide a pdf path or loaded document');
		}

		let canvasElement;
		let originalScale = null;
		scope.scale = scope.scale || null;
		scope.page = attr.page ? parseInt(attr.page, 10) : 1;
		scope.prevPage = scope.page;
		scope.isRendered = false;
		scope.isRendering = false;

		const g2p = new GrabToPan({element: elem[0]});

		scope.zoomIn = function () {
			scope.scale = parseFloat(scope.scale) + 0.1;
			renderPage(scope.page, false);
			g2p.activate();
		};

		scope.zoomOut = function () {
			scope.scale = parseFloat(scope.scale) - 0.1;
			renderPage(scope.page, false);
		};

		scope.fit = function () {
			scope.scale = originalScale;
			renderPage(scope.page, false);
		};

		scope.goto = function (page) {
			if (page > 0 && page <= scope.doc.numPages) {
				scope.prevPage = scope.page;
				scope.page = page;
				changePage();
			}
		};

		scope.next = function () {
			if (scope.page < scope.doc.numPages) {
				scope.prevPage = scope.page;
				scope.page++;
				changePage();
			}
		};

		scope.prev = function () {
			if (scope.page > 1) {
				scope.prevPage = scope.page;
				scope.page--;
				changePage();
			}
		};

		scope.renderPage = renderPage;

		scope.trigger = channel.broadcast.bind(channel);
		// eslint-disable-next-line angular/avoid-scope-typos
		scope.on = channel.subscribe.bind(channel);
		scope.off = channel.unsubscribe.bind(channel);
		scope.once = channel.subscribeOnce.bind(channel);

		/**
		 * Change the current page
		 */
		function changePage () {
			scope.trigger('pageChange', {
				page: scope.page,
				numPages: scope.doc.numPages,
				prevPage: scope.prevPage
			});

			// Render the page
			renderPage(scope.page, false).then(function () {
				scope.trigger('pageChanged', {
					page: scope.page,
					numPages: scope.doc.numPages,
					prevPage: scope.prevPage
				});
			});
		}

		/**
		 * Render a single page
		 *
		 * If a scale value is passed, it will override
		 * the current scope scale
		 *
		 * @param pageNum
		 * @param forceResize
		 * @returns {*}
		 */
		let timeoutPromise;
		const pagesRendering = {};
		function renderPage (pageNum, forceResize) {
			scope.scale = forceResize ? null : scope.scale;

			// If the page is already rendering,
			// just return the same promise.
			if (pagesRendering[pageNum]) {
				return pagesRendering[pageNum];
			}

			const deferred = $q.defer(),
				pagePromise = scope.doc.getPage(pageNum);

			pagesRendering[pageNum] = deferred.promise;
			scope.page = pageNum;
			scope.isRendered = false;
			canvasElement = newCanvasInstance();

			// Broadcast page render event
			scope.trigger('pageRender', {
				page: scope.page,
				numPages: scope.doc.numPages,
				prevPage: scope.prevPage
			});

			// Don't broadcast a rendering event
			// if it takes less than a second to render.
			$timeout.cancel(timeoutPromise);
			timeoutPromise = $timeout(function () {
				// If it is not rendered yet,
				// then we need to broadcast
				// a page rendering event
				if (!scope.isRendered) {
					scope.isRendering = true;
					scope.trigger('pageRendering', {
						page: scope.page,
						numPages: scope.doc.numPages,
						prevPage: scope.prevPage
					});
				}
			}, RENDERING_THRESHOLD);

			// Wait for page to load
			pagePromise.then(function (page) {
				const ctx = canvasElement[0].getContext('2d');

				// scale being null means we are rendering for the first time
				// and need to pick the scale that matches the element size
				let viewport;
				if (!scope.scale) {
					const elemWidth = elem[0].offsetWidth;
					const elemHeight = elem[0].offsetHeight;
					viewport = page.getViewport({ scale: 1 });
					scope.scale = Math.min(elemWidth / viewport.width, elemHeight / viewport.height);
				}

				if (scope.scale < 0) {
					scope.scale = 0.01;
				}

				viewport = page.getViewport({ scale: scope.scale });

				setCanvasDimensions(canvasElement[0], viewport.width, viewport.height);

				if (!originalScale) {
					originalScale = scope.scale;
				}

				page.render({
					canvasContext: ctx,
					viewport
				}).promise.then(function () {
					deferred.resolve();
				});
			});

			deferred.promise.then(function () {
				// Set as rendered and not rendering
				scope.isRendered = true;
				scope.isRendering = false;
				delete pagesRendering[pageNum];

				scope.onPageRender({
					page: scope.page,
					numPages: scope.doc.numPages,
					prevPage: scope.prevPage
				});

				// Broadcast page rendered event
				scope.trigger('pageRendered', {
					page: scope.page,
					numPages: scope.doc.numPages,
					prevPage: scope.prevPage
				});
			});

			return deferred.promise;
		}

		/**
		 * Create a new canvas instance
		 *
		 * @returns {*}
		 */
		function newCanvasInstance () {
			let instance = elem.children('canvas');

			if (instance.length) {
				instance.remove();
			}

			instance = angular.element('<canvas></canvas>');
			elem.prepend(instance);

			return instance;
		}

		// expose me to my parent if defined
		if (goPdfPreviewCtrl) {
			goPdfPreviewCtrl.register(scope);
		}

		// Load document
		let promise;
		if (scope.doc) {
			promise = $q.when(scope.doc);
		} else {
			promise = goPdfLoader.load(scope.url);
		}

		// Wait for document to load
		// and then render the first page.
		promise.then((doc) => {
			scope.doc = doc;
			scope.onLoad({document: scope});
			scope.$evalAsync();

			renderPage(scope.page, false).then(() => {
				elem.removeClass('loading');
			});
		});

		elem.addClass('loading');
	}

	return {
		scope: isolateScope,
		link,
		require: '^?goDocViewer',
		template
	};
}
