import * as angular from 'angular';
import { getOffsetParent } from './utils/get-element-offset-parent.helper';
import { getHeight } from './utils/get-element-height.helper';
import { getWidth } from './utils/get-element-width.helper';

/* @ngInject */
export function TreeViewController ($scope, $element, $attrs, treeShared) {
	$scope.collapsed = false;
	$scope.shared = treeShared;
	const defaultHighlightDuration = 1500;

	$scope.collapse = function (collapse) {
		if (angular.isUndefined(collapse)) {
			collapse = !$scope.collapsed;
		}
		$scope.collapsed = collapse;
	};

	$scope.setActive = function () {
		$scope.shared.active = $scope.family;

		if ($attrs.ngModel) {
			$scope.active = $scope.family;
		}
	};

	/** Add highlight class to this element and remove after a given duration */
	let highlightTimeoutId;
	$scope.highlight = function (duration) {
		const listItem = angular.element($element[0].firstElementChild);
		const highlightEl = angular.element(listItem[0].firstElementChild);
		highlightEl.addClass('before-highlight');
		highlightEl.addClass('highlight');

		duration = duration || defaultHighlightDuration;

		// remove highlight
		clearTimeout(highlightTimeoutId);
		// TODO: Use $timeout service?
		highlightTimeoutId = setTimeout(function () {
			highlightEl.removeClass('highlight');
			// TODO: Use $timeout service?
			const id = setTimeout(function () {
				clearTimeout(id);
				highlightEl.removeClass('before-highlight');
			}, duration);
		}, duration);
	};

	const scrolling = false;

	/** Whether viewport is currently being scrolled */
	$scope.isScrolling = function () {
		return scrolling;
	};

	/** Scroll to this element */
	$scope.scrollTo = function (_duration, onComplete) {
		const scrollTop = $element[0].offsetTop;
		const offsetParent = getOffsetParent($element[0]);
		offsetParent.scrollTop = scrollTop;

		if (angular.isFunction(onComplete)) {
			onComplete();
		}
	};

	/** Scroll to this element but just keep it within the view port */
	$scope.scrollWithin = function () {
		let elementTop = $element[0].parentElement.offsetTop;
		let elementBottom = elementTop + $element[0].parentElement.clientHeight;
		if ($element[0].parentElement.classList.contains('list-item-child')) {
			elementTop += $element[0].closest('.top-level-li').offsetTop;
			elementBottom = elementTop + $element[0].clientHeight;
		}

		const scrollContainer = $element[0].closest('.scrollable-feedback-section');

		const containerTop = scrollContainer.scrollTop;
		const containerBottom = containerTop + scrollContainer.clientHeight;

		if (elementTop < containerTop) {
			scrollContainer.scrollTop -= (containerTop - elementTop)
				+ $element[0].parentElement.clientHeight;
		}
		else if (elementBottom > containerBottom) {
			scrollContainer.scrollTop += (elementBottom - containerBottom);
		}
	};


	$scope.getViewPort = function () {
		const offsetParent = getOffsetParent($element[0]);
		return {
			width: getWidth(offsetParent),
			height: getHeight(offsetParent)
		};
	};

	$scope.addClassToRoot = function (name) {
		let el = $element;
		while (el.parent().length) {
			if (el.hasClass('root')) {
				el.addClass(name);
				break;
			}
			el = el.parent();
		}
	};

	$scope.removeClassFromRoot = function (name) {
		let el = $element;
		while (el.parent().length) {
			if (el.hasClass('root')) {
				el.removeClass(name);
				break;
			}
			el = el.parent();
		}
	};
}
