import * as angular from 'angular';

import { event } from '../event-channel';
import { detectModule } from '../detect';

import { goPdfPage } from './go-pdf.directive';
import { goPdfLoader } from './go-pdf-loader/go-pdf-loader.factory';
import { onImgLoad } from './go-doc-viewer/go-doc-viewer-on-img-load.directive';
import { goDocViewer } from './go-doc-viewer/go-doc-viewer.directive';

import 'ngx/go-modules/src/services/grab-to-pan/style.css';
import './style.less';

export const goPdfModule = angular.module('go-pdf', [
	event,
	detectModule
])
	.directive('goDocViewer', goDocViewer)
	.directive('onImgLoad', onImgLoad)
	.factory('goPdfLoader', goPdfLoader)
	.directive('goPdfPage', goPdfPage)
	.name;
