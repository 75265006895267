/* @ngInject */
export function emailListDirective () {
	const EMAIL_REGEXP = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/;
	return {
		require: 'ngModel',
		link (_scope, _element, _attr, modelController) {
			modelController.$validators.emails = function (modelValue) {
				if (!modelValue) {
					return false;
				}
				let isValid = true;
				modelValue.forEach(function (email) {
					if (!EMAIL_REGEXP.test(email)) {
						isValid = false;
					}
				});
				return isValid;
			};
		}
	};
}
