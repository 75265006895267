import * as angular from 'angular';
import { clientSettings } from '../common/client.settings';

/**
 * This is a model that represents a sync event.
 *
 * Data attributes include:
 * 	id
 * 	session_id
 * 	media_id
 * 	track_number
 * 	action
 * 	time
 * 	master_time
 * 	reason
 * 	created_by
 * 	created_at
 */

/* @ngInject */
export function SyncEvent (
	$resource,
	$q,
	cacheManager,
	syncEventServiceTransformer,
	syncEventServiceInterceptor,
	Time
) {
	const baseUrl = `${clientSettings.GoReactV1API}/sessions/:session_id/sync_events`;

	const syncEvent = $resource(baseUrl + '/:id', {
		id: '@id',
		session_id: '@session_id',
		track_id: '@track_id'
	}, {
		get: {
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/sync_events/:id`,
			method: 'GET',
			responseType: 'json',
			transformResponse: syncEventServiceTransformer.response,
			interceptor: syncEventServiceInterceptor
		},
		save: {
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/sync_events`,
			method: 'POST',
			responseType: 'json',
			transformRequest: syncEventServiceTransformer.request,
			transformResponse: syncEventServiceTransformer.response,
			interceptor: syncEventServiceInterceptor
		},
		update: {
			method: 'PUT',
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/sync_events/:id`,
			responseType: 'json',
			transformRequest: syncEventServiceTransformer.request,
			transformResponse: syncEventServiceTransformer.response,
			interceptor: syncEventServiceInterceptor
		},
		delete: {
			method: 'DELETE',
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/sync_events/:id`,
			responseType: 'json',
			transformResponse: syncEventServiceTransformer.response,
			interceptor: syncEventServiceInterceptor
		},
		deleteAll: {
			method: 'DELETE',
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/sync_events`
		},
		batch: {
			method: 'POST',
			url: `${clientSettings.GoReactV2API}/submissions/:session_id/sync_events/batch`,
			responseType: 'json',
			transformRequest: syncEventServiceTransformer.request
		}
	});

	// Valid sync event actions
	syncEvent.ACTION = {
		PLAY: 'play',
		PAUSE: 'pause',
		SEEK: 'seek'
	};

	// List of valid sync event actions
	syncEvent.ACTIONS = [];
	for (const key in syncEvent.ACTION) {
		if (syncEvent.ACTION.hasOwnProperty(key)) {
			syncEvent.ACTIONS.push(syncEvent.ACTION[key]);
		}
	}

	/**
	 * Creates a new sync event instance or loads
	 * an existing instance from the cache.
	 *
	 * @param data
	 * @returns {SyncEvent}
	 */
	syncEvent.model = function (data) {
		let newInstance = new syncEvent(syncEventServiceTransformer.response[1](data || {}));

		// Check cache
		if (newInstance.getId()) {
			const cachedInstance = syncEvent.getCache().get(newInstance.getId());

			// If instance is already in the cache,
			// the new instance should use that reference
			if (cachedInstance) {
				angular.extend(cachedInstance, newInstance);
				newInstance = cachedInstance;
			} else {
				syncEvent.getCache().put(newInstance.getId(), newInstance);
			}
		}

		return newInstance;
	};

	/**
	 * Modelize a collection of sync events by track
	 *
	 * @param data
	 * @returns Object
	 */
	syncEvent.modelizeAllTracks = function (data) {
		const newGroup = {};

		for (const i in data) {
			if (data.hasOwnProperty(i)) {
				newGroup[i] = [];
				data[i].forEach(function (_syncEvent) {
					newGroup[i].push(syncEvent.model(_syncEvent));
				});
			}
		}

		return newGroup;
	};

	/**
	 * Returns a sync event cache manager instance
	 * interacting with cached sync events.
	 *
	 * @returns {SyncEvent}
	 */
	syncEvent.getCache = function () {
		return cacheManager('syncEvent');
	};

	/**
	 * Saves multiple sync events at once
	 *
	 * If there is still data inflight, saveBatch will wait until they finish before saving
	 */
	syncEvent.saveBatch = function (urlParams, data: typeof SyncEvent[], ...cbs) {
		// Nothing to do, let's not waste a call to the backend
		if (data.length === 0) {
			return $q.resolve(data);
		}

		// Map our data into promises. Either use the attached promise or immediately resolve.
		// We want to wait for all the promises to complete. Even if some failed so we can retry them.
		// $q will abort on the first failure and just return that failure, so catch and return the event.
		const promises = data.map((event: any) => {
			return (event.$promise || $q.reject()).catch(() => {
				return $q.resolve(event);
			});
		});

		// Wait for all the promises to finish, then run the reall batch post
		return $q.all(promises).then((events) => {
			return syncEvent.batch(urlParams, events, ...cbs);
		});
	};

	/**
	 * Returns the sync event id
	 *
	 * @returns {string}
	 */
	syncEvent.prototype.getId = function () {
		return this.id;
	};

	/**
	 * Set sync event session id
	 *
	 * @param value
	 */
	syncEvent.prototype.setSessionId = function (value) {
		this.session_id = value;
	};

	/**
	 * Set sync event media
	 */
	syncEvent.prototype.setMedia = function (media) {
		this.media_id = media.getId();
		this.media = media;
	};

	/**
	 * Set sync event time
	 *
	 * @param value
	 */
	syncEvent.prototype.setTime = function (value) {
		this.time = value;
	};

	/**
	 * Set sync event master time
	 *
	 * @param value
	 */
	syncEvent.prototype.setMasterTime = function (value) {
		this.master_time = value;
	};

	/**
	 * Set sync event track number
	 *
	 * @param value
	 */
	syncEvent.prototype.setTrackNumber = function (value) {
		this.track_number = value;
	};

	/**
	 * Set sync event action
	 *
	 * @param value
	 */
	syncEvent.prototype.setAction = function (value) {
		this.action = value;
	};

	/**
	 * Set sync event reason
	 *
	 * @param value
	 */
	syncEvent.prototype.setReason = function (value) {
		this.reason = value;
	};

	/**
	 * Save / update the sync event
	 */
	syncEvent.prototype.save = function () {
		// We already have a pendingUpdate promise queued for this resource
		// Refresh the update and wait for the promise to perform the update
		if (this.$pendingUpdate) {
			this.$pendingUpdate = this.toJSON();
			return this.$promise;
		}

		const isDataInFlight = this.$resolved === false;
		const newSyncEvent = this.getId() == null && !isDataInFlight;

		let promise;
		if (newSyncEvent) {
			promise = this.$save();
		} else if (!isDataInFlight) {
			promise = this.$update();
		} else {
			// When the server replies, our data will be overwritten with the server response (old)
			// We need to save our data, and prevent angular from deleting it.
			Object.defineProperty(this, '$pendingUpdate', {
				enumerable: false, /*hide from angular-resource*/
				configurable: true, /*allow deletion below*/
				writable: true, /*allow modification above*/
				value: this.toJSON() /*strip $resource or $promise*/
			});
			// We are flighting some existing data to the server
			// Wait until that arrives, then update with the new data.
			promise = this.$promise.then(() => {
				angular.extend(this, this.$pendingUpdate);
				delete this.$pendingUpdate;
				return this.save();
			});
		}

		// For some reason (probably perf), angular-resource does not set
		// $promise or $resolved before the call comes back when it's an instance call
		// Manually save that data for debouncing later
		this.$resolved = false;
		return this.$promise = promise;
	};

	/**
	 * Whether sync event is deleted
	 *
	 * @returns {boolean}
	 */
	syncEvent.prototype.isDeleted = function () {
		return angular.isDate(this.deleted_at);
	};

	/**
	 * Returns a summary of the sync event
	 */
	syncEvent.prototype.getInfo = function () {
		let output = '';
		output += 'Track Number: ' + this.track_number;
		output += '\n';
		output += 'Action: ' + this.action;
		output += '\n';
		output += 'Time: ' + this.time + ' (' + Time.formatTime(this.time) + ')';
		output += '\n';
		output += 'Master Time: ' + this.master_time + ' (' + Time.formatTime(this.master_time) + ')';
		output += '\n';
		output += 'Reason: ' + this.reason;
		return output;
	};

	return syncEvent;
}
