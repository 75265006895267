import { lastValueFrom, Observable, Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { States } from 'go-modules/enums/states.enum';
import type {
	AuthResponseSuccessDataInterface
} from 'ngx/go-modules/src/services/auth/interfaces/auth-response-success.interface';
import type { LogoutSubscribeOptions } from 'ngx/go-modules/src/services/auth/interfaces/logout-subscribe-options.interface';
import type { ResetPassword, ResetPasswordRequest } from 'ngx/go-modules/src/services/auth/interfaces/auth-reset-password.interface';
import type { InviteValidation } from 'ngx/dashboard/src/interfaces/invite-validation.interface';
import type { CourseIdValidationDataInterface } from 'ngx/dashboard/src/interfaces/auth-response-course-id.interface';
import type { StudentRegisterRequestInterface } from 'ngx/dashboard/src/interfaces/sign-up/student-register-request';
import type { StudentRegisterRequest } from 'ngx/go-modules/src/interfaces';
import type { User } from 'ngx/go-modules/src/interfaces/user';
import { ResetPasswordSources } from 'ngx/go-modules/src/enums/';
import { OpenIdConfiguration } from '../../interfaces/auth-providers';

export const AUTH = {
	ROLE_COOKIE_NAME: 'gr_role',
	AUTH_USER_NAME: 'gr_user',
	IS_LTI_COOKIE_NAME: 'gr_is_lti',
	EXPIRES_AT_COOKIE_NAME: 'gr_expires_at',
	HIGHEST_USER_ROLE_COOKIE_NAME: 'gr_highest_user_role'
};

export const USER_TYPES = {
	ADMIN: 'admin',
	ADMIN_WITH_BILLING: 'admin_with_billing',
	USER: 'user'
};

export const USER_ROLES = {
	ADMIN: 'admin',
	OWNER: 'owner',
	INSTRUCTOR: 'instructor',
	REVIEWER: 'reviewer',
	PRESENTER: 'presenter'
};

export interface CheckPasswordResult {
	passwordMatched: boolean;
}

/**
 * AuthService setup result interface.
 */
export interface SetupResult {
	name: string;
	params: any;
	options: any;
}

@Injectable({
	providedIn: 'root'
})
export class NgxAuthService {

	public authLogoutSubject = new Subject();

	constructor (
		private http: HttpClient,
		@Inject('Window') private window: Window
	) {}

	/**
	 * Login to a user's account
	 */
	public login (username: string, password: string): Promise<AuthResponseSuccessDataInterface> {
		const payload = {username, password};
		const options = {withCredentials: true};
		return this.http
			.post<AuthResponseSuccessDataInterface>(`${clientSettings.GoReactV2API}/users:login`, payload, options)
			.toPromise();
	}

	/**
	 * Log out the current user
	 */
	public logout (options?: LogoutSubscribeOptions): Promise<void> {
		return this.http.post(`${clientSettings.GoReactV2API}/users:logout`, null).toPromise()
			// We don't care if the endpoint fails (and it likely will if the user's token is invalid)
			// Continue with deleting all session information
			.catch(() => {})
			.then(() => {
				this.authLogoutSubject.next(options);
			});
	}

	/**
	 * Reset a user's password
	 */
	public resetPassword (requestData: ResetPassword): Observable<AuthResponseSuccessDataInterface> {
		const payload: ResetPasswordRequest = {
			password: requestData.password,
			email: requestData.email,
			expiration: requestData.expiration,
			ticket: requestData.hash,
			email_signature: requestData.email_signature
		};

		return this.http.post<AuthResponseSuccessDataInterface>(`${clientSettings.GoReactV2API}/users:reset_password`,
			payload
		);
	}

	public checkPassword (password: string): ng.IPromise<CheckPasswordResult> {
		return this.http.post<CheckPasswordResult>(`${clientSettings.GoReactV2API}/users/check-password`,
			{ password }
		).toPromise();
	}

	/**
	 * Validate invite id
	 */
	public validateInvite (inviteId: string): ng.IPromise<InviteValidation> {
		return this.http.get<InviteValidation>(`${clientSettings.GoReactV2API}/invites/${inviteId}`)
			.toPromise();
	}

	/**
	 * Validates course code.
	 *
	 * @param {string} groupCode
	 *
	 * @returns {Promise<CourseIdValidationResponseInterface>}
	 */
	public validateGroupCode (groupCode: string): ng.IPromise<CourseIdValidationDataInterface> {
		const url = `${clientSettings.GoReactV2API}/groups/validate-code/${groupCode}`;
		return this.http.get<CourseIdValidationDataInterface>(url)
			.toPromise();
	}

	/**
	 * Request password
	 */
	public requestPassword (
		username: string, uuid: string = null,
		source: string = ResetPasswordSources.DASHBOARD
	): Observable<void> {
		return this.http.post<void>(`${clientSettings.GoReactV2API}/users:request_password`, { username, uuid, source });
	}

	/**
	 * Register student.
	 */
	public registerStudent (
		studentRequest: StudentRegisterRequest | StudentRegisterRequestInterface
	): Promise<AuthResponseSuccessDataInterface> {
		return lastValueFrom(this.http.post<AuthResponseSuccessDataInterface>(
			`${clientSettings.GoReactV2API}/users`,
			studentRequest
		));
	}

	/**
	 * Setup user.
	 * Handler on successful login or register.
	 */
	public setUpUser (user: any, options: any = {}): Promise<SetupResult> {
		// Start GA user tracking
		// todo: The window.ga function is not currently working and should be fixed in DEV-12692
		try {
			const role = user?.isInstructor ? 'instructor' : 'student';
			this.window.ga('send', 'login', `${role} login`);
		} catch (e) { } // Do nothing

		let invitePromise: Promise<any> = Promise.resolve();
		if (options.inviteId) {
			invitePromise = this.acceptInvite(user.user_id, options.inviteId);
		}

		return invitePromise.then(() => {
			if (options.redirect) {
				this.window.location.href = options.redirect;
			} else {
				const state = {
					name: States.DASHBOARD_ROOT,
					params: {},
					options: {reload: true}
				};

				if (options.groupId) {
					state.name = States.DASHBOARD_FOLDER_VIEW;
					state.params = {folderId: options.groupId};
				}
				return state;
			}
		}).catch(() => {
			throw new Error('login-accept-invite-fail-message');
		});

	}

	/**
	 * Accepts invite.
	 */
	public acceptInvite (userId: number, inviteId: string): Promise<AuthResponseSuccessDataInterface> {
		const payload = {
			user_id: userId,
			invite_uuid: inviteId
		};

		const httpUrl = `${clientSettings.GoReactV2API}/invites/${inviteId}/accept`;

		return this.http
			.post<AuthResponseSuccessDataInterface>(httpUrl, payload)
			.toPromise();
	}

	/**
	 * Checks if user has GoreactProvider.
	 */
	public hasGoreactProvider (userId) {
		return this.http.get(`${clientSettings.GoReactV2API}/users/${userId}/has_goreact_provider`)
			.toPromise();
	}

	/**
	 * Changes user's password.
	 */
	 public changePassword (payload: any) {
		return this.http.post(
			`${clientSettings.GoReactV2API}/users/${payload.user_id}/change_password`,
			payload
		);
	}

	/**
	 * Changes user's email.
	 */
	 public changeEmail (userId, payload: any) {
		return this.http.put(
			`${clientSettings.GoReactV2API}/users/${userId}/update_email`,
			payload
		).toPromise();
	}

	public connectSsoAccount (payload: any, provider: string) {
		return this.http.post(
			`${clientSettings.GoReactV2API}/sso/${provider}/link`,
			payload
		);
	}

	public unlinkSsoAccount (provider: string, payload: { password?: string } = {}): Observable<User> {
		return this.http.post<User>(
			`${clientSettings.GoReactV2API}/sso/${provider}/unlink`,
			payload
		);
	}

	public async refresh (payload: any = {}) {
		return this.http.post(`${clientSettings.GoReactV2API}/users/refresh`, payload, {withCredentials: true}).toPromise();
	}

	public isAdmin (): boolean {
		const highestUserRole = this.getCookie(AUTH.HIGHEST_USER_ROLE_COOKIE_NAME);
		if (highestUserRole && highestUserRole === USER_ROLES.ADMIN) {
			return true;
		}

		const role = this.getCookie(AUTH.ROLE_COOKIE_NAME);
		switch (role) {
			case USER_TYPES.ADMIN:
			case USER_TYPES.ADMIN_WITH_BILLING:
				return true;
			default:
				return false;
		}
	}

	public getCookie (name: string): string | null {
		const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
		if (match) {
			return match[2];
		}
		return null;
	}

	public getAuthUrl (provider, queryParams: any = {}) {
		let url = `${clientSettings.GoReactV2API}/sso/${provider}/redirect`;

		const filteredParams = Object.entries(queryParams)
			.filter((entry) => !!entry[1])
			.reduce((obj, [key, value]) => {
				obj[key] = value;
				return obj;
			}, {});

		const queryString = new URLSearchParams(filteredParams).toString();

		if (!!queryString) {
			url += `?${queryString}`;
		}

		return this.http
			.get(url)
			.toPromise();
	}

	public getSsoProviders (): Observable<OpenIdConfiguration[]> {
		return this.http.get<OpenIdConfiguration[]>(`${clientSettings.GoReactV2API}/open-id-configurations`);
	}
}
