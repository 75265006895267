import * as angular from 'angular';
import * as dayjs from 'dayjs';
import { clientSettings } from '../common/client.settings';
import { GroupService as GroupServiceClass } from 'go-modules/services/group/group.service';
import { CourseService } from 'go-modules/services/course/course.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { ExpirationPolicies } from 'ngx/go-modules/src/enums/salesforce-license';
import type { CourseGroup } from './group.interface';
import { UserService } from 'go-modules/models/user/user.service';
import { UserRoleLocalizationUtil } from 'ngx/go-modules/src/utilities/user-role-localization/user-role-localization.util';

export enum SessionCreationDisabledError {
	COURSE_NOT_STARTED = 'session-creation-error_group-not-started',
	COURSE_ENDED = 'session-creation-error_group-expired',
	LICENSE_NOT_STARTED = 'session-creation-error_license-not-started',
	LICENSE_EXPIRED = 'session-creation-error_license-expired',
	LICENSE_INACTIVE ='session-creation-error_license-inactive'
}

export interface SessionValidationResult {
	isValid: boolean;
	errorKey?: SessionCreationDisabledError;
}

export enum ActivityCreationError {
	COURSE_ENDED = 'activity-creation_course-expired-cannot-create-activity',
	LICENSE_EXPIRED = 'activity-creation_license-expired-cannot-create-activity-contact-license-manager',
	LICENSE_INACTIVE ='activity-creation_license-inactive-cannot-create-activity'
}

export interface ActivityValidationResult {
	isValid: boolean;
	errorKey?: ActivityCreationError;
}

export interface Options {
	includeDeletedUsers?: boolean;
	firstFocusSelector?: string;
}

export const GROUP_OPTIONS = {
	CURRENT_COURSE_DATE_DEFAULT_OFFSETS: {
		START_DATE: -14, // Day units
		END_DATE: 14 // Day units
	}
};

/* @ngInject */
export function Group (
	$resource: ng.resource.IResourceService,
	$q: ng.IQService,
	$log: ng.ILogService,
	Service,
	cacheManager,
	User,
	groupModelTransformer,
	GroupService: GroupServiceClass,
	courseService: CourseService,
	UserGroupModel,
	GroupModel,
	userService: UserService
) {

	let GroupData = null, GroupDepModel = null;
	const template = {};

	const getGroupTemplate = function (type) {
		return template[type] || {};
	};
	const setGroupTemplate = function (type, group) {
		if (type) {
			template[type] = group;
		}
	};

	const basePath = `${clientSettings.GoReactV2API}/user_group/:group_id`;
	const paramDefaults = {
		group_id: '@group_id',
		user_id: '@user_id'
	};

	const extraActions = {
		getLegacy: {
			url: `${clientSettings.GoReactV1API}/user_groups/:group_id`,
			method: 'GET'
		},
		getByUser: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/users/:user_id/groups`,
			isArray: true,
			transformResponse: groupModelTransformer.response
		},
		moveToNewParent: {
			url: `${clientSettings.GoReactV2API}/groups/:group_id/move`,
			method: 'PUT'
		},
		getChildren: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/groups/:group_id/children`,
			isArray: true,
			transformResponse: groupModelTransformer.response
		},
		getAncestors: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/groups/:group_id/ancestors`
		},
		getOrgSettings: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/groups/:group_id/org_settings`
		},
		getShareLink: {
			url: `${clientSettings.GoReactV2API}/groups/:group_id/join`,
			method: 'GET'
		},
		createShareLink: {
			url: `${clientSettings.GoReactV2API}/groups/:group_id/join`,
			method: 'POST'
		},
		deleteUser: {
			url: `${clientSettings.GoReactV2API}/users/:userId/groups/:groupId`,
			method: 'DELETE'
		},
		changeParticipation: {
			url: `${clientSettings.GoReactV2API}/users/:userId/groups/:groupId/participation`,
			method: 'PUT'
		},
		createAccount: {
			url: `${clientSettings.GoReactV2API}/orgs/:orgId/accounts`,
			method: 'POST',
			params: {
				orgId: '@orgId'
			},
			transformRequest: (req) => {
				delete req.orgId;
				return angular.toJson(req);
			}
		},
		updateAccount: {
			url: `${clientSettings.GoReactV2API}/accounts/:group_id`,
			method: 'PUT'
		},
		updateUserRole: {
			url: `${clientSettings.GoReactV2API}/users/:user_id/groups/:group_id`,
			method: 'PUT',
			params: {
				role: '@role'
			}
		},
		consumeSeat: {
			method: 'POST',
			url: `${clientSettings.GoReactV2API}/users/:userId/groups/:groupId/consume-seat`,
			params: {
				userId: '@userId',
				groupId: '@groupId'
			}
		},
		getOrgAccountsWithStats: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/groups/:groupId/org-accounts-with-stats`,
			params: {
				groupId: '@groupId'
			},
			isArray: true
		},
		hasLtiIntegration: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/groups/:group_id/has_lti_integration`
		},
		setupComplete: {
			method: 'POST',
			url: `${clientSettings.GoReactV2LTI}/v1p1/courses/:groupId/course-setup-complete`,
			params: {
				groupId: '@groupId'
			},
			transformRequest: (req) => {
				delete req.groupId;
				return angular.toJson(req);
			}
		}
	};

	const groupService = $resource<
		typeof GroupDepModel,
	ng.resource.IResourceExtendedClass<typeof extraActions, typeof GroupDepModel>
	>(
		basePath, paramDefaults, extraActions
	);

	// Group definition
	GroupData = {

		/** Group types */
		FOLDER: 'folder',
		COURSE: 'course',
		ACCOUNT: 'account',
		ORG: 'org',

		/** Group types with numeric precedence */
		precedence: {
			org: 1,
			orgGroup: 2,
			account: 3,
			course: 4,
			folder: 4
		},

		/** Group Roles */
		role: {
			OWNER: 'owner',
			ADMIN: 'admin',
			INSTRUCTOR: 'instructor',
			REVIEWER: 'reviewer',
			PRESENTER: 'presenter'
		},

		roles: [
			'owner',
			'admin',
			'instructor',
			'reviewer',
			'presenter'
		],

		/** Roles associated with numeric precedence */
		rolePrecedence: {
			owner: 1,
			admin: 2,
			instructor: 3,
			reviewer: 4,
			presenter: 5
		},

		// Organization type map
		OrgType: {
			BIZ: 'biz',
			EDU: 'edu',
			K12: 'k12',
			K12_PRO: 'k12pro',
			OTHER: 'other'
		},

		/** Group model */
		model (data, dontCache) {
			const groupId = data ? data.group_id : null;
			let group = !dontCache && groupId ? GroupData.getCache().get(groupId) : new GroupDepModel();

			if (!group) {
				group = data instanceof GroupDepModel ? data : new GroupDepModel();
				group.group_id = groupId;
				if (groupId) {
					GroupData.getCache().put(group.group_id, group);
				}
			}

			// extend group with data
			// TODO: Potential overwrites occur here.  If we always extend with data
			// Requests to get groups (userGroups) for other users, will overwrite roles
			// Make sure you pass the dontCache param to avoid this
			group.extend(data);

			return group;
		},

		/** Wrap multiple groups with the group model */
		setModels (array, dontCache) { // set multiple group models
			const vm = this;
			angular.forEach(array, function (data, index) {
				array[index] = vm.model(data, dontCache);
			});
			return array;
		},

		/** Get a group */
		get (id, success, fail, ignoreCache) {
			const vm = this;
			const group = ignoreCache ? null : GroupData.getCache().get(id);

			if (angular.isFunction(success)) {
				if (group) {
					success(group);
				} else {
					groupService.get({group_id: id})
						.$promise
						.then(function (data) {
							success(vm.model(data));
						})
						.catch(fail);
				}
			}

			return group;
		},

		getChildren: (...args) => {
			return groupService.getChildren.apply(null, args).$promise.then((groups) => {
				return GroupData.setModels(groups);
			}).catch($log.error);
		},

		getOrgSettings: (...args: any[]) => {
			return groupService.getOrgSettings.apply(null, args).$promise
				.catch($log.error);
		},

		getDependencies: (...args) => {
			return groupService.getAncestors.apply(null, args).$promise.then((map) => {
				Object.keys(map).forEach((key) => {
					map[key] = GroupData.setModels(map[key]);
				});
				return map;
			}).catch($log.error);
		},

		getActivityGroups (activity) {
			const deferred = $q.defer();

			GroupData.get(activity.group_id, function (currentGroup) {
				GroupData.getChildren({group_id: currentGroup.parent_id}).then(function (groups) {

					const activityGroups = groups.filter(function (group) {
						return group.isFuture() || group.isCurrent();
					});

					// check to see if current group is in list of groups
					const hasCurrentGroup = activityGroups.filter(function (group) {
						return currentGroup.getId() === group.getId();
					}).length;

					if (!hasCurrentGroup) {
						activityGroups.push(currentGroup);
					}

					deferred.resolve(activityGroups);
				});
			}, function () {
				deferred.reject();
			});

			return deferred.promise;
		},

		createAccount: (name, orgId, useTypeId = null) => {
			const payload = {
				name,
				type: GroupData.ACCOUNT,
				orgId
			} as any;

			if (useTypeId) {
				payload.use_type_id = useTypeId;
			}

			return groupService.createAccount(payload);
		},

		canBeCredited (userId, groupId) {
			return UserGroupModel.willCreditBack({
				user_id: userId,
				group_id: groupId
			});
		},

		getOrgAccountsWithStats (groupId) {
			return groupService.getOrgAccountsWithStats({groupId}).$promise;
		},

		/**
		 * Retrieve products by group
		 *
		 * @param groupId
		 * @param filterBy
		 * @param success
		 * @param fail
		 * @return {object}
		 */
		getProducts (groupId, filterBy, success, fail) {
			return Service.query(angular.extend({
				resource: 'products',
				group_id: groupId
			}, filterBy), function (response) {
				if (angular.isFunction(success)) {
					success(response);
				}
			}, fail);
		},

		/**
		 * Get group template
		 */
		getTemplate (type) {
			const newTemplate = GroupData.model({ type: type || GroupData.COURSE });
			const groupTemplate = getGroupTemplate(newTemplate.type);

			switch (newTemplate.type) {
				case GroupData.COURSE:
					// Self registration is allowed on all courses created
					newTemplate.self_registration_enabled = true;
					// set start date
					if (angular.isDate(groupTemplate.start_date)) {
						newTemplate.start_date = angular.copy(groupTemplate.start_date);
					} else {
						newTemplate.start_date = dayjs().toDate();
					}

					break;
				case GroupData.FOLDER:
					break;
			}

			return newTemplate;
		},

		/**
		 * Get published groups that a group has access to
		 *
		 * @param groupId
		 * @returns {*}
		 */
		getPublishedGroups (groupId) {
			return GroupModel.getPublishedGroups({
				group_id: groupId
			}, function (response) {
				GroupData.setModels(response);
			});
		},

		/**
		 * Get groups by user
		 *
		 * @param payload
		 */
		getByUser (payload) {
			const result = groupService.getByUser(payload);

			result.$promise.then(function (groups) {
				// Don't cache these results as the BEID return on these results
				// is the UserRole BEID. This will overwrite the group's BEID
				// so in attempts to save the group, will attempt to change what
				// license it is on.
				// I curse whomever decided to take 3 seperate objects with overlapping
				// properties and decided to merge them into a super model "UserGroup"
				GroupData.setModels(groups, /*dontCache*/ true);
			});

			return result;
		},

		/**
		 * Returns group cache
		 */
		getCache () {
			return cacheManager('group');
		}
	};

	/**
	 * Constructor
	 *
	 * @param groupType
	 */
	 GroupDepModel = function (groupType) {
		this.group_id = null;
		this.type = groupType || GroupData.COURSE;
		this.parent_id = null;
		this.name = '';
		this.start_date = null;
		this.end_date = null;
		this.created_by = null;
		this.created_at = null;
		this.deleted_at = null;

		// additional
		this.upload_limit = 2048; // 2 gb
		this.users = [];
		this.activities = [];

		/**
		 * Get id
		 *
		 * @returns {string}
		 */
		this.getId = function () {
			return this.group_id;
		};

		/**
		 * Get parent id
		 *
		 * @returns {string}
		 */
		this.getParentId = function () {
			return this.parent_id;
		};

		/**
		 * Set this group's parent id
		 *
		 * @param parentId
		 */
		this.setParentId = function (parentId) {
			if (!parentId) {
				return;
			}
			this.parent_id = parentId;
		};

		/**
		 * Whether group is org type
		 *
		 * @param atleast
		 * @returns {boolean}
		 */
		this.isOrg = function (atleast) {
			if (atleast) {
				return GroupData.precedence[this.type] <= GroupData.precedence.org;
			}
			return this.type === GroupData.ORG;
		};

		/**
		 * Whether group is account type
		 *
		 * @param atleast
		 * @returns {boolean}
		 */
		this.isAccount = function (atleast) {
			if (atleast) {
				return GroupData.precedence[this.type] <= GroupData.precedence.account;
			}
			return this.type === GroupData.ACCOUNT;
		};

		/**
		 * Whether group is folder type
		 *
		 * @returns {boolean}
		 */
		this.isFolder = function () {
			return this.type === GroupData.FOLDER;
		};

		/**
		 * Whether group is course type
		 *
		 * @returns {boolean}
		 */
		this.isCourse = function () {
			return this.type === GroupData.COURSE;
		};

		/**
		 * Whether group has higher role than specified role
		 */
		this.hasHigherRole = function (role, orEqualTo) {
			let result = false;

			if (orEqualTo) {
				result = GroupData.rolePrecedence[this.role] <= GroupData.rolePrecedence[role];
			} else {
				result = GroupData.rolePrecedence[this.role] < GroupData.rolePrecedence[role];
			}

			return result;
		};

		/**
		 * Has a role of type 'admin'
		 *
		 * @param atleast
		 * @returns {boolean}
		 */
		this.hasAdminRole = function (atleast) {
			if (atleast) {
				return GroupData.rolePrecedence[this.role] <= GroupData.rolePrecedence[GroupData.role.ADMIN];
			}
			return this.role === GroupData.role.ADMIN;
		};

		/**
		 * Has a role of type 'instructor'
		 *
		 * @param atleast
		 * @returns {boolean}
		 */
		this.hasInstructorRole = function (atleast) {
			if (atleast) {
				return GroupData.rolePrecedence[this.role] <= GroupData.rolePrecedence[GroupData.role.INSTRUCTOR];
			}
			return this.role === GroupData.role.INSTRUCTOR;
		};

		/**
		 * Has a role of type 'reviewer'
		 *
		 * @param atleast
		 * @returns {boolean}
		 */
		this.hasReviewerRole = function (atleast) {
			if (atleast) {
				return GroupData.rolePrecedence[this.role] <= GroupData.rolePrecedence[GroupData.role.REVIEWER];
			}
			return this.role === GroupData.role.REVIEWER;
		};

		/**
		 * Has a role of type 'presenter'
		 *
		 * @param atleast
		 * @returns {boolean}
		 */
		this.hasPresenterRole = function (atleast) {
			if (atleast) {
				return GroupData.rolePrecedence[this.role] <= GroupData.rolePrecedence[GroupData.role.PRESENTER];
			}
			return this.role === GroupData.role.PRESENTER;
		};

		/**
		 * Whether group is associated with a product
		 *
		 * @returns {boolean}
		 */
		this.requiresPayment = function () {
			return this.hasProduct() && this.isUserBillingParty() && !this.isUnlimited();
		};

		/**
		 * If group has been paid for
		 *
		 * @returns {boolean}
		 */
		this.hasPaid = function () {
			return this.billing_entity_id > 0;
		};

		this.isPresenterDeactivated = function () {
			return this.role === GroupData.role.PRESENTER && this.deactivated_role === true;
		};

		/**
		 * If group has an unlimited profile type
		 *
		 * @returns {boolean}
		 */
		this.isUnlimited = function () {
			return this.is_unlimited > 0;
		};

		/**
		 * If group has a billing party of user
		 *
		 * @returns {boolean}
		 */
		this.isUserBillingParty = function () {
			return this.billing_party === 'user';
		};

		/**
		 * If group is responsible for billing
		 *
		 * @returns {boolean}
		 */
		this.isSelfBillable = function () {
			return this.initial_billing_entity_id > 0 &&
				parseInt(this.billing_entity_id, 10) === parseInt(this.initial_billing_entity_id, 10);
		};

		/**
		 * Whether group is inactive
		 */
		this.isPast = function () {
			let result = false;
			if (angular.isDate(this.end_date)) {
				const endDate = angular.copy(this.end_date);
				endDate.setHours(0, 0, 0, 0);

				const now = new Date();
				now.setHours(0, 0, 0, 0);
				result = dayjs(endDate).isBefore(now);
			}
			return result;
		};

		/**
		 * Whether group is active or not. Offsets are in days.
		 *
		 * @param startDateOffset
		 * @param endDateOffset
		 * @returns boolean
		 */
		this.isCurrent = function (
			startDateOffset = GROUP_OPTIONS.CURRENT_COURSE_DATE_DEFAULT_OFFSETS.START_DATE,
			endDateOffset = GROUP_OPTIONS.CURRENT_COURSE_DATE_DEFAULT_OFFSETS.END_DATE) {
			let result = true;
			if (angular.isDate(this.start_date) && angular.isDate(this.end_date)) {
				// start date
				const startDate = angular.copy(this.start_date);
				if (startDateOffset < 0 || startDateOffset > 0) {
					startDate.setDate(startDate.getDate() + startDateOffset);
				}
				startDate.setHours(0, 0, 0, 0);

				// end date
				const endDate = angular.copy(this.end_date);
				if (endDateOffset < 0 || endDateOffset > 0) {
					endDate.setDate(endDate.getDate() + endDateOffset);
				}
				endDate.setHours(0, 0, 0, 0);

				// now
				const today = new Date();
				today.setHours(0, 0, 0, 0);
				result = (dayjs(today).isSame(startDate) || dayjs(today).isAfter(startDate)) &&
					(dayjs(today).isBefore(endDate) || dayjs(today).isSame(endDate));
			}
			return result;
		};

		/**
		 * Whether group is scheduled to become active
		 */
		this.isFuture = function () {
			let result = false;
			if (angular.isDate(this.start_date)) {
				const startDate = angular.copy(this.start_date);
				startDate.setHours(0, 0, 0, 0);

				const now = new Date();
				now.setHours(0, 0, 0, 0);
				result = dayjs(startDate).isAfter(now);
			}
			return result;
		};

		/**
		 * Retrieve the products for this group
		 *
		 * @param filterBy
		 * @param success
		 * @param fail
		 * @returns {Object}
		 */
		this.getProducts = function (filterBy, success, fail) {
			const vm = this;
			if (this.products) {
				if (angular.isFunction(success)) {
					success(vm.products, vm);
				}
			} else {
				return GroupData.getProducts(this.group_id, filterBy, function (resource) {
					vm.products = resource;

					// check to see if product is already in the global cache
					angular.forEach(vm.products, function (product, index) {
						const cachedProduct = cacheManager('product').get(product.product_id);
						if (cachedProduct) {
							vm.products[index] = cachedProduct;
						} else {
							product.price = parseFloat(product.price);
							cacheManager('product').put(product.product_id, product);
						}
					});

					if (angular.isFunction(success)) {
						success(resource, vm);
					}
				}, fail);
			}
		};

		/**
		 * Validate user can create a session for a course/folder group
		 *
		 * Note: This function allows the license to be passed in based on the current context
		 * Legacy Nav: The license is typically appended to the group
		 * Vertical Nav: The license is not in the group. It is usually retrieved from the selectedService
		 */
		this.validateCanCreateSession = function (currentUser, license): SessionValidationResult {
			const vm = this;

			// User Validation
			if (currentUser.is_root_user) {
				return { isValid: true };
			}

			// Course Validation
			const currentDateUTC = dayjs().utc();
			if (currentDateUTC.isBefore(dayjs(vm.start_date))) {
				return { isValid: false, errorKey: SessionCreationDisabledError.COURSE_NOT_STARTED };
			}

			if (vm.end_date && currentDateUTC.isAfter(dayjs(vm.end_date))) {
				return { isValid: false, errorKey: SessionCreationDisabledError.COURSE_ENDED };
			}

			// License Validation
			if (license) {
				if (!license.is_active) {
					return { isValid: false, errorKey: SessionCreationDisabledError.LICENSE_INACTIVE };
				}

				if (license.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED) {
					if (currentDateUTC.isBefore(dayjs.utc(license.current_access_date))) {
						return { isValid: false, errorKey: SessionCreationDisabledError.LICENSE_NOT_STARTED };
					}

					if (currentDateUTC.isAfter(dayjs.utc(license.ends_at))) {
						return { isValid: false, errorKey: SessionCreationDisabledError.LICENSE_EXPIRED };
					}
				}
			}

			return { isValid: true };
		};

		/**
		 * Retrieve additional data for a group including users, activities, etc...
		 */
		this.getData = function (options: Options) {
			const vm = this,
				payload = {
					group_id: vm.getId()
				};

			// if this is a course, then check the feedback session download permission
			let deferred, promise;
			if (vm.hasReviewerRole(true)) {
				deferred = $q.defer();
				promise = $q.all({
					// fetch extra group meta
					group: UserGroupModel.getData(payload).$promise,
					users: User.getByGroup({
						groupId: vm.getId(),
						includeDeleted: options?.includeDeletedUsers
					})
				});
			} else {
				deferred = $q.defer();
				promise = $q.all({
					// fetch extra group meta
					group: UserGroupModel.getData(payload).$promise
				});
			}


			// expose promise so we can know when this work is done
			vm.getDataPromise = promise;

			// success
			promise.then(function (data) {

				// extend this group with data
				if (data.hasOwnProperty('group')) {
					vm.extend(data.group);
				}

				if (data.hasOwnProperty('users')) {
					vm.users = User.setModels(data.users);
				}

				deferred.resolve(vm);
			});

			// fail
			promise.catch(function (response) {
				deferred.reject(response);
			});

			// finally
			deferred.promise.finally(function () {
				vm.dataLoaded = true;
			});

			return deferred.promise;
		};

		/** Save this group */
		this.save = function (copyFrom) {
			const vm = this;

			const payload = {
				group_id: vm.group_id,
				parent_id: vm.parent_id,
				type: vm.type,
				name: vm.name,
				copy_from: copyFrom,
				published: vm.published,
				default_activity_id: vm.default_activity_id || null,
				use_type_id: vm.use_type_id || null,
				is_public: vm.is_public,
				block_presenter_downloads: vm.block_presenter_downloads,
				product_id: null,
				start_date: null,
				end_date: null,
				org_type: null
			} as any;

			if (vm.hasOwnProperty('product_id')) {
				payload.product_id = vm.product_id;
			}

			if (vm.hasOwnProperty('start_date')) {
				if (angular.isDate(vm.start_date)) {
					if (vm.group_id > 0) {
						payload.start_date = dayjs(vm.start_date).utc().format('YYYY-MM-DD HH:mm:ss');
					} else {
						payload.start_date = dayjs(vm.start_date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
					}
				} else {
					payload.start_date = null;
				}
			}

			if (vm.hasOwnProperty('end_date')) {
				if (angular.isDate(vm.end_date)) {
					payload.end_date = dayjs(vm.end_date).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
				} else {
					payload.end_date = null;
				}
			}

			// Add org only properties to the payload
			if (vm.isOrg()) {
				if (vm.hasOwnProperty('org_type')) {
					payload.org_type = vm.org_type;
				}
			}

			// set past / future
			vm.past = vm.isPast();
			vm.future = vm.isFuture();

			// keep group template around
			if (!vm.getId()) {
				setGroupTemplate(vm.type, vm);
			}

			// Save Group. Handle courses and folders in V2
			if (vm.isFolder() || vm.isCourse()) {
				const valueData = {} as any;
				payload.course_settings = {
					start_date: payload.start_date,
					end_date: payload.end_date,
					product_id: payload.product_id ?? null
				};
				payload.billing_entity_id = parseInt(vm.billing_entity_id, 10);

				const saveCoursePromise = GroupService.saveCourse(payload);

				const promise = saveCoursePromise.then((course: CourseGroup) => {
					// If we are copying, do it now
					if (copyFrom) {
						return GroupService.copyActivities(copyFrom, course.group_id)
							.then(() => GroupData.model(angular.extend(vm, course)));
					}

					const groupModel = GroupData.model(angular.extend(vm, course));

					courseService.setCourse(groupModel);

					return groupModel;
				});

				valueData.$promise = promise;
				return valueData;
			}

			// Handle other creates and updates
			return groupService.updateAccount({
				group_id: this.group_id
			}, payload).$promise;
		};

		this.moveToNewParent = (params) => {
			return groupService.moveToNewParent(params).$promise;
		};

		/** Get user in group */
		this.getUser = function (id) {
			return this.users.find((user) => parseInt(user.getId(), 10) === parseInt(id, 10)) || null;
		};

		/**
		 *  Update a user's role in this group
		 */
		this.updateUserRole = function (user, role) {
			return groupService.updateUserRole({
				user_id: user.getId(),
				group_id: this.getId(),
				role
			});
		};

		/**
		 * Deactivate user in group
		 */
		this.deactivateUser = function (user) {
			return groupService.changeParticipation({
				userId: user.user_id,
				groupId: this.group_id
			}, {
				stop: true
			}).$promise;
		};

		/**
		 * Reactivate user to group
		 */
		this.reactivateUser = function (user) {
			return groupService.changeParticipation({
				userId: user.user_id,
				groupId: this.group_id
			}, {
				stop: false
			}).$promise;
		};

		/**
		 * Delete user from group
		 */
		this.deleteUser = function (user) {
			return groupService.deleteUser({
				userId: user.user_id,
				groupId: this.group_id
			}).$promise;
		};

		this.setupComplete = function (isComplete = true) {
			return groupService.setupComplete(
				{groupId: this.group_id, isComplete});
		};

		/** Remove an activity from this group */
		this.removeActivity = function (activity) {
			const index = this.activities.indexOf(activity);

			if (index >= 0) {
				this.activities.splice(index, 1);
			}

			return index;
		};

		this.hasLtiIntegration = function () {
			const vm = this;
			return groupService.hasLtiIntegration({
				group_id: vm.group_id
			});
		};

		this.getShareLink = () => {
			return groupService.getShareLink.apply(null, [{group_id: this.group_id}]).$promise
				.then((data) => {
					this.link = data.link;
				})
				.catch((err) => {
					$log.error(err);
					return $q.reject(err);
				});
		};

		this.createShareLink = () => {
			return groupService.createShareLink.apply(null, [{group_id: this.group_id}]).$promise
				.then((data) => {
					const splitUrl = data.link.split('/');
					this.uuid = splitUrl[splitUrl.length - 1];
					this.link = data.link;
				})
				.catch($log.error);
		};

		/** Set the product id for this group */
		this.setProductId = function (productId) {
			this.product_id = productId;
		};

		this.hasProduct = function () {
			return this.product_id > 0;
		};

		this.extendedLmsOrgName = function () {
			if (this.app_registration?.lms_name != null) {
				return `${this.name} (${this.app_registration.lms_name})`;
			} else if (this.lms_name != null) {
				return `${this.name} (${this.lms_name})`;
			} else {
				return this.name;
			}
		};

		/** Extend group model with additional data */
		this.extend = function (...args) {
			args.unshift(this);
			const group = angular.extend.apply(this, args);

			// created at at string to date object
			if (group.created_at && !angular.isDate(group.created_at)) {
				group.created_at = dayjs.utc(group.created_at).toDate();
			}

			// start date string to date object
			if (group.start_date && !angular.isDate(group.start_date)) {
				group.start_date = dayjs.utc(group.start_date).toDate();
			}

			// end date string to date object
			if (group.end_date && !angular.isDate(group.end_date)) {
				group.end_date = dayjs.utc(group.end_date).toDate();
			}

			// deleted at at string to date object
			if (group.deleted_at && !angular.isDate(group.deleted_at)) {
				group.deleted_at = dayjs.utc(group.deleted_at).toDate();
			}

			// set past / future
			group.past = group.isPast();
			group.future = group.isFuture();

			// Make sure certain properties are booleans
			group.published = group.published > 0;
			group.is_public = group.is_public > 0;
			group.block_presenter_downloads = group.block_presenter_downloads > 0;
			group.self_registration_enabled = group.self_registration_enabled > 0;

			if (group.hasOwnProperty('product_id')) {
				group.setProductId(group.product_id); // clean product id
			}

			// localization of type
			switch (group.type) {
				case GroupData.COURSE:
					group.type_localized = 'models-group_factory-type-course';
					break;
				case GroupData.FOLDER:
					group.type_localized = 'models-group_factory-type-folder';
					break;
				case GroupData.ACCOUNT:
					group.type_localized = 'models-group_factory-type-account';
					break;
				case GroupData.ORG:
					group.type_localized = 'models-group_factory-type-org';
					break;
				default:
					group.type_localized = group.type;
					break;
			}

			// localization of role
			group.role_localized = UserRoleLocalizationUtil.localize(group.role);
			return group;
		};

		/** Checks if can create activity */
		this.validateCanCreateActivity = function (license): ActivityValidationResult {
			const vm = this;

			// User Validation
			if (userService.currentUser.is_root_user) {
				return { isValid: true };
			}

			// Course Validation
			const currentDateUTC = dayjs().utc();

			if (vm.end_date && currentDateUTC.isAfter(dayjs(vm.end_date))) {
				return { isValid: false, errorKey: ActivityCreationError.COURSE_ENDED };
			}

			// License Validation
			if (license) {
				if (!license.is_active) {
					return { isValid: false, errorKey: ActivityCreationError.LICENSE_INACTIVE };
				}

				if (license.salesforce_license.expiration_policy === ExpirationPolicies.RESTRICTED) {

					if (currentDateUTC.isAfter(dayjs(license.ends_at))) {
						return { isValid: false, errorKey: ActivityCreationError.LICENSE_EXPIRED };
					}
				}
			}

			return { isValid: true };
		};

		this.consumeSeat = function (userId: number, groupId: number) {
			return groupService.consumeSeat({userId, groupId}).$promise;
		};
	};

	// expose model
	GroupData.GroupModel = GroupDepModel;

	// The deepest level a group node can be in the group tree
	GroupData.MAX_NODE_DEPTH = 3;

	/**
	 * Given a set of groups, fetch the deepest, most recently created group node
	 *
	 * @param groups
	 * @returns {Promise}
	 */
	GroupData.findDeepestGroup = function findDeepestGroup (groups, preferredAccountId) {
		const deferred = $q.defer();
		const deepestGroup = groups.reduce(function (deepestSoFar, nextGroup) {
			if (deepestSoFar.depth > nextGroup.depth) {
				return deepestSoFar;
			}

			if (nextGroup.depth > deepestSoFar.depth) {
				return nextGroup;
			}

			if (deepestSoFar.isCurrent() && nextGroup.isCurrent()) {
				if(!!preferredAccountId
					&& deepestSoFar.parent_id === preferredAccountId
					&& nextGroup.parent_id !== preferredAccountId){
					return deepestSoFar;
				}
				if(!!preferredAccountId
					&& deepestSoFar.parent_id !== preferredAccountId
					&& nextGroup.parent_id === preferredAccountId){
					return nextGroup;
				}
				if (dayjs(deepestSoFar.created_at).isBefore(dayjs(nextGroup.created_at))) {
					return nextGroup;
				}
				return deepestSoFar;
			} else if (nextGroup.isCurrent() && !deepestSoFar.isCurrent()) {
				return nextGroup;
			}

			return deepestSoFar;
		}, groups[0]);

		// Continue to fetch the children of the deepest found group (so far)
		// until we have reached the max node depth and then resolve.
		if (deepestGroup.depth === GroupData.MAX_NODE_DEPTH) {
			deferred.resolve(deepestGroup);
		} else {
			GroupData.getChildren({group_id: deepestGroup.group_id})
				.then(function (children) {
					if (!children.length) {
						deferred.resolve(deepestGroup);
					} else {
						GroupData.findDeepestGroup(children)
							.then(function (newDeepestGroup) {
								deferred.resolve(newDeepestGroup);
							});
					}
				})
				.catch(function (err) {
					deferred.resolve(deepestGroup);
					$log.error(err);
				});
		}

		return deferred.promise;
	};

	return GroupData;
}

Group.NG1_INJECTION_NAME = 'Group' as const;
export const groupToken = upgradeNg1Dependency(Group);
