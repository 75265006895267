import { throttle } from 'lodash';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';

/* @ngInject */
export const SlideDeckItemRendererController = function ($scope, eventService: EventService) {
	const vm = this;
	vm.documentViewer = null;
	vm.numPages = null;

	vm.$onInit = () => {
		// When the page number changes, render the new page
		$scope.$watch(() => {
			return vm.pageNumber;
		}, function () {
			if (vm.documentViewer) {
				vm.documentViewer.goto(vm.pageNumber);
			}
		});

		/**
		 * Clear the document scale and re-render the
		 * page when that the splitter is re-sized so
		 * that the document will fill the space provided.
		 */
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.SPLITTER_RESIZE))
			.subscribe(() => vm.validateDocumentViewerSize());
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	/**
	 * Document load event handler
	 *
	 * @param documentViewer
	 */
	vm.onDocumentLoad = function (documentViewer) {
		vm.documentViewer = documentViewer;
		vm.numPages = documentViewer.doc.numPages;
		vm.onDocumentReady({document: documentViewer});
	};

	/**
	 * Page render event handler
	 *
	 * @param pageNumber
	 */
	vm.onPageRender = function (pageNumber) {
		vm.pageNumber = pageNumber;
	};

	/**
	 * This ensures that the document viewer fills the available space
	 */
	vm.validateDocumentViewerSize = throttle(function () {
		if (vm.documentViewer) {
			vm.documentViewer.renderPage(vm.pageNumber, true);
		}
	}, 300);
};
