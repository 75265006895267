import * as angular from 'angular';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { SessionManagerService } from 'go-modules/session-manager/session-manager.service';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

const DEFAULT_OPTIONS = {
	templateId: 'fine-uploader/templates/view.html',
	uploadBlacklistId: 'fine-uploader/src/partials/video-only-whitelist.json',
	core: {
		autoUpload: false,
		button: null,
		debug: false,
		disableCancelForFormUploads: false,
		maxConnections: 3,
		camera: {
			button: null,
			ios: false
		},
		chunking: {
			concurrent: {enabled: false},
			enabled: true,
			mandatory: false,
			partSize: 2000000,
			success: {endpoint: null}
		},
		cors: {
			allowXdr: false,
			expected: true,
			sendCredentials: true
		},
		retry: {
			autoAttemptDelay: 5,
			enableAuto: true,
			maxAutoAttempts: 3,
			preventRetryResponseProperty: 'preventRetry'
		},
		request: {
			customHeaders: {},
			endpoint: '',
			filenameParams: 'qqfilename',
			forceMultipart: true,
			inputName: 'qqfile',
			params: {},
			paramsInBody: true,
			uuidName: 'qquuid',
			totalFileSizeName: 'qqtotalfilesize'
		},
		validation: {
			acceptFiles: null,
			allowedExtensions: [],
			itemLimit: 0,
			minSizeLimit: 1,
			sizeLimit: 2 * 1024 * 1024 * 1024,
			stopOnFirstInvalidFile: true
		}
	},
	s3: {
		chunking: {partSize: 5242880},
		cors: {allowXDir: true, sendCredentials: true, expected: true},
		iframeSupport: {localBlankPagePath: ''},
		objectProperties: {
			acl: 'private',
			reducedRedundancy: false,
			serverSideEncryption: false
		},
		request: {
			accessKey: '',
			endpoint: '',
			params: {},
			filenameParam: 'qqfilename'
		},
		signature: {
			customHeaders: {},
			endpoint: ''
		},
		uploadSuccess: {
			customHeaders: {},
			endpoint: '',
			params: {}
		},
		uploadFail: {endpoint: ''}
	}
};

export class FineUploaderService {
	public static readonly NG1_INJECTION_NAME = 'fineUploader' as const;

	private options;
	private originalOptions;

	/* @ngInject */
	constructor (private sessionManager: SessionManagerService) {}

	public configure (config) {
		this.originalOptions = this.originalOptions || {...config};
		if (this.options) return;
		if (typeof config === 'object') {
			this.options = {...DEFAULT_OPTIONS, ...config};

			const iframeSupportUrl = `${clientSettings.GoReactV2API}/media-uploads/success`,
				signatureUrl = `${clientSettings.GoReactV2API}/media-uploads/sign`,
				successUrl = `${clientSettings.GoReactV2API}/media-uploads/success`,
				failUrl = `${clientSettings.GoReactV2API}/media-uploads/fail`;

			this.setS3Option('iframeSupport', {
				get localBlankPagePath () { return iframeSupportUrl; }
			});
			this.setS3Option('objectProperties', {acl: this.options.object_properties.acl});
			this.setS3Option('request', {
				endpoint: this.options.request.endpoint,
				accessKey: this.options.request.access_key
			});
			this.setS3Option('signature', {
				get endpoint () { return signatureUrl; },
				customHeaders: () => {
					return this.sessionManager.getRequestHeaders();
				}
			});
			this.setS3Option('uploadSuccess', {
				get endpoint () { return successUrl; },
				customHeaders: () => {
					return this.sessionManager.getRequestHeaders();
				}
			});
			this.setS3Option('uploadFail', {
				get endpoint () { return failUrl; },
				customHeaders: () => {
					return this.sessionManager.getRequestHeaders();
				}
			});
		} else {
			throw new Error('Fine uploader config not provided!');
		}
	}

	public reset () {
		this.options = null;
	}

	/**
	 * Set template id
	 *
	 * @param value
	 */
	public setTemplateId (value: string) {
		this.options.templateId = value;
	};

	/**
	 * Set core option
	 *
	 * @param option
	 * @param value
	 */
	public setOption (option: string, value: any) {
		if (typeof value === 'object') {
			angular.extend(this.options.core[option], value);
		} else {
			this.options.core[option] = value;
		}
	};

	/**
	 * Set s3 option
	 *
	 * @param option
	 * @param value
	 */
	public setS3Option (option: string, value: any) {
		if (typeof value === 'object') {
			angular.extend(this.options.s3[option], value);
		} else {
			this.options.s3[option] = value;
		}
	};

	public getTemplateId () {
		return this.options.templateId;
	}

	public getUploadBlacklistId () {
		return this.options.uploadBlacklistId;
	}

	public getOptions () {
		return this.options.core;
	}

	public reConfigure () {
		this.reset();
		this.configure(this.originalOptions);
	}

	public getS3Options () {
		return this.extendDeep(this.getOptions(), this.options.s3);
	}

	private extendDeep (dst, ...args) {
		angular.forEach(args, (obj) => {
			if (obj !== dst) {
				angular.forEach(obj, (value, key) => {
					if (dst[key] && dst[key].constructor && dst[key].constructor === Object) {
						this.extendDeep(dst[key], value);
					} else {
						dst[key] = value;
					}
				});
			}
		});
		return dst;
	}

}

export const fineUploaderToken = upgradeNg1Dependency(FineUploaderService);
