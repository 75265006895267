export interface Bindings {
	users: any;
}

export class PresenterListController implements Bindings {
	public users: any;

	/* @ngInject */
	constructor () {}
}
