import { IHttpService, IHttpRequestTransformer, IHttpResponseTransformer } from 'angular';
import * as dayjs from 'dayjs';

export abstract class DataTransformer {

	public requestTransformers: IHttpRequestTransformer[];
	public responseTransformers: IHttpResponseTransformer[];

	constructor ($http: IHttpService) {
		this.requestTransformers = [this.transformRequest.bind(this)];
		this.responseTransformers = [$http.defaults.transformResponse[0], this.transformResponse.bind(this)];
	}

	public static propertyExists (object, ...propNames: string[]) {
		return propNames.reduce((exists, nextPropName) => {
			return exists || object[nextPropName] != null;
		}, false);
	}

	/**
	 * Helper for transforming a prop to an int
	 * @param data
	 * @param propName
	 */
	public static transformToInt (data: any, propName: string): number {
		return DataTransformer.propertyExists(data, propName) ? parseInt(data[propName], 10) : data[propName];
	}

	public static transformToDate (data: any, propName: string): Date {
		return DataTransformer.propertyExists(data, propName) ? dayjs.utc(data[propName]).toDate() : data[propName];
	}

	public static transformToBoolean (data: any, propName: string): boolean {
		if (DataTransformer.propertyExists(data, propName)) {
			return !!DataTransformer.transformToInt(data, propName);
		}
		return data[propName];
	}

	public static transformFromDate (data: any, propName: string): string {
		return DataTransformer.propertyExists(data, propName) ?
			dayjs(data[propName]).utc().format('YYYY-MM-DD HH:mm:ss') :
			data[propName];
	}

	/**
	 * Transform request
	 */
	private transformRequest (request: any): any {

		if (Array.isArray(request)) {
			return JSON.stringify(request.map(this.transformRequestData));
		}

		return JSON.stringify(this.transformRequestData(request));
	}

	/**
	 * Transform response
	 */
	private transformResponse (response: any): any {
		if (Array.isArray(response)) {
			response.forEach((data) => {
				this.transformResponseData(data);
			});
		} else {
			this.transformResponseData(response);
		}
		return response;
	}

	protected abstract transformRequestData (data: any): any;
	protected abstract transformResponseData (data: any): void;
}
