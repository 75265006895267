import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { GoSelectDirective } from './go-select.directive';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		GoMaterialModule
	],
	declarations: [
		GoSelectDirective
	],
	entryComponents: [],
	exports: [
		GoSelectDirective
	],
	providers: []
})
export class GoSelectModule {}
