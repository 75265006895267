import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import 'angular-legacy-sortablejs-maintained';

import { tagSetModel } from '../models/tag-set';
import { tagModel } from '../models/tag';

import { TagEditorProvider } from './tag-editor.provider';
import { TagEditorErrorFactory } from './tag-editor-error.factory';
import { tagEditorDirective } from './tag-editor.directive';
import { TagEditorListController } from './tag-list.controller';
import { TagEditorController } from './tag-editor.controller';
import { TagListItemController } from './tag-item.controller';
import { onBlurChangeDirective } from './directives/on-blur-change.directive';
import { cacheTemplates } from './cache-templates.run';
import { tagnameFilter } from './filters/tagname.filter';
import './style.less';
import { ngxColorMenuModule } from 'ngx/go-modules/src/components/color-menu';

export const tagEditorModule = angular.module('go.tag-editor', [
	tagSetModel,
	tagModel,
	'ng-sortable',
	angularTranslate,
	ngxColorMenuModule
])
	.provider('tagEditor', TagEditorProvider)
	.factory('TagEditorError', TagEditorErrorFactory)
	.filter('tagname', tagnameFilter)
	.directive('tagEditor', tagEditorDirective)
	.directive('onBlurChange', onBlurChangeDirective)
	.controller('TagEditorController', TagEditorController)
	.controller('TagEditorListController', TagEditorListController)
	.controller('TagListItemController', TagListItemController)
	.run(cacheTemplates)
	.name;
