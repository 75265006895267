import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';
import { sessionManager } from '../../session-manager';
import { rubricModule } from '../../rubric';

import { rubricEditorModal } from './modal.factory';

import './modal.less';

export const rubricEditorModalModule = angular.module('modal.rubric-editor', [
	goModalBootstrapModule,
	angularTranslate,
	sessionManager,
	rubricModule
])
	.factory(rubricEditorModal.NG1_INJECTION_NAME, rubricEditorModal)
	.name;
