import template from './go-banner.component.html';
import { GoBannerController } from './go-banner.controller';

export const GoBannerComponent = {
	template,
	controller: GoBannerController,
	bindings: {
		banner: '<'
	}
};
