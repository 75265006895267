/**
 * This cache allows for function references
 * to be passed as a cache item identifier.
 * With angular's $cacheFactory, you can only
 * pass strings as cache item identifiers.
 */
export function goCacheDef () {
	/**
	 * Whether a key is invalid or not
	 *
	 * @param key
	 * @returns {boolean}
	 */
	function isKeyInvalid (key) {
		return !key && key !== 0;
	}

	/**
	 * Constructor
	 */
	return function GoCache () {
		const storage = [];
		return {
			all () {
				const result = [];
				storage.forEach(function (obj) {
					result.push(obj.value);
				});
				return result;
			},
			put (key, value) {
				if (isKeyInvalid(key)) {
					throw new Error('Invalid key');
				}

				if (!this.get(key)) {
					storage.push({
						key,
						value
					});
				}
			},
			get (key) {
				if (isKeyInvalid(key)) {
					throw new Error('Invalid key');
				}

				const result = storage.filter(function (obj) {
					return key === obj.key;
				});
				return result.length ? result[0].value : null;
			},
			remove (key) {
				if (isKeyInvalid(key)) {
					throw new Error('Invalid key');
				}

				const result = storage.filter(function (obj) {
					return key === obj.key;
				});

				if (result.length) {
					const index = storage.indexOf(result[0]);
					storage.splice(index, 1);
				}
			},
			clear () {
				storage.splice(0, storage.length);
			}
		};
	};
}
