/* eslint-disable @typescript-eslint/prefer-for-of */
import { openDB, IDBPDatabase } from 'idb';
import { Subject } from 'rxjs';
import { Part, Status, Upload, UploadManagerDB } from './db.interface';
import { clientSettings } from 'go-modules/models/common/client.settings';

/* @ngInject */
export class UploadManagerService {
	public db?: IDBPDatabase<UploadManagerDB>;
	public openDB = openDB;
	public $ = new Subject();

	constructor (
		private $log: ng.ILogService,
		private $http: ng.IHttpService
	) {}

	// Setup DB
	public async init () {
		this.db = await this.openDB<UploadManagerDB>('UploadManager', 1, {
			upgrade (db) {
				// Create Uploads Store
				db.createObjectStore('uploads', {
					keyPath: 'id',
					autoIncrement: false
				}).createIndex('mediaId', 'mediaId');

				// Create Parts Store
				db.createObjectStore('parts', {
					keyPath: 'id',
					autoIncrement: true
				}).createIndex('uploadId', 'uploadId');
			}
		});
	}

	// Create Upload
	public async createUpload (mediaId: number, format: string, groupId: number) {
		const uploadId = await this.requestUpload(mediaId, format, groupId);
		await this.db.add('uploads', {
			id: uploadId,
			mediaId,
			format,
			groupId,
			createdAt: new Date()
		});

		return uploadId;
	}

	// Add Part
	public async addPart (uploadId: number, blob: Blob, lastPart = false) {
		const count = await this.countRecords('parts', 'uploadId', uploadId);
		const partId = await this.db.add('parts', {
			uploadId,
			blob,
			signedUrl: null,
			partNumber: count + 1,
			etag: null,
			size: blob.size ?? -1,
			createdAt: new Date(),
			signedAt: null,
			uploadStartedAt: null,
			uploadedCompletedAt: null,
			reportedAt: null
		});

		// Mark the upload as done for receiving new parts
		if (lastPart) {
			const upload = await this.getUpload(uploadId);
			upload.doneAddingParts = new Date();
			await this.updateUpload(upload);
		}

		// Always return the part
		return this.getPart(partId);
	}

	// Complete Upload
	public async completeUpload (uploadId: Upload['id']) {
		// Check if all parts are in an uploaded state
		const parts = await this.getParts(uploadId);
		// Do we have the final part?
		const upload = await this.getUpload(uploadId);
		if (!upload.doneAddingParts) {
			return;
		}
		// This part can be skipped in the future if we want to allow forced completions
		if (parts.some((part) => !part.reportedAt)) {
			return;
		}
		// Call V2 to finilize the upload. If this call takes too long we will instead listen
		// for a pubnub event before removing the local upload.
		await this.uploadComplete(uploadId);

		// Set upload to completed
		upload.completedAt = new Date();
		await this.updateUpload(upload);

		// Notify anyone listening to UploadManager
		this.emitStatus(uploadId);

		// Cleanup the upload and its parts
		await this.removeUpload(uploadId);
	}

	// Get Part
	public async getPart (partId: unknown) {
		return this.db.get('parts', partId as IDBKeyRange);
	}

	// Get Upload
	public async getUpload (uploadId: unknown) {
		return this.db.get('uploads', uploadId as IDBKeyRange);
	}

	// Update Part
	public async updatePart (part: Part) {
		await this.db.put('parts', part);
		return this.getPart(part.id);
	}

	// Update Upload
	public async updateUpload (upload: Upload) {
		await this.db.put('uploads', upload);
		return this.getUpload(upload.id);
	}

	// Remove Upload and Parts
	public async removeUpload (uploadId: Upload['id']) {
		const parts = await this.getParts(uploadId);
		for (const part of parts) {
			await this.db.delete('parts', part.id as unknown as string);
		}
		await this.db.delete('uploads', uploadId as unknown as string);
	}

	// Sign Part
	public async signPart (partId: unknown) {
		const part = await this.getPart(partId);
		const { uploadPartId, url } = await this.requestSignedPart(part.uploadId, part.partNumber, part.size);
		return this.updatePart({...part, remoteId: uploadPartId, signedUrl: url, signedAt: new Date()});
	}

	// Upload Part
	public async uploadPart (partId: unknown) {
		let part = await this.getPart(partId);
		this.emitStatus(part.uploadId); // Notify the user that the upload is in progress
		part = await this.updatePart({...part, uploadStartedAt: new Date()});
		const etag = await this.uploadPartToS3(part);
		part = await this.updatePart({...part, etag, uploadedCompletedAt: new Date()});
		await this.reportPartUploaded(part);
		return this.updatePart({...part, reportedAt: new Date()});
	}

	// Get Parts
	public async getParts (uploadId: Upload['id']) {
		return this.db.transaction('parts', 'readwrite').store.index('uploadId').getAll(uploadId);
	}

	// Get Uploads
	public async getUploads () {
		let cursor = await this.db.transaction('uploads').store.openCursor();
		const uploads: Upload[] = [];
		while (cursor) {
			uploads.push(cursor.value);
			cursor = await cursor.continue();
		}
		return uploads;
	}

	// Count Records
	public async countRecords (storeName: 'uploads' | 'parts', key: string, keyValue: unknown) {
		return this.db.transaction(storeName, 'readwrite').store.index(key as never).count(keyValue as IDBKeyRange) || 0;
	}

	// Add Part and Upload - Helper function to streamline adding, signing and uploading to S3
	public async addPartAndUpload (uploadId: number, blob: Blob, lastPart = false) {
		const part = await this.addPart(uploadId, blob, lastPart);

		// Wrapped in a try catch to allow user to continue recording even if
		// signing or uploading fail.
		try {
			await this.signPart(part.id);
			await this.uploadPart(part.id);
			await this.completeUpload(uploadId);
		} catch(error) {
			this.$log.error(error);
		}

		// Always return the part
		return this.getPart(part.id);
	}

	// Request Upload from V2
	public requestUpload (mediaId: number, format: string, groupId: number) {
		return this.$http.post<{uploadId: number}>(`${clientSettings.GoReactV2API}/uploads`, {media_id: mediaId, format, group_id: groupId})
			.then((response: ng.IHttpResponse<{uploadId: number}>) => response.data.uploadId)
			.catch((err) => {
				this.$log.error(err);
				throw new Error('Unable to request upload ID');
			});
	}

	// Request signed part from V2
	public requestSignedPart (uploadId: number, partNumber: number, size: number) {
		return this.$http.post<{uploadPartId: number; url: string}>(
			`${clientSettings.GoReactV2API}/uploads/${uploadId}/parts`,
			{
				part_number: partNumber,
				size
			})
			.then((response: ng.IHttpResponse<{uploadPartId: number; url: string}>) => ({
				uploadPartId: response.data.uploadPartId,
				url: response.data.url
			}))
			.catch((err) => {
				this.$log.error(err);
				throw new Error('Unable to request signed part url');
			});
	}

	// Upload part to S3
	public uploadPartToS3 (part: Part) {
		// Make sure there is no Authorization or Content-Type headers
		return this.$http.put(part.signedUrl, part.blob, { params: { skipAuthorization: true }, headers: {'Authorization': undefined, 'Content-Type': undefined}})
			.then((response) => response.headers('etag').replace(/"/g,''))
			.catch((err) => {
				this.$log.error(err);
				throw new Error('Unable to upload part');
			});
	}

	// Report completed part to V2
	public reportPartUploaded (part: Part) {
		return this.$http.put(`${clientSettings.GoReactV2API}/uploads/${part.uploadId}/parts/${part.remoteId}`, {etag: part.etag})
			.catch((err) => {
				this.$log.error(err);
				throw new Error('Unable to report part etag');
			});
	}

	// Trigger upload complete
	public uploadComplete (uploadId: Upload['id']) {
		return this.$http.put(`${clientSettings.GoReactV2API}/uploads/${uploadId}`, null)
			.catch((err) => {
				this.$log.error(err);
				throw new Error('Unable to complete upload');
			});
	}

	// Emit Upload Status
	public async emitStatus (uploadId: Upload['id']) {
		const upload = await this.getUpload(uploadId);
		const parts = await this.getParts(uploadId);
		const completedParts = parts.filter((part) => part.reportedAt);
		this.$.next({
			uploadId,
			status: upload.completedAt ? Status.COMPLETE : Status.UPLOADING,
			allPartsAdded: upload.doneAddingParts,
			totalParts: parts.length,
			uploadedParts: completedParts.length
		});
	}

	// Resume uploads
	public async checkAndRetryUploads () {
		const uploads = await this.getUploads();
		const errors: unknown[] = [];
		for (let uploadInc = 0; uploadInc < uploads.length; uploadInc++) {
			const upload = uploads[uploadInc];
			try {
				const parts = await this.getParts(upload.id);
				for (let i = 0; i < parts.length; i++) {
					const part = parts[i];
					if (!part.reportedAt) {
						await this.signPart(part.id);
						await this.uploadPart(part.id);
					};
				}

				// If the user has marked the session done for adding parts
				// complete the upload. If they have not marked the upload
				// done for adding parts then they will have the ability to resume
				// adding parts.
				if (upload.doneAddingParts) {
					await this.completeUpload(upload.id);
				}
			} catch (error) {
				this.$log.error(error);
				errors.push(error);
			}
		}
		if (errors.length) {
			throw new Error(errors.toString());
		}
	}
}
