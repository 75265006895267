import { Component, Inject, Injector, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { NgxLicenseUpgradeService } from 'ngx/go-modules/src/services/license/license-upgrade/license-upgrade.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { BehaviorSubject, EMPTY, Subject, takeUntil } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

export interface LicenseUpgradeSnackbarData {
	mode: LicenseUpgradeSnackbarType;
}

export enum LicenseUpgradeSnackbarType {
	SUCCESS = 'success',
	PLUS_FEATURES_SUCCESS = 'plus_features_success',
	UPGRADE = 'upgrade'
}

@Component({
	selector: 'license-upgrade-snack-bar',
	template: require('./license-upgrade-snack-bar.component.html'),
	styles: [require('./license-upgrade-snack-bar.component.scss')]
})
export class LicenseUpgradeSnackBarComponent implements OnDestroy {
	public titleTranslationKey = '';
	public messageTranslationKey = '';
	public actionButtonTranslationKey = '';
	public learnMoreLink = NgxLicenseUpgradeService.getLearnMoreLink();
	public LicenseUpgradeSnackbarType = LicenseUpgradeSnackbarType;
	public componentDestroyed$$ = new Subject();
	public loading$ = new BehaviorSubject(false);

	constructor (
		private snackbarRef: MatSnackBarRef<LicenseUpgradeSnackBarComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: LicenseUpgradeSnackbarData,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService,
		private injector: Injector
	) {
		if (this.data.mode === LicenseUpgradeSnackbarType.UPGRADE) {
			this.titleTranslationKey = 'license-upgrade-snackbar_upgrade-title';
			this.messageTranslationKey = 'license-upgrade-snackbar_upgrade-message';
			this.actionButtonTranslationKey = 'common_upgrade';
		} else if (this.data.mode === LicenseUpgradeSnackbarType.PLUS_FEATURES_SUCCESS) {
			this.titleTranslationKey = 'license-upgrade-snackbar_plus-title';
			this.messageTranslationKey = 'license-upgrade-snackbar_plus-message';
			this.actionButtonTranslationKey = 'common_done';
		} else {
			this.titleTranslationKey = 'license-upgrade-snackbar_success-title';
			this.messageTranslationKey = 'license-upgrade-snackbar_success-message';
			this.actionButtonTranslationKey = 'common_done';
			// Link to AI support article
			this.learnMoreLink = 'https://help.goreact.com/hc/en-us/articles/26176190875661';
		}
	}

	public static open (snackBar: MatSnackBar, data: LicenseUpgradeSnackbarData) {
		return snackBar.openFromComponent(
			LicenseUpgradeSnackBarComponent,
			{
				panelClass: 'snack-bar-panel',
				horizontalPosition: 'end',
				data
			}
		);
	}

	public close () {
		this.snackbarRef.dismiss();
	}

	public openPaymentPanelOrClose () {
		if (this.data.mode !== LicenseUpgradeSnackbarType.UPGRADE) {
			return this.close();
		}

		this.loading$.next(true);
		const license = this.selectedService.getLicense();
		// We can't inject NgxLicenseUpgradeService in the constructor because it causes a circular dependency even
		// though the service only opens this snackbar in success mode. Instead of separating this component into
		// two other components SUCCESS vs UPGRADE, we can just inject the service in this one case where we need it
		const ngxLicenseUpgradeService = this.injector.get(NgxLicenseUpgradeService);
		const result = ngxLicenseUpgradeService.upgradeOrPurchase(license);

		result.pipe(
			switchMap(({requestUpgrade}) => {
				if (requestUpgrade) {
					// Non license-admin requested an upgrade
					return ngxLicenseUpgradeService.handleLicenseUpgradeRequest(license.id);
				} else {
					this.close();
					return EMPTY;
				}
			}),
			takeUntil(this.componentDestroyed$$),
			finalize(() => {
				this.loading$.next(false);
			})
		).subscribe();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}
}
