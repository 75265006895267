import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { common } from '../common';
import { userModel } from '../user';
import { mediaModel } from '../media';
import { syncEventModel } from '../sync-event';
import { commentModel } from '../comment';
import { attachmentModel } from '../attachment';

import { Session } from './session.service';
import { SESSION_CONSTANTS } from './session.constant';
import { sessionResourceServiceDef } from './session-resource.service';
import { sessionModelInterceptorService } from './session-interceptor.service';
import { sessionModelTransformerService } from './session-transformer.service';
import { featureFlagModule } from 'go-modules/feature-flag';
import { ngxGoToastModule } from 'ngx/go-modules/src/components/go-toast';

// TODO: Resolve circular dependency
// import /*{ activityModel } from*/ '../activity'; -- commented to satisfy eslint
import '../activity';

export const sessionModel = angular.module('go.models.session', [
	angularResource,
	common,
	userModel,
	mediaModel,
	syncEventModel,
	commentModel,
	attachmentModel,
	featureFlagModule,
	ngxGoToastModule,
	'go.models.activity' // TODO: Activity and session depend on each other, so we have to use strings
])
	.constant('SessionConstants', SESSION_CONSTANTS)
	.service('sessionResourceService', sessionResourceServiceDef)
	.service('sessionModelInterceptor', sessionModelInterceptorService)
	.service('sessionModelTransformer', sessionModelTransformerService)
	.service(Session.NG1_INJECTION_NAME, Session)
	.name;
