/* @ngInject */
export function goGlobalEventService (EventChannel) {
	const GoGlobalEventService = function () {
		const eventChannel = new EventChannel();
		this.trigger = eventChannel.broadcast.bind(eventChannel);
		this.on = eventChannel.subscribe.bind(eventChannel);
		this.once = eventChannel.subscribeOnce.bind(eventChannel);
		this.off = eventChannel.unsubscribe.bind(eventChannel);
	};
	return new GoGlobalEventService();
}
