export enum BROWSER_NAMES {
	CHROME = 'chrome',
	CHROME_HEADLESS = 'chrome', // Needed for CI e2es, treat Chrome Headless as supported Browser
	EDGE = 'edge',
	CHROMIUM_EDGE = 'chromium_edge',
	FIREFOX = 'firefox',
	IE = 'ie',
	SAFARI = 'safari',
	MOBILE_SAFARI = 'mobile safari'
}
