import template from './content.component.html';
import { FeedbackSessionContentController, FeedbackSessionContentControllerScope, FeedbackSessionContentControllerClass } from './content.controller';

export interface FeedbackSessionContentComponentScope extends FeedbackSessionContentControllerScope {
	contentController: FeedbackSessionContentControllerClass;
}

export const feedbackSessionContent: ng.IComponentOptions = {
	template,
	require: {
		mainController: '^feedbackSession'
	},
	controller: FeedbackSessionContentController,
	controllerAs: 'contentController'
};
