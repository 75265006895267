import template from './tour-step.component.html';
import * as angular from 'angular';

export const tourStepDirective = function () {
	return {
		template,
		scope: {
			tour: '<',
			step: '<'
		},
		transclude: {
			body: 'tourStepBody',
			footer: '?tourStepFooter'
		},
		controller () {},
		controllerAs: '$ctrl',
		bindToController: true,
		link (scope, elem, _attrs, _ctrl, transcludeFn) {

			// By default, transcluded content gets access to the parent
			// control of where it was injected.  We need these transcluded
			// children to access the directive scope to access next, prev, end functions
			transcludeFn(scope, function (content) {
				const el = angular.element(elem[0].querySelector('.tour-step-footer-slot'));
				el.empty();
				el.append(content);
			}, null, 'footer');

			transcludeFn(scope, function (content) {
				const el = angular.element(elem[0].querySelector('.tour-step-body-container'));
				el.empty();
				el.append(content);
			}, null, 'body');
		}
	};
};
