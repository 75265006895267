import template from './stimulus-response-viewer.component.html';
import { StimulusResponseViewerController as controller } from './stimulus-response-viewer.controller';

export const stimulusResponseViewer = {
	template,
	require: {
		mainController: '^feedbackSession',
		multiMediaDisplayController: '^multiMediaDisplay'
	},
	controller,
	bindings: {
		responseMedia: '<?',
		stimulusMedia: '<'
	}
};
