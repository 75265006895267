import * as angular from 'angular';

/* @ngInject */
export function GoStorageFactory ($cookies, $window) {
	let storage;

	const webStorageSupported = function () {
		try {
			return 'localStorage' in $window && $window.localStorage !== null;
		} catch (e) {
			return false;
		}
	};

	if (webStorageSupported()) { // use web storage
		storage = $window.localStorage;
		return function (storageId) {
			if (!storage[storageId]) {
				storage[storageId] = '""';
			}

			const setStorageShelf = function (shelf) {
				storage.setItem(storageId, angular.toJson(shelf));
			};

			const getStorageShelf = function () {
				return angular.fromJson(storage.getItem(storageId)) || {};
			};

			this.get = function (id) {
				const shelf = getStorageShelf();
				return shelf[id];
			};

			this.put = function (id, value) {
				const shelf = getStorageShelf();
				shelf[id] = value;
				setStorageShelf(shelf);
			};

			this.remove = function (id) {
				const shelf = getStorageShelf();
				delete shelf[id];
				setStorageShelf(shelf);
			};
		};
	}
	storage = $cookies;
	return function (storageId) {
		if (!angular.isObject(storage.getObject(storageId))) {
			storage.putObject(storageId, {});
		}

		const getStorageShelf = function () {
			return storage.getObject(storageId);
		};

		this.get = function (id) {
			const shelf = getStorageShelf();
			return shelf[id];
		};

		this.put = function (id, value) {
			const shelf = getStorageShelf();
			shelf[id] = value;
			storage.putObject(storageId, shelf);
		};

		this.remove = function (id) {
			const shelf = getStorageShelf();
			delete shelf[id];
			storage.putObject(storageId, shelf);
		};
	};
}
