import * as angular from 'angular';
import { InviteFactory, InviteModel } from '../../models/invite/invite.schema';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { LICENSE_TRANSACTION_TYPE, PAYMENT_TYPES, PURCHASE_TYPES } from 'go-modules/payment-panel/payment-panel.controller';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import type { LazyPaymentLoader } from 'go-modules/payment-panel/lazy-payment-loader.service';
import type { UserService } from 'go-modules/models/user/user.service';
import type { GoToastOptions } from 'ngx/go-modules/src/interfaces/go-toast-options';
import { SeatOveragePolicies } from 'ngx/go-modules/src/enums/salesforce-license';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';

/* @ngInject */
export function InviteUsersModalController (
	$scope,
	$log: angular.ILogService,
	goModal,
	Group,
	$filter,
	$document: ng.IDocumentService,
	$translate: ng.translate.ITranslateService,
	helpUrls,
	confirmModal,
	inviteFactory: InviteFactory,
	featureFlag: FeatureFlag,
	ngxGoToastService: NgxGoToastService,
	PaymentLoader: LazyPaymentLoader,
	userService: UserService,
	GroupService,
	ngxDowngradeModalService: DowngradeModalService
) {

	const vm = this;
	$scope.featureFlag = featureFlag;
	$scope.seatUsageSuccessLevel = 25;
	$scope.seatUsageWarningLevel = 10;
	$scope.usersCount = 0;
	$scope.selectedRole = null;
	$scope.sentInvites = [];

	vm.$onInit = () => {
		if (!$scope.group) {
			throw new Error('InviteUsersModalController:: must supply group');
		}

		$scope.options = {};
		$scope.options.inviteForm = {};
		$scope.Group = Group;
		$scope.helpUrls = helpUrls;
		$scope.roles = [
			{
				key: Group.role.ADMIN,
				title: $translate.instant(Group.role.ADMIN),
				description: $translate.instant('modal-invite_role-item-description-admin')
			},
			{
				key: Group.role.INSTRUCTOR,
				title: $translate.instant(Group.role.INSTRUCTOR),
				description: $translate.instant('modal-invite_role-item-description-instructor')
			}];

		if (!$scope.group.isOrg()) {
			$scope.roles = [
				...$scope.roles,
				{
					key: Group.role.REVIEWER,
					title: $translate.instant(Group.role.REVIEWER),
					description: $translate.instant('modal-invite_role-item-description-reviewer')
				}
			];
		}

		$scope.options.groupLinkEnabled = $scope.group.isCourse() || $scope.group.isFolder();
		$scope.options.showInvite = !$scope.options.groupLinkEnabled;

		// Add Presenter as an option if Group is a course or Folder
		if ($scope.group.isCourse() || $scope.group.isFolder()) {
			$scope.roles.push({
				key: Group.role.PRESENTER,
				title: $translate.instant(Group.role.PRESENTER),
				description: $translate.instant('modal-invite_role-item-description-presenter')
			});

			GroupService.getCourseUsersList($scope.group.group_id).
				then((result) => $scope.usersCount = result.length);

			// If course is not using product, then check license seats availability
			if (!$scope.group.product_id) {
				// get license for most current seats remaining
				// also need full license here to pass to the pay form
				GroupService.getLicense($scope.group.group_id).then((res) => {
					const license = res;
					if (license && license.salesforce_license.seat_overage_policy === SeatOveragePolicies.RESTRICTED) {
						$scope.sfLicense = license.salesforce_license;
						$scope.showSeatsAvailabilityToast();
					}
				});
			}
		}

		const groupRole = userService.currentUser.is_root_user ? Group.role.OWNER : $scope.group.role;
		$scope.roles = $filter('roleFilter')($scope.roles, groupRole);
		$scope.selectedRole = $scope.roles[$scope.roles.length - 1];

		// Determine if this has an appIntegration
		$scope.group.hasLtiIntegration().$promise.then(function (details) {
			$scope.hasLtiIntegration = details.has_lti_integration;
		}).catch($log.error);

		// load share link
		if ($scope.options.groupLinkEnabled) {
			$scope.group.getShareLink();
		}
	};

	$scope.showSeatsAvailabilityToast = () => {
		const seatsAvailablePercent = $scope.getSeatsAvailablePercent();
		let toastType: string = '';
		let toastMessage: string = '';

		if (seatsAvailablePercent > $scope.seatUsageSuccessLevel) {
			toastType = GoToastStatusType.SUCCESS;
		} else if (seatsAvailablePercent >= $scope.seatUsageWarningLevel) {
			toastType = GoToastStatusType.WARNING;
		} else {
			toastType = GoToastStatusType.ERROR;
		}

		const toastDetails: GoToastOptions = {
			type: toastType,
			hideToastTime: 10000,
			message: ''
		};

		if ($scope.sfLicense.is_license_admin) {
			toastMessage = 'modal-invite-user_seats-toast-message-admin';
			toastDetails.actionLink = {
				title: $translate.instant('modal-invite-user_seats-toast-message-admin-purchase-seats'),
				action: $scope.purchaseSeats
			};
		} else {
			toastMessage = 'modal-invite-user_seats-toast-message-non-admin';
		}

		toastDetails.message = $translate.instant(toastMessage, {
			seatCount: $scope.sfLicense.total_seats - $scope.sfLicense.total_seats_consumed
		});

		ngxGoToastService.createToast(toastDetails);
	};

	$scope.purchaseSeats = async () => {
		// dismiss invite user modal
		goModal.dismissAll();

		// open payment sidepanel
		const account = $scope.Group.get($scope.group.parent_id);
		$scope.sfLicense.license = $scope.group.license;
		await PaymentLoader.openPayForm(
			userService.currentUser,
			account,
			() => {},
			$scope.sfLicense,
			PAYMENT_TYPES.CARD,
			PURCHASE_TYPES.LICENSE,
			LICENSE_TRANSACTION_TYPE.SEATS
		);
	};

	$scope.getSeatsAvailablePercent = () => {
		return 100 - (($scope.sfLicense.total_seats_consumed / $scope.sfLicense.total_seats) * 100);
	};

	$scope.openNewJoinLinkModal = function () {
		if (!$scope.group.uuid) {
			$scope.group.createShareLink();
			return;
		}

		ngxDowngradeModalService.openConfirmDialog({
			title: $translate.instant('modal-new-join-link_new-link'),
			message: $translate.instant('modal-new-join-link_sure-create-link')
		}, {disableClose: true})
			.then(() => $scope.group.createShareLink())
			.catch(angular.noop);
	};

	$scope.handleSendInvite = function (form: ng.IFormController) {
		if (form.$invalid) {
			if(form.$getControls()[0].$invalid) {
				const focusable = $document[0].querySelector('.invalid-submit-select.ng-invalid') as HTMLElement;
				focusable.focus();
			} else {
				const focusable = $document[0].querySelector('uib-dropdown button') as HTMLElement;
				focusable.focus();
			}

			return;
		}

		if (form.roleField.$viewValue.key === Group.role.ADMIN ||
			form.roleField.$viewValue.key === Group.role.INSTRUCTOR) {

			let key = '';
			switch ($scope.group.type) {
				case Group.FOLDER: key = 'models-group_factory-type-folder'; break;
				case Group.COURSE: key = 'models-group_factory-type-course'; break;
				case Group.ACCOUNT: key = 'models-group_factory-type-account'; break;
				case Group.ORG: key = 'models-group_factory-type-org'; break;
			}

			// show confirmation
			const localizedGroupType = $translate.instant(key);

			// These keys are being used dynamically here. This comment helps the un-used translations check
			// modal-invite-users_controller-invite-confirm-role-presenter
			// modal-invite-users_controller-invite-confirm-role-reviewer
			// modal-invite-users_controller-invite-confirm-role-instructor
			// modal-invite-users_controller-invite-confirm-role-admin
			confirmModal.open({
				modalData: {
					message: $translate.instant(
						'modal-invite-users_controller-invite-confirm-text',
						{role: $filter('capitalize')($translate.instant(`modal-invite-users_controller-invite-confirm-role-${form.roleField.$viewValue.key}`)), group: localizedGroupType}
					),
					no: 'common_cancel',
					title: $translate.instant(
						'modal-invite-users_controller-invite-confirm-title', {role: $filter('capitalize')($translate.instant(`modal-invite-users_controller-invite-confirm-role-${form.roleField.$viewValue.key}`))}
					),
					yes: $translate.instant(
						'modal-invite-users_controller-invite-confirm-yes', {role: $filter('uppercase')($translate.instant(`modal-invite-users_controller-invite-confirm-role-${form.roleField.$viewValue.key}`))}
					)
				}
			}).result.then(() => {
				$scope.sendInvites(form);
			}).catch($log.error);
		} else {
			$scope.sendInvites(form);
		}

	};

	$scope.sendInvites = (form) => {
		// send the invite
		const invites = form.emails.map((email) => {
			return new inviteFactory({
				email,
				role: form.roleField.$viewValue.key
			} as InviteModel);
		});

		inviteFactory.sendInvites({
			groupId: $scope.group.group_id
		}, invites).$promise.then((results) => {
			form.emails = [];
			form.$setPristine();
			form.$setUntouched();
			// iterate over them because inviteFactory uses $resource
			// and we need to exclude $promise properties
			results.forEach((result) => {
				$scope.sentInvites.push(result);
			});
			ngxGoToastService.createToast({
				type: GoToastStatusType.SUCCESS,
				message: 'invite-sent'
			});
		});
	};

}
