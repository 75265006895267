import './style.less';
import template from './presenter-list.component.html';
import { PresenterListController as controller } from './presenter-list.controller';

export const PresenterListComponent = {
	controller,
	template,
	bindings: {
		users: '<'
	}
};
