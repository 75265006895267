import { Injectable } from '@angular/core';
import { Observable, Subject, filter } from 'rxjs';
import { EVENT_NAMES } from './event-names.constants';

export interface GoEvent {
	name: string;
	data?: any;
}

/**
 * A service used to broadcast and listen for events
 */
@Injectable({
	providedIn: 'root'
})
export class EventService {

	/**
	 * Can listen/subscribe to events
	 *
	 * **Usage:**
	 * ```
	 * eventService.events
	 * .pipe(filter((ev: GoEvent) => ev.name === 'event:name'))
	 * .subscribe((ev: GoEvent) => {
	 * // do something with ev.data
	 * })
	 * ```
	 * Important Note: Should always unsubscribe from event
	 * i.e. in $onDestroy method
	 * eventSubscription?.unsubscribe()
	 * @deprecated $scope.$broadcast()
	 * @deprecated $scope.$emit()
	 */
	public events = new Subject<GoEvent>();

	constructor () {}

	public listen (name: EVENT_NAMES | EVENT_NAMES[]): Observable<GoEvent> {
		return this.events.asObservable()
			.pipe(filter(((event) => {
				if(Array.isArray(name)) {
					return name.some((eventName) => eventName === event.name);
				} else {
					return event.name === name;
				}
			})));
	}

	/**
	 * Broadcasts an event
	 * Data can be any type (string, boolean, object, callback function, etc.)
	 *
	 * **Usage:**
	 * ```
	 * eventService.broadcast('event:name', {foo: bar})
	 * ```
	 */
	public broadcast (name: string, data: any = null): void {
		this.events.next({name, data});
	}
}
