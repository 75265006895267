import { MotionDetectionOptions } from './motion-detector-options';
import { v4 as uuid } from 'uuid';
import './style.less';

const CAPTURE_INTERVAL = 150;
const DEFAULT_DETECTION_TIMEOUT = 5000;

// Individual pixel sensitivity to change
const PIXEL_DIFF_THRESHOLD = 35;

// Total number of pixels with motion detected
// in a given frame
const SCORE_THRESHOLD = 2;

const DIFF_PIXEL_RATIO = 10;

const HAVE_ENOUGH_DATA = 4;

export class MotionDetector {
	// Canvas used for capturing snapshots of stream
	// at interval defined in options
	private captureCanvas: HTMLCanvasElement;

	// Used to calculate the diffs in pixels captured
	// from captureCanvas
	private diffCanvas: HTMLCanvasElement;
	private motionScore: number = 0;
	private diffReady: boolean = false;
	private uniqueClass: string;
	private totalRuns: number;
	private captureTimeoutId: number;

	constructor (
		private videoEle: HTMLVideoElement,
		private options: MotionDetectionOptions = {
			detectionTimeout: DEFAULT_DETECTION_TIMEOUT,
			imageCaptureInterval: CAPTURE_INTERVAL
		}
	) {
		this.totalRuns = Math.floor(this.options.detectionTimeout / this.options.imageCaptureInterval);
		this.uniqueClass = `md-${uuid()}`;
	}

	public run (): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const captureReady = () => {
				this.videoEle.removeEventListener('canplay', captureReady);
				Object.assign(
					this.options,
					this.getVideoSize(this.videoEle as HTMLVideoElement)
				);
				this.captureCanvas = this.createCanvas(
					this.options.height,
					this.options.width
				);
				this.diffCanvas = this.createCanvas(
					this.options.height / DIFF_PIXEL_RATIO,
					this.options.width / DIFF_PIXEL_RATIO
				);
				this.startCapture().then((success) => {
					this.destroy();
					resolve(success);
				});
			};

			// If the video element is not ready, add a listener
			// Else run the check immediately
			if (this.videoEle.readyState < HAVE_ENOUGH_DATA) {
				this.videoEle.addEventListener('canplay', captureReady);
			} else {
				captureReady();
			}
		});
	}

	public motionDetected (): boolean {
		return this.motionScore > SCORE_THRESHOLD;
	}

	public destroy () {
		[].forEach.call(document.querySelectorAll(`.${this.uniqueClass}`), (el) => {
			el.parentNode.removeChild(el);
		});
		clearTimeout(this.captureTimeoutId);
		delete this.captureCanvas;
		delete this.diffCanvas;
	}

	private processDiff (imageData: ImageData, threshold: number): number {
		const rgba = imageData.data;
		let score = 0;

		// Each 4 items represent RGBA of a single pixel
		for (let i = 0; i < rgba.length; i += 4) {
			const pixelDiff = rgba[i] * 0.3 + rgba[i + 1] * 0.6 + rgba[i + 3] * 0.1;

			if (pixelDiff >= threshold) {
				score++;
			}
		}

		return score;
	}

	private createCanvas (height: number, width: number): HTMLCanvasElement {
		const canvas = document.createElement('canvas');
		canvas.classList.add('motion-detector');
		canvas.classList.add(this.uniqueClass);
		canvas.width = width;
		canvas.height = height;
		document.body.appendChild(canvas);
		return canvas;
	}

	private getVideoSize (video: HTMLVideoElement): MotionDetectionOptions {
		const options = {} as MotionDetectionOptions;

		options.height = video.videoHeight;
		options.width = video.videoWidth;

		return options;
	}

	private startCapture (): Promise<boolean> {
		this.totalRuns--;

		if (this.totalRuns === 0) {
			return Promise.resolve(false);
		}

		this.capture();

		if (this.motionDetected()) {
			return Promise.resolve(true);
		}

		return new Promise((resolve) => {
			this.captureTimeoutId = window.setTimeout(() => {
				this.startCapture().then(resolve);
			}, CAPTURE_INTERVAL);
		});
	}

	private capture () {
		const diffHeight = this.options.height / DIFF_PIXEL_RATIO;
		const diffWidth = this.options.width / DIFF_PIXEL_RATIO;

		// Diff over previous capture
		const diffContext = this.diffCanvas.getContext('2d', {willReadFrequently: true});
		diffContext.globalCompositeOperation = 'difference';
		diffContext.drawImage(this.videoEle, 0, 0, diffWidth, diffHeight);
		const diffImageData = diffContext.getImageData(0, 0, diffWidth, diffHeight);

		if (this.diffReady) {
			const score = this.processDiff(
				diffImageData,
				PIXEL_DIFF_THRESHOLD
			);
			this.motionScore = Math.max(this.motionScore, score);
		}

		diffContext.globalCompositeOperation = 'source-over';
		diffContext.drawImage(this.videoEle, 0, 0, diffWidth, diffHeight);
		this.diffReady = true;
	}
}
