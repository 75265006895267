export interface Bindings {
	disabled: string;
}

export class ToolbarController implements Bindings {
	public disabled: string;

	public isDisabled (): boolean {
		return !!this.disabled;
	}
}
