import { isString } from 'lodash';

export function replaceFilter () {
	return (value, symbol, newValue) => {
		if (!value) {
			return value;
		} else if (!isString(value)) {
			value = value.toString();
		}
		return value.replace(symbol, newValue);
	};
}
