import type { HasMedia, MediaSchema } from '../media';

export interface ActivitySourceMedia {
	media_id: number;
	media: MediaSchema | null;
}

export class ActivitySourceMediaModel implements HasMedia {

	public media_id: number;
	public media: MediaSchema | null;

	constructor (attributes?: ActivitySourceMedia) {
		this.init(attributes);
	}

	public static create (attributes?: ActivitySourceMedia): ActivitySourceMediaModel {
		return new ActivitySourceMediaModel(attributes || {} as ActivitySourceMedia);
	}

	public attachMediaData (media: MediaSchema): void {
		this.media = media;
		this.media_id = media ? media.media_id : null;
	}

	private init ({ media_id, media }): void {
		this.media_id = media_id;
		this.media = media;
	}
}
