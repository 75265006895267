import { DataTransformer } from '../models/common/data-transformer';
import { IHttpService } from 'angular';
import type { Transaction, CardPurchasePayload } from './braintree.service';

export class BraintreeTransformer extends DataTransformer {

	/* @ngInject */
	constructor ($http: IHttpService) {
		super($http);
	}

	public static response (data: Transaction): Transaction {
		if (data?.created_at) {
			data.created_at = DataTransformer.transformToDate(data, 'created_at');
		}
		if (data?.modified_at) {
			data.modified_at = DataTransformer.transformToDate(data, 'modified_at');
		}
		if (data?.deleted_at) {
			data.deleted_at = DataTransformer.transformToDate(data, 'deleted_at');
		}

		return data;
	}

	public static request (data: CardPurchasePayload) {
		// No request transformations
		return data;
	}

	protected transformRequestData (purchase: CardPurchasePayload): any {
		return BraintreeTransformer.request(purchase);
	}

	protected transformResponseData (data: any): Transaction {
		return BraintreeTransformer.response(data);
	}
}
