import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { attachmentModel } from '../models/attachment';
import { mediaModel } from '../models/media';

import goAttachmentComponent from './attachment/attachment.component';
import goAttachmentsComponent from './attachments/attachments.component';

import './style.less';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.

// tslint:disable-next-line:import-spacing
import /*{ goModal } from*/ '../modals';
// tslint:disable-next-line:import-spacing
import /*{ universalMediaChooserModule } from*/ '../universal-media-chooser';
// tslint:disable-next-line:import-spacing
import /*{ mediaThumbnailModule } from*/ '../media-thumbnail';
// tslint:disable-next-line:import-spacing
import /*{ libraryCollectionViewerModalModule } from*/ 'go-modules/modals/library-collection-viewer';

export const goAttachment = angular.module('go.attachment', [
	angularTranslate,
	attachmentModel,
	mediaModel,

	// TODO: use module strings directly until circular dependency is fixed
	'go.modal',
	'modal.library-collection-viewer',
	'universal-media-chooser',
	'go.mediaThumbnail'
])
	.component('goAttachment', goAttachmentComponent)
	.component('goAttachments', goAttachmentsComponent)
	.name;
