import * as angular from 'angular';
import * as dayjs from 'dayjs';
import { UserRoleLocalizationUtil } from 'ngx/go-modules/src/utilities/user-role-localization/user-role-localization.util';

/* @ngInject */
export function userModelTransformer ($http, $translate) {

	const dateFields = [
		'created',
		'last_login_at',
		'accepted_terms_at'
	];

	/**
	 * Transform request data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformRequestData (data) {
		const payload = angular.extend({}, data);

		// format dates
		dateFields.forEach((key) => {
			if (payload.hasOwnProperty(key)) {
				const date = dayjs(payload[key]);

				if (date.isValid() && date.get('year') > 0) {
					payload[key] = dayjs(payload[key]).utc().format('YYYY-MM-DD HH:mm:ss');
				} else {
					payload[key] = '';
				}
			}
		});

		return payload;
	}

	/**
	 * Transform request
	 *
	 * @param request
	 * @returns {*}
	 */
	function transformRequest (request) {
		let requestData;

		if (Array.isArray(request)) {
			requestData = [];
			request.forEach((data) => {
				requestData.push(transformRequestData(data));
			});
		} else {
			requestData = transformRequestData(request);
		}

		return JSON.stringify(requestData);
	}

	/**
	 * Transform response data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformResponseData (data) {

		// convert to dates
		angular.forEach(dateFields, function (key) {
			if (data.hasOwnProperty(key)) {
				data[key] = data[key] ? dayjs.utc(data[key]).toDate() : null;
			}
		});

		// build full name
		const array = [];
		if (data.first_name) {
			array.push(data.first_name);
		}
		if (data.last_name) {
			array.push(data.last_name);
		}
		data.fullname = array.join(' ');

		// Default to the fallback locale
		data.language = data.language || $translate.fallbackLanguage();
		data.role_localized = UserRoleLocalizationUtil.localize(data.role);

		return data;
	}

	/**
	 * Transform response
	 *
	 * @param response
	 * @returns {*}
	 */
	function transformResponse (response) {
		if (angular.isArray(response)) {
			angular.forEach(response, function (data) {
				transformResponseData(data);
			});
		} else {
			transformResponseData(response);
		}

		return response;
	}

	return {
		request: transformRequest,
		response: [$http.defaults.transformResponse[0], transformResponse]
	};
}
