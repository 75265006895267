
export interface Bindings {
	message: string;
	title: string;
	resolveBtnClass: string;
	resolveBtnText: string;
	rejectBtnClass: string;
	rejectBtnText?: string;
}

export class MessageModalController implements Bindings {
	public readonly rejectButtonSource = 'reject';

	public message: string;
	public title: string;
	public resolveBtnClass: string;
	public resolveBtnText: string;
	public rejectBtnClass: string;
	public rejectBtnText?: string; // Reject btn is optional

	/* @ngInject */
	constructor () {}

	public $onInit () {
		if (!this.message) {
			throw new Error('MessageModalController:: must supply a message and title');
		}

		this.resolveBtnClass = this.resolveBtnClass || 'primary-btn';
		this.resolveBtnText = this.resolveBtnText || 'common_close';
		this.rejectBtnClass = this.rejectBtnClass || 'secondary-btn';
	}
}
