import { clientSettings } from 'go-modules/models/common/client.settings';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import type { OrgSettings } from 'ngx/go-modules/src/interfaces/groups/org-settings';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';

export interface AppDataClientSettingsConfig {
	partners: any;
	disjoint_groups: any;
	user_pubnub_channel: any;
	userCreatedAt: string|Date;
	userId: number;
};

export class AppEnvService {
	public static readonly NG1_INJECTION_NAME = 'appEnv' as const;
	public appDataClientSettings: AppDataClientSettingsConfig;
	public environmentVarsService: EnvironmentVarsService;
	/* @ngInject */
	constructor () {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	/**
	 * Configure.
	 *
	 * @returns {void}
	 */
	public configure (appDataClientSettings: AppDataClientSettingsConfig): void {
		if (this.appDataClientSettings) return;

		this.environmentVarsService.reset();
		this.environmentVarsService.set(EnvironmentVarsService.VAR.ENVIRONMENT, {
			name: ENVIRONMENTS.DEFAULT
		});
		this.environmentVarsService.set(
			EnvironmentVarsService.VAR.USER_PUBNUB_CHANNEL,
			appDataClientSettings.user_pubnub_channel
		);

		// Set partner info on GoSettings, set orgList on GoSettings
		clientSettings.partners = appDataClientSettings.partners;
		clientSettings.orgList = appDataClientSettings.disjoint_groups.map((group) => Number(group.org_id));
		clientSettings.userCreatedAt = appDataClientSettings.userCreatedAt;
		clientSettings.userId = appDataClientSettings.userId;
		this.appDataClientSettings = appDataClientSettings;
	}

	public configureOrgSettings (orgSettings: OrgSettings) {
		clientSettings.orgSettings = orgSettings;
	}

	public reset () {
		this.appDataClientSettings = null;
		this.environmentVarsService.reset();
		clientSettings.partners = [];
		clientSettings.orgList = [];
		clientSettings.orgSettings = null;
	}
}

export const appEnvToken = upgradeNg1Dependency(AppEnvService);
