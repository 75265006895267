import * as angular from 'angular';
import template from './umc.directive.html';
import { UniversalMediaChooserController as controller } from './umc.controller';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

/* @ngInject */
export function universalMediaChooserDirective (
	UniversalMediaChooser,
	$compile,
	$analytics,
	eventService: EventService
) {
	return {
		restrict: 'E',
		scope: {
			disabled: '@',
			options: '=',
			close: '&',
			choose: '&',
			onEngineInit: '&',
			onMediaDetermined: '&',
			selectedZoomTab: '@',
			isZoomCloudRecordingsTabReady: '@'
		},
		controller,
		template,
		link (scope, elem, attr) {
			if (!attr.umcInstanceName || !scope.options || !scope.options.groupId) {
				throw new Error('umc:: requires options with groupId and umcInstanceName');
			}

			function init (): void {
				let defaultSection: string = scope.options.defaultSection;
				scope.umc = UniversalMediaChooser.get(attr.umcInstanceName);
				scope.messages = {};

				// If the requested default isn't in the list of available
				// sections, fallback on the first available section.
				if (!scope.umc.currentSections.includes(defaultSection)) {
					defaultSection = scope.umc.currentSections[0];
				}

				// If resource is a comment, change current section to the selected default.
				// If current section is not the default section and if there is already a current section,
				// ensure that it is in the list of current sections, if not, fallback on the default.
				if ((scope.options.resourceType === 'comment' && scope.umc.currentSection !== defaultSection) ||
					!scope.umc.currentSection || !scope.umc.currentSections.includes(scope.umc.currentSection)) {
					scope.umc.currentSection = defaultSection;
				}
			}

			let currentScope;
			function showSection (section) {
				if (currentScope) {
					currentScope.$destroy();
				}

				const newScope = scope.$new();
				const umcTemplate = '<' + section.directive + ' options="options" umc-instance-name="' + attr.umcInstanceName + '"></' + section.directive + '>';
				const element = angular.element(umcTemplate);
				const containerElement = angular.element(elem[0].querySelector('.media-chooser-component'));
				containerElement.empty().append(element);
				$compile(element)(newScope);

				currentScope = newScope;
			}

			// Watch type change
			scope.$watch('umc.currentSection', (newSection) => {
				if (newSection && !scope.selfChange) {
					eventService.broadcast(EVENT_NAMES.UMC_CHANGE);
					showSection(newSection);
				}
				scope.selfChange = false;
			});

			// Watch selectedMedia changes to trigger analytics events
			scope.$watch('umc.selectedMedia', (mediaPromise) => {
				if (mediaPromise) {
					mediaPromise.then(() => {
						// This means a user actually selected the media from a umc option
						$analytics.eventTrack('umc', {
							category: 'picked',
							label: scope.umc.currentSection && scope.umc.currentSection.directive
						});
					});
				}
			});

			// Unset selected media when destroyed
			scope.$on('$destroy', () => {
				scope.umc.selectedMedia = null;
				if (currentScope) {
					currentScope.$destroy();
				}
				eventService.broadcast(EVENT_NAMES.UMC_CLOSE);
				scope.eventSubscription?.unsubscribe();
			});

			// Listen for success from children
			scope.eventSubscription = eventService.events
				.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.UMC_CHOOSE))
				.subscribe((ev: GoEvent) => {
					eventService.broadcast(EVENT_NAMES.UMC_CHOSEN, ev.data);
					(scope.choose || angular.noop)();
				});

			init();
		}
	};
}
