import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';
interface Bindings {
	activity: any;
};

export class StartTestActivityModalController implements Bindings {
	public activity: any;

	/* @ngInject */
	constructor (private UADetect: UADetectClass) {}

	public showScreenCaptureWarning (): boolean {
		return this.UADetect.browserDetector.isFirefox() || this.UADetect.browserDetector.isSafari();
	}
}
