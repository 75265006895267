import * as angular from 'angular';
import 'angulartics';

import { mediaModel } from 'go-modules/models/media';
import { fineUploaderModule } from 'go-modules/fine-uploader';
import { mediaPreviewModule } from 'go-modules/media-preview';
import { UniversalMediaChooserFactory } from './umc.factory';
import { universalMediaChooserDirective } from './umc.directive';
import { UniversalMediaChooserHeaderComponent } from './umc-header.component';
import { umcInfiniteScrollDirective } from './infinite-scroll/umc-infinite-scroll.directive';
import { umcYoutubeLinkDirective } from './youtube/umc-youtube-link.directive';
import { formValidationModule } from 'go-modules/form-validation';
import umcUploadVideoComponent from './upload-video/umc-upload-video.component';
import umcRecordSessionComponent from './record-session/umc-record-session.component';
import umcZoomComponent from './zoom/umc-zoom.component';
import { UMCRecordVideoComponent } from './record/record-video/umc-record-video.component';
import { UMCRecordAudioComponent } from './record/record-audio/umc-record-audio.component';
import { videoSceneModule } from 'go-modules/video-scene';
import { sessionManager } from 'go-modules/session-manager';
import { zoomServiceModule } from 'go-modules/services/zoom';
import { messageModalModule } from 'go-modules/modals/message';
import * as angularTranslate from 'angular-translate';
import './styles.less';
import { goTablistKeyboardNavigationModule } from 'go-modules/go-tablist-keyboard-navigation';
import { replaceStrFilter } from './filters/replace-str.filter';
import umcLibraryComponent from './library/umc-library.component';
import { umcInstancesServiceModule } from 'go-modules/services/umc-instances';
import { featureFlagModule } from 'go-modules/feature-flag';
import { ngxLibraryCollectionViewerModule } from 'ngx/go-modules/src/components/library/library-collections-viewer';

export const universalMediaChooserModule = angular.module('universal-media-chooser', [
	mediaModel,
	fineUploaderModule,
	mediaPreviewModule,
	'angulartics',
	videoSceneModule,
	featureFlagModule,
	sessionManager,
	zoomServiceModule,
	messageModalModule,
	angularTranslate,
	goTablistKeyboardNavigationModule,
	formValidationModule,
	umcInstancesServiceModule,
	ngxLibraryCollectionViewerModule
])
	.factory(UniversalMediaChooserFactory.NG1_INJECTION_NAME, UniversalMediaChooserFactory)
	.directive('universalMediaChooser', universalMediaChooserDirective)
	.component('universalMediaChooserHeader', UniversalMediaChooserHeaderComponent)
	.directive('umcInfiniteScroll', umcInfiniteScrollDirective)
	.directive('umcYoutubeLink', umcYoutubeLinkDirective)
	.component('umcUploadVideo', umcUploadVideoComponent)
	.component('umcRecordVideo', UMCRecordVideoComponent)
	.component('umcRecordAudio', UMCRecordAudioComponent)
	.component('umcRecordSession', umcRecordSessionComponent)
	.component('umcZoom', umcZoomComponent)
	.component('umcLibrary', umcLibraryComponent)
	.filter('replaceStr', replaceStrFilter)
	.name;
