/* @ngInject */
export function sessionModelInterceptorService (cacheManager, SessionConstants) {

	function cacheSession (session) {

		let cachedSession = cacheManager('session').get(session.getId());

		if (cachedSession) {
			const cachedMedia = cachedSession.media;
			const cachedMediaUrl = cachedSession.media?.media_url;

			cachedSession.extend(session);

			if (cachedSession.media == null) {
				cachedSession.media = cachedMedia;
			} else if (cachedSession.media?.media_url == null) {
				cachedSession.media.media_url = cachedMediaUrl;
			}

		} else {
			cachedSession = session;
			if (session.getId()) {
				cacheManager('session').put(session.getId(), session);
			}
		}

		// Set defaults
		// This needs to happen here and not in transformer
		// because we don't want this defaults to potentially overwrite
		// correct values when we extend
		if (cachedSession.status == null) {
			cachedSession.status = SessionConstants.STATUS.WAITING;
		}

		if (cachedSession.description == null) {
			cachedSession.description = '';
		}

		if (cachedSession.presenters == null) {
			cachedSession.presenters = [];
		}

		if (cachedSession.trim == null) {
			cachedSession.trim = {};
		}

		if (cachedSession.sync_events == null) {
			cachedSession.sync_events = {};
		}

		return cachedSession;
	}

	return {
		response (response) {
			if (Array.isArray(response.resource)) {
				response.resource.forEach(function (session, index) {
					response.resource[index] = cacheSession(session);
				});
			} else {
				response.resource = cacheSession(response.resource);
			}

			return response.resource;
		}
	};
}
