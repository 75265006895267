import * as angular from 'angular';
import * as dayjs from 'dayjs';

/* @ngInject */
export function syncEventServiceTransformer ($http, MediaModel) {

	const dateFields = [
		'created_at',
		'deleted_at'
	];

	/**
	 *
	 * Handle date fields
	 * @param data
	 * @returns {Object}
	 */
	function handleDateFields (data) {
		// Convert date objects to date strings
		angular.forEach(dateFields, function (key) {
			if (data.hasOwnProperty(key)) {
				if (angular.isDate(data[key])) {
					data[key] = dayjs(data[key]).utc().format('YYYY-MM-DD HH:mm:ss');
				} else {
					data[key] = null;
				}
			}
		});

		return data;
	}

	/**
	 * Transform request data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformRequestData (data) {
		let payload = angular.extend({}, data.toJSON());

		if (payload.hasOwnProperty('media')) {
			delete payload.media;
		}

		payload = handleDateFields(payload);

		return payload;
	}

	/**
	 * Transform request
	 *
	 * @param request
	 * @returns {String}
	 */
	function transformRequest (request) {
		let requestData;

		if (angular.isArray(request)) {
			requestData = [];
			angular.forEach(request, function (data) {
				requestData.push(transformRequestData(data));
			});
		} else {
			requestData = transformRequestData(request);
		}

		return angular.toJson(requestData);
	}

	/**
	 * Transform response data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformResponseData (data) {
		// Convert date strings to Date objects
		angular.forEach(dateFields, function (key) {
			if (data.hasOwnProperty(key)) {
				data[key] = data[key] ? dayjs.utc(data[key]).toDate() : null;
			}
		});

		// Transform media object into media model
		if (!(data.media instanceof MediaModel) && angular.isObject(data.media)) {
			data.media = MediaModel.model(data.media);
		}

		return data;
	}

	/**
	 * Transform response
	 *
	 * @param response
	 * @returns {*}
	 */
	function transformResponse (response) {
		if (angular.isArray(response)) {
			angular.forEach(response, function (data) {
				transformResponseData(data);
			});
		} else {
			transformResponseData(response);
		}

		return response;
	}

	return {
		request: transformRequest,
		response: [$http.defaults.transformResponse[0], transformResponse]
	};
}
