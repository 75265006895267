import { ActivityInfoMediaPreviewController as controller } from './media-preview.controller';
import template from './media-preview.component.html';

export const activityInfoMediaPreview = {
	template,
	controller,
	require: {
		activityInfoController: '^activityInfo'
	},
	bindings: {
		media: '=',
		kind: '@'
	}
} as ng.IComponentOptions;
