import * as angular from 'angular';

import { common } from '../common';
import { mediaModel } from '../media';

import { AttachmentModel } from './attachment.factory';
import { attachmentServiceTransformer } from './attachment-transformer.service';

export const attachmentModel = angular.module('go.models.attachment', [
	common,
	mediaModel
])
	.service('attachmentServiceTransformer', attachmentServiceTransformer)
	.factory('AttachmentModel', AttachmentModel)
	.name;
