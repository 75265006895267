import { clientSettings } from 'go-modules/models/common/client.settings';
import { MediaAnalyticsRequest } from 'go-modules/models/media/analytics/media-analytics';

/* @ngInject */
export class MediaAnalyticsService {
	constructor (
		private $http: ng.IHttpService
	) { }

	public save (payload: MediaAnalyticsRequest): ng.IPromise<void> {
		return this.$http.post(
			`${clientSettings.GoReactV2API}/media/${payload.media_id}/media-analytics`,
			payload
		).then((response: ng.IHttpResponse<void>) => {
			return response.data;
		});
	}
}
