import { noop } from 'angular';
import { UmcInstancesService } from 'go-modules/services/umc-instances/umc-instances.service';
export class UniversalMediaChooserHeaderController implements ng.IOnInit {

	public umc;
	public options;
	public umcInstanceName: string;
	public viewFrom: string;

	/** @ngInject */
	constructor (
		private UniversalMediaChooser,
		private $element: ng.IAugmentedJQuery,
		private $translate: ng.translate.ITranslateService,
		private umcInstancesService: UmcInstancesService
	) {}

	public $onInit () {
		this.umc = this.UniversalMediaChooser.get(this.umcInstanceName);
		this.umc.currentSections = this.options;

		if (this.viewFrom === 'video-sharing') {
			this.options = this.options.filter((option) => option !== this.UniversalMediaChooser.MEDIA_TYPE.LIBRARY);
		}
	}

	public changeSection (option) {
		// Ignore if we are already here
		if (this.umc.currentSection === option) return;

		this.umcInstancesService.canChangeTab()
			.then(() => {
				this.umc.currentSection = option;
				const ariaSpan = this.$element[0].querySelector('.accessibility-text');
				ariaSpan.textContent = this.$translate.instant(option.selectedAriaLiveMessage);
			}).catch(noop);
	}
}
