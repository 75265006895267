function EventChannelFactory () {
	// Cached event channels
	const cache = {};

	/**
	 * Constructor
	 *
	 * @constructor
	 */
	function EventChannelFactoryFn () {
		let subscribers = {};

		this.subscribe = (eventType, listener) => {
			if (typeof listener !== 'function') {
				throw new Error('Event subscription callback is not a function');
			}
			if (!Array.isArray(subscribers[eventType])) {
				subscribers[eventType] = [];
			}
			if (!this.hasSubscriber(eventType, listener)) {
				subscribers[eventType].push(listener);
			}

			return this;
		};

		this.subscribeOnce = (eventType, listener) => {
			if (typeof listener !== 'function') {
				throw new Error('Event subscription callback is not a function');
			}
			const handler = (...args) => {
				listener.apply(this, args);
				this.unsubscribe(eventType, handler);
			};
			this.subscribe(eventType, handler);
			return this;
		};

		this.broadcast = (eventType, ...data) => {
			if (!Array.isArray(subscribers[eventType])) {
				subscribers[eventType] = [];
			}
			// broadcast in ascending order
			subscribers[eventType].forEach((callback: () => void) => {
				callback.apply(this, data);
			});

			return this;
		};

		/**
		 * Whether a subscriber exists
		 *
		 * @param {string} eventType Type of event
		 * @param {function} listener Callback function
		 * @return {boolean} true when subscriber exists, false when no subscriber
		 */
		this.hasSubscriber = (eventType, listener) => {
			let result = false;
			if (Array.isArray(subscribers[eventType])) {

				// see if listener exists
				if (typeof listener !== 'undefined') {
					let i = subscribers[eventType].length;
					while (i--) {
						if (subscribers[eventType][i] === listener) {
							result = true;
							break;
						}
					}
				} else {
					result = true;
				}
			}

			return result;
		};

		this.unsubscribe = (eventType, listener) => {
			if (typeof eventType !== 'undefined') {
				if (typeof listener !== 'undefined') {
					if (this.hasSubscriber(eventType, listener)) {
						const index = subscribers[eventType].indexOf(listener);
						subscribers[eventType].splice(index, 1);
					}
				} else {
					delete subscribers[eventType];
				}
			} else {
				subscribers = {};
			}
			return this;
		};
	}

	/**
	 * For retrieving the same event channel
	 * instance using an identifier.
	 *
	 * @param id
	 * @returns {*}
	 */
	EventChannelFactoryFn.get = (id) => {
		if (!(cache[id] instanceof EventChannelFactoryFn)) {
			cache[id] = new EventChannelFactoryFn();
		}
		return cache[id];
	};

	return EventChannelFactoryFn;
}

export const EventChannel = EventChannelFactory();
