import { clientSettings } from 'go-modules/models/common/client.settings';

/* @ngInject */
export function GroupModel ($resource) {

	const baseUrl = `${clientSettings.GoReactV1API}/groups`;

	const GroupModelResource = $resource(baseUrl + '/index/:group_id', {group_id: '@group_id'}, {
		getPublishedGroups: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/groups/:group_id/published`,
			isArray: true
		}
	});

	return GroupModelResource;
}
