import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { MediaAnalyticsRequest } from 'go-modules/models/media/analytics/media-analytics';

@Injectable({
	providedIn: 'root'
})
export class NgxMediaAnalyticsService {
	constructor (
		private http: HttpClient
	) { }

	public save (payload: MediaAnalyticsRequest): Promise<void> {
		return this.http.post<void>(
			`${clientSettings.GoReactV2API}/media/${payload.media_id}/media-analytics`,
			payload
		).toPromise();
	}
}
