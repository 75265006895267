import template from './go-popover.component.html';
import { GoPopoverController as controller } from './go-popover.controller';

export const goPopoverComponent = {
	template,
	controller,
	transclude: true,
	bindings: {
		templateUrl: '<',
		data: '<',
		popoverTitle: '@',
		disablePopover: '<',
		allowHoverOnPopover: '<',
		popoverAppendToBody: '<'
	}
};
