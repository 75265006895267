import { Position } from './go-source';

// export const EQUIPMENT_CHECK_PASSED = 'goreact-equipment-check-passed-opentok';
export const CONTINUE_WITHOUT_AUDIO_KEY = 'recordWithoutAudioOk';
export const RECORDING_MIC_START_ACTIVE_KEY = 'recordingMicStartActive';
export const RECORDING_CAM_START_ACTIVE_KEY = 'recordingCamStartActive';
export const RECORDING_BLUR_START_ACTIVE_KEY = 'recordingBlurStartActive';
export const RECORDING_IS_MIRRORED = 'recordingIsMirrored';

const muteIndicatorDefaultPosition = {
	x: 0.5, y: 93.5, valign: 'top', halign: 'left',
	resourceWidth: 28, resourceHeight: 28
} as Position;

export const MUTE_INDICATOR_POSITIONS = {
	default: muteIndicatorDefaultPosition as Position,
	sharingScreen: { ...muteIndicatorDefaultPosition, y: 86.5 } as Position,
	observingScreenShare: {
		...muteIndicatorDefaultPosition,
		y: 84, resourceWidth: 75, resourceHeight: 75
	} as Position,
	moreThanFourParticipants: {
		...muteIndicatorDefaultPosition,
		y: 91, resourceWidth: 40, resourceHeight: 40
	} as Position
};
