import * as angular from 'angular';
import * as ngMessages from 'angular-messages';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';

import { ChangeEmailModal } from './modal.service';

import './modal.less';
import { formValidationModule } from 'go-modules/form-validation';
import { ngxGoModalServiceModule } from 'ngx/go-modules/src/services/go-modal';
import { ngxAuthServiceModule } from 'ngx/go-modules/src/services/auth';

export const changeEmailModalModule = angular.module('modal.change-email', [
	goModalBootstrapModule,
	angularTranslate,
	ngMessages,
	formValidationModule,
	ngxGoModalServiceModule,
	ngxAuthServiceModule
])
	.service(ChangeEmailModal.NG1_INJECTION_NAME, ChangeEmailModal)
	.name;
