import { isObject, isUndefined } from 'lodash';

/* @ngInject */
export function arrayDiffFilter ($filter) {
	return (array1, array2, key) => {
		if (!isObject(array1) || !isObject(array2)) {
			return undefined;
		}
		if (typeof array2 !== typeof array1) {
			return undefined;
		}
		return $filter('filter')(array1, (item) => {
			let index, outcome = true;
			if (isUndefined(key)) {
				for (index in array2) {
					if (item === array2[index]) {
						outcome = false;
						break;
					}
				}
			} else {// compare on a specific key
				for (index in array2) {
					if (item[key] === array2[index][key]) {
						outcome = false;
						break;
					}
				}
			}
			return outcome;
		});
	};
}
