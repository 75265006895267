import { EventEmitter } from 'events';
import { STATES } from '../states';
import { EVENTS } from '../events';
import { PROVIDERS } from '../providers';
import type { PlaylistItem } from '../playlist-item';

const _window = (window as any);

// Threshold for determining whether a seek occurred.
export const SEEK_THRESHOLD = 500;
export const TIME_INTERVAL = 250;

export abstract class GoMediaPlayer extends EventEmitter {
	public id?: string;
	protected alwaysEmitTimeEvent: boolean = false;
	private state: STATES = STATES.IDLE;
	private lastTime: number = 0;
	private seeking: boolean = false;
	private pauseAfterSeek: null|boolean = null;
	private intervalId: number;

	constructor () {
		super();
		this.initEventEmulation();
	}

	public getState (): STATES {
		return this.state;
	}

	public seek (time: number, pauseAfterSeek?: boolean): void {
		if (pauseAfterSeek || (this.pauseAfterSeek === null && this.getState() === STATES.PAUSED)) {
			this.pauseAfterSeek = true;
		}

		this.emit(EVENTS.REQUEST_SEEK, {offset: time});
		this.seekTo(time);
	}

	public isBuffering (): boolean {
		return this.getState() === STATES.BUFFERING;
	}

	public isPlaying (): boolean {
		return this.getState() === STATES.PLAYING;
	}

	public isPaused (): boolean {
		return this.getState() === STATES.PAUSED
			|| this.getState() === STATES.IDLE
			|| this.isComplete();
	}

	public isComplete (): boolean {
		return this.getState() === STATES.COMPLETE;
	}

	public getContainer (): HTMLElement {
		// At some point, change this to not make an assumption about the container.
		return this.getElement().closest('go-media-player') as HTMLElement;
	}

	public getFullscreen (): boolean {
		return !!document.fullscreenElement
			|| !!document.webkitFullscreenElement
			|| !!document.mozFullScreenElement
			|| !!document.msFullscreenElement;
	}

	// for more info: https://caniuse.com/fullscreen
	public canGoFullScreen (): boolean {
		const wrapper = this.getContainer();

		return wrapper && (!!wrapper.requestFullscreen
			|| !!wrapper.webkitRequestFullscreen
			|| !!wrapper.webkitEnterFullscreen
			|| !!wrapper.mozRequestFullScreen
			|| !!wrapper.msRequestFullscreen);
	}

	public goFullscreen (): void {
		const wrapper = this.getContainer();
		if (wrapper.requestFullscreen) {
			wrapper.requestFullscreen();
		} else if (wrapper.webkitRequestFullscreen) {
			wrapper.webkitRequestFullscreen();
		} else if (wrapper.webkitEnterFullscreen) {
			wrapper.webkitEnterFullscreen();
		} else if (wrapper.mozRequestFullScreen) {
			wrapper.mozRequestFullScreen();
		} else if (wrapper.msRequestFullscreen) {
			wrapper.msRequestFullscreen();
		}
	}

	public exitFullscreen (): void {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	}

	public destroy (): void {
		_window.clearInterval(this.intervalId);
		this.removeAllListeners();
	}

	/**
	 * @deprecated
	 */
	public trigger (event: string | symbol, ...args: any[]): void {
		this.emit(event, ...args);
	}

	public setState (newstate: STATES): void {
		const oldstate = this.getState();
		if (newstate === oldstate) {
			return;
		}

		this.state = newstate;
		switch (newstate) {
			case STATES.BUFFERING:
				this.emit(EVENTS.BUFFER, { oldstate, newstate });
				break;
			case STATES.PLAYING:
				this.emit(EVENTS.PLAY, { oldstate, newstate });
				break;
			case STATES.PAUSED:
				this.emit(EVENTS.PAUSE, { oldstate, newstate });
				break;
			case STATES.IDLE:
				this.emit(EVENTS.IDLE, { oldstate, newstate });
				break;
			case STATES.COMPLETE:
				this.emit(EVENTS.COMPLETE, { oldstate, newstate });
				break;
		}

		// For those who want to listen to any event
		this.emit(EVENTS.STATE_CHANGE, { oldstate, newstate });
	}

	private isSeek (requestedTime: number): boolean {
		return Math.abs(this.getTime() - requestedTime) > SEEK_THRESHOLD;
	}

	/**
	 * Keep time and seek events consistent between player implementations.
	 * Some players don't support these events at all.
	 */
	private initEventEmulation (): void {
		this.on(EVENTS.REQUEST_SEEK, (event) => {
			this.seeking = true;

			// The seek detection mechanism won't recognize that a seek occurred
			// if the request time doesn't exceed the seek threshold.
			if (!this.isSeek(event.offset)) {
				this.emit(EVENTS.TIME, { time: event.offset });
				this.emit(EVENTS.SEEK, { offset: event.offset });
			}
		});

		this.on(EVENTS.SEEK, () => {
			this.seeking = false;
		});

		this.intervalId = _window.setInterval(() => {
			const currentTime = this.getTime();

			// Emit a time event when in the playing state. Some players will
			// set the `alwaysEmitTimeEvent`. This allows subscribers to still
			// get time events for live streams. Our player controls still need
			// to show the elapsed time even when the stream is not playing.
			if (this.alwaysEmitTimeEvent || (this.isPlaying() && !this.seeking)) {
				this.emit(EVENTS.TIME, { time: currentTime });
			}

			// Third party players either don't support a seek event or don't consistently
			// emit seek events when the time has actually changed. So we will do our own
			// seek detection and emit a seek event when the seek threshold is exceeded.
			if (this.seeking && this.isSeek(this.lastTime)) {
				this.emit(EVENTS.TIME, { time: currentTime });
				this.beforeSeek();
				this.emit(EVENTS.SEEK, { offset: currentTime });
			}

			this.lastTime = currentTime;
		}, TIME_INTERVAL);
	}

	private beforeSeek (): void {
		// Pause after seek behavior. This needs to run right before
		// the seek event is emitted so that subscribers can then
		// change the state of the player if they desire.
		if (this.pauseAfterSeek) {
			this.pauseAfterSeek = null;
			if (this.getState() === STATES.BUFFERING) {
				this.once(EVENTS.PLAY, () => this.pause());
			} else if (this.getState() === STATES.PLAYING) {
				this.pause();
			}
		}
	}

	public abstract play (): void;
	public abstract pause (): void;
	public abstract stop (): void;

	public abstract getTime (): number;
	public abstract getDuration (): number;

	public abstract getElement (): HTMLElement;

	public abstract getMute (): boolean;
	public abstract setMute (value: boolean): Promise<void>;

	public abstract setVolume (value: number): void;
	public abstract getVolume (): number;

	public abstract isPlaybackRateSupported (): boolean;
	public abstract set playbackRate (value: number);
	public abstract get playbackRate (): number;

	public abstract captionsEnabled (): boolean;
	public abstract toggleCaptions (value?: boolean): void;
	public abstract hasCaptions (): boolean;

	public abstract getPlaylistItem (): PlaylistItem;
	public abstract getProvider (): {name: PROVIDERS};

	protected abstract seekTo (time: number/* in milliseconds */): void;
}
