import { FeatureTests } from './feature-flag-tests';
import { clientSettings } from '../models/common/client.settings';
import type { ComplexFeatureFlagWhichSetting, FeatureTypes } from './feature-flag-types';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';

export class FeatureFlag {

	/** @ngInject */
	constructor (
		private featureTests: FeatureTests,
		private $window,
	    private fullstoryService: FullstoryService,
	    private $log
	) {}

	public getSynchronizedFlags (): string[] {
		const flags = clientSettings.synchronizedFeatureFlags;
		return flags.filter((flag) => this.isAvailable(flag));
	}

	public isAvailable (featureKey: string): boolean {
		let flag = clientSettings.featureFlags[featureKey];
		if (this.$window.localStorage.getItem('featureFlagOverrides')) {
			const flagOverrides = JSON.parse(this.$window.localStorage.getItem('featureFlagOverrides'));
			if (flagOverrides.hasOwnProperty(featureKey)) {
				flag = flagOverrides[featureKey];
			}
		}
		const enabled = this.isFeatureAvailable(flag);
		const storedFeatureFlag = sessionStorage.getItem(`goreact-flag-${featureKey}`);
		if (enabled && (storedFeatureFlag == null || storedFeatureFlag === 'false')) {
			this.fullstoryService.isReady().then(()=> {
				this.fullstoryService.createEvent(FULLSTORY_EVENTS.FEATURE_FLAG_ENABLED, {
					flag: featureKey
				});
				sessionStorage.setItem(`goreact-flag-${featureKey}`, 'true');
			}).catch((err) => this.$log.error(err));
		}

		return enabled;
	}

	public which (featureKey: string): string {
		const feature = clientSettings.featureFlags[featureKey] as ComplexFeatureFlagWhichSetting<FeatureTests>;
		return (this.isFeatureAvailable(feature) ? feature.default : feature.fallback).toLowerCase();
	}

	private isFeatureAvailable = (feature: FeatureTypes<FeatureTests> | null): boolean => {
		if (feature === false || feature == null) {
			return false;
		}
		if (feature === true) {
			return true;
		}

		return Object.keys(feature).every(
			(check) => this.featureTests[check] == null ||
				this.featureTests[check](feature[check], this.isFeatureAvailable)
		);
	};
}
