import { isUndefined, isString } from 'lodash';

export const truncateFilter = () => {
	return (text, length, end) => {
		if (isUndefined(length)) {
			length = 10;
		} else if (isNaN(length)) {
			length = 10;
		}
		if (isUndefined(end)) {
			end = '...';
		} else if (!isString(end)) {
			end = end.toString();
		}
		if (isUndefined(text)) {
			return '';
		}
		if (text.length <= length || text.length - end.length <= length) {
			return text;
		}

		return String(text).substring(0, length - end.length) + end;
	};
};
