import { GoSource } from '../go-source/go-source';
import { MicrophoneSource } from '../go-source';
import { States } from '../state-emitter/state-emitter';
import DeviceMediaStreamFactory from 'go-modules/device-kit/media-stream-factory/device-media-stream-factory';
import { MediaStreamSource } from '../go-source/media-stream-source';
import { VideoQuality, VideoQualityUtil } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';
import { clientSettings } from 'go-modules/models/common/client.settings';

interface CanvasElement extends HTMLCanvasElement {
	captureStream(arg0?: number): MediaStream;
}

export interface DrawDimensions {
	x: number;
	y: number;
	width: number;
	height: number;
}

export class GoScene {
	public readonly sources: GoSource[] = [];
	public readonly canvasEle: CanvasElement = document.createElement('canvas') as CanvasElement;
	public overlayNameEnabled = false;
	public outputContainer: HTMLElement;
	public ctx: CanvasRenderingContext2D = this.canvasEle.getContext('2d');
	private outputStream: MediaStream = new MediaStream();
	private canvasStream: MediaStream;
	private timeout;
	private thumbnail = new Image();

	constructor (
		public resolution = VideoQualityUtil.MINIMUM_RESOLUTION,
		public fps: number = 30
	) {
		this.setResolution(resolution);
		this.canvasStream = this.canvasEle.captureStream(this.fps);
		const videoTrack = this.canvasStream.getTracks()[0];
		DeviceMediaStreamFactory.aliasTrackStopMethod(videoTrack);
		this.outputStream.addTrack(videoTrack);
		this.thumbnail.src = 'https://staticassets.goreact.com/logo-goreact-spot-2022.svg';
		this.render();
	}

	public getResolution (): OT.GetUserMediaProperties['resolution'] {
		return `${this.canvasEle.width}x${this.canvasEle.height}` as OT.GetUserMediaProperties['resolution'];
	}

	public getAspectRatio () {
		return VideoQualityUtil.aspectRatio(`${this.canvasEle.width}x${this.canvasEle.height}` as VideoQuality);
	}

	public setResolution (resolution: VideoQuality) {
		const [width, height] = resolution.split('x').map((n: string) => parseInt(n, 10));
		this.canvasEle.width = width;
		this.canvasEle.height = height;
	}

	public addSource (source: GoSource): void {
		if (!this.sources.includes(source)) {
			source.on(GoSource.EVENTS.STATE_CHANGE, this.sourceStateChange);
			this.sources.unshift(source);
		}
	}

	public removeSource (source: GoSource): void {
		const index = this.sources.indexOf(source);
		if (index > -1) this.sources.splice(index, 1);
		if (source.state !== GoSource.STATES.DESTROYED) source.destroy();
	}

	// Temporary until we need to support multiple audio tracks
	public updateAudioTrack (source: MediaStreamSource): void {
		const oldTrack = this.outputStream.getAudioTracks()[0];
		if (oldTrack) this.outputStream.removeTrack(oldTrack);

		const audioTrack = source.stream.getAudioTracks()[0];
		this.outputStream.addTrack(audioTrack);
	}

	public combineAudio (stream: MediaStream): MediaStreamTrack {
		const audio = new AudioContext();
		const audioStream = audio.createMediaStreamSource(this.outputStream);
		const stream2 = audio.createMediaStreamSource(stream);
		const dest = audio.createMediaStreamDestination();
		audioStream.connect(dest);
		stream2.connect(dest);
		return dest.stream.getAudioTracks()[0];
	}

	public getStream (): MediaStream {
		return this.outputStream;
	}

	public destroy (): void {
		clearTimeout(this.timeout);
		this.outputStream.getTracks().forEach((track) => track.stopTrack());
		// Traverse backwards since when a source is destroyed it is removed from
		// the array due to the source emitting its state. This causes the indexing
		// of the loop to get off and miss sources.
		for (let i = this.sources.length - 1; i >= 0; i--) {
			this.sources[i].destroy();
		}
	}

	public get drawDimensions (): DrawDimensions {
		return { x: 0, y: 0, width: this.canvasEle.width, height: this.canvasEle.height };
	}

	public getPixelsX (percent: number, container: DrawDimensions = this.drawDimensions) {
		return container.width * percent * .01 + container.x;
	}

	public getPixelsY (percent: number, container: DrawDimensions = this.drawDimensions) {
		return container.height * percent * .01 + container.y;
	}

	// This is only public for test reasons.
	// It'd be too involved to ensure the output stream's pixels were right,
	// so I'm instead settling to test the draw dimensions, but it has to be public
	public getScaledDrawDimensions (source: GoSource) {
		// The position x/y of the goSource container.
		const goSourceX = this.getPixelsX(source.x);
		const goSourceY = this.getPixelsY(source.y);

		// The width/height of goSource container.
		const goSourceWidth = clientSettings.featureFlags.HEYGEN === true ? 640 : this.getPixelsX(source.width);
		const goSourceHeight = clientSettings.featureFlags.HEYGEN === true ? 480 : this.getPixelsY(source.height);

		// How much we have to scale to fit the original resource into the goSource dimensions
		// If we allow overscan, then the scale can be bigger than the goSource dimensions, but will be cropped
		const scale = Math.min(
			(goSourceWidth / source.resourceWidth) * source.maxOverscanX,
			(goSourceHeight / source.resourceHeight) * source.maxOverscanY
		);

		// The width/height of the scaled resource.
		const destWidth = Math.min(source.resourceWidth * scale, goSourceWidth);
		const destHeight = Math.min(source.resourceHeight * scale, goSourceHeight);

		const offsetObj = {
			get left () { return goSourceX; },
			get right () { return goSourceX + (goSourceWidth - destWidth); },
			get center () { return goSourceX + (goSourceWidth - destWidth) / 2; },
			get top () { return goSourceY; },
			get bottom () { return goSourceY + (goSourceHeight - destHeight); },
			get middle () { return goSourceY + (goSourceHeight - destHeight) / 2; }
		};

		// Get the destination of where we are drawing based on alignment
		const destX = offsetObj[source.halign];
		const destY = offsetObj[source.valign];

		// Get how much of the resource is drawing.
		// This is normally 100% unless we are overscanning
		const sourceWidth = Math.min(goSourceWidth / scale, source.resourceWidth);
		const sourceHeight = Math.min(goSourceHeight / scale, source.resourceHeight);

		// Get the x/y position of the source
		// This is normally 0 unless we are overscanning
		const sourceX = (source.resourceWidth - sourceWidth) / 2;
		const sourceY = (source.resourceHeight - sourceHeight) / 2;

		return { sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight };
	}

	public getDrawDimensions (source: GoSource, container?: DrawDimensions) {
		const xPixels = this.getPixelsX(source.x, container);
		const yPixels = this.getPixelsY(source.y, container);
		const width = this.getPixelsX(source.width);
		const height = this.getPixelsY(source.height);
		const offsetObj = {
			get left () { return xPixels; },
			get right () { return xPixels - width; },
			get center () { return xPixels - width / 2; },
			get top () { return yPixels; },
			get bottom () { return yPixels - height; },
			get middle () { return yPixels - height / 2; }
		};
		const x = offsetObj[source.halign];
		const y = offsetObj[source.valign];

		return { x, y, width, height };
	}

	private sourceStateChange = (_state: States, source: GoSource): void => {
		switch (source.state) {
			/**
			 * Opentok cannot begin a stream without an audio track then add one later
			 * Therefore, when a mic is "missing," we proceed as normal so that when
			 * a different (working) mic is selected, opentok will pick it up.
			 *
			 * A "missing" mic indicates that the user has chosen an audio device that
			 * failed the AudioActivityDetector's test but the user chose to continue
			 * without audio.
			 */
			case GoSource.STATES.MISSING:
			case GoSource.STATES.ACTIVE:
				if (source instanceof MicrophoneSource && !!source.stream?.getAudioTracks().length) {
					this.updateAudioTrack(source);
				}
				break;
		}
	};

	private render (): void {
		const startRenderTime = performance.now();
		this.ctx.fillStyle = '#97A3B3';
		this.ctx.fillRect(0, 0, this.canvasEle.width, this.canvasEle.height);
		this.sources.forEach((source) => source.active && source.render(this));
		this.timeout = setTimeout(this.render.bind(this), (1000 / this.fps) - (performance.now() - startRenderTime));
	}

}
