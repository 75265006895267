export function trimTrailingZerosFilter () {
	function isNumeric (n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}
	return (value) => {
		if (!isNumeric(value)) {
			return value;
		}
		return parseFloat(value.toString());
	};
}
