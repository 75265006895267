/* @ngInject */
export function UserActivityDetectionServiceDef ($q, $document, $interval, GoGlobalEvent, GoReactHeartbeatEvent) {
	let deferred;
	let timeoutPromise;

	// Default max duration for running detection process
	const DEFAULT_MAX_DURATION = 5 * 1000 * 60; // 5 minutes

	// Handle user activity detection event
	const onDetected = function () {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		cleanup();
		// A resolved promise indicates that
		// user activity was succesfully detected.
		deferred.resolve();
	};

	// Handle detection timeout event
	const onDetectionTimeout = function () {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		cleanup();
		// A rejected promise indicates that
		// user activity was not succesfully detected.
		deferred.reject();
	};

	// Unsubscribe from all events / timeout
	const cleanup = function () {
		GoGlobalEvent.off(GoReactHeartbeatEvent, onDetected);
		$document.off('keydown', onDetected);
		$document.off('mousemove', onDetected);
		$interval.cancel(timeoutPromise);
	};

	const UserActivityDetectionService = function () { };

	/**
	 * Begin user activity detection. A user is considered 'active' when
	 * keyboard and/or mousemove events are occurring. Other areas of app
	 * will also trigger a heart beat to signal that the user is active.
	 * These scenarios include: recording, uploading, and video playback.
	 *
	 * @param maxDuration The max amount of time to wait for user activity before giving up
	 *
	 * @returns Promise
	 */
	UserActivityDetectionService.run = function (maxDuration) {
		if (deferred) {
			cleanup();
		}
		deferred = $q.defer();
		maxDuration = maxDuration > 0 ? maxDuration : DEFAULT_MAX_DURATION;
		// Listen for heartbeat events
		GoGlobalEvent.once(GoReactHeartbeatEvent, onDetected);
		// Listen for mouse/keyboard events
		$document.one('keydown', onDetected);
		$document.one('mousemove', onDetected);
		// If user activity isn't detected after a specific duration, give up.
		timeoutPromise = $interval(onDetectionTimeout, maxDuration, 1);

		return deferred.promise;
	};

	/**
	 * Stops the user activity detection process
	 */
	UserActivityDetectionService.stop = function () {
		cleanup();
	};

	return UserActivityDetectionService;
}
