import { isArray } from 'angular';

/* @ngInject */
export function allSettledConfig ($provide) {
	const mapValues = (obj, callback) => {
		if (isArray(obj)) {
			return obj.map(callback);
		}

		const ret = {};
		Object.keys(obj).forEach((key, _val) => {
			ret[key] = callback(obj[key], key);
		});
		return ret;
	};

	const decorateAllSettled = ($delegate) => {
		const $q = $delegate;

		$q.allSettled = (promises) => {
			return $q.all(mapValues(promises, (promiseOrValue) => {
				if (! promiseOrValue.then) {
					return { state: 'fulfilled', value: promiseOrValue };
				}

				return promiseOrValue.then((value) => {
					return { state: 'fulfilled', value };
				}, (reason) => {
					return { state: 'rejected', reason };
				});
			}));
		};

		return $q;
	};

	$provide.decorator('$q', decorateAllSettled);
}
