import { NgModule } from '@angular/core';
import { TwoLettersPipe } from './two-letters.pipe';

@NgModule({
	declarations: [
		TwoLettersPipe
	],
	exports: [
		TwoLettersPipe
	]
})
export class TwoLettersPipeModule {}
