export enum VideoQuality {
	SD = '640x480',
	HD = '1280x720'
}

export class VideoQualityUtil {
	public static SCREEN_CAPTURE_MINIMUM_RESOLUTION = VideoQuality.HD;
	public static MINIMUM_RESOLUTION = VideoQuality.SD;

	public static max (resolutions: VideoQuality[]): VideoQuality
	{
		const values = Object.values(VideoQuality);
		return resolutions.reduce((prev, current) => {
			if(values.indexOf(prev) > values.indexOf(current)) {
				return prev;
			}

			return current;
		}, VideoQuality.SD);
	 }

	 public static aspectRatio (dimension: VideoQuality): string
	 {
		if(!Object.values(VideoQuality).includes(dimension)) {
			throw new Error('dimension should be in the VideoQuality enum.');
		}

		const value = dimension.toLowerCase().split('x');

		const commonFactor = VideoQualityUtil.getGreatestCommonFactor(+value[0], +value[1]);
		const width =  +value[0] / commonFactor;
		const height = +value[1] / commonFactor;

		 return `${width}:${height}`;
	 }

	 public static getGreatestCommonFactor (width: number, height: number): number
	 {
		if (height === 0) return width;
		return VideoQualityUtil.getGreatestCommonFactor(height, width % height);
	 }
}
