import { IAugmentedJQuery } from 'angular';
import { Timer } from 'go-modules/timer';
import { TimeUtilsService } from 'go-modules/time/time.service';
import humanizeDuration from 'humanize-duration';

interface Bindings {
	timer: Timer;
	timeLimit: number;
}

export class TimeRemainingCountdownController implements Bindings, ng.IController {
	public timer: Timer;
	public timeLimit: number;
	private timeDisplayElement: HTMLElement;

	/* @ngInject */
	constructor (
		private $element: IAugmentedJQuery,
		private Time: TimeUtilsService,
		private $translate: ng.translate.ITranslateService
	) {}

	public $onInit () {
		this.timeDisplayElement = this.$element[0].querySelector('.time-display');
		this.timer.on(Timer.EVENT.TIME, this.updateTime);
	}

	public $onDestroy () {
		this.timer.off(Timer.EVENT.TIME, this.updateTime);
	}

	private formatTime (timeRemaining: number): string {
		// proposedLanguage() may not be available, fall back to use
		return humanizeDuration(timeRemaining, {language: this.$translate.proposedLanguage() || this.$translate.use() || 'en'});
	}

	private shouldDisplay (timeRemaining: number): boolean {
		const timeRemainingAsSeconds = Math.ceil(this.Time.millisecondsToSeconds(timeRemaining));
		return (timeRemainingAsSeconds > 0
			&& (Math.floor(timeRemainingAsSeconds) === 60 ||
				Math.floor(timeRemainingAsSeconds) === 30 ||
				Math.floor(timeRemainingAsSeconds) <= 10)
		);
	}

	private updateTime = (time: number): void => {
		const timeRemaining = Math.ceil(this.timeLimit * 60 - this.Time.millisecondsToSeconds(time)) * 1000;
		const shouldDisplay: boolean = this.shouldDisplay(timeRemaining);
		this.$element.toggleClass('show-time-display', shouldDisplay);
		this.$element.toggleClass('hidden', timeRemaining <= 0);
		if (shouldDisplay) {
			this.timeDisplayElement.textContent = this.formatTime(timeRemaining);
		}
	};
}
