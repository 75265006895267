import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'ngx-zero-state',
	template: require('./zero-state.component.html'),
	styles: [require('./zero-state.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxZeroStateComponent {
	constructor () {}
}
