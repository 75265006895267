import textTemplate from './comment-captions/caption/text.html';

import attachmentsPopoverTemplate from './header/attachments-popover.html';

import toastTemplate from './slide-presentation-viewer/slide-deck-viewer/sync-event-toast/toast.tpl.html';

/* @ngInject */
export const templates = function ($templateCache) {
	$templateCache.put('feedback-session/comment-captions/caption/text.html', textTemplate);
	$templateCache.put('feedback-session/header/attachments-popover.html', attachmentsPopoverTemplate);
	$templateCache.put('feedback-session/slide-presentation-viewer/slide-deck-viewer/sync-event-toast/toast.tpl.html', toastTemplate);
};
