import { GoSource, GoSourceConfig } from './go-source';
import { GoScene } from '../go-scene/go-scene';

export class MediaStreamSource extends GoSource {
	public stream?: MediaStream;
	public subSources: GoSource[] = [];
	public element?: HTMLVideoElement;

	constructor (public config: GoSourceConfig, stream?: MediaStream) {
		super(config);

		if (stream) {
			this.stream = stream;
		}
	}

	public async init (): Promise<void> {
		return new Promise((resolve, _reject) => {
			this.initVideoElement(this.config);
			this.element.srcObject = this.stream;
			this.setState(GoSource.STATES.ACTIVE);
			resolve();
		});
	}

	public async test (): Promise<boolean> {
		return ![ GoSource.STATES.FAILED, GoSource.STATES.DESTROYED ].includes(this.state);
	}

	public get resourceWidth (): number {
		return this.stream.getVideoTracks()[0]?.getSettings().width;
	}

	public get resourceHeight (): number {
		return this.stream.getVideoTracks()[0]?.getSettings().height;
	}

	public destroy (): void {
		super.destroy();
		this.stopStream();
		// https://stackoverflow.com/a/28060352/2895909
		if (this.element) {
			this.element.pause();
			this.element.removeAttribute('src'); // empty source
			this.element.load();
			this.element.remove();
		}
	}

	public getRenderableElement (): CanvasImageSource {
		return this.element;
	};

	public render (scene: GoScene): void {
		const {
			sourceX, sourceY, sourceWidth, sourceHeight,
			destX, destY, destWidth, destHeight
		} = scene.getScaledDrawDimensions(this);

		const container = {x: destX, y: destY, width: destWidth, height: destHeight};

		scene.ctx.drawImage(
			this.getRenderableElement(),
			sourceX, sourceY, sourceWidth, sourceHeight,
			destX, destY, destWidth, destHeight
		);

		this.subSources.forEach((source) => source.active && source.render(scene, container));
	}

	protected stopStream (): void {
		if (this.stream) this.stream.getTracks().forEach((track) => track.stopTrack?.());
	}

	protected initVideoElement (config: GoSourceConfig): void {
		this.element = document.createElement('video');
		this.element.setAttribute('style', 'position: absolute; top: 1px; left: 1px; height: 1px; width: 1px; z-index: 9999;');
		this.element.muted = config.muted != null ? config.muted : true;
		this.element.autoplay = true;
		this.element.setAttribute('playsinline', 'true');
		(config?.appendElement || document.body).append(this.element);
	}
}
