import template from './header.component.html';
import { FeedbackSessionHeaderController as controller } from './header.controller';

export const feedbackSessionHeader: ng.IComponentOptions = {
	require: {
		mainController: '^feedbackSession'
	},
	template,
	controller
};
