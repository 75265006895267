export enum PrivacyLevel {
	PRIVATE,
	CLOSED_PEER_REVIEW,
	OPEN_FEEDBACK,
	OPEN_PEER_REVIEW
}

export const privacyLevelConfigurationMap = {
	// Recordings and feedback are only visible to the Presenter and Instructors/Reviewers.
	[PrivacyLevel.PRIVATE]: {
		public_feedback: false,
		peer_review: false
	},
	// Peers see and critique others’ recordings, but their feedback
	// is only visible to the Presenter and Instructors/Reviewers.
	[PrivacyLevel.CLOSED_PEER_REVIEW]: {
		public_feedback: false,
		peer_review: true
	},
	// Peers cannot see and critique others' recordings but can see
	// everyone's feedback on all submissions.
	[PrivacyLevel.OPEN_FEEDBACK]: {
		public_feedback: true,
		peer_review: false
	},
	// Peers see and critique others’ recordings, and can see everyone’s feedback on all submissions.
	[PrivacyLevel.OPEN_PEER_REVIEW]: {
		public_feedback: true,
		peer_review: true
	}
};

export const privacyLevelConfigurations = [
	privacyLevelConfigurationMap[PrivacyLevel.PRIVATE],
	privacyLevelConfigurationMap[PrivacyLevel.CLOSED_PEER_REVIEW],
	privacyLevelConfigurationMap[PrivacyLevel.OPEN_FEEDBACK],
	privacyLevelConfigurationMap[PrivacyLevel.OPEN_PEER_REVIEW]
];
