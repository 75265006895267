import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import type {
	HighlightedSearchResults
} from 'ngx/go-modules/src/components/feedback-session/transcript-viewer/transcript-viewer.component';

export const DEBOUNCE_TIME = 250;
@Component({
	selector: 'transcript-search',
	template: require('./transcript-search.component.html'),
	styles: [require('./transcript-search.component.scss')]
})
export class TranscriptSearchComponent implements OnInit, OnDestroy, OnChanges {
	@Input() public searchResults: HighlightedSearchResults[] = [];
	@Input() public currentSearchIndex: number;

	@Output() public currentSearchIndexChange = new EventEmitter<number>();
	@Output() public searchTextChange = new EventEmitter<string>();

	public showActionButtons$ = new BehaviorSubject(false);

	@ViewChild('textInput')
	public textInput: ElementRef;
	public searchText: string = '';
	private searchTextUpdate$$: Subject<string> = new Subject<string>();
	private searchTextUpdateSubscription: Subscription;

	constructor () {}

	public ngOnInit () {
		this.searchTextUpdateSubscription = this.searchTextUpdate$$.pipe(
			debounceTime(DEBOUNCE_TIME),
			distinctUntilChanged()
		).subscribe((text: string) => {
			this.searchText = text;
			this.searchTextChange.emit(text);
		});
	}

	public ngOnChanges (changes: SimpleChanges) {
		if (changes.searchResults) {
			this.showActionButtons$.next(this.searchResults && this.searchResults.length > 1);
		}
	}

	public ngOnDestroy () {
		this.searchTextUpdateSubscription.unsubscribe();
	}

	public onSearchQueryUpdate (text: string) {
		this.searchTextUpdate$$.next(text);
	}

	public clearText () {
		this.searchText = '';
		this.searchTextUpdate$$.next('');
		this.textInput.nativeElement.focus();
	}

	public moveSearchResultSelection (direction: number): void {
		const newIndex = this.currentSearchIndex + direction;
		if (newIndex >= 0 && newIndex < this.searchResults.length) {
			this.currentSearchIndex = newIndex;
			this.currentSearchIndexChange.emit(this.currentSearchIndex);
		}
	}
}
