import { Injectable } from '@angular/core';
import { GoTooltipDirective } from './go-tooltip.directive';

@Injectable()
export class GoTooltipService {
	private currentOpen: GoTooltipDirective;

	public updateCurrentOpen (directive: GoTooltipDirective) {
		if(this.currentOpen !== directive) {
			this.currentOpen?.close();
			this.currentOpen = directive;
		}
	}
}
