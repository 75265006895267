import { Observable, Subject } from 'rxjs';
import type { GoToastOptions } from 'ngx/go-modules/src/interfaces/go-toast-options';

export class NgxGoToastService {
	private _toastSubject = new Subject<GoToastOptions|null>();

	constructor () {}

	public get toastObserver (): Observable<GoToastOptions|null> {
		return this._toastSubject.asObservable();
	}

	public createToast (toastOptions: GoToastOptions) {
		this._toastSubject.next(toastOptions);
	}

	public closeToast () {
		this._toastSubject.next(null);
	}
}
