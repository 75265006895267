import { IModalScope } from 'angular-ui-bootstrap';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { VIDEO_SHARE_SETTINGS } from 'go-modules/org-settings/org-settings.video-share.enum';

interface Bindings {
	orgSettings: any;
	sessionId: number;
};

export class VideoShareModalController implements Bindings, Partial<IModalScope> {

	public $close;
	public $dismiss;
	public orgSettings: any;
	public sessionId: number;

	public shareLink: string;
	public validateInput: boolean;
	public guestReviewersCount: number;
	public creatingLink: boolean = false;

	/* @ngInject */
	constructor (
		private $document: ng.IDocumentService,
		private $timeout: ng.ITimeoutService,
		private Session
	) {}

	public $onInit () {
		this.Session.get({
			session_id: this.sessionId
		}).$promise.then((session) => {
			if (session.external_session) {
				this.shareLink = `${clientSettings.VideoShareUrl}/sessions/${session.external_session}`;
				this.guestReviewersCount = session.guest_reviewers.length;
			}
		});
	}

	public isPrivateShare () {
		return this.orgSettings.allow_video_share === VIDEO_SHARE_SETTINGS.PRIVATE;
	}

	public isPublicShare () {
		return this.orgSettings.allow_video_share === VIDEO_SHARE_SETTINGS.PUBLIC;
	}

	public isPublicAndPrivateShare () {
		return this.orgSettings.allow_video_share === VIDEO_SHARE_SETTINGS.BOTH;
	}

	public createPublicShareLink () {
		this.creatingLink = true;
		this.Session.sharePublic({
			session_id: this.sessionId
		}).$promise.then((res) => {
			this.shareLink = `${clientSettings.VideoShareUrl}/sessions/${res.uuid}`;
		}).finally(() => {
			this.creatingLink = false;
		});
	}

	public sendInvite (form) {
		if (form.$invalid) {
			this.focusFirstError();
			return;
		}

		this.Session.sharePrivate({
			session_id: this.sessionId,
			external_reviewer_emails: [...form.emails]
		}).$promise.then((res) => {
			// TODO remove invalidUsers in any future deploy or delete whole modal
			// on clean up of PEER_REVIEW flag
			const invalidEmails = res.invalidExternalUsers || res.invalidUsers;
			this.$close({ invalidEmails });
		}).catch((err) => {
			this.$dismiss(err);
		});
	}

	public onBlur (form) {
		// when org settings is both, we shouldn't require the form to validate when invite form is empty
		if (this.isPublicAndPrivateShare() && !form.emailsField.$viewValue) {
			this.validateInput = false;
			this.$timeout(() => {
				form.$setPristine();
				form.$setUntouched();
			});
		} else {
			this.validateInput = true;
		}
	}

	public manageVideoSharing () {
		this.$dismiss({ openEditUserPanel: true });
	}

	private focusFirstError () {
		const firstItem = this.$document[0].querySelector('.modal.video-share .ng-invalid:not(form)') as HTMLElement;
		if (firstItem !== null) {
			firstItem.focus();
		}
	}
}
