import * as angular from 'angular';
import * as dayjs from 'dayjs';

/* @ngInject */
export function lessThanDirective ($parse) {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function link (scope, _elem, attrs, ngModelCtrl) {

			// Watch changes to passed property, cuz we need to re-run our validator when it changes
			const watcher = scope.$watch(function () {
				return $parse(attrs.lessThan)(scope);
			}, function (newVal, oldVal) {

				if (newVal !== oldVal) {
					ngModelCtrl.$validate();
				}
			});

			ngModelCtrl.$validators.lessThan = function (modelVal) {
				const compareVal = $parse(attrs.lessThan)(scope);

				// We can't compare them and enforce if they arent both dates
				if (!angular.isDate(modelVal) || !angular.isDate(compareVal)) {
					return true;
				}

				return dayjs(modelVal).isAfter(compareVal);
			};

			// un-register watcher
			scope.$on('$destroy', watcher);
		}
	};
}
