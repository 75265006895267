import angulartics from 'angulartics';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export class AccessibilityAttachmentsController implements ng.IOnInit, ng.IOnDestroy {

	public media;
	public groupId: number;
	public captionFileOptions: any;
	public audioDescriptionOptions: any;

	private captionMedia = null;
	private audioDescriptionMedia = null;
	private eventSubscription: Subscription;
	private origCaptionMediaId: number;

	/** @ngInject */
	constructor (
		private MediaModel,
		private $translate: ng.translate.ITranslateService,
		private $log: ng.ILogService,
		private UniversalMediaChooser,
		private goModal,
		private MediaVTT,
		private eventService: EventService,
		private SupplementaryMedia,
		private confirmModal,
		private $analytics: angulartics.IAnalyticsService,
		public helpUrls
	) {}

	public $onInit (): void {
		this.updateAccessibilityMedia();
		this.initEventWatcher();
		this.initThumbnailOptions();
	}

	public $onDestroy (): void {
		this.eventSubscription.unsubscribe();
	}

	public addCaptionMedia () {
		this.openUMC('fine-uploader/src/partials/caption-only-whitelist.json')
			.then(this.onCaptionUpload)
			.catch(this.$log.error);
	}

	public addAudioDescriptionMedia () {
		this.openUMC('fine-uploader/src/partials/audio-description-blacklist.json', this.MediaModel.TYPE.AUDIO)
			.then(this.onAudioDescriptionUpload)
			.catch(this.$log.error);
	}

	public removeCaptions () {
		return this.confirmModal.open({
			modalData: {
				title: this.$translate.instant('modal-confirm-delete-closed-caption_title'),
				message: this.$translate.instant('modal-confirm-delete-closed-caption_message'),
				yes: this.$translate.instant('common_delete'),
				no: this.$translate.instant('common_cancel')
			}
		}).result.then(() => {
			this.deleteCaptionMedia();
		}).catch(this.$log.error);
	}

	public removeAudioDescription () {
		return this.confirmModal.open({
			modalData: {
				title: this.$translate.instant('modal-confirm-delete-audio-description_title'),
				message: this.$translate.instant('modal-confirm-delete-audio-description_message'),
				yes: this.$translate.instant('common_delete'),
				no: this.$translate.instant('common_cancel')
			}
		}).result.then(() => {
			this.deleteAudioDescriptionMedia();
		}).catch(this.$log.error);
	}

	private updateAccessibilityMedia () {
		this.setCaptionMedia();
		this.setAudioDescriptionMedia();
	}

	private setAudioDescriptionMedia () {
		this.audioDescriptionMedia = this.media.getSupplementaryAudio().shift();
	}

	private getCaptionMedia () {
		const vtt = this.media.getMediaVTTCaptions().shift();
		const transformed = vtt != null ? this.MediaModel.transformMediaVttCaptionToMedia(vtt) : null;

		if (transformed !== null) {
			transformed.vtt_id = vtt.id;
			transformed.media_id = this.origCaptionMediaId || null;
		}

		return transformed;
	}

	private setCaptionMedia () {
		this.captionMedia = this.getCaptionMedia();
	}

	private onCaptionUpload = (captionMedia) => {
		this.origCaptionMediaId = captionMedia.media_id;
		this.captionMedia = captionMedia;
		this.captionMedia.media_id = this.media.getId();
		const captionVtt = this.MediaVTT.transformMediaToMediaVttCaption(this.captionMedia);
		captionVtt.$save()
			.then((item) => this.captionMedia.vtt_id = item.id)
			.catch(this.$log.error);
		this.media.addMediaVTT(captionVtt);
		this.setCaptionMedia();
	};

	private openUMC (blacklistFile, mediaType = null) {
		const umc = this.UniversalMediaChooser.get('umcModal');

		const defaultOptions = {
			headerOptions: [
				umc.MEDIA_TYPE.UPLOAD
			],
			defaultSection: umc.MEDIA_TYPE.UPLOAD,
			chooseAutomatically: true,
			uploadMinSizeLimit: 1,
			groupId: this.groupId,
			blacklist: null
		};

		if (blacklistFile) {
			defaultOptions.blacklist = blacklistFile;
		}

		return this.goModal.open({
			modal: 'umc',
			modalData: {
				options: {
					mediaType,
					...defaultOptions
				}
			},
			animation: false
		}).result;
	}

	private initEventWatcher () {
		// Media sync event handler
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {

				// video caption update
				if (parseInt(this.captionMedia?.media_id, 10) === parseInt(ev.data.media_id, 10)) {
					let captionMedia = ev.data;
					captionMedia.media_id = this.media.getId();
					captionMedia = this.MediaVTT.transformMediaToMediaVttCaption(captionMedia);
					captionMedia.media_url = ev.data.media_url;
					this.media.media_vtt = this.media.media_vtt.map(
						(item) => item.id === this.captionMedia.vtt_id ? captionMedia : item
					);

					// Refresh
					this.setCaptionMedia();
				}

				if (this.media.supplementary_media && this.media.supplementary_media.length) {
					const supplementaryId = parseInt(this.media.supplementary_media[0].supplementary_media_id, 10);
					// audio description update
					if (supplementaryId === parseInt(ev.data.media_id, 10)) {
						const supplementaryMedia = this.SupplementaryMedia.newInstance(this.media.getId(), ev.data);
						this.media.supplementary_media[0] = supplementaryMedia;

						// Refresh
						this.setAudioDescriptionMedia();
					}
				}
			});
	}

	private initThumbnailOptions () {
		// Media thumbnail options
		this.captionFileOptions = {
			actions: false,
			preventPreview: true,
			preventEdit: true,
			hideTitle: true
		};

		this.audioDescriptionOptions = {
			actions: false,
			preventEdit: true,
			hideTitle: true
		};
	}

	private deleteCaptionMedia () {
		const caption = this.media.media_vtt.find((item) => item.id === this.captionMedia.vtt_id);
		this.media
			.deleteMediaVTT(caption)
			.then(() => {
				this.$analytics.eventTrack('delete cc file');
				this.setCaptionMedia();
			}).catch(this.$log.error);
	}

	private deleteAudioDescriptionMedia () {
		this.media
			.deleteSupplementaryMedia(this.audioDescriptionMedia)
			.then(() => {
				this.$analytics.eventTrack('delete ad file');
				this.setAudioDescriptionMedia();
			}).catch(this.$log.error);
	}

	private onAudioDescriptionUpload = (media) => {
		const supplementaryMedia = this.SupplementaryMedia.newInstance(this.media.getId(), media);
		supplementaryMedia.media_type = this.MediaModel.TYPE.AUDIO;
		this.media.addSupplementaryMedia(supplementaryMedia);
		supplementaryMedia.$save();
		this.setAudioDescriptionMedia();
	};
}
