import fitty, { FittyInstance } from 'fitty';

/* @ngInject */
export const fitTextDirective = ($timeout: ng.ITimeoutService) => {
	return {
		link: (scope, element) => {
			let instance: FittyInstance;

			// This provides enough of a delay to ensure that
			// the element's font size gets calculated correctly.
			$timeout(() => {
				instance = fitty(element[0]);
			}, 100);

			scope.$on('$destroy', () => {
				if (instance) {
					instance.unsubscribe();
				}
			});
		}
	};
};
