import template from './attachments.component.html';
import { ActivityAttachmentsController } from './attachments.controller';

export default {
	bindings: {
		group: '<',
		activity: '<',
		attachments: '<',
		readonlyMode: '<',
		canDownloadAttachment: '<',
		analyticsCategory: '@',
		analyticsLabel: '@',
		onChange: '&'
	},
	controller: ActivityAttachmentsController,
	template
} as ng.IComponentOptions;
