/* @ngInject */
export function EditSessionScoreModalController ($scope, ActivityModel, Session) {
	const vm = this;
	vm.activity = null;
	vm.session = null;
	vm.score = null;

	/**
	 * Handles init life cycle hook
	 */
	vm.$onInit = function () {
		if (!($scope.activity instanceof ActivityModel)) {
			throw new Error('Parameter `activity` is required by EditSessionScoreModalController');
		} else if (!($scope.session instanceof Session)) {
			throw new Error('Parameter `session` is required by EditSessionScoreModalController');
		}

		vm.activity = $scope.activity;
		vm.session = $scope.session;
		vm.score = vm.session.score;
		vm.$close = $scope.$close;
		vm.$dismiss = $scope.$dismiss;
	};

	/**
	 * Handles form submit event
	 */
	vm.onSubmit = function () {
		vm.session.setScore(vm.score);
		// Pass a true value to indicate that the score has been modified
		// and should be saved by the entity that opened the modal.
		vm.$close(true);
	};

	/**
	 * Whether to show error message for a specific field
	 *
	 * @param field
	 * @returns {boolean}
	 */
	vm.shouldShowError = function (field) {
		return (field.$touched || vm.formController.$submitted) && Object.keys(field.$error).length > 0;
	};
}
