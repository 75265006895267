export function onBlurChangeDirective () {
	return {
		restrict: 'A',
		require: 'ngModel',
		priority: 1,
		link: (scope, element, attr, ngModelCtrl) => {
			if (attr.type === 'radio' || attr.type === 'checkbox') {
				return;
			}

			element.off('input').off('keydown').off('change');
			element.on('blur', function () {
				scope.$apply(function () {
					ngModelCtrl.$setViewValue(element.val());
				});
			});
		}
	};
}
