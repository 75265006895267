import template from './umc-record-session.component.html';
import { UniversalMediaChooserRecordSessionController as controller } from './umc-record-session.controller';

export default {
	bindings: {
		options: '<',
		umcInstanceName: '@'
	},
	controller,
	require: {
		umcController: '^universalMediaChooser'
	},
	template
};
