import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { activityTemplateModelInterceptor } from './activity-template.service';
import { ActivityTemplateModel } from './activity-template.factory';

export const activityTemplateModel = angular.module('go.models.activityTemplate', [
	angularResource
])
	.service('activityTemplateModelInterceptor', activityTemplateModelInterceptor)
	.factory(ActivityTemplateModel.NG1_INJECTION_NAME, ActivityTemplateModel)
	.name;
