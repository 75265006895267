// eslint-disable-next-line unicorn/filename-case
import * as angular from 'angular';

/* @ngInject */
export const uibModalDecorator = ($provide) => {
	$provide.decorator('$uibModal', [
		'$delegate',
		function $uibModalDecorator ($delegate) {
			const origOpen = $delegate.open;

			const openModals = [];

			const addPromise = (promise) => {
				openModals.push(promise);
				promise.then(() => {
					openModals.splice(openModals.indexOf(promise), 1);
				}).catch(() => {
					openModals.splice(openModals.indexOf(promise), 1);
				});
			};

			const fixClasses = (options) => {
				// Hack to make ui-bootstrap compatiable with es6 classes
				// ui-bootstrap doesn't understand that the `this` is lost when new'ing a es6 class
				// See https://stackoverflow.com/a/30759109 to see how to detect classes
				// Angular does the same isClass check (see function isClass, L5114)
				if (angular.isFunction(options.controller) &&
					/^class\b/.test(Function.prototype.toString.call(options.controller))) {
					options.controller = ((originalControllerClass) => {
						const patchController = function (...args) {
							args.unshift(null);
							const controller = new (Function.prototype.bind.apply(originalControllerClass, args))();
							return angular.extend(controller, this);
						};
						patchController.$inject = options.controller.$inject;
						return patchController;
					})(options.controller);
				}
			};

			$delegate.getOpenedModalPromises = function getOpenedModalPromises () {
				return openModals;
			};

			$delegate.open = function open (options) {
				fixClasses(options);
				const modal = origOpen.call($delegate, options);
				addPromise(modal.result);
				return modal;
			};

			return $delegate;
		}]);
};
