import * as angular from 'angular';
import { uibModalDecorator } from './$uib-modal.decorator';
import * as uiModal from 'angular-ui-bootstrap/src/modal';

export const uibModalDecoratorModule = angular
	.module('uib-modal-decorator', [
		uiModal
	])
	.config(uibModalDecorator)
	.name;

// Reverse dependency inject ourselves into anyone requesting uiModal
angular.module(uiModal).requires.push(uibModalDecoratorModule);
