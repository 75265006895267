import { YoutubeProcessor } from '../../models/media/youtube.processor';
import { EVENTS } from '../events';
import { STATES } from '../states';
import { PROVIDERS } from '../providers';
import { GoMediaPlayer } from './media-player';
import type { PlaylistItem } from '../playlist-item';
import { GoMediaPlayerError } from '../errors/error';
import { nextAnimationFrame } from '../../next-animation-frame/next-animation-frame';

const _window = (window as any);

export enum YOUTUBE_STATES {
	UNSTARTED = -1,
	ENDED = 0,
	PLAYING = 1,
	PAUSED = 2,
	BUFFERING = 3
}

export const youtubeStateMap = {
	[YOUTUBE_STATES.UNSTARTED]: STATES.IDLE,
	[YOUTUBE_STATES.ENDED]: STATES.COMPLETE,
	[YOUTUBE_STATES.PLAYING]: STATES.PLAYING,
	[YOUTUBE_STATES.PAUSED]: STATES.PAUSED,
	[YOUTUBE_STATES.BUFFERING]: STATES.BUFFERING
};

export interface YoutubePlayerOptions {
	width: string;
	height: string;
	url: string;
}

export class YoutubePlayerAdapter extends GoMediaPlayer {

	public static loading: Promise<void> = null;

	constructor (private youtubePlayer: any) {
		super();
		this.initEventAdapter();
	}

	public static async setup (elementId: string, options: YoutubePlayerOptions): Promise<YoutubePlayerAdapter> {
		await YoutubePlayerAdapter.waitForYoutubeAPIReady();

		const youtubePlayer = new _window.YT.Player(elementId, {
			width: options.width,
			height: options.height,
			videoId: YoutubeProcessor.getYoutubeId(options.url),
			playerVars: {
				controls: 0,
				autoplay: 0,
				disablekb: 1,
				modestbranding: 1,
				iv_load_policy: 3,
				cc_load_policy: 1,
				showinfo: 0,
				rel: 0,
				start: 0,
				playsinline: 1
			}
		});

		return new Promise((resolve) => {
			youtubePlayer.addEventListener('onReady', () => {
				const youtubePlayerAdapter = new YoutubePlayerAdapter(youtubePlayer);
				resolve(youtubePlayerAdapter);
			});
		});
	}

	public static waitForYoutubeAPIReady (): Promise<void> {

		if (this.loading) {
			return this.loading;
		}

		return this.loading = new Promise ((resolve) => {
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			tag.id = 'youtube-script';
			_window.onYouTubeIframeAPIReady = () => {
				resolve();
			};
			document.head.prepend(tag);
		});
	}

	public play (): void {
		this.emit(EVENTS.REQUEST_PLAY);
		this.youtubePlayer.playVideo();
	}

	public pause (): void {
		this.emit(EVENTS.REQUEST_PAUSE);
		this.youtubePlayer.pauseVideo();
	}

	public stop (): void {
		this.youtubePlayer.stopVideo();
	}

	public getTime (): number {
		return Math.floor(this.youtubePlayer.getCurrentTime() * 1000);
	}

	public getDuration (): number {
		return this.youtubePlayer.getDuration() * 1000;
	}

	public getElement (): HTMLIFrameElement {
		return this.youtubePlayer.getIframe();
	}

	public destroy (): void {
		super.destroy();
		this.youtubePlayer.destroy();
	}

	public getMute (): boolean {
		return this.youtubePlayer.isMuted();
	}

	public setMute (value: boolean): Promise<void> {
		if (value) {
			this.youtubePlayer.mute();
		} else {
			this.youtubePlayer.unMute();
		}
		return nextAnimationFrame().then(() => nextAnimationFrame());
	}

	public setVolume (value: number): void {
		this.youtubePlayer.setVolume(value);
	}

	public getVolume (): number {
		return this.youtubePlayer.getVolume();
	}

	public isPlaybackRateSupported (): boolean {
		return true;
	}

	public get playbackRate (): number {
		return this.youtubePlayer.getPlaybackRate();
	}

	public set playbackRate (value: number) {
		this.youtubePlayer.setPlaybackRate(value);
	}

	public captionsEnabled (): boolean {
		return false;
	}

	public toggleCaptions (_value?: boolean): void {
		throw new GoMediaPlayerError('Captions not available!');
	}

	public hasCaptions (): boolean {
		return false;
	}

	public getPlaylistItem (): PlaylistItem {
		return {
			file: this.youtubePlayer.getVideoUrl()
		};
	}

	public getProvider (): {name: PROVIDERS} {
		return { name: PROVIDERS.YOUTUBE };
	}

	protected seekTo (time: number /* in milliseconds */): void {
		this.youtubePlayer.seekTo(time / 1000);
	}

	private initEventAdapter (): void {
		this.youtubePlayer.addEventListener('onStateChange', () => {
			this.handlePlayerStateChange();
		});
	}

	private handlePlayerStateChange (): void {
		const youtubeState: number = this.youtubePlayer.getPlayerState();
		const state: STATES = youtubeStateMap[youtubeState] || STATES.IDLE;
		this.setState(state);
	}
}
