/**
 * TODO: We can slowly move this to NgxYouTubeProcessorService - youtube-processor.service.ts
 */

const youtubeRegex = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]+).*/;
const youtubeThumbnailUrlPrefix = 'https://img.youtube.com/vi/';
const youtubeThumbnailUrlSuffix = '/hqdefault.jpg';

export class YoutubeProcessor {

	public static isYoutubeUrl (url: string) {
		const match = url?.match(youtubeRegex);
		return match != null && match[1] != null;
	}

	public static getYoutubeId (url: string) {
		const match = url?.match(youtubeRegex);

		if (match != null && match[1] != null) {
			return match[1];
		}

		throw new Error('Youtube URL contains no youtube ID');
	}

	public static makeYoutubeThumbnailUrl (url: string) {
		const id = YoutubeProcessor.getYoutubeId(url);

		if (id != null) {
			return youtubeThumbnailUrlPrefix + id + youtubeThumbnailUrlSuffix;
		}

		throw new Error('Youtube URL contains no youtube ID');
	}
}
