import * as angular from 'angular';

import { treeViewDirective } from './tree-view.directive';
import { treeViewSharedFactory } from './tree-view.factory';
import './style.less';
import { featureFlagModule } from 'go-modules/feature-flag';

export const treeViewModule = angular.module('treeView', [
	featureFlagModule
])
	.directive('tree', treeViewDirective)
	.factory('treeShared', treeViewSharedFactory)
	.name;
