/* @ngInject */
export function FeedbackSessionStimulusMediaDisplayController (feedbackSession, mediaPlayer) {
	const vm = this;
	let stimulusPlayer;

	/**
	 * Controller init handler
	 */
	vm.$onInit = (): void => {
		const session = vm.mainController.session;

		// Restrict message that is shown when source media cannot be previewed
		vm.restrictMessage = feedbackSession.isRecordMode() ? 'source-media-restrict-recording' : 'source-media-restrict-graded';

		// Fetch stimulus player
		mediaPlayer.get(feedbackSession.sourcePlayerId(session)).then((player) => {
			stimulusPlayer = player;
		});
	};

	/**
	 * Whether or not to show the source media loading indicator
	 *
	 * @returns {boolean}
	 */
	vm.showStimulusMediaLoadingOverlay = function () {
		return feedbackSession.isRecordMode() &&
			vm.mainController.activity.has_single_recording_attempt &&
			!!stimulusPlayer &&
			stimulusPlayer.isBuffering();
	};
}
