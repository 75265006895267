import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const MULTIBYTE_MAX_LENGTH = 72;

export function goPasswordValidator (): ValidatorFn {
	const getStringByteLength = (value: string) => {
		const byteSize = new Blob([value]).size;
		return byteSize;
	};

	const isValid = (value: string): boolean => {
		const patterns = ['[a-z]', '[A-Z]', '\\d', '\\W'];

		if (! new RegExp('.{8,}').test(value)) {
			return false;
		}

		if (getStringByteLength(value) > MULTIBYTE_MAX_LENGTH) {
			return false;
		}

		return patterns.filter((pattern) => {
			return new RegExp(pattern).test(value);
		}).length >= 3;
	};

	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null;
		}

		return isValid(value) ? null : {goPassword: 'Password does not meet requirements'};
	};
}
