import * as angular from 'angular';

import { userModel } from './user';
import { groupDepModel } from './group-dep';
import { group } from './group';
import { userGroupModel } from './user-group';
import { groupServiceModule } from '../services/group';
import { activityModel } from './activity';
import { activityTemplateModel } from './activity-template';
import { sessionModel } from './session';
import { commentModel } from './comment';
import { rubricTemplateModel } from './rubric-template';
import { rubricSessionModel } from './rubric-session';
import { mediaModel } from './media';
import { tagSetModel } from './tag-set';
import { tagModel } from './tag';
import { useTypeModel } from './use-type';
import { userActivityHistoryModel } from './user-activity-history';
import { syncEventModel } from './sync-event';
import { inviteModelModule } from './invite';

export const allModelsModule = angular.module('go.models', [
	userModel,
	groupDepModel,
	group,
	userGroupModel,
	groupServiceModule,
	activityModel,
	activityTemplateModel,
	sessionModel,
	commentModel,
	rubricTemplateModel,
	rubricSessionModel,
	mediaModel,
	tagSetModel,
	tagModel,
	useTypeModel,
	userActivityHistoryModel,
	syncEventModel,
	inviteModelModule
])
	.name;
