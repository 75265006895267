import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emailValidator (): ValidatorFn {
	const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/);

	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null;
		}

		if (!regex.test(value)) {

			return { email: 'common-validation_email' };
		}

		return null;
	};
}
