import * as angular from 'angular';
import * as dayjs from 'dayjs';


/* @ngInject */
export function UserActivityHistoryModalController ($q, $scope, $log: angular.ILogService, UserActivityHistoryModel) {
	const vm = this;

	vm.$onInit = () => {
		vm.categorized = false;

		const promises = {
			history: null,
			data: null
		};
		$scope.isReady = false;
		$scope.expanded = false;

		if (!angular.isObject($scope.user)) {
			throw new Error('UserActivityHistoryModalController:: must supply user object');
		}

		// Fetch user activity history events
		if (!angular.isArray($scope.history)) {
			$scope.history = UserActivityHistoryModel.getEvents({
				user_id: $scope.user.user_id
			});

			promises.history = $scope.history.$promise;
		} else {
			promises.history = $q.when($scope.history);
		}

		// Fetch additional activity history data
		if (!angular.isObject($scope.data)) {
			$scope.data = UserActivityHistoryModel.get({
				user_id: $scope.user.user_id
			});

			promises.data = $scope.data.$promise;
		} else {
			promises.data = $q.when($scope.data);
		}

		// Wait for promises to resolve
		$q.all(promises).then(function (result) {
			$scope.isReady = true;

			// format data
			angular.forEach(result.data.uploads, function (upload) {
				try {
					upload.data = angular.fromJson(upload.data);
					upload.encoding = angular.fromJson(upload.encoding);

				} catch (e) {
					// Don't care about exceptions here
				}

				upload.filesize = Math.ceil(upload.filesize / 1000000);
				upload.started_at = dayjs.utc(upload.started_at).format('YYYY-MM-DD HH:mm:ss');
			});

			// Transform transactions
			const parseFloats = (item) => {
				if (item.data_type === 'transaction') {
					item.data.subtotal = parseFloat(item.data.subtotal);
					item.data.tax_amount = parseFloat(item.data.tax_amount);
				}
			};
			result.history.forEach(parseFloats);
			result.data.events.forEach(parseFloats);

			angular.forEach(result.data.sessions, function (session) {
				session.created_at = session.created_at ? dayjs.utc(session.created_at).toDate() : null;
				session.started_at = session.started_at ? dayjs.utc(session.started_at).toDate() : null;
				session.posted_at = session.posted_at ? dayjs.utc(session.posted_at).toDate() : null;
				session.viewed_recording_instructions_at = session.viewed_recording_instructions_at ?
					dayjs.utc(session.viewed_recording_instructions_at).toDate() : null;
				session.archived_at = session.archived_at ? dayjs.utc(session.archived_at).toDate() : null;
			});
		}).catch($log.error);
	};

	$scope.toggleExpand = function () {
		$scope.expanded = !$scope.expanded;
	};

	$scope.goToSession = function (session) {
		$scope.$close(session);
	};
}
