import {
	viewTemplate,
	editTemplate
} from './templates';

/* @ngInject */
export function cacheTemplates ($templateCache) {
	$templateCache.put('tag-editor/templates/view.html', viewTemplate);
	$templateCache.put('tag-editor/templates/edit.html', editTemplate);
}
