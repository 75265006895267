import template from './volume-indicator.component.html';
import { VolumeIndicatorController as controller } from './volume-indicator.controller';
import './style.less';

const bindings = {
	audioContext: '<',
	mediaStream: '<',
	active: '<'
};

export const VolumeIndicatorComponent = {
	bindings,
	controller,
	template
};
