import * as angular from 'angular';
import * as angularCookies from 'angular-cookies';
import { tourStepDirective } from './tour-step.component';
import * as angularTranslate from 'angular-translate';
import { masqueradeModule } from '../masquerade';
import { GoTourService } from './go-tour.service';
import { tourModelModule } from '../models/tour';
import { helpUrlsModule } from 'go-modules/help-urls';
import { responsiveViewModule } from '../responsive-view';
import { trapTabIndexModule } from 'go-modules/trap-tab-index';
import { featureFlagModule } from 'go-modules/feature-flag';

import './tour-step.less';
import { PlacementEngine } from './placement-engine.factory';

export const goTourModule = angular.module('go.tour', [
	angularTranslate,
	masqueradeModule,
	tourModelModule,
	helpUrlsModule,
	angularCookies,
	responsiveViewModule,
	trapTabIndexModule,
	featureFlagModule
])
	.service(GoTourService.NG1_INJECTION_NAME, GoTourService)
	.directive('tourStep', tourStepDirective)
	.factory(PlacementEngine.NG1_INJECTION_NAME, PlacementEngine)
	.name;
