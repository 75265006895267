import { Action } from './action';

export enum TranslationKey {
	PREVIEW = 'common_preview',
	EDIT = 'common_edit',
	REMOVE = 'common_delete'
}

export interface ActionTranslationKeyMap {
	[action: string]: TranslationKey;
}

export const TranslationKeyMap: ActionTranslationKeyMap = {
	[Action.PREVIEW]: TranslationKey.PREVIEW,
	[Action.EDIT]: TranslationKey.EDIT,
	[Action.REMOVE]: TranslationKey.REMOVE
};
