import { clientSettings } from 'go-modules/models/common/client.settings';
import { Attachment } from './attachment';

/* @ngInject */
export class AttachmentService {
	constructor (
		private $http: ng.IHttpService,
		private attachmentServiceTransformer,
		private AttachmentModel
	) {}

	public getSessionAttachments (sessionId: number): ng.IPromise<Attachment[]> {
		return this.$http.get(`${clientSettings.GoReactV2API}/attachments/session/${sessionId}`, {
			transformResponse: this.attachmentServiceTransformer.response
		}).then((response: ng.IHttpResponse<Attachment[]>) => {
			return response.data.map((item) => new this.AttachmentModel(item));
		});
	}
}
