import * as angular from 'angular';
import type { Color } from './resources/colors';
import { colors } from './resources/colors';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

// TODO: Use controller as syntax
/* @ngInject */
export function TagEditorController (
	$scope,
	tagEditor,
	eventService: EventService
) {
	const vm = this;

	vm.$onInit = () => {
		// tag model
		vm.tagModel = {};

		// available tag colors
		vm.tagColors = colors;

		// selected tag
		vm.selected = {
			tag: false
		};

		// expose settings
		vm.settings = tagEditor.defaults;
	};

	// tag color index {"ccc": 2, "ddd": 3}
	let tagColorCountIndex: {[hex: string]: number} = {};

	/**
	 *
	 * @param tag
	 */
	vm.selectTag = function (tag) {
		const scaledFocusEnabled = vm.getOption('scaledFocus');
		if (scaledFocusEnabled) {
			// TODO: Can we use $timeout here?
			setTimeout(function () {
				vm.selectTag(tag);
			}, 200);
		} else {
			vm.selectTag(tag);
		}
	};

	/**
	 * Set edit mode
	 */
	vm.setEditMode = function () {
		$scope.options.editing = true;
	};

	/**
	 * Set view mode
	 */
	vm.setViewMode = function () {
		$scope.options.editing = false;
	};

	/**
	 * Whether to show the container when there are no tags
	 */
	vm.showNoTagsContainer = () => {
		if (!$scope.options || ($scope.options.hasOwnProperty('hideLoading') && $scope.options.hideLoading)) {
			return false;
		}
		if (Array.isArray($scope.tagSet.tags)) {
			return !$scope.tagSet.tags.length && $scope.options.hasOwnProperty('extraEditModeButton') && $scope.options.extraEditModeButton;
		}
		return false;
	};

	/**
	 * Get an option
	 *
	 * @param key
	 * @returns {*}
	 */
	vm.getOption = function (key) {
		return angular.isObject($scope.options) ? $scope.options[key] : undefined;
	};

	/**
	 * Set an option
	 *
	 * @param key
	 * @param option
	 */
	vm.setOption = function (key, option) {
		if (angular.isObject($scope.options)) {
			$scope.options[key] = option;
		}
	};

	/**
	 * Tag set tags length change event handler
	 */
	$scope.$watch('tagSet.tags.length', function () {

		// update tag color index
		if ($scope.tagSet) {
			vm.updateTagColorIndex($scope.tagSet.tags);
		}

		// set default tag model color
		vm.tagColor = vm.getNextColor();
		vm.tagModel.tag_color = vm.tagColor.hex;
	});

	/**
	 * Reset tag model
	 */
	vm.resetTagModel = function () {
		vm.tagModel.tag_name = '';
		vm.tagModel.tag_abbreviation = '';
		vm.tagModel.tag_preset_comment = '';
	};

	/**
	 * Update tag color index
	 */
	vm.updateTagColorIndex = function (tags) {
		tagColorCountIndex = {};

		angular.forEach(vm.tagColors, function (color) {
			tagColorCountIndex[color.hex] = 0;
		});

		angular.forEach(tags, function (tag) {
			tagColorCountIndex[tag.tag_color]++;
		});
	};

	/**
	 * Returns the next available tag color
	 *
	 * @returns {string}
	 */
	vm.getNextColor = function () {

		const tagColors = angular.copy(vm.tagColors);

		// sort tags by count
		tagColors.sort(function (a: Color, b: Color) {
			return tagColorCountIndex[a.hex] - tagColorCountIndex[b.hex];
		});

		return tagColors[0];
	};

	/**
	 * Select a tag
	 *
	 * @param tag
	 */
	vm.selectTag = function (tag) {
		vm.selected.tag = tag;
		eventService.broadcast(EVENT_NAMES.TAG_EDITOR_TAG_SELECTED, tag);
	};
}
