/* @ngInject */
export function sessionResourceServiceDef (ActivityModel) {
	function SessionResourceService () { }

	/**
	 * Returns the session activity resource
	 *
	 * @param session
	 * @returns {ActivityModel}
	 */
	SessionResourceService.prototype.getActivity = function (session) {
		let activity = ActivityModel.getCache().get(session.activity_id);
		if (!activity) {
			activity = ActivityModel.model();
			ActivityModel.getCache().put(session.activity_id, activity);
			ActivityModel.get({ activity_id: session.activity_id });
		}
		return activity;
	};

	return new SessionResourceService();
}
