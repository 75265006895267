import * as angular from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';
import { CommentFilterService } from 'ngx/go-modules/src/services/comment-filter/comment-filter.service';

/* @ngInject */
export const CommentCaptionsController = function (
	$scope,
	$element,
	$attrs,
	$compile,
	$templateCache,
	CommentModel,
	ngxCommentFilterService: CommentFilterService,
	eventService: EventService,
	CommentResourceTypes,
	$sanitize
) {
	const vm = this,
		CAPTION_DURATION = 4000,
		innerEl = angular.element($element.children('span')[0]);
	let lookup = {}, // Comment captions look up
		lastCommentCaption;

	// List of comment captions
	vm.collection = [];

	/**
	 * Handle controller init
	 */
	vm.$onInit = () => {
		// Initially hide comment caption
		$element.css('display', 'none');

		// Enable / disable comment captions
		$attrs.$observe('disabled', (value) => {
			enable(!value);
		});
	};

	/**
	 * Handle controller destroy
	 */
	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
		removeEventListeners();
	};

	/**
	 * Session feedback loaded event handler
	 */
	vm.eventNames = [
		EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_LOADED,
		EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_SAVED,
		EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_DELETED
	];
	vm.eventSubscription = eventService.events
		.pipe(filter((ev: GoEvent) => vm.eventNames.includes(ev.name)))
		.subscribe((ev: GoEvent) => {
			switch (ev.name) {
				case EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_LOADED:
					clearFeedbackCaptions();

					// add feedback captions
					angular.forEach(ev.data.comment, (feedbackItem) => {
						addFeedbackCaption(feedbackItemAsFeedbackCaption(feedbackItem));
					});

					updateLookup();
					validate();
					break;
				case EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_SAVED:
					if (ev.data instanceof CommentModel) {
						const index = getFeedbackCaption(ev.data.getId());
						if (index !== -1) {
							updateFeedbackCaption(ev.data.getId(),
								feedbackItemAsFeedbackCaption(ev.data));
						} else {
							addFeedbackCaption(feedbackItemAsFeedbackCaption(ev.data));
						}

						updateLookup();
						validate();
					}
					break;
				case EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_DELETED:
					if (ev.data instanceof CommentModel) {
						removeFeedbackCaption(ev.data.getId());
						updateLookup();
						validate();
					}
					break;
			}
		});

	vm.isContentOverflowing = () => {
		return ($element[0].clientHeight < $element[0].scrollHeight);
	};

	/**
	 * Show / hide comment captions
	 *
	 * @param on
	 */
	function enable (on) {
		if (on) {
			addEventListeners();
		} else {
			removeEventListeners();
		}
		validate();
	}

	/**
	 * Verify that comment caption is rendered correctly
	 */
	function validate () {
		const time = !$attrs.disabled ? vm.mainController.playerSync.getTime() : null;
		render(time);
	}

	/**
	 * Render the current caption
	 *
	 * @param time
	 */
	function render (time) {
		// Convert milliseconds to seconds
		time = time === null ? null : Math.floor(time / 1000);

		// Find comment caption
		const commentCaption = lookup[time];
		if (commentCaption === lastCommentCaption) {
			return;
		}

		innerEl.empty();

		if (commentCaption?.scope.feedbackItem.created_by &&
			!ngxCommentFilterService.shouldCommentShow(commentCaption?.scope.feedbackItem)) {
			return;
		}

		if (commentCaption) {
			innerEl.append(commentCaption.element);
			$element.css('display', '');
		} else {
			$element.css('display', 'none');
		}

		lastCommentCaption = commentCaption;
	}

	/**
	 * Update the comment caption lookup
	 */
	function updateLookup () {
		const commentCaptions = vm.collection.slice(0);

		// Clear lookup
		lookup = {};

		// Sort the comment captions desc by start time
		commentCaptions.sort((a, b) => {
			return a.startTime - b.startTime;
		});

		// Update each comment caption in the lookup
		angular.forEach(commentCaptions, (commentCaption) => {
			updateLookupItem(commentCaption);
		});
	}

	/**
	 * Update a single comment caption in the lookup
	 *
	 * @param commentCaption
	 */
	function updateLookupItem (commentCaption) {
		const startTime = Math.floor(commentCaption.startTime / 1000);
		lookup[startTime] = commentCaption;

		const templatePath = 'feedback-session/comment-captions/caption/text.html';
		const template = $templateCache.get(templatePath);
		commentCaption.element = $compile(template)(commentCaption.scope);

		const ccEnd = commentCaption.startTime + CAPTION_DURATION; // Leave on 4 s
		let duration = Math.floor((ccEnd - commentCaption.startTime) / 1000);
		while (duration--) {
			const endTime = startTime + duration + 1;
			lookup[endTime] = commentCaption;
		}
	}

	/**
	 * Add all event listeners
	 */
	function addEventListeners () {
		vm.mainController.playerSync.on('time', onTime);
		vm.mainController.playerSync.on('complete', onComplete);
	}

	/**
	 * Remove all event listeners
	 */
	function removeEventListeners () {
		// NOTE: Its possible playerSync can be destroyed before the destroy for
		// comment captions is called.  If so, we don't need to bother calling off.
		if (vm.mainController.playerSync) {
			vm.mainController.playerSync.off('time', onTime);
			vm.mainController.playerSync.off('complete', onComplete);
		}
	}

	/**
	 * Handle time events
	 */
	function onTime () {
		validate();
	}

	/**
	 * Handle complete events
	 */
	function onComplete () {
		validate();
	}

	/**
	 * Get feedback caption by id
	 *
	 * @param id
	 * @returns {number}
	 */
	function getFeedbackCaption (id) {
		let index = -1;
		for (let i = 0; i < vm.collection.length; i++) {
			if (vm.collection[i].id === id) {
				index = i;
				break;
			}
		}
		return index;
	}

	/**
	 * Add a feedback caption
	 *
	 * @param feedbackCaption
	 */
	function addFeedbackCaption (feedbackCaption) {
		// Sync comments will not be shown to users
		// as comment captions. The slide change
		// will communicate a similar message.
		// End note comments should not be shown as
		// captions either.
		// so if no time on comment, or no text, media, and tags
		if (feedbackCaption.scope.feedbackItem.time === null ||
			(!feedbackCaption.scope.feedbackItem.text &&
				!feedbackCaption.scope.mediaResources?.length &&
				!feedbackCaption.scope.tagResources?.length)) {
			return;
		}
		vm.collection.push(feedbackCaption);
	}

	/**
	 * Update a feedback caption
	 *
	 * @param id
	 * @param data
	 * @returns {boolean}
	 */
	function updateFeedbackCaption (id, data) {
		const index = getFeedbackCaption(id);
		let feedbackCaption = false;
		if (index !== -1 && angular.isObject(data)) {
			feedbackCaption = vm.collection[index];
			angular.extend(feedbackCaption, data);
		}
		return feedbackCaption;
	}

	/**
	 * Remove a feedback caption
	 *
	 * @param id
	 * @returns {boolean}
	 */
	function removeFeedbackCaption (id) {
		const index = getFeedbackCaption(id);
		if (index !== -1) {
			vm.collection[index].scope.$destroy(); // ensure scope is destroyed
			vm.collection.splice(index, 1);
		}
		return index >= 0;
	}

	function clearFeedbackCaptions () {
		vm.collection = [];
	}

	/**
	 * Feedback item as feedback caption
	 *
	 * @param feedbackItem
	 * @returns {Object}
	 */
	function feedbackItemAsFeedbackCaption (feedbackItem) {
		const scope = $scope.$new();
		scope.feedbackItem = feedbackItem;
		if (feedbackItem.resource) {
			scope.tagResources = feedbackItem.resource.filter((res) => {
				return res.resource_type === CommentResourceTypes.TAG;
			});
			scope.mediaResources = feedbackItem.resource.filter((res) => {
				return res.resource_type === CommentResourceTypes.MEDIA;
			});
		}
		return {
			id: feedbackItem.getId(),
			startTime: feedbackItem.time,
			text: feedbackItem.text ? $sanitize(feedbackItem.text) : null,
			scope
		};
	}
};
