import { Injectable } from '@angular/core';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { FeatureTestsService } from './feature-flag-tests.service';
import type { ComplexFeatureFlagWhichSetting, FeatureTypes } from './feature-flag-types';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { NgxFullstoryService } from '../fullstory/fullstory.service';
import { NgxLoggerService } from 'ngx/go-modules/src/services/logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class NgxFeatureFlagService {
	constructor (
		private featureTests: FeatureTestsService,
		private fullstoryService: NgxFullstoryService,
		private logger: NgxLoggerService
	) {}

	public getSynchronizedFlags (): string[] {
		const flags = clientSettings.synchronizedFeatureFlags;
		return flags.filter((flag) => this.isAvailable(flag));
	}

	public isAvailable (featureKey: string): boolean {
		let flag = clientSettings.featureFlags[featureKey];
		if (localStorage.getItem('featureFlagOverrides')) {
			const flagOverrides = JSON.parse(localStorage.getItem('featureFlagOverrides'));
			if (flagOverrides.hasOwnProperty(featureKey)) {
				flag = flagOverrides[featureKey];
			}
		}

		const enabled = this.isFeatureAvailable(flag);
		const storedFeatureFlag = sessionStorage.getItem(`goreact-flag-${featureKey}`);
		if (enabled && (storedFeatureFlag == null || storedFeatureFlag === 'false')) {
			this.fullstoryService.isReady().then(()=> {
				this.fullstoryService.createEvent(FULLSTORY_EVENTS.FEATURE_FLAG_ENABLED, {
					flag: featureKey
				});
				sessionStorage.setItem(`goreact-flag-${featureKey}`, 'true');
			}).catch((err) => this.logger.error(err));
		}

		return enabled;
	}

	public which (featureKey: string): string {
		const feature = clientSettings.featureFlags[featureKey] as ComplexFeatureFlagWhichSetting<FeatureTestsService>;
		return (this.isFeatureAvailable(feature) ? feature.default : feature.fallback).toLowerCase();
	}

	private isFeatureAvailable = (feature: FeatureTypes<FeatureTestsService> | null) => {
		if (feature === false || feature == null) {
			return false;
		}
		if (feature === true) {
			return true;
		}

		return Object.keys(feature).every((check) => this.featureTests[check] == null ||
			this.featureTests[check](feature[check], this.isFeatureAvailable));
	};
}
