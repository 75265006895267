import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { UserService } from 'ngx/go-modules/src/services/user/user.service';
import angular from 'angular';
import { Language } from 'go-modules/translation-helper/go-localize-helper.service';

@Injectable({
	providedIn: 'root'
})
export class TermsService {

	constructor (
		private userService: UserService
	) {}

	public requiresUpdate (): Observable<any> {
		return new Observable((observer: Observer<any>) => {
			this.userService.requiresTermsUpdate().subscribe({
				next: (info) => {
					if (info.requires_update) {
						observer.next(info);
					} else {
						observer.error({requires_update: info.requires_update});
					}
				},
				error: (err) => {
					observer.error(err);
				}
			});
		});
	}

	public accept () {
		return this.userService.getSelf().subscribe((response: any) => {
			const data = {...response, accepted_terms_at: new Date()};
			if (!data.language) data.language = Language.EN;
			return this.userService.updateSelf(data).subscribe(angular.noop);
		});
	}
}
