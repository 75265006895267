import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'rubric-filter-dropdown',
	template: require('./rubric-filter-dropdown.component.html'),
	styles: [require('./rubric-filter-dropdown.component.scss')]
})
export class RubricFilterDropdownComponent implements OnInit {
	@Input() public rubrics: any[];
	@Input() public initial: any;
	@Output() public rubricChange: EventEmitter<any>  = new EventEmitter();
	public selected;

	public ngOnInit () {
		this.selected = this.initial;
	}

	public updated (rubric) {
		this.rubricChange.next(rubric);
	}
}
