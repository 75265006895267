import * as angular from 'angular';
import { colors } from './resources/colors';

// TODO: Use controller as syntax

/* @ngInject */
export function TagListItemController (
	$scope,
	$element: ng.IAugmentedJQuery
) {

	const vm = this;

	vm.$onInit = () => {
		// select tag immediately if it matches the selected tag in the options hash
		const selectedTag = $scope.tagEditor.getOption('selectedTag');
		if (selectedTag != null && $scope.tag) {
			if (parseInt(selectedTag.tag_id, 10) === parseInt($scope.tag.tag_id, 10)) {
				// TODO: Can we use $timeout here?
				setTimeout(function () {
					$element[0].focus();
				}, 1);
			}
		}

		$scope.$watch('tag', () => {
			if ($scope.tag == null) {
				return;
			}

			$scope.tagColor = colors.find((color) => color.hex === $scope.tag.tag_color);
			// One of the old colors before we updated out color pallette
			// Make a custom one instead.
			if ($scope.tagColor == null) {
				$scope.tagColor = {
					hex: $scope.tag.tag_color,
					name: 'Custom'
				};
			}
		});

		// whether tag is focused or not
		$scope.focused = false;

		// dynamic styling in focused state
		$scope.focusedStyle = {};
	};

	/**
	 * Focus event handler
	 */
	$scope.onFocus = function () {
		setFocused(true);
	};

	/**
	 * Blur event handler
	 */
	$scope.onBlur = function () {
		setFocused(false);
	};

	/**
	 * Color select event handler
	 *
	 * @param tag
	 */
	$scope.onColorSelect = function (color, tag) {
		tag.tag_color = color.hex;
		if (tag.tag_id > 0) {
			$scope.tagList.updateTag(tag);
		} else {
			focusInput();
		}
	};

	/**
	 * Name change event handler
	 *
	 * @param tag
	 */
	$scope.onTagChange = function (tag, $event?: JQueryKeyEventObject) {
		if (tag.tag_id > 0) {
			$scope.tagList.updateTag(tag);
		} else {
			add(tag);
		}

		$event?.stopPropagation();
		$event?.preventDefault();
	};

	/**
	 * Remove tag
	 *
	 * @param tag
	 */
	$scope.removeTag = function (tag) {
		$scope.tagList.removeTag(tag);
	};

	//for existing tags the limit is 1
	//new tags the limit is 0
	$scope.checkName = function (form: ng.IFormController, marker, limit, tagListCtrl) {
		const count = $scope.tagSet.tags.filter((tag) =>
			tag.tag_name?.toLowerCase() === marker.tag_name?.toLowerCase()).length;
		if (count > limit) {
			form.name.$setValidity('duplicate', false);
		} else {
			form.name.$setValidity('duplicate', true);
		}
		$element.toggleClass('form-error', form.$invalid);
		tagListCtrl.updateTagSetValidity();
		if (!marker.tag_name) {
			tagListCtrl.updateTagFormValidity();
		}
	};

	$scope.showNewTagError = function () {
		return ($scope.tagList.newTagForm.$error && $scope.tagList.newTagForm.$submitted) ||
			(($scope.tagList.newTagForm.name.$touched || $scope.tagList.newTagForm.$submitted) &&
			$scope.tagList.newTagForm.$error &&
			($scope.tagList.newTagForm.$error.required || $scope.tagList.newTagForm.$error.duplicate));
	};

	/**
	 * Add tag
	 *
	 * @param tag
	 */
	function add (tag) {
		try {
			const copiedTag = { ...tag };
			reset();
			$scope.tagList.addTag(copiedTag);
		} catch (e) {
			angular.noop();
		}
	}

	/**
	 * Reset
	 */
	function reset () {
		$scope.tagEditor.resetTagModel();
		focusInput();
	}

	/**
	 * Focus on tag input
	 */
	function focusInput () {
		const input = $element.find('input');
		input[0].focus();
		// clear input style
		input.attr('style', '');
	}

	/**
	 * Set tag focused
	 *
	 * @param value
	 */
	function setFocused (value) {
		$scope.focused = !!value;

		// set the selected tag if option is defined
		if ($scope.tag) {

			if ($scope.focused) {
				$scope.focusedStyle.borderColor = '#' + $scope.tag.tag_color;
				$scope.tagEditor.setOption('selectedTag', $scope.tag);
			} else {
				delete $scope.focusedStyle.borderColor;
				$scope.tagEditor.setOption('selectedTag', false);
			}
		}
	}
}
