import template from './slide-toggle.component.html';
import { SlideToggleController as controller } from './slide-toggle.controller';

export default {
	bindings: {
		ariaLabel: '@',
		disabled: '@',
		value: '=ngModel'
	},
	controller,
	require: {
		ngModelController: 'ngModel'
	},
	template,
	transclude: true
};
