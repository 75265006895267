import { clientSettings } from '../common/client.settings';

/* @ngInject */
export function UserActivityHistoryModel (
	$resource,
	userActivityHistoryModelTransformer
) {
	const baseUrl = `${clientSettings.GoReactV1API}/users`;

	const UserActivityHistoryModelFunc = $resource(baseUrl + '/', {user_id: '@user_id'}, {
		getEvents: {
			method: 'GET',
			url: `${window.goSettings.GoReactV2API}/users/:user_id/activity_history`,
			isArray: true,
			transformResponse: userActivityHistoryModelTransformer.response
		},
		get: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/users/:user_id/history`
		}
	});

	return UserActivityHistoryModelFunc;
}
