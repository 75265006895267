import * as angular from 'angular';

export class FeedbackNodeModel {

	public generation: number;
	public parent: FeedbackNodeModel;
	public numChildren: number;
	public children: FeedbackNodeModel[];

	/* @ngInject */
	constructor () {
		this.generation = 0;
		this.parent = null;
		this.numChildren = 0;
	}

	public getTotalDescendents (): number {
		return this.getAllDescendentsFlat().length;
	}

	public getAllDescendentsFlat (): FeedbackNodeModel[] {
		return this.getChildren().reduce((array, child) => {
			array.push(child);
			return array.concat(child.getAllDescendentsFlat());
		}, []);
	}

	public hasChildMatching (matchFn: (child: FeedbackNodeModel) => boolean): boolean {
		if (!this.hasChildren(true)) {
			return false;
		}

		return this.getChildren().reduce((found, child) => {
			return found || matchFn(child) || child.hasChildMatching(matchFn);
		}, false);
	}

	public hasChildren (actual: boolean = false): boolean {
		if (actual) {
			return !!this.getChildren().length;
		}
		return this.numChildren > 0;
	}

	public getChildren (): FeedbackNodeModel[] {

		if (!angular.isArray(this.children)) {
			this.children = [];
		}

		return this.children;
	}

	public setChildren (children: FeedbackNodeModel[] = []) {
		children.forEach((child) => child.setParent(this));
		this.children = children;
		this.numChildren = this.children.length;
	}

	public getChild (index: number): FeedbackNodeModel {
		const child = this.getChildren()[index];
		if (child instanceof FeedbackNodeModel) {
			return child;
		}
		return null;
	}

	public addChild (child: FeedbackNodeModel) {
		child.setParent(this);
		const children = this.getChildren();
		children.push(child);
		this.numChildren = children.length;
	}

	public removeChild (child: FeedbackNodeModel) {
		const children = this.getChildren();
		const index = children.indexOf(child);
		if (index >= 0) {
			children.splice(index, 1);
		}
		this.numChildren = children.length;
	}

	public getParent (): FeedbackNodeModel {
		return this.parent;
	}

	public setParent (parent: FeedbackNodeModel) {
		this.generation = parent.generation + 1;
		this.parent = parent;

		// Need to call set parent on my children to update depth
		this.getChildren().forEach((child) => child.setParent(this));
	}

	public hasParent (): boolean {
		return !!this.parent;
	}

	public hasChild (child: FeedbackNodeModel): boolean {
		return this.getChildren().some((myChild) => myChild === child);
	}

	public isOnlyChild (): boolean {
		return this.hasParent() &&
			this.getParent().getChildren().length === 1 &&
			this.getParent().hasChild(this);
	}

	public isGeneration (value): boolean {
		return this.generation === value;
	}

	public isRoot (): boolean {
		return !this.hasParent();
	}
}
