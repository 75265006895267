import { SessionCreationIntent } from '../intent';
import type { SessionCreationWizardOptions } from '../options';
import type { SessionCreationWizardStep } from './';
import { UADetect as UADetectClass } from '../../detect/ua-detect.service';
import { MediaSource } from 'go-modules/models/media';
import { ZoomService } from 'go-modules/services/zoom/zoom.service';
import { MessageModal } from 'go-modules/modals/message/modal.factory';

export class UploadStep implements SessionCreationWizardStep {

	/* @ngInject */
	constructor (
		private UniversalMediaChooser: any,
		private goModal: any,
		private Session: any,
		private UADetect: UADetectClass,
		private zoomService: ZoomService,
		private messageModal: MessageModal
	) {}

	/**
	 * Run this step
	 */
	public run (options: SessionCreationWizardOptions): ng.IPromise<void> {
		// When the group specifies the upload size limit, use it
		const uploadLimit: number = options.group.upload_limit > 0 ?
			options.group.upload_limit * 1024 * 1024 : 0;
		const umcOptions: any = this.getUniversalMediaChooserOptions(options.session, uploadLimit);
		const now: Date = new Date();

		return this.goModal.open({
			modal: 'umc',
			modalData: {
				options: umcOptions
			}
		}).result
			.then((response) => {
				if (response !== SessionCreationIntent.CONTINUE) {
					options.intent = SessionCreationIntent.UPLOAD;
					options.session.setMedia(response);
					options.session.started_at = now;
					return options.session.save().then(() => {
						if (response.source === MediaSource.ZOOM_IMPORT) {
							this.zoomService.queueImport(response.media_id, {
								group_id: options.group.group_id,
								download_url: response.zoom_download_url,
								resource_id: options.session.session_id,
								resource_type: 'session'
							}).then(() => {
								return options.session.post();
							}).catch(() => {
								options.session.archive();

								const modalData = {
									title: 'zoom-queue-recordings-error_title',
									message: 'zoom-queue-recordings-error_message',
									resolveBtnText: 'common_close',
									resolveBtnClass: 'primary-btn'
								};

								return this.messageModal.open({ modalData }).result;
							});
						} else {
							return options.session.post();
						}
					});
				}
			});
	}

	/**
	 * Determine whether this step is required
	 */
	public isRequired (_options: SessionCreationWizardOptions): boolean {
		return true;
	}

	/**
	 * Get universtal media chooser options
	 */
	private getUniversalMediaChooserOptions (session: any, uploadSizeLimit: number = 0): any {
		const umc: any = this.UniversalMediaChooser.get('umcModal');
		const headerOptions: any = [
			umc.MEDIA_TYPE.UPLOAD,
			umc.MEDIA_TYPE.YOUTUBE
		];
		let defaultSection = umc.MEDIA_TYPE.UPLOAD;

		headerOptions.unshift(umc.MEDIA_TYPE.RECORD_SESSION);
		defaultSection = umc.MEDIA_TYPE.RECORD_SESSION;

		if (!this.UADetect.isMobile()) {
			headerOptions.push(umc.MEDIA_TYPE.ZOOM);
		}

		return {
			headerOptions,
			defaultSection,
			groupId: session.group_id,
			resourceId: session.session_id,
			resourceType: this.Session.RESOURCE_TYPE,
			uploadSizeLimit,
			chooseAutomatically: true,
			blacklist: 'fine-uploader/src/partials/video-only-whitelist.json',
			notify: true
		};
	}
}
