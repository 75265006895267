import { IAugmentedJQuery } from 'angular';

export interface LayoutManagerConfiguration {
	feedbackOnlyMode: boolean;
	feedbackTools: boolean;
	feedbackDisabled: boolean;
	commentsDisabled: boolean;
	tagsDisabled: boolean;
}

export class LayoutManager {

	/**
	 * Apply a set of given layout modes to the host element
	 */
	public static apply (hostElement: IAugmentedJQuery, config: LayoutManagerConfiguration) {
		const cssClasses = {
			'tools-enabled': config.feedbackTools,
			'tools-disabled': !config.feedbackTools,
			'feedback-only-mode': config.feedbackOnlyMode,
			'feedback-disabled': config.feedbackDisabled,
			'comments-disabled': config.commentsDisabled,
			'tags-disabled': config.tagsDisabled
		};

		for (const className in cssClasses) {
			if (cssClasses.hasOwnProperty(className)) {
				if (hostElement.hasClass(className)) {
					hostElement.removeClass(className);
				}
				if (cssClasses[className]) {
					hostElement.addClass(className);
				}
			}
		}
	}
}
