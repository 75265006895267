import * as angular from 'angular';

/* @ngInject */
export function blurFocusDirective ($document) {

	/**
	 * Get closest element to a given element
	 */
	function closest (el, selector) {
		let matchesFn;

		// find vendor prefix
		['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatches', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
			if (typeof $document[0].body[fn] == 'function') {
				matchesFn = fn;
				return true;
			}
			return false;
		});

		// traverse parents
		while (el !== null) {
			const parent = el.parentElement;
			if (parent !== null && parent[matchesFn](selector)) {
				return parent;
			}
			el = parent;
		}

		return null;
	}

	return {
		restrict: 'E',
		require: '?ngModel',
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		link (scope, elm, attr, ctrl) {
			if (!ctrl) {
				return;
			}

			const formEl = angular.element(closest(elm[0], 'form'));

			elm.on('focus', function () {
				elm.addClass('has-focus');
				ctrl.hasFocus = true;
			});

			elm.on('blur', function () {
				elm.removeClass('has-focus');
				elm.addClass('has-visited');
				ctrl.hasFocus = false;
				ctrl.hasVisited = true;
			});

			formEl.on('submit', function () {
				elm.addClass('has-visited');
				ctrl.hasFocus = false;
				ctrl.hasVisited = true;
			});
		}
	};
}
