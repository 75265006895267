import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { opentokSessionFactory } from './factory';
import { featureFlagModule } from 'go-modules/feature-flag';

import type { OpenTokSessionFactory as ExportOpenTokSessionFactory, OpenTokSessionModel as ExportOpenTokSessionModel } from './schema';
export type OpenTokSessionFactory = ExportOpenTokSessionFactory;
export type OpenTokSessionModel = ExportOpenTokSessionModel;
export { OpenTokRole } from './role';

export default angular.module('go.models.opentokSession', [
	angularResource,
	featureFlagModule
])
	.factory('OpenTokSession', opentokSessionFactory)
	.name;
