import * as angular from 'angular';
import { LazyPaymentLoader } from './lazy-payment-loader.service';
import * as $ocLazyLoad from 'oclazyload';
import { ngxSelfPayServiceModule } from 'ngx/go-modules/src/services/self-pay';

export const lazyPaymentPanelModule = angular.module('lazy-payment-panel-loader', [
	$ocLazyLoad as $ocLazyLoad.IModuleConfig,
	ngxSelfPayServiceModule
])
	.service(LazyPaymentLoader.NG1_INJECTION_NAME, LazyPaymentLoader)
	.name;
