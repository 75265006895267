import template from './tree-view.directive.html';
import { TreeViewController } from './tree-view.controller';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';

/* @ngInject */
export function treeViewDirective ($compile, featureFlag: FeatureFlag) {
	// Here is the Directive Definition Object being returned
	// which is one of the two options for creating a custom directive
	// http://docs.angularjs.org/guide/directive
	return {
		restrict: 'AEC',
		require: '?ngModel',
		// We are stating here the HTML in the element the directive is applied to is going to be given to
		// the template with a ng-transclude directive to be compiled when processing the directive
		transclude: true,
		replace: true,
		scope: {
			family: '=',
			settings: '=',
			active: '=ngModel',
			filterExpression: '=',
			sortExpression: '='
		},
		template,
		controller: TreeViewController,
		compile (tElement, _tAttr, transclude) {
			const contents = tElement.contents().remove();
			let compiledContents;
			return function (scope, iElement) {

				scope.featureFlag = featureFlag;
				if (!compiledContents) {
					compiledContents = $compile(contents, transclude);
				}
				// Call the link function to link the given scope
				compiledContents(scope, (clone) => {
					// Appending the cloned template to the instance element, 'iElement',
					// on which the directive is to used.
					iElement.append(clone);
				});

				// root element
				const parentElement = iElement.parent();
				if (!parentElement.length || parentElement.prop('tagName').toLowerCase() !== 'li') {

					iElement.addClass('root');

					// add relative positioning to root element so that we have an offset parent
					iElement.css('position', 'relative');
				}
			};
		}
	};
}
