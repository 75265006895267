
import * as angular from 'angular';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class GroupsIndexService {
	public static readonly NG1_INJECTION_NAME = 'groupsIndex';
	private deferredCache = {};
	private cache = {};

	/* @ngInject */
	constructor (private $q, private Group) {}

	public get (parentId) {
		return this.cache[parentId];
	}

	public set (parentId, groups) {
		this.cache[parentId] = groups;
	}

	public add (group) {
		const key = group.parent_id;
		if (!angular.isArray(this.cache[key])) {
			this.cache[key] = [];
		}

		// add to index
		this.cache[key].push(group);

		// increment child count
		const parentGroup = this.Group.get(key);
		if (parentGroup) {
			parentGroup.numChildren++;
		}
	}

	public remove (group) {
		const key = group.parent_id;
		if (angular.isArray(this.cache[key])) {
			const index = this.cache[key].indexOf(group);
			if (index >= 0) {
				this.cache[key].splice(index, 1);
			}
		}

		// decrement child count
		const parentGroup = this.Group.get(key);
		if (parentGroup) {
			parentGroup.numChildren--;
		}
	}

	public move (group, newParentId) {

		// remove the group from the index
		this.remove(group);

		// change its parent id to the new parent
		group.setParentId(newParentId);

		// before we move, make sure that the group's
		// siblings are also loaded
		if (!this.has(newParentId)) {
			this.resolve(newParentId);
		}

		// add the group back in using the new parent id
		this.add(group);
	}

	public has (parentId) {
		const groups = this.get(parentId);
		return angular.isArray(groups) && groups.length;
	}

	/**
	 * Resolve dependencies
	 *
	 * @param groupId
	 * @param waitForDependencyResolution
	 * @returns {promise}
	 */
	public resolve (groupId, waitForDependencyResolution: boolean = false) {

		if (!groupId) {
			throw new Error('Cannot resolve empty group id');
		}
		let group = this.Group.get(groupId);
		let deferred = this.deferredCache[groupId];
		if (!deferred) {
			deferred = this.$q.defer();

			// cache deferred object by group id
			this.deferredCache[groupId] = deferred;

			// if group is found, resolve immediately
			if (group && !waitForDependencyResolution) {
				deferred.resolve(group);
			}

			if (!group) {
				this.Group.get(groupId, (result) => {
					group = result;
					deferred.resolve(group);
				}, () => deferred.reject(),
				/*ignoreCache*/ false);
			} else {
				deferred.resolve(group);
			}

			return deferred.promise;
		}

		// Handle error
		deferred.promise.catch(() => {
			delete this.deferredCache[groupId];
		});

		return deferred.promise;
	}

	public clear () {
		this.deferredCache = {};
		this.cache = {};
	}
}

export const groupsIndexServiceToken = upgradeNg1Dependency(GroupsIndexService);
