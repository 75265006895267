import template from './video-scene.component.html';
import { VideoSceneController as controller} from './video-scene.controller';

const bindings = {
	disabled: '<ngDisabled',
	hideControls: '<',
	options: '<',
	hideTimeDisplay: '<',
	onInit: '&',
	mediaId: '<',
	onConnectionStatus: '&'
};

export const VideoSceneComponent = {
	bindings,
	controller,
	template
};
