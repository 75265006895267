import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { clientSettings } from 'go-modules/models/common/client.settings';
import type { EmailVerificationResponse } from 'ngx/go-modules/src/enums/email-verification-response';
import type { SignupOrg } from 'ngx/dashboard/src/interfaces/sign-up/signup-orgs-response';
import type { SignupRequest } from 'ngx/dashboard/src/interfaces/sign-up/signup-request';
import { UseType } from 'go-modules/models/use-type/use-type.interface';
import type { AuthResponseSuccessDataInterface } from 'ngx/go-modules/src/services/auth/interfaces/auth-response-success.interface';
import type { SsoData } from 'ngx/dashboard/src/components/signup/course-invite';

@Injectable({
	providedIn: 'root'
})
export class SignUpService {
	constructor (private http: HttpClient) {}

	public sendVerificationEmail (email: string): Observable<void> {
		return this.http.post<void>(`${clientSettings.GoReactV2API}/registration/email-verification/send`, { email });
	}

	public validateCode (email: string, code: string): Observable<EmailVerificationResponse> {
		return this.http.post<EmailVerificationResponse>(`${clientSettings.GoReactV2API}/registration/email-verification/validate`, {
			email,
			code
		});
	}

	public getAllOrgs (): Observable<SignupOrg[]> {
		return this.http.get<SignupOrg[]>(`${clientSettings.GoReactV2API}/signup/orgs`);
	}

	public getUseTypes (includeOther = false, activeOnly = false): Observable<UseType[]> {
		return this.http.get<UseType[]>(`${clientSettings.GoReactV2API}/useTypes?includeOther=${includeOther}&activeOnly=${activeOnly}`);
	}

	public createAccount (data: SignupRequest): Observable<AuthResponseSuccessDataInterface> {
		return this.http.post<AuthResponseSuccessDataInterface>(`${clientSettings.GoReactV2API}/users/signup`, data);
	}

	public getSignupInfo (key: string): Observable<SsoData> {
		return this.http.get<SsoData>(`${clientSettings.GoReactV2API}/sso/signup-info/${key}`);
	}
}
