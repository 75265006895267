import { EVENTS } from '../events';
import { STATES } from '../states';
import { PROVIDERS } from '../providers';
import { GoMediaPlayer } from './media-player';
import type { PlaylistItem } from '../playlist-item';
import { GoMediaPlayerError } from '../errors/error';
import { VideoVolumeManager } from '../services/video-volume-manager';

export interface HlsStreamOptions {
	url: string;
}

export class HlsStreamAdapter extends GoMediaPlayer {
	private videoVolumeManager:  VideoVolumeManager;
	private captionsOn: boolean = false;
	private writeableDuration: number;

	constructor (private hlsPlayer: HTMLVideoElement, private options: HlsStreamOptions) {
		super();
		this.videoVolumeManager = new VideoVolumeManager(this.hlsPlayer);
		this.writeableDuration = 0;
		this.initEventAdapter();
	}

	public static async setup (element: HTMLElement, options: HlsStreamOptions): Promise<HlsStreamAdapter> {
		let HLS, hls;

		try {
			const module = await import(/* webpackChunkName: "HlsJs" */ 'hls.js');
			HLS = module.default;
		} catch (error) {
			throw new Error(`Failed to lazy load hls.js module: ${error.message}`);
		}

		const videoElement = document.createElement('video');
		videoElement.classList.add('hls-player');
		videoElement.setAttribute('crossorigin', 'anonymous');
		element.appendChild(videoElement);

		if (HLS.isSupported()) {
			hls = new HLS();
			hls.loadSource(options.url);
			hls.attachMedia(videoElement);
		}

		return new Promise((resolve, reject) => {
			if (hls) {
				const hlsStreamAdapter = new HlsStreamAdapter(videoElement, options);

				const getTotalDuration = (_event, data) => {
					if (hlsStreamAdapter && data.details?.totalduration) {
						hlsStreamAdapter.setDuration(data.details.totalduration);
						resolve(hlsStreamAdapter);
						hls.off(HLS.Events.LEVEL_LOADED, getTotalDuration);
					}
				};

				hls.on(HLS.Events.LEVEL_LOADED, getTotalDuration);
			} else {
				reject(new GoMediaPlayerError('HLS unsupported on your browser.'));
			}
		});
	}

	public play (): void {
		this.emit(EVENTS.REQUEST_PLAY);
		this.hlsPlayer.play();
	}

	public pause (): void {
		this.emit(EVENTS.REQUEST_PAUSE);
		this.hlsPlayer.pause();
	}

	// Not implemented
	public stop (): void {}

	public getTime (): number {
		return this.hlsPlayer.currentTime * 1000;
	}

	public getDuration (): number {
		return this.writeableDuration * 1000;
	}

	public setDuration (duration: number): void {
		this.writeableDuration = duration;
	}

	public getElement (): HTMLElement {
		return this.hlsPlayer;
	}

	public destroy (): void {
		this.videoVolumeManager.destroy();
		this.removeEventListeners();
		// The `pause`, `removeAttribute` and `load` are a recommendation to remove the video element
		// from this SO question:
		//   https://stackoverflow.com/questions/3258587/how-to-properly-unload-destroy-a-video-element/40419032.
		// If we don't do it this way, memory leaks occur.
		this.hlsPlayer.pause();
		this.hlsPlayer.removeAttribute('src');
		this.hlsPlayer.load();
		super.destroy();
	}

	public getMute (): boolean {
		return this.videoVolumeManager.getMuted();
	}

	public async setMute (muted: boolean): Promise<void> {
		this.videoVolumeManager.setMuted(muted);
	}

	public setVolume (volume: number): void {
		this.videoVolumeManager.setVolume(volume / 100);
	}

	public getVolume (): number {
		return this.videoVolumeManager.getVolume() * 100;
	}

	public isPlaybackRateSupported (): boolean {
		return !!this.hlsPlayer;
	}

	public get playbackRate (): number {
		return this.hlsPlayer.playbackRate;
	}

	public set playbackRate (value: number) {
		this.hlsPlayer.playbackRate = value;
	}

	public captionsEnabled (): boolean {
		return this.captionsOn;
	}

	public toggleCaptions (enable: boolean = !this.captionsOn): void {
		this.captionsOn = enable;

		if (this.captionsOn) {
			this.emit(EVENTS.AUDIO_STREAM, this.videoVolumeManager.getUngainedStream());
		} else {
			this.emit(EVENTS.AUDIO_STREAM, null);
		}
	}

	public hasCaptions (): boolean {
		return true;
	}

	public getPlaylistItem (): PlaylistItem {
		return null;
	}

	public getProvider (): {name: PROVIDERS} {
		return { name: PROVIDERS.HLSPLAYER };
	}

	protected seekTo (time: number /* in milliseconds */): void {
		this.hlsPlayer.currentTime = time / 1000;
	}

	private initEventAdapter (): void {
		this.hlsPlayer.addEventListener('playing', this.playingListener);
		this.hlsPlayer.addEventListener('pause', this.pauseListener);
		this.hlsPlayer.addEventListener('ended', this.endedListener);
		this.hlsPlayer.addEventListener('error', this.errorListener);
	}

	private removeEventListeners (): void {
		this.hlsPlayer.removeEventListener('playing', this.playingListener);
		this.hlsPlayer.removeEventListener('pause', this.pauseListener);
		this.hlsPlayer.removeEventListener('ended', this.endedListener);
		this.hlsPlayer.removeEventListener('error', this.errorListener);
	}

	private playingListener = (): void => {
		this.setState(STATES.PLAYING);
	};

	private pauseListener = (): void => {
		this.setState(STATES.PAUSED);
	};

	private endedListener = (): void => {
		this.setState(STATES.COMPLETE);
	};

	private errorListener = (event: {message: string}): void => {
		this.emit(EVENTS.ERROR, new GoMediaPlayerError(event.message));
	};
}
