import template from './attachments.component.html';
import { AttachmentsController } from './attachments.controller';

export default {
	bindings: {
		attachments: '=ngModel',
		onAdded: '&',
		onDownload: '&',
		onDelete: '&',
		onDeleted: '&',
		onEdit: '&',
		onEdited: '&',
		onPreview: '&',
		onUmcOpen: '&',
		onUmcClose: '&',
		options: '<'
	},
	controller: AttachmentsController,
	template
} as ng.IComponentOptions;
