import {
	FlexibleConnectedPositionStrategy,
	Overlay,
	OverlayConfig,
	OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
	Attribute,
	ChangeDetectorRef,
	ComponentRef,
	Directive,
	ElementRef,
	HostListener,
	Injector,
	Input,
	OnDestroy,
	Renderer2,
	ViewContainerRef
} from '@angular/core';
import {
	GoTooltipComponent,
	GO_TOOLTIP_DATA
} from './components/go-tooltip.component';
import {
	HORIZONTAL_ARROW_HEIGHT,
	HORIZONTAL_ARROW_WIDTH,
	TOOLTIP_POSITIONS,
	TRIGGER_ELEMENT_MIN_SIZE,
	VERTICAL_ARROW_HEIGHT,
	VERTICAL_ARROW_WIDTH
} from './constants';
import { fromEvent, merge, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, finalize, map, takeUntil, throttleTime } from 'rxjs/operators';
import { FocusableElementsUtil } from 'ngx/go-modules/src/utilities';
import { Component } from '@angular/core';
import { GoTooltipService } from './go-tooltip-service';
import type { ArrowDimensionStyle } from './interfaces/arrow-dimension-style';
import { ArrowAngle } from './enums/arrow-angle.enum';
import type { GoTooltipData } from './interfaces/go-tooltip-data';

/**
 * Placements:
 * 		-[ top|left, 		top|center, 		top|right 			]
 * 		-[ bottom|left,		bottom|center, 		bottom|right 		]
 * 		-[ left|top, 		left|center, 		left|bottom			]
 * 		-[ right|top,		right|center, 		right|bottom		]
 *
 * Example:
 * 		- <button goTooltip="content" goTooltipPlacement="top|left"></button>
 * 		- <button [goTooltip]="variable" [goTooltipPlacement]="variable"></button>
 *
 * Note:
 * 		- Tooltip position default placement is top|center.
 * 		- Tooltip content can be a Text|Component.
 * 		- If trigger element has (click) event or an Input element, toggle will be disabled.
 * 		- will add tabindex=0 if the trigger element does not have one.
 * 		- It will add aria-labelledby attribute to the trigger element if aria-label is missing
 */
@Directive({
	selector: '[goTooltip]'
})
export class GoTooltipDirective implements OnDestroy {
	@Input()
	public goTooltip: any;

	@Input()
	public goTooltipPlacement;

	@Input()
	public goTooltipDisabled: boolean = false;

	public overlayConfig;
	public overlayRef: OverlayRef;
	public portalComponentRef: ComponentRef<GoTooltipComponent>;

	private positionStrategy: FlexibleConnectedPositionStrategy;
	private positionSubscription: Subscription;
	private globalEventsListener: Subscription;
	private isOpen = false;
	private preventCloseOnFocusOut = false;
	private preventOpenOnFocusIn = false;
	private mouseEvent$$ = new Subject<PointerEvent>();
	private destroyed$$ = new Subject();

	constructor (
		@Attribute('aria-label') private ariaLabel: string,
		private elementRef: ElementRef<HTMLElement>,
		private viewContainerRef: ViewContainerRef,
		private overlay: Overlay,
		private injector: Injector,
		private goTooltipService: GoTooltipService,
		private cdr: ChangeDetectorRef,
		private renderer2: Renderer2
	) {
		this.setAriaLabel();
		this.addTabIndex();
		this.onbserveClickAndFocusEvents();
		this.observeHoverEvent();
	}

	@HostListener('mouseleave', ['$event'])
	public mouseout ($event: PointerEvent) {
		this.mouseEvent$$.next($event);
	}

	@HostListener('mouseenter', ['$event'])
	public onMouseEnter ($event) {
		this.mouseEvent$$.next($event);
	}

	@HostListener('keydown', ['$event'])
	public onKeyDown ($event: KeyboardEvent) {
		if (this.isOpen && $event.key === 'Tab' && !$event.shiftKey) {
			const firstActiveElement = FocusableElementsUtil.getFirstFocusableElement(this.overlayRef.overlayElement);

			if (firstActiveElement) {
				this.preventCloseOnFocusOut = true;
				firstActiveElement.focus();
				$event.preventDefault();
			}
		}
	}

	public ngOnDestroy () {
		this.destroyed$$.next(null);
		this.destroyed$$.complete();
		this.close();
	}

	public show () {
		if(this.isOpen || this.goTooltipDisabled) {
			return;
		}

		this.isOpen = true;
		this.goTooltipService.updateCurrentOpen(this);
		this.buildPositionStrategy();

		this.overlayConfig = new OverlayConfig({ positionStrategy: this.positionStrategy});
		this.overlayRef = this.overlay.create(this.overlayConfig);

		this.portalComponentRef = this.overlayRef.attach(this.portal);
		this.positionSubscription = this.positionStrategy.positionChanges
			.pipe(takeUntil(this.destroyed$$)).subscribe(() => this.setArrowPosition());
		this.positionStrategy.apply();

		this.setArrowPosition();
		this.listenGlobalEvents();
		this.cdr.detectChanges();
	}

	public close () {
		if(!this.isOpen) {
			return;
		}

		this.isOpen = false;
		this.preventCloseOnFocusOut = false;
		this.preventOpenOnFocusIn = false;
		this.overlayRef.dispose();

		this.positionSubscription.unsubscribe();
		this.globalEventsListener.unsubscribe();
		this.cdr.detectChanges();
	}

	private observeHoverEvent () {
		this.mouseEvent$$.asObservable()
			.pipe(debounceTime(100)).pipe(takeUntil(this.destroyed$$)).subscribe((event: PointerEvent) => {
				if (event.type === 'mouseenter') {
					this.show();
				} else {
					this.close();
				}
			});
	}

	private onbserveClickAndFocusEvents () {
		const click = fromEvent(this.elementRef.nativeElement, 'mousedown');
		const focusin = fromEvent(this.elementRef.nativeElement, 'focusin');
		const focusout = fromEvent(this.elementRef.nativeElement, 'focusout');
		const keydown = fromEvent(this.elementRef.nativeElement, 'keydown')
			.pipe(filter((event: KeyboardEvent) => (event.key === ' ' || event.key === 'Enter')));

		merge(click, focusin, focusout, keydown)
			.pipe(takeUntil(this.destroyed$$), throttleTime(10))
			.pipe(map((event: FocusEvent | PointerEvent | KeyboardEvent) => {
				const canBeToggle = !this.cannotBeToggle();
				switch(event.type) {
					case 'mousedown':
						// When clicking a button clicking it call 3-4 events mousedown, mouseup, focusin and out
						// we need to handle it properly so it will only toggle the tooltip on mousedown and
						// ignore other events
						this.preventOpenOnFocusIn = true;
						this.preventCloseOnFocusOut = true;

						if(canBeToggle || !this.isOpen) {
							return this.isOpen ? 'close' : 'show';
						}

					case 'focusin':
						const preventOpenOnFocusIn = this.preventOpenOnFocusIn ? 'do-nothing' : 'show';
						this.preventCloseOnFocusOut = false;

						return preventOpenOnFocusIn;

					case 'focusout':
						const preventCloseOnFocusOut = this.preventCloseOnFocusOut ? 'do-nothing' : 'close';
						this.preventCloseOnFocusOut = false;

						return preventCloseOnFocusOut;

					case 'keydown':
						if(canBeToggle || !this.isOpen) {
							event.preventDefault();
							return this.isOpen ? 'close' : 'show';
						}
					default: return 'do-nothing';

				}
			}))
			.pipe(filter((value) => value !== 'do-nothing'))
			.subscribe((action: string) => {
				switch(action) {
					case 'show':
						this.show();

						break;

					case 'close':
						this.close();
				}

				this.preventOpenOnFocusIn = false;
				this.preventCloseOnFocusOut = false;

			});
	}

	private get content (): string | Component {
		return this.goTooltip;
	}

	private get placement (): string[] {
		const placement = this.goTooltipPlacement;
		const positions = placement ? placement.split('|') : [];
		const originPosition = positions.length > 0 ? positions[0] : 'top';
		const overlayPosition = positions.length > 1 ? positions[1] : 'center';
		return [originPosition.toLowerCase(), overlayPosition.toLowerCase()];
	}

	private get portal () {
		return new ComponentPortal(
			GoTooltipComponent,
			null,
			this.createInjector()
		);
	}

	private get tooltipData (): GoTooltipData {
		return {
			content: this.content,
			onTabbedOut: () => {
				this.onTabbedOutTooltip();
			},
			onHoverEvent: ($event: PointerEvent) => {
				this.mouseEvent$$.next($event);
			}
		};
	}

	private buildPositionStrategy () {
		this.positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.elementRef.nativeElement)
			.withPositions([TOOLTIP_POSITIONS[this.placement.join('|')]]);
	}

	private createInjector (): Injector {
		return Injector.create({
			parent: this.injector,
			providers: [{
				provide: GO_TOOLTIP_DATA,
				useValue: this.tooltipData
			}]
		});
	}

	private setArrowPosition (): any {
		const placement = this.placement;
		const originPosition = placement[0];
		let overlayPosition = placement[1];
		const style: ArrowDimensionStyle = {
			height: '0',
			width: '0'
		};
		let arrowAngle: ArrowAngle;
		const tooltipRect = this.overlayRef.overlayElement.querySelector('#goTooltipId').getBoundingClientRect();
		const triggerElementRect =  this.elementRef.nativeElement.getBoundingClientRect();
		let arrowPosition = 0; // this make sure that arrow position stick on the trigger element

		if (originPosition === 'top' || originPosition === 'bottom') {
			style.height = VERTICAL_ARROW_HEIGHT + 'px';
			style.width = VERTICAL_ARROW_WIDTH + 'px';
			style[originPosition === 'top' ? 'bottom' : 'top'] = `${-(VERTICAL_ARROW_HEIGHT - 1)}px`;
			arrowAngle = originPosition === 'top' ? ArrowAngle.TOP : ArrowAngle.BOTTOM;

			// if trigger element height is less than or equal TRIGGER_ELEMENT_MIN_SIZE
			// will just center the arrow position
			if(triggerElementRect.width <=  TRIGGER_ELEMENT_MIN_SIZE) {
				overlayPosition = 'center';
			}

			style.left = '0';
			switch(overlayPosition) {
				case 'right':
					arrowPosition = (triggerElementRect.left - tooltipRect.left) +
						triggerElementRect.width - VERTICAL_ARROW_WIDTH;
					style.transform=`translateX(${arrowPosition}px)`;
					break;
				case 'left':
					arrowPosition = triggerElementRect.left - tooltipRect.left;
					style.transform=`translateX(${arrowPosition}px)`;
					break;
				default:
					const arrowStartPos = triggerElementRect.x - tooltipRect.x;
					const triggerElementHaftWidth = triggerElementRect.width / 2;
					const centerOfArrowHalftWidth = VERTICAL_ARROW_WIDTH / 2;

					arrowPosition =  arrowStartPos + triggerElementHaftWidth - centerOfArrowHalftWidth;
					style.transform=`translateX(${arrowPosition}px)`;
			}
		} else {
			style.height = HORIZONTAL_ARROW_WIDTH + 'px';
			style.width = HORIZONTAL_ARROW_HEIGHT + 'px';
			style[originPosition === 'left' ? 'right' : 'left'] = `${-HORIZONTAL_ARROW_HEIGHT}px`;
			arrowAngle = originPosition === 'left' ? ArrowAngle.LEFT : ArrowAngle.RIGHT;

			// if trigger element width is less than or equal TRIGGER_ELEMENT_MIN_SIZE will
			// just center the arrow position
			if(triggerElementRect.height <=  TRIGGER_ELEMENT_MIN_SIZE) {
				overlayPosition = 'center';
			}

			style.top = '0';
			switch(overlayPosition) {
				case 'top':
					arrowPosition = triggerElementRect.top - tooltipRect.top;
					style.transform=`translateY(${arrowPosition}px)`;
					break;
				case 'bottom':
					arrowPosition = (triggerElementRect.y - tooltipRect.y  +
						triggerElementRect.height) - HORIZONTAL_ARROW_WIDTH;
					style.transform=`translateY(${arrowPosition}px)`;
					break;
				default:
					const arrowStartPos = triggerElementRect.y - tooltipRect.y;
					const triggerElementHaftWidth = triggerElementRect.height / 2;
					const centerOfArrowHalftWidth = HORIZONTAL_ARROW_WIDTH / 2;
					arrowPosition =  arrowStartPos + triggerElementHaftWidth - centerOfArrowHalftWidth;

					style.transform=`translateY(${arrowPosition}px)`;
			}
		}

		this.portalComponentRef.instance.updatePosition(style, arrowAngle);
	}

	private listenGlobalEvents () {
		const emitter = new Subject();

		const closeOnScrollHandler = (event) => {
			if (event.target.contains(this.elementRef.nativeElement)) {
				this.close();
			}
		};

		const closeOnEscapeKeyHandler = ($event: KeyboardEvent) => {
			if($event.key === 'Escape') {
				this.close();
				this.preventOpenOnFocusIn = true;
				this.elementRef.nativeElement.focus();
			}
		};

		const closeOnClickOutsideHandler = ($event: PointerEvent) => {
			const inOrTheTriggerelement = this.elementRef.nativeElement.contains($event.target as Element) ||
				this.elementRef.nativeElement === $event.target;
			const inOrTheOverlayElement = this.overlayRef.overlayElement.contains($event.target as Element) ||
				this.overlayRef.overlayElement === $event.target;

			if(!inOrTheTriggerelement && !inOrTheOverlayElement) {
				this.close();
			} else {
				this.preventCloseOnFocusOut = true;
			}
		};

		// renderer2.listen won`t work in this case.
		// it emit the event when the scrolled element is not the window scroll
		document.addEventListener('scroll', closeOnScrollHandler, true);
		const keydownEventDestroyFn = this.renderer2.listen(window, 'keydown', closeOnEscapeKeyHandler);
		const mousedownEventDestroyFn = this.renderer2.listen(window, 'mousedown', closeOnClickOutsideHandler);

		// For destroying event purposes when tooltip closed
		this.globalEventsListener = emitter.asObservable().pipe(
			finalize(() => {
				emitter.complete();
				document.removeEventListener('scroll', closeOnScrollHandler, true);
				keydownEventDestroyFn();
				mousedownEventDestroyFn();
			})
		).subscribe();
	}

	private onTabbedOutTooltip () {
		this.preventOpenOnFocusIn = true;
		this.elementRef.nativeElement.focus();
		this.close();
	}

	private cannotBeToggle (): boolean {
		const hasClickEvent = (this.viewContainerRef.injector as any).elDef.outputs.find((value) => value.eventName === 'click');
		const isInput = this.elementRef.nativeElement.tagName === 'input';

		return hasClickEvent || isInput;
	}

	private addTabIndex () {
		if (this.elementRef.nativeElement.getAttribute('tabindex') === null) {
			this.renderer2.setAttribute(this.elementRef.nativeElement, 'tabindex', '0');
		}
	}

	private setAriaLabel () {
		if (!this.ariaLabel) {
			this.renderer2.setAttribute(this.elementRef.nativeElement, 'aria-labelledby', 'goTooltipId');
		}
	}
}
