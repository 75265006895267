import * as angular from 'angular';

import { userActivityHistoryDirective } from './user-activity-history.directive';
import { uahFromNow } from './user-activity-history-from-now.filter';
import { uahDateFilter } from './user-activity-history-date.filter';
import { uahBytesToSize } from './user-activity-history-bytes-to-size.filter';
import { templates } from './templates';
import { time } from '../time';
import { userActivityHistoryModel } from '../models/user-activity-history';
import { userActivityHistoryItemRenderer } from './item-renderer/item-renderer.directive';
import './style.less';

export const userActivityHistory = angular.module('go.user-activity-history', [
	userActivityHistoryModel,
	time // for formatTime filter
])
	.run(templates)
	.directive('userActivityHistory', userActivityHistoryDirective)
	.filter('uahFromNow', uahFromNow)
	.filter('uahDateFilter', uahDateFilter)
	.filter('uahBytesToSize', uahBytesToSize)
	.directive('userActivityHistoryItemRenderer', userActivityHistoryItemRenderer)
	.name;
