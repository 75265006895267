import videoTemplate from './video/widget.html';
import textTemplate from './text/widget.html';
import qualitativeTemplate from './qualitative/widget.html';
import pointsTemplate from './points/widget.html';
import numericTemplate from './numeric/widget.html';
import checkboxTemplate from './checkbox/widget.html';
import categoryTemplate from './category/widget.html';
import breakTemplate from './break/widget.html';
import audioTemplate from './audio/widget.html';

/* @ngInject */
export function widgetTemplates ($templateCache) {
	$templateCache.put('rubric/widget/video/widget.html', videoTemplate);
	$templateCache.put('rubric/widget/text/widget.html', textTemplate);
	$templateCache.put('rubric/widget/qualitative/widget.html', qualitativeTemplate);
	$templateCache.put('rubric/widget/points/widget.html', pointsTemplate);
	$templateCache.put('rubric/widget/numeric/widget.html', numericTemplate);
	$templateCache.put('rubric/widget/checkbox/widget.html', checkboxTemplate);
	$templateCache.put('rubric/widget/category/widget.html', categoryTemplate);
	$templateCache.put('rubric/widget/break/widget.html', breakTemplate);
	$templateCache.put('rubric/widget/audio/widget.html', audioTemplate);
}
