import { Injectable } from '@angular/core';
import type { Media } from 'go-modules/services/attachment/media';
import { HttpClient } from '@angular/common/http';
import { MediaTranscription } from 'ngx/go-modules/src/interfaces/media-transcription';
import { Observable } from 'rxjs';
import { clientSettings } from 'go-modules/models/common/client.settings';

@Injectable({
	providedIn: 'root'
})
export class TranscriptionService {
	constructor (private http: HttpClient) {}

	public getTranscription (media: Media, includeAnalytics: boolean = false): Observable<MediaTranscription> {
		return this.http.get<MediaTranscription>(`${clientSettings.GoReactV2API}/media/${media.media_id}/transcription?includeAnalytics=${includeAnalytics}`);
	}

	public createTranscription (media: Media): Observable<MediaTranscription> {
		return this.http.post<MediaTranscription>(`${clientSettings.GoReactV2API}/media/${media.media_id}/transcription`, null);
	}

	public updateSpeakers (transcriptId: number, payload: any): Observable<MediaTranscription> {
		return this.http.put<MediaTranscription>(`${clientSettings.GoReactV2API}/transcriptions/${transcriptId}/speaker-name`, payload);
	}
}
