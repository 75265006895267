import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import type { MediaTranscriptionAnalytic } from 'ngx/go-modules/src/interfaces/media-transcription-analytic';
import * as Highcharts from 'highcharts';
import { FormatTimePipe } from 'ngx/go-modules/src/pipes/format-time/format-time.pipe';
import { Color, colors } from 'ngx/go-modules/src/interfaces/colors';
import { TranslateService } from '@ngx-translate/core';
import type { PlayerSync } from 'ngx/go-modules/src/interfaces/media/media';
import * as ContrastColor from 'color';
import { WordGraphType } from 'ngx/go-modules/src/enums/word-graph-type';
import { floor } from 'lodash';
import { ViewChild } from '@angular/core';

interface WordCount {
	word: string;
	count: number;
	color: string;
}

interface TypeSeriesData {
	x: number;
	y: number;
	actualTime: number;
	marker: object;
	text: string;
}

@Component({
	selector: 'word-metric-graph',
	template: require('./word-metric-graph.component.html'),
	styles: [require('./word-metric-graph.component.scss')]
})
export class WordMetricGraphComponent implements OnInit, OnChanges {
	@Output() public onExpanded = new EventEmitter();

	@Input() public isExpanded: boolean;
	@Input() public analytic: MediaTranscriptionAnalytic;
	@Input() public media: any;
	@Input() public playerSync: PlayerSync | null;
	@Input() public type: WordGraphType;
	@Input() public shouldTease: boolean;

	@ViewChild('chart') public chartRef;

	public titleTranslation: string;
	public summary: string;
	public normalTranslation: string;
	public Highcharts: typeof Highcharts = Highcharts;
	public chartOptions: Highcharts.Options;
	public wordTypeWords: WordCount[];
	public seriesData: TypeSeriesData[];
	public noWords = false;
	public zeroStateTranslation: string;

	private intervalDuration: number;

	constructor (private translate: TranslateService) {
	}

	public ngOnChanges () {
		let wordTypeTotal: number = 0;

		if (this.analytic) {
			wordTypeTotal = this.type === WordGraphType.FILLER
				? this.analytic.filler_total
				: this.analytic.hedging_total;
		}

		this.summary = this.buildSummary(wordTypeTotal);

		if (wordTypeTotal === 0) {
			this.noWords = true;
		} else {
			this.noWords = false;
			// destroy previous chart so it renders correctly
			this.chartRef?.ngOnDestroy();
			this.buildData();
			this.setOptions(wordTypeTotal);
		}
	}

	public ngOnInit (): void {
		this.zeroStateTranslation = 'transcript-viewer-zero-state_youtube-subtext';

		if (!this.media.isYoutube()) {
			this.zeroStateTranslation = this.type === WordGraphType.FILLER ? 'filler-words-missing' : 'hedging-words-missing';
		}

		this.titleTranslation = this.type === WordGraphType.FILLER ? 'filler-words' : 'hedging-words';
		this.normalTranslation = this.type === WordGraphType.FILLER ? 'filler-normal' : 'hedging-normal';
	}

	public bestContrast (wordColor: string) {
		const color = ContrastColor(wordColor);
		return color.contrast(ContrastColor('#ffffff')) > color.contrast(ContrastColor('#333333')) ? '#ffffff' : '#333333';
	}

	public buildSummary (wordTypeTotal: number) {
		const translation: string = this.type === WordGraphType.FILLER ? 'filler-summary' : 'hedging-summary';
		const percentage: number = this.analytic ? floor((wordTypeTotal / this.analytic.words_total) * 100) : 0;
		return this.translate.instant(translation, {
			total: wordTypeTotal,
			percent: percentage
		});
	}

	public buildData () {
		const minGroups = 10;
		const maxGroups = 25;
		const numGroups = Math.min(maxGroups, Math.max(minGroups, Math.ceil(this.media.duration / 60000)));
		this.intervalDuration = this.media.duration / numGroups;

		this.seriesData = [];
		const wordTypeWords: WordCount[] = [];
		let colorCount = 0;
		const instances = this.type === WordGraphType.FILLER
			? this.analytic.filler_instances
			: this.analytic.hedging_instances;
		Object.keys(instances).forEach((key: string) => {
			const color: Color = colors[colorCount];
			const colorHex = '#' + color.hex;
			colorCount++;
			wordTypeWords.push({
				word: key,
				count: instances[key].length,
				color: colorHex
			});
			instances[key].forEach((time: string) => {
				this.seriesData.push({
					x: Math.floor(parseInt(time, 10) / this.intervalDuration) * this.intervalDuration,
					y: 1,
					actualTime: parseInt(time, 10),
					marker: {enabled: true, fillColor: colorHex},
					text: key
				});
			});
		});
		this.wordTypeWords = wordTypeWords;
		this.seriesData.sort((a, b) => {
			return a.actualTime - b.actualTime;
		});
	}

	public setOptions (wordTypeTotal: number) {
		const descriptionTranslation: string = this.type === WordGraphType.FILLER
			? 'filler-word-graph-description'
			: 'hedging-word-graph-description';
		const graphName: string = this.type === WordGraphType.FILLER ? 'filler-words' : 'hedging-words';
		this.chartOptions = {
			accessibility: {
				enabled: !this.shouldTease
			},
			chart: {
				type: 'scatter',
				plotBackgroundColor: '#F7F7F7'
			},
			title: {
				text: ''
			},
			xAxis: {
				minPadding: 0.025,
				maxPadding: 0.025,
				startOnTick: false,
				lineWidth: 0,
				tickWidth: 0,
				labels: {
					overflow: 'justify',
					// eslint-disable-next-line object-shorthand
					formatter: function () {
						const timePipe = new FormatTimePipe();
						return timePipe.transform(Number(this.value));
					}
				},
				softMin: 0,
				max: this.media.duration,
				minTickInterval: this.intervalDuration
			},
			yAxis: {
				minPadding: 0.15,
				maxPadding: 0.15,
				endOnTick: false,
				gridLineWidth: 0,
				title: {
					text: null
				},
				labels: {
					enabled: false
				},
				softMax: 9
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				scatter: {
					stacking: 'normal',
					accessibility: {
						description: this.translate.instant(descriptionTranslation, {total: wordTypeTotal})
					},
					point: {
						events: {
							click: (e: any) => {
								this.playerSync.seek(e.point.actualTime);
							}
						}
					}
				}
			},
			tooltip: {
				outside: true,
				headerFormat: '',
				formatter: (a) => {
					const point = a.chart.hoverPoint as any;
					const timePipe = new FormatTimePipe();
					let val = timePipe.transform(point.actualTime);
					val += `<br/><b>${this.translate.instant('common_word')}:</b> ${point.text}`;
					return val;
				}
			},
			series: [{
				type: 'scatter',
				name: this.translate.instant(graphName),
				data: this.seriesData
			}],
			credits: {
				enabled: false
			}
		};
	}
}
