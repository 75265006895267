/* @ngInject */
export const debounceFactory = ($timeout: ng.ITimeoutService, $q: ng.IQService): ng.IDebounceService => {
	return (fn, delay = 0) => {
		let timeoutPromise: ng.IPromise<void>;
		return function (...args) {
			return $q((resolve) => {
				const debouncedFn = () => {
					resolve(fn.apply(this, args));
				};
				$timeout.cancel(timeoutPromise);
				timeoutPromise = $timeout(debouncedFn, delay);
			});
		};
	};
};
