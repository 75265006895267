import * as angular from 'angular';
import { noop } from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';

interface MarkerSetEditorModalScope extends ng.IScope, ng.ui.bootstrap.IModalScope {
	options: any;
	markerSet: any;
}

export class MarkerSetEditorModalController implements ng.IOnInit {
	public markerSet: any;
	public isNew: boolean;
	public editing: boolean;
	public copying: boolean;
	public tagEditorForm: ng.IFormController;
	private eventSubscription: any;

	/* @ngInject */
	constructor (
		private $document: ng.IDocumentService,
		private $scope: MarkerSetEditorModalScope,
		private TagSetModel,
		private confirmModal,
		private eventService: EventService
	) {}

	public $onInit () {
		if (!angular.isObject(this.$scope.options)) {
			throw new Error('MarkerSetEditorModalController::must supply options');
		}

		if (!angular.isObject(this.$scope.markerSet)) {
			throw new Error('MarkerSetEditorModalController::must supply markerSet');
		}

		// We need to listen for color changes to tags to know to save
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.TAG_EDITOR_TAG_UPDATED))
			.subscribe(() => this.tagEditorForm.$setDirty());

		this.markerSet = angular.copy(this.$scope.markerSet);
		this.isNew = !!this.markerSet.id;
		this.editing = this.$scope.options.editing;
		this.copying = this.$scope.options.copying;
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	public cancelChanges (form) {
		if (!form.$pristine) {
			this.confirmModal.open({
				size: 'sm',
				modalData: {
					title: 'modal-marker-editor_discard-title',
					message: 'modal-marker-editor_discard-message',
					yes: 'common_return',
					no: 'modal-marker-editor_discard-cancel'
				}
			}).result.then(noop)
				.catch(() => {
					this.$scope.$dismiss();
				});
		} else {
			this.$scope.$dismiss();
		}
	}

	public closeModal (markerSet, form) {
		if (form.$invalid) {
			const invalid = this.$document[0].querySelector(':not(form):not(ng-form).ng-invalid') as HTMLElement;
			invalid.focus();
			return;
		}

		// if copying, the form should not be dismissed if a user submits without making changes
		// normally this should be done in $onInit(), but we need to wait for the form to be initialized
		if (this.copying) {
			this.tagEditorForm.$setDirty();
		}

		// if not editing or no changes made then just dismiss
		if (!this.editing || form.$pristine) {
			this.$scope.$dismiss();
			return;
		}

		if (!!markerSet.id) {
			this.TagSetModel.update(markerSet);
			this.$scope.$close(markerSet);
		} else {
			this.TagSetModel.save(markerSet).$promise.then((tagSet) => {
				this.$scope.$close(tagSet);
			});
		}
	};
}
