import { DataSource } from '@angular/cdk/collections';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

export abstract class BaseDataSource<T, ParamsType> extends DataSource<T> {
	public currentPage: number;
	public lastPage: number;
	public total: number;
	public results$: Observable<T[]>;

	protected params$: ParamsType | any;
	protected onError$$ = new Subject<any>();
	protected reload$$ = new BehaviorSubject<void>(void(0));
	protected loading$$ = new BehaviorSubject<boolean>(true);

	public onError$ = this.onError$$.asObservable();
	public loading$ = this.loading$$.asObservable();

	public reload (): void {
		this.reload$$.next(void(0));
	}

	public setLoading (value = true): void {
		this.loading$$.next(value);
	}
}
