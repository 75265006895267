import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as angulartics from 'angulartics';

import { userModel } from '../../models/user';
import { mediaModel } from '../../models/media';
import { sessionModel } from '../../models/session';
import { activityModel } from '../../models/activity';
import { groupDepModel } from '../../models/group-dep';
import { sessionEditorModal } from './modal.factory';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import { helpUrlsModule } from 'go-modules/help-urls';
import { featureFlagModule } from '../../feature-flag/index';
import { featureSupportModule } from 'go-modules/feature-support';
import { ngxGoToastModule } from 'ngx/go-modules/src/components/go-toast';

import './modal.less';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
import '../../session-editor';

export const sessionEditorModalModule = angular.module('modal.session-editor', [
	goModalBootstrapModule,
	angularTranslate,
	uiTooltip,
	userModel,
	mediaModel,
	sessionModel,
	activityModel,
	groupDepModel,
	angulartics,
	helpUrlsModule,
	featureFlagModule,
	featureSupportModule,
	ngxGoToastModule,
	// TODO: use module strings directly until circular dependency is fixed
	'go.session-editor'
])
	.factory('sessionEditorModal', sessionEditorModal)
	.name;
