import * as angular from 'angular';
import * as autosize from 'autosize';

/* @ngInject */
export function labelInputTextareaAutosize () {
	if (!angular.isFunction(autosize)) {
		throw new Error('Global variable autosize does not exist');
	}

	return {
		restrict: 'A',
		require: ['^labelInput', 'ngModel'],
		link: (_scope, element, _attrs, controllers) => {
			const labelInputController = controllers[0];
			// When need to ensure that the input value
			// is set so that the text area can autosize
			// itself correctly. ngModelController value
			// is not ready yet at this point.
			element.val(labelInputController.value);
			autosize(element[0]);
		}
	};
}
