import { clientSettings } from '../common/client.settings';
import { AnnouncementSchema } from './announcement.schema';
import { AnnouncementTransformer } from './announcement.transformer';
import { AnnouncementType } from './announcement.type';

import * as angular from 'angular';

export class Announcement implements AnnouncementSchema {
	public content: string;
	public id: number;
	public name: string;
	public isDismissable: boolean;
	public startDisplayAt: Date;
	public stopDisplayAt: Date;
	public type: AnnouncementType;
	public maxViews: number;
	public html: ng.IAugmentedJQuery;

	constructor (announcementData: AnnouncementSchema) {
		angular.extend(this, AnnouncementTransformer.response(announcementData));
	}
}

/* @ngInject */
export function AnnouncementFactory (
	$http,
	announcementTransformer: AnnouncementTransformer
) {

	const resourceUrl = `${clientSettings.GoReactV2API}/announcements`;

	const query = () => {
		return $http.get(resourceUrl, {
			isArray: true,
			transformResponse: announcementTransformer.responseTransformers
		}).then((res) => {
			return res.data;
		});
	};

	const requests = {
		query
	};

	angular.extend(Announcement, requests);

	return Announcement;
}
