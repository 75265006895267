import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, defer, EMPTY, map, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { TranscriptionService } from 'ngx/go-modules/src/services/transcription/transcription.service';
import type { Media } from 'go-modules/services/attachment/media';
import {
	MediaTranscription,
	MediaTranscriptionAnalytics,
	TranscriptionStatus
} from 'ngx/go-modules/src/interfaces/media-transcription';
import { TranslatePipe } from '@ngx-translate/core';
import { Marker } from 'ngx/go-modules/src/interfaces/markers/marker';
import type { PlayerSync } from 'ngx/go-modules/src/interfaces/media/media';
import { Comment } from 'ngx/go-modules/src/interfaces/comments/comment';
import {
	Utterance
} from 'ngx/go-modules/src/components/feedback-session/transcript-viewer/transcript-viewer.component';
import { WordGraphType } from 'ngx/go-modules/src/enums/word-graph-type';
import { HttpErrorResponse } from '@angular/common/http';
import { EventService, GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import type { License } from 'go-modules/services/group/license';
import { TeaseWallConfig } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.config';
import {
	TEASE_WALL_BETA_REQUEST_REMEMBER_KEY,
	TEASE_WALL_PURCHASE_REMEMBER_KEY,
	TEASE_WALL_UPGRADE_REMEMBER_KEY
} from 'ngx/go-modules/src/directives/tease-wall/constants';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { SelfPayService } from 'ngx/go-modules/src/services/self-pay/self-pay.service';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { NgxLicenseUpgradeService } from 'ngx/go-modules/src/services/license/license-upgrade/license-upgrade.service';
import { MatSelectChange } from '@angular/material/select';
import type { Activity } from 'ngx/go-modules/src/interfaces/activity';

export interface MockAnalyticsParsedMediaTranscript extends MediaTranscription {
	// Appended @Input() overrides
	media: { duration: number },
	playerSync: null
}
export enum AnalyticItems {
	FILLER,
	PAUSE,
	PACE,
	HEDGING,
	FEEDBACK
}

interface TranscriptComplete {
	status: TranscriptionStatus.COMPLETED;
}
interface TranscriptIncomplete {
	status: TranscriptionStatus.PROCESSING | TranscriptionStatus.ERROR | TranscriptionStatus.QUEUED;
}

type ParsedMediaTranscript = TranscriptIncomplete | TranscriptComplete;

@Component({
	selector: 'session-analytics',
	template: require('./session-analytics.component.html'),
	styles: [require('./session-analytics.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TranslatePipe]
})
export class SessionAnalyticsComponent implements OnInit, OnDestroy {
	@Input() public activity: Activity;
	@Input() public media: Media;
	@Input() public comments: Comment[];
	@Input() public tagsList: Marker[];
	@Input() public playerSync: PlayerSync;
	@Input() public license: License;

	public readonly TranscriptStatus = TranscriptionStatus;
	public isLoading$ = new BehaviorSubject(true);
	public requestErrorOccurred$ = new BehaviorSubject(false);
	public parsedMediaTranscript$: Observable<ParsedMediaTranscript>;
	public selectedAnalytic$ = new BehaviorSubject<MediaTranscriptionAnalytics | null>(null);
	public selectedUtterances$: Observable<Utterance[]>;
	public analytics$ = new BehaviorSubject([]);
	public analyticItems = AnalyticItems;
	public expanded$$ = new BehaviorSubject<AnalyticItems>(null);
	public expanded$ = this.expanded$$.asObservable();
	public utterances: Utterance[] = [];
	public showBetaLabel = false;
	public readonly WordGraphType = WordGraphType;
	public currentAnalytic: MediaTranscriptionAnalytics;

	public shouldTease = false;
	public analyticsTeaseWallConfig: TeaseWallConfig = {
		useRealData: true,
		translationKey: '', // Determined when student/reviewer vs instructor
		rememberKey: TEASE_WALL_BETA_REQUEST_REMEMBER_KEY,
		select: ['mat-panel-description', '.graph'],
		showDelay: 150,
		insertAfterChildren: true
	};

	public environmentVarsService: EnvironmentVarsService;
	private destroyed$ = new Subject();

	constructor (
		public translatePipe: TranslatePipe,
		public featureFlagService: NgxFeatureFlagService,
		private transcriptionService: TranscriptionService,
		private cdr: ChangeDetectorRef,
		private eventService: EventService,
		private selfPayService: SelfPayService,
		private ngxGoToastService: NgxGoToastService,
		private ngxLicenseUpgradeService: NgxLicenseUpgradeService,
		@Inject(selectedServiceToken) private selectedService: SelectedService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public isExpanded (analyticItem: AnalyticItems) {
		return this.expanded$.pipe(map((item) => item === analyticItem));
	}

	public onExpanded (analyticItem: AnalyticItems) {
		if (analyticItem === this.expanded$$.getValue()) {
			this.expanded$$.next(null);
			this.cdr.detectChanges();
			return;
		}

		this.expanded$$.next(analyticItem);
		this.cdr.detectChanges();
	}

	public ngOnInit () {
		this.initRealData();
	}

	public initRealData () {
		this.eventService.listen(EVENT_NAMES.MEDIA_SYNC)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((event: GoEvent) => {
				if (this.media.media_id !== event.data.media_id) return;

				if (event.data.analyticsUpdated == null || event.data.analyticsUpdated === false) return;

				this.loadAndParseTranscription();
			});

		this.loadAndParseTranscription();

		this.selectedUtterances$ = this.selectedAnalytic$.pipe(
			map((selectedAnalytic) =>
				this.utterances.filter((utterance) => utterance.speaker === selectedAnalytic.speaker_label))
		);
	}

	public ngOnDestroy () {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	public handleCompletedTranscript (analytics: MediaTranscriptionAnalytics[]) {
		this.currentAnalytic = analytics[0];
		this.selectedAnalytic$.next(this.currentAnalytic);
		this.isLoading$.next(false);
		this.analytics$.next(analytics);
		return { status: TranscriptionStatus.COMPLETED };
	}

	public selectAnalytic (value: MatSelectChange) {
		this.selectedAnalytic$.next(value.value);
	}

	public loadAndParseTranscription () {
		if(!this.activity.has_response_media || this.media.isYoutube()) {
			this.utterances = [];
			this.setBetaLabel();
			this.isLoading$.next(false);
			this.expanded$$.next(AnalyticItems.FILLER);
			this.selectedAnalytic$.next(null);
			this.parsedMediaTranscript$ = of({
				status: this.activity.has_response_media ? TranscriptionStatus.COMPLETED : null
			});

			return;
		}

		this.requestErrorOccurred$.next(false);
		this.isLoading$.next(true);

		this.parsedMediaTranscript$ = this.transcriptionService.getTranscription(this.media, true).pipe(
			take(1),
			catchError((error: HttpErrorResponse) => {
				const { status } = error;

				if (status === 404) {
					this.shouldTease = !this.license?.salesforce_license.transcript_analytics_enabled;

					if (this.shouldTease) {
						this.initMockData();
					}
				}

				return of({ status });
			}),
			map((response: MediaTranscription | {status: number}) => {
				switch (response.status) {
					case TranscriptionStatus.PROCESSING:
					case TranscriptionStatus.QUEUED:
						this.isLoading$.next(false);
						return { status: response.status };
					case TranscriptionStatus.COMPLETED:
						const { analytics, utterances } = response;
						this.utterances = utterances;
						this.setBetaLabel();
						this.expanded$$.next(AnalyticItems.FILLER);
						return this.handleCompletedTranscript(analytics);
					case 404:
						this.expanded$$.next(AnalyticItems.FEEDBACK);
						this.isLoading$.next(false);
						return {status: null};
					default:
						this.isLoading$.next(false);
						// In the rare chance a status isn't provided, default to failed network state
						this.requestErrorOccurred$.next(true);
						return {status: TranscriptionStatus.ERROR};
				}
			})
		);
	}

	public initMockData () {
		const group = this.selectedService.getGroup();
		const isInstructorOrAbove = group.hasInstructorRole(true);

		if (isInstructorOrAbove) {
			this.analyticsTeaseWallConfig.secondaryLink = NgxLicenseUpgradeService.getLearnMoreLink();

			if (this.featureFlagService.isAvailable('LICENSE_UPGRADE_PURCHASE')) {
				if (!this.license) {
					// No license so assume course is on student pay and allow instructors to purchase
					this.analyticsTeaseWallConfig.translationKey = 'common_purchase';
					this.analyticsTeaseWallConfig.rememberKey = TEASE_WALL_PURCHASE_REMEMBER_KEY;
					this.analyticsTeaseWallConfig.promptAction =
						this.ngxLicenseUpgradeService.createTeaseWallPurchasePromptAction();
				} else {
					// License found. User can upgrade
					this.analyticsTeaseWallConfig.promptAction =
						this.ngxLicenseUpgradeService.createTeaseWallUpgradePromptAction(this.license);
					this.analyticsTeaseWallConfig.licenseId = this.license?.id;
					this.analyticsTeaseWallConfig.translationKey = 'common_upgrade';
					this.analyticsTeaseWallConfig.rememberKey = TEASE_WALL_UPGRADE_REMEMBER_KEY;
				}

				this.selectedService.selectedSubject
					.pipe(takeUntil(this.destroyed$))
					.subscribe((selected) => {
						if (!selected.license) return;
						if (selected.license.salesforce_license.transcript_analytics_enabled) {
							// License got upgraded
							this.shouldTease = false;
							this.parsedMediaTranscript$ = of({status: TranscriptionStatus.ERROR});
							this.expanded$$.next(AnalyticItems.FEEDBACK);
						} else {
							// License updated and can be upgraded
							this.shouldTease = true;
							this.analyticsTeaseWallConfig = {
								...this.analyticsTeaseWallConfig,
								promptAction:
									this.ngxLicenseUpgradeService.createTeaseWallUpgradePromptAction(selected.license),
								licenseId: selected.license.id,
								translationKey: 'common_upgrade',
								rememberKey: TEASE_WALL_UPGRADE_REMEMBER_KEY
							};
						}
					});
			} else {
				this.analyticsTeaseWallConfig.translationKey = 'tease-wall_request-button';
				this.analyticsTeaseWallConfig.promptAction = this.requestBetaAction();
			}
		} else {
			this.analyticsTeaseWallConfig.promptAction = null;

			if (this.featureFlagService.isAvailable('LICENSE_UPGRADE_PURCHASE')) {
				this.analyticsTeaseWallConfig.translationKey = 'tease-wall_contact-your-instructor-upgraded-text';
				this.analyticsTeaseWallConfig.rememberKey = TEASE_WALL_UPGRADE_REMEMBER_KEY;
			} else {
				this.analyticsTeaseWallConfig.translationKey = 'tease-wall_contact-your-instructor-text';
			}
		}

		this.analyticsTeaseWallConfig.useRealData = false;

		this.parsedMediaTranscript$ = defer(() =>
			import(/* webpackChunkName: "MockSessionAnalyticsData" */ 'ngx/go-modules/src/components/feedback-session/tease-wall-mock/analytics-mock.json')
				.then((data: any) => data as MockAnalyticsParsedMediaTranscript)
		).pipe(
			map((data: MockAnalyticsParsedMediaTranscript) => {
				const { analytics, utterances, playerSync } = data;
				this.utterances = utterances;
				this.setBetaLabel();
				this.expanded$$.next(AnalyticItems.FILLER);
				this.playerSync = playerSync;
				return this.handleCompletedTranscript(analytics);
			})
		);

		this.selectedUtterances$ = this.selectedAnalytic$.pipe(
			map((selectedAnalytic) =>
				this.utterances.filter((utterance) => utterance.speaker === selectedAnalytic.speaker_label))
		);
	}

	public setBetaLabel () {
		this.showBetaLabel = !this.featureFlagService.isAvailable('LICENSE_UPGRADE_PURCHASE');
	}

	public maySeeTranscriptionAnalytics (): boolean {
		if (this.featureFlagService.isAvailable('TRANSCRIPTION')) {
			if (this.license?.salesforce_license.may_tease) {
				return true;
			}
			return this.license?.salesforce_license.transcript_analytics_enabled;
		}
		return false;
	}

	private requestBetaAction () {
		const group = this.selectedService.getGroup();

		if (!group) return null;

		return this.selfPayService.requestBeta(group.group_id).pipe(
			map(()=> {
				// On success, need to map response to translation key for tease wall
				return 'tease-wall_request-sent-message';
			}),
			catchError(() => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'tease-wall_request-failed-message'
				});
				return EMPTY;
			})
		);
	}
}
