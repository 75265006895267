import * as angular from 'angular';
import { isObject } from 'lodash';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { TagEditorController as controller } from './tag-editor.controller';

/* @ngInject */
export function tagEditorDirective (
	$q,
	$templateCache,
	$compile,
	tagEditor,
	TagSetModel,
	TagModel,
	eventService: EventService
) {
	return {
		restrict: 'EA',
		scope: {
			options: '=tagEditor',
			tagSet: '=ngModel'
		},
		controller,
		controllerAs: 'tagEditor',
		link (scope, element) {
			// loading flag
			scope.loading = false;

			// error message
			scope.error = '';

			/**
			 *  Options tag set change event handler
			 */
			scope.$watch('tagSet', function (tagSet) {
				scope.error = false;

				if (isObject(tagSet)) {
					const promise = resolve(tagSet);

					if (!scope.options || !scope.options.hideLoading) {
						scope.loading = true;
					}

					promise.catch(function (response) {
						scope.error = response.data.message;
					});

					promise.finally(function () {
						scope.loading = false;
					});
				}
			});

			/**
			 * Options mode change event handler
			 */
			scope.$watch('options.editing', function (editing) {

				let templateUrl;

				if (editing) {
					templateUrl = tagEditor.defaults.editTemplateUrl;
				} else {
					templateUrl = tagEditor.defaults.viewTemplateUrl;
				}

				// switch template
				const html = $templateCache.get(templateUrl);
				element.empty();
				element.append($compile(html)(scope));

				eventService.broadcast(EVENT_NAMES.TAG_EDITOR_MODE_CHANGE, editing);
			});

			/**
			 * Ensure that complete tag set is loaded
			 */
			function resolve (tagSet) {
				const result = $q.defer();

				const deferred = {
					tagSet: $q.defer(),
					tags: $q.defer()
				};

				// check to see if we have a promise
				if (isObject(tagSet.$promise) && !tagSet.$resolved) {
					tagSet.$promise.then(function () {
						deferred.tagSet.resolve(tagSet);
					}).catch(function (response) {
						deferred.tagSet.reject(response);
					});
				} else if (tagSet.id && !tagSet.title) {
					// if we have an id but no title, we need to load the tag set
					TagSetModel.get({
						id: tagSet.id
					}, function (data) {
						deferred.tagSet.resolve(angular.extend(tagSet, data));
					}, function (response) {
						deferred.tagSet.reject(response);
					});
				} else {
					deferred.tagSet.resolve(tagSet);
				}

				// when tag set is resolved, get tags
				deferred.tagSet.promise.then(function (tagSetResult) {

					// if tag set doesn't have an array of tags, fetch them
					if (!Array.isArray(tagSetResult.tags)) {
						tagSetResult.tags = [];
						deferred.tags.resolve(tagSetResult.tags);
					} else {
						deferred.tags.resolve(tagSetResult.tags);
					}
				});

				// promise of all promises
				const promise = $q.all({
					tagSet: deferred.tagSet.promise,
					tags: deferred.tags.promise
				});

				// success
				promise.then(function (data) {
					tagSet.tags = processTags(data.tags); // process the tags
					result.resolve(tagSet);
				});

				// error
				promise.catch(function (data) {
					result.reject(data);
				});

				return result.promise;
			}

			/**
			 * Process a set of tags
			 *
			 * @param tags
			 */
			function processTags (tags) {
				if (Array.isArray(tags)) {

					// make sure sort index is int
					angular.forEach(tags, function (tag, index) {
						// make sure tag is wrapped with tag model
						tags[index] = new TagModel(tag);

						tag.sort_index = parseInt(tag.sort_index, 10);
					});

					// sort tags by sort index
					tags.sort(function (a, b) {
						return a.sort_index - b.sort_index;
					});
				}

				return tags;
			}

		}
	};
}
