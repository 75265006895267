import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { common } from '../common';
import { mediaModel } from '../media';
import { time } from '../../time';

import { SyncEvent } from './sync-event.factory';
import { syncEventServiceTransformer } from './sync-event-transformer.service';
import { syncEventServiceInterceptor } from './sync-event-interceptor.service';

export const syncEventModel = angular.module('go.models.syncEvent', [
	angularResource,
	common,
	mediaModel,
	time
])
	.service('syncEventServiceInterceptor', syncEventServiceInterceptor)
	.service('syncEventServiceTransformer', syncEventServiceTransformer)
	.factory('SyncEvent', SyncEvent)
	.name;
