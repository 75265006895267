import template from './course-payment-wall.component.html';
import {CoursePaymentWallController} from './course-payment-wall.controller';

export const CoursePaymentWallComponent = {
	template,
	controller: CoursePaymentWallController,
	bindings: {
		user: '<',
		course: '<',
		lti: '<',
		onCredit: '&',
		onPay: '&'
	}
};
