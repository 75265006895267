import { Roles } from 'ngx/go-modules/src/enums/roles';

export class UserRoleLocalizationUtil {
	public static localize (role: Roles) {
		switch (role) {
			case Roles.OWNER:
				return 'models-group_factory-role-owner';
			case Roles.ADMIN:
				return 'models-group_factory-role-admin';
			case Roles.INSTRUCTOR:
				return 'models-group_factory-role-instructor';
			case Roles.REVIEWER:
				return 'models-group_factory-role-reviewer';
			case Roles.PRESENTER:
				return 'models-group_factory-role-presenter';
			default:
				return role;
		}
	}
}
