import { GoBannerService } from 'go-modules/go-banner/go-banner.service';
import { InternetStatus, Events } from 'go-modules/internet-status';
import { AnnouncementType } from 'go-modules/models/announcement/announcement.type';
import { AnnouncementSchema } from 'go-modules/models/announcement/announcement.schema';

export class OfflineMode {
	public status: InternetStatus;
	private banner: AnnouncementSchema;

	/* @ngInject */
	constructor (
		private goBannerService: GoBannerService,
		private $rootScope: ng.IRootScopeService,
		private translateFilter: ng.translate.ITranslateFilter,
		private $translate: ng.translate.ITranslateService
	) {
		this.banner = {
			isDismissable: false,
			name: 'offline-mode',
			type: AnnouncementType.WARNING
		} as AnnouncementSchema;

		this.$translate.onReady(() => {
			// We create the banner here, because if we do it any sooner,
			// translations are not loaded and we don't get the right
			// translation string
			this.banner.content = this.translateFilter('internet-status_offline-message');
		});
	}

	public attachOfflineBannerWatcher () {
		this.status = new InternetStatus();
		this.status.on(Events.OFFLINE, this.onOffline);
		this.status.on(Events.ONLINE, this.onOnline);
	}

	public detachOfflineBannerWatcher () {
		this.goBannerService.destroy();
		this.status.off(Events.OFFLINE, this.onOffline);
		this.status.off(Events.ONLINE, this.onOnline);
		this.status.destroy();
		this.status = null;
	}

	public onOffline = () => {
		this.goBannerService.addBanner(this.banner);
		this.$rootScope.$apply();
	};

	public onOnline = () => {
		this.goBannerService.removeBanner(this.banner);
		this.$rootScope.$apply();
	};
}
