import * as angular from 'angular';
import type { GrabData } from 'go-modules/aria-decorator/aria-ng-sortable.decorator';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

/* @ngInject */
export function RubricScaleController ($scope, $element, $timeout, $translate, eventService: EventService) {
	const MAX_OPTIONS = 10;

	const vm = this;

	vm.$onInit = () => {
		$scope.sortableOptions = {
			group: {
				pull: false
			},
			sort: true,
			scroll: true,
			ghostClass: 'rb-element-placeholder',
			handle: `.${$scope.element.type}-option-handle`,
			draggable: '.rb-scale-option-draggable',
			speakerId: 'rubric-ng-sortable-speaker',
			onUpdate () {
				eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);
			}
		};

		updateShowMore();
	};

	function updateShowMore () {
		$scope.showMore = $scope.element.options.length < MAX_OPTIONS;
	}

	$scope.getTotal = function () {
		let max = 0;
		angular.forEach($scope.element.options, function (option) {
			if (option.value > max) {
				max = Number(option.value);
			}
		});
		return max;
	};

	$scope.select = function (item, option) {
		// because scales don't always have points,
		// we have to remember the id of the option selected
		if (parseInt(item.option, 10) === parseInt(option.id, 10)) {
			item.option = null;
			item.points = null;
		} else {
			item.option = option.id;
			item.points = option.value;
		}

		// emit change event
		eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);
	};

	$scope.addOption = function () {

		const option = {
			id: new Date().getTime(),
			label: '',
			desc: '',
			value: ''
		};

		$scope.element.options.push(option);

		updateShowMore();
		eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);

		$timeout(() => {
			const handles = $element[0].querySelectorAll('.rb-scale-option-list .option-handle');
			const handle = handles[handles.length - 1];
			const ngSortable = angular.element($element[0].querySelector('[ng-sortable]'));
			const additionalText = $translate.instant('rubric-element-scale_added-aria-live-text', {type: $scope.element.type});

			ngSortable.triggerHandler('grabHandle', {handle, speakerAdditionalText: {prepend: additionalText}} as GrabData);
		});
	};

	$scope.removeOption = function (option) {
		angular.forEach($scope.element.options, function (item, key) {
			if (item === option) {
				$scope.element.options.splice(key, 1);
			}
		});
		updateShowMore();

		eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);
	};
}
