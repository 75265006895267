export interface UserNotification {
	type: UserNotificationType;
	level: UserNotificationLevel;
	eventName?: UserNotificationEvent;
	message: string;
	userId: number;
	data?: any;
}

export enum UserNotificationType {
	MODAL = 'modal'
}

export enum UserNotificationLevel {
	INFORMATIONAL = 'informational',
	NOTICE = 'notice',
	WARNING = 'warning'
}

export enum UserNotificationEvent {
	VIRUS_FOUND = 'virus-found'
}

import { VirusFoundEventHandler } from './virus-found/handler';
import { GenericEventHandler } from './generic/handler';
import { IModalInstanceService } from 'angular-ui-bootstrap';

const handlerMapping = {
	[UserNotificationEvent.VIRUS_FOUND]: VirusFoundEventHandler
};

export class UserNotificationHandler {

	/* @ngInject */
	constructor (
		private goModalBootstrap,
		private translateFilter
	) {}

	public getHandler (eventName: string = null): GenericEventHandler {
		const HandlerClass = handlerMapping[eventName] || GenericEventHandler;
		return new HandlerClass(this);
	}

	public openModal (modalData, eventTemplate: string = null): IModalInstanceService {
		const template = eventTemplate || this.generateModalTemplate(modalData);
		return this.goModalBootstrap.open({
			template,
			modalData,
			controller: () => {},
			windowClass: 'user-notification-modal',
			keyboard: false,
			backdrop: 'static',
			controllerAs: '$ctrl',
			bindToController: true,
			goreact: true
		});
	}

	private generateModalTemplate (modalData): string {
		let template = '';

		// Set header
		if (modalData.title) {
			template += `
				<div class="goreact-modal-header">
					<h4 class="modal-title">{{$ctrl.title}}</h4>
				</div>
			`;
		}

		// Add body
		template += `
			<div class="goreact-modal-body">
				<p>{{$ctrl.message}}</p>
			</div>
		`;

		const btnClass = modalData.btnClass || 'primary-btn';
		modalData.btnTitle = modalData.btnTitle || this.translateFilter('common_close');

		// Add footer
		template += `
			<div class="goreact-modal-footer">
				<button
					type="button"
					class="${btnClass}"
					ng-click="$ctrl.$close()"
					aria-label="${modalData.btnTitle}">
					${modalData.btnTitle}
				</button>
			</div>
		`;

		return template;
	}
}
