// This never got merged:
// https://github.com/angular/angular.js/pull/13996
// But we need it. Rather than forking angular, let's decorate it in

/* @ngInject */
const ariaNgClickDecoratorFn = (
	$delegate: ng.IDirective[],
	$aria: ng.aria.IAriaService,
	$parse: ng.IParseService) => {
	// Find the aria ngClick
	const $ariaNgClick = $delegate.find((delegate) => delegate.compile.toString().toUpperCase().includes('BINDROLEFORCLICK'));

	// Monkey patch it
	$ariaNgClick.compile = ((oldCompile) => {
		return function newCompile (tElem: ng.IAugmentedJQuery, tAttrs: ng.IAttributes) {
			// Get the ngClick function
			const fn = $parse(tAttrs.ngClick);

			// Get the old linkingFn
			const oldLinkingFn = oldCompile.call(this, tElem, tAttrs);

			// If someone has added 'ng-attr-disable', then we abort
			if (oldLinkingFn == null) {
				return;
			}

			// Now monkey patch that
			return function newLinkingFn (scope: ng.IScope, elem: ng.IAugmentedJQuery, attr: ng.IAttributes) {
				oldLinkingFn.call(this, scope, elem, attr);

				// If this is a listbox/combobox dropdown, then we don't want tabindex
				if (elem[0].matches('[role="listbox"] *') || elem[0].matches('[role="combobox"] *')) {

					// only remove tabindex if not chips
					if(!elem[0].classList.contains('md-chip-remove')) {
						attr.$set('tabindex', null);
					}
				}

				// And call the code in the PR (slightly changed for modern)
				if (elem[0].nodeName === 'A') {
					if ($aria.config('bindRoleForClick') && !attr.href && !attr.xlinkHref && !attr.role) {
						elem.attr('role', 'link');
					}
					if ($aria.config('bindKeydown')) {
						elem.on('keydown', (event: JQuery.KeyDownEvent) => {
							// We actually want a falsy check here. Href empty string shouldn't count
							const hasHref = attr.href || attr.xlinkHref;
							if ((event.key === 'Enter' || event.key === ' ') && !hasHref && !attr.ngKeypress) {
								scope.$apply(() => {
									fn(scope, { $event: event });
								});
							}
						});
					}
				}
			};
		};
	})($ariaNgClick.compile);
	return $delegate;
};

export const ariaNgClickDecorator: [string, ng.Injectable<Function>] = [
	'ngClickDirective',
	ariaNgClickDecoratorFn
];
