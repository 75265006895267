import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
	imports: [
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatMenuModule,
		MatSnackBarModule,
		OverlayModule,
		MatTableModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatToolbarModule,
		MatSidenavModule,
		MatListModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatRadioModule,
		// MatAutocompleteModule needs to be before MatChipsModule
		// or else some issues may arise with keyboard navigation
		MatAutocompleteModule,
		MatChipsModule
	],
	exports: [
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatMenuModule,
		MatSnackBarModule,
		OverlayModule,
		MatTableModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatToolbarModule,
		MatSidenavModule,
		MatListModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatRadioModule,
		MatAutocompleteModule,
		MatChipsModule
	],
	providers: [MatNativeDateModule]
})
export class GoMaterialModule {}
