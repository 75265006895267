import * as angular from 'angular';
import { GoLocalizeHelperService } from 'go-modules/translation-helper/go-localize-helper.service';

/* @ngInject */
export function datetimepickerDirective ($compile, $timeout, $document, goLocalizeHelper: GoLocalizeHelperService) {
	const datetimePickers = [];

	return {
		restrict: 'EA',
		require: '?ngModel',
		templateUrl: 'datetimepicker/datetimepicker.directive.html',
		scope: {
			date: '=ngModel',
			format: '@format',
			isOpen: '@isOpen',
			isReadonly: '<',
			ngChange: '&',
			minDate: '<',
			maxDate: '<'
		},
		compile (_tElement, tAttrs) {

			const tempid = tAttrs.id;

			// clear id
			tAttrs.$set('id', null);

			// set name attribute
			if (tAttrs.name) {
				tAttrs.$set('name', tAttrs.name);
			}

			return (scope, _element, attrs, ngModelCtrl: ng.INgModelController) => {
				const id = tempid;
				datetimePickers.push(scope);

				// expose id to scope
				scope.id = id;

				//localize date and format, allow leading 0's in dates or not
				scope.format = goLocalizeHelper.longDateFormat.replace('MM', 'M!').replace('DD', 'd!').replaceAll('Y', 'y');

				// Expose valid date events
				let initializedWatcher = false;
				scope.$watch('date', (newVal) => {
					if (!initializedWatcher) {
						// Nothing has happened yet
						initializedWatcher = true;
						return;
					}

					// Set date validity
					const isValid = angular.isDate(newVal) || newVal === null;
					ngModelCtrl.$setDirty();
					ngModelCtrl.$setTouched();
					ngModelCtrl.$setValidity('date', isValid);
				});

				scope.$watch('minDate', (val) => {
					scope.datePickerOptions.minDate = val;
				});

				scope.$watch('maxDate', (val) => {
					scope.datePickerOptions.maxDate = val;
				});

				let timePicker = null;
				if (!attrs.hasOwnProperty('excludeTimepicker')) {

					// time picker template
					timePicker = angular.element('<datetimepicker-timepicker></datetimepicker-timepicker>');
					timePicker.attr('ng-model', 'date');
					timePicker.attr('options', 'timePickerOptions');

					scope.timePickerOptions = {
						hourStep: 1,
						minuteStep: 1,
						showMeridian: true
					};
				}

				scope.datePickerOptions = {
					startingDay: 0,
					showWeeks: false
				};

				scope.onChanged = () => {
					if (scope.ngChange) scope.ngChange({date: scope.date});
				};

				let compiledTimePicker;
				scope.$watch('isOpen', (value) => {
					scope.datePickerOptions = {
						minDate: scope.minDate,
						maxDate: scope.maxDate,
						showWeeks: false
					};
					// ensure that all datetime pickers are closed
					if (value) {
						angular.forEach(datetimePickers, (picker) => {
							if (picker.id !== id) {
								picker.isOpen = false;
							}
						});
					}

					if (!attrs.hasOwnProperty('excludeTimepicker')) {
						// add / remove timepicker from datepicker
						$timeout(() => {
							const inputElement = $document[0].getElementById(id);
							if (!inputElement) return;
							const datepicker = angular.element(inputElement.nextElementSibling);
							if (value) {
								compiledTimePicker = $compile(timePicker)(scope);

								datepicker[0].children[0].insertBefore(
									compiledTimePicker[0],
									datepicker[0].children[0].children[1]
								);

							} else if (compiledTimePicker) {
								compiledTimePicker.remove();
							}
						});
					}
				});

				scope.ariaLabelledby = attrs.labelledBy;
				scope.ariaDescribedby = attrs.describedBy;

				attrs.$observe('disabled', (value) => {
					scope.disabled = !!value;
				});

				scope.$on('$destroy', () => {
					const index = datetimePickers.indexOf(scope);
					if (index !== -1) {
						datetimePickers.splice(index, 1);
					}
				});
			};
		}
	};
}
