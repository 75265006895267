import { RichInstructionsController as controller } from './rich-instructions.controller';
import template from './rich-instructions.component.html';

/* @ngInject */
export const richInstructionsComponent = {
	template,
	controller,
	bindings: {
		instruction: '<'
	}
} as ng.IComponentOptions;
