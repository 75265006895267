import { Component } from '@angular/core';

@Component({
	selector: 'analytic-graph-zero-state',
	template: require('./analytic-graph-zero-state.component.html'),
	styles: [require('./analytic-graph-zero-state.component.scss')]
})
export class AnalyticGraphZeroStateComponent {

}
