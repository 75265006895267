import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';

import { avalaraModelModule } from 'go-modules/models/avalara';

import { lessThanDirective } from './less-than.directive';
import { greaterThanDirective } from './greater-than.directive';
import { formSubmitDirective } from './form.directive';
import { dateRangeDirective } from './date-range.directive';
import { dateDiffExceedsLimit } from './date-diff.constant';
import { blurFocusDirective } from './blur-focus.directive';
import { invalidEmailsDirective } from './invalid-emails/invalid-emails.directive';
import './style.less';
import { authServiceModule } from 'go-modules/services/auth';

export const formValidationModule = angular.module('formValidation', [
	uiTooltip,
	angularTranslate,
	authServiceModule,
	avalaraModelModule
])
	.directive('input', blurFocusDirective)
	.directive('select', blurFocusDirective)
	.constant('dateDiffExceedsLimit', dateDiffExceedsLimit)
	.directive('dateRange', dateRangeDirective)
	.directive('form', formSubmitDirective)
	.directive('greaterThan', greaterThanDirective)
	.directive('lessThan', lessThanDirective)
	.directive('invalidEmails', invalidEmailsDirective)
	.name;
