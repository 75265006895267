import { Utterance } from 'ngx/go-modules/src/components/feedback-session/transcript-viewer/transcript-viewer.component';

export enum TranscriptionStatus {
	PROCESSING = 'processing',
	COMPLETED = 'completed',
	ERROR = 'error',
	QUEUED = 'queued'
}

export interface WordCriteria {
	value: string;
	caseSensitive: boolean;
	punctuationSensitive: boolean;
}

export interface MediaTranscriptionAnalyticsConfig {
	hedging: WordCriteria[];
	filler: WordCriteria[];
}

export interface MediaTranscriptionAnalytics {
	id: number;
	media_transcription_id: number;
	speaker_label: string;
	words_total: number;
	duration_milliseconds: number;
	filler_instances: any;
	filler_total: number;
	hedging_instances: any;
	hedging_total: number;
	pause_instances: any;
	pause_total: number;
	pause_lengthy_total: number;
}

export interface MediaTranscription {
	id: number;
	media_id: number;
	status: TranscriptionStatus;
	utterances: Utterance[] | null;
	parsed_utterances: Utterance[] | null;
	analytics?: MediaTranscriptionAnalytics[];
	analytics_config?: MediaTranscriptionAnalyticsConfig;
}
