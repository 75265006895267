import { noop } from 'angular';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { VideoShareModal } from 'go-modules/modals/video-share/modal.service';
import { VideoDetailsDialogComponent } from 'ngx/go-modules/src/components/dialogs/video-details-dialog/video-details-dialog.component';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';

export enum TooltipAndAriaLabel {
	tooltip = 'tooltip',
	ariaLabel = 'ariaLabel'
}

export class FeedbackSessionHeaderController {

	public readonly;
	public doneButtonText: string;
	public options: any;
	public doneAnalyticsActions: any;
	public mainController: any;
	public environmentVarsService: EnvironmentVarsService;
	public tooltipAndAriaLabel = TooltipAndAriaLabel;
	public scoreAriaLabel: string;

	/* @ngInject */
	constructor (
		private sessionEditor,
		private $analytics,
		private MediaModel,
		private feedbackSession,
		private videoShareModal: VideoShareModal,
		private $translate: ng.translate.ITranslateService,
		private featureFlag: FeatureFlag,
		private ngxGoModalService: GoModalService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	/**
	 * Handle controller init
	 */
	public $onInit () {
		this.readonly = this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY);
		this.doneButtonText = this.getDoneButtonText();
		this.options = { hideLoading: true };
		this.doneAnalyticsActions = {
			common_leave: 'leave',
			common_finish: 'finish',
			common_back: 'back',
			common_cancel: 'cancel'
		};
	}

	/**
	 * Whether download link should be shown
	 *
	 * @returns {boolean}
	 */
	public showDownloadLink () {
		if (this.MediaModel.isMediaPlayableOnClient(this.mainController.session.download_link) &&
			this.mainController.activity.has_response_media &&
			!this.feedbackSession.isRecording()) {
			if (this.mainController.userGroup.hasInstructorRole(true)) {
				return true;
			}
			if (!this.mainController.userGroup.block_presenter_downloads &&
					!this.mainController.activity.has_single_recording_attempt) {
				return true;
			}
		}
		return false;
	}

	public download () {
		this.MediaModel.download(this.mainController.session.media.media_id);
	}

	public shouldShowShareButton (): boolean {
		const orgSettings = this.mainController.orgSettings;

		if (orgSettings?.allow_video_share
			&& !this.feedbackSession.isRecording()
			&& this.mainController.session.posted_at
			&& !this.mainController.session.archived_at) {
			return this.mainController.userGroup.hasInstructorRole(true) ||
				(this.mainController.activity.isVideoShareEnabled() &&
				this.mainController.session.isOwner(this.mainController.user));
		}
		return false;
	}

	public shareSession () {
		this.videoShareModal.shareSession({
			modalData: {
				orgSettings: this.mainController.orgSettings,
				sessionId: this.mainController.session.session_id
			}
		}).catch(noop);
	}

	/**
	 * Whether presenter names should be shown
	 *
	 * @returns {boolean}
	 */
	public showPresenterNames () {
		return this.mainController.session.presenters.length > 0;
	}

	/**
	 * Whether video details should be shown
	 *
	 * @returns {boolean}
	 */
	public showVideoDetails () {
		return this.mainController.user.is_root_user &&
		!this.feedbackSession.isRecordMode();
	}

	/**
	 * Open the media details modal
	 *
	 * @param event
	 * @returns {*}
	 */
	public openMediaDetailsModal (event: Event) {
		event.preventDefault();
		event.stopPropagation();

		return this.ngxGoModalService.open(VideoDetailsDialogComponent, true, {
			data: {
				session: this.mainController.session
			}
		});
	}

	/**
	 * Add slide deck to session
	 */
	public addSlideDeck () {
		this.sessionEditor.addStimulus(
			this.mainController.session,
			this.mainController.activity,
			this.mainController.userGroup
		).result.then((sourceMedia) => {
			this.mainController.session.setSourceMedia(sourceMedia);
			this.mainController.session.save();
			this.$analytics.eventTrack('add-deck', {
				category: 'dashboard'
			});
			this.mainController.validateSourceMedia();
		});
	}

	/**
	 * Handles exit
	 */
	public exit () {
		this.mainController.requestExit();
	}

	public canSeeScore () {
		const group = this.mainController.userGroup;
		const activity = this.mainController.activity;

		return group.hasReviewerRole(true) ||
			this.mainController.session.isOwner(this.mainController.user) &&
			!activity.shouldHoldFeedback(group.hasPresenterRole());
	}

	public shouldDisplayScore () {
		return !this.feedbackSession.isRecordMode();
	}

	public mayEditScore () {
		if (this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		if (this.mainController.activity.isAutoScored()) {
			return false;
		}

		if (!this.mainController.activity.mayScore(this.mainController.userGroup)) {
			return false;
		}

		return true;
	}

	/**
	 * Get label for score display
	 *
	 * @param score
	 * @returns {string}
	 */
	 public getScoreLabel (score) {
		let label = '—';
		score = parseFloat(score);

		if (!isNaN(score)) {
			score = Math.round(score * 100) / 100 + ''; // round to 2 decimal places
			label = score;
			this.scoreAriaLabel = this.$translate.instant('feedback-session-feedback-display_points-assigned', { score });
		} else if (this.mayEditScore() && !this.mainController.session.isScoreAssigned()) {
			label = this.$translate.instant('feedback-session_controller-placeholder-pts');
			this.scoreAriaLabel = this.$translate.instant('feedback-session-feedback-display_no-points-assigned');
		}
		return label;
	}

	public getScoreTooltipAndAriaLabel (type: string) {
		let ariaLabel = '';
		let tooltip = '';
		const score = this.mainController.session.score;

		if (this.mainController.activity.isRubricEnabled()) {
			tooltip = this.$translate.instant('feedback-session-feedback-display_score-calculated');

			if (score === null) {
				ariaLabel = this.$translate.instant('feedback-session-feedback-display_no-rubric-no-score-aria-label');
			} else {
				ariaLabel = this.$translate.instant('feedback-session-feedback-display_no-rubric-has-score-aria-label', {
					score: Math.round(score * 100) / 100 + '',
					possible: this.mainController.activity.getTotalPoints()
				});
			}
		} else {
			if (score === null) {
				tooltip = this.$translate.instant('feedback-session-feedback-display_no-points-assigned');
				ariaLabel = this.$translate.instant('feedback-session-feedback-display_no-points-assigned');
			} else {
				const scorePossible = {
					score: Math.round(score * 100) / 100 + '',
					possible: this.mainController.activity.getTotalPoints()
				};

				tooltip = this.$translate.instant('feedback-session-feedback-display_points-possible', scorePossible);
				ariaLabel = this.$translate.instant('feedback-session-feedback-display_points-graded', scorePossible);
			}
		}

		return type === TooltipAndAriaLabel.tooltip ? tooltip : ariaLabel;
	}

	public shouldShowSpeedGrader () {
		const sessionList = this.mainController.options.speedGraderList;
		return sessionList && sessionList.length &&
			this.mainController.userGroup.hasReviewerRole(true) &&
			!this.feedbackSession.isRecordMode() &&
			this.feedbackSession.lastMediaDisplayMode !== this.feedbackSession.MediaDisplayMode.RECORD;
	};

	/**
	 * Done buton text
	 */
	private getDoneButtonText () {
		const displayMode = this.feedbackSession.options.mediaDisplayMode;
		const MediaDisplayMode = this.feedbackSession.MediaDisplayMode;
		const activity = this.mainController.activity;
		const session = this.mainController.session;
		const user = this.mainController.user;

		if (displayMode === MediaDisplayMode.CONVERSATION || displayMode ===  MediaDisplayMode.LIVE) {
			return 'common_leave';
		} else if (displayMode === MediaDisplayMode.PLAYBACK &&
			this.mainController.activity.isCommentOnlySingleAttempt() &&
			//the following function checks if the FeedbackCreator should be hidden, which will just
			//be on subsequent session views, we only want to show 'finish' if it is the first time
			!session.isLimitCommentOnlySingleAttempt(activity, user)){
			return 'common_finish';
		} else if (displayMode === MediaDisplayMode.PLAYBACK) {
			return 'common_back';
		} else {
			return 'common_cancel';
		}
	}
}
