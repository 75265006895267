import template from './view.component.html';
import { RecordViewController } from './view.controller';

export const recordViewComponent = {
	controller: RecordViewController,
	require: {
		mainController: '^feedbackSession',
		mediaDisplayController: '^mediaDisplay',
		singleMediaDisplayController: '?^singleMediaDisplay'
	},
	template
};
