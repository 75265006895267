import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { common } from '../common';
import { inviteFactory } from './invite.factory';
import { InviteTransformer } from './invite.transformer';

export const inviteModelModule = angular.module('go.models.invite', [
	angularResource,
	common
])
	.factory('inviteFactory', inviteFactory)
	.service('inviteTransformer', InviteTransformer)
	.name;
