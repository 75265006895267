import { GoSource, GoSourceConfig } from '../go-source';
import { GoScene } from '../../go-scene/go-scene';

export class ImageSource extends GoSource {
	public readonly image: HTMLImageElement = new Image();

	constructor (config: GoSourceConfig) {
		super(config);
	}

	public init (): Promise<void> {
		return new Promise((resolve, reject) => {
			this.image.onload = () => {
				this.setPosition({
					resourceWidth: this.resourceWidth || this.image.width,
					resourceHeight: this.resourceHeight || this.image.height
				});
				resolve();
			};
			this.image.onerror = () => reject();
			this.image.crossOrigin = 'Anonymous';
			this.image.src = this.resource;
		});
	}

	public async test () { return true; }

	public render (scene: GoScene) {
		this.setPosition({
			width: this.resourceWidth / scene.canvasEle.width * 100,
			height: this.resourceHeight / scene.canvasEle.height * 100
		});
		const { x, y, width, height } = scene.getDrawDimensions(this);
		scene.ctx.drawImage(this.image, x, y, width, height);
	}
};
