import template from './attachment.component.html';
import { AttachmentController } from './attachment.controller';

export default {
	bindings: {
		attachment: '=ngModel',
		onDelete: '&',
		onDeleted: '&',
		onDownload: '&',
		onEdit: '&',
		onEdited: '&',
		onPreview: '&',
		options: '<'
	},
	controller: AttachmentController,
	template
} as ng.IComponentOptions;
