import { UserNotification } from '../user-notifications.service';
import { GenericEventHandler } from '../generic/handler';
import { IModalInstanceService } from 'angular-ui-bootstrap';

export class VirusFoundEventHandler extends GenericEventHandler {
	protected handleModal (notification: UserNotification): IModalInstanceService {
		const modal = super.handleModal(notification);

		// Refresh page after modal closes
		modal.result.then(() => {
			window.location.reload();
		});

		return modal;
	}
}
