import * as angular from 'angular';
import { isUndefined } from 'lodash';

export function goListDirective () {
	return {
		restrict: 'A',
		require: 'ngModel',
		link (_scope, _element, attr, ctrl) {
			let regexStr = attr.goList;
			regexStr = regexStr.substring(1, regexStr.length - 1);

			const separator = new RegExp(regexStr);

			ctrl.$parsers.push(function (viewValue) {
				if (isUndefined(viewValue)) {
					return;
				}
				const list = [];

				if (viewValue) {
					angular.forEach(viewValue.split(separator), function (val) {
						if (val) {
							list.push(val.trim());
						}
					});
				}

				return list;
			});
		}
	};
}
