import { clientSettings } from '../common/client.settings';
import type { OpenTokSessionFactory, OpenTokTokenModel } from './schema';

/* @ngInject */
export function opentokSessionFactory (
	$resource: ng.resource.IResourceService
): OpenTokSessionFactory {
	const resourceUrl: string = `${clientSettings.GoReactV2API}/opentok/session/:session_id`;
	return $resource<OpenTokTokenModel, OpenTokSessionFactory>(resourceUrl, { session_id: '@session_id', media_id: '@media_id' }, {
		create: {
			method: 'POST'
		},
		createToken: {
			method: 'POST',
			url: `${resourceUrl}/token`
		},
		getForMedia: {
			method: 'GET',
			url: `${clientSettings.GoReactV2API}/media/:media_id/opentok_session`
		}
	});
}
