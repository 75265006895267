import * as angular from 'angular';
import { FeatureFlag } from './feature-flag.service';
import { FeatureTests } from './feature-flag-tests';
import { detectModule } from 'go-modules/detect';
import { common } from 'go-modules/models/common';
import { fullstoryModule } from 'go-modules/services/fullstory';

export const featureFlagModule = angular.module('go.featureFlag', [
	detectModule,
	common,
	fullstoryModule
])
	.service('featureFlag', FeatureFlag)
	.service('featureTests', FeatureTests)
	.name;
