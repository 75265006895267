import * as angular from 'angular';
import { SessionCreationIntent } from '../../session-creation-wizard/intent';
import { MediaSource } from '../../models/media';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';

interface Bindings {
	session: any;
	group: any;
	activity: any;
	$close: Function;
	$dismiss: Function;
};

export class SessionEditorModalController implements Bindings {
	public sessionCopy: any;
	public submitting: boolean = false;
	public intentType: SessionCreationIntent;

	// Bindings
	public activity: any;
	public group: any;
	public session: any;
	public $close: Function;
	public $dismiss: Function;

	/* @ngInject */
	constructor (
		public helpUrls: any,
		private $log: angular.ILogService,
		private Group: any,
		private ActivityModel: any,
		private $translate: ng.translate.ITranslateService,
		private Session: any,
		private MediaModel: any,
		private ngxGoToastService: NgxGoToastService
	) {}

	/**
	 * Handles controller init life cycle hook
	 */
	public $onInit () {
		if (!(this.session instanceof this.Session)) {
			throw new Error('SessionEditorModalController::must supply a session');
		}

		if (!(this.group instanceof this.Group.GroupModel)) {
			throw new Error('SessionEditorModalController::must supply a group');
		}

		if (!(this.activity instanceof this.ActivityModel)) {
			throw new Error('SessionEditorModalController::must supply an activity');
		}

		// Create a copy of the supplied session
		this.sessionCopy = angular.copy(this.session);
	}

	/**
	 * When to disable the form.
	 * 1. The session requires a self-select stimulus, but the media on it is not ready yet
	 * 2. The session has an attached self-select stimulus/slides but they aren't ready yet
	 */
	public shouldDisable = (): boolean => {
		if (this.activity.requiresSelfSelectStimulus()) {
			return !this.sessionCopy.source_media || !this.sessionCopy.source_media.isReady();
		} else if (this.sessionCopy.source_media) {
			return !this.sessionCopy.source_media.isReady();
		}

		return this.submitting;
	};

	public getDisabledTooltipText (): string {
		if (this.activity.requiresSelfSelectStimulus() && !this.sessionCopy.source_media) {
			return this.$translate.instant('session-editor_activity-requires-attach-stimulus');
		} else if (this.sessionCopy.source_media && !this.sessionCopy.source_media.isReady()) {
			return this.$translate.instant('session-editor_we-are-preparing-your-stimulus');
		}

		return '';
	}

	/**
	 * Whether or not to display the save for later functionality
	 */
	public shouldDisplaySaveForLater (): boolean {
		return (this.activity.isLiveSessionEnabled() || !!this.activity.is_conversation) &&
			this.group.hasInstructorRole(true) &&
			!this.activity.isSingleAttempt();
	}

	/**
	 * Save this session for later use
	 */
	public saveForLater (): Promise<void> {
		return this.done(SessionCreationIntent.SAVE_FOR_LATER);
	}

	/**
	 * On form submit, execute the appropriate intent action
	 */
	public submit (): Promise<void> {
		return this.done(SessionCreationIntent.CONTINUE);
	}

	/**
	 * Set intent to continue.
	 */
	public setContinue (): void {
		this.intentType = SessionCreationIntent.CONTINUE;
		this.submitting = true;
		this.submit().finally(() => this.submitting = false);
	}

	/**
	 * Save the session
	 */
	public async save (): Promise<any> {

		this.session.extend(this.sessionCopy);
		this.session.presenters = this.sessionCopy.presenters;

		// Pre-create the media and assign it to the session
		if (this.activity.isConversation() || this.activity.isLiveSessionEnabled()) {
			const media = await this.MediaModel.createFor(MediaSource.OPENTOK, this.session.group_id);
			this.session.setMedia(media);
		}

		return this.session.save();
	}

	/**
	 * Set the source_media
	 * @param sourceMedia (MediaModel)
	 */
	public onStimulusSaved (media: any): void {
		this.sessionCopy.source_media = media;
	}

	/**
	 * Close the modal with a given intent
	 */
	private done (intent: SessionCreationIntent): Promise<any> {
		return this.save()
			.then(() => {
				this.$close(intent);
			}).catch((e) => {
				this.$log.error(e);
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'modal-session-editor_error'
				});
			});
	}
}
