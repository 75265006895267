import * as angular from 'angular';

/* @ngInject */
export const feedbackSettings = function (WebStorage, EventChannel, SessionFeedback) {
	const storage = new WebStorage('feedbackSettings');
	const eventChannel = new EventChannel();

	const kindFilter = {
		current: SessionFeedback.KIND.COMMENT,
		filters: SessionFeedback.KINDS
	};

	const activeTab = SessionFeedback.TAB.COMMENT;

	return {
		settingsStorage: storage,
		highlight: angular.isDefined(storage.get('highlight')) ? storage.get('highlight') : true,
		autoScroll: angular.isDefined(storage.get('autoScroll')) ? storage.get('autoScroll') : true,
		autoPlay: angular.isDefined(storage.get('autoPlay')) ? storage.get('autoPlay') : true,
		filterOutSyncEventComments: angular.isDefined(storage.get('filterOutSyncEventComments')) ? storage.get('filterOutSyncEventComments') : false,
		oneClickMarker: angular.isDefined(storage.get('oneClickMarker')) ? storage.get('oneClickMarker') : false,

		// sort priority for feedback that has time association
		sortExpression: ['time', 'created_at'],

		/** Filter feedback by kind */
		kindFilter,
		activeTab,

		/** */
		setKindFilter (value) {
			this.kindFilter.current = value;
			eventChannel.broadcast('kindFilterChange', value);
		},

		/** */
		getKindFilter () {
			return this.kindFilter.current;
		},

		/** */
		onKindFilterChange (callback) {
			eventChannel.subscribe('kindFilterChange', callback);
		},

		/** */
		setActiveTab (value) {
			this.activeTab = value;
			eventChannel.broadcast('tabChange', value);
		},

		/** */
		onTabChange (callback) {
			eventChannel.subscribe('tabChange', callback);
		},

		/**
		 * Toggle media comment auto play
		 *
		 * @param value
		 * @param persist
		 */
		toggleAutoPlay (value, persist) {
			if (angular.isUndefined(value)) {
				value = !this.autoPlay;
			}
			this.autoPlay = value;

			// auto persist
			if (angular.isUndefined(persist)) {
				persist = true;
			}

			if (persist) {
				// store feedback setting
				storage.put('autoPlay', value);
			}
		},

		/**
		 * Toggle sync event comments filter
		 *
		 * @param value
		 */
		toggleSyncEventCommentsFilter (value) {
			if (angular.isUndefined(value)) {
				value = !this.filterOutSyncEventComments;
			}
			this.filterOutSyncEventComments = !!value;

			// store feedback setting
			storage.put('filterOutSyncEventComments', value);
		},

		/**
		 * Toggle one-click marker setting
		 *
		 * @param value
		 */
		 toggleOneClickMarker (value) {
			this.oneClickMarker = !!value;
			storage.put('oneClickMarker', value);
		},

		subscribe (eventType, callback) {
			eventChannel.subscribe(eventType, callback);
		},

		unsubscribe (eventType, callback) {
			eventChannel.unsubscribe(eventType, callback);
		}
	};
};
