import * as angular from 'angular';

import { TimeUtilsService } from './time.service';
import { formatTime } from './format-time.filter';
import { timeToSeconds } from './time-to-seconds.filter';
import { formatMsToHrs } from './format-ms-to-hrs.filter';
import { millisecondsToHrs } from './ms-to-hrs.filter';
import { formatTimeNoPad } from './format-time-no-pad.filter';

export const time = angular.module('time', [])
	.service('Time', TimeUtilsService)
	.filter('formatTime', formatTime)
	.filter('formatTimeNoPad', formatTimeNoPad)
	.filter('formatMs2Hrs', formatMsToHrs)
	.filter('timeToSeconds', timeToSeconds)
	.filter('millisecondsToHrs', millisecondsToHrs)
	.name;
