import { ENVIRONMENT_CONSTANTS } from 'go-modules/constants/environment-constants.constant';
import { MediaStreamFactory, MediaStreamKind } from './media-stream-factory/media-stream-factory';
import * as angular from 'angular';
import DebugVideoMediaStreamFactory from './media-stream-factory/debug-video-media-stream-factory';
import DeviceMediaStreamFactory from './media-stream-factory/device-media-stream-factory';
import MediaPermissions from './media-permissions/media-permissions';

const PREFERRED_CAMERA_STORAGE_KEY = 'preferred-camera';
const PREFERRED_MICROPHONE_STORAGE_KEY = 'preferred-microphone';

export interface DeviceKitInterface {
	ensureDevicePermissions (video: boolean, audio: boolean): Promise<boolean>;
	getDevicesByKind (kind: MediaStreamKind): Promise<MediaStreamFactory[]>;
	getPreferredCamera (): Promise<MediaStreamFactory|null>;
	getPreferredMicrophone (): Promise<MediaStreamFactory|null>;
	setPreferredCamera (camera: MediaStreamFactory): void;
	setPreferredMicrophone (microphone: MediaStreamFactory): void;
	unsubscribe (): void;
}

export class DeviceKit implements DeviceKitInterface {

	private mediaPermissions: MediaPermissions;
	private mediaFactories: Promise<MediaStreamFactory[]>;
	private mediaDevicesApi: MediaDevices;

	constructor (mediaDevicesApi: MediaDevices, permissionsApi?: Permissions|null) {
		this.mediaDevicesApi = mediaDevicesApi;
		this.mediaPermissions = new MediaPermissions(permissionsApi, this.mediaDevicesApi);
		if (this.mediaDevicesApi.addEventListener) {
			this.mediaDevicesApi.addEventListener('devicechange', this.refreshDevices);
		} else {
			this.mediaDevicesApi.ondevicechange = this.refreshDevices;
		}
		this.refreshDevices();
	}

	public static async requestScreenCaptureStream (): Promise<MediaStream> {
		const stream = await (navigator.mediaDevices as any).getDisplayMedia({ video: true, audio: false });
		stream.getTracks().forEach((track) => {
			DeviceMediaStreamFactory.aliasTrackStopMethod(track);
		});
		return stream;
	}

	/**
	 * Makes sure that the real devices have/prompt for permission
	 * @returns boolean
	 */
	public async ensureDevicePermissions (video: boolean, audio: boolean): Promise<boolean> {
		const permissions = await this.mediaPermissions.ensureMediaPermissions(video, audio);
		this.refreshDevices();
		return permissions === 'granted';
	}

	/**
	 * Get devices by kind
	 * @param kind MediaStreamKind
	 * @returns Promise<MediaStreamFactory[]>
	 */
	public async getDevicesByKind (kind: MediaStreamKind): Promise<MediaStreamFactory[]> {
		return (await this.mediaFactories).filter(
			(factory: MediaStreamFactory) => factory.getType() === kind
		);
	}

	/**
	 * Get devices by id
	 * Some devices allow navigator.mediaDevices.enumerateDevices() to return
	 * a list with duplicate deviceIds. Must filter by both id and kind.
	 * @param id string
	 * @returns Promise<MediaStreamFactory>
	 */
	public async getDeviceById (id: string,  kind: MediaStreamKind): Promise<MediaStreamFactory> {
		return (await this.mediaFactories).find(
			(factory: MediaStreamFactory) => factory.getId() === id && factory.getType() === kind
		);
	}

	/**
	 * Get preferred camera
	 * @returns Promise<MediaStreamFactory>
	 */
	public async getPreferredCamera (): Promise<MediaStreamFactory|null> {
		const preferredId: string = window.localStorage.getItem(PREFERRED_CAMERA_STORAGE_KEY);
		const cameras = await this.getDevicesByKind('video');
		const camera: MediaStreamFactory = cameras.find((c) => c.getId() === preferredId);
		return camera || cameras[0];
	}

	/**
	 * Get preferred microphone
	 * @returns Promise<MediaStreamFactory>
	 */
	public async getPreferredMicrophone (): Promise<MediaStreamFactory|null> {
		const preferredId: string = window.localStorage.getItem(PREFERRED_MICROPHONE_STORAGE_KEY);
		const microphones = await this.getDevicesByKind('audio');
		const microphone: MediaStreamFactory = microphones.find((m) => m.getId() === preferredId);
		return microphone || microphones[0];
	}

	/**
	 * Set preferred camera in localstorage
	 * @param camera
	 */
	public setPreferredCamera (camera: MediaStreamFactory): void {
		window.localStorage.setItem(PREFERRED_CAMERA_STORAGE_KEY, camera.getId());
	}

	/**
	 * Set preferred microphone in localstorage
	 * @param microphone
	 */
	public setPreferredMicrophone (microphone: MediaStreamFactory): void {
		window.localStorage.setItem(PREFERRED_MICROPHONE_STORAGE_KEY, microphone.getId());
	}

	public unsubscribe (): void {
		if (this.mediaDevicesApi.removeEventListener) {
			this.mediaDevicesApi.removeEventListener('devicechange', this.refreshDevices);
		} else {
			delete this.mediaDevicesApi.ondevicechange;
		}
	}

	private refreshDevices = (): void => {
		this.mediaFactories = this.mediaDevicesApi.enumerateDevices().then(
			(devices: MediaDeviceInfo[]) => devices.map(
				(device: MediaDeviceInfo) => new DeviceMediaStreamFactory(device, this.mediaDevicesApi)
			)
		);

		if (ENVIRONMENT_CONSTANTS.APP_ENVIRONMENT !== ENVIRONMENT_CONSTANTS.PROD) {
			this.mediaFactories = this.mediaFactories.then((mediaFactories: MediaStreamFactory[]) => {

				// Hack to get some information about start/stopping
				const videoSceneEl = angular.element(document.querySelector('video-scene'));
				const videoSceneCtrl = videoSceneEl && videoSceneEl.data('$VideoSceneController');
				mediaFactories.push(new DebugVideoMediaStreamFactory(videoSceneCtrl));
				return mediaFactories;
			});
		}
	};
}
