import { InjectionToken, inject, ProviderToken } from '@angular/core';

/**
 * The magic to inject ng1 into ngx.
 *
 * Rather than providing it all in one file, we build the tools here and use them in the ng1 definitions.
 * Becasue these are tree-shaking, you don't need to add them to any module's providers or anything.
 * Just @Inject(token) and it'll magically provide as needed. If you need to add another angularjs service
 * add NG1_INJECTION_NAME as a const to the class or function (see `UserService` for class, and `User` for fn)
 * and replace where it's defined in angularjs. (i.e. `.factory(User.NG1_INJECTION_NAME, User)`)
 */

// Angular upgrade doesn't export their injector token, so we have to copy it here.
// It's not even an injector token to begin with. It's a string.
// We have to lie here, because inject()'s typing says it doesn't take string (it does)
// Do not export this.
const $INJECTOR: ProviderToken<ng.auto.IInjectorService> = '$injector' as any;

// A type helper that determines if the type passed in is a class or function
type ClassType = new (...args: any) => any;
type FunctionType = (...args: any) => any;
type IsClass<T> = T extends ClassType ? T : never;
type IsFunction<T> = T extends FunctionType ? T : never;

// A type helper that gets the right type that ng1 will return
// If a type is a class factory, the injection token will return an instance type (UserService)
// If a type is a function factory, the injection token will return the functions's return type (User)
type InstanceOrFunctionType<T> =
	T extends IsClass<T> ? InstanceType<T> :
		T extends IsFunction<T> ? ReturnType<T> :
			T;

// Types that can be injected via NG1 and upgraded to NGX
interface NG1Injectaable {NG1_INJECTION_NAME: string}

// A function that generates a tree-shakable Injection token that retrieves an angularjs service:
// https://angular.io/api/core/InjectionToken#tree-shakable-injectiontoken
export const upgradeNg1Dependency = <T extends NG1Injectaable>(dependency: T) =>
	new InjectionToken<InstanceOrFunctionType<T>>(`${dependency.NG1_INJECTION_NAME}.ng1.token`, {
		providedIn: 'root',
		factory: () => inject<ng.auto.IInjectorService>($INJECTOR).get(dependency.NG1_INJECTION_NAME)
	});

// For upgrading 3rd party deps
export const upgradeExternalNg1Dependency = <T>(injectableName: string) =>
	new InjectionToken<InstanceOrFunctionType<T>>(`${injectableName}.ng1.token`, {
		providedIn: 'root',
		factory: () => inject<ng.auto.IInjectorService>($INJECTOR).get(injectableName)
	});

