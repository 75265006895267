import { IAugmentedJQuery, IChangesObject, IScope } from 'angular';
import { SlideToggleControlController } from '../control/control.controller';
import * as Hammer from 'hammerjs';

export interface Bindings {
	slideToggleController: SlideToggleControlController;
}

/* @ngInject */
export class SlideToggleThumbController implements Bindings {
	public slideToggleController: SlideToggleControlController;
	public disabled: string;

	private nativeElement: HTMLElement;
	private manager: HammerManager;
	private parentManager: HammerManager;
	private minLeft: number;
	private maxLeft: number;
	private sliding: boolean;
	private lastLeft: number;

	constructor (
		private $element: IAugmentedJQuery,
		private $scope: IScope)
	{}

	/**
	 * Handle controller init life-cycle hook
	 */
	public $onInit (): void {
		this.nativeElement = this.$element[0];
		this.sliding = false;
		this.minLeft = 0;
		this.maxLeft = this.nativeElement.parentElement.offsetWidth - this.nativeElement.offsetWidth;
		this.lastLeft = 0;

		this.parentManager = new Hammer(this.nativeElement.parentElement);
		this.parentManager.add(new Hammer.Pan({
			direction: Hammer.DIRECTION_HORIZONTAL,
			threshold: 0
		}));
		this.parentManager.on('pan', this.handlePan.bind(this));
		this.parentManager.set({
			enable: !this.disabled
		});

		// Handle tap event
		this.$element.on('click', this.handleClick.bind(this));
		this.manager = new Hammer(this.nativeElement);
		this.manager.on('tap', this.handleTap.bind(this));
	}

	/**
	 * Handle controller destroy life-cycle hook
	 */
	public $onDestroy (): void {
		this.manager.destroy();
		this.parentManager.destroy();
	}

	/**
	 * Handle property binding changes
	 */
	public $onChanges (changes: any): void {
		// Ensure that sliding is either enabled/disabled
		const disabledPropertyChange: IChangesObject<boolean> = changes.disabled;
		if (disabledPropertyChange && this.parentManager) {
			this.parentManager.set({
				enable: !disabledPropertyChange.currentValue
			});
		}
	}

	/**
	 * Distinguish between a tap event that occurs during sliding vs.
	 * one that occurs when the thumb is tapped in-place. Tapping
	 * the thumb in-place should toggle the the state of the input.
	 */
	private handleTap (): void {
		if (this.sliding) {
			return;
		}

		this.$scope.$evalAsync(() => {
			this.slideToggleController.toggle();
		});
	}

	/**
	 * Immediately cancel click events on this thumb element.
	 * The label in the parent element toggles the checkbox input
	 * enabled/disabled, so we have to prevent this here.
	 */
	private handleClick (event: Event): void {
		event.preventDefault();
	}

	/**
	 * Handle pan event
	 */
	private handlePan (event: HammerInput): void {
		// Slide started
		if (!this.sliding) {
			this.handleSlideStart();
		}

		if (this.sliding) {
			this.handleSliding(event);
		}

		// Slide ended
		if (event.isFinal) {
			this.handleSlideEnd();
		}
	}

	/**
	 * Handle slide start event
	 */
	private handleSlideStart (): void {
		this.lastLeft = this.nativeElement.offsetLeft;
		this.maxLeft = this.nativeElement.parentElement.offsetWidth - this.nativeElement.offsetWidth;
		this.sliding = true;
		this.nativeElement.offsetParent.classList.add('sliding');
	}

	/**
	 * Handle sliding event
	 */
	private handleSliding (event: HammerInput): void {
		this.nativeElement.offsetParent.classList.toggle('inactive', this.isLeftLeaning());
		this.nativeElement.offsetParent.classList.toggle('active', !this.isLeftLeaning());

		// Set new position and consider bounds
		const currentLeft: number = event.deltaX + this.lastLeft;
		const left: number = Math.min(
			Math.max(
				this.minLeft,
				currentLeft
			),
			this.maxLeft
		);

		this.nativeElement.style.cssText = `left: ${left}px`;
	}

	/**
	 * Handle slide end event
	 */
	private handleSlideEnd (): void {
		// If the new state differs from the current one,
		// manually set the model after slide end.
		const isActive: boolean = !this.isLeftLeaning();
		this.$scope.$evalAsync(() => {
			this.slideToggleController.toggle(isActive);
		});

		// Clear out style. This allows the existing css rule to apply.
		this.nativeElement.style.cssText = '';
		this.sliding = false;
		this.nativeElement.offsetParent.classList.remove('sliding');
	}

	/**
	 * Determines whether the slide thumb is closer to
	 * the parent element's left side than the right.
	 */
	private isLeftLeaning (): boolean {
		const threshold = (this.nativeElement.parentElement.offsetWidth / 2) - (this.nativeElement.offsetWidth / 2);
		return this.nativeElement.offsetLeft < threshold;
	}
}
