import template from './view.component.html';
import { LiveViewController } from './view.controller';

export const liveViewComponent = {
	controller: LiveViewController,
	require: {
		mainController: '^feedbackSession',
		singleMediaDisplayController: '?^singleMediaDisplay'
	},
	bindings: {
		media: '<'
	},
	template
};
