import template from './go-banner-list.component.html';
import {GoBannerListController} from './go-banner-list.controller';

export const GoBannerListComponent = {
	template,
	controller: GoBannerListController,
	bindings: {
		banners: '<'
	}
};
