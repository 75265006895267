import * as angular from 'angular';
import template from './feedback-tree.directive.html';

/* @ngInject */
export const feedbackTree: ng.IDirectiveFactory = () => {
	return {
		restrict: 'E',
		require: ['^feedbackSession', '?ngModel'],
		scope: {
			provider: '=provider',
			filterExpression: '=',
			sortExpression: '=',
			autoScroll: '=',
			autoPlay: '=',
			highlight: '=',
			hideCommentReply: '<',
			userGroup: '<'
		},
		template,
		// We have to use any here until this is a component
		link (scope: any, element, _attrs, controllers) {
			let scrollEnabled = true;
			scope.active = null;

			const messageEl = angular.element('<div class=\'feedback-tree-message\'></div>'),
				messageContent = angular.element('<div class=\'message-content\'></div>');

			messageEl.append(messageContent);

			// Expose main controller to scope
			scope.mainController = controllers[0];

			/** Scroll is not enabled when mouse is hovering over offset parent */
			scope.isScrollEnabled = () => {
				return scrollEnabled;
			};

			scope.autoScrollEnabled = () => {
				return (angular.isDefined(scope.autoScroll) ? scope.autoScroll : true) && scope.isScrollEnabled();
			};

			scope.autoPlayEnabled = () => {
				return angular.isDefined(scope.autoPlay) ? scope.autoPlay : true;
			};

			scope.highlightEnabled = () => {
				return angular.isDefined(scope.highlight) ? scope.highlight : true;
			};

			scope.notify = (message, duration) => {
				duration = duration || 2000;
				messageContent.html(message);
				messageEl.css('display', '');
				// TODO: Can we not use $timeout?
				setTimeout(() => {
					messageEl.css('display', 'none');
				}, duration);
			};

			// mouse over feedback tree disables auto scrolling
			element.on('mouseenter', () => {
				scrollEnabled = false;
			});

			element.on('mouseleave', () => {
				scrollEnabled = true;
			});

			// feedback tree scope will serve as feedback global settings
			scope.settings = scope;

			// add message element
			messageEl.css('display', 'none');
			element.append(messageEl);
		}
	};
};
