import { Inject, Injectable } from '@angular/core';
import {
	BehaviorSubject,
	catchError,
	combineLatest,
	concat,
	debounceTime,
	distinctUntilChanged,
	EMPTY,
	filter,
	first,
	map,
	Observable,
	of,
	shareReplay,
	Subject,
	switchMap,
	tap
} from 'rxjs';
import { SortOrder } from 'ngx/go-modules/src/enums/sort-order-main';
import { ActivitiesQueryParams } from 'ngx/go-modules/src/interfaces/groups/activities-query-params';
import { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';
import { NgxActivityService } from 'ngx/go-modules/src/services/activity/activity.service';
import { BaseDataSource } from 'ngx/go-modules/src/classes/base-data-source';
import {
	SelectedInterface,
	SelectedService,
	selectedServiceToken
} from 'go-modules/services/selected/selected.service';
import * as dayjs from 'dayjs';
import { AvailabilityFilter } from 'ngx/go-modules/src/enums/availability-filter';
import { accumulateArr, accumulateMap } from 'ngx/go-modules/src/rxjs/accumulate/accumulate';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import { EventService } from '../event/event.service';
import { EVENT_NAMES } from '../event/event-names.constants';
import { NgxGoToastService } from '../go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';

export const DEBOUNCE_TIME = 50;
export const ACTIVITY_FILTER_KEY = 'activity-filter';
export const ACTIVITY_SORT_KEY = 'activity-sort';

@Injectable({
	providedIn: 'root'
})
export class ActivityListDataSource extends BaseDataSource<Activity, any> {
	public isFiltering$: Observable<boolean>;
	private folderId$$ = new BehaviorSubject<number>(null);
	private availabilityDate$$ = new BehaviorSubject<string>(null);
	private dueDate$$ = new BehaviorSubject<string>(null);
	public sortBy$$ = new BehaviorSubject<SortOrder>(SortOrder.DEFAULT);
	public availabilityFilter$$ = new BehaviorSubject<AvailabilityFilter>(AvailabilityFilter.ALL);
	private includeNullDueDate$$ = new BehaviorSubject<boolean>(false);
	private includeNullAvailabilityDate$$ = new BehaviorSubject<boolean>(false);
	private reloadStats$$ = new BehaviorSubject<boolean>(true);
	private statsLoadingFailed = false;

	private add$$ = new Subject<Activity>();
	private edit$$ = new Subject<Activity>();
	private remove$$ = new Subject<Activity>();

	constructor (
		private ngxGroupService: NgxGroupService,
		private ngxActivityService: NgxActivityService,
		private eventService: EventService,
		private ngxGoToastService: NgxGoToastService,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(activityToken) private activityModel
	) {
		super();

		this.selectedService.selectedSubject.asObservable()
			.pipe(
				map((selected: SelectedInterface) => selected.group?.group_id || null),
				filter((groupId) => this.folderId$$.getValue() !== groupId)
			)
			.subscribe((folderId: number) => {
				this.setFolder(folderId);
			});

		this.setupFilters();
		this.params$ = combineLatest([
			this.folderId$$.pipe(distinctUntilChanged()),
			this.availabilityDate$$.pipe(distinctUntilChanged()),
			this.dueDate$$.pipe(distinctUntilChanged()),
			this.includeNullDueDate$$.pipe(distinctUntilChanged()),
			this.includeNullAvailabilityDate$$.pipe(distinctUntilChanged()),
			this.reload$$
		], (folderId, availabilityDate, dueDate, includeNullDueDate, includeNullAvailabilityDate, _reload) => {
			return {
				folder_id: folderId,
				availability_date: availabilityDate,
				due_date: dueDate,
				include_null_due_date: includeNullDueDate,
				include_null_availability_date: includeNullAvailabilityDate
			} as ActivitiesQueryParams;
		});
		this.results$ = this.getActivityList();

		this.isFiltering$ = this.availabilityFilter$$.asObservable().pipe(
			map((thisFilter) => thisFilter !== AvailabilityFilter.ALL)
		);

		this.observeGradedEvent();
	}

	public connect (): Observable<Activity[]> {
		if (this.statsLoadingFailed) {
			this.statsLoadingFailed = false;
			this.reload();
		}
		return this.results$;
	}

	public disconnect (): void {}

	public setFolder (folderId: number) {
		this.folderId$$.next(folderId);
	}

	public sortBy (sortBy: SortOrder) {
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + ACTIVITY_SORT_KEY, sortBy);
		this.sortBy$$.next(sortBy);
	}

	public getCurrentSort () {
		return this.sortBy$$.getValue();
	}

	public reloadStats () {
		this.reloadStats$$.next(true);
	}

	public setAvailabilityFilter (availFilter: AvailabilityFilter) {
		switch(availFilter) {
			case AvailabilityFilter.ALL:
				this.setAvailabilityDate(null);
				this.setDueDate(null);
				this.includeNullAvailabilityDate$$.next(false);
				this.includeNullDueDate$$.next(false);
				break;
			case AvailabilityFilter.CURRENT:
				this.setAvailabilityDate(`<${dayjs().add(1, 'day').format('YYYY-MM-DD')}`);
				this.setDueDate(`>${dayjs().add(1, 'day').format('YYYY-MM-DD')}`);
				this.includeNullAvailabilityDate$$.next(true);
				this.includeNullDueDate$$.next(true);
				break;
			case AvailabilityFilter.FUTURE:
				this.setAvailabilityDate(`>${dayjs().add(1, 'day').format('YYYY-MM-DD')}`);
				this.setDueDate(null);
				this.includeNullAvailabilityDate$$.next(false);
				this.includeNullDueDate$$.next(true);
				break;
			case AvailabilityFilter.PAST:
				this.setAvailabilityDate(null);
				this.setDueDate(`<${dayjs().add(1, 'day').format('YYYY-MM-DD')}`);
				this.includeNullAvailabilityDate$$.next(true);
				this.includeNullDueDate$$.next(false);
				break;
		}
		localStorage.setItem(`${this.userService.currentUser.user_id}-` + ACTIVITY_FILTER_KEY, availFilter);
		this.availabilityFilter$$.next(availFilter);
	}

	public getCurrentFilter () {
		return this.availabilityFilter$$.getValue();
	}

	public setAvailabilityDate (text: string) {
		this.availabilityDate$$.next(text);
	}

	public setDueDate (text: string) {
		this.dueDate$$.next(text);
	}

	public addActivity (activity: Activity) {
		if (this.getCurrentFilter() !== AvailabilityFilter.ALL) {
			const activityFilterType = this.getFilterTypeOfActivity(activity);

			if(this.getCurrentFilter() !== activityFilterType) {
				this.setAvailabilityFilter(activityFilterType);

				// fallback no need to add the activity
				return;
			}
		}

		this.add$$.next(activity);
	}

	public editActivity (editedActivity: Activity) {
		this.edit$$.next(editedActivity);
	}

	public removeActivity (activity: Activity) {
		this.remove$$.next(activity);
	}

	public sortActivities (activityList, sortBy: SortOrder) {
		switch (sortBy) {
			case SortOrder.OLDEST:
				return activityList.sort((a,b) => {
					if (dayjs(a.created_at).toISOString() === dayjs(b.created_at).toISOString()) {
						return a.activity_id - b.activity_id;
					}
					return dayjs(a.created_at).isAfter(dayjs(b.created_at)) ? 1 : -1;
				});
			case SortOrder.ASC:
				return activityList.sort((a, b) => a.name.localeCompare(b.name));
			case SortOrder.DESC:
				return activityList.sort((a, b) => b.name.localeCompare(a.name));
			case SortOrder.NEWEST:
				return activityList.sort((a,b) => {
					if (dayjs(a.created_at).toISOString() === dayjs(b.created_at).toISOString()) {
						return b.activity_id - a.activity_id;
					}
					return dayjs(a.created_at).isAfter(dayjs(b.created_at)) ? -1 : 1;
				});
			case SortOrder.DEFAULT:
			default:
				return activityList.sort((a,b) => {
					const initialSortValue = a.sort_index - b.sort_index;
					if (initialSortValue !== 0) return initialSortValue;
					return a.activity_id - b.activity_id;
				});
		}
	}

	private setupFilters () {
		const selectedFilter = localStorage.getItem(`${this.userService.currentUser.user_id}-` + ACTIVITY_FILTER_KEY);
		if (selectedFilter && Object.values(AvailabilityFilter).includes(selectedFilter as AvailabilityFilter)) {
			this.setAvailabilityFilter(selectedFilter as AvailabilityFilter);
		} else {
			this.setAvailabilityFilter(this.getCurrentFilter());
		}

		const selectedSort = localStorage.getItem(`${this.userService.currentUser.user_id}-` + ACTIVITY_SORT_KEY);
		if (selectedSort && Object.values(SortOrder).includes(selectedSort as SortOrder)) {
			this.sortBy(selectedSort as SortOrder);
		}
	}

	private observeGradedEvent () {
		this.eventService.listen([
			EVENT_NAMES.ACTIVTY_INCREMENT_NUM_GRADED,
			EVENT_NAMES.ACTIVTY_DECREMENT_NUM_GRADED,
			EVENT_NAMES.COURSE_ACTIVITIES_COPIED
		])
			.subscribe((event) => {
				if (event.name === EVENT_NAMES.COURSE_ACTIVITIES_COPIED) {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'activity-list_data-source-copied'
					});
					return this.reload();
				}
				this.results$.pipe(first())
					.subscribe((activities) => {
						const existingActivity = activities.find((activity) => activity.activity_id === event.data);

						// TODO DEV-15860 This never gets triggered due to an existing bug and fails if stats dont load
						if (existingActivity) {
							existingActivity.num_graded =
								event.name === EVENT_NAMES.ACTIVTY_INCREMENT_NUM_GRADED ?
									existingActivity.num_graded + 1 :
									existingActivity.num_graded - 1;

							this.editActivity(existingActivity);
						}
					});
			});
	}

	private getFilterTypeOfActivity (activity) {
		if (activity.due_at && dayjs(activity.due_at).isBefore(dayjs())) {
			return AvailabilityFilter.PAST;
		}

		if (dayjs(activity.available_at).isAfter(dayjs())) {
			return AvailabilityFilter.FUTURE;
		}

		if (!activity.available_at || dayjs(activity.available_at).isBefore(dayjs().add(1, 'day'))) {
			return AvailabilityFilter.CURRENT;
		}
	}

	private errorHandler (err) {
		this.onError$$.next(err);
		this.setLoading(false);
		return EMPTY;
	}

	private getActivityList () {
		return this.params$.pipe(
			debounceTime(DEBOUNCE_TIME),
			tap(() => this.setLoading()),
			switchMap((activityListParams: ActivitiesQueryParams) => {
				return this.ngxGroupService.getActivities(activityListParams)
					.pipe(
						map((activities) => ({activityListParams, activities})),
						catchError(this.errorHandler.bind(this))
					);
			}),
			switchMap(({activityListParams, activities}) => {
				const statsEmission = this.reloadStats$$.asObservable().pipe(
					switchMap(() => {
						return this.ngxActivityService.getStats(activityListParams.folder_id).pipe(
							map((stats) => activities.map((activity) => {
								return Object.assign(activity,
									stats.find((stat) =>
										parseInt(stat.activity_id, 10) === parseInt(activity.activity_id, 10)));
							})),
							catchError(() => {
								this.statsLoadingFailed = true;
								this.ngxGoToastService.createToast({
									type: GoToastStatusType.ERROR,
									message: 'activity-list_data-source-failed-stats'
								});
								// Do not emit since stats haven't loaded
								return EMPTY;
							})
						);
					})
				);

				return concat(of(activities), statsEmission);
			}),
			map((response: Activity[]) => {
				this.setLoading(false);
				return response.map((activity) => this.activityModel.model(activity, true));
			}),
			switchMap((activityList: Activity[]) => {
				const added$ = this.add$$.pipe(accumulateArr());
				const editted$ = this.edit$$.pipe(accumulateMap('activity_id'));
				const removed$ = this.remove$$.pipe(accumulateArr());
				return combineLatest({added: added$, editted: editted$, removed: removed$}).pipe(
					map(({added, editted, removed}) =>
						activityList.concat(added)
							.map((activity) => editted[activity.activity_id] ?? activity)
							.filter((activity) => !removed.some((removedActivity) =>
								removedActivity.activity_id === activity.activity_id))
					)
				);
			}),
			shareReplay(1),
			switchMap((activityList: Activity[]) => {
				return this.sortBy$$.pipe(
					map((sort) => this.sortActivities(activityList, sort))
				);
			}),
			catchError(this.errorHandler.bind(this))
		);
	}
}
