import * as angular from 'angular';
import { IHttpService } from 'angular';
import { DataTransformer } from '../common/data-transformer';

export class RubricSessionDataTransformerService extends DataTransformer {

	/* @ngInject */
	constructor (
		private BaseModel,
		private FeedbackNodeModel,
		$http: IHttpService
	) {
		super($http);
	}

	/**
	 * Transform request data
	 */
	protected transformRequestData (data: any): any {
		const payload = angular.extend({}, data);

		if (payload.hasOwnProperty('children')) {
			delete payload.children;
		}

		if (payload.hasOwnProperty('parent')) {
			delete payload.parent;
		}

		// Remove irrelevant properties
		[
			this.BaseModel.prototype,
			this.FeedbackNodeModel.prototype
		].forEach((obj) => {
			angular.forEach(obj, (_value, key) => {
				if (payload.hasOwnProperty(key)) {
					delete payload[key];
				}
			});
		});

		return this.clean(payload);
	}

	/**
	 * Transform response data
	 */
	protected transformResponseData (data: any): any {
		// Convert points and totals to numeric type
		data.points = isNaN(parseFloat(data.points)) ? null : parseFloat(data.points);
		data.total = isNaN(parseFloat(data.total)) ? null : parseFloat(data.total);

		return this.clean(data);
	}

	/**
	 * Clean rubric session data
	 */
	private clean (rubricSession: any): any {
		if (!Array.isArray(rubricSession.data)) {
			rubricSession.data = [];
		}

		// clean the array
		for (let i = 0; i < rubricSession.data.length; i++) {
			if (!rubricSession.data[i]) {
				rubricSession.data.splice(i, 1);
				i--;
			}
		}

		rubricSession.data.forEach((item) => {
			if (item.points) {
				const points = parseFloat(item.points);
				item.points = isNaN(points) ? null : points;
			}
		});

		return rubricSession;
	}
}
