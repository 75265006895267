import { Announcement } from '../models/announcement/announcement.service';

interface Bindings {
	banner: Announcement;
}

export class GoBannerController implements Bindings {
	public banner: Announcement;
	public closed: boolean;

	/* @ngInject */
	constructor (
		private goBannerHelper,
		private goBannerService,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService
	) {}

	public $onInit () {
		this.closed = false;
		if (this.banner) {
			this.checkSupportedType();
		}
	}

	public $postLink () {
		if (this.banner.html) {
			this.$timeout(() => {
				this.$element[0].querySelector('.message').appendChild(this.banner.html[0]);
			});
		}
	}

	public closeBanner (): void {
		this.goBannerHelper.setViewedStatus(this.banner);
		this.goBannerService.removeBanner(this.banner);
	}

	private checkSupportedType (): void {
		if (!this.goBannerHelper.isSupportedType(this.banner.type)) {
			throw new Error(`${this.banner.type} is not a supported type.`);
		}
	}
}
