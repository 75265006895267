import { StateService } from '@uirouter/angularjs';
import { States } from 'go-modules/enums/states.enum';
import { AuthService } from 'go-modules/services/auth/auth.service';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';

/* @ngInject */
export function SessionTimeoutModalController (
	$scope,
	User,
	authService: AuthService,
	$state: StateService,
	$location,
	$window,
	featureFlag: FeatureFlag,
	ngxAuthService,
	sessionManager) {

	$scope.loading = false;
	$scope.timeToLogout = 10;

	const vm = this;

	vm.$onInit = () => {

		$scope.user = {
			username: $scope.user.username,
			password: ''
		};

		$scope.featureFlag = featureFlag;
	};

	$scope.logout = function () {
		authService.logout().then(() => {
			$scope.$dismiss();
			$state.go(States.AUTH_LOGIN);
		});
	};

	$scope.signIn = function (username, password) {
		$scope.invalid = false;
		$scope.loading = true;
		const result = authService
			.login(username, password)
			.then((userInfo) => {
				// If user needs to force reset password
				if (userInfo.data.user.reset_data) {
					const data = userInfo.data.user.reset_data;
					const port =  $location.port() ? ':' + $location.port() : '';
					sessionManager.end(); // End this session
					return $window.location = `//${$location.host()}${port}/dashboard/auth/reset/${data.email}/${data.exp}/${data.hash}`;
				}

				$scope.$close(User.model(userInfo.data.user));
			}).catch(() => {
				$scope.invalid = true;
			}).finally(() => {
				$scope.loading = false;
			});

		return result;
	};

	$scope.loginWithSso = function (provider: string) {
		return ngxAuthService.getAuthUrl(provider, { redirect: $window.location.href })
			.then(({ url }: { url: string }) => {
				$window.location.href = url;
			});
	};
}
