import * as angular from 'angular';
import { MODE } from './modes';

/* @ngInject */
export function ActivityInstructionsModalController ($sanitize) {
	const vm = this;

	vm.$onInit = () => {
		if (!angular.isObject(vm.activity)) {
			throw new Error('ActivityInstructionsModalController::Must provide activity');
		}

		if (!vm.activity.hasInstructions() && !vm.activity.has_single_recording_attempt && !vm.showHeadPhones) {
			throw new Error('ActivityInstructionsModalController::Activity must have test settings or instructions (or both)');
		}

		if ([MODE.RECORDING, MODE.FEEDBACK].indexOf(vm.mode) === -1) {
			throw new Error('ActivityInstructionsModalController::Mode not supported');
		}

		vm.mediaPreviewOptions = { autoPlay: true };

		// Determine which instructions to display
		if (vm.mode ===  MODE.RECORDING) {
			vm.instructionsToShow = vm.activity.recording_instructions;
		} else {
			vm.instructionsToShow = vm.activity.feedback_instructions;
		}
	};

	/**
	 * Whether or not to show recording instructions
	 *
	 * @returns {boolean}
	 */
	vm.showRecordingInstructions = function () {
		return vm.mode === MODE.RECORDING;
	};

	/**
	 * Whether or not to show feedback instructions
	 *
	 * @returns {boolean}
	 */
	vm.showFeedbackInstructions = function () {
		return vm.mode === MODE.FEEDBACK;
	};

	vm.returnAsTrusted = function () {
		if (vm.instructionsToShow.text) {
			return $sanitize(vm.instructionsToShow.text);
		}
	};
}
