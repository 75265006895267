import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { FullstoryService, fullstoryToken } from 'go-modules/services/fullstory/fullstory.service';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LibraryService } from 'ngx/go-modules/src/services/library/library.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { BehaviorSubject, Observable, Subject, filter, finalize, forkJoin, map, of } from 'rxjs';
import { GoDialogRef } from 'ngx/go-modules/src/services/go-dialog-ref/go-dialog-ref';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'ngx/go-modules/src/form-validator/email.validator/email.validator';
import { GO_SIDEPANEL_DATA } from 'ngx/go-modules/src/services/go-sidepanel/component/go-sidepanel.component';
import { LibraryCollection, LibraryCollectionPermission } from 'ngx/go-modules/src/interfaces/library/library-collection';

interface CollaboratorEmails {
	id: number | null;
	email: string;
}

@Component({
	selector: 'ngx-modify-collections-panel',
	template: require('./modify-collections-panel.component.html'),
	styles: [require('./modify-collections-panel.component.scss')]
})
export class ModifyCollectionsPanelComponent implements OnInit, OnDestroy {
	public collection: LibraryCollection;
	public saving$ = new BehaviorSubject(false);
	public collaboratorEmails: CollaboratorEmails[] = [];
	public uniqueEmails: string[] = [];
	public collaboratorsToAdd: string[] = [];
	public collaboratorsToDelete: LibraryCollectionPermission[] = [];
	public inviteForm: FormGroup;
	public modifyCollectionForm: FormGroup;
	public readonly componentDestroyed$$ = new Subject();

	constructor (
		@Inject(GO_SIDEPANEL_DATA) public sidepanelData: {
			options: {
				inputs: {
					collection: LibraryCollection
				}
			};
		},
		private dialogRef: GoDialogRef,
		private libraryService: LibraryService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private renderer2: Renderer2,
		@Inject(userServiceToken) private userService: UserService,
		@Inject(fullstoryToken) private fullstoryService: FullstoryService
	) {}

	public ngOnInit () {
		this.modifyCollectionForm = new FormGroup({
			newCollectionName: new FormControl('', [Validators.required])
		});
		this.inviteForm = new FormGroup({
			newCollaboratorEmail: new FormControl('', [Validators.required, emailValidator()])
		});

		this.collection = this.sidepanelData.options.inputs.collection;
		if (this.collection){
			this.modifyCollectionForm.controls.newCollectionName.setValue(this.collection.name);

			this.collection.library_collection_permissions.map((permission) => {
				if (permission.email !== this.userService.currentUser.email) {
					this.collaboratorEmails.push({
						id: permission.id,
						email: permission.email
					});
				}
			});

			this.uniqueEmails = [...new Set(this.collaboratorEmails.map((item) => item.email))];
		};
		this.listenGlobalEvents();
	}

	public ngOnDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
	}

	public cancel (): void {
		if (this.collaboratorsToAdd.length || this.collaboratorsToDelete.length) {
			const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(
				ConfirmDialogComponent, {
					data: {
						title: this.translate.instant('library-collections_modify-collections-edit-lose-modal-title'),
						message: this.translate.instant('library-collections_modify-collections-edit-lose-modal-message'),
						confirmText: this.translate.instant('common_return'),
						cancelText: this.translate.instant('library-collections_modify-collections-edit-lose-modal-discard')
					} as ConfirmDialogData
				});

			dialogRef.afterClosed().pipe(
				filter((confirm: boolean) => !confirm)
			).subscribe(() => {
				this.dialogRef.close();
			});
		} else {
			this.dialogRef.close();
		}
	}

	/**
	 * Save the collection and close this panel
	 */
	public onFormSubmit (form: FormGroup) {
		// We don't care if the invite form is invalid here so discard it
		this.inviteForm.controls.newCollaboratorEmail.markAsPristine();
		this.inviteForm.controls.newCollaboratorEmail.markAsUntouched();
		this.inviteForm.controls.newCollaboratorEmail.setErrors({ required: true });

		// If form is pristine (we didn't do anything) and no collaborator deleted, just close
		if (form.pristine && this.collaboratorsToDelete.length === 0 && this.collaboratorsToAdd.length === 0) {
			this.dialogRef.close();
			return;
		}

		if (form.invalid) return;

		this.saving$.next(true);
		const savingObservables: Observable<any>[] = [of(null)];

		if (this.collection) {
			let returnedCollection = this.collection;
			if (this.collection.name !== this.modifyCollectionForm.value.newCollectionName) {
				savingObservables.push(
					this.libraryService.updateCollection(this.collection.id, {
						name: this.modifyCollectionForm.value.newCollectionName
					})
				);
				returnedCollection = {
					...returnedCollection,
					name: this.modifyCollectionForm.value.newCollectionName
				};
			}

			if (this.collaboratorsToDelete.length) {
				savingObservables.push(this.libraryService.removeCollectionPermission(this.collaboratorsToDelete));
				this.collaboratorsToDelete.forEach((collaborator) => {
					if (returnedCollection.library_collection_permissions
						.some((permission) => permission.id === collaborator.id))
					{
						returnedCollection.library_collection_permissions
							.splice(returnedCollection.library_collection_permissions.indexOf(collaborator), 1);
					}
				});
			}

			if (this.collaboratorsToAdd.length) {
				savingObservables.push(
					this.libraryService.inviteToCollection(this.collection.id, {
						emails: this.collaboratorsToAdd
					}).pipe(
						map((response: any) => {
							response.permissions.map((permission) => {
								returnedCollection = {
									...returnedCollection,
									library_collection_permissions:
										[...returnedCollection.library_collection_permissions, permission]
								};
							});

							if (response.failed_emails.length) {
								this.dialog.open(MessageDialogComponent, {
									data: {
										title: this.translate.instant('library-collections_manage-collections-invite-failed-title'),
										message: this.translate.instant('library-collections_manage-collections-invite-failed-message', {
											emails: response.failed_emails.join(', ')
										})
									}
								});
							}
						}))
				);
			}

			forkJoin(savingObservables)
				.pipe(
					finalize(() => {
						this.saving$.next(false);
						this.dialogRef.close(returnedCollection);
					})
				).subscribe(() => {
					this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_MODIFY_COLLECTION, {});
				});
		} else {
			this.libraryService.createCollection({
				name: this.modifyCollectionForm.value.newCollectionName,
				emails: this.collaboratorsToAdd
			}).subscribe((response) => {
				this.saving$.next(false);
				this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_CREATE_COLLECTION, {});
				this.dialogRef.close(response);
			});
		}
	}

	public invite () {
		if (this.inviteForm.invalid) {
			this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_INVITE_FAILED, { reason_str: 'EMAIL_INVALID' });
			return;
		}

		// check if email is present from the list of collaborators to be deleted
		this.fullstoryService.createEvent(FULLSTORY_EVENTS.NEW_LIBRARY_INVITE_SUCCESS, {});
		this.collaboratorsToDelete.forEach((collaborator, key) => {
			if (collaborator.email === this.inviteForm.value.newCollaboratorEmail) {
				// add back to the list of collaborators
				this.collaboratorEmails.push(collaborator);
				this.uniqueEmails.push(this.inviteForm.value.newCollaboratorEmail);

				// remove from the list of collaborators to be deleted
				this.collaboratorsToDelete.splice(key, 1);

				this.inviteForm.controls.newCollaboratorEmail.setValue('');
			}
		});

		if (this.inviteForm.value.newCollaboratorEmail !== '') {
			this.collaboratorEmails.push({id: null, email: this.inviteForm.value.newCollaboratorEmail });
			if (this.uniqueEmails.indexOf(this.inviteForm.value.newCollaboratorEmail) === -1) {
				this.uniqueEmails.push(this.inviteForm.value.newCollaboratorEmail);
			}
			this.collaboratorsToAdd.push(this.inviteForm.value.newCollaboratorEmail);
			this.inviteForm.controls.newCollaboratorEmail.setValue('');
		}

		this.inviteForm.controls.newCollaboratorEmail.markAsPristine();
		this.inviteForm.controls.newCollaboratorEmail.markAsUntouched();
		this.inviteForm.controls.newCollaboratorEmail.setErrors({ required: true });
	}

	public removeCollaborator (emailToRemove: string) {
		// remove from the list of emails to be added (unsaved)
		if (this.collaboratorsToAdd.some((email) => email === emailToRemove)) {
			this.collaboratorsToAdd.splice(this.collaboratorsToAdd.indexOf(emailToRemove), 1);
		} else {
			// add to the list for collaborators to delete
			this.collaboratorEmails.map((collaborator) => {
				if (collaborator.email === emailToRemove) {
					this.collaboratorsToDelete.push(collaborator);
				};
			});
		}

		// remove from the list of emails populated in the UI
		this.uniqueEmails.splice(this.uniqueEmails.indexOf(emailToRemove), 1);
		// update list of collaborators
		this.collaboratorEmails.splice(
			this.collaboratorEmails.findIndex((collaborator) => collaborator.email === emailToRemove),
			1
		);
	}

	public checkValidity () {
		if (this.inviteForm.value.newCollaboratorEmail === '') {
			this.inviteForm.controls.newCollaboratorEmail.markAsPristine();
			this.inviteForm.controls.newCollaboratorEmail.markAsUntouched();
			this.inviteForm.controls.newCollaboratorEmail.setErrors({ required: true });
		}
	}

	// goSidePanel can close on backdrop click but not escape
	// we also need to prompt for user confirmation on changes so do it here
	private listenGlobalEvents () {
		const closeOnEscapeKeyHandler = ($event: KeyboardEvent) => {
			if ($event.key === 'Escape') {
				this.cancel();
			}
		};

		const closeOnClickOutsideHandler = ($event: PointerEvent) => {
			const element = $event.target as HTMLElement;
			if (element.closest('.cdk-overlay-backdrop')) {
				this.cancel();
			}
		};

		const keydownEventDestroyFn = this.renderer2.listen(window, 'keydown', closeOnEscapeKeyHandler);
		const mousedownEventDestroyFn = this.renderer2.listen(window, 'mousedown', closeOnClickOutsideHandler);

		this.componentDestroyed$$.subscribe({
			complete: () => {
				keydownEventDestroyFn();
				mousedownEventDestroyFn();
			}
		});
	}
}
