import { clientSettings } from '../common/client.settings';
import { UseType } from './use-type.interface';

/* @ngInject */
export function UseTypeModelFactory ($resource) {

	const baseUrl = `${clientSettings.GoReactV2API}/useTypes/:use_type_id`;

	const UseTypeModel = $resource(baseUrl, {use_type_id: '@use_type_id'}, {update: {method: 'PUT'}});

	UseTypeModel.ACTIVE = 'Active';
	UseTypeModel.CUSTOM = 'Custom';

	UseTypeModel.TYPES = {
		OTHER: 'other'
	};

	UseTypeModel.prototype.save = function () {
		return this.use_type_id > 0 ? this.$update() : this.$save();
	};

	/**
	 * groupByCategory
	 *
	 * @returns {UseType[]}
	 */
	UseTypeModel.groupByCategory = function (useTypes: UseType[]) {
		const groupedTypes = [];
		useTypes.forEach((type) => {
			if(!groupedTypes.find((item) => item.title === type.category)) {
				groupedTypes.push({title: type.category, isGroup: true});
			}
			groupedTypes.push(type);
		});
		return groupedTypes;
	};

	return UseTypeModel;
}
