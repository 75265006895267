import template from './user-activity-history.directive.html';
import { UserActivityHistoryController as controller } from './user-activity-history.controller';

export function userActivityHistoryDirective () {
	return {
		require: 'E',
		template,
		scope: {
			events: '=',
			categorized: '=',
			filter: '=',
			expanded: '='
		},
		bindToController: true,
		controllerAs: 'uahController',
		controller
	};
}
