import { UploadManagerService } from 'go-modules/services/upload-manager/upload-manager.service';
import { Destination, DestinationType } from '../destination';
import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';

// 5 seconds
const SPLIT_TIME_SIZE = 5_000;

export class MediaRecorderDestination extends Destination {
	public type: DestinationType = Destination.TYPES.MEDIARECORDER;
	public format: string;
	private stream: MediaStream;
	private mediaRecorder: MediaRecorder;
	private uploadId: number;
	private mediaRecorderOptions: MediaRecorderOptions;
	private currentBlob: Blob;

	constructor (
		private options,
		private uploadManager: UploadManagerService,
		private UADetect: UADetectClass
	) {
		super(options);
	}

	public async init (stream: MediaStream): Promise<void> {
		this.setState(Destination.STATES.INITIALIZING);
		this.currentBlob = null;
		if (this.UADetect.browserDetector.isSafari()) {
			this.mediaRecorderOptions = {};
			this.format = 'mp4';
		} else {
			this.mediaRecorderOptions = { mimeType : 'video/webm\;codecs=vp8' };
			this.format = 'webm';
		}
		this.stream = stream.clone();
		this.mediaRecorder = new MediaRecorder(this.stream, this.mediaRecorderOptions);
		this.mediaRecorder.ondataavailable = (event) => this.onDataAvailable(event);
		this.uploadId = await this.uploadManager
			.createUpload(this.options.params.media_id, this.format, this.options.params.group_id);
		this.setState(Destination.STATES.ACTIVE);
	}

	public async start (): Promise<void> {
		this.setState(Destination.STATES.STARTING);
		this.mediaRecorder.start(SPLIT_TIME_SIZE);
		this.setState(Destination.STATES.RECORDING);
	}

	public async pause (): Promise<void> {
		this.setState(Destination.STATES.PAUSING);
		this.mediaRecorder.pause();
		this.setState(Destination.STATES.PAUSED);
	}

	public async stop (): Promise<void> {
		this.setState(Destination.STATES.STOPPING);
		this.mediaRecorder.stop();
		this.setState(Destination.STATES.STOPPED);
	}

	public async destroy () {
		this.stream.getTracks().forEach((track) => track.stop());
		super.destroy();
	}

	public setVideoSource (_track: MediaStreamTrack): void {
		// Potential solution to changing tracks while recordings
		// Needs to be done for Safari and Firefox Hotswapping
		// https://github.com/meething/StreamSwitcher
	}

	public setAudioSource (_track: MediaStreamTrack): void {}

	public getAudioStream (): MediaStream {
		// Still needs to be updated
		return this.stream;
	}

	public async screenCaptureStart () {}

	public async updateScreenResolution () {}

	public async publishAudio () {}

	public async remoteStreamStats () {}

	private onDataAvailable (blob: BlobEvent) {

		if (this.currentBlob === null) {
			this.currentBlob = blob.data;
		} else {
			this.currentBlob = new Blob([this.currentBlob, blob.data], { type: 'video/webm' });
		}

		const finalPart = this.mediaRecorder.state === 'inactive';
		if (finalPart || this.currentBlob.size > (5 * Math.pow(2, 10) * 1000)) {
			this.uploadManager.addPartAndUpload(this.uploadId, this.currentBlob, finalPart);
			this.currentBlob = null;
		}

	}
}
