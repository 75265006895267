import { Injector, ComponentRef, Injectable } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { GoDialogRef } from '../go-dialog-ref/go-dialog-ref';
import { GO_MODAL_DATA } from './go-modal.tokens';
import { FocusableElementsUtil } from '../../utilities';
import { timer } from 'rxjs';

interface GoModalConfig {
	data: object;
}

/**
 * A service used to open a provided component in our standard GoReact modal.
 * Upon opening the modal you get a reference to the modal itself.
 */
@Injectable({
	providedIn: 'root'
})
export class GoModalService {
	constructor (private injector: Injector, private overlay: Overlay) {}

	/**
	 * Opens a provided component in a GoReact modal.
	 * The modal automatically sizes based on screen size.
	 *
	 * By default the panel will not close on backdrop click.
	 *
	 * **Usage:**
	 * ```
	 * const dialogRef = modalService.open(MyComponent);
	 * dialogRef.afterClosed().subscribe((result) => {
	 *   // The panel has now closed
	 *   // You can do something with the optional result
	 * })
	 * ```
	 */
	public open<T> (component: ComponentType<T>, closeOnBackdropClick = false, config?: GoModalConfig): GoDialogRef {
		if (!this.hasCdkTrapFocusDirective(component)) {
			throw new Error('Modal component must have cdkTrapFocus directive. Be sure to import A11yModule as well');
		}

		// Returns an OverlayRef which is a PortalHost
		const overlayRef = this.createOverlay();

		// Instantiate remote control
		const dialogRef = new GoDialogRef(overlayRef);

		this.attachDialogContainer<T>(overlayRef, component, dialogRef, config);

		overlayRef.backdropClick().subscribe(() => {
			if (closeOnBackdropClick) {
				dialogRef.close();
			}
		});

		return dialogRef;
	}

	private createOverlay () {
		const overlayConfig = this.getOverlayConfig();
		return this.overlay.create(overlayConfig);
	}

	private attachDialogContainer<T> (
		overlayRef: OverlayRef,
		component: ComponentType<T>,
		dialogRef: GoDialogRef,
		config: GoModalConfig) {
		const injector = this.createInjector(dialogRef, config);

		const containerPortal = new ComponentPortal<T>(component, null, injector);
		const containerRef: ComponentRef<T> = overlayRef.attach(containerPortal);

		this.focusFirstElement(containerRef.location.nativeElement);

		return containerRef.instance;
	}

	private focusFirstElement (element: HTMLElement): void {
		const focusableElement = FocusableElementsUtil.getFirstFocusableElement(element);

		if (focusableElement) {
			timer(1).subscribe(() => focusableElement.focus());
		}
	}

	private hasCdkTrapFocusDirective (component: ComponentType<any>): boolean {
		// video sharing or angular apps
		if (component['ɵcmp']) {
			const constsArray = component['ɵcmp'].consts.flat(Infinity);
			return constsArray.some((item: any) => /cdkTrapFocus/i.test(item));
		}

		// hybrid apps
		const annotations = (component as any).__annotations__;
		if (!annotations || !annotations.length) {
			return false;
		}

		const template = annotations[0].template;
		if (!template) {
			return false;
		}

		return /cdkTrapFocus/i.test(template);
	}

	private createInjector (dialogRef: GoDialogRef, config: GoModalConfig): Injector {
		return Injector.create({
			parent: this.injector,
			providers: [
				{ provide: GoDialogRef, useValue: dialogRef },
				{ provide: GO_MODAL_DATA, useValue: config?.data }
			]
		});
	}

	private getOverlayConfig (): OverlayConfig {
		const positionStrategy = this.overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();

		const overlayConfig = new OverlayConfig({
			hasBackdrop: true,
			backdropClass: 'overlay-backdrop',
			panelClass:  ['overlay-panel-modal', 'goreact-modal'],
			scrollStrategy: this.overlay.scrollStrategies.block(),
			positionStrategy
		});

		return overlayConfig;
	}
}
