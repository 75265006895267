import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import Accessibility from 'highcharts/modules/accessibility';
import { Comment } from 'ngx/go-modules/src/interfaces/comments/comment';
import { Marker } from 'ngx/go-modules/src/interfaces/markers/marker';
import type { PlayerSync } from 'ngx/go-modules/src/interfaces/media/media';
import { FormatTimePipe } from 'ngx/go-modules/src/pipes/format-time/format-time.pipe';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { Subject, takeUntil } from 'rxjs';
Accessibility(Highcharts);

interface SeriesData {
	x: number;
	y: number;
	actualTime: number;
	commenter: string;
	commentText?: string;
	markerName?: string;
	marker?: object;
}


@Component({
	selector: 'feedback-graph',
	template: require('./feedback-graph.component.html'),
	styles: [require('./feedback-graph.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackGraphComponent implements OnInit, OnDestroy {
	@Output() public onExpanded = new EventEmitter();

	@Input() public isExpanded: boolean;
	@Input() public comments: Comment[];
	@Input() public duration: number;
	@Input() public tagsList: Marker[];
	@Input() public playerSync: PlayerSync;

	public Highcharts: typeof Highcharts = Highcharts;
	public chartOptions: Highcharts.Options;
	public markersWithCount: Marker[];
	public commentSeriesData: SeriesData[] = [];
	public markerSeriesData: SeriesData[] = [];
	public feedbackSummary: string;
	private destroyed$ = new Subject();

	@ViewChild('feedbackChart') public chartRef;

	constructor (
		private translate: TranslateService,
		private eventService: EventService,
		private cdr: ChangeDetectorRef
	) {}

	public ngOnInit () {
		this.setChartOptions();

		this.eventService.listen([EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_SAVED])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => {
				// destroy previous chart so it renders correctly
				this.chartRef?.ngOnDestroy();
				// reset seriesData to build it from scratch
				this.commentSeriesData = [];
				this.markerSeriesData = [];
				this.setChartOptions();
			});
	}

	public ngOnDestroy () {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	public setChartOptions () {
		// to handle both short and long videos and make the chart still look good
		// and not just be linear because of small differences in timestamp milliseconds
		// we need to group the comments into 10-25 groups based on the length of the video
		// so we can stack them up if they are in a similar range
		const minGroups = 10;
		const maxGroups = 25;
		const numGroups = Math.min(maxGroups, Math.max(minGroups, Math.ceil(this.duration / 60000)));
		const intervalDuration = this.duration / numGroups;

		this.markersWithCount = JSON.parse(JSON.stringify(this.tagsList));
		this.comments.forEach((comment) => {
			// dont care about endnotes
			if (comment.time === null) {
				return;
			}
			if (comment.resource && comment.resource.length) {
				let isOnlyMarker = false;
				comment.resource.forEach((resource) => {
					if (resource.resource_type === 'Tag') {
						this.markerSeriesData.push({
							x: Math.floor(Number(comment.time) / intervalDuration) * intervalDuration,
							y: 1,
							actualTime: Number(comment.time),
							markerName: resource.item.tag_name,
							commenter: comment.first_name + ' ' + comment.last_name,
							marker: {
								enabled: true,
								fillColor: comment.was_ai_generated ? '#ffffff' : `#${resource.item.tag_color}`,
								lineColor: `#${resource.item.tag_color}`,
								lineWidth: 1
							}
						});
						const matchingMarker = this.markersWithCount.find((m) => m.tag_name === resource.item.tag_name);
						if (matchingMarker) {
							matchingMarker.count = (matchingMarker.count || 0) + 1;
						} else {
							resource.item.count = 1;
							resource.item.is_ai_marker = comment.was_ai_generated;
							this.markersWithCount.push(resource.item);
						}
						if (!comment.text) {
							isOnlyMarker = true;
						}
					}
				});
				if (!isOnlyMarker) {
					this.commentSeriesData.push({
						x: Math.floor(Number(comment.time) / intervalDuration) * intervalDuration,
						y: 1,
						actualTime: Number(comment.time),
						commentText: comment.text,
						commenter: comment.first_name + ' ' + comment.last_name
					});
				}
			} else {
				this.commentSeriesData.push({
					x: Math.floor(Number(comment.time) / intervalDuration) * intervalDuration,
					y: 1,
					actualTime: Number(comment.time),
					commentText: comment.text,
					commenter: comment.first_name + ' ' + comment.last_name
				});
			}
		});
		this.feedbackSummary = this.translate.instant('session-analytics_feedback-graph-summary', {
			markers: this.markerSeriesData.length,
			comments: this.commentSeriesData.length
		});

		this.commentSeriesData.sort((a,b) => {
			return a.actualTime - b.actualTime;
		});
		this.markerSeriesData.sort((a,b) => {
			return a.actualTime - b.actualTime;
		});

		this.markersWithCount.map((marker) => marker.is_ai_marker = marker.is_ai_marker ?? false);
		this.markersWithCount = this.markersWithCount
			.filter((marker) => marker.count > 0)
			.sort((a: any, b: any) => b.is_ai_marker - a.is_ai_marker);

		this.chartOptions = {
			chart: {
				type: 'scatter',
				height: 200,
				plotBackgroundColor: '#F7F7F7'
			},
			title: {
				text: ''
			},
			xAxis: {
				minPadding: 0.025,
				maxPadding: 0.025,
				startOnTick: false,
				lineWidth: 0,
				tickWidth: 0,
				labels: {
					overflow: 'justify',
					// eslint-disable-next-line object-shorthand
					formatter: function () {
						const timePipe = new FormatTimePipe();
						return timePipe.transform(Number(this.value));
					}
				},
				softMin: 0,
				max: this.duration,
				minTickInterval: intervalDuration
			},
			yAxis: {
				minPadding: 0.15,
				maxPadding: 0.15,
				endOnTick: false,
				gridLineWidth: 0,
				title: {
					text: null
				},
				labels: {
					enabled: false
				},
				softMax: 4
			},
			legend: {
				align: 'left',
				verticalAlign: 'top',
				padding: 0
			},
			plotOptions: {
				scatter: {
					stacking: 'normal',
					accessibility: {
						description: this.translate.instant('feedback-graph-description', {markers: this.markerSeriesData.length, comments: this.commentSeriesData.length})
					},
					point: {
						events: {
							click: (e: any) => {
								this.playerSync.seek(e.point.actualTime);
							}
						}
					}
				}
			},
			tooltip: {
				outside: true,
				headerFormat: '',
				formatter: (a) => {
					const point = a.chart.hoverPoint as any;
					const timePipe = new FormatTimePipe();
					let val = timePipe.transform(point.actualTime);
					if (point.markerName) {
						val += `<br/><b>${point.commenter}:</b> ${point.markerName}`;
					}
					if (point.commentText) {
						val += `<br/><b>${point.commenter}:</b> ${point.commentText}`;
					}
					return val;
				}
			},
			series: [{
				type: 'scatter',
				name: this.translate.instant('chart_title-tags'),
				data: this.markerSeriesData,
				marker: {
					symbol: 'circle',
					fillColor: '#929292'
				}
			}, {
				type: 'scatter',
				name: this.translate.instant('comments'),
				data: this.commentSeriesData,
				marker: {
					symbol: 'square',
					fillColor: '#333333'
				}
			}],
			credits: {
				enabled: false
			}
		};

		// since we destroyed the chart before, we need to detect changes to render it again
		this.cdr.detectChanges();
	}
}
