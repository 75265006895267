import * as angular from 'angular';
import * as angularResource from 'angular-resource';
import { featureFlagModule } from 'go-modules/feature-flag';

import { GroupModel } from './group.factory';

export const group = angular.module('go.models.group', [
	angularResource,
	featureFlagModule
])
	.factory('GroupModel', GroupModel)
	.name;
