import template from './go-typeahead-select.component.html';
import { GoTypeaheadSelectController as controller } from './go-typeahead-select.controller';

export const goTypaheadSelectComponent = {
	template,
	controller,
	transclude: {
		itemTemplate: 'goTypeaheadItemTemplate'
	},
	require: {
		ngModelCtrl: '^ngModel'
	},
	bindings: {
		items: '<',
		fuzzySearchKeys: '<',
		selectedItem: '=ngModel',
		onSelect: '&',
		disabled: '<?ngDisabled',
		minCharacterCount: '<',
		placeholder: '@?',
		size: '@?',
		allowCustom: '<'
	}
} as ng.IComponentOptions;

interface TypeaheadTemplateScope extends ng.IScope {
	$item: any;
}

/* @ngInject */
export const transcludeThisScopeDirective = ($interpolate: ng.IInterpolateService): ng.IDirective => {
	return {
		restrict: 'A',
		link: (
			scope: TypeaheadTemplateScope,
			elem: JQLite,
			attrs: ng.IAttributes,
			_ctrl,
			$transclude: ng.ITranscludeFunction
		) => {
			const innerScope = scope.$new() as TypeaheadTemplateScope;
			const slot = attrs.slotName;

			$transclude(innerScope, (goTypeaheadItemTemplate) => {

				const ariaLabel = goTypeaheadItemTemplate.attr('aria-label');
				if (!ariaLabel) {
					throw new Error('GoTypeaheadItemTemplate: Aria Label attribute required');
				}

				innerScope.$item = scope.$item;
				elem.empty();
				elem.attr('aria-label', $interpolate(ariaLabel)(innerScope));
				elem.append(goTypeaheadItemTemplate);
				elem.on('$destroy', () => innerScope.$destroy());
			}, null, slot);
		}
	};
};
