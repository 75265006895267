import * as dayjs from 'dayjs';
import { isDate } from 'lodash';

export function timeagoFilter () {
	return function (date) {
		const input = date;
		if (!input) {
			return;
		}
		if (!isDate(date)) {
			date = dayjs(input).toDate();
		}
		if (!isDate(date)) {
			date = new Date(input * 1000);
		}
		if (!isDate(date)) {
			return 'Invalid Date';
		}
		return dayjs(date).fromNow();
	};
}
