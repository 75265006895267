import template from './umc-zoom.component.html';
import { UniversalMediaChooserZoomController as controller } from './umc-zoom.controller';

export default {
	bindings: {
		options: '<',
		umcInstanceName: '@'
	},
	controller,
	require: {
		umcController: '^universalMediaChooser'
	},
	template
};
