export enum MediaSource {
	WOWZA = 'wowza',
	RED5 = 'red5',
	OPENTOK = 'opentok',
	MEDIARECORDER = 'media_recorder',
	FINEUPLOADER = 'fineuploader',
	YOUTUBE = 'youtube',
	BBB = 'bbb',
	ZOOM_UPLOAD = 'zoom_upload',
	ZOOM_IMPORT = 'zoom_import'
}
