import { INgModelController } from 'angular';
import { NgxGroupService } from 'ngx/go-modules/src/services/group/group.service';

interface Bindings {
	labelText: string;
	showPopover: boolean;
	activity: any;
	group: any;
	onChange: any;
	isRootUser: boolean;
	disabled: boolean;
	inline: boolean;
	ngModelController: INgModelController;
}

export class SessionActivitySelectorController implements Bindings {
	public labelText: string;
	public showPopover: boolean;
	public activity: any;
	public group: any;
	public onChange: any;
	public isRootUser: boolean;
	public disabled: boolean;
	public inline: boolean;
	public ngModelController: INgModelController;

	private activities: any[];
	private ngOptionsArg: string;

	/* @ngInject */
	constructor (
		private $filter: any,
		private $scope: any,
		private ActivityModel,
		private ngxGroupService: NgxGroupService
	) {}

	public $onInit () {
		this.ngxGroupService.getActivities({
			folder_id: this.group.group_id,
			availability_date: null,
			due_date: null
		})
			.subscribe((activities) => {
				this.activities = this.$filter('activityFilter')(
					activities.map((activity) => this.ActivityModel.model(activity)),
					this.group,
					true
				).sort((a, b) => {
					return a.name.localeCompare(b.name);
				});

				this.ngOptionsArg = this.activityMoverOptions();
				this.$scope.$evalAsync();
			});
	}

	private activityMoverOptions (): string {
		if (this.isRootUser) {
			return `activity.name for activity in $ctrl.activities
			 | orderBy: 'sort_index'
			 track by activity.activity_id`;
		}

		// We need to make sure that we only prevent moving between different typed
		// activities when the session in question is not on the default activity.
		// A session is on the default activity when a student transfers from one
		// course to another, and so when it's in this state, we need to let them
		// move it anywhere.
		return `activity.name disable when
			(activity.activity_template_id != $ctrl.activity.activity_template_id &&
				!$ctrl.activity.isDefault())
			for activity in $ctrl.activities |
			orderBy: 'sort_index' track by activity.activity_id`;
	}

	/**
	 * Handle input change event.
	 *
	 * Setting the view value also ensures that the callback
	 * affiliated with an `ngChange` directive will get invoked.
	 */
	private handleChange (): void {
		this.ngModelController.$setViewValue(this.activity);
	}
}
