export interface Bindings {
	message: string;
	title: string;
	rememberText?: string;
	rejectBtnText?: string;
	resolveBtnClass?: string;
	resolveBtnText?: string;
	rejectBtnClass?: string;
}

export interface Scope extends ng.IScope {
	options: {
		remember: boolean;
	};
}

export class MessageRememberingModalController implements Bindings {
	public message: string;
	public title: string;
	public rememberText: string;
	// Reject btn is optional
	public rejectBtnText?: string;
	public resolveBtnClass: string;
	public resolveBtnText: string;
	public rejectBtnClass: string;

	/* @ngInject */
	constructor () {}

	public $onInit () {
		if (!this.message) {
			throw new Error('MessageRememberingModalController:: must supply a message and title');
		}

		this.rememberText = this.rememberText || 'modal-message-remember_remember-text';
		this.resolveBtnClass = this.resolveBtnClass || 'primary-btn';
		this.resolveBtnText = this.resolveBtnText || 'common_close';
		this.rejectBtnClass = this.rejectBtnClass || 'secondary-btn';
	}
}
