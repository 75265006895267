import {
	AfterViewInit,
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	ElementRef,
	EmbeddedViewRef,
	Input,
	OnDestroy,
	Renderer2,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import { TeaseWallContext } from './tease-wall.context';
import { BehaviorSubject, Observable, Subject, combineLatest, of, timer, isObservable } from 'rxjs';
import { delayWhen, filter, switchMap, takeUntil } from 'rxjs/operators';
import { TeaseWallComponent } from './component/tease-wall.component';
import { Injector } from '@angular/core';
import { TEASE_DATA_LOADING, TEASE_WALL_BLUR_CLASS, TEASE_WALL_BLUR_CLASS_SELECTOR, TEASE_WALL_CONFIG, TEASE_WALL_HIDDEN, TEASE_WALL_INDEX_ATTRIBUTE, TEASE_WALL_WRAPPER_CLASS } from './constants';
import type { TeaseWallConfig } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.config';

@Directive({
	selector: '[teaseWall]'
})
export class TeaseWallDirective<TData> implements OnDestroy, AfterViewInit {
	private _context: TeaseWallContext<TData>;
	private ofData: Observable<TData>;
	private config: TeaseWallConfig;
	private hideTeaseWall$$ = new BehaviorSubject(false);
	private isLoading$$ = new BehaviorSubject(true);
	private configReady$$ = new Subject();
	private ofDataReady$$ = new Subject();
	private afterViewInit$$ = new Subject<boolean>();
	private directiveDestroyed$$ = new Subject();
	private data: TData;
	private dataChanged = false;
	private teaseWallComponentRef: ComponentRef<TeaseWallComponent>;
	private hideTeaseWallPreviousValue = false;

	constructor (private templ: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private elementRef: ElementRef,
		private renderer2: Renderer2,
		private injector: Injector,
		private componentFactoryResolver: ComponentFactoryResolver
	) {

		combineLatest([
			this.configReady$$,
			this.ofDataReady$$,
			this.afterViewInit$$,
			this.hideTeaseWall$$
		])
			.pipe(
				takeUntil(this.directiveDestroyed$$),
				filter(
					([_config, _ofData, afterViewInit, hideTeaseWall]) => {
						// Check if the tease wall already exists to prevent re-subscribing to the same data
						// when toggling tease wall visibility
						if (this.teaseWallComponentRef && this.hideTeaseWallPreviousValue !== hideTeaseWall) {
							this.hideTeaseWallPreviousValue = hideTeaseWall;
							this.isLoading$$.next(false);
							return false;
						}

						return afterViewInit;
					}),
				switchMap(() => {
					this.isLoading$$.next(true);
					// We need to render the view without waiting for the data to be loaded first
					if(!this.data) this.updateView();

					if (this.data && !this.dataChanged) return of(this.data);

					return this.ofData ? this.ofData: of(true);
				})
			)
			.subscribe((data: TData) => {
				this.isLoading$$.next(false);
				this.dataChanged = false;
				this.data = data;
				this.updateView();
			});
	}

	public ngAfterViewInit (): void {
		// If ofData is not supplied to the directive at this point,
		// we can safely assume that the view is ready to be updated
		if (!this.ofData) this.ofDataReady$$.next(true);

		this.afterViewInit$$.next(true);
	}

	public ngOnDestroy (): void {
		this.directiveDestroyed$$.next(true);
		this.directiveDestroyed$$.complete();
		this.configReady$$.complete();
		this.ofDataReady$$.complete();
	}

	@Input()
	public set teaseWallHide (hide: boolean) {
		this.hideTeaseWall$$.next(hide);
	}

	@Input()
	public set teaseWall (config: TeaseWallConfig) {
		this.dataChanged = true;
		this.config = config;
		this.configReady$$.next(true);
	}

	public get teaseWall () {
		return this.config;
	};

	/**
	 * This directive can potentially update the view before the elements we want to blur are rendered (ex: *ngIf)
	 * This "of" input:
	 * 1. Gives the directive a way to know when to update the view
	 *     - Ex: <section *teaseWall="aiMarkerTeaseConfig; of: loading$">
	 * 2. Extract the value from the observable/data so that it can be used in the component that uses this directive
	 *     - Ex: <section *teaseWall="aiMarkerTeaseConfig; let selectedAnalytic of selectedAnalytic$">
	 */
	@Input()
	public set teaseWallOf (data: TData | Observable<TData> | undefined) {
		this.dataChanged = true;
		this.ofData = isObservable(data) ? data : of(data);
		this.ofDataReady$$.next(true);
	}

	private updateView () {
		this._context = new TeaseWallContext(this.data, this.teaseWall);

		// Check and retrieve existing embedded view
		let embeddedViewRef =
			this.viewContainer.length > 0 ?
				this.viewContainer.get(0) as EmbeddedViewRef<TeaseWallContext<TData>> : null;

		// Do not clear the view if it already exists. It will cause visual UI flickers and other unexpected behaviors
		// depending on the component this directive is attached to.
		if (!embeddedViewRef) {
			embeddedViewRef = this.viewContainer.createEmbeddedView(this.templ, this._context);
		} else {
			embeddedViewRef.context = {...this._context};
		}

		if (!this.config.useRealData && !this.teaseWallComponentRef) {
			const factory = this.componentFactoryResolver.resolveComponentFactory(TeaseWallComponent);
			this.teaseWallComponentRef = this.viewContainer.createComponent(factory, undefined, this.createInjector());

			// Insert tease wall as first/last child element to have control over default browser tab behavior
			if (this.config?.insertAfterChildren === true) {
				this.renderer2.appendChild(
					this.viewElement,
					this.teaseWallComponentRef.instance.elementRef.nativeElement
				);
			} else {
				this.renderer2.insertBefore(
					this.viewElement,
					this.teaseWallComponentRef.instance.elementRef.nativeElement,
					this.viewElement.firstElementChild
				);
			}

			this.teaseWallComponentRef.changeDetectorRef.detectChanges();
		} else if (!this.config.useRealData && this.teaseWallComponentRef) {
			// If tease wall already created, but we want to show another tease wall based on the updated config
			// Ex: Purchase -> Upgrade Tease Wall Transition
			this.teaseWallComponentRef.instance.config = this.config;
			this.teaseWallComponentRef.instance.initCurrentConfig();
			this.teaseWallComponentRef.changeDetectorRef.detectChanges();
		}

		embeddedViewRef.detectChanges();
		this.applyClass();
	}

	private applyClass () {
		const selector = this.config.select.join(', ');
		if (!this.config.select.length) return;

		if (this.config.useRealData) {
			this.viewElement.classList.remove(TEASE_WALL_WRAPPER_CLASS);
			this.viewElement
				.querySelectorAll(TEASE_WALL_BLUR_CLASS_SELECTOR)
				.forEach((element: HTMLElement) => {
					element.classList.remove(TEASE_WALL_BLUR_CLASS);
					const index = element.getAttribute(TEASE_WALL_INDEX_ATTRIBUTE);

					if (index) {
						element.tabIndex = +index;
					}
				});

			// If switching from fakeData to realData, we need to remove the existing tease-wall from the DOM
			if (this.teaseWallComponentRef) {
				this.renderer2.removeChild(this.viewElement, this.teaseWallComponentRef.location.nativeElement);
				this.teaseWallComponentRef.destroy();
				this.teaseWallComponentRef = null;
			}

			return;
		}

		this.viewElement.classList.add(TEASE_WALL_WRAPPER_CLASS);
		this.viewElement
			.querySelectorAll(selector)
			.forEach((element: HTMLElement) => {
				element.classList.add(TEASE_WALL_BLUR_CLASS);

				if (element.tabIndex > -1) {
					element.setAttribute(TEASE_WALL_INDEX_ATTRIBUTE, element.tabIndex.toString());
				}

				element.tabIndex = -1;
			});
	}

	/**
	 * The element adjacent to the tease wall (the parent element of the components we want to blur)
	 */
	private get viewElement () {
		return  this.elementRef.nativeElement.nextElementSibling;
	}

	private createInjector (): Injector {
		return Injector.create({
			parent: this.injector,
			providers: [{
				provide: TEASE_WALL_CONFIG,
				useValue: this.config
			},
			{
				provide: TEASE_DATA_LOADING,
				useValue: this.isLoading$$.asObservable()
			},
			{
				provide: TEASE_WALL_HIDDEN,
				useValue: this.hideTeaseWall$$.asObservable()
					.pipe(delayWhen((hide) => {
						return this.config.showDelay && !hide ? timer(this.config.showDelay) : timer(0);
					}))
			}]
		});
	}
}
