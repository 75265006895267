export function TagEditorErrorFactory () {
	function TagEditorError (code, message) {
		this.code = code;
		this.message = message;
		this.stack = (new Error()).stack;
	}

	TagEditorError.prototype = new Error();

	return TagEditorError;
}
