import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

export class RubricCheckboxController {

	public element: any;
	public isChecked: boolean;
	public checkboxId: string;

	/* @ngInject */
	constructor (
		private $scope: any,
		private eventService: EventService
	) {}

	public $onInit () {
		this.element = this.$scope.element;
		this.isChecked = this.setChecked();
		this.checkboxId = 'rb-checkbox-input-' + this.element.id;
	}

	/**
	 * Handles input change event
	 */
	public onInputChange () {
		if (this.isChecked) {
			if (this.element.value == null || this.element.value === '') {
				this.element.points = 0;
			} else {
				this.element.points = parseFloat(this.element.value);
			}
		} else {
			this.element.points = null;
		}

		// emit change event
		this.eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);
	};

	private setChecked (): boolean {
		return !isNaN(parseFloat(this.element.points));
	}
}
