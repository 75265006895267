interface Bindings {
	banners: any[];
}

export class GoBannerListController implements Bindings {
	public banners: any[];

	/* @ngInject */
	constructor (private goBannerHelper) {
	}

	public $onInit () {
	}
}
