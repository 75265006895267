import template from './stimulus-video-viewer.component.html';
import { StimulusVideoViewerController as controller } from './stimulus-video-viewer.controller';

export const stimulusVideoViewer = {
	template,
	require: {
		mainController: '^feedbackSession',
		multiMediaDisplayController: '^multiMediaDisplay'
	},
	controller,
	bindings: {media: '<ngModel'}
};
