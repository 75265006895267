import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { sessionModel } from '../session';

import { UserActivityHistoryModel } from './user-activity-history.factory';
import { userActivityHistoryModelTransformer } from './user-activity-history.service';

export const userActivityHistoryModel = angular.module('go.models.userActivityHistory', [
	angularResource,
	sessionModel
])
	.factory('UserActivityHistoryModel', UserActivityHistoryModel)
	.service('userActivityHistoryModelTransformer', userActivityHistoryModelTransformer)
	.name;
