import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NgxLoggerService {
	constructor (private errorHandler: ErrorHandler) {}

	public log (value: any, ...rest: any[]) {
		// eslint-disable-next-line no-console
		console.log(value, ...rest);
	}

	public error (error: Error) {
		this.errorHandler.handleError(error);
	}

	public warn (value: any, ...rest: any[]) {
		// eslint-disable-next-line no-console
		console.warn(value, ...rest);
	}
}
