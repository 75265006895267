import { ActivityInfoInstructionsController as controller } from './instructions.controller';
import template from './instructions.component.html';

/* @ngInject */
export const activityInfoInstructionsComponent = {
	template,
	controller,
	bindings: {
		kind: '@',
		text: '<'
	}
} as ng.IComponentOptions;
