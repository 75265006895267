import { isObject, isString } from 'lodash';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';

/* @ngInject */
export function TagEditorListController (
	$scope,
	tagEditor,
	TagModel,
	TagEditorError,
	eventService: EventService,
	$timeout: ng.ITimeoutService
) {
	const vm = this;

	vm.$onInit = () => {
		/**
		 * Sortable options
		 */
		vm.sortableOptions = {
			filter: '.no-drag',
			ghostClass: '.tag-placeholder',
			handle: '.tag-handle',
			animation: 100,
			disabled: !isObject($scope.options) || !$scope.options.editing,
			onUpdate () {
				$scope.tagSet.tags.forEach(vm.updateTag);
			}
		};

		$timeout(() => {
			vm.updateTagSetValidity();
		});
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	vm.tagNameRequired = (): boolean => {
		return $scope.tagSet.tags.length === 0 ||
			(!$scope.tagEditor.tagModel.tag_name && (
				!!$scope.tagEditor.tagModel.tag_abbreviation ||
				!!$scope.tagEditor.tagModel.tag_preset_comment
			));
	};

	/**
	 * Get tag by name
	 *
	 * @param tagName
	 * @returns {*}
	 */
	 vm.getTagByName = function (tagName) {
		const foundTag = $scope.tagSet.tags.find((tag) =>
			isString(tag.tag_name)
			&& tag.tag_name.toLowerCase() === tagName.toLowerCase()
		);
		return foundTag || false;
	};

	vm.updateTagSetValidity = () => {
		// Tags are valid as long as there is one present and the new tag name
		// field is empty
		vm.newTagForm.name.$setValidity('tags', $scope.tagSet.tags.length > 0 && !$scope.tagEditor.tagModel.tag_name);
	};

	vm.updateTagFormValidity = () => {
		vm.newTagForm.$setPristine();
	};

	/**
	 * Add a tag
	 *
	 * @param tag
	 * @returns {object}
	 */
	vm.addTag = function (tag) {
		if (!tag.tag_name) {
			throw new TagEditorError(1, 'Tag must have a name');
		} else if (vm.getTagByName(tag.tag_name)) {
			throw new TagEditorError(3, 'Tag already exists');
		}

		tag = TagModel.model(tag);
		tag.tag_set_id = $scope.tagSet.id;
		tag.sort_index = 0;

		$scope.tagSet.tags.unshift(tag);
		vm.updateTagSetValidity();
		vm.updateTagFormValidity();
		return tag;
	};

	/**
	 * Update a tag
	 *
	 * @param tag
	 * @returns {object}
	 */
	vm.updateTag = function (tag) {
		tag.sort_index = $scope.tagSet.tags.indexOf(tag);

		eventService.broadcast(EVENT_NAMES.TAG_EDITOR_TAG_UPDATED, tag);

		return tag;
	};

	/**
	 * Remove a tag
	 *
	 * @param tag
	 * @returns {object}
	 */
	vm.removeTag = function (tag) {
		const tags = $scope.tagSet.tags;
		const index = tags.indexOf(tag);
		tags.splice(index, 1);
		eventService.broadcast(EVENT_NAMES.TAG_EDITOR_TAG_UPDATED, tag);
		vm.updateTagSetValidity();
	};

	/**
	 * Whether or not to show tag creator
	 *
	 * @returns {boolean}
	 */
	vm.showTagCreator = function () {
		return Array.isArray($scope.tagSet.tags) && $scope.tagSet.tags.length < tagEditor.defaults.maxNumTags;
	};

	/**
	 * Mode change event handler
	 */
	vm.eventSubscription = eventService.events
		.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.TAG_EDITOR_MODE_CHANGE))
		.subscribe((ev: GoEvent) => {
			vm.sortableOptions.disabled = !ev.data;
		});

}
