import * as angular from 'angular';
import { event } from '../event-channel';
import { goGlobalEventService } from './go-global-event.service';

export const globalEvent = angular.module('go.global-event', [
	event
])

// For now, collect event names here so consumers dont need to depend on
// each others services to get these unique event names (Keep it DRY)

	// GoReactHeartbeatEvent
	// Triggered whenever something is "happening" in goreact.
	// Either the user is moving the mouse, typing on the keyboard,
	// watching a video, recording a video, uploading a file, etc.
	.constant('GoReactHeartbeatEvent', 'GOREACT_HEARTBEAT_EVENT')
	.service('GoGlobalEvent', goGlobalEventService)
	.name;
