import * as angular from 'angular';
import 'ngx/go-modules/src/services/grab-to-pan/style.css';
import { GrabToPan } from 'ngx/go-modules/src/services/grab-to-pan/index';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';
import template from './go-doc-viewer.directive.html';

/* @ngInject */
export function goDocViewer (
	UADetect,
	$document: ng.IDocumentService,
	eventService: EventService,
	$timeout
) {

	const isolateScope = {
		media: '=',
		onPageChange: '&',
		initialPage: '=',
		options: '<'
	};

	const increase = 0.1;

	const isMobile = UADetect.isMobile();

	function link (scope, elem: ng.IAugmentedJQuery) {

		// Add arrow listener for paging
		// global keydown listener
		function eventBroadcast (evt) {
			scope.$apply(function () {
				eventService.broadcast(EVENT_NAMES.KEY_DOWN_EVENT, evt);
			});
		}
		elem.on('keydown', eventBroadcast);

		scope.hasPages = scope.media.filename.split('.').pop().toLowerCase() === 'pdf';

		scope.toggleFullscreen = () => {
			scope.loading = true;
			let request;
			if (scope.fullscreenActive()) {
				request = ($document[0].exitFullscreen || $document[0].webkitExitFullscreen).bind($document[0])();
			} else {
				request = (elem[0].requestFullscreen || elem[0].webkitRequestFullscreen).bind(elem[0])({navigationUI: 'show'});
			}
			// safari doesnt return a promise so need to timeout instead
			if (request) {
				request.finally(() => {
					scope.$evalAsync(() => {
						scope.loading = false;
					});
				});
			} else {
				$timeout(() => {
					scope.loading = false;
				}, 100);
			}
		};

		scope.fullscreenActive = () => {
			return $document[0].fullscreenElement || $document[0].webkitFullscreenElement;
		};

		scope.onPageRender = function (page, numPages, prevPage) {
			elem[0].focus(); // for easy page switching
			scope.page = page;
			scope.numPages = numPages;
			scope.rendered = true;

			// If page changes fire page change event
			if (page !== prevPage) {
				scope.onPageChange({
					$event: {
						page,
						numPages,
						prevPage
					}
				});
			}
		};

		scope.scaleOffset = 0;

		// ensure panning works on mobile
		scope.isMobile = isMobile;

		// set image dimensions if image is found
		let origHeight, origWidth, g2p: GrabToPan;
		scope.setUpImage = function () {
			const img = elem[0].querySelector('img.scale-img') as HTMLImageElement;
			const container = elem[0].querySelector('.img-viewer') as HTMLElement;
			if (img) {
				const height = img.naturalHeight;
				const width = img.naturalWidth;
				const containerWidth = container.offsetWidth;
				const containerHeight = container.offsetHeight;
				const imgRatio = height / width;

				// grow horizontally
				const ngImg = angular.element(img);
				if (Math.floor(containerWidth * imgRatio) < containerHeight) {
					origHeight = Math.floor(containerWidth * imgRatio);
					origWidth = containerWidth;
					ngImg.css('width', origWidth + 'px');
					ngImg.css('height', origHeight + 'px');
				} else {
					origWidth = Math.floor(containerHeight * (1 / imgRatio));
					origHeight = containerHeight;
					ngImg.css('height', origHeight + 'px');
					ngImg.css('width', origWidth + 'px');
				}
			}
		};

		// zoom functions for images
		scope.zoom = function (zoomIn) {
			const imgElem = elem[0].querySelector('img.scale-img') as HTMLImageElement;
			const container = elem[0].querySelector('.img-viewer') as HTMLElement;

			g2p = g2p ? g2p : new GrabToPan({element: container});

			g2p.activate();

			const direction = zoomIn ? 1 : -1;
			scope.scaleOffset += direction;
			const change = direction * increase;

			const ngImgElem = angular.element(imgElem);
			const height = parseInt(ngImgElem.css('height'), 10) + parseInt(ngImgElem.css('height'), 10) * change;
			const width = parseInt(ngImgElem.css('width'), 10) + parseInt(ngImgElem.css('width'), 10) * change;

			if (height < 0 || width < 0) {
				return;
			}

			ngImgElem.css('height', height + 'px');
			ngImgElem.css('width', width + 'px');

			// try to keep vertically centered
			if (scope.scaleOffset > 0 && container.offsetHeight > height) {
				ngImgElem.css('margin-top', (container.offsetHeight - height) / 2 + 'px');
			} else {
				ngImgElem.css('margin-top', 0);
			}
		};

		scope.fit = function () {
			const imgElem = angular.element(elem[0].querySelector('img.scale-img'));
			imgElem.css('height', origHeight + 'px');
			imgElem.css('width', origWidth + 'px');
			imgElem.css('margin-top', 0);
			scope.scaleOffset = 0;
		};

		if (!scope.hasPages) {
			scope.eventNames = [
				EVENT_NAMES.DOC_FIT,
				EVENT_NAMES.DOC_ZOOM_IN,
				EVENT_NAMES.DOC_ZOOM_OUT,
				EVENT_NAMES.DOC_RESIZE
			];
			scope.eventSubscription = eventService.events
				.pipe(filter((ev: GoEvent) => scope.eventNames.includes(ev.name)))
				.subscribe((ev: GoEvent) => {
					switch (ev.name) {
						case EVENT_NAMES.DOC_FIT:
							scope.fit();
							break;
						case EVENT_NAMES.DOC_ZOOM_IN:
							scope.zoom(true);
							break;
						case EVENT_NAMES.DOC_ZOOM_OUT:
							scope.zoom(false);
							break;
						case EVENT_NAMES.DOC_RESIZE:
							scope.setUpImage();
							break;
					}
				});
		}

		// on destroy
		scope.$on('$destroy', function () {
			elem.off('keydown', eventBroadcast);
			scope.eventSubscription?.unsubscribe();
		});
	}

	return {
		scope: isolateScope,
		link,

		/* @ngInject */
		controller: function controler ($scope) {

			// zoom functions for pdfs
			this.register = function (doc) {

				// listen for advancing with keys
				this.eventNames = [
					EVENT_NAMES.KEY_DOWN_EVENT,
					EVENT_NAMES.DOC_FIT,
					EVENT_NAMES.DOC_ZOOM_IN,
					EVENT_NAMES.DOC_ZOOM_OUT,
					EVENT_NAMES.DOC_RESIZE
				];
				this.eventSubscription = eventService.events
					.pipe(filter((ev: GoEvent) => this.eventNames.includes(ev.name)))
					.subscribe((ev: GoEvent) => {
						switch (ev.name) {
							case EVENT_NAMES.KEY_DOWN_EVENT:
								const KEY_RIGHT = 39,
									KEY_LEFT = 37;
								if (ev.data.keyCode === KEY_RIGHT) {
									$scope.go('next');
								}

								if (ev.data.keyCode === KEY_LEFT) {
									$scope.go('prev');
								}
								break;
							case EVENT_NAMES.DOC_FIT:
								$scope.fit();
								break;
							case EVENT_NAMES.DOC_ZOOM_IN:
								$scope.zoom(true);
								break;
							case EVENT_NAMES.DOC_ZOOM_OUT:
								$scope.zoom(false);
								break;
							case EVENT_NAMES.DOC_RESIZE:
								$scope.setUpImage();
								break;
						}
					});

				$scope.go = function (direction) {
					if (direction === 'next') {
						doc.next();
					} else {
						doc.prev();
					}
				};
				$scope.fit = function () {
					$scope.scaleOffset = 0;
					doc.fit();
				};
				$scope.zoom = function (zoomIn) {
					$scope.scaleOffset = zoomIn ? $scope.scaleOffset + 1 : $scope.scaleOffset - 1;
					if (zoomIn) {
						doc.zoomIn();
					} else {
						doc.zoomOut();
					}
				};
			};
		},
		template
	};
}
