import { clientSettings } from 'go-modules/models/common/client.settings';

/* @ngInject */
export function MediaVTTFactory ($resource) {
	const baseUrl = `${clientSettings.GoReactV2API}/media/:media_id/media_vtt/:id`;

	const MediaVTT = $resource(baseUrl, {
		id: '@id',
		media_id: '@media_id'
	});

	MediaVTT.TYPE = {
		CAPTION: 'caption',
		THUMBNAIL: 'thumbnail'
	};

	MediaVTT.EXT = {
		VTT: '.vtt'
	};

	/**
	 * Transform media to media vtt
	 *
	 * @param media
	 * @returns {MediaVTT}
	 */
	MediaVTT.transformMediaToMediaVttCaption = function (media) {
		const mediaVtt = {
			file: media.filename,
			media_id: media.media_id,
			media_url: media.media_url,
			type: {
				name: MediaVTT.TYPE.CAPTION
			}
		};

		return MediaVTT.newInstance(mediaVtt);
	};

	/**
	 * Creates a new media vtt instance
	 *
	 * @param data
	 * @returns {MediaVTT}
	 */
	MediaVTT.newInstance = function (data) {
		return new MediaVTT(data || {});
	};

	/**
	 * Returns the media vtt id
	 *
	 * @returns {Number}
	 */
	MediaVTT.prototype.getId = function () {
		return this.id;
	};

	/**
	 * Whether this media vtt is of type `caption`
	 *
	 * @returns {boolean}
	 */
	MediaVTT.prototype.isCaption = function () {
		return this.media_vtt_type_name === MediaVTT.TYPE.CAPTION ||
			this.type?.name === MediaVTT.TYPE.CAPTION;
	};

	/**
	 * Whether this media vtt is of type `thumbnail`
	 *
	 * @returns {boolean}
	 */
	MediaVTT.prototype.isThumbnail = function () {
		return this.type.name === MediaVTT.TYPE.THUMBNAIL;
	};

	return MediaVTT;
}
