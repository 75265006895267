/* @ngInject */
const ariaChipsDecoratorFn = (
	$delegate: ng.IDirective[],
	$timeout: ng.ITimeoutService) => {

	const $ariaChips = $delegate[0];

	// Monkey patch it
	$ariaChips.compile = ((oldCompile) => {
		return function newCompile (tElem: ng.IAugmentedJQuery, tAttrs: ng.IAttributes) {
			// Get the old linkingFn
			const oldLinkingFn = oldCompile.call(this, tElem, tAttrs);

			// Now monkey patch that
			return function newLinkingFn (scope: ng.IScope, elem: ng.IAugmentedJQuery, attr: ng.IAttributes, mdCtrls) {
				oldLinkingFn.call(this, scope, elem, attr, mdCtrls);

				if (elem[0].querySelector('.md-chip-content')) {
					$timeout(() => {
						const chipText = elem[0].querySelector('.md-contact-name').textContent;
						const defaultAriaAttr = elem[0].querySelector('.md-chip-content').getAttribute('aria-label');
						elem[0].querySelector('.md-chip-content').setAttribute('aria-label', chipText.trim() + ', ' + defaultAriaAttr);
					});
				}
			};
		};
	})($ariaChips.compile);
	return $delegate;
};

export const ariaChipsDecorator: [string, ng.Injectable<Function>] = [
	'mdChipDirective',
	ariaChipsDecoratorFn
];
