import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Observable } from 'rxjs';
import type { Activity } from 'ngx/go-modules/src/interfaces/activity';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import { activityToken } from 'go-modules/models/activity/activity.factory';
import { PrivacyLevel } from 'go-modules/models/activity';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { ActivityValidationResult, SessionValidationResult } from 'go-modules/models/group-dep/group.factory';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { ActivityEditorPanel, activityEditorPanelToken } from 'go-modules/activity-editor-panel/activity-editor-panel.service';

@Injectable({
	providedIn: 'root'
})
export class NgxActivityService {
	constructor (
		private http: HttpClient,
		private translate: TranslateService,
		private featureFlag: NgxFeatureFlagService,
		@Inject(selectedServiceToken) public selectedService: SelectedService,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(uaDetectToken) public UADetect: UADetectClass,
		@Inject(activityToken) public activityFactory,
		@Inject(activityEditorPanelToken) private activityEditorPanel: ActivityEditorPanel
	) {}

	public get (activityId: number): Observable<Activity> {
		return this.http.get<Activity>(`${clientSettings.GoReactV2API}/activities/${activityId}`);
	}

	public getDefault (groupId: number): Observable<Activity> {
		return this.http.get<Activity>(`${clientSettings.GoReactV2API}/groups/${groupId}/activities/default`);
	}

	public getStats (groupId: number): Observable<any[]> {
		return this.http.get<any[]>(`${clientSettings.GoReactV2API}/groups/${groupId}/activity-stats`);
	}

	public restore (activityId: number): Observable<any> {
		return this.http.patch(`${clientSettings.GoReactV2API}/activities/${activityId}/restore`, {});
	}

	public archive (activityId: number): Observable<any> {
		return this.http.patch(`${clientSettings.GoReactV2API}/activities/${activityId}/archive`, {});
	}

	public deleteActivity (activityId: number): Observable<any> {
		return this.http.delete(`${clientSettings.GoReactV2API}/activities/${activityId}`);
	}

	public updateSort (groupId: number, payload: any): Observable<any> {
		return this.http.put(`${clientSettings.GoReactV2API}/groups/${groupId}/activities/sort`, payload);
	}

	public canStartActivity (): boolean {
		try {
			// This activity check is only present on vertical nav Start Activity buttons
			this.mayStartActivity();
		} catch (error)	{
			return false;
		}

		const group = this.selectedService.getGroup();
		const license = this.selectedService.getLicense();
		return group.validateCanCreateSession(this.userService.currentUser, license).isValid;
	}

	public canEditActivity (activity) {
		const org = this.selectedService.getOrg();
		return !(org.org_settings.block_copied_assignment_editing
			&& !!activity.parent_id
			&& !this.userService.currentUser.is_root_user);
	}

	public openActivityEditor (activity, limitActivityEditor = false) {
		// Activity editor header options
		const headerOptions: any = {
			editMode: false
		};
		// When we are in the LTI environment and in the default
		// mode, we need to limit the activity editor options.
		if (limitActivityEditor) {
			headerOptions.disablePoints = false;
			headerOptions.hideDates = true;
			headerOptions.hideGroups = true;
			headerOptions.hideName = true;
		}

		if (!!activity.activity_id) {
			headerOptions.editMode = true;
		}

		return this.activityEditorPanel.open({
			user: this.userService.currentUser,
			activity,
			group: this.selectedService.getGroup(),
			orgSettings: this.selectedService.getOrg().org_settings,
			headerOptions,
			firstFocusSelector: '#activity-editor-name-input'
		}).result;
	}

	public getSessionCreationDisabledMessage (): string {
		try {
			// This activity check is only present on vertical nav Start Activity buttons
			this.mayStartActivity();
		} catch (error) {
			return error.message;
		}

		const group = this.selectedService.getGroup();
		const license = this.selectedService.getLicense();
		const validationResult: SessionValidationResult =
			group.validateCanCreateSession(this.userService.currentUser, license);

		return validationResult.isValid ? '' : this.translate.instant(validationResult.errorKey);
	}

	private mayStartActivity (): boolean {
		// TODO DEV-15860 Messaging can be incorrect before stats load
		const activity = this.activityFactory.model(this.selectedService.getActivity());
		const group = this.selectedService.getGroup();
		const isOpenPeerReview = activity.getPrivacyLevel() === PrivacyLevel.OPEN_PEER_REVIEW;

		if (activity.isConversation() && this.UADetect.browserDetector.isOldEdge()) {
			throw new Error(this.translate.instant('common_unsupported-feature-try-latest-browser-version'));
		} else if (group.hasReviewerRole()) {
			throw new Error(this.translate.instant('session-creation-error_group-reviewer-may-not-create'));
		} else if (group.hasPresenterRole() && activity.has_single_recording_attempt && activity.have_i_submitted) {
			throw new Error(this.translate.instant('session-creation-error_activity-test-already-submitted'));
		} else if (group.hasPresenterRole() && activity.isCommentOnly() && activity.have_i_submitted) {
			throw new Error(this.translate.instant('session-creation-error_activity-comment-only-already-submitted'));
		} else if (isOpenPeerReview && group.hasInstructorRole(true) && activity.isCommentOnly()) {
			throw new Error(this.translate.instant('session-creation-error_activity-comment-only-instructor'));
		}

		return true;
	}

	public canCreateActivity (): boolean {
		const group = this.selectedService.getGroup();
		const license = this.selectedService.getLicense();
		return group.validateCanCreateActivity(license).isValid;
	}

	public getActivityCreationDisabledMessage (): string {
		const group = this.selectedService.getGroup();
		const license = this.selectedService.getLicense();
		const validationResult: ActivityValidationResult = group.validateCanCreateActivity(license);

		return validationResult.isValid ? '' : this.translate.instant(validationResult.errorKey);
	}
}
