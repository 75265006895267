/* @ngInject */
export function progressBarDirective (
	EventChannel,
	translateFilter: ng.translate.ITranslateFilter,
	$timeout,
	$document) {
	return {
		restrict: 'A',
		require: '^fineUploader',
		scope: true,
		link (scope, element, attrs, uploadController) {
			const progressEventChannel = EventChannel.get(uploadController.getId()),
				fileId = scope.$eval(attrs.fineUploaderProgressBar);
			let scrernReaderDiv: HTMLElement;

			// set width initially to zero
			element.css('width', 0);
			element[0].setAttribute('role', 'progressbar');
			element[0].setAttribute('aria-valuemin', '0');
			element[0].setAttribute('aria-valuenow', '0');
			element[0].setAttribute('aria-valuemax', '100');

			// subscribe to file progress event
			progressEventChannel.subscribe(fileId, onProgress);

			/**
			 * Destroy event handler
			 */
			scope.$on('$destroy', function () {
				progressEventChannel.unsubscribe(fileId, onProgress);
				scrernReaderDiv?.remove();
			});

			/**
			 * On progress event handler
			 *
			 * @param uploadedBytes
			 * @param totalBytes
			 */
			function onProgress (uploadedBytes, totalBytes) {
				const progress = Math.round(uploadedBytes / totalBytes * 100);

				if(progress === 100) {
					scrernReaderDiv = $document[0].createElement('div');
					scrernReaderDiv.classList.add('accessibility-text');
					scrernReaderDiv.classList.add('progress-bar-screen-reader-div');
					scrernReaderDiv.setAttribute('role', 'alert');

					element[0].parentElement.appendChild(scrernReaderDiv);
					$timeout(() => scrernReaderDiv?.remove(), 2000);

					scrernReaderDiv.textContent = translateFilter('fine-uploader_complete');
				} else {
					element[0].setAttribute('aria-valuenow', progress);
				}

				element.css('width', progress + '%');
			}
		}
	};
}
