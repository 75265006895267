import template from './modal.html';
import { InstructionsPreviewModalController } from './modal.controller';

/* @ngInject */
export class InstructionsPreviewModal {

	constructor (private goModalBootstrap) {}

	public open (options): angular.ui.bootstrap.IModalInstanceService {
		options.template = template;
		options.controller = InstructionsPreviewModalController;
		options.windowClass = options.name = 'instructions-preview';
		options.ariaLabelledBy = 'instructions-preview-modal';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.size = 'lg';
		options.goreact = true; // For new modal styles
		return this.goModalBootstrap.open(options);
	}
}
