import template from './feedback-item.directive.html';

export const feedbackTreeItemDirective: ng.IDirectiveFactory = () => {
	return {
		restrict: 'E',
		template,
		controller: 'FeedbackTreeItemController',
		controllerAs: 'feedbackItemController',
		scope: true
	};
};
