import * as angular from 'angular';
import * as uiModal from 'angular-ui-bootstrap/src/modal';
import 'bootstrap-css-only';


import { SidePanelService } from './side-panel.service';

export const SidePanelModule = angular.module('side-panel', [
	uiModal
])
	.service('SidePanel', SidePanelService)
	.name;
