import * as angular from 'angular';
import * as angulartics from 'angulartics';
import * as angularTranslate from 'angular-translate';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as angularAria from 'angular-aria';

import { treeViewModule } from '../../tree-view';
import { goNoUISliderModule } from '../../go-nouislider';
import { universalMediaChooserModule } from '../../universal-media-chooser';
import { userModel } from '../../models/user';
import { groupDepModel } from '../../models/group-dep';
import { sessionModel } from '../../models/session';
import { commentModel } from '../../models/comment';
import { mediaModel } from '../../models/media';

import { feedbackTree } from './feedback-tree.directive';
import { FeedbackTreeItemController } from './feedback-item/feedback-item.controller';
import { feedbackTreeItemDirective } from './feedback-item/feedback-item.directive';
import { FeedbackTreeCommentController } from './feedback-item/comment/comment.controller';
import { feedbackTreeCommentDirective } from './feedback-item/comment/comment.directive';
import { TimeSliderComponent } from './feedback-item/time-slider/time-slider.component';

import { KeyCode } from './feedback-tree.value';
import { keypressEnter } from './directives/keypress.directive';
import { templates } from './templates';
import { allPlayersServiceModule } from 'ngx/go-modules/src/services/all-players';

export const feedbackTreeModule = angular.module('go.feedbackTree', [
	angulartics,
	angularTranslate,
	uiTooltip,
	angularAria,
	treeViewModule,
	goNoUISliderModule,
	universalMediaChooserModule,
	userModel,
	groupDepModel,
	sessionModel,
	commentModel,
	mediaModel,
	allPlayersServiceModule
])
	.run(templates)
	.component('feedbackTreeItemTimeSlider', TimeSliderComponent)
	.controller('FeedbackTreeItemController', FeedbackTreeItemController)
	.controller('FeedbackTreeCommentController', FeedbackTreeCommentController)
	.directive('feedbackTree', feedbackTree)
	.directive('feedbackTreeItem', feedbackTreeItemDirective)
	.directive('feedbackTreeComment', feedbackTreeCommentDirective)
	.directive('keypressEnter', keypressEnter)
	.value('KeyCode', KeyCode)
	.name;
