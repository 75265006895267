import { EventEmitter } from 'events';
import logger from 'go-modules/logger/logger';
import { StatusOverlayable } from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay.component';

export enum Events {
	STATE_CHANGE = 'STATE_CHANGE'
}

export enum States {
	UNINITIALIZED = 'uninitialized',
	INITIALIZING = 'initializing',
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	FAILED = 'failed',
	STARTING = 'starting',
	RECORDING = 'recording',
	STOPPING = 'stopping',
	STOPPED = 'stopped',
	PAUSING = 'pausing',
	PAUSED = 'paused',
	DESTROYED = 'destroyed',
	MISSING = 'missing'
}

export enum Reasons {
	UNSUPPORTED_BROWSER = 'unsupported_browser',
	CHROME_OS_DOO_DOO = 'chrome_os_doo_doo',
	IOS_NON_SAFARI_BROWSER = 'ios_non_safari_browser',
	MICROPHONE_NO_AUDIO = 'microphone_no_audio',
	CAMERA_NO_MOTION = 'camera_no_motion',
	OPENTOK_CONNECTION_FAILED = 'opentok_connection_failed',
	OPENTOK_CONNECTING = 'opentok_connecting',
	OPENTOK_RECONNECTING = 'opentok_reconnecting',
	OPENTOK_POOR_CONNECTION = 'opentok_poor',
	// This is camel-cased because it gets compared directly to an opentok-provided string
	OPENTOK_NETWORK_DISCONNECTED = 'networkDisconnected',
	FORCE_UNPUBLISHED = 'forceUnpublished',
	OPENTOK_NETWORK_FAILED = 'network_failed',
	SCREEN_SHARE_PREPARING = 'SCREEN_SHARE_PREPARING',
	RESOLUTION_UPDATING = 'RESOLUTION_UPDATING',
	RESTARTING_SESSION = 'RESTARTING_SESSION'
}

export abstract class StateEmitter extends EventEmitter implements StatusOverlayable {
	public static readonly EVENTS = Events;
	public static readonly STATES = States;
	public static readonly REASONS = Reasons;

	public state: States = StateEmitter.STATES.UNINITIALIZED;
	public reason: Reasons = null;
	public action: () => void = null;

	public whenState (desiredState: States, wait: number = 0): Promise<States> {
		return new Promise((resolve, reject) => {
			let timeout;

			const listener = (stateChanged) => {
				if (desiredState === stateChanged) {
					this.removeListener(StateEmitter.EVENTS.STATE_CHANGE, listener);
					clearTimeout(timeout);
					resolve(stateChanged);
				}
			};

			const timeoutHandler = () => {
				this.removeListener(StateEmitter.EVENTS.STATE_CHANGE, listener);
				reject(new Error('StateEmitter::whenState wait exceeded'));
			};

			if (wait) timeout = setTimeout(timeoutHandler, wait);

			this.on(StateEmitter.EVENTS.STATE_CHANGE, listener);
			// Fire once in case we are already in that state
			listener(this.state);
		});
	}

	public destroy (): void {
		this.setState(StateEmitter.STATES.DESTROYED);
		this.removeAllListeners();
	}

	protected setState (state: States): void {
		const originalState: States = this.state;
		if (state === originalState) return;

		// Reset failed reason and action
		if (state === StateEmitter.STATES.ACTIVE
			|| (originalState === StateEmitter.STATES.FAILED && !this.reason)) {
			this.reason = null;
			this.action = null;
		}

		this.state = state;
		this.emit(StateEmitter.EVENTS.STATE_CHANGE, this.state, this);
	}

	protected setFailed (reason: Reasons, action: () => void = null, ...errs: any[]): void {
		this.reason = reason;
		this.action = action;
		this.setState(StateEmitter.STATES.FAILED);
		if (errs.length > 0) {
			logger.logError(...errs);
		}
	}
}
