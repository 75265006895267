import richDropdownListItemTemplate from './rich-dropdown-list-item-template.directive.html';
import titleDescriptionItemTemplate from './title-description-template/title-description.item.template.html';
import titleDescriptionSelectedTemplate from './title-description-template/title-description.selected.template.html';

/* @ngInject */
export const templates = function ($templateCache) {
	$templateCache.put('rich-dropdown/rich-dropdown-list-item-template.directive.html', richDropdownListItemTemplate);
	$templateCache.put('title-description-item', titleDescriptionItemTemplate);
	$templateCache.put('title-description-selected', titleDescriptionSelectedTemplate);
};
