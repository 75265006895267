import { Kind } from '../services/session-feedback.factory';

/* @ngInject */
export const FeedbackSessionCommentSyncEventViewer = function (feedbackSettings, feedbackSession, PlayerSync) {
	const vm = this;
	vm.feedbackSettings = feedbackSettings;
	vm.selectedItem = null;

	/**
	 * Filter out comments that aren't associated with a sync event
	 *
	 * @returns {boolean}
	 */
	vm.filterSyncEventTypes = (comment) => {
		return comment.isSyncEventType();
	};

	/**
	 * Whether there sync event comments
	 *
	 * @returns {boolean}
	 */
	vm.hasSyncEventComments = () => {
		return vm.mainController.sessionFeedback.getComments()
			.filter((comment) => {
				return vm.filterSyncEventTypes(comment);
			}).length > 0;
	};

	/**
	 * Close this viewer and update playerSync
	 */
	vm.done = () => {
		feedbackSession.toggleFeedbackTools(true);
		vm.mainController.playerSync.setMode(PlayerSync.MODE.PLAYBACK);
	};

	/**
	 * Close this viewer but don't update playerSync
	 */
	 vm.close = () => {
		feedbackSession.toggleFeedbackTools(false);
		this.feedbackSettings.setKindFilter(Kind.COMMENT);
	};
};
