import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as uiPopover from 'angular-ui-bootstrap/src/popover';
import { panel } from 'go-modules/panel';
import { accessibilityAttachmentsComopnent } from './accessibility-attachments.component';
import { mediaModel } from 'go-modules/models/media';
import { confirmModalModule } from 'go-modules/modals/confirm';
import { helpUrlsModule } from 'go-modules/help-urls';
import captionHelp from './caption-help-popover.html';
import * as angulartics from 'angulartics';

// Circular Dep
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ goModal } from*/ 'go-modules/modals';
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ mediaThumbnailModule } from*/ 'go-modules/media-thumbnail';
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ universalMediaChooserModule } from*/ 'go-modules/universal-media-chooser';

/* @ngInject */
const templateCache = ($templateCache) => {
	$templateCache.put('caption-help-popover.html', captionHelp);
};

export const accessibilityAttachmentsModule = angular.module('accessibility-attachments', [
	'go.modal',
	'go.mediaThumbnail',,
	'universal-media-chooser',
	panel,
	angularTranslate,
	mediaModel,
	confirmModalModule,
	angulartics,
	helpUrlsModule,
	uiPopover
])
	.component('accessibilityAttachments', accessibilityAttachmentsComopnent)
	.run(templateCache)
	.name;
