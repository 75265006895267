import * as angular from 'angular';

import { arrayDiffFilter } from './array-diff.filter';
import { capitalizeFilter } from './capitalize.filter';
import { fullNameFilter } from './fullname.filter';
import { pluralFilter } from './plural.filter';
import { replaceFilter } from './replace.filter';
import { roleFilter } from './role.filter';
import { trimTrailingZerosFilter } from './trim-trailing-zeros.filter';
import { truncateFilter } from './truncate.filter';

export const commonFiltersModule = angular.module('generalFilters', [])
	.filter('arrayDiff', arrayDiffFilter)
	.filter('fullname', fullNameFilter)
	.filter('capitalize', capitalizeFilter)
	.filter('plural', pluralFilter)
	.filter('replace', replaceFilter)
	.filter('roleFilter', roleFilter)
	.filter('trimTrailingZeros', trimTrailingZerosFilter)
	.filter('truncate', truncateFilter)
	.name;
