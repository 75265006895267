import template from './aspect-ratio-container.component.html';
import { AspectRatioContainerController } from './aspect-ratio-container.controller';

export const AspectRatioContainerComponent: ng.IComponentOptions = {
	bindings: {
		ratio: '@'
	},
	controller: AspectRatioContainerController,
	template,
	transclude: true
};
