import { FeedbackTabs, Kind } from '../services/session-feedback.factory';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { FeedbackGraphTour } from './feedback-graph-tour/feedback-graph-tour.service';

export interface Bindings {
	activity: any;
	media: any;
	playerSync: any;
	sourceMedia: any;
	canSeeCommentKind: any;
	canSeeRubricKind: any;
	canSeeTranscriptions: any;
	canSeeAnalytics: any;
}

export class FeedbackDisplayPanelController implements Bindings {

	// Bindings
	public activity: any;
	public media: any;
	public playerSync: any;
	public sourceMedia: any;
	public canSeeCommentKind: any;
	public canSeeRubricKind: any;
	public canSeeTranscriptions: any;
	public canSeeAnalytics: any;

	/* @ngInject */
	constructor (
		public feedbackSettings,
		public feedbackSession,
		public SessionFeedback,
		public featureFlag: FeatureFlag,
		private $timeout,
		private feedbackGraphTour: FeedbackGraphTour
	) {}

	public $onInit () {
		if (this.feedbackSettings.activeTab === this.SessionFeedback.TAB.TRANSCRIPTION &&
			!this.canSeeTranscriptions()) {
			this.feedbackSettings.setActiveTab(FeedbackTabs.COMMENT);
		}

		if (this.canSeeAnalytics() && this.featureFlag.isAvailable('FEEDBACK_GRAPH_TOUR')) {
			this.feedbackGraphTour.getTour().start();
		}
	}

	public openFeedbackPanel (tab: FeedbackTabs): void {
		this.feedbackSession.toggleFeedbackTools(true);

		this.$timeout(() => {
			if (this.isInAsyncSlideEditMode()) {
				this.feedbackSettings.setKindFilter(Kind.SLIDE_TIMINGS);
				this.feedbackSettings.setActiveTab(FeedbackTabs.COMMENT);
			} else {
				this.feedbackSettings.setActiveTab(tab);
				if (tab === FeedbackTabs.RUBRIC) {
					this.feedbackSettings.setKindFilter(Kind.RUBRIC);
				} else if (tab === FeedbackTabs.COMMENT) {
					this.feedbackSettings.setKindFilter(Kind.COMMENT);
				}
			}
		});
	}

	public isInAsyncSlideEditMode (): boolean {
		return this.playerSync &&
			this.playerSync.isCaptureMode() &&
			this.sourceMedia &&
			this.sourceMedia.isDocument() &&
			!this.activity.isLiveSessionEnabled() &&
			this.activity.isSlidesEnabled();
	};

	public shouldShowToggleFeedbackPanel (): boolean {
		return !!this.media || this.activity.has_response_media;
	}
}
