import { clientSettings } from 'go-modules/models/common/client.settings';

export interface TaxEstimate {
	total: string;
	tax_amount: string;
	subtotal: string;
	transactionProducts: {
		line_subtotal: string;
		line_tax_amount: string;
		product_id: number;
		qty?: number;
		sort_order: number;
	}[];
}

export interface TaxEstimateParams {
	subtotal: string;
	user_id: number;
	tax_exempt?: boolean;
	transactionProducts: {
		line_subtotal: string;
		product_id: number;
		qty?: number;
		sort_order: number;
	}[];
	address: {
		line1: string;
		line2?: string;
		city: string;
		region: string;
		country: string;
		postal_code: string;
	};
}

/* @ngInject */
export class Avalara {

	constructor (
		private $http: ng.IHttpService
	) {}

	/**
	 * Get a sales tax estimate
	 * @param params TaxEstimateParams
	 */
	public getSalesTaxEstimate (params: TaxEstimateParams): ng.IPromise<TaxEstimate> {
		return this.$http.post(`${clientSettings.GoReactV2API}/tax/estimate`, params)
			.then((response: ng.IHttpResponse<TaxEstimate>) => {
				return response.data;
			});
	}
}
