import template from './thumb.component.html';
import { SlideToggleThumbController as controller } from './thumb.controller';

export default {
	controller,
	template,
	bindings: {
		disabled: '@'
	},
	require: {
		slideToggleController: '^slideToggleControl'
	}
};
