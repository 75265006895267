import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { sessionManager } from '../../session-manager';

import { UserAccountModel } from './user-account.factory';

export const userAccountModel = angular.module('go.models.userAccount', [
	angularResource,
	sessionManager
])
	.factory('UserAccountModel', UserAccountModel)
	.name;
