
/* @ngInject */
export function goTablistKeyboardNavigation ($document, $parse) {
	return {
		restrict: 'EA',
		scope: {},
		link (scope, elm, tAttrs) {
			elm[0].setAttribute('role', 'tablist');

			elm.on('keydown', (e) => {
				const validKeys = ['ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'];
				const next = e.key === 'ArrowDown' || e.key === 'ArrowRight';
				const prev = e.key === 'ArrowUp' || e.key === 'ArrowLeft';
				const children = [...elm[0].querySelectorAll('[role=tab]')];

				// prevent window scrolling
				if(validKeys.find((key) => key === e.key)) {
				  e.preventDefault();
				}

				const index = children.findIndex((element) => {
					return $document[0].activeElement === element || element.contains($document[0].activeElement);
				});

				if(next) {
				  children[index].setAttribute('tabindex', -1);
				  const nextSibling = index === children.length -1 ? children[0] : children[index + 1];

				  if(nextSibling) {
				  	  nextSibling.setAttribute('tabindex', 0);
					  nextSibling.focus();
					  return;
				  }
				} else if(prev) {
				  children[index].setAttribute('tabindex', -1);
				  const prevSibling = index === 0 ? children[children.length - 1] : children[index - 1];

				  if(prevSibling) {
				  	prevSibling.setAttribute('tabindex', 0);
				  	prevSibling.focus();
				  	return;
				  }
				}
			});

			if (tAttrs.goTablistKeyboardNavigation) {
				scope.$parent.$watch($parse(tAttrs.goTablistKeyboardNavigation), (val) => {
				  scope.goTablistKeyboardNavigation = val;
				  elm[0].setAttribute('aria-orientation', val);
				});
			  } else {
				elm[0].setAttribute('aria-orientation', 'horizontal');
			}
		}
	};
}
