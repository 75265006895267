export interface TabOutConfig {
	targetElement: HTMLElement;
}

export interface TrapIndexConfig {
	targetElement: HTMLElement;
	autoFocusFirstElement?: boolean;
	onStopFocusElement?: HTMLElement;
	trapTabEvent: boolean;
	onTabOut?: () => any; // don`t trigger if trapTabEvent is true
	onEscape?: () => any;
}

interface TrapTabEntity {
	config: TrapIndexConfig;
	focusableContent: HTMLElement[];
	isFirstTabConsumed: boolean;
	currentlyFocusedElement: HTMLElement | null;
}

const FOCUSABLE_ELEMENTS =
	`a:not([tabindex="-1"]),
	button:not([tabindex="-1"]),
	[href]:not([tabindex="-1"]),
	input:not([tabindex="-1"]),
	select:not([tabindex="-1"]),
	textarea:not([tabindex="-1"]),
	[tabindex]:not([tabindex="-1"])`;

/* @ngInject */
export class TrapTabIndexService {

	private configStack: TrapTabEntity[];
	private currentEntity: TrapTabEntity | null;

	constructor (
		private $document: ng.IDocumentService,
		private $rootScope: ng.IRootScopeService,
		private $timeout: ng.ITimeoutService
	) {
		this.configStack = [];
		this.currentEntity = null;
	}

	public start (config: TrapIndexConfig): () => void {

		const newEntity = {
			config,
			isFirstTabConsumed: false
		} as TrapTabEntity;

		this.setFocusableElements(config.targetElement, newEntity);
		this.currentEntity = newEntity;

		if (this.configStack.length === 0) {
			this.$document.on('keydown', this.keyDownHandler);
		}

		if (config.autoFocusFirstElement && newEntity.focusableContent.length > 0) {
			// Need to wait for next digest in-case the element is not quite ready
			this.$timeout(() => this.focusElement(newEntity.focusableContent[0], newEntity));
		}

		this.configStack.unshift(newEntity);

		return this.stop(config);
	}

	private stop (config: TrapIndexConfig): () => void {
		return () => {
			const entity = this.configStack.find((item) => {
				return item.config === config;
			});

			// Remove this entity from the list
			this.configStack.splice(this.configStack.indexOf(entity), 1);

			// If this isn't currentEntity, nothing to do
			if (entity !== this.currentEntity) {
				return;
			}

			// If we aren't stacked on something else
			if (this.configStack.length === 0) {

				this.$document.off('keydown', this.keyDownHandler);

				if (entity.config.onStopFocusElement) {
					entity.config.onStopFocusElement.focus();
					this.currentEntity = null;
				}

				return;
			}

			// Set current entity to next in list, and re-focus
			// on the last thing it was focused on
			this.currentEntity = this.configStack[0];
			if (this.currentEntity.currentlyFocusedElement !== null) {
				this.focusElement(this.currentEntity.currentlyFocusedElement, this.currentEntity);
			}
		};
	}

	private focusElement (element: HTMLElement, entity: TrapTabEntity) {
		entity.currentlyFocusedElement = element;
		element.focus();
	}

	private setFocusableElements (element: HTMLElement, entity: TrapTabEntity) {
		const focusableContent = [...element.querySelectorAll(FOCUSABLE_ELEMENTS)] as HTMLElement[];
		entity.focusableContent = focusableContent.filter((elem) => elem.getAttribute('disabled') !== 'disabled');
	}

	private keyDownHandler = (event: JQueryEventObject) => {
		const isTabPressed = event.key === 'Tab' || event.keyCode === 9;
		const isEscPressed = event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27;

		const config = this.currentEntity.config;

		if (isEscPressed && config.onEscape) {
			this.$rootScope.$evalAsync(() => config.onEscape());
			return;
		}

		if (!isTabPressed) {
			return;
		}

		// Re-ask for focusable elements in case DOM came and/or went
		this.setFocusableElements(config.targetElement, this.currentEntity);
		const focusableElements = this.currentEntity.focusableContent;
		const firstElement = focusableElements[0];
		const lastElement = focusableElements[focusableElements.length - 1];

		if (event.shiftKey) {

			if (this.$document[0].activeElement === firstElement) {
				lastElement.focus();
				event.preventDefault();
			}
		} else {

			if (this.$document[0].activeElement === lastElement) {
				if (config.trapTabEvent) {
					firstElement.focus();
					event.preventDefault();
				} else if (config.onTabOut) {
					this.$rootScope.$evalAsync(() => config.onTabOut());
				}
			}

			// when autoFocus is not enabled, on first tab focus the first focusable element
			if (!config.autoFocusFirstElement && !this.currentEntity.isFirstTabConsumed) {
				firstElement.focus();
				event.preventDefault();
			}

			this.currentEntity.isFirstTabConsumed = true;
		}
	};
}
