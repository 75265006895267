export const goFrameId = 'go-print';

export const getFrame = (): HTMLIFrameElement => {
	let printFrame = document.querySelector(`iframe#${goFrameId}`) as HTMLIFrameElement;

	if (!printFrame) {
		printFrame = document.createElement('iframe');
		printFrame.setAttribute('style', 'position:absolute;left:-9999px;');
		printFrame.setAttribute('width', '650px');
		printFrame.setAttribute('height', '800px');
		printFrame.setAttribute('id', goFrameId);
	}

	return printFrame;
};

/* @ngInject */
export const goPrintService = function ($timeout) {

	const stripScripts = (headString: string): string => {
		const div = document.createElement('div');
		div.innerHTML = headString;
		const scripts = div.getElementsByTagName('script');
		let i = scripts.length;
		while (i--) {
			scripts[i].parentNode.removeChild(scripts[i]);
		}
		const title = div.querySelector('title');
		title.parentNode.removeChild(title);
		return div.innerHTML;
	};

	return {
		print: (element, title) => {

			const printFrame = getFrame();

			const headContent = stripScripts(document.querySelector('head').innerHTML);

			const tempDiv = document.createElement('div');
			tempDiv.appendChild(element.cloneNode(true));

			printFrame.onload = () => {
				printFrame.contentDocument.head.innerHTML = `
					${headContent}
					<title>${title}</title>
					<style>html, body { overflow: auto !important; display: initial !important; } @media print { * { transition: none !important; }}</style>
				`;
				printFrame.contentDocument.body.innerHTML = tempDiv.innerHTML;
				$timeout(() => {
					printFrame.focus();
					printFrame.contentWindow.print();
				}, 1000); // Probably excessive, but need to give CSS/DOM time to load and render
			};

			window.document.body.appendChild(printFrame);
		}
	};
};
