import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogConfig, ConfirmDialogData } from 'ngx/go-modules/src/components/dialogs/confirm-dialog/confirm-dialog.component';
import { ChangePasswordDialogComponent, ChangePasswordDialogData } from 'ngx/go-modules/src/components/dialogs/change-password-dialog/change-password-dialog.component';
import { TermsOfUseDialogComponent } from 'ngx/go-modules/src/components/dialogs/terms-of-use-dialog/terms-of-use-dialog.component';
import { MessageDialogComponent, MessageDialogData } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { CompareProductsDialogComponent, LicenseProductChooserData } from 'ngx/go-modules/src/components/dialogs/compare-products-dialog/compare-products-dialog.component';

/**
 * A service used to open an ngx modal in ng1
 * we also have ngxGoModalService but that doesnt work for modals not using GoDialogRef and GO_MODAL_DATA
 * can add additional modals as needed
 */
@Injectable({
	providedIn: 'root'
})
export class DowngradeModalService {
	constructor (
		private dialog: MatDialog,
		private ngZone: NgZone
	) {}

	public openConfirmDialog (data: ConfirmDialogData, config: ConfirmDialogConfig = {}): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			this.ngZone.run(() => {
				const dialogRef = this.dialog.open(ConfirmDialogComponent, {...config, data});
				dialogRef.afterClosed().subscribe((confirm: boolean) => {
					if (confirm) {
						resolve(true);
					} else {
						reject();
					}
				});
			});
		});
	}

	public openChangePasswordDialog (data: ChangePasswordDialogData): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.ngZone.run(() => {
				const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {data});
				dialogRef.afterClosed().subscribe((result) => {
					if (result) {
						resolve(result);
					} else {
						reject();
					}
				});
			});
		});
	}

	public openTermsOfUseDialog () {
		this.ngZone.run(() => {
			return this.dialog.open(TermsOfUseDialogComponent);
		});
	}

	public openMessageDialog (data: MessageDialogData): MatDialogRef<MessageDialogComponent, boolean> {
		return this.ngZone.run(() => {
			return this.dialog.open(MessageDialogComponent, {data});
		});
	}

	public openCompareProductsDialog (data: LicenseProductChooserData) {
		return this.ngZone.run(() => {
			return this.dialog.open(CompareProductsDialogComponent, {data});
		});
	}
}
