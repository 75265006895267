import { UniversalMediaChooserController } from '../umc.controller';
export interface Bindings {
	umcController: UniversalMediaChooserController;
	options: any;
	umcInstanceName: string;
}

export class UniversalMediaChooserRecordSessionController implements Bindings {

	// Bindings
	public umcController: UniversalMediaChooserController;
	public options: any;
	public umcInstanceName: string;

	public isReady: boolean;
	public uploadOptions: any;

	/* @ngInject */
	constructor () {}

	public $onInit (): void {
		if (!this.options || !this.umcInstanceName || !this.options.groupId) {
			throw new Error('Must provide all required params (groupId, umcInstanceName)');
		}
	}
}
