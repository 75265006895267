import * as angular from 'angular';
import * as angulartics from 'angulartics';
import * as uiPopover from 'angular-ui-bootstrap/src/popover';
import * as uiDropdown from 'angular-ui-bootstrap/src/dropdown';
import * as angularMaterial from 'angular-material';

import { sessionModel } from '../models/session';
import { mediaModel } from '../models/media';
import { activityModel } from '../models/activity';
import { groupDepModel } from '../models/group-dep';
import { helpUrlsModule } from '../help-urls';
import { uiDecimal } from '../ui-decimal';
import { mediaThumbnailModule } from '../media-thumbnail';
import { sessionAttachments } from '../session-attachments';
import { userModel } from 'go-modules/models/user';

import { SessionEditorController } from './session-editor.controller';
import { sessionEditorDirective } from './session-editor.directive';
import { sessionEditor } from './session-editor.factory';
import { MODE } from './modes';
import { sessionActivitySelectorModule } from 'go-modules/session-activity-selector';

import 'angular-material/modules/layouts/angular-material.layouts.css';
import 'angular-material/modules/js/core/core.css';
import 'angular-material/modules/js/virtualRepeat/virtualRepeat.css';
import 'angular-material/modules/js/chips/chips.css';
import 'angular-material/modules/js/autocomplete/autocomplete.css';
import 'bootstrap-css-only';

import './style.less';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// import /*{ goModal } from*/ '../modals'; -- commented to satisfy eslint
import '../modals';
// tslint:disable-next-line:import-spacing
import /*{ universalMediaChooserModule } from*/ '../universal-media-chooser';
// tslint:disable-next-line:import-spacing
import /*{ accessibilityAttachmentsModule } from*/ 'go-modules/accessibility-attachments';

export const sessionEditorModule = angular.module('go.session-editor', [
	sessionModel,
	mediaModel,
	activityModel,
	groupDepModel,
	sessionAttachments,
	mediaThumbnailModule,
	uiPopover,
	uiDropdown,
	angularMaterial,
	angulartics,
	userModel,
	helpUrlsModule,
	uiDecimal,
	sessionActivitySelectorModule,

	// TODO: use module strings directly until circular dependency is fixed
	'go.modal',
	'universal-media-chooser',
	'accessibility-attachments'
])
	.controller('SessionEditorController', SessionEditorController)
	.directive('sessionEditor', sessionEditorDirective)
	.factory('sessionEditor', sessionEditor)
	.name;

export {
	MODE
};
