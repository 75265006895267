import template from './label-wrapper.component.html';
import { LabelWrapperController } from './label-wrapper.controller';

export const labelWrapper = {
	template,
	transclude: true,
	bindings: {
		label: '@'
	},
	controller: LabelWrapperController
};
