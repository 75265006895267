import { GoSource, GoSourceConfig } from '../go-source';
import { DeviceKit } from 'go-modules/device-kit';
import { MediaStreamSource } from '../media-stream-source';

export class ScreenSource extends MediaStreamSource {
	constructor (goSourceConfig: GoSourceConfig) {
		super(goSourceConfig);
		this.initVideoElement(goSourceConfig);
	}

	public async init (): Promise<void> {
		try {
			this.stream = await DeviceKit.requestScreenCaptureStream();
		} catch (e) {
			// If the user cancels screen capture, one of these errors is thrown.
			// In the case that the user cancelled, we need to destroy before throwing.
			if (e?.message === GoSource.ERRORS.PERMISSION_DENIED || e?.name === GoSource.ERRORS.NOT_ALLOWED) {
				this.destroy();
			} else {
				this.setState(GoSource.STATES.FAILED);
			}
			throw e;
		}
		this.element.srcObject = this.stream;
		this.setState(GoSource.STATES.ACTIVE);
	}

	public async test (): Promise<boolean> {
		return ![ GoSource.STATES.FAILED, GoSource.STATES.DESTROYED ].includes(this.state);
	}
}
