import * as angular from 'angular';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as angularTranslate from 'angular-translate';
import * as angularSanitize from 'angular-sanitize';
import * as angularAria from 'angular-aria';

import 'angular-legacy-sortablejs-maintained';

import { rubricSessionModel } from '../models/rubric-session';
import { rubricTemplateModel } from '../models/rubric-template';
import { labelInputModule } from '../label-input';
import { uiDecimal } from '../ui-decimal';

import { rubricDirective } from './rubric.directive';
import { widgetTemplates } from './widget/templates';
import { rubricWidget } from './widget/widget.directive';
import { rubricActionsDirective } from './element/actions.directive';
import { addLabel } from './filters/add-label.filter';
import { nonNegative } from './filters/non-negative.filter';
import { rubricPlaceholder } from './filters/placeholder.filter';
import { RubricCanvasController } from './canvas/canvas.controller';
import { elementTemplates } from './element/templates';
import { rubricElement } from './element/element.directive';
import { RubricScaleController } from './element/scale/scale.controller';
import { RubricPointsController } from './element/points/points.controller';
import { rubricCheckbox } from './element/checkbox/checkbox.directive';
import { RubricCheckboxController } from './element/checkbox/checkbox.controller';
import { RubricCategoryController } from './element/category/category.controller';
import rubricWidgetPanelComponent from './panel/panel.component';

import 'bootstrap-css-only';
import 'awesome-bootstrap-checkbox';

import './style.less';
import { rubricElementWithHandleWrapperComponent } from './rubric-element-with-handle-wrapper/rubric-element-with-handle-wrapper.component';
import { messageModalModule } from 'go-modules/modals/message';

export const rubricModule = angular.module('rubric', [
	rubricTemplateModel,
	rubricSessionModel,
	labelInputModule,
	uiTooltip,
	'ng-sortable',
	angularTranslate,
	angularSanitize,
	angularAria,
	uiDecimal,
	messageModalModule
])
	.run(elementTemplates)
	.run(widgetTemplates)
	.filter('rubricPlaceholder', rubricPlaceholder)
	.filter('nonNegative', nonNegative)
	.filter('addLabel', addLabel)
	.controller('RubricCategoryController', RubricCategoryController)
	.controller('RubricCheckboxController', RubricCheckboxController)
	.controller('RubricPointsController', RubricPointsController)
	.controller('RubricScaleController', RubricScaleController)
	.controller('RubricCanvasController', RubricCanvasController)
	.directive('rubricCheckbox', rubricCheckbox)
	.directive('rubricElement', rubricElement)
	.directive('rubricWidget', rubricWidget)
	.directive('rubricActions', rubricActionsDirective)
	.directive('rubric', rubricDirective)
	.component('rubricWidgetPanel', rubricWidgetPanelComponent)
	.component('rubricElementWithHandleWrapper', rubricElementWithHandleWrapperComponent)
	.name;
