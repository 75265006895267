import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NgxYouTubeProcessorService {
	private youtubeRegex = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]+).*/;
	private youtubeThumbnailUrlPrefix = 'https://img.youtube.com/vi/';
	private youtubeThumbnailUrlSuffix = '/hqdefault.jpg';

	constructor () {}

	public isYoutubeUrl (url: string) {
		const match = url?.match(this.youtubeRegex);
		return match != null && match[1] != null;
	}
}
