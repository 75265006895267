export enum SORT_OPTIONS {
	ALPHANUMERIC = 'alphanumeric',
	ALPHANUMERICREVERSE = 'alphanumericreverse',
	LASTMODIFIED = 'lastmodified'
};

export enum CONTENT_TYPES {
	MEDIA = 'media',
	RUBRICS = 'rubric',
	MARKERS = 'markers',
	DOCUMENTS = 'document',
	ACTIVITIES = 'activity'
};

export enum MODES {
	SELECT = 'select'
};

export enum LIBRARY_TYPES {
	ARCHIVE = 'archive',
	LEGACY = 'legacy',
	MINE = 'mine',
	ORG = 'org',
	SHARED = 'shared',
	MANAGED = 'managed',
	DIRECT_SHARE = 'direct_share'
};
