import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import { VideoShareEmailErrorModal } from './modal.service';
import './style.less';

export const videoShareEmailErrorModalModule = angular.module('modal.video-share-email-error', [
	goModalBootstrapModule,
	angularTranslate
])
	.service('videoShareEmailErrorModal', VideoShareEmailErrorModal)
	.name;
