import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';

import { mediaPreviewModule } from '../../media-preview';
import { sessionModel } from '../../models/session';
import { activityModel } from '../../models/activity';
import { commonFiltersModule } from '../../common-filters';

import { ActivityInstructionsModal } from './modal.factory';


import './modal.less';

export const activityInstructionsModalModule = angular.module('modal.activity-instructions', [
	goModalBootstrapModule,
	angularTranslate,
	mediaPreviewModule,
	sessionModel,
	activityModel,
	commonFiltersModule
])
	.service(ActivityInstructionsModal.NG1_INJECTION_NAME, ActivityInstructionsModal)
	.name;
