import { Directive, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface FocusOnFirstInvalidField {
	formGroup: FormGroup;
	formWrapper: HTMLElement;
}

/* This directive will only work if you attach this directive into a form submit button */
@Directive({
	selector: '[focusOnFirstInvalidField]'
})
export class FocusOnFirstInvalidFieldDirective {
	@Input()
	public focusOnFirstInvalidField: FocusOnFirstInvalidField;

	@HostListener('click')
	public handle () {
		const formGroup = this.focusOnFirstInvalidField.formGroup;
		const wrapper = this.focusOnFirstInvalidField.formWrapper;
		formGroup.markAllAsTouched();

		Object.keys(formGroup.controls)
			.find((key: string) => {
				const control = formGroup.controls[key];
				if(control.invalid) {
					const element = wrapper.querySelector(`[formControlName="${key}"]`);
					control.markAsTouched();
					control.markAsDirty();

					// dynamically named form controls don't have formcontrolname on the html
					if (element) {
						if (element.tagName === 'MAT-CHECKBOX' || element.tagName === 'MAT-RADIO-GROUP' || element.tagName === 'USER-FORM-FIELD') {
							element.querySelector('input').focus();
						} else {
							(wrapper.querySelector(`[formControlName="${key}"]`) as HTMLElement).focus();
						}
					} else {
						// just focus on first invalid input
						const input = wrapper.querySelector('input.ng-invalid') as HTMLInputElement;
						input.focus();
					}

					return true;
				}
			});
	}
}
