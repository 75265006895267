import { MessageModal } from 'go-modules/modals/message/modal.factory';

interface Bindings {
	playerControlsCtrl: any;
}

export class PlayerControlsSettingsController implements Bindings {

	public playerControlsCtrl: any;
	public captionsEnabled: boolean = false;
	public commentCaptionsEnabled: boolean = false;
	public audioDescriptionEnabled: boolean = false;

	/** @ngInject */
	constructor (
		private $scope: ng.IScope,
		private messageModal: MessageModal
	) {}

	public $onInit () {

		// Because slide toggle wants to manage a model,
		// but captions aren't controlled that way, we need
		// to watch captionsEnabled to know what state the toggle is in
		this.$scope.$watch(() => {
			return this.playerControlsCtrl.player.captionsEnabled();
		}, (newValue) => {
			this.captionsEnabled = newValue === true;
		});

		// Same thing for comment captions. It's managed elsewhere
		this.$scope.$watch(() => this.playerControlsCtrl.cuepointsActive, (newValue) => {
			this.commentCaptionsEnabled = newValue === true;
		});

		// Also audio description
		this.$scope.$watch(() => {
			return this.playerControlsCtrl.options.audioDescriptionActive;
		}, (newValue) => {
			this.audioDescriptionEnabled = newValue === true;
		});
	}

	public shouldShowClosedCaptionsToggle () {
		return !this.playerControlsCtrl.options.isClosedCaptionsDisabled;
	}

	public toggleCaptions () {
		if (this.playerControlsCtrl.player.youtubePlayer) {
			const modalData = {
				title: 'modal-cc-not-available-title',
				message: 'modal-cc-not-available-message',
				resolveBtnText: 'common_close',
				resolveBtnClass: 'primary-btn'
			};
			this.messageModal.open({ modalData }).result.then(() => {
				this.captionsEnabled = false;
			});
		} else {
			this.playerControlsCtrl.toggleCaptions(!this.playerControlsCtrl.player.captionsEnabled());
		}
	}
}
