import angular, { INgModelController } from 'angular';
import { NoUiSliderOptionsWithEvents } from 'go-modules/go-nouislider/go-nouislider.directive';

const LEFT_RIGHT_ADVANCE_TIME_MS = 5000;
const PAGE_UP_DOWN_ADVANCE_TIME_MS = 30000;

export interface Bindings {
	value: number;
	max: number;
	min: number;
	ngModelController: INgModelController;
}

export class TimeSliderController implements Bindings {

	// Bindings
	public value: number;
	public max: number;
	public min: number;
	public ngModelController: INgModelController;

	public handles: number[];
	private sliderOptions: NoUiSliderOptionsWithEvents;

	/* @ngInject */
	constructor (
		private $scope: ng.IScope,
		private $filter: ng.IFilterService,
		private $translate: ng.translate.ITranslateService
	) {}

	public $onInit (): void {
		// The first handle will be the new value, the second the original value
		this.handles = [this.value, this.value];

		// We want a set time for left/right, but nouislider works in percent
		// And we want a set time for page up/down, but nouislider works in a multiplier
		// So calculate the percent/multiple, based on the time
		const duration = this.max - this.min;
		const leftRightAdvancePercent = duration / LEFT_RIGHT_ADVANCE_TIME_MS;
		const pageUpDownAdvanceMultiplier = PAGE_UP_DOWN_ADVANCE_TIME_MS / LEFT_RIGHT_ADVANCE_TIME_MS;

		this.sliderOptions = {
			disabled: [false, true],
			behaviour: 'unconstrained-snap',
			orientation: 'horizontal',
			direction: 'ltr',
			range: {
				min: this.min,
				max: this.max
			},
			keyboardDefaultStep: leftRightAdvancePercent,
			keyboardPageMultiplier: pageUpDownAdvanceMultiplier,
			tooltips: true,
			format: {
				to: (value: number) => {
					return this.$filter<(time: number) => string>('timeFormat')(value);
				},
				from: (value: number) => value
			},
			ariaFormat: {
				to: (value: number): string => {
					return this.$filter<(time: number, aria: boolean) => string>('timeFormat')(value, true);
				},
				// required but unused
				from: angular.noop
			},
			ariaLabel: [
				this.$translate.instant('feedback-session-feedback-tree-comment_new-time-handle'),
				this.$translate.instant('feedback-session-feedback-tree-comment_previous-time-handle')
			]
		};

		this.$scope.$watch('$ctrl.handles[0]', (newTime) => {
			this.ngModelController.$setViewValue(newTime);
		});
	}

	public increment (): void {
		let newTime: number = this.handles[0] + 1000;
		if (newTime > this.sliderOptions.range.max) {
			newTime = this.sliderOptions.range.max as number;
		}
		this.handles[0] = newTime;
	}

	public decrement (): void {
		let newTime: number = this.handles[0] - 1000;
		if (newTime < this.sliderOptions.range.min) {
			newTime = this.sliderOptions.range.min as number;
		}
		this.handles[0] = newTime;
	}
}
