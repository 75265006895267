import { NgModule } from '@angular/core';
import { FocusOnFirstInvalidFieldDirective } from './focus-on-first-invalid-field.directive';

@NgModule({
	declarations: [
		FocusOnFirstInvalidFieldDirective
	],
	exports: [
		FocusOnFirstInvalidFieldDirective
	]
})
export class FocusOnFirstInvalidFieldModule {}
