import * as angular from 'angular';
import * as dayjs from 'dayjs';

/* @ngInject */
export function groupModelTransformer ($http) {

	const booleanFields = [
		'self_registration_enabled'
	];

	const dateFields = [
		'created_at',
		'start_date',
		'end_date',
		'deleted_at'
	];

	/**
	 * Transform request data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformRequestData (data) {

		const payload = angular.extend({}, data);

		// format dates
		angular.forEach(dateFields, function (key) {
			if (payload.hasOwnProperty(key)) {
				if (angular.isDate(payload[key])) {
					payload[key] = dayjs(payload[key]).utc().format('YYYY-MM-DD HH:mm:ss');
				} else {
					payload[key] = '';
				}
			}
		});

		return payload;
	}

	/**
	 * Transform request
	 *
	 * @param request
	 * @returns {*}
	 */
	function transformRequest (request) {
		let requestData;

		if (angular.isArray(request)) {
			requestData = [];
			angular.forEach(request, function (data) {
				requestData.push(transformRequestData(data));
			});
		} else {
			requestData = transformRequestData(request);
		}

		return angular.toJson(requestData);
	}

	/**
	 * Transform response data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformResponseData (data) {

		if (!angular.isObject(data)) {
			return data;
		}

		// convert to booleans
		angular.forEach(booleanFields, function (key) {
			if (data.hasOwnProperty(key)) {
				data[key] = data[key] > 0;
			}
		});

		// convert to dates
		angular.forEach(dateFields, function (key) {
			if (data.hasOwnProperty(key)) {
				data[key] = data[key] ? dayjs.utc(data[key]).toDate() : null;
			}
		});

		data.past = isPast(data);
		data.future = isFuture(data);

		return data;
	}

	/**
	 * Transform response
	 *
	 * @param response
	 * @returns {*}
	 */
	function transformResponse (response) {
		if (angular.isArray(response)) {
			angular.forEach(response, function (data) {
				transformResponseData(data);
			});
		} else {
			transformResponseData(response);
		}

		return response;
	}

	/**
	 * Whether group is inactive
	 *
	 * @param group
	 * @returns {boolean}
	 */
	function isPast (group) {
		let result = false;
		if (angular.isDate(group.end_date)) {
			const endDate = angular.copy(group.end_date);
			endDate.setHours(0, 0, 0, 0);

			const now = new Date();
			now.setHours(0, 0, 0, 0);
			result = dayjs(endDate).isBefore(now);
		}
		return result;
	}

	/**
	 * Whether group is scheduled to become active
	 *
	 * @param group
	 * @returns {boolean}
	 */
	function isFuture (group) {
		let result = false;
		if (angular.isDate(group.start_date)) {
			const startDate = angular.copy(group.start_date);
			startDate.setHours(0, 0, 0, 0);

			const now = new Date();
			now.setHours(0, 0, 0, 0);
			result = dayjs(startDate).isAfter(now);
		}
		return result;
	}

	return {
		request: transformRequest,
		response: [$http.defaults.transformResponse[0], transformResponse]
	};
}
