import template from './modal.html';

/* @ngInject */
export function confirmModal (goModalBootstrap) {

	function open (options) {
		options.template = template;
		options.controller = function () {};
		options.windowClass = options.name = 'confirm-modal';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		return goModalBootstrap.open(options);
	}

	return {
		open
	};
}
