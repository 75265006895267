import * as angular from 'angular';

export interface InviteModel extends angular.resource.IResource<InviteModel> {
	inviteId?: number;
	uuid?: string;
	groupId: number;
	userId: number;
	active?: boolean;
	email: string;
	role: InviteRole;
	lastSent?: Date;
	message?: string;

	$resend: ng.resource.IResourceInstanceMethod<InviteModel>;
}

export interface InviteFactory extends angular.resource.IResourceClass<InviteModel> {
	resend: angular.resource.IResourceMethod<InviteModel>;
	resendAll: angular.resource.IResourceMethod<InviteModel>;
	sendInvites: angular.resource.IResourceArrayMethod<InviteModel>;
}

export enum InviteRole {
	OWNER = 'owner',
	ADMIN = 'admin',
	INSTRUCTOR = 'instructor',
	REVIEWER = 'reviewer',
	PRESENTER = 'presenter'
}
