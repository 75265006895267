import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { RubricElementController } from './element.controller';

/* @ngInject */
export function rubricElement ($templateCache, $compile, eventService: EventService) {
	return {
		restrict: 'E',
		require: '^rubric',
		scope: {
			type: '=type',
			mode: '=mode',
			category: '=category',
			element: '=element',
			schema: '=schema'
		},
		link (scope, el, _attr, rubricController) {
			const template = $templateCache.get('rubric/element/' + scope.type + '/' + scope.mode + '.html');
			el.html(template);
			$compile(el.contents())(scope);

			scope.dataChanged = () => {
				eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);
			};

			scope.rubricController = rubricController;
		},
		controller: RubricElementController
	};
}
