import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';
import { NgxMediaThumbnailComponent } from './media-thumbnail.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		GoTooltipModule
	],
	declarations: [
		NgxMediaThumbnailComponent
	],
	entryComponents: [
		NgxMediaThumbnailComponent
	],
	exports: [
		NgxMediaThumbnailComponent
	]
})
export class NgxMediaThumbnailModule {}
