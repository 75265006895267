import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { OfflineMode } from './offline-mode.service';
import { goBannerModule } from 'go-modules/go-banner';

/**
 * This module is for handling offline mode
 *
 * For now, we only add a listener and banner alerting a user to being offline
 */
export const goOfflineModeModule = angular.module('go.offline-mode', [
	goBannerModule,
	angularTranslate
])
	.service('offlineMode', OfflineMode)
	.name;
