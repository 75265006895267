import inviteUsersPermissionsTemplate from './invite-users/permissions.html';
import browserNotSupported from './session-editor/browser-not-supported.html';
import uploadNotAllowedSingleRecording from './session-editor/upload-not-allowed-single-recording.html';
import roleItemTemplate from './invite-users/role-option-item.template.html';
import roleSelectedTemplate from './invite-users/role-option-selected.template.html';

/* @ngInject */
export function templateCache ($templateCache) {
	$templateCache.put('modals/invite-users/permissions.html', inviteUsersPermissionsTemplate);
	$templateCache.put('role-option-item', roleItemTemplate);
	$templateCache.put('role-option-selected', roleSelectedTemplate);
	$templateCache.put('modals/session-editor/browser-not-supported.html', browserNotSupported);
	$templateCache.put('modals/session-editor/upload-not-allowed-single-recording.html', uploadNotAllowedSingleRecording);
}
