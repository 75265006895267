import textEdit from './text/edit.html';
import textLive from './text/live.html';
import textPrint from './text/print.html';
import qualitativeEdit from './qualitative/edit.html';
import qualitativeLive from './qualitative/live.html';
import qualitativePrint from './qualitative/print.html';
import pointsEdit from './points/edit.html';
import pointsLive from './points/live.html';
import pointsPrint from './points/print.html';
import numericEdit from './numeric/edit.html';
import numericLive from './numeric/live.html';
import numericPrint from './numeric/print.html';
import checkboxEdit from './checkbox/edit.html';
import checkboxLive from './checkbox/live.html';
import checkboxPrint from './checkbox/print.html';
import categoryEdit from './category/edit.html';
import categoryLive from './category/live.html';
import categoryPrint from './category/print.html';
import breakEdit from './break/edit.html';
import breakLive from './break/live.html';
import breakPrint from './break/print.html';

/* @ngInject */
export function elementTemplates ($templateCache) {
	$templateCache.put('rubric/element/text/edit.html', textEdit);
	$templateCache.put('rubric/element/text/live.html', textLive);
	$templateCache.put('rubric/element/text/print.html', textPrint);
	$templateCache.put('rubric/element/qualitative/edit.html', qualitativeEdit);
	$templateCache.put('rubric/element/qualitative/live.html', qualitativeLive);
	$templateCache.put('rubric/element/qualitative/print.html', qualitativePrint);
	$templateCache.put('rubric/element/points/edit.html', pointsEdit);
	$templateCache.put('rubric/element/points/live.html', pointsLive);
	$templateCache.put('rubric/element/points/print.html', pointsPrint);
	$templateCache.put('rubric/element/numeric/edit.html', numericEdit);
	$templateCache.put('rubric/element/numeric/live.html', numericLive);
	$templateCache.put('rubric/element/numeric/print.html', numericPrint);
	$templateCache.put('rubric/element/checkbox/edit.html', checkboxEdit);
	$templateCache.put('rubric/element/checkbox/live.html', checkboxLive);
	$templateCache.put('rubric/element/checkbox/print.html', checkboxPrint);
	$templateCache.put('rubric/element/category/edit.html', categoryEdit);
	$templateCache.put('rubric/element/category/live.html', categoryLive);
	$templateCache.put('rubric/element/category/print.html', categoryPrint);
	$templateCache.put('rubric/element/break/edit.html', breakEdit);
	$templateCache.put('rubric/element/break/live.html', breakLive);
	$templateCache.put('rubric/element/break/print.html', breakPrint);
}
