import { clientSettings } from 'go-modules/models/common/client.settings';
import { GoBannerService } from 'go-modules/go-banner/go-banner.service';
import { AnnouncementType } from 'go-modules/models/announcement/announcement.type';
import { AnnouncementSchema } from 'go-modules/models/announcement/announcement.schema';
import refreshBanner from './upgrade-banner.html';

interface UpgradeBannerScope extends ng.IScope {
	$window: ng.IWindowService;
}

export class UpdateManagerService {

	private currentVersion = null;
	private hasUpdates = false;

	/** @ngInject */
	constructor (
		private $http: ng.IHttpService,
		private $interval: ng.IIntervalService,
		private $document: ng.IDocumentService,
		private GoVersionUpdateInterval,
		private goBannerService: GoBannerService,
		private $compile: ng.ICompileService,
		private $rootScope: ng.IRootScopeService,
		private $window: ng.IWindowService
	) {}

	public init () {
		this.getVersion().then((startingVersion) => {
			this.currentVersion = startingVersion;
			this.startVersionTracker();
		});
	}

	public checkVersionAndReloadIfHasUpdates () {
		if (this.hasUpdates) {
			this.$window.location.reload();
		}
	}

	// Check the server for current version
	// and notify user if necessary
	public updateVersion (): ng.IPromise<void> {
		return this.getVersion().then((latestVersion) => {
			const existingBanner = this.$document[0].querySelector('#update-banner');
			if (this.updateRequired(latestVersion.version) && !existingBanner) {
				const scope = this.$rootScope.$new() as UpgradeBannerScope;
				scope.$window = this.$window;
				this.goBannerService.addBanner({
					type: AnnouncementType.INFO,
					isDismissable: true,
					name: latestVersion.version,
					maxViews: 1,
					content: '',
					html: this.$compile(refreshBanner)(scope)
				} as AnnouncementSchema);
				this.currentVersion = latestVersion;
			}
		});
	};

	private getVersion (): ng.IPromise<any> {
		return this.$http.get(`${clientSettings.GoReactV2API}/version`).then((response) => {
			return response.data;
		});
	}

	private updateRequired (newVersion: string) {
		if (!this.currentVersion) {
			throw new Error('GoVersionManager:: cant compare version without current version. Did you call init?');
		}
		this.hasUpdates = this.hasUpdates || this.isVersionGreater(this.currentVersion.version, newVersion);
		return this.isVersionGreater(this.currentVersion.version, newVersion, 2);
	}

	private isVersionGreater (
		oldVersion: string,
		newVersion: string,
		greaterBy?: number
	): boolean {
		const oldVersionParts = oldVersion.split('.');
		const newVersionParts = newVersion.split('.');
		return oldVersionParts.reduce((larger, oldVersionSegment, index) => {
			if (greaterBy && index === oldVersionParts.length -1) {
				return larger || +newVersionParts[index] - +oldVersionSegment >= +greaterBy;
			}
			return larger || +newVersionParts[index] > +oldVersionSegment;
		}, false);
	}

	// Version tracker
	// Updates current version on intervals
	// Also updates if browser tab is focused
	private startVersionTracker () {
		// Check version on every interval
		this.$interval(() => this.updateVersion(), this.GoVersionUpdateInterval);
		// Check version on tab focus
		this.$document[0].addEventListener('visibilitychange', () => {
			if (this.$document[0].visibilityState === 'visible') {
				this.updateVersion();
			}
		});
	}
}
