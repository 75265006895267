import { clientSettings } from '../common/client.settings';

/* @ngInject */
export function UserAccountModel ($resource, sessionManager) {

	const baseUrl = `${clientSettings.GoReactV2API}/users/link-users`;

	const UserAccountModelFunc = $resource(baseUrl, {
		save: {
			method: 'POST',
			interceptor: {
				response: (response) => {
					// Force token refresh
					sessionManager.refreshToken();
					return response;
				}
			},
			url: baseUrl
		}
	});

	return UserAccountModelFunc;
}
