import template from './umc-header.component.html';
import { UniversalMediaChooserHeaderController as controller } from './umc-header.controller';

/* @ngInject */
export const UniversalMediaChooserHeaderComponent = {
	bindings: {
		options: '=',
		umcInstanceName: '@',
		viewFrom: '<?'
	},
	controller,
	template,
	transclude: {
		start: '?universalMediaChooserHeaderStart',
		end: '?universalMediaChooserHeaderEnd'
	}
} as ng.IComponentOptions;
