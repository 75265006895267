import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeaseWallDirective } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.directive';
import { TeaseWallComponent } from 'ngx/go-modules/src/directives/tease-wall/component/tease-wall.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		TeaseWallComponent,
		TeaseWallDirective
	],
	entryComponents: [
		TeaseWallComponent
	],
	exports: [
		TeaseWallDirective
	]
})
export class TeaseWallModule {}
