import { NgModule } from '@angular/core';
import { GoMonitorTabEventDirective } from './go-monitor-tab-event.directive';

@NgModule({
	declarations: [
		GoMonitorTabEventDirective
	],
	exports: [
		GoMonitorTabEventDirective
	]
})
export class GoMonitorTabEventModule {}
