import * as angular from 'angular';

/* @ngInject */
// eslint-disable-next-line
export const RichDropdownController = function ($scope, $q, richDropdownConfig, $templateRequest, $timeout) {
	const vm = this;

	vm.$onInit = () => {
		vm.id = 'rich-dropdown-' + generateId();

		vm.selectedElement = null;

		vm.defaultItemTemplateUrl = richDropdownConfig.itemTemplateUrl;
		$templateRequest(vm.defaultItemTemplateUrl, true).then(function (response) {
			vm.defaultItemTemplate = response;
		});
	};

	vm.select = function (item, element, external) {
		const oldItem = vm.item;
		vm.item = item;
		vm.selectedElement = element;

		// Also inform viewChangeListeners since richDropdown is not bound to a form element.
		if (!external && oldItem !== item && vm.ngModelCtrl) {
			angular.forEach(vm.ngModelCtrl.$viewChangeListeners, function (listener) {
				// must wait until after .item's value change propogates to the original ngModel
				$timeout(listener);
			});
		}
	};

	vm.isSelected = (item) => {
		// if deep equality check doesnt work pass in a key to compare on instead
		if (vm.compareKey) {
			return item[vm.compareKey] === vm.item[vm.compareKey];
		}
		return item === vm.item;
	};

	vm.isDataLoading = function () {
		let result = false;

		if (angular.isArray(vm.collection) && vm.collection.hasOwnProperty('$resolved')) {
			result = !vm.collection.$resolved;
		}

		return result;
	};

	vm.showEmptyPlaceholder = function () {
		return vm.placeholderEmpty &&
			!vm.isDataLoading() &&
			angular.isArray(vm.collection) &&
			vm.collection.length === 0;
	};

	function generateId () {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return text;
	}

};
