import template from './real-time-captions.component.html';
import { RealTimeCaptionsController as controller } from './real-time-captions.controller';
import './style.less';

const bindings = {
	audioStream: '<',
	playerState: '<',
	mediaId: '<'
};

export const RealTimeCaptionsComponent = {
	bindings,
	controller,
	template
};
