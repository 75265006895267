import {
	ChangeDetectorRef,
	Component, ComponentFactoryResolver, ComponentRef, Inject, InjectionToken, OnDestroy, OnInit, TemplateRef, ViewChild
} from '@angular/core';
import { GoAnchorDirective } from 'ngx/go-modules/src/directives/go-anchor/go-anchor.directive';
import { ArrowAngle } from '../enums/arrow-angle.enum';
import type { ArrowDimensionStyle } from '../interfaces/arrow-dimension-style';
import type { GoTooltipData } from '../interfaces/go-tooltip-data';

export const GO_TOOLTIP_DATA = new InjectionToken<GoTooltipData>('GO_TOOLTIP_DATA');
@Component({
	selector: 'go-tooltip',
	template: require('./go-tooltip.component.html'),
	styles: [require('./go-tooltip.component.scss')]
})
export class GoTooltipComponent implements OnInit, OnDestroy {
	@ViewChild(GoAnchorDirective, {static: true})
	public goAnchor: GoAnchorDirective;

	public content: any;
	public isTemplate: boolean = false;
	public isAString: boolean = true;
	public dimension: ArrowDimensionStyle;
	public arrowAngleClass = 'go-tooltip-arrow-top';
	private componentRef: ComponentRef<any>;


	constructor (
		@Inject(GO_TOOLTIP_DATA) public data: GoTooltipData,
		private componentFactoryResolver: ComponentFactoryResolver,
		public cdr: ChangeDetectorRef
	) {
		this.isAString = typeof data.content === 'string';
		this.isTemplate = data.content instanceof TemplateRef;
		this.content = data.content;
	}

	public ngOnInit () {
		this.loadComponent();
	}

	public ngOnDestroy () {
		this.componentRef?.destroy();
	}

	public updatePosition (dimension: ArrowDimensionStyle, arrowAngle: ArrowAngle) {
		this.dimension = dimension;
		this.arrowAngleClass = arrowAngle;
		this.cdr.detectChanges();
	}

	public onTabbedOut ($event: KeyboardEvent) {
		this.data.onTabbedOut($event);
	}

	public onHoverEvent ($event) {
		this.data.onHoverEvent($event);
	}

	public loadComponent () {
		if(this.isAString) {
			return;
		}

		if (this.isTemplate) {
			const viewRef2 =  this.goAnchor.viewContainerRef;
			viewRef2.clear();
			viewRef2.createEmbeddedView(this.content);
			return;
		}

		const component = this.content as any;
		const cFactory = this.componentFactoryResolver.resolveComponentFactory<any>(component);
		const viewRef =  this.goAnchor.viewContainerRef;
		viewRef.clear();
		this.componentRef = viewRef.createComponent<any>(cFactory);
	}
}
