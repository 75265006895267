import { IScope } from 'angular';
import { EquipmentCheckStatus } from './status';
import { VideoSceneController } from 'go-modules/video-scene/video-scene.controller';
import { Destination } from 'go-modules/video-scene/destinations/destination';
import { VideoSceneOptions } from 'go-modules/video-scene/video-scene.options';
import { States } from 'go-modules/video-scene/state-emitter/state-emitter';
import { CameraSource, MicrophoneSource } from 'go-modules/video-scene/go-source';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { MediaSource } from '../../models/media';
interface Bindings {
	recorderOptions: VideoSceneOptions;
}

enum MicrophoneStatus {
	ACTIVE = 'active',
	FAILED = 'failed'
}

export class EquipmentCheckModalController implements Bindings, Partial<ng.ui.bootstrap.IModalScope> {
	public cameraCheckStatus: EquipmentCheckStatus;
	public microphoneCheckStatus: EquipmentCheckStatus;
	public internetConnectionStatus: EquipmentCheckStatus;
	public serverConnectionStatus: States;
	public recorderOptions: VideoSceneOptions;
	public whitelabel: boolean;
	public goRecorder: VideoSceneController;
	public $close: (result?: any) => boolean;
	public $dismiss: (result?: any) => boolean;
	public ready: boolean = false;

	public group_id: number;
	private environmentVarsService;

	/* @ngInject */
	constructor (
		private $scope: IScope,
		public helpUrls,
		private MediaModel,
		private $log: ng.ILogService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
		this.cameraCheckStatus = EquipmentCheckStatus.PENDING;
		this.microphoneCheckStatus = EquipmentCheckStatus.PENDING;
		this.internetConnectionStatus = EquipmentCheckStatus.PENDING;
	}

	/**
	 * Handle controller init life-cycle hook
	 */
	public $onInit () {
		if (!this.recorderOptions || !this.group_id) {
			throw new Error('Property binding `recorderOptions` is required');
		}
		this.whitelabel = this.environmentVarsService.get(EnvironmentVarsService.VAR.WHITELABEL) as boolean;

		this.MediaModel.model({
			group_id: this.group_id,
			source: MediaSource.OPENTOK,
			media_type: this.MediaModel.TYPE.VIDEO
		}).$save().then((media: any) => {
			return media.getStreamingConnectionInfo();
		}).then((info) => {
			this.recorderOptions[MediaSource.OPENTOK] = info;
			this.ready = true;
		}).catch((err) => {
			this.$log.error(err);
		});
	}

	/**
	 * Handle recorder init event
	 */
	public onRecorderInit (recorder: VideoSceneController) {
		this.goRecorder = recorder;

		recorder.on(CameraSource.event, (cameraStatus: string) => {
			let status = -1;
			if (cameraStatus === MicrophoneStatus.ACTIVE) {
				status = 1;
			} else if (cameraStatus === MicrophoneStatus.FAILED) {
				status = 0;
			}

			this.$scope.$evalAsync(() => {
				if(status === 1) {
					this.cameraCheckStatus = EquipmentCheckStatus.SUCCESS;
				} else if(status === 0) {
					this.cameraCheckStatus = EquipmentCheckStatus.FAILURE;
				}
			});
		});

		recorder.on(MicrophoneSource.event, (microphoneStatus: string) => {
			let status = -1;
			if (microphoneStatus === MicrophoneStatus.ACTIVE) {
				status = 1;
			} else if (microphoneStatus === MicrophoneStatus.FAILED) {
				status = 0;
			}

			this.$scope.$evalAsync(() => {
				if(status === 1) {
					this.microphoneCheckStatus = EquipmentCheckStatus.SUCCESS;
				} else if(status === 0) {
					this.microphoneCheckStatus = EquipmentCheckStatus.FAILURE;
				}
			});
		});

		recorder.on(Destination.event, (connectionStatus: States) => {
			this.$scope.$evalAsync(() => {
				this.serverConnectionStatus = connectionStatus;
			});
		});
	}

	public onConnectionStatus (goodConnection: boolean) {
		this.internetConnectionStatus = goodConnection ? EquipmentCheckStatus.SUCCESS : EquipmentCheckStatus.FAILURE;
	}

	/**
	 * Handle recorder init error event
	 */
	public onRecorderInitError () {
		this.cameraCheckStatus = EquipmentCheckStatus.FAILURE;
		this.microphoneCheckStatus = EquipmentCheckStatus.FAILURE;
	}

	public close () {
		this.$close();
	}

	/**
	 * Whether all the equipment checks have succeeded
	 */
	public isReady (): boolean {
		if (!this.goRecorder) {
			return false;
		}

		if (this.recorderOptions && !!this.recorderOptions.equipmentOnly) {
			return this.goRecorder.isEquipmentReady();
		}

		return this.goRecorder.isReady();
	}
}
