import { INgModelController } from 'angular';
import { RichDropdownController as controller } from './rich-dropdown.controller';
import template from './rich-dropdown.directive.html';

/* @ngInject */
export const richDropdown = ($templateRequest, $templateCache) => {
	return {
		restrict: 'EA',
		require: 'ngModel',
		template,
		replace: true,
		controller,
		controllerAs: 'richDropdown',
		scope: {
			item: '=ngModel',
			collection: '=',
			collectionKey: '@',
			collectionValue: '@',
			placeholder: '@',
			placeholderEmpty: '@',
			selectedTemplateUrl: '<',
			options: '<',
			compareKey: '<'
		},
		bindToController: true,
		link (scope, _element, attrs, ngModelController: INgModelController) {
			scope.richDropdown.ngModelCtrl = ngModelController;

			attrs.$observe('disabled', (value) => {
				scope.richDropdown.disabled = !!value;
			});

			scope.ariaLabelledby = attrs.labelledby;
			scope.ariaDescribedby = attrs.describedby;

			// Race-Condition Warning: This $watch works in tandem with the
			// $watches in the list items. By the order of the $digest cycle of angular, this
			// watch will fire first, reseting the selectedElement, and the list items will
			// fire afterwards setting the selectedElement to the correct item (or not at all)
			scope.$watch('richDropdown.item', (newValue, oldValue) => {
				if (newValue === oldValue) {
					return;
				}
				scope.richDropdown.selectedElement = null;
				ngModelController.$setDirty();
			});

			scope.getTemplate = ($scope) => {
				const itemTemplate = scope.$parent.$eval(attrs.itemTemplate, $scope);
				if (itemTemplate) {
					return itemTemplate;
				}

				const itemTemplateUrl = scope.$parent.$eval(attrs.itemTemplateUrl, $scope);
				if (itemTemplateUrl) {
					const cache = $templateCache.get(itemTemplateUrl);
					if (cache) {
						return cache;
					}

					return $templateRequest(itemTemplateUrl, true);
				}
			};

			scope.onClick = () => {
				scope.richDropdown.ngModelCtrl.$setTouched();
			};
		}
	};
};
