export enum EVENTS {
	PLAY = 'play',
	PAUSE = 'pause',
	SEEK = 'seek',
	TIME = 'time',
	COMPLETE = 'complete',
	ERROR = 'error',
	BUFFER = 'buffer',
	IDLE = 'idle',
	REQUEST_PLAY = 'requestPlay',
	REQUEST_PAUSE = 'requestPause',
	REQUEST_SEEK = 'requestSeek',
	SUBSCRIBER_CONNECTION_COUNT = 'subscriberCountUpdate',
	AUDIO_STREAM = 'audioStream',
	STATE_CHANGE = 'stateChange'
}
