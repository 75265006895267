import { isObject } from 'lodash';
import * as dayjs from 'dayjs';

/* @ngInject */
export function sessionModelTransformerService (
	User,
	MediaModel,
	SyncEvent,
	attachmentServiceTransformer
) {
	const dates = [
		'created_at',
		'viewed_recording_instructions_at',
		'started_at',
		'posted_at',
		'deleted_at',
		'archived_at'
	];

	const booleans = [
		'is_activity_placeholder',
		'have_i_commented'
	];

	const ints = [
		'activity_id',
		'session_id',
		'group_id',
		'status'
	];

	const floats = [
		'score'
	];

	// This function is only responsible for mutating data on a session
	const transformResponseData = function transformResponseData (data) {

		dates.forEach(function (dateField) {
			if (data.hasOwnProperty(dateField)) {
				data[dateField] = data[dateField] ? dayjs.utc(data[dateField]).toDate() : null;
			}
		});

		booleans.forEach(function (booleanField) {
			if (data.hasOwnProperty(booleanField)) {
				data[booleanField] = data[booleanField] > 0;
			}
		});

		ints.forEach(function (intField) {
			if (data.hasOwnProperty(intField)) {
				data[intField] = parseInt(data[intField], 10);
			}
		});

		floats.forEach(function (floatField) {
			if (data.hasOwnProperty(floatField)) {
				const value = parseFloat(data[floatField]);
				data[floatField] = isNaN(value) ? null : value;
			}
		});

		if (Array.isArray(data.presenters)) {
			data.presenters = User.setModels(data.presenters);
		}

		if (isObject(data.media)) {
			data.media = MediaModel.model(data.media);
			data.media_status = data.media.media_status;
			data.duration = data.media.duration;
		}

		if (isObject(data.source_media)) {
			data.source_media = MediaModel.model(data.source_media);
			data.source_media_id = data.source_media.media_id;
		}

		if (isObject(data.sync_events)) {
			data.sync_events = SyncEvent.modelizeAllTracks(data.sync_events);
		}

		if (Array.isArray(data.attachments)) {
			data.attachments = attachmentServiceTransformer.response[1](data.attachments);
		}

		return data;
	};

	const transformResponse = function transformResponse (response) {
		if (Array.isArray(response)) {
			response.forEach(function (session, index) {
				response[index] = transformResponseData(session);
			});
		} else {
			response = transformResponseData(response);
		}

		return response;
	};

	return {
		response: transformResponse
	};
}
