import { SessionCreationIntent } from '../intent';
import type { SessionCreationWizardOptions } from '../options';
import type { SessionCreationWizardStep } from './';

export class RecordStep implements SessionCreationWizardStep {

	/**
	 * Run this step
	 */
	public run (options: SessionCreationWizardOptions): ng.IPromise<void> {
		return options.openRecordView(options.session);
	}

	/**
	 * Determine whether this step is required
	 */
	public isRequired (options: SessionCreationWizardOptions): boolean {
		return options.intent === SessionCreationIntent.RECORD
			|| options.intent === SessionCreationIntent.CONTINUE;
	}
}
