import * as FullStory from '@fullstory/browser';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { FULLSTORY_EVENTS } from './fullstory.events';

/* @ngInject */
export class FullstoryService {
	public static readonly NG1_INJECTION_NAME = 'fullstoryService' as const;

	public userIdTracker: string;
	private FS = FullStory;

	/** @ngInject */
	constructor (
		private $cookies: ng.cookies.ICookiesService,
		private $q: ng.IQService,
		private $interval: ng.IIntervalService
	) {}

	public configure (user: any) {
		if (user.user_id === this.userIdTracker) return;
		this.identify(user);
	}

	public createEvent (eventName: FULLSTORY_EVENTS, payload: object) {
		this.FS.event(eventName, payload);
	}

	public identify (user: any) {
		if (this.$cookies.get('is_self_demo_experiment') !== 'true') {
			this.userIdTracker = user.user_id;
			this.FS.identify(user.user_id.toString(), {
				displayName: user.fullname || user.full_name,
				email: user.email
			});
		}
	}

	public clearUser () {
		this.userIdTracker = null;
		this.FS.anonymize();
	}

	/**
	 * Polls and resolves when Fullstory is initialized
	 */
	public async isReady (): Promise<void> {
		return this.$q((resolve) => {
			// Immediately resolve if initialized
			if (this.FS.isInitialized()) {
				resolve();
				return;
			}

			const intervalPromise = this.$interval(() => {
				if (this.FS.isInitialized()) {
					this.$interval.cancel(intervalPromise);
					resolve();
				}
			}, 100);
		});
	}
}

export const fullstoryToken = upgradeNg1Dependency(FullstoryService);
