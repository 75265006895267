import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';
import { PostDiscardModal } from './modal.factory';

export const postDiscardSessionModalModule = angular.module('modal.post-discard', [
	goModalBootstrapModule,
	angularTranslate
])
	.service('postDiscardSessionModal', PostDiscardModal)
	.name;
