import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

/* @ngInject */
export function RubricCanvasController ($scope, eventService: EventService) {
	$scope.sortableOptions = {
		group: {
			name: 'rubric-group',
			pull: false,
			put: true
		},
		animation: 150,
		draggable: '.rb-element-draggable',
		handle: '.rubric-element-handle',
		ghostClass: 'rb-element-placeholder',
		speakerId: 'rubric-ng-sortable-speaker',
		onAdd (info) {
			// Assign id to model
			info.model.id = Date.now();
		},
		onUpdate () {
			$scope.rubricController.validate();
		},
		onSort () {
			eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);
		}
	};
}
