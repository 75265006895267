export enum FULLSTORY_EVENTS {
	USER_CONTEXT_IDENTIFIED = 'User Context Identified',
	USE_TYPE_IDENTIFIED = 'Use Type Identified',
	CAPTIONS_STARTED = 'Real Time Captions Started',
	SCREENSHARE_66_33_LAYOUT = '66/33 Screen Share Layout Used',
	FEATURE_FLAG_ENABLED = 'Feature Flag Enabled',
	LTI_WARNING = 'LTI Launch Warning',
	NEW_LIBRARY_LOADED = 'New Library: Opened',
	NEW_LIBRARY_SELECT_MODE = 'New Library: Opened In Select Mode',
	NEW_LIBRARY_VIEWED_LEGACY = 'New Library: Viewed Legacy Collection',
	NEW_LIBRARY_MANAGE_COLLECTIONS = 'New Library: Opened Manage Collections Panel',
	NEW_LIBRARY_CREATE_NEW_COLLECTION = 'New Library: Open Create Collection Panel',
	NEW_LIBRARY_INVITE_FAILED = 'New Library: Invite To Collection Failed',
	NEW_LIBRARY_INVITE_SUCCESS = 'New Library: Invite To Collection Success',
	NEW_LIBRARY_CREATE_COLLECTION = 'New Library: Created Collection',
	NEW_LIBRARY_MODIFY_COLLECTION = 'New Library: Modified Collection',
	NEW_LIBRARY_TOGGLE_VISIBILITY = 'New Library: Collection Visibility Toggled',
	NEW_LIBRARY_COLLECTIONS_REORDERED = 'New Library: Collections Reordered',
	NEW_LIBRARY_CREATE_FOLDER_CLICK = 'New Library: Create Folder Clicked',
	NEW_LIBRARY_FOLDER_CREATED = 'New Library: Folder Created',
	NEW_LIBRARY_FOLDER_OPENED = 'New Library: Folder Expanded',
	NEW_LIBRARY_ADD_ASSIGNMENT = 'New Library: Add Assignment',
	NEW_LIBRARY_ADD_ASSIGNMENT_FROM_DASHBOARD = 'New Library: Add Assignment From Dashboard',
	NEW_LIBRARY_ASSIGNMENT_SOURCE_NEW = 'New Library: New Assignment Created',
	NEW_LIBRARY_ASSIGNMENT_SOURCE_LIBRARY = 'New Library: New Assignment Created From Library',
	NEW_LIBRARY_ASSIGNMENT_SOURCE_PREVIOUS = 'New Library: New Assignment Created From Previous',
};
