import * as angular from 'angular';

import { PubnubService } from './pubnub.service';
import { userNotificationsModule } from '../user-notifications';

export const goPubnubModule = angular.module('pubnub', [
	userNotificationsModule
])
	.service(PubnubService.NG1_INJECTION_NAME, PubnubService)
	.name;
