import * as angular from 'angular';
import * as qq from 'fine-uploader/s3.fine-uploader/s3.fine-uploader.js';
import * as s3FineUploader from 'fine-uploader/s3.fine-uploader/s3.fine-uploader.js';
import { FineUploaderService } from './fine-uploader.service';
import { FineUploaderController as controller } from './fine-uploader.controller';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

/* @ngInject */
export function fineUploaderDirective (
	$compile,
	$templateCache,
	fineUploader: FineUploaderService,
	$document,
	$translate: ng.translate.ITranslateService,
	featureFlag: FeatureFlag,
	eventService: EventService
) {

	if (!qq) {
		throw new Error('Fine uploader source file must be included');
	}

	// file type black list
	const fileTypeBlacklist = $templateCache.get(fineUploader.getUploadBlacklistId());

	/**
	 * Get template element
	 *
	 * @param templateId
	 * @returns {HTMLDivElement}
	 */
	function getTemplate (templateId) {

		// get template from cache
		const template = angular.element($templateCache.get(templateId))[0];

		// fine uploader requires that a list node with the
		// qq-upload-list-selector class be present,
		// so we will add it to the template, but hide it
		const listNode = $document[0].createElement('ul');
		listNode.className = 'qq-upload-list-selector';
		listNode.style.display = 'none';

		const listItemNode = $document[0].createElement('li');
		listNode.appendChild(listItemNode);

		const referenceNode = template.getElementsByClassName('qq-upload-button-selector')[0];
		referenceNode.parentNode.insertBefore(listNode, referenceNode.nextSibling);

		const wrapper = $document[0].createElement('div');
		wrapper.appendChild(template);

		return wrapper;
	}

	return {
		restrict: 'A',
		replace: true,
		scope: {options: '=fineUploader'},
		controller,
		controllerAs: 'fup',
		template: '<span class="fine-uploader-container"></span>',
		// bindToController: true,
		link (scope: Loose<ng.IScope>, element, _attrs, uploadController) {
			const templateId = fineUploader.getTemplateId();

			// default options
			// Reset them so that the feature flags can be
			// Determined at upload time
			fineUploader.reConfigure();
			const options = fineUploader.getS3Options();
			options.objectProperties.key = function (fieldId) {
				return scope.options.uniqueId + '.' + s3FineUploader.getExtension(this.getName(fieldId));
			};

			// template element
			options.template = getTemplate(templateId);

			// container element
			options.element = element[0];

			// Set feature flag in options
			if (scope.options?.request?.params) {
				const flags = featureFlag.getSynchronizedFlags();
				if (flags.length > 0) {
					scope.options.request.params.feature_flags = featureFlag.getSynchronizedFlags().join(',');
				}
			}

			// initialize fine uploader
			const uploader = new qq.s3.FineUploader(options);

			// we need to compile the element contents because
			// fine uploader manually injects the template
			// into the element container
			// Warning: don't attempt to seperate this directive out to a seperate compile/linking phase like I did.
			// qq.s3.FineUploader attaches event listeners to the dom it creates.
			// This should be done in the linking phase,
			// but as a 3rd party non-angular library it does it all in one go.
			// This means the element must not be transcluded,
			// as transclusion clones the elements, loosing the event listeners.
			$compile(element.contents())(scope);

			// Screen readers get bogged down by un-necessary `title` attributes
			// FineUploader is dumping one on the <input> element it is creating.
			// This removes that.
			const input = element[0].querySelector('.qq-upload-button input');
			input.removeAttribute('title');

			// Default text for button
			const descriptionText = $translate.instant('fine-uploader_upload-video-or-audio-file');
			input.setAttribute('aria-label', descriptionText);
			input.setAttribute('id', 'fine-uploader-file-input');
			input.setAttribute('aria-describedby', 'file-upload-error');

			// initialize main controller
			uploadController.init(uploader, fileTypeBlacklist);

			/**
			 * Upload start event handler
			 */
			uploadController.on('upload', function (file, key) {
				eventService.broadcast(EVENT_NAMES.FINE_UPLOADER_UPLOAD, {file, key});
			});


			/**
			 * Upload success event handler
			 */
			uploadController.on('success', function (file, key) {
				eventService.broadcast(EVENT_NAMES.FINE_UPLOADER_SUCCESS, {file, key});
			});

			/**
			 * Blacklist option change event handler
			 */
			scope.$watch('options.blacklist', function (list) {
				if (list) {
					const descriptionCaseParts = (list as string).split('/');
					list = $templateCache.get(list);
					uploadController.setOption('blackList', list);
					const descriptionCases = {
						'previewable-only-blacklist.json': 'fine-uploader_choose-different',
						'previewable-only-whitelist.json': 'fine-uploader_choose-different',
						'video-only-whitelist.json': 'fine-uploader_choose-video',
						'pdf-only-whitelist.json': 'fine-uploader_choose-pdf',
						'caption-only-whitelist.json': 'fine-uploader_upload-caption',
						'audio-description-blacklist.json': 'fine-uploader_upload-audio-description',
						'dangerous-file-blacklist.json': 'fine-uploader_upload-media-document',
						'library-document-blacklist.json': 'fine-uploader_choose-different',
						'zoom-whitelist.json': 'fine-uploader_upload-zoom-local-recording'
					};
					const defaultDescriptionKey = 'fine-uploader_upload-video-or-audio-file';
					const descriptionCase = descriptionCaseParts.pop();
					const descriptionKey = descriptionCases[descriptionCase] || defaultDescriptionKey;
					const descriptionTextTranslated = $translate.instant(descriptionKey);
					element[0].querySelector('input[type="file"]')
						.setAttribute('aria-label', descriptionTextTranslated);
				}
			});

			/**
			 * Validator fn outside of blacklist for more fine grained control
			 */
			scope.$watch('options.uploadMessageFn', function (list) {
				if (list) {
					uploadController.setOption('validator', list);
				}
			});

			/**
			 * Auto upload change event handler
			 */
			scope.$watch('options.autoUpload', function (value) {
				uploadController.setOption('autoUpload', value);
			}, true);

			/**
			 * Request option change event handler
			 */
			scope.$watch('options.request', function (request) {
				uploadController.setOption('request', request);
			}, true);

			/**
			 * Validation option change event handler
			 */
			scope.$watch('options.validation', function (validation) {
				uploadController.setOption('validation', validation);
			}, true);

			/**
			 * On destroy event handler
			 */
			scope.$on('$destroy', function () {
				uploadController.destroy();
			});
		}
	} as ng.IDirective;
}
