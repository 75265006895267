interface Defaults {
	instructions: {
		charLimit: number;
	};
}

export class ActivityInfoConfig implements ng.IServiceProvider {

	public defaults: Defaults;

	constructor () {
		this.defaults = {instructions: {charLimit: 100}};
	}

	/* @ngInject */
	public $get () {
		return this.defaults;
	}

}
