type MediaStreamKind = 'video' | 'audio' | 'other';

enum MediaStreamKinds {
	VIDEO = 'video',
	AUDIO = 'audio'
}

interface MediaStreamFactory {
	getId (): string;
	getType (): MediaStreamKind;
	getLabel (): string;
	getStream (options?: MediaTrackConstraints, otherFactories?: MediaStreamFactory[]): Promise<MediaStream>;
}

export { MediaStreamKind, MediaStreamKinds, MediaStreamFactory };
