import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';

/* @ngInject */
export function FineUploaderFileController ($scope, $timeout, eventService: EventService) {
	const vm = this;
	let file;

	vm.$onInit = () => {
		// initialize file title to file name
		file = $scope.file;
		file.title = file.name;
		vm.mode = '';
		/**
		 * Cancel all event handler
		 */
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.FINE_UPLOADER_CANCEL_ALL))
			.subscribe((ev: GoEvent) => {
				const media = ev.data;
				// When the `$uploading` method is absent, this tells us that the fine uploader
				// lib has already removed the helper properties/methods from file object.
				$timeout(() => {
					if (file.hasOwnProperty('$uploading')) {
						if (file.$uploading()) {
							vm.confirmAbort();
							media.$delete();
						}
					}
				});
			});
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	/**
	 * Request upload abort
	 */
	vm.requestAbort = function () {
		if (file.$uploading()) {
			vm.mode = 'abort';
		} else {
			vm.confirmAbort();
		}
	};

	/**
	 * Confirm upload abort
	 */
	vm.confirmAbort = function () {
		file.$cancel();
		vm.mode = '';
	};

	/**
	 * Cancel upload abort
	 */
	vm.cancelAbort = function () {
		vm.mode = '';
	};

	/**
	 * On title change event handler
	 */
	vm.onTitleChange = function () {
		$scope.fup.extendParams({media_title: file.title}, file.$id());
	};
}
