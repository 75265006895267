import * as angular from 'angular';

/* @ngInject */
export function dateRangeDirective ($parse, dateDiffExceedsLimit) {
	return {
		restrict: 'A',
		require: 'ngModel',
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		link: function link (scope, elem, attrs, ngModelCtrl) {

			if (!$parse(attrs.monthRangeLimit)(scope)) {
				throw new Error('monthRangeLimit is required');
			}

			// Watch changes to passed property, cuz we need to re-run our validator when it changes
			const watcher = scope.$watch(function () {
				return $parse(attrs.comparisonDate)(scope);
			}, function (newVal, oldVal) {
				if (newVal !== oldVal) {
					ngModelCtrl.$validate();
				}
			});

			scope.$on('$destroy', watcher);

			ngModelCtrl.$validators.dateRange = function (modelVal) {
				const comparisonDate = $parse(attrs.comparisonDate)(scope);
				if (!angular.isDate(modelVal) || !angular.isDate(comparisonDate)) {
					return true;
				}

				const monthRangeLimit = parseInt($parse(attrs.monthRangeLimit)(scope), 10);

				return !dateDiffExceedsLimit(modelVal, comparisonDate, monthRangeLimit);
			};
		}
	};
}
