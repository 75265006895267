import * as angular from 'angular';
import { FeedbackSessionControllerClass } from '../feedback-session.controller';
import { SessionFeedbackClass } from '../services/session-feedback.factory';
import { FeedbackBaseModelClass } from 'go-modules/models/base/base.factory';
import { FeedbackSessionComponentScope } from '../feedback-session.component';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { ResponsiveViewService } from 'go-modules/responsive-view/responsive-view.service';

export interface Bindings {
	readonly mainController: FeedbackSessionControllerClass;
}

export interface Locals {
	feedbackSession: any;
	mainController: FeedbackSessionControllerClass;
}

export interface FeedbackSessionContentControllerScope extends FeedbackSessionComponentScope, Locals {}

export declare class FeedbackSessionContentControllerClass implements ng.IController, Bindings {
	public readonly mainController: FeedbackSessionControllerClass;
	public settings: any;
	public eventSubscription: any;
	public eventNames: string[];
	public isMobileView: boolean;

	public $onInit (): void;
	public $onDestroy(): void;
	public addFeedbackItem (feedbackItem: FeedbackBaseModelClass): void;
	public deleteFeedbackItem (feedbackItem: FeedbackBaseModelClass): ng.IPromise<void>;
	public getFeedback (): ng.IPromise<{[type: string]: FeedbackBaseModelClass[]}>;
	public isInAsyncSlideEditMode (): boolean;
	public removeSyncEventComments (): void;
	public removeFeedbackItem (feedbackItem: FeedbackBaseModelClass): void;
	public saveFeedbackItem (feedbackItem: FeedbackBaseModelClass): ng.IPromise<void>;
	public shouldDisplayMediaPane (): boolean;
	public shouldDisplayFeedbackDisplayPanel (): boolean;
	public isMobileSize(): boolean;
}

/* @ngInject */
export function FeedbackSessionContentController (
	$scope: FeedbackSessionContentControllerScope,
	feedbackSession,
	SessionFeedback: typeof SessionFeedbackClass,
	MediaModel,
	eventService: EventService,
	responsiveView: ResponsiveViewService
) {
	const vm: FeedbackSessionContentControllerClass = this;

	/**
	 * Controller init handler
	 */
	vm.$onInit = () => {
		vm.settings = feedbackSession;

		// TODO: Expose these controllers on the scope until we refactor the feedback-creator, etc...
		$scope.mainController = vm.mainController;
		$scope.feedbackSession = feedbackSession;

		// Toggle feedbackTools to false if mobile
		vm.isMobileSize();

		// Immediately get all feedback
		vm.getFeedback();

		vm.eventNames = [
			EVENT_NAMES.MEDIA_SYNC,
			EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_DELETED,
			EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_SAVED,
			EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_REMOVED
		];
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => vm.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.MEDIA_SYNC:
						const rootComment = vm.mainController.sessionFeedback[SessionFeedback.KIND.COMMENT];
						rootComment.getAllDescendentsFlat()
							.flatMap((comment) => comment.resource ?? [])
							.filter((resource) => resource.item instanceof MediaModel)
							.filter((mediaResource) =>
								parseInt(mediaResource.item.media_id, 10) === parseInt(ev.data.media_id, 10)
							)
							.forEach((matchingResource) => {
								Object.assign(matchingResource.item, ev.data);
							});
						break;
					case EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_DELETED:
						vm.mainController.sessionFeedback.remove(ev.data);
						eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_DELETED, ev.data);
						break;
					case EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_SAVED:
						eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_SAVED, ev.data);
						break;
					case EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_REMOVED:
						eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_REMOVED, ev.data);
						break;
				}
			});
	};

	vm.$onDestroy = () => {
		vm.eventSubscription.unsubscribe();
	};

	/**
	 * Get the feedback for this session
	 *
	 * @returns {Object}
	 */
	vm.getFeedback = () => {
		const promise = vm.mainController.sessionFeedback.get();
		promise.then((feedback) => {
			eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_LOADED, feedback);
		});

		return promise;
	};

	/**
	 * Add feedback item to session
	 *
	 * @param feedbackItem
	 */
	vm.addFeedbackItem = (feedbackItem) => {
		vm.mainController.sessionFeedback.add(feedbackItem);
		eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_ADDED, feedbackItem);
	};

	/**
	 * Remove feedback item from session
	 *
	 * @param feedbackItem
	 */
	vm.removeFeedbackItem = (feedbackItem: FeedbackBaseModelClass): void => {
		vm.mainController.sessionFeedback.remove(feedbackItem);
		eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_REMOVED, feedbackItem);
	};

	/**
	 * Save feedback item
	 *
	 * @param feedbackItem
	 */
	vm.saveFeedbackItem = (feedbackItem: FeedbackBaseModelClass): ng.IPromise<void> => {
		return vm.mainController.sessionFeedback.save(feedbackItem).then((item) => {
			eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_SAVED, item);
		});
	};

	/**
	 * Delete feedback item
	 *
	 * @param feedbackItem
	 */
	vm.deleteFeedbackItem = (feedbackItem: FeedbackBaseModelClass): ng.IPromise<void> => {
		return vm.mainController.sessionFeedback.delete(feedbackItem).then(() => {
			eventService.broadcast(EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_DELETED, feedbackItem);
		});
	};

	/**
	 * Remove all comments of a given type
	 *
	 * @param type
	 * @returns {*}
	 */
	vm.removeSyncEventComments = () => {
		const commentsToDelete = vm.mainController.sessionFeedback.getComments().filter((item: any) => {
			return item.isSyncEventType();
		});
		angular.forEach(commentsToDelete, (comment) => {
			vm.removeFeedbackItem(comment);
		});
	};

	/**
	 * Whether we are in async slide edit mode
	 */
	vm.isInAsyncSlideEditMode = () => {
		return vm.mainController.playerSync &&
			vm.mainController.playerSync.isCaptureMode() &&
			vm.mainController.sourceMedia &&
			vm.mainController.sourceMedia.isDocument() &&
			!vm.mainController.activity.isLiveSessionEnabled() &&
			vm.mainController.activity.isSlidesEnabled();
	};

	/**
	 * Hide media pane when there is no media to speak of
	 * and the activity says there is no response media
	 */
	vm.shouldDisplayMediaPane = (): boolean => {
		return vm.mainController.hasOrRequiresResponseMedia();
	};

	vm.shouldDisplayFeedbackDisplayPanel = (): boolean => {
		return !vm.isInAsyncSlideEditMode() &&
			!vm.mainController.activity.isConversationBoard();
	};

	vm.isMobileSize = (): boolean => {
		const view = responsiveView.showView('FEEDBACK_PANEL_MOBILE_VIEW');
		if (view && this.isMobileView !== view && !vm.mainController.activity.isConversationBoard()) {
			feedbackSession.toggleFeedbackTools(false);
		}
		this.isMobileView = view;
		$scope.$evalAsync();
		return this.isMobileView;
	};
}
