import * as angular from 'angular';
import * as angularResource from 'angular-resource';
import { featureFlagModule } from 'go-modules/feature-flag';

import { UserGroupModel } from './user-group.factory';

export const userGroupModel = angular.module('go.models.userGroup', [
	angularResource,
	featureFlagModule
])
	.factory(UserGroupModel.NG1_INJECTION_NAME, UserGroupModel)
	.name;
