import * as angular from 'angular';
import { GroupsIndexService } from './groups-index.service';
import { groupDepModel } from 'go-modules/models/group-dep';

export const groupsIndexModule = angular
	.module('groups-index', [
		groupDepModel
	])
	.service(GroupsIndexService.NG1_INJECTION_NAME, GroupsIndexService)
	.name;
