import { GoRecorderTimer } from './go-recorder-timer';
import { DeferredGoRecorder } from './go-recorder-deferred';

/* @ngInject */
export function TimingOrchestratorService (
	feedbackSession,
	mediaPlayer,
	MediaPlayerTimer,
	goRecorderManagerService,
	Timer,
	PlayerSync,
	$rootScope
) {
	return function TimingOrchestrator (session) {
		const timer = getTimer(), playerSync = new PlayerSync(timer);

		let wasActive = playerSync.isPlaying(), watcherInitialized = false;
		// eslint-disable-next-line prefer-const
		let unwatch;

		/**
		 * Wraps player sync pause functionality
		 */
		playerSync.halt = function () {
			// Capture whether player sync was playing before pause. Also protect
			// the `wasActive` flag from getting turned off unintentionally in cases
			// where halt is called multiple times in a row.
			if (playerSync.isPlaying()) {
				wasActive = playerSync.isPlaying();
				playerSync.pause();
			}
		};

		/**
		 * It will only play if it was previously
		 * active. This can easily be overridden by
		 * setting the ignoreWasActiveCheck flag.
		 *
		 * @param ignoreWasActiveCheck
		 */
		playerSync.resume = function (ignoreWasActiveCheck) {
			if (wasActive || ignoreWasActiveCheck) {
				wasActive = false;
				playerSync.play();
			}
		};

		/**
		 * Override the destroy function so that when
		 * player sync gets destroyed we also unwatch
		 * the timer validation change event watcher.
		 */
		const destroyFn = playerSync.destroy.bind(playerSync);
		playerSync.destroy = function () {
			unwatch();
			destroyFn();
		};

		// Validate the player sync internal timing mechanism to ensure it hasn't gone stale.
		// The only reason we have to do this is because the master timer is actually
		// a player instance underneath. When the player instance gets destroyed, player
		// sync loses its reference to the right master timer player instance. We can
		// remove this line once the master timer is just a plain javascript timer.
		unwatch = $rootScope.$watch(function () {
			return getTimingMechanism();
		}, function () {
			if (watcherInitialized) {
				playerSync.setMasterTimer(getTimer());
			} else {
				watcherInitialized = true;
			}
		});

		/**
		 * Resolve which timing mechanism will be used for player sync.
		 *
		 * In record mode, we will treat the session recorder
		 * as a timer. In playback mode we will treat the session
		 * player as the timer.
		 *
		 * @returns {*}
		 */
		function getTimingMechanism () {
			let mechanism;
			if (feedbackSession.isRecordMode()) {
				mechanism = goRecorderManagerService.get(feedbackSession.sessionRecorderId(session));
			} else if (feedbackSession.isLiveMode()) {
				mechanism = Timer.get(feedbackSession.liveTimerId(session), Number.POSITIVE_INFINITY);
			} else {
				mechanism = mediaPlayer.get(feedbackSession.sessionPlayerId(session));
			}
			return mechanism;
		}

		/**
		 * Get timer instance
		 *
		 * @returns {*}
		 */
		function getTimer () {
			let timer;
			const mechanism = getTimingMechanism();

			if (mechanism instanceof Timer) {
				timer = mechanism;
			} else if (mechanism instanceof DeferredGoRecorder) {
				timer = new GoRecorderTimer(mechanism);
			} else {
				timer = new MediaPlayerTimer(mechanism);
			}

			return timer;
		}

		return playerSync;
	};
}
