import { IDeferred, IQService } from 'angular';
import { DeferredGoRecorder } from './go-recorder-deferred';
import { VideoSceneController } from 'go-modules/video-scene/video-scene.controller';

interface GoRecorderStore {
	[id: string]: DeferredGoRecorder;
}

interface GoRecorderDeferredStore {
	[id: string]: IDeferred<VideoSceneController>;
}

const recorderStore: GoRecorderStore = {};
const deferredStore: GoRecorderDeferredStore = {};

export class GoRecorderManagerService {

	/* @ngInject */
	constructor (private $q: IQService) {}

	public get (id: string): DeferredGoRecorder {
		if (!recorderStore[id]) {
			deferredStore[id] = this.$q.defer();
			recorderStore[id] = new DeferredGoRecorder(deferredStore[id].promise);
		}

		return recorderStore[id];
	}

	public register (id: string, recorder: VideoSceneController): DeferredGoRecorder {
		const deferredGoRecorder: DeferredGoRecorder = this.get(id);
		deferredStore[id].resolve(recorder);
		return deferredGoRecorder;
	}

	public unregister (id: string): void {
		delete recorderStore[id];
		delete deferredStore[id];
	}
}
