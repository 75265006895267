import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { Observable } from 'rxjs';
import type { Comment, CommentReaction } from 'ngx/go-modules/src/interfaces/comments/comment';

@Injectable({
	providedIn: 'root'
})
export class NgxCommentService {
	constructor (
		private http: HttpClient
	) {}

	public save (sessionId: number, payload): Observable<Comment> {
		return this.http.post<Comment>(
			`${clientSettings.GoReactV2API}/submissions/${sessionId}/comments`,
			payload
		);
	}

	public addReaction (commentId: number, payload): Observable<CommentReaction> {
		return this.http.post<CommentReaction>(`${clientSettings.GoReactV2API}/comments/${commentId}/reactions`, payload);
	}

	public deleteReaction (commentId: number, reactionId: number): Observable<any> {
		return this.http.delete(`${clientSettings.GoReactV2API}/comments/${commentId}/reactions/${reactionId}`);
	}
}
