import * as angular from 'angular';
import template from './rich-dropdown-selection.directive.html';

/* @ngInject */
export const richDropdownSelection = function ($timeout, $templateCache, $compile, translateFilter) {
	return {
		restrict: 'EA',
		require: '^richDropdown',
		template,
		replace: true,
		scope: true,
		link (scope, element, _attrs, controller) {
			const templateUrl = scope.richDropdown && scope.richDropdown.selectedTemplateUrl;
			if (templateUrl) {
				const selectedTemplate = templateUrl && $templateCache.get(scope.richDropdown.selectedTemplateUrl);
				element.append(selectedTemplate);
				$compile(element.contents())(scope);
			} else {
				scope.$watch(() => controller.selectedElement, (listItemElement) => {
					// wait for next digest cycle so that we can ensure that the view
					// value is updated by this point
					$timeout(() => {
						element.empty();

						if (!controller.item || !listItemElement) {
							const placeholder = controller.placeholder || translateFilter('rich-dropdown-no-select');
							element.append(angular.element('<span class="rich-dropdown-placeholder">' + placeholder + '</span>'));
							return;
						}

						const clonedChildren = listItemElement.children().clone();
						// Strip `href` attribute if it exists. That way the text
						// inside doesn't become tab focused enabled.
						angular.forEach(clonedChildren, function (child) {
							if (child.hasAttribute('href')) {
								child.removeAttribute('href');
							}
						});
						element.append(clonedChildren);
					});
				});
			}
		}
	};
};
