import template from './item-renderer.component.html';
import { SlideDeckItemRendererController as controller } from './item-renderer.controller';

export const slideDeckItemRenderer = {
	template,
	controller,
	bindings: {
		url: '<ngModel',
		label: '@',
		hideFooter: '<',
		pageNumber: '=pageNumber',
		onDocumentReady: '&'
	}
};

