import template from './editor.directive.html';

/* @ngInject */
export function labelInputEditor () {
	return {
		restrict: 'E',
		require: '^labelInput',
		template,
		scope: {
			type: '@type',
			value: '=ngModel',
			ariaLabel: '@',
			fractional: '@',
			format: '@'
		},
		controller: () => {},
		controllerAs: 'editorController',
		bindToController: true
	};
}
