import { Injector, Injectable, ElementRef, RendererFactory2, Renderer2 } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig, ConnectedPosition } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';

import { GoDialogRef } from '../go-dialog-ref/go-dialog-ref';
import { GoSidepanelComponent, GO_SIDEPANEL_DATA } from './component/go-sidepanel.component';
import type { GoSidePanelOptions } from './interfaces/go-sidepanel-options';

/**
 * A service used to open a provided component in our standard GoReact sidepanel.
 * Upon opening the sidepanel you get a reference to the panel itself.
 */
@Injectable({
	providedIn: 'root'
})
export class GoSidepanelService {
	private options: GoSidePanelOptions;
	private renderer: Renderer2;
	private resizeListener: (() => void) | null = null;

	constructor (
		private injector: Injector,
		private overlay: Overlay,
		private rendererFactory: RendererFactory2
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	/**
	 * Opens a provided component in a GoReact sidepanel.
	 * The sidepanel automatically sizes based on screen size.
	 *
	 * By default the panel will not close on backdrop click.
	 *
	 * **Usage:**
	 * ```
	 * const dialogRef = sidepanelService.open(MyComponent, GoSidePanelOptions);
	 * dialogRef.afterClosed().subscribe((result) => {
	 *   // The panel has now closed
	 *   // You can do something with the optional result
	 * });
	 *
	 * Note: If the Component that you want to open has @Input in it and you want to set value of that variable.
	 * Example component: TestComponent { @Input name = ''; };
	 *
	 * const dialogRef = sidepanelService.open(TestComponent, {inputs: {name: 'value'}});
	 * ```
	 */
	public open<T> (
		component: ComponentType<T>,
		options?: GoSidePanelOptions,
		parentElement?: HTMLElement
	): GoDialogRef {
		this.options = {
			closeOnBackdropClick: false,
			closeOnEscape: false,
			title: null,
			noFooter: false,
			panelClass: [],
			...(options ?? {})
		};

		// Returns an OverlayRef which is a PortalHost
		const overlayRef = this.createOverlay(parentElement);

		// Instantiate remote control
		const dialogRef = new GoDialogRef(overlayRef);

		this.attachDialogContainer<T>(overlayRef, component, dialogRef);

		overlayRef.backdropClick().subscribe(() => {
			if (this.options.closeOnBackdropClick) {
				dialogRef.close();
			}
		});

		if (parentElement) {
			this.listenToResize(overlayRef, parentElement);

			dialogRef.afterClosed().subscribe(() => {
				// clean up listener on close
				this.resizeListener();
			});
		}

		return dialogRef;
	}

	private createOverlay (parentElement?: HTMLElement) {
		const overlayConfig = this.getOverlayConfig(parentElement);
		return this.overlay.create(overlayConfig);
	}

	private attachDialogContainer<T> (overlayRef: OverlayRef, component: ComponentType<T>, dialogRef: GoDialogRef) {
		const containerPortal = this.portal(component, dialogRef);
		const containerRef = overlayRef.attach(containerPortal);
		return containerRef.instance;
	}

	private createInjector (component, dialogRef: GoDialogRef): Injector {
		return Injector.create({
			parent: this.injector,
			providers: [
				{
					provide: GO_SIDEPANEL_DATA,
					useValue: {
						content: component,
						options: this.options
					}
				},
				{
					provide: GoDialogRef,
					useValue: dialogRef
				}
			]
		});
	}

	private portal (component: ComponentType<any>, dialogRef: GoDialogRef) {
		return new ComponentPortal (
			GoSidepanelComponent,
			null,
			this.createInjector(component, dialogRef)
		);
	}

	private getOverlayConfig (parentElement?: HTMLElement): OverlayConfig {
		let positionStrategy;

		if (parentElement) {
			const element = new ElementRef(parentElement);
			positionStrategy = this.overlay
				.position()
				.flexibleConnectedTo(element)
				.withFlexibleDimensions(false)
				.withPositions([
					{ originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top' }  as ConnectedPosition
				]);
		} else {
			positionStrategy = this.overlay
				.position()
				.global()
				.right('0px')
				.top('0px')
				.bottom('0px');
		}

		const overlayConfig = new OverlayConfig({
			hasBackdrop: true,
			backdropClass: 'overlay-backdrop',
			panelClass: ['overlay-panel', ...this.options.panelClass],
			height: parentElement ? parentElement.offsetHeight : '100%',
			scrollStrategy: this.overlay.scrollStrategies.block(),
			positionStrategy
		});

		return overlayConfig;
	}

	private listenToResize (overlayRef: OverlayRef, parentElement: HTMLElement): void {
		this.resizeListener = this.renderer.listen('window', 'resize', () => {
			const height = `${parentElement.offsetHeight}px`;
			overlayRef.updateSize({ height });
		});
	}
}
