/* @ngInject */
export function rubricCheckbox () {
	return {
		require: 'ngModel',
		link (scope, _el, attr) {
			scope.$watch('element.value', function (val) {
				attr.ngTrueValue = val + '';
			});
		}
	};
}
