import template from './view.component.html';
import { PlaybackViewController } from './view.controller';

export const playbackViewComponent = {
	bindings: {
		media: '<'
	},
	controller: PlaybackViewController,
	require: {
		mainController: '^feedbackSession',
		mediaDisplayController: '^mediaDisplay',
		singleMediaDisplayController: '?^singleMediaDisplay'
	},
	template
};
