
export const FOCUSABLE_ELEMENTS = `a:not([tabindex="-1"]),
	button:not([tabindex="-1"]),
	[href]:not([tabindex="-1"]),
	input:not([tabindex="-1"]),
	select:not([tabindex="-1"]),
	textarea:not([tabindex="-1"]),
	[tabindex]:not([tabindex="-1"])`;

export class FocusableElementsUtil {
	public static getAllFrom (element: HTMLElement): HTMLElement[] {
		return [...element.querySelectorAll(FOCUSABLE_ELEMENTS)]
			.filter((elem: HTMLButtonElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
				const displayNone = window.getComputedStyle(elem).display === 'none';
				const visibilityHidden = window.getComputedStyle(elem).visibility === 'hidden';
				const disabled = elem.disabled;
				return !displayNone && !visibilityHidden && !disabled;
			}) as HTMLElement[];
	}

	public static getLastFocusableElement (element: HTMLElement): HTMLElement | null {
		const elements = FocusableElementsUtil.getAllFrom(element);

		return elements.length ? elements[elements.length -1] : null;
	}

	public static getFirstFocusableElement (element: HTMLElement): HTMLElement | null {
		const elements = FocusableElementsUtil.getAllFrom(element);

		return elements.length ? elements[0] : null;
	}
}
