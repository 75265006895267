import template from './selector.component.html';
import { SessionActivitySelectorController } from './selector.controller';

export const SessionActivitySelectorComponent = {
	template,
	controller: SessionActivitySelectorController,
	require: {
		ngModelController: 'ngModel'
	},
	bindings: {
		labelText: '@',
		showPopover: '<',
		activity: '=ngModel',
		group: '<',
		onChange: '&',
		isRootUser: '<',
		disabled: '<',
		inline: '<'
	}
};
