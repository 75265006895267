import * as angular from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';

/* @ngInject */
export function RubricEditorModalController (
	$scope,
	eventService: EventService
) {

	const vm = this;

	vm.$onInit = () => {
		if (!angular.isObject($scope.options)) {
			throw new Error('RubricEditorModalController::must supply options object');
		}

		if ($scope.options.previewOnly && $scope.options.mode === 'edit') {
			throw new Error('RubricEditorModalController:: cannot be in edit mode with previewOnly flag');
		}

		$scope.rubricTemplate = {};
		$scope.previewOnly = $scope.options.previewOnly;
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	/**
	 * Change mode
	 *
	 * @param mode
	 */
	$scope.changeMode = function (mode) {
		$scope.options.mode = mode;
	};

	/**
	 * Toggle mode
	 */
	$scope.toggleMode = function () {
		const mode = $scope.options.mode === 'live' ? 'edit' : 'live';
		$scope.changeMode(mode);
	};

	/**
	 * Close rubric editor
	 */
	$scope.closeEditor = function () {
		if ($scope.rubricTemplate.id) {
			$scope.$close($scope.rubricTemplate);
		} else {
			$scope.$dismiss();
		}
	};

	$scope.saveAndClose = function () {
		if ($scope.rubricTemplate.id && !$scope.changed) {
			$scope.$dismiss();
		} else if (!$scope.changed) {
			$scope.$close($scope.rubricTemplate);
		} else {
			eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE);
		}
	};

	vm.eventNames = [
		EVENT_NAMES.RUBRIC_READY,
		EVENT_NAMES.RUBRIC_CHANGE,
		EVENT_NAMES.RUBRIC_SAVE_SCHEMA_DONE,
		EVENT_NAMES.RUBRIC_SAVE_SCHEMA_START
	];
	vm.eventSubscription = eventService.events
		.pipe(filter((ev: GoEvent) => vm.eventNames.includes(ev.name)))
		.subscribe((ev: GoEvent) => {
			switch (ev.name) {
				case EVENT_NAMES.RUBRIC_READY:
					$scope.rubricTemplate = ev.data;
					break;
				case EVENT_NAMES.RUBRIC_CHANGE:
					$scope.changed = true;
					$scope.rubricTemplate = ev.data;

					if (angular.isFunction($scope.onRubricChange)) {
						$scope.onRubricChange(ev.data);
					}
					break;
				case EVENT_NAMES.RUBRIC_SAVE_SCHEMA_DONE:
					$scope.saving = false;
					if (ev.data) {
						if ($scope.rubricTemplate.id) {
							$scope.$close($scope.rubricTemplate);
						} else {
							$scope.$dismiss();
						}
					}
					break;
				case EVENT_NAMES.RUBRIC_SAVE_SCHEMA_START:
					$scope.saving = true;
					break;
			}
		});
}
