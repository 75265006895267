export class VideoVolumeManager {
	private context: AudioContext;
	private source: MediaElementAudioSourceNode;
	private destination: MediaStreamAudioDestinationNode;
	private gainNode: GainNode;
	private gainConnected: boolean = false;

	constructor (private video: HTMLVideoElement) {}

	public getUngainedStream (): MediaStream {
		if (this.destination == null) {
			// Initalize all the audio context nodes
			this.context = new (window.AudioContext || window.webkitAudioContext)();
			this.source = this.context.createMediaElementSource(this.video);
			this.destination = this.context.createMediaStreamDestination();
			this.gainNode = this.context.createGain();

			// Connect the video's audio to an ungained destination stream that we'll return
			this.source.connect(this.destination);

			// Connect the video's audio to a gain.
			// The muted will either connect/disconnect that to the user's speakers
			this.source.connect(this.gainNode);

			// Switch to gain control
			this.setGainVolume(this.getVideoVolume());
			this.setGainMuted(this.getVideoMuted());
			this.setVideoVolume(1);
			this.setVideoMuted(false);
			this.getMuted = this.getGainMuted;
			this.setMuted = this.setGainMuted;
			this.getVolume = this.getGainVolume;
			this.setVolume = this.setGainVolume;
		}

		return this.destination.stream;
	}

	public async destroy (): Promise<void> {
		this.destination?.stream.getTracks().forEach((track) => track.stop());
		this.destination?.disconnect();
		this.gainNode?.disconnect();
		this.source?.disconnect();
		await this.context?.close();
	}

	/* We use these methods before we initalize the gain node */
	private getVideoMuted = (): boolean => {
		return this.video.muted;
	};

	private setVideoMuted = (muted: boolean): void => {
		this.video.muted = muted;
	};

	private getVideoVolume = (): number => {
		return this.video.volume;
	};

	private setVideoVolume = (volume: number): void => {
		this.video.volume = volume;
	};

	/* We use these methods after we initalize the gain node */
	private getGainMuted = (): boolean => {
		return !this.gainConnected;
	};

	private setGainMuted = (muted: boolean): void => {
		// We don't want to reconnect or redisconnect
		if (this.gainConnected === !muted) {
			return;
		}

		this.gainConnected = !muted;
		const toggleConnect = !muted ? 'connect' : 'disconnect';
		this.gainNode[toggleConnect](this.context.destination);
	};

	private getGainVolume = (): number => {
		return this.gainNode.gain.value;
	};

	private setGainVolume = (volume: number): void => {
		this.gainNode.gain.value = volume;
	};

	// Aliases to the methods we are currently using, have to be after the implementions
	/* eslint-disable @typescript-eslint/member-ordering */
	public getMuted = this.getVideoMuted;
	public setMuted = this.setVideoMuted;
	public getVolume = this.getVideoVolume;
	public setVolume = this.setVideoVolume;
	/* eslint-enable @typescript-eslint/member-ordering */
}
