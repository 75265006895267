
export interface InstructionDataInterface {
	text: string;
	type: string;
	media: any;
}

export class InstructionsPreviewModalController {

	public instruction: InstructionDataInterface;

	public $close: () => void;

	/* @ngInject */
	constructor () {}

	public $onInit () {}
}
