import { Subject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import * as fuzzysort from 'fuzzysort';
import { ITimeoutService } from 'angular';
import { KeyCode } from 'go-modules/feedback-session/feedback-tree/feedback-tree.value';

export enum TypeaheadSize {
	LARGE = 'large',
	DEFAULT = 'default'
}
export interface Bindings {
	items: any[];
	fuzzySearchKeys: string[];
	ngModelCtrl?: ng.INgModelController;
	selectedItem: any;
	onSelect?: ({item: any}) => void;
	placeholder?: string;
	size?: TypeaheadSize | null;
	disabled?: boolean;
	allowCustom?: boolean;
	minCharacterCount?: number;
}

export const LIST_LIMIT = 10;
const IGNORE_WORDS = [
	'the',
	'of'
];
const IGNORE_REGEX = new RegExp(`\\b(${IGNORE_WORDS.join('|')})\\b`, 'gi');

export class GoTypeaheadSelectController implements Bindings, ng.IOnInit, ng.IOnChanges, ng.IOnDestroy {

	public searchTerm: string;
	public limit: number;
	public openDropdown: boolean;
	public searchName: string;
	public list: any[];

	// Bindings
	public items: any[];
	public selectedItem: any;
	public fuzzySearchKeys: string[];
	public ngModelCtrl: ng.INgModelController;
	public onSelect: ({item: any}) => void;
	public placeholder: string;
	public size: TypeaheadSize;
	public allowCustom: boolean;
	public disabled: boolean;
	public minCharacterCount: number;

	private searchTerm$: Subject<string>;
	private searchList$: Observable<any[]>;
	private searchSubscriber: Subscription;

	/* @ngInject */
	constructor (
		private $timeout: ITimeoutService,
		private $element: JQLite
	) {}

	public $onInit () {
		this.limit = LIST_LIMIT;
		this.openDropdown = false;

		if (!Number.isInteger(this.minCharacterCount)) {
			this.minCharacterCount = 0;
		}

		// Set size if not set
		if (!this.size) {
			this.size = TypeaheadSize.DEFAULT;
		}

		if (this.allowCustom) {
			this.searchName = 'selectedItem';
		} else {
			this.searchName = 'searchTerm';
		}

		// If allowCustom is used, list must be all strings
		if (this.allowCustom) {
			const allStrings = this.items.every((item) => typeof item === 'string');
			if (!allStrings) {
				throw new Error('If using allow-custom flag, list must be all strings');
			}
		}

		this.$element.addClass(this.size);

		// Init our observable list of search results and subscribe
		this.initSearchList(this.items);
		this.searchSubscriber = this.searchList$.subscribe((results: any[]) => {
			this.list = results;
		});

		// Is a model pre-selected?
		if (this.selectedItem) {
			this.select(this.selectedItem);
		}

		// Set up list initially so some results show
		this.list = this.items;
	}

	public $onChanges (changes: ng.IOnChangesObject) {
		const items = changes.items;
		if (items && items.currentValue !== items.previousValue) {
			this.$onDestroy();
			this.$onInit();
		}
	}

	public startSearch (event: MouseEvent) {
		const target = event.target as HTMLElement;
		this.ngModelCtrl.$setUntouched();
		if (target.closest('.selected-item-template-container')) {
			const curSearchTerm = this[this.searchName];
			if (!this.allowCustom) {
				this.selectedItem = false;
			}
			this[this.searchName] = '';
			this.$timeout(() => {
				const input = this.getInputElement();
				input.focus();
				// This little trick makes sure the end of string is where cursor is placed
				this[this.searchName] = curSearchTerm;
				this.openDropdown = input.value.length >= this.minCharacterCount;
			});
		}
	}

	public onType (event: KeyboardEvent) {
		// If enter is pressed get outta here
		if (event.keyCode === KeyCode.ENTER || event.keyCode === KeyCode.ESCAPE) {
			this.openDropdown = false;
			return;
		}

		// Unset selected item since we are searching again
		if (!this.allowCustom) {
			this.selectedItem = null;
		}

		const input = event.target as HTMLInputElement;

		// Always open dropdown when there are 2 or more characters
		this.openDropdown = input.value.length >= this.minCharacterCount;

		this.ngModelCtrl.$setDirty();

		if (input.value.length) {
			this.searchTerm$.next(input.value);
		} else {
			// Reset list if we cleared the search term
			this.list = this.items;
		}
	}

	public onFocus () {
		const input = this.getInputElement();
		this.openDropdown = input.value.length >= this.minCharacterCount;
	}

	public onBlur (event: any) {
		const input = event.target as HTMLInputElement;
		const hasSufficientCharacters = input.value.length > 1;
		if (hasSufficientCharacters) {
			const container = this.getListWrapper();
			if (!container.contains(event.relatedTarget)) {
				this.openDropdown = false;
			}
		}
		this.ngModelCtrl.$setTouched();
		this.ngModelCtrl.$validate();
	}

	public onItemBlur (event: any) {
		const input = this.getInputElement();
		const container = this.getListWrapper();
		const relatedTarget = event.relatedTarget;
		if (relatedTarget !== input && !container.contains(event.relatedTarget)) {
			this.openDropdown = false;
		}
	}

	public $onDestroy () {
		if (this.searchSubscriber) this.searchSubscriber.unsubscribe();
		if (this.searchTerm$) this.searchTerm$.unsubscribe();
	}

	public select (item: any) {
		this.openDropdown = false;
		this.selectedItem = item;
		this.ngModelCtrl.$setDirty();
		this.onSelect({item});
	}

	private initSearchList (items: any[]) {
		// Init with static list
		const options = {
			allowTypo: true
		} as Fuzzysort.KeysOptions<any>;

		if (!this.allowCustom) {
			options.keys = this.fuzzySearchKeys;
		}

		this.searchTerm$ = new Subject<string>();
		this.searchList$ = this.searchTerm$.pipe(
			distinctUntilChanged(),
			map((query: string) => {
				return (query.replace(IGNORE_REGEX, '').trim() || query.trim()).split(/\s+/).join(' ');
			}),
			switchMap((query: string) =>
				[fuzzysort.go(query, items, options)]
			),
			map((results) => {
				return results.map((item: any) => item.obj || item.target);
			})
		);
	}

	private getInputElement (): HTMLInputElement {
		return this.$element[0].querySelector('input');
	}

	private getListWrapper (): HTMLElement {
		return this.$element[0].querySelector('ul');
	}
}
