import { MessageModal } from '../message/modal.factory';

/* @ngInject */
export function UserSearchModalCtrl (
	$scope,
	$log: angular.ILogService,
	User,
	goModal,
	masquerade,
	messageModal: MessageModal,
	$document: ng.IDocumentService,
	$timeout: ng.ITimeoutService,
	$translate: ng.translate.ITranslateService
) {

	const vm = this;

	let lastQuery;
	vm.$onInit = () => {

		lastQuery = '';

		$scope.searchQuery = '';
		$scope.selectedUser = false;

		// If passed a search query, start search automatically
		if ($scope.initialQuery) {
			$scope.getUsersBy($scope.initialQuery);
		}
	};

	$scope.selectUser = function (user) {
		$scope.selectedUser = user;
	};

	$scope.openEditUser = function (user) {
		goModal.open({
			modal: 'editUser',
			modalData: {
				user,
				currentUser: $scope.currentUser,
				edit: false
			}
		}).result.then(function (resource) {
			if (resource) {
				$scope.$close(resource);
			}
		}).catch($log.error);
	};

	/** Query for users */
	$scope.getUsersBy = (query) => {
		if ($scope.isSearchDisabled(query)) {
			return;
		}

		if (query !== lastQuery) {
			$scope.users = [];
			$scope.searching = true;

			User.search({
				query,
				limit: 100
			}, function (users) {
				$scope.users = users;
				$scope.searching = false;
			});

			lastQuery = query;
		}
	};

	$scope.isSearchDisabled = (query) => {
		return !query || query.length < 3;
	};

	$scope.clearSearch = (form) => {
		form.searchQuery = '';
		$timeout(() => {
			$document[0].querySelector<HTMLInputElement>('.search-input').focus();
		});
	};

	$scope.maskAs = function (user) {
		return masquerade.as(user).catch(() => {
			messageModal.open({
				modalData: {
					title: $translate.instant('mask-user-error_title'),
					message: $translate.instant('mask-user-error_message')
				}
			});
		});
	};

}
