import * as angular from 'angular';

const keyPressDirective: ng.IDirectiveFactory = (keyName, KeyCode) => {
	keyName = keyName.toUpperCase();
	return {
		restrict: 'A',
		link (scope, element, attrs) {
			const target = attrs.hasOwnProperty('keypressGlobal') ? document : element;
			angular.element(target).on('keydown', (e) => {
				const code = e.keyCode ? e.keyCode : e.which;
				const targetAttr = 'keypress' + (keyName.charAt(0).toUpperCase() + keyName.toLowerCase().slice(1));
				if (code === KeyCode[keyName] && attrs[targetAttr]) {
					e.preventDefault();
					scope.$eval(attrs[targetAttr]);
					scope.$apply();
				}
			});
		}
	};
};

/* @ngInject */
export const keypressEnter = (KeyCode) => {
	return keyPressDirective('enter', KeyCode);
};
