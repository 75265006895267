import * as angular from 'angular';
import { YoutubeProcessor } from './youtube.processor';

/* @ngInject */
export function mediaServiceTransformer ($http, $injector) {

	/**
	 * Transform request data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformRequestData (data) {
		const payload = angular.extend({}, data);
		return payload;
	}

	/**
	 * Transform request
	 *
	 * @param request
	 * @returns {String}
	 */
	function transformRequest (request) {
		let requestData;

		if (angular.isArray(request)) {
			requestData = [];
			angular.forEach(request, function (data) {
				requestData.push(transformRequestData(data));
			});
		} else {
			requestData = transformRequestData(request);
		}

		return angular.toJson(requestData);
	}

	/**
	 * Transform response data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformResponseData (data) {
		if (angular.isArray(data.media_vtt)) {
			const MediaVTT = $injector.get('MediaVTT');
			data.media_vtt.forEach(function (mediaVTT, index) {
				data.media_vtt[index] = MediaVTT.newInstance(mediaVTT);
			});
		}
		if (angular.isArray(data.supplementary_media)) {
			const SupplementaryMedia = $injector.get('SupplementaryMedia');
			data.supplementary_media.forEach(function (media, index) {
				data.supplementary_media[index] = SupplementaryMedia.newInstance(data.media_id, media);
			});
		}

		// Check for YouTube and make correct thumbnail
		if (data.media_url && YoutubeProcessor.isYoutubeUrl(data.media_url)) {
			data.thumbnail_url = YoutubeProcessor.makeYoutubeThumbnailUrl(data.media_url);
		}

		if (data.duration != null && typeof data.duration === 'string') {
			data.duration = Number(data.duration);
		}

		return data;
	}

	/**
	 * Transform response
	 *
	 * @param response
	 * @returns {*}
	 */
	function transformResponse (response) {
		if (angular.isArray(response)) {
			angular.forEach(response, function (data) {
				transformResponseData(data);
			});
		} else {
			transformResponseData(response);
		}

		return response;
	}

	return {
		request: transformRequest,
		response: [$http.defaults.transformResponse[0], transformResponse]
	};
}
