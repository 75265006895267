import template from './rubric-element-with-handle-wrapper.component.html';

export const rubricElementWithHandleWrapperComponent = {
	template,
	transclude: true,
	bindings: {
		handleClass: '@',
		customClass: '<',
		handleIcon: '<',
		handlePosition: '<',
		handleAriaLabel: '@'
	}
};
