import { clientSettings } from 'go-modules/models/common/client.settings';

type XhrConfig = Omit<RequestInit, 'body'> & {
	token?: string;
	body?: null | {[key: string]: any};
};

export const xhr = async <Result = unknown> (
	url: string, config: XhrConfig = {}
): Promise<Result> => {
	url = clientSettings.GoReactV2API + url;
	config.credentials = 'include';
	config.mode = 'cors';
	config.headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	};
	if(config.token) config.headers.Authorization = `Bearer ${config.token}`;
	if(config.body) (config as RequestInit).body = JSON.stringify(config.body);

	const res = await fetch(url, config as RequestInit);
	if(res.status >= 300) throw res;
	if(res.status === 204) return;
	return res.json();
};
