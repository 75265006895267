import angular from 'angular';

export class SessionInterceptor {
	public static request (request, sessionManager) {
		request.headers = request.headers || {};
		const config = sessionManager.getConfig();
		const prevAuthHeader = request.headers[config.authHeader];

		// Extend request headers
		angular.extend(request.headers, sessionManager.getRequestHeaders());

		// Handle scenario where authorization header is already set
		// If the auth header existed previously, put it back
		if (prevAuthHeader) {
			request.headers[config.authHeader] = prevAuthHeader;
		}

		return request;
	}

	public static response (response, sessionManager) {
		if (sessionManager.isRefreshNeeded()) {
			// Fetch new access token and set new token
			sessionManager.refreshToken().then();
		}

		return response;
	}

	public static responseError (response, sessionManager) {
		// Handle the case where the user is not authenticated
		if (response.status === 401) {
			if (!sessionManager.isEnded() && !sessionManager.isLockedOut(response.error)) {
				sessionManager.broadcast('timeout');
				sessionManager.end();
			}
		}

		return response;
	}

}
