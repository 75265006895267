import template from './control.component.html';
import { SlideToggleControlController as controller } from './control.controller';

export default {
	controller,
	bindings: {
		ariaLabel: '@',
		disabled: '@',
		id: '@',
		value: '=ngModel'
	},
	require: {
		ngModelController: 'ngModel',
		slideToggleController: '?^slideToggle'
	},
	template,
	transclude: true
};
