/* @ngInject */
export function goreactMissingTranslationHandler ($sanitize: ng.sanitize.ISanitizeService, $log: ng.ILogService) {
	const seenFallbacks = [];
	return (translationId: string) => {
		if (!(seenFallbacks.includes(translationId))) {
			$log.warn('Translation fallback!', translationId);
			seenFallbacks.push(translationId);
		}
		return $sanitize(translationId);
	};
}
