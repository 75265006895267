/* @ngInject */
export function DisconnectZoomAccountModalController ($scope, zoomService) {
	$scope.disconnectZoomAccount = () => {
		$scope.isLoading = true;
		zoomService.disconnect().then(() => {
			$scope.isLoading = false;
			$scope.$close();
		});
	};
}
