interface Bindings {
	mainController: any;
	mediaDisplayController: any;
	singleMediaDisplayController: any;
}

export class SessionMediaDisplayController implements Bindings {
	public mainController: any;
	public mediaDisplayController: any;
	public singleMediaDisplayController: any;

	/* @ngInject */
	constructor (public feedbackSession) {}
}
