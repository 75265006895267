import { INgModelController, IAugmentedJQuery } from 'angular';
import { SlideToggleController } from '../slide-toggle.controller';

export interface Bindings {
	ariaLabel: string;
	disabled: string;
	id: string;
	ngModelController: INgModelController;
	value: boolean;
	slideToggleController: SlideToggleController;
}

/* @ngInject */
export class SlideToggleControlController implements Bindings {
	public ariaLabel: string;
	public disabled: string;
	public id: string;
	public ngModelController: INgModelController;
	public value: boolean;
	public slideToggleController: SlideToggleController;

	private lastValue: boolean;

	constructor (private $element: IAugmentedJQuery) {}

	public $onInit (): void {
		if (this.slideToggleController) {
			this.id = this.slideToggleController.id;
		}
		this.$element.on('keydown', this.onEnterPress);
		this.$element.removeAttr('id');
		this.validate();
	}

	public $onChanges (changes: ng.IOnChangesObject): void {
		const disabledPropertyChange = changes.disabled;
		if (disabledPropertyChange) {
			this.$element.toggleClass('disabled', !!disabledPropertyChange.currentValue);
		}
	}

	public $doCheck (): void {
		if (this.value !== this.lastValue) {
			this.validate();
			this.lastValue = this.value;
		}
	}

	public toggle (value?: boolean): void {
		if (value === undefined) {
			value = !this.value;
		}

		this.ngModelController.$setViewValue(value);
	}

	public onChange (): void {
		this.ngModelController.$setViewValue(this.value);
	}

	private validate (): void {
		if (typeof this.value !== 'boolean') {
			throw new Error('Invalid type supplied, must be of type boolean');
		}

		this.$element.toggleClass('active', this.value);
		this.$element.toggleClass('inactive', !this.value);
	}

	private onEnterPress = (event: JQueryEventObject) => {
		if ((event as any).code === 'Enter') {
			this.toggle();
		}
	};
}
