import { GoSource, GoSourceConfig } from '../go-source';
import { DrawDimensions, GoScene } from '../../go-scene/go-scene';

interface TextSourceConfig extends GoSourceConfig {
	fillStyle?: string;
	baseline?: CanvasTextBaseline;
	align?: CanvasTextAlign;
	fontSize?: number;
	fontFamily?: string;
	backgroundColor?: string|null;
	padding?: number;
	maxWidth?: number|null;
}

export class TextSource extends GoSource {
	public readonly fillStyle: string = '#FFF';
	public readonly baseline: CanvasTextBaseline = 'top';
	public readonly align: CanvasTextAlign = 'left';
	public readonly fontSize: number = 5;
	public readonly fontFamily: string = 'proxima-nova, Open Sans';
	public readonly backgroundColor: string|null = null;
	public readonly padding: number = 0;
	public readonly maxWidth: number|null = null;

	constructor (config: TextSourceConfig) {
		super(config);
		this.fillStyle = config.fillStyle ?? this.fillStyle;
		this.baseline = config.baseline ?? this.baseline;
		this.align = config.align ?? this.align;
		this.fontSize = config.fontSize ?? this.fontSize;
		this.fontFamily = config.fontFamily ?? this.fontFamily;
		this.backgroundColor = config.backgroundColor ?? this.backgroundColor;
		this.padding = config.padding ?? this.padding;
		this.maxWidth = config.maxWidth ?? this.maxWidth;
	}

	public async init () {}

	public async test () { return true; }

	public update (config: TextSourceConfig) {
		Object.assign(this, config);
	}

	public render (scene: GoScene, container?: DrawDimensions) {
		const fontSizePx = scene.getPixelsY(this.fontSize);
		const ctx = scene.ctx;
		// measureText will be incorrect if font size is not set first.
		ctx.font = `${fontSizePx}px ${this.fontFamily}`;
		const text = this.maxWidth
			? this.ellipseText(this.resource, scene.getPixelsX(this.maxWidth), ctx)
			: this.resource;
		const textWidthPx = ctx.measureText(text).width;
		const widthPaddingPx = scene.getPixelsX(this.padding);
		const heightPaddingPx = scene.getPixelsY(this.padding);
		this.setPosition({
			width: textWidthPx / scene.canvasEle.width * 100 + this.padding,
			height: fontSizePx / scene.canvasEle.height * 100 + this.padding
		});
		const {x, y, height, width} = scene.getDrawDimensions(this, container);
		const textX = x + widthPaddingPx / 2;
		const textY = y + heightPaddingPx / 2;

		if (this.backgroundColor) {
			ctx.fillStyle = this.backgroundColor;
			ctx.fillRect(x, y, width, height);
		}

		ctx.fillStyle = this.fillStyle;
		ctx.textBaseline = this.baseline;
		ctx.textAlign = this.align;
		ctx.fillText(text, textX, textY);
	}

	private ellipseText (text: string, width: number, ctx: CanvasRenderingContext2D) {
		const txtWidth = ctx.measureText(text).width;
		const widthPerChar = txtWidth / text.length;
		return txtWidth > width
			? text.slice(0, Math.round(width / widthPerChar) - 1) + '.'
			: text;
	}
}
