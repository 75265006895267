import template from './bg-splitter.component.html';
import './style.less';
import { BgSplitterController as controller } from './bg-splitter.controller';


export const bgSplitter: ng.IComponentOptions = {
	template,
	controller,
	transclude: {
		left: 'bgPaneLeft',
		right: 'bgPaneRight'
	},
	bindings: {
		disabled: '<disableResize'
	}
};
