import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import { MessageRememberingModal } from './modal.factory';

export const messageRememberingModalModule = angular.module('modal.message.remembering', [
	goModalBootstrapModule,
	angularTranslate
])
	.service('messageRememberingModal', MessageRememberingModal)
	.name;
