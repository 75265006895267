import template from './single-media-display.component.html';
import { SingleMediaDisplayController as controller } from './single-media-display.controller';

export const singleMediaDisplay = {
	template,
	require: {
		mainController: '^feedbackSession',
		mediaDisplayController: '^mediaDisplay'
	},
	controller,
	bindings: {media: '<?'}
};

