import { ResizeSensor } from 'css-element-queries';

interface Bindings {
	ratio: string;
}

export class AspectRatioContainerController implements Bindings, ng.IController {
	public ratio: string;
	private aspectRatio: number;
	private nativeElement: HTMLElement;
	private resizeSensor: ResizeSensor;

	/* @ngInject */
	constructor (private $element, private $timeout: ng.ITimeoutService) {}

	/**
	 * Handles controller init life-cycle hook
	 */
	public $onInit () {
		this.nativeElement = this.$element[0];

		this.updateRatio(this.ratio);

		this.resizeSensor = new ResizeSensor(this.nativeElement.parentElement, () => {
			this.resize(this.aspectRatio);
		});

		// This needs to happen in a later tick
		this.$timeout(() => this.resize(this.aspectRatio));
	}

	public $onChanges (changes: ng.IOnChangesObject) {
		if ('ratio' in changes) {
			this.updateRatio(changes.ratio.currentValue);
		}
	}

	public updateRatio (ratio: string): void {
		if (!ratio) {
			throw new Error('Property binding `ratio` is required');
		}

		const match = ratio.match(/(\d+):(\d+)/);
		if (!match) {
			throw new Error('Property binding `ratio` is invalid');
		}

		this.aspectRatio = parseFloat(match[1]) / parseFloat(match[2]);
		this.$timeout(() => this.resize(this.aspectRatio));
	}

	/**
	 * Handles controller destroy life-cycle hook
	 */
	public $onDestroy () {
		this.resizeSensor.detach();
	}

	/**
	 * Resizes the width and height of the element to maintain a given aspect ratio
	 */
	private resize (aspectRatio: number) {
		const boundingRect = this.nativeElement.parentElement.getBoundingClientRect();
		let containerWidth: number = boundingRect.width;
		let containerHeight: number = boundingRect.height;

		// Minus parent padding
		const containerStyle = window.getComputedStyle(this.nativeElement.parentElement);
		containerWidth -= parseFloat(containerStyle.paddingLeft) + parseFloat(containerStyle.paddingRight);
		containerHeight -= parseFloat(containerStyle.paddingTop) + parseFloat(containerStyle.paddingBottom);

		// Minus element margin
		const elementStyle = window.getComputedStyle(this.nativeElement);
		containerWidth -= parseFloat(elementStyle.marginLeft) + parseFloat(elementStyle.marginRight);
		containerHeight -= parseFloat(elementStyle.marginTop) + parseFloat(elementStyle.marginBottom);

		const containerRatio: number = containerWidth / containerHeight;
		if (aspectRatio > containerRatio) {
			this.nativeElement.style.width = `${Math.ceil(containerWidth)}px`;
			this.nativeElement.style.height = `${Math.ceil(containerWidth / aspectRatio)}px`;
		} else {
			this.nativeElement.style.width = `${Math.ceil(containerHeight * aspectRatio)}px`;
			this.nativeElement.style.height = `${Math.ceil(containerHeight)}px`;
		}
	}
}
