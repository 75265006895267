import { Writable } from 'stream';

// Convert from WritableStream to Writable
export class WritableStreamAdapter extends Writable {
	private writer: WritableStreamDefaultWriter;

	constructor (writableStream: WritableStream) {
		super();
		this.writer = writableStream.getWriter();
	}

	public _write (chunk, _encoding, callback) {
		this.writer.write(chunk)
			.then(callback)
			.catch(callback);
	}

	public _final (callback) {
		this.writer.close()
			.then(callback)
			.catch(callback);
	}

	public _destroy (err, callback) {
		this.writer.abort(err)
			.then(callback)
			.catch(callback);
	}
}
