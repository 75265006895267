import { Events, States } from 'go-modules/video-scene/state-emitter/state-emitter';
import { MediaStreamFactory, MediaStreamKind } from './media-stream-factory';
import { EventEmitter } from 'events';
import DeviceMediaStreamFactory from './device-media-stream-factory';

export default class DebugVideoMediaStreamFactory implements MediaStreamFactory {

	private canvas: HTMLCanvasElement;
	private context: CanvasRenderingContext2D;
	private startTimes: Map<States, number> = new Map();
	private drawScheduler: (callback: () => any) => any;
	private isDrawing = false;

	constructor (event: EventEmitter = null,
		canvas: HTMLCanvasElement = document.createElement('canvas'),
		drawScheduler: (callback?: () => any) => any = window.requestAnimationFrame.bind(window)) {

		this.canvas = canvas;
		this.canvas.width = 640;
		this.canvas.height = 480;
		this.context = this.canvas.getContext('2d');

		this.drawScheduler = drawScheduler;
		if (event != null) {
			event.on(Events.STATE_CHANGE, this.updateTime.bind(this));
		}
	}

	public getId (): string {
		return 'debug-video-media-stream';
	}

	public getType (): MediaStreamKind {
		return 'video';
	}

	public getLabel (): string {
		return 'Debug Video Fake Device';
	}

	public async getStream (options: MediaTrackConstraints = {}, otherFactories: MediaStreamFactory[] = []):
	Promise<MediaStream> {

		if (!this.isDrawing) {
			this.drawScheduler(this.draw.bind(this));
			this.isDrawing = true;
		}
		let frameRate = 30;
		if (options.frameRate != null) {
			if (typeof options.frameRate === 'number') {
				frameRate = options.frameRate;
			} else {
				frameRate = options.frameRate.exact
					|| options.frameRate.ideal
					|| options.frameRate.max
					|| options.frameRate.min;
			}
		}

		const stream = this.canvas.captureStream(frameRate);

		stream?.getTracks().forEach((track: MediaStreamTrack) => {
			DeviceMediaStreamFactory.aliasTrackStopMethod(track);
		});

		if (otherFactories.length !== 0) {
			const otherStream = await otherFactories[0].getStream({}, otherFactories.slice(1));

			otherStream.getTracks().forEach((track: MediaStreamTrack) => {
				stream.addTrack(track);
			});
		}

		return stream;
	}

	private updateTime (state: States) {
		this.startTimes.set(state, performance.now());
	}

	private draw () {
		this.context.fillStyle = '#00f';
		this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
		this.context.font = '22px Arial';
		this.context.fillStyle = '#fff';
		this.context.fillText('now: ' + Math.floor(performance.now()), 10, 40);

		let i = 2;
		for (const [state, startTime] of Array.from(this.startTimes)) {
			this.context.fillText(state + ': ' + Math.floor(performance.now() - startTime), 10, 40 * i);
			i++;
		}
		this.drawScheduler(this.draw.bind(this));
	}
}
