import template from './slide-deck-instructions.component.html';
import { SlideDeckInstructionsController as controller } from './slide-deck-instructions.controller';

export const slideDeckInstructions = {
	template,
	require: {mainController: '^feedbackSession'},
	controller,
	bindings: {
		media: '<',
		done: '&',
		uploadAgain: '&'
	}
};
