import {
	Component, Input, NgZone, OnDestroy, OnInit
} from '@angular/core';
import { Timer } from 'go-modules/timer';

@Component({
	selector: 'time-display',
	template: require('./time-display.component.html')
})
export class TimeDisplayComponent implements OnInit, OnDestroy {
	@Input() public timer: Timer;
	@Input() public timeLimit: number;
	public timerValue: number;

	constructor (private zone: NgZone) {}

	public ngOnInit () {
		this.updateTime(0);
		this.timer.on(Timer.EVENT.TIME, this.updateTime);
	}

	public ngOnDestroy () {
		this.timer?.off(Timer.EVENT.TIME, this.updateTime);
	}

	private updateTime = (time: number): void => {
		this.zone.run(() => {
			let value = time;
			if (this.timeLimit > 0) {
				const timeRemainingInSeconds = this.timeLimit * 60 - (time / 1000);
				value = Math.max(0, Math.ceil(timeRemainingInSeconds)) * 1000;
			}

			this.timerValue = value;
		});
	};
}
