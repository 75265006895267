import * as angular from 'angular';
import { clientSettings } from 'go-modules/models/common/client.settings';

/* @ngInject */
export function SupplementaryMedia ($resource, MediaModel) {
	const baseUrl = `${clientSettings.GoReactV2API}/media`;

	const supplementaryMedia = $resource(baseUrl + '/:primary_media_id/supplementary_media/:supplementary_media_id', {
		primary_media_id: '@primary_media_id',
		supplementary_media_id: '@supplementary_media_id'
	});

	// Inherit from Media model
	const prototype = supplementaryMedia.prototype;
	supplementaryMedia.prototype = Object.create(MediaModel.prototype);
	supplementaryMedia.prototype.constructor = supplementaryMedia;
	angular.extend(supplementaryMedia.prototype, prototype);

	/**
	 * Creates a new supplementary media instance
	 *
	 * @param primaryMediaId
	 * @param data
	 * @returns {SupplementaryMedia}
	 */
	supplementaryMedia.newInstance = function (primaryMediaId, data) {
		if (!primaryMediaId) {
			throw new Error('Parameter `primaryMediaId` is required');
		}
		data.primary_media_id = primaryMediaId;
		data.supplementary_media_id = data.media_id;
		return new supplementaryMedia(data || {});
	};

	return supplementaryMedia;
}
