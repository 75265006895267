import { clientSettings } from 'go-modules/models/common/client.settings';

/* @ngInject */
export class LicenseModel {
	constructor (
		private $http: ng.IHttpService
	){}

	public getLicense (id) {
		return this.$http.get(`${clientSettings.GoReactV2API}/licenses/${id}`)
			.then((response) => {
				return response.data;
			});
	}
}
