import { Subject } from 'rxjs';

export class CourseService {

	public courseSyncSubject = new Subject();

	/* @ngInject */
	constructor () {}

	public setCourse (course): void {
		this.courseSyncSubject.next(course);
	}
}
