import template from './player-controls.component.html';
import { PlayerControlsController as controller } from './player-controls.controller';

export const playerControlsComponent = {
	controller,
	template,
	bindings: {
		player: '=',
		options: '=',
		mediaId: '<',
		collapseAudio: '<',
		allowDownload: '<'
	}
};
