import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as angularSanitize from 'angular-sanitize';

import { GoBannerComponent } from './go-banner.component';
import { GoBannerService } from './go-banner.service';
import { GoBannerHelper } from './go-banner-helper.service';
import { GoBannerListComponent } from './go-banner-list.component';


import './style.less';

export const goBannerModule = angular.module('goBanner', [
	angularTranslate,
	angularSanitize
])
	.component('goBanner', GoBannerComponent)
	.component('goBannerList', GoBannerListComponent)
	.service(GoBannerService.NG1_INJECTION_NAME, GoBannerService)
	.service('goBannerHelper', GoBannerHelper)
	.name;
