import * as angular from 'angular';
import { clientSettings } from '../common/client.settings';
import { InviteFactory } from './invite.schema';
import { InviteTransformer } from './invite.transformer';

// Take the existing Invite model and modify it with angular
// based network requests

/* @ngInject */
export const inviteFactory = (
	$resource: angular.resource.IResourceService,
	inviteTransformer: InviteTransformer
): InviteFactory => {

	return $resource(`${clientSettings.GoReactV2API}/groups/:groupId/invites/:inviteId`, {
		groupId: '@groupId',
		inviteId: '@inviteId'
	}, {
		query: {
			isArray: true,
			transformResponse: inviteTransformer.responseTransformers,
			method: 'GET'
		},
		sendInvites: {
			isArray: true,
			transformRequest: inviteTransformer.requestTransformers,
			transformResponse: inviteTransformer.responseTransformers,
			method: 'POST'
		},
		resend: {
			method: 'POST',
			url: `${clientSettings.GoReactV2API}/groups/:groupId/invites/:inviteId/resend`
		},
		resendAll: {
			method: 'POST',
			url: `${clientSettings.GoReactV2API}/groups/:groupId/invites/resend`
		}
	}) as InviteFactory;
};
