import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { MarkerComponent } from './marker.component';
import { TwoLettersPipeModule } from 'ngx/go-modules/src/pipes/two-letters/two-letters.module';
import { GoTooltipModule } from 'ngx/go-modules/src/directives/go-tooltip';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		TwoLettersPipeModule,
		GoTooltipModule
	],
	exports: [
		MarkerComponent
	],
	declarations: [
		MarkerComponent
	],
	entryComponents: [
		MarkerComponent
	]
})
export class MarkerModule {}
