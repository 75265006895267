/* @ngInject */
function richDropdownListItemController ($scope, $element, $attrs, $parse, $compile) {

	const vm = this;
	let itemValue;
	vm.$onInit = () => {
		const templateGetter = $parse($attrs.richDropdownListItem);
		$scope.$watch(templateGetter, function (templateHtml) {
			$element.html(templateHtml);
			$compile($element.contents())($scope);
		});

		itemValue = $scope.item[$scope.richDropdown.collectionValue] || $scope.item || null;
	};

	$scope.selectItem = function (item, $event) {
		if (!item.disabled) {
			$scope.select();
		} else {
			$event.stopPropagation();
			$event.preventDefault();
		}
	};

	$scope.select = function (external) {
		$scope.richDropdown.select(itemValue, $element, external);
	};

	$scope.$watch('richDropdown.item', function (selectedItem) {
		if (itemValue === selectedItem) {
			$scope.select(true);
		}
	});
}

export const richDropdownListItem = function () {
	return {
		restrict: 'EA',
		require: '^richDropdown',
		scope: true,
		controller: richDropdownListItemController
	};
};
