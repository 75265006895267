import type { SessionCreationWizardOptions } from '../options';
import type { SessionCreationWizardStep } from './';
import { SessionCreationIntent } from '../intent';

export class FinalizeStep implements SessionCreationWizardStep {

	/** @ngInject */
	constructor (
		private Session: any,
		private $q: ng.IQService
	) {}

	/**
	 * Run this step
	 */
	public run (options: SessionCreationWizardOptions): ng.IPromise<any> {
		if (!options.session.session_id) {
			// Move the session status into the awaiting start state
			// when the intent is to save the session for later use.
			if (options.intent === SessionCreationIntent.SAVE_FOR_LATER) {
				options.session.setStatus(this.Session.WAITING);
			}

			return options.session.save();
		}

		return this.$q.resolve(options.session);
	}

	/**
	 * Determine whether this step is required
	 */
	public isRequired (_options: SessionCreationWizardOptions): boolean {
		return true;
	}
}
