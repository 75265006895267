import * as angular from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';

/* @ngInject */
export function RubricController (
	$scope,
	$q,
	$timeout,
	RubricTemplateModel,
	RubricSessionModel,
	messageModal,
	eventService: EventService
) {
	const vm = this;
	let isCanvasActive = false;

	vm.$onInit = function () {
		$scope.schema = false;
		$scope.session = false;
		vm.selectedItem = null;
		vm.ready = false;

		let schema, template, session;

		if ($scope.options.schemaId) {
			template = RubricTemplateModel.get({
				id: $scope.options.schemaId
			}).$promise;
		}

		if ($scope.options.sessionId) {
			session = RubricSessionModel.get({
				id: $scope.options.sessionId,
				session_id: $scope.options.postData.session_id
			}).$promise;
		}

		$q.all({
			session: session || $q.when($scope.options.session),
			template: template || $q.when(null)
		}).then(function (data) {
			if (data.template) {
				schema = data.template;
				if (data.session) {
					$scope.session = data.session;
					schema.setValues($scope.session.data);
				} else {
					$scope.session = RubricSessionModel.newInstance({
						points: null, data: []
					});
				}
				$scope.schema = schema;
			} else {
				$scope.schema = new RubricTemplateModel({
					total: 0, elements: []
				});
			}

			vm.ready = true;
			eventService.broadcast(EVENT_NAMES.RUBRIC_READY, $scope.schema);
		});
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	/**
	 * Whether canvas is active
	 *
	 * @returns {boolean}
	 */
	vm.isCanvasActive = function () {
		return isCanvasActive;
	};

	/**
	 * Set canvas active / inactive
	 *
	 * @param value
	 */
	vm.setCanvasActive = function (value) {
		isCanvasActive = !!value;
	};

	/**
	 * Set the selected item
	 *
	 * @param item
	 */
	vm.setSelectedItem = function (item) {
		vm.selectedItem = item;
	};

	/**
	 * Handle item focus
	 *
	 * @param event
	 * @param item
	 */
	vm.onItemFocus = function (_event, item) {
		vm.setSelectedItem(item);
	};

	/**
	 * Handle item blur
	 *
	 * @param event
	 */
	vm.onItemBlur = function (event) {
		if (!event.target.contains(event.relatedTarget)) {
			vm.setSelectedItem(null);
		}
	};

	/**
	 * Whether tooltips are enabled
	 *
	 * @returns {boolean}
	 */
	vm.isTooltipEnabled = function () {
		return angular.isObject($scope.options) && !!$scope.options.hideDesc;
	};

	/**
	 * Add item to schema
	 *
	 * @param item
	 * @param index
	 */
	vm.addItem = function (item, index) {
		$scope.schema.addItem(item, index);
	};

	/**
	 * Duplicate item in schema
	 *
	 * @param item
	 */
	vm.duplicateItem = function (item) {
		$scope.schema.duplicateItem(item);
	};

	/**
	 * Remove item from schema
	 *
	 * @param item
	 */
	vm.removeItem = function (item) {
		$scope.schema.removeItem(item);
	};

	vm.canSeePrintTotalScore = () => {
		return !!$scope.options.userGroup && !!$scope.session &&
			$scope.options.mode === 'print' && $scope.options.userGroup.hasReviewerRole(true);
	};

	/**
	 * Validate rubric elements
	 */
	vm.validate = function () {
		$scope.schema.validate();
	};

	/**
	 * Validate rubric elements
	 */
	vm.getCategory = function (element) {
		return $scope.schema.getCategory(element);
	};

	/**
	 * Validate rubric elements
	 */
	vm.validate = function () {
		$scope.schema.validate();
	};

	/**
	 * Save schema or session depending on current mode
	 */
	vm.save = function (event) {

		if ($scope.saving) {
			return;
		}

		let result;

		if ($scope.options.mode === 'live') {// save session

			// when we try to save the session before it is resolved, just return
			if ($scope.session.hasOwnProperty('$resolved') && !$scope.session.$resolved) {
				return;
			}

			$scope.saving = true;

			// add extra data
			angular.extend($scope.session, $scope.options.postData);

			if ($scope.session.id) {

				result = RubricSessionModel.update($scope.session, function (data) {
					delete data.data;
					delete data.points;
					angular.extend($scope.session, data);
				}).$promise;

			} else {
				$scope.session.template_id = $scope.schema.id;

				result = RubricSessionModel.create($scope.session, function (data) {
					delete data.data;
					delete data.points;
					angular.extend($scope.session, data);
					eventService.broadcast(EVENT_NAMES.RUBRIC_CHANGE, $scope.schema);
				}).$promise;
			}

			result = result.then(() => {
				if (event.data?.callback) event.data.callback(true);
				eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE_SESSION_DONE, {succeeded: true, rubric: $scope.session});
			}).catch(() => {
				return messageModal.open({
					size: 'sm',
					modalData: {
						title: 'unknown-error-title',
						message: 'unknown-error-description',
						resolveBtnText: 'common_close',
						resolveBtnClass: 'primary-btn'
					}
				}).result.finally(() => {
					if (event.data?.callback) event.data.callback(false);
					eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE_SESSION_DONE, {
						succeeded: false, rubric: $scope.session
					});
				});
			});

		} else if ($scope.options.mode === 'edit') {// save schema

			// when we try to save the session before it is resolved, just return
			if ($scope.schema.hasOwnProperty('$resolved') && !$scope.schema.$resolved) {
				return;
			}

			$scope.saving = true;

			const schemaToSave = {
				id: $scope.schema.id,
				title: $scope.schema.title,
				desc: $scope.schema.desc,
				elements: $scope.schema.elements,
				total: $scope.schema.total,
				parent_id: $scope.schema.parent_id,
				add_to_collection: $scope.schema.add_to_collection,
				...$scope.options.postData
			};

			if ($scope.schema.id) {

				eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE_SCHEMA_START);

				result = RubricTemplateModel.update(schemaToSave).$promise.then((data) => {
					angular.extend($scope.schema, data);
				}).catch((err) => {
					if (err?.data?.errors?.locked != null) {// rubric in use alert
						return messageModal.open({
							size: 'sm',
							modalData: {
								title: 'modal-rubric-edit-failed_title',
								message: 'modal-rubric-edit-failed_message',
								resolveBtnText: 'common_close',
								resolveBtnClass: 'primary-btn'
							}
						}).result;
					}
					throw err;
				});
			} else {
				result = RubricTemplateModel.save(schemaToSave, function (data) {
					delete data.elements;
					delete data.total;
					angular.extend($scope.schema, data);
					eventService.broadcast(EVENT_NAMES.RUBRIC_CHANGE, $scope.schema);
				}).$promise;
			}

			result = result.then(() => {
				eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE_SCHEMA_DONE, /*succeeded*/ true);
			}).catch(() => {
				return messageModal.open({
					size: 'sm',
					modalData: {
						title: 'unknown-error-title',
						message: 'unknown-error-description',
						resolveBtnText: 'common_close',
						resolveBtnClass: 'primary-btn'
					}
				}).result.finally(() => {
					eventService.broadcast(EVENT_NAMES.RUBRIC_SAVE_SCHEMA_DONE, /*succeeded*/ false);
				});
			});
		}

		if (result) {
			result.finally(function () {
				$scope.saving = false;
			});
		}
	};

	vm.dataChanged = function () {
		onChange();
	};

	// EVENT HANDLERS
	vm.eventNames = [
		EVENT_NAMES.RUBRIC_DATA_CHANGE,
		EVENT_NAMES.RUBRIC_SAVE
	];
	vm.eventSubscription = eventService.events
		.pipe(filter((ev: GoEvent) => vm.eventNames.includes(ev.name)))
		.subscribe((ev: GoEvent) => {
			switch (ev.name) {
				case EVENT_NAMES.RUBRIC_DATA_CHANGE:
					onChange();
					break;
				case EVENT_NAMES.RUBRIC_SAVE:
					vm.save(ev);
					break;
			}
		});

	// schema elements length change handler
	$scope.$watch('schema.elements.length', function (value) {
		if (value > 0) {
			$timeout(function () {
				$scope.schema.validate();
			});
		}
	});

	/**
	 * On schema change event handler
	 */
	function onChange () {
		$timeout(function () {// wait for models to update
			// update schema total points
			$scope.schema.setTotal($scope.schema.calculateTotalPoints());

			// set the session data
			if ($scope.session) {
				$scope.session.setData($scope.schema.elements);
			}

			eventService.broadcast(EVENT_NAMES.RUBRIC_CHANGE, $scope.schema);
		});
	}
}
