import { isObject } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
	EVENTS as MediaPlayerEvents,
	GoMediaPlayer
} from '../media-player';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

/* @ngInject */
export function MediaPreviewController (
	$scope,
	$timeout,
	$document,
	$element: ng.IAugmentedJQuery,
	MediaModel,
	MediaVTT,
	PlayerSync,
	MediaPlayerTimer,
	eventService: EventService
) {
	const vm = this;

	/**
	 * Handles init life cycle hook
	 */
	vm.$onInit = () => {
		vm.id = null;
		vm.previewItem = null;
		vm.mediaPreviewPlayer = null;
		vm.playerSync = null;

		// Generate unique media preview player id
		vm.id = vm.previewerId || 'media-previewer-' + uuid();

		if (vm.options?.darkMode) {
			$element.addClass('dark-mode');
		}

		// Set audioDescriptionFlag initially
		vm.setOption('shouldDisplayAudioDescriptionButton', vm.hasAudioDescription());
		// Set permanent callback for audio description
		vm.setOption('toggleAudioDescriptionCallback', () => {
			eventService.broadcast(EVENT_NAMES.MEDIA_PREVIEW_TOGGLE_AUDIO_DESCRIPTION);
		});

		/**
		 * Handle media url change
		 */
		$scope.$watch(function () {
			return vm.media;
		}, function (newMedia, oldMedia) {
			// if the same media comes in again and the previous media state had a filename
			// means that the video just completed encoding to a smaller format
			// and we don't want the player to refresh itself.
			if (
				newMedia.media_status === oldMedia.media_status
				&& newMedia?.media_id === oldMedia?.media_id
				&& newMedia?.media_type === oldMedia?.media_type
				&& newMedia?.filename !== oldMedia?.filename
			) {
				return;
			}

			if (vm.hasUrlChanged(newMedia.media_url, oldMedia.media_url) || !vm.previewItem) {
				vm.setPreviewItem();
			}
		});

		/**
		 * Handle media vtt captions change. i.e. caption media vtt deletion
		 */
		$scope.$watchCollection('$ctrl.media.media_vtt', (newVal, oldVal) => {
			// This will re-initialize the player so that
			// captions won't appear anymore as an option.
			const oldVtt: number[] = oldVal ? oldVal.map((vtt) => vtt.media_id) : [];
			const newVtt: number[] = newVal ? newVal.map((vtt) => vtt.media_id) : [];
			if (oldVtt.length === newVtt.length && oldVtt.every((i: number) => newVtt.includes(i))) {
				// If we're simply getting a pubnub update with the same thing, don't bother re-initializing
				return;
			}
			if (!vm.media.isDocument()) {
				vm.setPreviewItem();
			}
		});

		/**
		 * Handle supplementary media change event. i.e. audio description deletion
		 */
		$scope.$watch(function () {
			return vm.hasAudioDescription();
		}, function (value) {
			vm.setOption('shouldDisplayAudioDescriptionButton', value);
		});

		/**
		 * Handle audio description toggle events
		 */
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_PREVIEW_TOGGLE_AUDIO_DESCRIPTION))
			.subscribe(() => {
				vm.options.audioDescriptionActive = !vm.options.audioDescriptionActive;
			});
	};

	vm.hasAudioDescription = (): boolean => {
		return vm.media.getSupplementaryAudio().filter((media) => {
			return media.isReady();
		}).length > 0;
	};

	/**
	 * Handles destroy life cycle hook
	 */
	vm.$onDestroy = function () {
		vm.destroyMediaPlaybackMechanism();
		vm.eventSubscription?.unsubscribe();
	};

	vm.handleMediaPlayerInit = (player: GoMediaPlayer): void => {
		vm.validateMediaPlaybackMechanism(player);
		vm.onInit({ player });
	};

	/**
	 * Destroy the media preview player instance
	 */
	vm.destroyMediaPreviewPlayer = function () {
		if (vm.mediaPreviewPlayer) {
			vm.mediaPreviewPlayer.off(MediaPlayerEvents.REQUEST_SEEK, onSeek);
		}
		vm.mediaPreviewPlayer = null;
	};

	/**
	 * Setup the player sync instance.
	 *
	 * The audio description player will be synchronized with the media preview player.
	 * The media preview player will serve as the master time keeping mechanism.
	 */
	vm.createPlayerSync = function () {
		if (vm.playerSync) {
			throw new Error('Cannot create `playerSync` because it has already been created');
		} else if (!vm.mediaPreviewPlayer) {
			throw new Error('Cannot create `playerSync` because `mediaPreviewPlayer` hasn\'t been created');
		}

		vm.playerSync = new PlayerSync(new MediaPlayerTimer(vm.mediaPreviewPlayer));
	};

	/**
	 * Destroy the player sync instance (only if was created with this controller)
	 */
	vm.destroyPlayerSync = function () {
		if (vm.playerSync) {
			vm.playerSync.destroy();
			vm.playerSync = null;
		}
	};

	/**
	 * Validate media player mechanism
	 */
	vm.validateMediaPlaybackMechanism = (player: GoMediaPlayer): void => {
		vm.destroyMediaPlaybackMechanism();
		vm.mediaPreviewPlayer = player;
		vm.mediaPreviewPlayer.on(MediaPlayerEvents.REQUEST_SEEK, onSeek);
		vm.createPlayerSync();

		// This is need so that the audio description
		// will be destroyed and created again.
		if (vm.options) {
			const originalValue = vm.options.audioDescriptionActive;
			vm.options.audioDescriptionActive = false;
			$timeout(function () {
				vm.options.audioDescriptionActive = originalValue;
			});
		}
	};

	/**
	 * Destroy the media playback mechanism
	 */
	vm.destroyMediaPlaybackMechanism = function () {
		vm.destroyMediaPreviewPlayer();
		vm.destroyPlayerSync();
	};

	/**
	 * Set the preview item
	 */
	vm.setPreviewItem = function () {
		if (vm.media instanceof MediaModel) {
			if (vm.media.isDocument()) {
				vm.previewItem = vm.media;
			} else {
				vm.previewItem = vm.mediaToPlaylistItem(vm.media);
			}
		} else {
			vm.previewItem = null;
			throw new Error('Parameter `media` required by MediaPreviewController must be of type `Media`');
		}
	};

	/**
	 * Detects whether a url has changed
	 *
	 * @param newVal
	 * @param oldVal
	 * @returns {boolean}
	 */
	vm.hasUrlChanged = function (newVal, oldVal) {
		const parserNew = $document[0].createElement('a');
		const parserOld = $document[0].createElement('a');
		parserNew.href = newVal;
		parserOld.href = oldVal;
		const parsedNew = parserNew.host + parserNew.pathname;
		const parsedOld = parserOld.host + parserOld.pathname;
		return parsedNew !== parsedOld;
	};

	/**
	 * Page change event handler
	 *
	 * @param event
	 */
	vm.onPageChange = function (event) {
		vm.onSeek({
			event: {
				offset: event.page,
				position: event.prevPage,
				duration: event.numPages
			}
		});
	};

	/**
	 * Returns a playlist item that conforms to the jwplayer spec.
	 * The playlist item properties are derived from the media.
	 *
	 * Despite removing jwplayer, the rest of the adapters
	 * expect this format so we'll leave it as is.
	 *
	 * @returns {Object}
	 * @see https://developer.jwplayer.com/jw-player/docs/javascript-api-reference/#playlist
	 */
	vm.mediaToPlaylistItem = function (media) {
		const tracks = [];
		let trackKindMap;

		// Assemble playlist item tracks
		if (media.hasMediaVTTCaptions()) {
			trackKindMap = {};
			trackKindMap[MediaVTT.TYPE.CAPTION] = 'captions';
			trackKindMap[MediaVTT.TYPE.THUMBNAIL] = 'thumbnails';

			media.media_vtt.forEach(function (item) {
				tracks.push({
					file: item.media_url,
					label: 'Item',
					kind: trackKindMap[item.type.name],
					default: true
				});
			});
		}

		return {
			media,
			tracks
		};
	};

	/**
	 * Whether audio description functionality is enabled
	 *
	 * @returns {boolean}
	 */
	vm.isAudioDescriptionEnabled = function () {
		return isObject(vm.options) &&
			vm.options.audioDescriptionActive &&
			vm.hasAudioDescription();
	};

	/**
	 * Set an individual option
	 *
	 * @param key
	 * @param value
	 */
	vm.setOption = function (key, value) {
		if (isObject(vm.options)) {
			vm.options[key] = value;
		}
	};

	/**
	 * Seek event handler
	 */
	function onSeek (event) {
		vm.onSeek({
			event: {
				offset: event.offset,
				position: event.position,
				duration: vm.mediaPreviewPlayer.getDuration()
			}
		});
	}
}
