import { NgModule } from '@angular/core';
import { NgxZeroStateComponent } from 'ngx/go-modules/src/components/zero-state/zero-state.component';

@NgModule({
	declarations: [
		NgxZeroStateComponent
	],
	exports: [
		NgxZeroStateComponent
	],
	entryComponents: [
		NgxZeroStateComponent
	]
})
export class NgxZeroStateModule {}
