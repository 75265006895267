import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { ModelConfig } from './common.config';
import { cacheManager } from './cache-manager.factory';
import { Service } from './global-service.service';

export const common = angular.module('go.models.common', [
	angularResource
])
	.provider('modelConfig', ModelConfig)
	.factory('cacheManager', cacheManager)
	.factory('Service', Service)
	.name;
