import { GoMediaPlayer } from '../adapters/media-player';

/* @ngInject */
export function mediaPlayerCacheService ($q: ng.IQService) {
	const deferred: {[id: string]: ng.IDeferred<GoMediaPlayer>} = {};

	return {
		get (id: string): ng.IPromise<GoMediaPlayer> {
			if (!deferred[id]) {
				deferred[id] = $q.defer();
			}
			return deferred[id].promise;
		},

		register (id: string, player: GoMediaPlayer): ng.IPromise<GoMediaPlayer> {
			const promise = this.get(id);
			deferred[id].resolve(player);
			return promise;
		},

		unregister (id: string): void {
			delete deferred[id];
		}
	};
}
