import { Inject, Injectable } from '@angular/core';
import {
	LICENSE_TRANSACTION_TYPE,
	PAYMENT_TYPES,
	PURCHASE_TYPES
} from 'go-modules/payment-panel/payment-panel.controller';
import { UserService, userServiceToken } from 'go-modules/models/user/user.service';
import { LazyPaymentLoader, lazyPaymentLoaderToken } from 'go-modules/payment-panel/lazy-payment-loader.service';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { Account } from 'ngx/go-modules/src/interfaces/account';
import { NgxSelfPayService } from 'ngx/go-modules/src/services/self-pay';
import { MatDialog } from '@angular/material/dialog';
import {
	CompareProductsDialogComponent
} from 'ngx/go-modules/src/components/dialogs/compare-products-dialog/compare-products-dialog.component';
import { BehaviorSubject, catchError, EMPTY, Observable } from 'rxjs';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { LicenseService } from 'ngx/go-modules/src/services/license/license.service';
import type { LicenseProduct } from 'go-modules/services/group/product';
import type {
	License as NgxLicense,
	SalesforceLicense as NgxSalesforceLicense
} from 'ngx/go-modules/src/interfaces/licenses';
import type { License as Ng1License } from 'go-modules/services/group/license';
import { ExpirationPolicies } from 'ngx/go-modules/src/enums/salesforce-license';
import { TranslateService } from '@ngx-translate/core';
import { MessageDialogComponent } from 'ngx/go-modules/src/components/dialogs/message-dialog/message-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxCourseService } from 'ngx/go-modules/src/services/course/course.service';
import { TEASE_WALL_UPGRADE_REMEMBER_KEY } from 'ngx/go-modules/src/directives/tease-wall/constants';
import {
	LicenseUpgradeSnackBarComponent,
	LicenseUpgradeSnackbarType
} from 'ngx/go-modules/src/components/snack-bars/license-upgrade/license-upgrade-snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { groupToken } from 'go-modules/models/group-dep/group.factory';

export interface InvertedSalesforceLicense extends NgxSalesforceLicense {
	// STAB-1455 Use one license product over the other
	// Currently the compare products dialog and pay form rely on BOTH types of license products being available
	licenseProduct: LicenseProduct;
	license_product: LicenseProduct;
	// STAB-1458 (See ticket for more details)
	// NgxSalesforceLicense defines a License as always available, which is not true unless in License Management.
	// We override "License" here specifically to make this intention clear.
	// An inverted salesforce license must have a nested License
	// This property should eventually use a different License type that doesn't contain circular License references
	license: NgxLicense
}

@Injectable({
	providedIn: 'root'
})
export class NgxLicenseUpgradeService {
	public payformOpened$: Observable<boolean>;
	private payformOpened$$ = new BehaviorSubject(false);

	constructor (
		public dialog: MatDialog,
		@Inject(groupToken) private Group,
		@Inject(userServiceToken) public userService: UserService,
		@Inject(lazyPaymentLoaderToken) public paymentLoader: LazyPaymentLoader,
		@Inject(selectedServiceToken) private selectedService: SelectedService,
		private ngxSelfPayService: NgxSelfPayService,
		private ngxGoToastService: NgxGoToastService,
		private licenseService: LicenseService,
		private translate: TranslateService,
		private courseService: NgxCourseService,
		private snackbar: MatSnackBar
	) {
		this.payformOpened$ = this.payformOpened$$.asObservable();
	}

	public static getLearnMoreLink () {
		return 'https://get.goreact.com/ai';
	}

	public static createTeaseWallUpgradeRememberKey (userId: number, licenseId: number) {
		return `${userId}-${licenseId}-${TEASE_WALL_UPGRADE_REMEMBER_KEY}`;
	}

	/**
	 * This method handles license admin upgrades + free trial purchases, and non-admin upgrade requests
	 * Students can't reach any component that calls this method
	 *
	 * @param license Accepts any license. Pick your poison.
	 * @param shouldRefreshLicense If current license should be re-fetched after successful payment
	 * @param useSelectedOrgToFetchAccount If the currently selected org can be used to fetch an account
	 * 			When false, the user will be prompted to select an org
	 * @returns Observable
	 * 			Emits { reguestUpgrade: true } on upgrade requests
	 * 			Emits { reguestUpgrade: false } on successful payments
	 * 			Otherwise, immediately completes
	 */
	public upgradeOrPurchase (
		license: Ng1License | NgxLicense | NgxSalesforceLicense,
		shouldRefreshLicense: boolean = true,
		useSelectedOrgToFetchAccount: boolean = true
	): Observable<{requestUpgrade: boolean} | never> {
		let invertedSfLicense: InvertedSalesforceLicense;
		try {
			invertedSfLicense = this.invertLicense(license);
		} catch(err) {
			return EMPTY;
		}

		const preferredOrgGroupId = useSelectedOrgToFetchAccount ? this.selectedService.getOrg()?.group_id : null;

		// If the user is buying a license we need to get an account for that org.
		// Because some users can be sneaky and be invited as a participant,
		// then upgraded to an instructor, they could be missing an account group.
		return this.licenseService.fetchOrCreateUserAccount(
			invertedSfLicense.license_id,
			preferredOrgGroupId
		).pipe(switchMap((account) => {
			if (this.isLicenseAdmin(invertedSfLicense)) {
				return this.handleAdminPurchase(invertedSfLicense, account, shouldRefreshLicense);
			} else {
				return this.handleNonAdminRequest(invertedSfLicense, account);
			}
		}));
	}

	/**
	 * @param license Any license outside License Management
	 */
	public createTeaseWallUpgradePromptAction (
		license: NgxLicense | Ng1License
	): Observable<string | never> | null {
		// Hide the tease wall action button by not returning an observable
		if (license == null || license.salesforce_license.has_renewal) return null;

		return this.upgradeOrPurchase(license).pipe(
			switchMap(({requestUpgrade}) => {
				if (requestUpgrade) {
					const groupId = this.selectedService.getGroup()?.group_id ?? null;
					return this.licenseService.requestUpgrade(license.id, groupId).pipe(
						// String response for tease wall
						map(() => 'license-plan-upgrade_request-sent'),
						catchError((err)  => {
							this.handleLicenseUpgradeRequestError(err);
							return EMPTY;
						})
					);
				} else {
					return EMPTY;
				}
			})
		);
	}

	public createTeaseWallPurchasePromptAction (): Observable<never> | null {
		return new Observable((observer) => {
			this.paymentLoader.openPayForm(
				this.userService.currentUser,
				// Can just use the current user account. User can select a different org when purchasing new licenses
				this.selectedService.getAccount(),
				(transaction) => {
					// There is a delay between making a license purchase and applying that license to the course
					// In order to immediately retrieve the license after a purchase, we need to update the group's
					// billing entity pre-emptively while the backend processes the course updates
					this.updateGroupBillingEntity(transaction.license.billing_entity_id);
					observer.complete();
				},
				null,
				PAYMENT_TYPES.CARD,
				PURCHASE_TYPES.LICENSE,
				LICENSE_TRANSACTION_TYPE.INITIAL,
				null,
				() => {
					// Complete on cancel
					observer.complete();
				}
			);
		});
	}

	public handleLicenseUpgradeRequest (licenseId: number) {
		// We don't actually care about the product the non license admin chooses
		const groupId = this.selectedService.getGroup()?.group_id ?? null; // License Management doesn't need a group_id
		return this.licenseService.requestUpgrade(licenseId, groupId).pipe(
			tap({
				next: () => {
					// Share upgrade request state with tease wall
					localStorage.setItem(
						NgxLicenseUpgradeService.createTeaseWallUpgradeRememberKey(
							this.userService.currentUser.user_id, licenseId
						),
						'license-plan-upgrade_request-sent'
					);
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.SUCCESS,
						message: 'license-plan-upgrade_request-sent-message'
					});
				},
				error: (err) => this.handleLicenseUpgradeRequestError(err)
			})
		);
	}

	private handleLicenseUpgradeRequestError (err: any) {
		if (err instanceof HttpErrorResponse) {
			if (err.status === 403) {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'license-plan-upgrade_request-unauthorized'
				});
				return;
			}
		}
		this.ngxGoToastService.createToast({
			type: GoToastStatusType.ERROR,
			message: 'license-plan-upgrade_request-failed'
		});
	}

	private isLicenseAdmin (sfLicense: InvertedSalesforceLicense): boolean | undefined {
		return this.userService.currentUser.is_root_user || sfLicense.is_org_admin || sfLicense.is_license_admin;
	}

	private handleAdminPurchase (
		invertedSfLicense: InvertedSalesforceLicense,
		account: Account,
		shouldRefreshLicense: boolean
	): Observable<{requestUpgrade: false} | never> {
		// Only self pay non free trial licenses can be upgraded
		const canUpgrade = invertedSfLicense.self_pay && !invertedSfLicense.is_free_trial;
		// Any restricted license can be renewed by an admin assuming has_renewal is false when this method is called
		const canRenew = invertedSfLicense.expiration_policy === ExpirationPolicies.RESTRICTED;

		let licenseTransactionType: LICENSE_TRANSACTION_TYPE;

		// Prioritize upgrades over renewals if possible
		if (canUpgrade) {
			licenseTransactionType = LICENSE_TRANSACTION_TYPE.UPGRADE;
		} else if (canRenew) {
			licenseTransactionType = LICENSE_TRANSACTION_TYPE.INITIAL;
		} else {
			// Can't renew or upgrade this license
			this.dialog.open(MessageDialogComponent, {
				data: {
					title: this.translate.instant('license-plan-upgrade-license'),
					message: this.translate.instant('license-plan-upgrade_request-unrestricted-message')
				}
			});
			return EMPTY;
		}

		return new Observable((observer) => {
			this.payformOpened$$.next(true);
			this.paymentLoader.openPayForm(
				this.userService.currentUser,
				account,
				() => {
					// Successfully made payment
					if (licenseTransactionType === LICENSE_TRANSACTION_TYPE.UPGRADE) {
						// STAB-1475: Free trial licenses or regular renewals could potentially not be upgraded
						LicenseUpgradeSnackBarComponent
							.open(this.snackbar, {
								mode: LicenseUpgradeSnackbarType.SUCCESS
							})
							.afterDismissed()
							.subscribe();
					}

					if (shouldRefreshLicense) {
						this.refreshCourseLicense();
					}
					this.payformOpened$$.next(false);
					observer.next({requestUpgrade: false});
					observer.complete();
				},
				invertedSfLicense,
				PAYMENT_TYPES.CARD,
				PURCHASE_TYPES.LICENSE,
				licenseTransactionType,
				null,
				() => {
					// Cancel or Error
					this.payformOpened$$.next(false);
					observer.complete();
				}
			);
		});
	}

	private handleNonAdminRequest (
		invertedSfLicense: InvertedSalesforceLicense,
		account: Account
	): Observable<{requestUpgrade: true} | never> {
		if (invertedSfLicense.is_free_trial) {
			// Non license admins can't purchase a license and can't send upgrade requests on a free trial license
			// This is just a preventative measure
			return EMPTY;
		}

		return this.ngxSelfPayService.getProducts(account.org_id).pipe(
			switchMap((products) => {
				// DEV-16255 pass in some flag so that product chooser can detect if an upgrade
				// request was already sent
				const dialogRef = this.dialog.open(
					CompareProductsDialogComponent, {
						data: {
							transactionType: LICENSE_TRANSACTION_TYPE.UPGRADE,
							license: invertedSfLicense,
							products,
							launchAsNonAdminRequest: true
						}
					});

				// Only emit an upgrade request if user selects a product
				return dialogRef.afterClosed().pipe(
					filter((product)=> product != null),
					map(() => ({requestUpgrade: true} as {requestUpgrade: true}))
				);
			}),
			catchError(() => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'tease-wall_request-failed-message'
				});
				return EMPTY;
			})
		);
	}
	private invertLicense (license: Ng1License | NgxLicense | NgxSalesforceLicense | null): InvertedSalesforceLicense {
		// STAB-1464 Use structuredClone
		const licenseCopy = JSON.parse(JSON.stringify(license));
		let invertedSfLicense: InvertedSalesforceLicense;

		if (
			// If common license
			!('license' in license) &&
			'salesforce_license' in license &&
			license.salesforce_license != null
		) {
			invertedSfLicense = licenseCopy.salesforce_license as InvertedSalesforceLicense;
			invertedSfLicense.license = Object.assign({}, licenseCopy as NgxLicense, {salesforce_license: null});
		} else if (
			// Or if already inverted license
			('license' in license) &&
			!('salesforce_license' in license) &&
			('salesforce_license' in license.license)
		) {
			// This only really happens in license management
			invertedSfLicense = licenseCopy as InvertedSalesforceLicense;
		} else {
			throw new Error('Could not determine license structure');
		}

		/**
		 * STAB-1455 (See relevant notes on ticket)
		 * This line is necessary because of how GroupController::viewGroup loads the license into the selected service,
		 * and because of how license-details-form.controller.ts uses the licenseProduct
		 */
		if (!('licenseProduct' in invertedSfLicense.license) && 'license_product' in invertedSfLicense) {
			invertedSfLicense.license.licenseProduct = invertedSfLicense.license_product;
		}

		return invertedSfLicense;
	}

	private refreshCourseLicense () {
		this.courseService.getCourseLicense(this.selectedService.getGroup().group_id)
			.subscribe((license) => {
				this.selectedService.setLicense(license);
			});
	}

	private updateGroupBillingEntity (billingEntityId: number) {
		const group = this.selectedService.getGroup();

		this.courseService.updateCourse({
			...group,
			billing_entity_id: billingEntityId,
			// properties already exist but endpoint expects them in course settings object
			course_settings: {
				start_date: group.start_date ?? null,
				end_date: group.end_date ?? null,
				product_id: null // need to null out product id when updating billing entity
			}
		}).subscribe((group) => {
			this.selectedService.setGroup(this.Group.model(group));
		});
	}
}
