/* @ngInject */
export function RubricCategoryController ($scope) {
	$scope.categoryPoints = null;
	$scope.categoryTotal = 0;

	/**
	 *  Calculate category total
	 */
	$scope.updateCategoryTotal = function () {
		$scope.categoryTotal = $scope.schema.calculateTotalPoints($scope.element);
	};

	/**
	 * Calculate category points
	 */
	$scope.updateCategoryPoints = function () {
		$scope.categoryPoints = $scope.schema.calculatePointsEarned($scope.element);
	};

	/**
	 * Get score label
	 *
	 * @returns {string}
	 */
	$scope.getScoreLabel = function () {
		let label = '';
		if ($scope.categoryTotal > 0) {
			if (isNaN(parseFloat($scope.categoryPoints))) {
				label = '— / ' + $scope.categoryTotal;
			} else {
				label = $scope.categoryPoints + ' / ' + $scope.categoryTotal;
			}
		}
		return label;
	};

	/**
	 * Whether category points is a negative value
	 *
	 * @returns {boolean}
	 */
	$scope.isCategoryNegative = function () {
		return $scope.categoryPoints < 0;
	};

	/**
	 * Whether category points is a positve value
	 *
	 * @returns {boolean}
	 */
	$scope.isCategoryPositive = function () {
		return parseFloat($scope.categoryPoints) >= 0;
	};

	/**
	 * Schema elements change event handler
	 */
	$scope.$watch('schema.elements', function () {
		$scope.updateCategoryPoints();
		$scope.updateCategoryTotal();
	}, true);
}
