import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import type { Marker } from 'ngx/go-modules/src/interfaces/markers/marker';
import * as Color from 'color';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'marker',
	template: require('./marker.component.html'),
	styles: [require('./marker.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerComponent {
	@Input() public marker: Marker;
	@Input() public showButton: boolean = false;
	@Input() public withName: boolean = false;
	@Input() public disabled: boolean = false;
	@Input() public showRemove: boolean = false;
	@Input() public aiStyle: boolean = false;
	@Input() public withCount: boolean = false;
	@Output() public select: EventEmitter<any> = new EventEmitter();
	@Output() public remove: EventEmitter<any> = new EventEmitter();

	constructor (
		private translate: TranslateService
	) {}

	public getLabel (marker) {
		if (this.withCount) {
			return this.translate.instant('marker-display_marker-with-count', {
				name: marker.tag_name,
				count: marker.count
			});
		}
		return marker.tag_name;
	}

	public bestContrast () {
		const color = Color('#' + this.marker.tag_color);
		return color.contrast(Color('#ffffff')) > color.contrast(Color('#333333')) ? '#ffffff' : '#333333';
	}
}
