export interface TagEditorConfigOptions {
	viewTemplateUrl: string;
	editTemplateUrl: string;
	maxNumTags: number;
	maxTagLength: number;
}

export class TagEditorProvider {

	public $config: TagEditorConfigOptions;
	public defaults: TagEditorConfigOptions;

	constructor () {
		this.$config = this.defaults = {
			viewTemplateUrl: 'tag-editor/templates/view.html',
			editTemplateUrl: 'tag-editor/templates/edit.html',
			maxNumTags: 30,
			maxTagLength: 30
		};
	}

	public $get = () => {
		return { defaults: this.$config };
	};
}
