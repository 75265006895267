import template from './audio-description.component.html';
import { AudioDescriptionController } from './audio-description.controller';

export const AudioDescriptionComponentDef = {
	template,
	controller: AudioDescriptionController,
	bindings: {
		audioMedia: '=ngModel'
	},
	require: {
		mediaPreviewController: '^mediaPreview'
	}
};
