export function fullNameFilter () {
	return (user) => {
		if (!user) {
			return;
		}
		let name = user.first_name || '';
		if (user.last_name) {
			name += ' ' + user.last_name;
		}
		return name;
	};
}
