import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoMonitorTabEventModule } from 'ngx/go-modules/src/directives/go-monitor-tab-event';
import { GoAnchorModule } from 'ngx/go-modules/src/directives/go-anchor/go-anchor.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { GoTooltipDirective } from 'ngx/go-modules/src/directives/go-tooltip/go-tooltip.directive';
import { GoTooltipComponent } from 'ngx/go-modules/src/directives/go-tooltip/components/go-tooltip.component';
import { GoTooltipService } from 'ngx/go-modules/src/directives/go-tooltip/go-tooltip-service';

@NgModule({
	imports: [
		CommonModule,
		GoMonitorTabEventModule,
		OverlayModule,
		GoAnchorModule
	],
	declarations: [
		GoTooltipDirective,
		GoTooltipComponent
	],
	exports: [
		GoTooltipDirective,
		GoTooltipComponent
	],
	entryComponents: [
		GoTooltipComponent
	],
	providers: [
		GoTooltipService
	]
})
export class GoTooltipModule {}
