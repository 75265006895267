import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

interface RubricActionScope extends ng.IScope {
	rubricController: any;
	removeItem: () => void;
	duplicateItem: () => void;
	element: any;
}

/** @ngInject */
export function rubricActionsDirective (eventService: EventService): ng.IDirective {
	return {
		restrict: 'AE',
		require: '^rubric',
		replace: true,
		scope: {element: '=ngModel'},
		template: '<div class="rb-actions rb-showHide">' +
			'<button type="button" class="btn btn-xs btn-mini" ng-click="duplicateItem()" aria-label="duplicate item">' +
			'<i class="ficon-copy"></i>' +
			'</button>' +
			'<button type="button" class="btn btn-danger btn-xs btn-mini" data-cy="remove-element" ng-click="removeItem()" aria-label="remove item">' +
			'<i class="ficon-times"></i>' +
			'</button>' +
			'</div>',
		link (scope: RubricActionScope, _element, _attrs, controller) {
			scope.rubricController = controller;

			scope.removeItem = function () {
				scope.rubricController.removeItem(scope.element);
				eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);
			};

			scope.duplicateItem = function () {
				scope.rubricController.duplicateItem(scope.element);
				eventService.broadcast(EVENT_NAMES.RUBRIC_DATA_CHANGE);
			};
		}
	};
}
