import { clientSettings } from './client.settings';

export class ModelConfig {

	private $config;

	constructor () {
		this.$config = { serviceBaseUrl: clientSettings.GoReactV1API };
	}

	public $get = () => {
		return { defaults: this.$config };
	};
}
