import { IModalInstanceService } from 'angular-ui-bootstrap';
import { noop } from 'angular';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';

export interface Bindings  {
	options: any;
	attachment: any;
	onPreview: () => void;
	onEdit: () => void;
	onEdited: () => void;
	onDelete: () => void;
	onDeleted: () => void;
	onDownload: () => void;
}

export class AttachmentController implements Bindings {

	// Bindings
	public options: any;
	public attachment: any;
	public onPreview: () => void;
	public onEdit: () => void;
	public onEdited: () => void;
	public onDelete: () => void;
	public onDeleted: () => void;
	public onDownload: () => void;

	public mediaThumbnailOptions: any;
	public isReadonlyMode: boolean;
	public environmentVarsService: EnvironmentVarsService;
	private translateFilter: angular.translate.ITranslateFilter;

	/* @ngInject */
	constructor (
		private confirmModal: any,
		private mediaPreviewModal: any,
		private $filter: ng.IFilterService,
		private $q: ng.IQService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	/**
	 * Handles init life cycle hook
	 */
	public $onInit (): void {
		this.translateFilter = this.$filter('translate');
		const defaultOptions = {
			showDelete: () => true,
			showEdit: () => true,
			showPreview: () => true,
			canDownload: () => true
		};

		this.options = {...defaultOptions, ...this.options};

		this.mediaThumbnailOptions = {
			preventPreview: !this.isPreviewAllowed(),
			preventDownload: !this.options.canDownload(this.attachment),
			hideTitle: true,
			hideDuration: true
		};

		this.isReadonlyMode = !!this.environmentVarsService.get(EnvironmentVarsService.VAR.READONLY);
	}

	/**
	 * Use the media title and fallback on the original filename if no title
	 */
	public getTitle (): string {
		return this.attachment.media.title
			|| this.attachment.media.filename_original
			|| this.translateFilter('common_untitled');
	}

	/**
	 * Whether preview action is allowed
	 */
	public isPreviewAllowed (): boolean {
		return this.options.showPreview(this.attachment);
	}

	/**
	 * Whether edit action is allowed
	 */
	public isEditAllowed (): boolean {
		return this.options.showEdit(this.attachment) && !this.isReadonlyMode;
	}

	/**
	 * Whether delete action is allowed
	 */
	public isDeleteAllowed (): boolean {
		return this.options.showDelete(this.attachment) && !this.isReadonlyMode;
	}

	/**
	 * Handles preview click event
	 */
	public onPreviewHandler (): void {
		this.onPreview();
		this.openMediaPreviewModal(false);
	}

	/**
	 * Handles edit click event
	 */
	public onEditHandler (): void {
		this.onEdit();
		this.openMediaPreviewModal(true).result
			.then(() => {
				this.onEdited();
			})
			.catch(noop);
	}

	/**
	 * Handles delete click event
	 */
	public onDeleteHandler (): void {
		this.onDelete();
		this.openConfirmDeleteModal().result
			.then(() => {
				let promise: ng.IPromise<any>;
				if (this.options.disableAutoSave) {
					promise = this.$q.resolve();
				} else {
					promise = this.attachment.$delete();
				}

				promise.then(() => {
					this.onDeleted();
				});
			})
			.catch(noop);
	}

	/**
	 * Open the preview media modal
	 */
	public openMediaPreviewModal (edit: boolean): IModalInstanceService {
		const modalData = {
			allowDownload: this.options.canDownload(this.attachment),
			edit,
			media: this.attachment.media,
			groupId: this.options.groupId,
			onDownload: this.onDownload.bind(this)
		};

		return this.mediaPreviewModal.open({modalData});
	}

	/**
	 * Open confirm delete attachment modal
	 */
	public openConfirmDeleteModal (): IModalInstanceService {
		const defaultModalData = {
			message: 'modal-confirm-delete-attachment_sure-delete',
			no: 'common_cancel',
			title: 'modal-confirm-delete-attachment_remove-attachment',
			yes: 'common_remove'
		};

		return this.confirmModal.open({
			modalData: {
				...defaultModalData,
				...this.options.confirmDeleteModal
			}
		});
	}
}
