import { INgModelController, IAugmentedJQuery } from 'angular';

import { debounce, Cancelable } from 'lodash';

const ID_PREFIX = 'slide-toggle-';
let count: number = 0;

export interface Bindings {
	ariaLabel: string;
	disabled: string;
	ngModelController: INgModelController;
	value: boolean;
}

/* @ngInject */
export class SlideToggleController implements Bindings, ng.IOnInit, ng.IOnDestroy {

	// Bindings
	public ariaLabel: string;
	public disabled: string;
	public ngModelController: INgModelController;
	public value: boolean;

	public id: string;

	public keydownSignature: ((event: JQueryEventObject) => void) & Cancelable;

	constructor (private $element: IAugmentedJQuery, private $timeout: ng.ITimeoutService) {
		this.id = `${ID_PREFIX}${++count}`;
	}

	public $onInit (): void {
		this.keydownSignature = debounce(this.handleKeyDown, 500, { leading: true });

		this.$element.on('click', this.handleClick);
		this.$element.on('keydown', this.keydownSignature);
	}

	public $onDestroy (): void {
		this.$element.off('click', this.handleClick);
		this.$element.off('keydown', this.keydownSignature);
	}

	/**
	 * Handle element keydown event
	 *
	 * If key is SPACE or ENTER, trigger click
	 * for accessible keyboard users
	 */
	public handleKeyDown = (event: JQueryEventObject) => {
		if (event.target.closest('slide-toggle') !== this.$element[0]) {
			return;
		}

		if (event.key === ' ') {
			this.triggerClick(event);
		}
	};

	/**
	 * Handle element click event.
	 *
	 * This allows the component element to receive a click
	 * event that is then passed to the inner label.
	 */
	public handleClick = (event: JQueryEventObject) => {
		if (event.target !== this.$element[0]) {
			return;
		}

		this.triggerClick(event);
	};

	/**
	 * Handle input change event.
	 *
	 * Setting the view value also ensures that the callback
	 * affiliated with an `ngChange` directive will get invoked.
	 */
	public handleChange (): void {
		this.ngModelController.$setViewValue(this.value);
	}

	/**
	 * Simulate clicking the checkbox
	 *
	 * This triggers the behavior we desire for changing the state
	 * of the checkbox hidden within
	 */
	private triggerClick (event: JQueryEventObject): void {
		const label: HTMLLabelElement = this.$element[0].querySelector('.slide-toggle-label');
		const slideToggle = this.$element[0].querySelector('slide-toggle-control') as HTMLElement;
		event.preventDefault();
		label.click();

		// Return focus to main element after click
		this.$timeout(() => slideToggle.focus());
	}
}
