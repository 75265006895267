import { RubricWidgetPanelController as controller } from './panel.controller';
import template from './panel.component.html';

export default {
	controller,
	require: {
		rubricController: '^rubric'
	},
	template
} as ng.IComponentOptions;
