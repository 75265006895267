import { UserTourSchema } from './tour.schema';
import { DataTransformer } from '../common/data-transformer';

import * as dayjs from 'dayjs';
import { IHttpService } from 'angular';

export class UserTourTransformer extends DataTransformer {
	/* @ngInject */
	constructor (
		$http: IHttpService
	) {
		super($http);
	}

	public static request (userTour: UserTourSchema): any {
		const payload = {} as any;
		payload.user_id = userTour.userId;
		payload.id = userTour.id;
		payload.name = userTour.name;
		payload.views = userTour.views;
		payload.created_at = userTour.createdAt ? dayjs.utc(userTour.createdAt).format('YYYY-MM-DD HH:mm:ss') : null;
		return payload;
	}

	public static response (data: any): UserTourSchema {
		if(!data) return null;
		data.id = DataTransformer.propertyExists(data, 'id') ? parseInt(data.id, 10) : null;
		data.userId = DataTransformer.propertyExists(data, 'user_id', 'userId') ?
			parseInt(data.user_id || data.userId || data.user_id, 10) : null;
		data.createdAt = DataTransformer.propertyExists(data, 'created_at', 'createdAt') ?
			dayjs.utc(data.created_at || data.createdAt).toDate() : null;

		delete data.user_id;
		delete data.viewed_at;

		return data;
	}

	public transformRequestData (userTour: UserTourSchema): any {
		return UserTourTransformer.request(userTour);
	}

	public transformResponseData (data: any): UserTourSchema {
		return UserTourTransformer.response(data);
	}
}
