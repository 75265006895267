import type { HasMedia, MediaSchema } from '../media';

export interface ActivityInstruction {
	text: string | null;
	media_id: number;
	media: MediaSchema | null;
	type: string;
}

export enum ActivityInstructionType {
	RECORDING = 'recording',
	FEEDBACK = 'feedback'
}

export class ActivityInstructionModel implements ActivityInstruction, HasMedia {
	public static TYPE = ActivityInstructionType;

	public activity_id: number;
	public text: string | null;
	public media_id: number;
	public media: MediaSchema | null;
	public type: string;

	constructor (attributes?: ActivityInstruction) {
		this.init(attributes);
	}

	public static create (attributes?: ActivityInstruction): ActivityInstructionModel {
		return new ActivityInstructionModel(attributes || {} as ActivityInstruction);
	}

	public attachMediaData (media: MediaSchema): void {
		this.media = media;
		this.media_id = media ? media.media_id : null;
	}

	private init ({ text, media_id, media, type }): void {
		this.text = text;
		this.media_id = media_id;
		this.media = media;
		this.type = type;
	}
}
