
/**
 * Helper to santize string output in the unusual scenarios where we build the
 * string outside of the template.
 *
 * Filter strategy from
 * https://gomakethings.com/preventing-cross-site-scripting-attacks-when-using-innerhtml-in-vanilla-javascript/
 */
export class SanitizerService  {
	/* @ngInject */
	constructor (
		private $sce: ng.ISCEService
	) {}

	public sanitize (str: string): string {
		const temp = document.createElement('div');
		temp.textContent = str;
		return temp.innerHTML;
	}

	public isValidRedirectUrl (url: string): boolean {
		try {
			this.$sce.getTrustedResourceUrl(url);
			return true;
		} catch (err) {
			return false;
		}
	}
}
