import * as angular from 'angular';
import { isNumber } from 'lodash';
import { RubricSessionDataTransformerService } from './rubric-session-transformer.service';
import { clientSettings } from '../common/client.settings';
import * as dayjs from 'dayjs';

export const TYPES = {
	PEER: 'peer',
	INSTRUCTOR: 'instructor'
};

/* @ngInject */
export function RubricSessionModel (
	$resource,
	BaseModel,
	FeedbackNodeModel,
	Group,
	rubricSessionDataTransformerService: RubricSessionDataTransformerService
) {
	const resourceUrl = `${clientSettings.GoReactV2API}/submissions/:session_id/rubrics/:id`;

	const rubricSessionModel = $resource(resourceUrl, {
		id: '@id',
		session_id: '@session_id'
	}, {
		get: {
			method: 'GET',
			responseType: 'json',
			transformResponse: rubricSessionDataTransformerService.responseTransformers
		},
		update: {
			method: 'PUT',
			responseType: 'json',
			transformRequest: rubricSessionDataTransformerService.requestTransformers,
			transformResponse: rubricSessionDataTransformerService.responseTransformers
		},
		create: {
			method: 'POST',
			responseType: 'json',
			transformRequest: rubricSessionDataTransformerService.requestTransformers,
			transformResponse: rubricSessionDataTransformerService.responseTransformers
		},
		publish: {
			url: `${resourceUrl}/publish`,
			method: 'POST',
			transformRequest: rubricSessionDataTransformerService.requestTransformers,
			transformResponse: rubricSessionDataTransformerService.responseTransformers
		},
		getForSession: {
			method: 'GET',
			isArray: true
		}
	});

	// extend prototype with base model prototype
	angular.extend(rubricSessionModel.prototype, BaseModel.prototype);

	// extend prototype with feedback node model prototype
	// Can't use angular extend because es6 class functions are nonenumerable
	// See https://stackoverflow.com/a/45332959
	const feedbackNodeProperties = Object.getOwnPropertyNames(FeedbackNodeModel.prototype)
		.filter((prop) => !prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/));
	for (const prop of feedbackNodeProperties) {
		const value = Object.getOwnPropertyDescriptor(FeedbackNodeModel.prototype, prop);
		Object.defineProperty(rubricSessionModel.prototype, prop, value);
	}

	rubricSessionModel.TYPES = TYPES;

	/**
	 * Creates a new rubric session instance.
	 *
	 * @param data
	 * @returns {RubricSessionModel}
	 */
	rubricSessionModel.newInstance = (data) => {
		// TODO: Either angular is typed wrong, or we should be passing 2 more params to this method
		const transformedData: any = (rubricSessionDataTransformerService.responseTransformers[1] as any)(data || {});
		const instance = new rubricSessionModel(transformedData);
		instance.generation = 0;
		instance.numChildren = instance.numChildren || 0;
		instance.children = [];
		return instance;
	};

	// properties
	rubricSessionModel.prototype.id = null;
	rubricSessionModel.prototype.session_id = null;
	rubricSessionModel.prototype.title = '';
	rubricSessionModel.prototype.desc = '';
	rubricSessionModel.prototype.total = '';
	rubricSessionModel.prototype.published_at = null;
	rubricSessionModel.prototype.created_by = null;
	rubricSessionModel.prototype.created_at = null;
	rubricSessionModel.prototype.kind = 'rubric';

	/**
	 * Get id
	 *
	 * @returns {Number}
	 */
	rubricSessionModel.prototype.getId = function () {
		return this.id;
	};

	/**
	 * Whether or not this is saved
	 *
	 * @returns {boolean}
	 */
	rubricSessionModel.prototype.isSaved = function () {
		return this.id > 0;
	};

	/**
	 * Set owner
	 *
	 * @param user
	 */
	rubricSessionModel.prototype.setOwner = function (user) {
		this.created_by = user.user_id;
		this.user = user;
	};

	/**
	 * Whether user is owner of this
	 *
	 * @param user
	 * @returns {boolean}
	 */
	rubricSessionModel.prototype.isOwner = function (user) {
		return parseInt(this.created_by, 10) === parseInt(user.user_id, 10);
	};

	/**
	 * Whether rubric session is published_at
	 *
	 * @returns {boolean}
	 */
	rubricSessionModel.prototype.isPublished = function () {
		return this.published_at !== null;
	};

	/**
	 * Set rubric session as published_at / unpublished
	 */
	rubricSessionModel.prototype.setPublished = function (value) {
		const date = dayjs.utc().format('YYYY-MM-DD HH:mm:ss');
		this.published_at = value ? date : null;
	};

	/**
	 * Set rubric session template
	 *
	 * @param templateId
	 */
	rubricSessionModel.prototype.setTemplate = function (templateId) {
		this.template_id = templateId;
	};

	/**
	 * Set session data with using schema elements
	 *
	 * @param elements
	 */
	rubricSessionModel.prototype.setData = function (elements) {
		const vm = this;
		vm.points = null;
		vm.data = [];

		// Auto calculate total points
		vm.points = rubricSessionModel.calculatePoints(
			elements.map(function (element) {
				const points = parseFloat(element.points);
				return isNaN(points) ? null : points;
			})
		);

		// populate rubric session data
		angular.forEach(elements, function (element) {
			const dataItem = {} as any;
			dataItem.id = element.id;

			let pushToList = false;

			if (angular.isDefined(element.value)) {
				pushToList = true;
				dataItem.value = element.value;
			}
			if (angular.isDefined(element.points)) {
				pushToList = true;
				const points = parseFloat(element.points);
				dataItem.points = isNaN(points) ? null : points;
			}
			if (angular.isDefined(element.option)) {
				pushToList = true;
				dataItem.option = element.option;
			}

			if (pushToList) {
				vm.data.push(dataItem);
			}
		});
	};

	/**
	 * Publish rubric session
	 * Note that this toggles publishing. If it is published, it'll unpublish
	 */
	rubricSessionModel.prototype.publish = function () {
		const previousPublishedAt = this.published_at;

		this.setSaving(true);
		this.setPublished(!this.isPublished());

		const promise = rubricSessionModel.publish({
			id: this.id,
			session_id: this.session_id
		}).$promise;

		promise.then((data) => {
			angular.extend(this, data);
		});

		promise.catch(() => {
			this.published_at = previousPublishedAt;
		});

		promise.finally(() => {
			this.setSaving(false);
		});

		return promise;
	};

	/**
	 * Save rubric session
	 */
	rubricSessionModel.prototype.save = function () {
		const vm = this;

		vm.setSaving(true);

		angular.forEach(vm.elements, (element) => {
			if (angular.isDefined(element.points)) {
				const points = parseFloat(element.points);
				element.points = isNaN(points) ? null : points;
			}
		});

		let promise: ng.IPromise<any>;
		if (!vm.id) {
			promise = rubricSessionModel.create({
				session_id: this.session_id
			}).$promise;
		} else {
			promise = rubricSessionModel.update({
				id: this.id,
				session_id: this.session_id,
				data: this.data
			}).$promise;
		}

		promise.then(function (data) {
			angular.extend(vm, data);
		});

		promise.finally(function () {
			vm.setSaving(false);
		});

		return promise;
	};

	/**
	 * Remove this rubric session
	 *
	 * @returns {object}
	 */
	rubricSessionModel.prototype.remove = function () {
		const vm = this;

		if (vm.hasParent()) {
			vm.parent.removeChild(vm);
		}

		return vm;
	};

	/**
	 * Delete this rubric session without overwriting
	 * it with what is returned in the server response.
	 *
	 * @returns {Promise}
	 */
	rubricSessionModel.prototype.delete = function () {
		return rubricSessionModel.delete({
			id: this.getId(),
			session_id: this.session_id
		}).$promise;
	};

	/**
	 * Sum a set of values
	 *
	 * @param values
	 * @returns {number|null}
	 */
	rubricSessionModel.calculatePoints = function (values) {
		const numDecimals = 2;

		// Sum values
		let total = values.reduce(function (sum, value) {
			value = parseFloat(value);
			if (isNaN(value)) {
				return sum;
			}
			return sum + value;
		}, null);

		if (typeof total === 'number') {
			total = parseFloat(total.toFixed(numDecimals));
		}
		return total;
	};

	/**
	 * Calculate average score for a group of rubric sessions
	 *
	 * @param rubricSessions Collection of rubric sessions
	 * @param onlyPublished Only average published_at rubric sessions
	 * @returns {number|null}
	 */
	rubricSessionModel.calculateAverageScore = function (rubricSessions, onlyPublished) {
		// Filter published_at rubric sessions
		const filteredRubricSessions = rubricSessions
			.filter((rubricSession) => {
				return !onlyPublished || rubricSession.isPublished();
			})
			.filter((rubricSession) => {
				// Don't include `presenter` labeled rubrics in average score calculation
				return rubricSession.role !== Group.role.PRESENTER;
			});

		// Sum all published_at rubric session points
		let total = rubricSessionModel.calculatePoints(
			filteredRubricSessions.map(function (rubricSession) {
				return rubricSession.points;
			})
		);

		// Calculate average
		if (isNumber(total)) {
			total /= filteredRubricSessions.length;
		}

		return total;
	};

	return rubricSessionModel;
}
