/* @ngInject */
export function UserActivityHistoryController ($scope, $timeout) {
	const vm = this;
	let unwatchList = [];

	vm.$onInit = () => {
		// Key / value store of
		// category types to readable labels
		vm.categoryMap = {
			course: 'Courses',
			activity: 'Activities',
			session: 'Sessions',
			user_login: 'User Logins',
			user_role: 'User Roles',
			media_recording: 'Media Recordings',
			media_upload: 'Media Uploads',
			transaction: 'Transactions',
			comment: 'Comments'
		};

		// List of categories
		vm.categories = [];

		// Only used for ui performance.
		// When categorized mode is turned on,
		// we make a slight delay so that
		// the user mouse click will immediately
		// select the "list by categories" button.
		vm.categorizedDelay = false;

		// Update categories list when collection changes
		$scope.$watch(function () {
			return vm.events.length;
		}, function () {
			// Clean up watch list before categories get updated
			unwatchList.forEach(function (unwatch) {
				unwatch();
			});
			unwatchList = [];

			vm.categories = vm.uniqueCategories(vm.events);
		});

		// Add delay in between categorized mode change
		let timeout;
		$scope.$watch(function () {
			return vm.categorized;
		}, function (value) {
			if (timeout) {
				$timeout.cancel(timeout);
			}
			timeout = $timeout(function () {
				vm.categorizedDelay = !!value;
			});
		});
	};

	/**
	 * Derive unique list of categories from event collection
	 *
	 * @param events
	 * @returns {*}
	 */
	vm.uniqueCategories = function (events) {
		let categories = events.map(function (item) {
			return item.data_type;
		});

		// Remove duplicates
		categories = categories.filter(function (item, pos) {
			return categories.indexOf(item) === parseInt(pos, 10);
		});

		// Organize categories by type and value
		categories = categories.map(function (type) {
			return {
				type,
				value: vm.categoryMap[type]
			};
		});

		return categories;
	};

	/**
	 * Initialize category
	 *
	 * @param scope
	 * @param category
	 */
	vm.initCategory = function (scope, category) {
		unwatchList.push(scope.$watch(function () {
			return vm.expanded;
		}, function (value) {
			category.show = !!value;
		}));
	};
}
