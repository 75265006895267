import { AccessibilityAttachmentsController as controller } from './accessibility-attachments.controller';
import template from './accessibility-attachments.component.html';
import './style.less';

export const accessibilityAttachmentsComopnent = {
	controller,
	template,
	bindings: {
		groupId: '<',
		media: '=' // Two way binding to support updating the upstream media with the files
	}
} as ng.IComponentOptions;
