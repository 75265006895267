import * as angular from 'angular';
import { versionCompare } from './version-compare.helper';
import { uaParserFilter } from './ua-parser.filter';
import { UADetect } from './ua-detect.service';

export const detectModule = angular.module('detect', [])
	.value('versionCompare', versionCompare)
	.filter('uaFilter', uaParserFilter)
	.service('UADetect', UADetect)
	.name;
