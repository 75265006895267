import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as translatePartialLoader from 'angular-translate-loader-partial';
import * as angularDynamicLocale from 'angular-dynamic-locale';
import { GoLocalizeHelperService } from './go-localize-helper.service';
import { goreactMissingTranslationHandler } from 'go-modules/translation-helper/go-missing-translation-handler.factory';

export const goLocalizeHelperModule = angular.module('translationHelperModule', [
	angularTranslate,
	angularDynamicLocale,
	translatePartialLoader
])
	.service(GoLocalizeHelperService.NG1_INJECTION_NAME, GoLocalizeHelperService)
	.factory('goreactMissingTranslationHandler', goreactMissingTranslationHandler)
	.name;
