import * as angular from 'angular';
import * as uiDropdown from 'angular-ui-bootstrap/src/dropdown';

import {templates} from './templates';
import {richDropdownListItem} from './rich-dropdown-list-item.directive';
import {richDropdownSelection} from './rich-dropdown-selection.directive';
import {richDropdown} from './rich-dropdown.directive';
import {RichDropdownConfig} from './rich-dropdown.provider';
import * as angularTranslate from 'angular-translate';

import './style.less';

export const richDropdownModule = angular.module('go.rich-dropdown', [
	uiDropdown,
	angularTranslate
])
	.run(templates)
	.directive('richDropdownListItem', richDropdownListItem)
	.directive('richDropdownSelection', richDropdownSelection)
	.directive('richDropdown', richDropdown)
	.provider('richDropdownConfig', RichDropdownConfig)
	.name;
