import * as angular from 'angular';
import {
	DropUserDialogComponent
} from 'ngx/go-modules/src/components/dialogs/drop-user-dialog/drop-user-dialog.component';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { ENVIRONMENTS } from 'ngx/go-modules/src/services/environment-vars/environments';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';

export enum PAYMENT_TRANSACTION_STATUS {
	LOADING = 'LOADING',
	PENDING = 'PENDING',
	DONE = 'COMPLETE'
}


/* @ngInject */
export const CoursePaymentWallController = function (
	$window,
	$filter,
	User,
	Group,
	confirmModal,
	messageModal,
	BraintreeService,
	$location,
	$timeout,
	$document,
	$interval,
	ngxGoModalService: GoModalService
) {
	const vm = this;
	vm.environmentVarsService = EnvironmentVarsService.getInstance();

	/**
	 * Handles controller init life cycle hook
	 */
	vm.$onInit = () => {
		const environment = vm.environmentVarsService
			.get(EnvironmentVarsService.VAR.ENVIRONMENT) as any;
		vm.isLtiEnvironment = environment.name === ENVIRONMENTS.LTI;
		vm.paymentTransactionStatus = PAYMENT_TRANSACTION_STATUS;
		vm.canBeCreditedBack = {};
		if (!(vm.user instanceof User)) {
			throw new Error('Property binding `user`, required by CoursePaymentWallController, must be of type `User`');
		} else if (!angular.isObject(vm.course)) {
			throw new Error('Property binding `course`, required by CoursePaymentWallController, must be of type `Object`');
		}

		// Determine if credit can be issued back
		Group.canBeCredited(vm.user.getId(), vm.course.getId())
			.$promise
			.then((result) => {
				vm.canBeCreditedBack = result;
			})
			.catch((result) => {
				vm.canBeCreditedBack = result.data;
			});

		// Check if there is pending payment transaction
		vm.handlePendingPaymentTransaction();
	};

	vm.$onDestroy = () => {
		$interval.cancel(vm.paymentTransactionIntervalRef);
	};

	vm.handlePendingPaymentTransaction = () => {
		vm.paymentTransactionState = PAYMENT_TRANSACTION_STATUS.LOADING;

		BraintreeService
			.getPaymentTransactionStatus(vm.course.getId())
			.then(() => {
				vm.paymentTransactionState = PAYMENT_TRANSACTION_STATUS.PENDING;

				vm.paymentTransactionIntervalRef = $interval(() => {
					vm.checkPendingPaymentTransaction();
				}, 10000);
			})
			.catch(() => {
				vm.paymentTransactionState = PAYMENT_TRANSACTION_STATUS.DONE;
			});
	};

	vm.checkPendingPaymentTransaction = () => {
		BraintreeService
			.getPaymentTransactionStatus(vm.course.getId())
			.catch((e) => {
				// Payment transaction is finish
				// Let`s check if it was successful or not
				if (e.status === 404) {
					Group.get(vm.course.getId(), (res) => {
						$interval.cancel(vm.paymentTransactionIntervalRef);

						if(!res.hasPaid()) {
							messageModal.open({
								modalData: {
									title: $filter('translate')('payment-panel-payment-error_payment-processing-error'),
									message: $filter('translate')('payment-unsuccessful-transaction'),
									resolveBtnText: $filter('translate')('common_refresh-page')
								}
							}).result.then(() => {
								vm.reloadPage();
							});

							return;
						}

						vm.reloadPage();

					}, /*fail*/ null, /*ignoreCache*/ true);
				}
			});
	};

	vm.reloadPage = () => {
		// set visibility to hidden before reloading the page
		// to hide weird behavior when setting the path to home
		$document[0].body.style.visibility = 'hidden';

		if (vm.isLtiEnvironment) {
			// in LTI should set path to home first before reloading
			// to remove query params
			$location.path('/').search({});
			$timeout(() => $window.location.reload(), 1);
		} else {
			$window.location.reload();
		}
	};

	/**
	 * Opens the move user modal
	 *
	 * @returns {Promise}
	 */
	vm.openDropUserModal = () => {
		return ngxGoModalService.open(DropUserDialogComponent, false, { data: {
			group: vm.course,
			user: vm.user
		}}).afterClosed().subscribe((result: { drop: boolean }) => {
			if (result.drop) {
				$window.location.reload();
			} else if (result.drop === false) {
				messageModal.open({
					modalData: {
						title: $filter('translate')('drop-user-dialog_title'),
						message: $filter('translate')('modal-move-user_controller-message')
					}
				});
			}
		});
	};

	/**
	 * Opens the unregister modal
	 *
	 * @returns {Promise}
	 */
	vm.openUnregisterModal = () => {
		let message = $filter('translate')('modal-move-user_controller-sure-drop-course');

		if (vm.canBeCreditedBack.credit_back) {
			message += ' ' + $filter('translate')('modal-move-user_controller-credit-received');
		} else {
			message += ' ' + $filter('translate')('modal-move-user_controller-no-credit-received');
		}

		return confirmModal.open({
			modalData: {
				title: $filter('translate')('modal-move-user_controller-drop', '{ name: "' + vm.course.name + '"}'),
				message,
				yes: $filter('translate')('modal-move-user_controller-drop-course'),
				no: $filter('translate')('common_cancel')
			}
		}).result.then(() => {
			// Unregister the user from the course
			vm.course.deleteUser(vm.user)
				.then(() => {
					$window.location.reload();
				})
				.catch(() => {
					messageModal.open({
						modalData: {
							type: 'danger',
							message: $filter('translate')('modal-move-user_controller-message'),
							title: $filter('translate')('modal-move-user_controller-uh-oh')
						}
					});
				});
		});
	};
};
