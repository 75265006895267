import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'search'
})
export class SearchPipe implements PipeTransform {
	public transform (value: any[], searchTerm: string, keys: string[]): any[] {
		if (!value) return;
		if (!searchTerm) return value;
		if (!keys || keys.length === 0) return value;

		const lowerSearchTerm = searchTerm.toLowerCase();

		return value.filter((item) => {
			return keys.some((key) => {
				return (
					item[key] &&
					item[key].toString().toLowerCase().includes(lowerSearchTerm)
				);
			});
		});
	}
}
