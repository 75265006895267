import { debounce } from 'lodash';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';

/* @ngInject */
export function SlideDeckViewerController (
	$scope,
	feedbackSession,
	PlayerSync,
	mediaPlayer,
	DocumentMediaPlayer,
	CommentModel,
	$document,
	$mdToast,
	$analytics,
	MediaModel,
	eventService: EventService,
	CommentResourceTypes
) {
	const vm = this;
	const environmentVarsService = EnvironmentVarsService.getInstance();
	vm.pageNumber = 1;
	vm.numPages = 0;
	vm.documentViewer = null;
	vm.lastChange = null;
	vm.documentMediaPlayer = null;

	/**
	 * Controller init handler
	 */
	vm.$onInit = function () {

		vm.eventNames = [
			EVENT_NAMES.MEDIA_SYNC,
			EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_DELETED
		];
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => vm.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.MEDIA_SYNC:
						if (vm.media instanceof MediaModel &&
							parseInt(vm.media.media_id, 10) === parseInt(ev.data.media_id, 10)) {
							Object.assign(vm.media, ev.data);
						}
						break;
					case EVENT_NAMES.FEEDBACK_SESSION_FEEDBACK_DELETED:
						const feedbackItem = ev.data;
						if (feedbackItem instanceof CommentModel &&
							feedbackItem.isSyncEventType() &&
							feedbackItem.resource?.length > 0) {
							vm.track.removeSyncEvent(feedbackItem.resource[0].item);
						}
						break;
				}
			});

		// Fetch document viewer id
		vm.documentViewerId = feedbackSession.sourcePlayerId(vm.mainController.session);

		// Add track
		vm.addTrack();

		if (vm.initiallyEnableEditMode()) {

			// Turn off auto play
			if (feedbackSession.isPlaybackMode()) {
				vm.multiMediaDisplayController.playerControlsOptions.autoPlay = false;
			}

			// Determine which edit mode to enter
			let mode = PlayerSync.MODE.ASYNCHRONOUS_CAPTURE;
			if (feedbackSession.isRecordMode()) {
				mode = PlayerSync.MODE.SYNCHRONOUS_CAPTURE;
			}
			vm.slidePresentationViewerController.toggleEditMode(mode);
		}
	};

	/**
	 * Post link handler
	 */
	vm.$postLink = function () {
		$document.on('keydown', onKeyDown);
	};

	/**
	 * Controller destroy handler
	 */
	vm.$onDestroy = function () {
		vm.eventSubscription?.unsubscribe();
		vm.removeTrack();
		$document.off('keydown', onKeyDown);

		// Remove document media player from the media service registry
		if (vm.documentMediaPlayer) {
			mediaPlayer.unregister(vm.documentViewerId);
			vm.documentMediaPlayer = null;
		}
	};

	/**
	 * Handle document ready event
	 *
	 * @param documentViewer
	 */
	vm.onDocumentReady = function (documentViewer) {
		vm.documentViewer = documentViewer;
		vm.numPages = documentViewer.doc.numPages;
		vm.documentMediaPlayer = new DocumentMediaPlayer(vm.documentViewerId, documentViewer);

		// Add document media player to the media service registry
		mediaPlayer.register(vm.documentViewerId, vm.documentMediaPlayer);

		// We need to re-add the track each time the document media player
		// changes so that the track has the correct reference.
		vm.addTrack();
	};

	/**
	 * Add track
	 */
	vm.addTrack = function () {
		const session = vm.mainController.session,
			trackNumber = vm.multiMediaDisplayController.getTrackNumber(vm.documentViewerId);
		let mode = PlayerSync.MODE.PLAYBACK;

		// If we already have a track, remove it
		if (vm.track) {
			mode = vm.track.mode;
			vm.removeTrack();
		}

		// Add track to player sync
		vm.track = vm.mainController.playerSync.addTrack(
			trackNumber,
			mediaPlayer.get(vm.documentViewerId),
			session.getSyncEventsByTrack(trackNumber)
		);

		// Maintain the same capture mode state as the previous track
		vm.track.setMode(mode);

		// Add track event listeners
		vm.track.on(PlayerSync.EVENT.SYNC_EVENT_ADDED, syncEventAddedEventHandler);
		vm.track.on(PlayerSync.EVENT.SYNC_EVENT_UPDATED, syncEventUpdatedEventHandler);
		vm.track.on(PlayerSync.EVENT.SYNC_EVENT_REMOVED, syncEventRemovedEventHandler);

		// Register the track with the parent controller
		vm.slidePresentationViewerController.registerSlideTrack(vm.track);
	};

	/**
	 * Remove track
	 */
	vm.removeTrack = function () {
		// Remove track event listeners
		vm.track.off(PlayerSync.EVENT.SYNC_EVENT_ADDED, syncEventAddedEventHandler);
		vm.track.off(PlayerSync.EVENT.SYNC_EVENT_UPDATED, syncEventUpdatedEventHandler);
		vm.track.off(PlayerSync.EVENT.SYNC_EVENT_REMOVED, syncEventRemovedEventHandler);

		// Remove track
		vm.mainController.playerSync.removeTrack(vm.track);
		vm.track = null;
	};

	/**
	 * Go to next page
	 */
	vm.next = function () {
		if (vm.pageNumber + 1 <= vm.numPages) {
			vm.pageNumber++;
			vm.lastChange = 'next';
		}
	};

	/**
	 * Go to previous page
	 */
	vm.previous = function () {
		if (vm.pageNumber - 1 > 0) {
			vm.pageNumber--;
			vm.lastChange = 'previous';
		}
	};

	/**
	 * Whether edit mode should initially be enabled
	 *
	 * @returns {boolean}
	 */
	vm.initiallyEnableEditMode = function () {
		if (environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		return vm.mainController.session.mayEdit(vm.mainController.user, vm.mainController.userGroup)
			&& vm.mainController.session.isOwner(vm.mainController.user)
			&& !vm.track.getSyncEvents().length
			&& !vm.mainController.session.isLive();
	};

	/**
	 * Show sync event saved confirmation
	 */
	vm.showSyncEventSavedConfirmation = debounce(function (isUpdate) {
		return $mdToast.show({
			hideDelay: 1500,
			position: 'top right',
			controller: 'SlideDeckViewerSyncEventToastController',
			controllerAs: '$ctrl',
			templateUrl: 'feedback-session/slide-presentation-viewer/slide-deck-viewer/sync-event-toast/toast.tpl.html',
			parent: 'slide-deck-viewer',
			locals: {isUpdate}
		});
	}, 1000, {leading: true});

	/**
	 * Handle global key left and right events when in capture mode
	 *
	 * @param event
	 */
	function onKeyDown (event) {
		if (vm.track.isCaptureMode() &&
			vm.slidePresentationViewerController.instructionsDeferred.$resolved) {
			$scope.$apply(function () {
				if (event.keyCode === 37) {// left
					vm.previous();
				} else if (event.keyCode === 39) {// right
					vm.next();
				}
			});
		}
	}

	/**
	 * Sync event added event handler
	 *
	 * @param syncEvent
	 */
	function syncEventAddedEventHandler (syncEvent) {
		// Associate sync event with session and and media
		syncEvent.setSessionId(vm.mainController.session.getId());
		syncEvent.setMedia(vm.media);
		syncEvent.save().then(function () {
			// Add sync event comment
			const comment = CommentModel.newInstance();
			comment.setTime(syncEvent.master_time);
			comment.setResource(syncEvent, CommentResourceTypes.SYNC_EVENT, syncEvent.id);
			vm.contentController.addFeedbackItem(comment);
			vm.contentController.saveFeedbackItem(comment);

			// Show save confirmation
			vm.showSyncEventSavedConfirmation();
			logAnalytics(vm.lastChange);
		});
	}

	/**
	 * Sync event updated event handler
	 *
	 * @param syncEvent
	 */
	function syncEventUpdatedEventHandler (syncEvent) {
		syncEvent.setSessionId(vm.mainController.session.getId());
		syncEvent.setMedia(vm.media);
		syncEvent.save().then(function () {
			vm.showSyncEventSavedConfirmation(true);
			logAnalytics('edit');
		});
	}

	/**
	 * Sync event removed event handler
	 */
	function syncEventRemovedEventHandler () {
		logAnalytics('delete');
	}

	/**
	 * Sync event analytics helper
	 *
	 * @param label
	 */
	function logAnalytics (label) {
		$analytics.eventTrack('transition', {
			category: 'session',
			label
		});
	}
}
