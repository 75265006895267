export interface Color {
	hex: string;
	name: string;
	translation: string;
}

export const colors: Color[] = [
	{hex: 'FF7979', name: 'Bright Red Pink', translation: 'colors_bright-red-pink'},
	{hex: 'FFCC2B', name: 'Bright Yellow', translation: 'colors_bright-yellow'},
	{hex: '6EDE2D', name: 'Bright Green', translation: 'colors_bright-green'},
	{hex: '52C4FD', name: 'Bright Blue', translation: 'colors_bright-blue'},
	{hex: 'DE95FC', name: 'Bright Purple', translation: 'colors_bright-purple'},
	{hex: 'FF881D', name: 'Bright Orange', translation: 'colors_bright-orange'},
	{hex: 'A8DDEE', name: 'Light Blue', translation: 'colors_light-blue'},
	{hex: 'CCAADD', name: 'Light Purple', translation: 'colors_light-purple'},
	{hex: 'AADD99', name: 'Light Green', translation: 'colors_light-green'},
	{hex: 'FF9999', name: 'Light Red Pink', translation: 'colors_light-red-pink'},
	{hex: '9AF2F2', name: 'Light Aqua Blue', translation: 'colors_light-aqua-blue'},
	{hex: 'AFBFFD', name: 'Light Gray Blue', translation: 'colors_light-gray-blue'},
	{hex: 'FFA7E2', name: 'Pink', translation: 'colors_pink'},
	{hex: 'FFB76F', name: 'Light Orange', translation: 'colors_light-orange'},
	{hex: 'A2F8B3', name: 'Light Green', translation: 'colors_light-green'},
	{hex: 'bb0000', name: 'Brown Red', translation: 'colors_brown-red'},
	{hex: '9d6b00', name: 'Brown Gold', translation: 'colors_brown-gold'},
	{hex: '3a840c', name: 'Dark Yellow Green', translation: 'colors_dark-yellow-green'},
	{hex: '007BB7', name: 'Blue', translation: 'colors_blue'},
	{hex: '9933CC', name: 'Purple', translation: 'colors_purple'},
	{hex: 'C3510B', name: 'Dark Orange Red', translation: 'colors_dark-orange-red'},
	{hex: '2C7E98', name: 'Dark Gray Blue', translation: 'colors_dark-gray-blue'},
	{hex: '826D8D', name: 'Dark Gray Purple', translation: 'colors_dark-gray-purple'},
	{hex: '68795B', name: 'Dark Gray Green', translation: 'colors_dark-gray-green'},
	{hex: 'AA6063', name: 'Dark Gray Red', translation: 'colors_dark-gray-red'},
	{hex: '418080', name: 'Dark Blue Green', translation: 'colors_dark-blue-green'},
	{hex: '334488', name: 'Dark Blue', translation: 'colors_dark-blue'},
	{hex: '660044', name: 'Dark Purple', translation: 'colors_dark-purple'},
	{hex: '886644', name: 'Dark Orange Brown', translation: 'colors_dark-orange-brown'},
	{hex: '005511', name: 'Dark Green', translation: 'colors_dark-green'}
];
