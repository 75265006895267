import * as angular from 'angular';

/* @ngInject */
export const featureCache = function (WebStorage) {
	const storage = new WebStorage('featureCache');

	return {

		/**
		 * Features
		 */
		FEATURE: {
			FeedbackInstructions: 'featureFeedbackInstructions',
			RecordingInstructions: 'featureRecordingInstructions'
		},

		/**
		 * Mark as seen
		 *
		 * @param id
		 * @param feature
		 */
		seen (id, feature) {
			const cache = storage.get(feature) || {};
			cache[id] = true;
			storage.put(feature, cache);
		},

		/**
		 * Whether user has seen
		 *
		 * @param id
		 * @param feature
		 */
		hasSeen (id, feature) {
			const cache = storage.get(feature);
			return angular.isObject(cache) ? !!cache[id] : false;
		}
	};
};
