import type { CreateUpdateMediaParameters } from 'go-modules/models/media';
import { MediaSource } from '../../models/media';
import { UniversalMediaChooserController } from '../umc.controller';
import { merge } from 'lodash';
import { UmcInstancesService } from 'go-modules/services/umc-instances/umc-instances.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
export interface Bindings {
	umcController: UniversalMediaChooserController;
	options: any;
	umcInstanceName: string;
}

export class UniversalMediaChooseUploadVideoController implements Bindings {

	// Bindings
	public umcController: UniversalMediaChooserController;
	public options: any;
	public umcInstanceName: string;

	public isReady: boolean;
	public uploadOptions: any;
	private media: any;
	private umc: any;
	private eventSubscription: any;
	private eventNames: string[];

	/* @ngInject */
	constructor (
		private $scope: ng.IScope,
		private MediaModel: any,
		private UniversalMediaChooser: any,
		private $window: ng.IWindowService,
		private umcInstancesService: UmcInstancesService,
		private eventService: EventService
	) {
		this.isReady = false;

		this.eventNames = [
			EVENT_NAMES.MEDIA_SYNC,
			EVENT_NAMES.FINE_UPLOADER_UPLOAD,
			EVENT_NAMES.FINE_UPLOADER_SUCCESS,
			EVENT_NAMES.UMC_CLOSE
		];
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => this.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.MEDIA_SYNC:
						if (this.media instanceof this.MediaModel
							&& parseInt(this.media.media_id, 10) === parseInt(ev.data.media_id, 10)
						) {
							Object.assign(this.media, ev.data);
						}
						break;
					case EVENT_NAMES.FINE_UPLOADER_UPLOAD:
						const file = ev.data.file;
						const key = ev.data.key;
						// Update the media filename, original filename and type now that we have the info
						this.media.filename_original = file.name;
						this.media.title = file.title || this.media.title; // Allow typed filename to override
						// Even though this component does not set the filename in the DB, it is still needed
						// to exist to be available in case this is a captions upload. So we will set it, but not
						// "save" it. It ultimately will match.
						this.media.$save().then(() => this.media.filename = key);
						this.umcInstancesService.registerFile(file);
						this.umcController.handleMediaDetermined(this.media);
						break;
					case EVENT_NAMES.FINE_UPLOADER_SUCCESS:
						this.umc.selectedMedia = Promise.resolve(this.media);

						// automatically trigger a choose action
						if (this.options.chooseAutomatically) {
							this.eventService.broadcast(EVENT_NAMES.UMC_CHOOSE, this.media);
						}
						break;
					case EVENT_NAMES.UMC_CLOSE:
						this.eventService.broadcast(EVENT_NAMES.FINE_UPLOADER_CANCEL_ALL, this.media);
						break;
				}
			});
	}

	public $onInit (): void {
		if (!this.options || !this.umcInstanceName || !this.options.groupId) {
			throw new Error('Must provide all required params (groupId, umcInstanceName)');
		}

		this.umc = this.UniversalMediaChooser.get(this.umcInstanceName);

		this.uploadOptions = merge({
			blacklist: this.options.blacklist,
			request: {
				params: {
					group_id: this.options.groupId,
					resource_id: this.options.resourceId,
					resource_type: this.options.resourceType,
					user_agent: this.$window.navigator.userAgent,
					timestamp: () => {
						return new Date().getTime();
					}
				}
			},
			validation: {
				minSizeLimit: this.options.uploadMinSizeLimit,
				// Override the default upload size limit when the options specify one
				sizeLimit: this.options.uploadSizeLimit > 0 ? this.options.uploadSizeLimit : undefined
			},
			uploadMessageFn: this.options.uploadMessageFn
		}, this.options.fineUploader);

		if (!this.uploadOptions.validation.sizeLimit) {
			delete this.uploadOptions.validation.sizeLimit;
		}

		this.initAsync().then(() => this.$scope.$evalAsync());
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	private async initAsync (): Promise<void> {
		this.media = await this.createMedia();
		this.uploadOptions.request.params.media_id = this.media.media_id;
		this.uploadOptions.request.params.user_id = this.media.created_by;
		this.uploadOptions.uniqueId = this.media.filename; // Use this uniqueId
		this.isReady = true;
	}

	private createMedia (): ng.IPromise<any> {
		const params: CreateUpdateMediaParameters = {
			add_to_collection: this.options.addToCollection,
			add_to_folder: this.options.addToFolder,
			group_id: this.options.groupId,
			source: MediaSource.FINEUPLOADER,
			media_status: this.MediaModel.PENDING,
			title: this.options.mediaTitle,
			media_url: null,
			resource_type: this.options.resourceType
		};

		if (this.options.mediaType) {
			params.media_type = this.options.mediaType;
		}

		return this.umcController.createOrUpdatePlaceholderMedia(params);
	}
}
