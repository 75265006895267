import { GoSource, SourceInitOptions, GoSourceConfig } from '../go-source';
import { DeviceKit } from 'go-modules/device-kit';
import AudioActivityDetector from './audio-activity-detector/index';
import { MediaStreamKinds } from 'go-modules/device-kit/media-stream-factory/media-stream-factory';
import { States } from 'go-modules/video-scene/state-emitter/state-emitter';
import { MediaStreamSource } from '../media-stream-source';
import { GoScene } from 'go-modules/video-scene/go-scene/go-scene';

export class MicrophoneSource extends MediaStreamSource {
	public static readonly event  = 'microphone_status';
	public readonly audioContext: AudioContext = new (window.AudioContext || window.webkitAudioContext)();

	constructor (goSourceConfig: GoSourceConfig, private deviceKit: DeviceKit) {
		super(goSourceConfig);
	}

	public async init (options?: SourceInitOptions): Promise<void> {
		this.setState(GoSource.STATES.INITIALIZING);

		if(this.stream) this.stopStream();
		await this.deviceKit.ensureDevicePermissions(false, true);
		const device = await (this.resource
			? this.deviceKit.getDeviceById(this.resource as string, MediaStreamKinds.AUDIO)
			: this.deviceKit.getPreferredMicrophone()
		);

		if(!device) {
			// An error message stating that a device could not be found
			// Would be more appropriate. Setting it to the generic
			// issue with microphone for now.
			this.setFailed(GoSource.REASONS.MICROPHONE_NO_AUDIO, this.init);
			return;
		}

		this.deviceKit.setPreferredMicrophone(device);
		this.resource = device.getId();

		try {
			this.stream = await device.getStream();
			await this.test(options);
		} catch (err) {
			this.handleNoAudio(options);
		}
	}

	public async test (options?: SourceInitOptions): Promise<boolean> {
		const audioActivityDetector = new AudioActivityDetector(this.stream, { audioContext: this.audioContext });
		const pass = await audioActivityDetector.run();

		if (pass) {
			this.setState(GoSource.STATES.ACTIVE);
		} else {
			this.handleNoAudio(options);
		}

		return pass;
	}

	public render (_scene: GoScene) {}

	public setActive (active: boolean): void {
		if (this.stream) {
			this.stream.getAudioTracks()[0].enabled = active;
			super.setActive(active);
		}
	}

	public async destroy (): Promise<void> {
		super.destroy();
		if(this.audioContext.state === 'running') {
			await this.audioContext.close();
		}
	}

	public get eventName (): string {
		return MicrophoneSource.event;
	}

	private handleNoAudio (options?: SourceInitOptions) {
		if (options?.continueWithoutAudio) {
			this.setState(States.MISSING);
		} else {
			this.setFailed(GoSource.REASONS.MICROPHONE_NO_AUDIO, this.init);
		}
	}
}
