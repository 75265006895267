import { EventService, GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewRef
} from '@angular/core';
import type { Media } from 'go-modules/services/attachment/media';
import {
	BehaviorSubject,
	catchError,
	defer,
	delay,
	EMPTY,
	filter,
	finalize,
	interval,
	map,
	Observable,
	of,
	Subject,
	take,
	takeUntil
} from 'rxjs';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import {
	MediaTranscription,
	MediaTranscriptionAnalytics,
	MediaTranscriptionAnalyticsConfig,
	TranscriptionStatus,
	WordCriteria
} from 'ngx/go-modules/src/interfaces/media-transcription';
import { TranscriptionService } from 'ngx/go-modules/src/services/transcription/transcription.service';
import {
	TranscriptSelectOptions,
	TranscriptSelectOptionsTranslations
} from 'ngx/go-modules/src/enums/transcript-select-options';
import { TranslateService } from '@ngx-translate/core';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import {
	TranscriptScrollStrategyFactory
} from 'ngx/go-modules/src/components/feedback-session/transcript-viewer/transcript-scroller/transcript-scroll-strategy.factory';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxSessionService } from 'ngx/go-modules/src/services/session/session.service';
import type { Session } from 'ngx/go-modules/src/services/session-list-datasource/session-list.datasource';
import { SelectedService, selectedServiceToken } from 'go-modules/services/selected/selected.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import {
	EditTranscriptionSpeakersDialogComponent
} from '../../dialogs/edit-transcription-speakers-dialog/edit-transcription-speakers-dialog.component';
import { TeaseWallConfig } from 'ngx/go-modules/src/directives/tease-wall/tease-wall.config';
import {
	TEASE_WALL_BETA_REQUEST_REMEMBER_KEY,
	TEASE_WALL_PURCHASE_REMEMBER_KEY,
	TEASE_WALL_UPGRADE_REMEMBER_KEY
} from 'ngx/go-modules/src/directives/tease-wall/constants';
import type { License as GroupLicense } from 'go-modules/services/group/license';
import {
	MockAnalyticsParsedMediaTranscript
} from 'ngx/go-modules/src/components/feedback-session/session-analytics/session-analytics.component';
import { SelfPayService } from 'ngx/go-modules/src/services/self-pay/self-pay.service';
import { NgxFeatureFlagService } from 'ngx/go-modules/src/services/feature-flag/feature-flag.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';
import { NgxLicenseUpgradeService } from 'ngx/go-modules/src/services/license/license-upgrade/license-upgrade.service';
import { MatSelectChange } from '@angular/material/select';

interface WordStyle {
	bold: boolean;
	highlight: boolean;
	select: boolean;
}

interface SearchCriteria {
	wordCriteria: WordCriteria[];
	styleStrategy: StyleStrategy;
	type: SearchCriteriaType;
}

interface StyleStrategy {
	bold: boolean;
	highlight: boolean;
}

export enum SearchCriteriaType {
	SEARCH_FIELD = 'search-field',
	API_FIELD = 'api-field'
}

export interface Sentence {
	id: number;
	speaker: string;
	start: number;
	parsedText: string;
	separateWords: string[];
}

// Incomplete transcripts don't have utterances to get sentences from
export interface TranscriptIncomplete {
	status: TranscriptionStatus.PROCESSING | TranscriptionStatus.ERROR | TranscriptionStatus.QUEUED;
}

export interface TranscriptComplete {
	status: TranscriptionStatus.COMPLETED;
	sentences: Sentence[];
}

export type ParsedMediaTranscript = TranscriptIncomplete | TranscriptComplete;

export interface HighlightedSearchResults {
	sentenceId: number;
	startIndex: number;
	endIndex: number;
}

export interface Utterance {
	confidence: number;
	end: number;
	start: number;
	speaker: string;
	text: string;
	words: Word[];
}

export interface Word {
	text: string;
	confidence: number;
	end: number;
	start: number;
	speaker: string;
}

@Component({
	selector: 'transcript-viewer',
	template: require('./transcript-viewer.component.html'),
	styles: [require('./transcript-viewer.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: VIRTUAL_SCROLL_STRATEGY,
			useFactory: (transcriptViewerComponent: TranscriptViewerComponent) => {
				return transcriptViewerComponent.scrollStrategy;
			},
			deps: [forwardRef(() => TranscriptViewerComponent)]
		}
	]
})
export class TranscriptViewerComponent implements OnInit, OnDestroy {
	@Input() public media: Media;
	@Input() public playerSync: any;
	@Input() public session: Session;
	@Input() public license: GroupLicense;
	@Output() public onSentenceClicked = new EventEmitter<number>();

	private parsedUtterances: Utterance[] = [];
	private destroyed$ = new Subject();

	public readonly SearchWordCriteria = { caseSensitive: false, punctuationSensitive: false };
	public readonly SearchStylesCriteria = {
		searchText: { bold: false, highlight: true },
		filler: { bold: true, highlight: false },
		hedging: { bold: true, highlight: false }
	};
	public apiFieldStyledWords = new Map<string, StyleStrategy>();
	public searchFieldStyledWords = new Map<string, StyleStrategy>();
	public sentenceStyles = new Map<number, StyleStrategy>();

	public readonly TranscriptStatus = TranscriptionStatus;
	public selectedTranscription = TranscriptSelectOptions.DEFAULT;
	public transcriptSelectOptions: TranscriptSelectOptions[] = Object.values(TranscriptSelectOptions);
	public transcriptSelectTranslations = TranscriptSelectOptionsTranslations;
	public hasAnalytics$ = new BehaviorSubject(false);
	public analytics: MediaTranscriptionAnalytics[] = [];
	public analyticsConfig: MediaTranscriptionAnalyticsConfig;
	public parsedMediaTranscript$: Observable<ParsedMediaTranscript>;
	public requestErrorOccurred$ = new BehaviorSubject(false);
	public isLoading$ = new BehaviorSubject(true);
	public isRunningAI$ = new BehaviorSubject(false);
	public isMultiSpeaker$ = new BehaviorSubject<null | boolean>(null);
	public uniqueSpeakers: string[] = [];
	public transcriptionId: number;

	public currentSearchIndex: number = 0;
	public searchResults$ = new BehaviorSubject<HighlightedSearchResults[]>([]);
	public searchText = '';

	public scrollStrategy = TranscriptScrollStrategyFactory.create(true);
	private playerSyncCallback;

	public shouldTease = false;
	public teaseWallConfig: TeaseWallConfig = {
		useRealData: true,
		translationKey: '', // Determined when student/reviewer vs instructor
		rememberKey: TEASE_WALL_BETA_REQUEST_REMEMBER_KEY,
		select: [
			'.search-bar',
			'.search-input',
			'.virtual-viewport'
		]
	};
	public environmentVarsService: EnvironmentVarsService;

	constructor (
		public ngxSessionService: NgxSessionService,
		private transcriptionService: TranscriptionService,
		private eventService: EventService,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private ngxGoToastService: NgxGoToastService,
		private modal: GoModalService,
		private selfPayService: SelfPayService,
		private featureFlagService: NgxFeatureFlagService,
		private ngxLicenseUpgradeService: NgxLicenseUpgradeService,
		@Inject(selectedServiceToken) public selectedService: SelectedService
	) {
		this.environmentVarsService = EnvironmentVarsService.getInstance();
	}

	public ngOnInit () {
		this.initRealData();
	}

	public ngOnDestroy () {
		this.apiFieldStyledWords.clear();
		this.searchFieldStyledWords.clear();
		this.clearPlayerSyncEvents();
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	public determineIfShouldTease () {
		return !this.license?.salesforce_license.transcriptions_enabled;
	}

	public initMockData () {
		const group = this.selectedService.getGroup();
		const isInstructorOrAbove = group.hasInstructorRole(true);
		if (isInstructorOrAbove) {
			this.teaseWallConfig.secondaryLink = NgxLicenseUpgradeService.getLearnMoreLink();

			if (this.featureFlagService.isAvailable('LICENSE_UPGRADE_PURCHASE')) {
				if (!this.license) {
					// No license so assume course is on student pay and allow instructors to purchase
					this.teaseWallConfig.translationKey = 'common_purchase';
					this.teaseWallConfig.rememberKey = TEASE_WALL_PURCHASE_REMEMBER_KEY;
					this.teaseWallConfig.promptAction =
						this.ngxLicenseUpgradeService.createTeaseWallPurchasePromptAction();
				} else {
					// License is defined, so user can upgrade
					this.teaseWallConfig.promptAction =
						this.ngxLicenseUpgradeService.createTeaseWallUpgradePromptAction(this.license);
					this.teaseWallConfig.licenseId = this.license?.id;
					this.teaseWallConfig.translationKey = 'common_upgrade';
					this.teaseWallConfig.rememberKey = TEASE_WALL_UPGRADE_REMEMBER_KEY;
				}

				this.selectedService.selectedSubject
					.pipe(takeUntil(this.destroyed$))
					.subscribe((selected) => {
						if (!selected.license) return;

						if (selected.license.salesforce_license.transcriptions_enabled) {
							// License got upgraded. Force launch ai zero state to show
							this.shouldTease = false;
							this.requestErrorOccurred$.next(true);
							this.parsedMediaTranscript$ = of(null);
							this.teaseWallConfig = { ...this.teaseWallConfig, useRealData: true };
						} else {
							// License updated and can be upgraded
							this.shouldTease = true;
							this.teaseWallConfig = {
								...this.teaseWallConfig,
								promptAction:
									this.ngxLicenseUpgradeService.createTeaseWallUpgradePromptAction(selected.license),
								translationKey: 'common_upgrade',
								rememberKey: TEASE_WALL_UPGRADE_REMEMBER_KEY,
								licenseId: selected.license.id,
								useRealData: false
							};
						}
					});
			} else {
				this.teaseWallConfig.translationKey = 'tease-wall_request-button';
				this.teaseWallConfig.promptAction = this.requestBetaAction();
			}
		} else {
			this.teaseWallConfig.promptAction = null;

			if (this.featureFlagService.isAvailable('LICENSE_UPGRADE_PURCHASE')) {
				this.teaseWallConfig.translationKey = 'tease-wall_contact-your-instructor-upgraded-text';
				this.teaseWallConfig.rememberKey = TEASE_WALL_UPGRADE_REMEMBER_KEY;
			} else {
				this.teaseWallConfig.translationKey = 'tease-wall_contact-your-instructor-text';
			}
		}

		this.teaseWallConfig.useRealData = false;

		this.parsedMediaTranscript$ = defer(() =>
			import(/* webpackChunkName: "MockSessionAnalyticsData" */ 'ngx/go-modules/src/components/feedback-session/tease-wall-mock/analytics-mock.json')
				.then((data: any) => data as MockAnalyticsParsedMediaTranscript)
		).pipe(
			// Previous tab elements are not immediately removed from the DOM when navigating to the transcript tab.
			// If the JSON data was already loaded, the virtual scroller would only render at half the available height
			delay(0),
			map((response: MockAnalyticsParsedMediaTranscript) => {
				this.analytics = response.analytics;
				this.hasAnalytics$.next(true);
				this.parsedUtterances = response.parsed_utterances;
				const sentences = this.createSentencesFromUtterances(this.parsedUtterances);
				this.checkAndUpdateMultiSpeaker(sentences);
				this.scrollStrategy.updateSentences(sentences);
				this.isLoading$.next(false);

				return {
					status: TranscriptionStatus.COMPLETED,
					sentences
				};
			})
		);
	}

	public initRealData () {
		this.loadAndParseTranscription();

		this.eventService.listen(EVENT_NAMES.MEDIA_SYNC)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(($event: GoEvent)=> {
				if (this.media.media_id !== $event.data.media_id) return;
				// If the transcript was updated or not
				if ($event.data.transcriptUpdated == null || $event.data.transcriptUpdated === false) return;
				// Event with matching media id and updated transcript found
				if (!this.isRunningAI$.getValue()) {
					this.loadAndParseTranscription();
				}
			});
	}

	public onSentenceClick (sentence: Sentence): void {
		this.onSentenceClicked.emit(sentence.start);
	}

	public onSearchIndexChange (idx: number) {
		this.currentSearchIndex = idx;
		this.scrollToCurrentIndex();
	}

	public scrollToCurrentIndex () {
		const searchResults = this.searchResults$.getValue();
		if (searchResults[this.currentSearchIndex] != null) {
			this.scrollStrategy.scrollToIndex(searchResults[this.currentSearchIndex].sentenceId, 'auto');
		}
	}

	public selectOption (value: MatSelectChange) {
		this.selectedTranscription = value.value;
		// We only need to regenerate the cached styles for the selected transcription
		this.updateApiFieldsCache(this.parsedUtterances);
	}

	public onSearchTextChange (text: string) {
		this.searchText = text;

		const searchResults = this.updateSearchFieldCacheAndGetSearchResults(this.parsedUtterances);
		this.searchResults$.next(searchResults);

		this.currentSearchIndex = 0;
		this.scrollToCurrentIndex();
	}

	/**
	 * A word/phrase is selected if it matches the currently selected search result
	 * This is making the assumption that the search results are sorted by sentenceId and word indices
	 */
	public isWordSelected (sentenceId: number, wordIndex: number): boolean {
		const searchResults = this.searchResults$.getValue();
		if (searchResults.length === 0) return false;
		return searchResults[this.currentSearchIndex].sentenceId === sentenceId &&
			wordIndex >= searchResults[this.currentSearchIndex].startIndex &&
			wordIndex <= searchResults[this.currentSearchIndex].endIndex;
	}

	public getSelectedTranscription () {

		if (this.selectedTranscription === TranscriptSelectOptions.DEFAULT) {
			return this.translate.instant('feedback-session-transcript_default');
		} else if (this.selectedTranscription === TranscriptSelectOptions.FILLER) {
			return this.translate.instant('feedback-session-transcript_filler-results', {
				results: this.analytics.length > 0 ? this.analytics[0].filler_total : 0
			});
		} else if (this.selectedTranscription === TranscriptSelectOptions.HEDGING) {
			return this.translate.instant('feedback-session-transcript_hedging-results', {
				results: this.analytics.length > 0 ? this.analytics[0].hedging_total : 0
			});
		}
	}

	/**
	 * Check if the word has any styles applied to it
	 * A word can have multiple styles applied to it if it matches multiple search criteria
	 * Always prioritize the truthy values
	 */
	public getWordStyle (sentenceId: number, wordIndex: number): WordStyle {
		const key = this.createWordKey(sentenceId, wordIndex);
		const apiStyle = this.apiFieldStyledWords.get(key);
		const searchStyle = this.searchFieldStyledWords.get(key);

		return {
			select: this.isWordSelected(sentenceId, wordIndex),
			bold: (searchStyle?.bold || apiStyle?.bold) || false,
			highlight: (searchStyle?.highlight || apiStyle?.highlight) || false
		};
	}

	/**
	 * Check if a word in a sentence matches the search criteria
	 */
	public isWordOrPhraseStyled (
		criteria: WordCriteria, separateWords: string[], currentWordIndex: number, strategyType: SearchCriteriaType
	): boolean
	{
		const adjustedSearchWord = this.adjustWord(criteria);
		const adjustedSeparatedWords =
			separateWords.map((word) =>
				this.adjustWord({
					value: word,
					caseSensitive: criteria.caseSensitive,
					punctuationSensitive: criteria.punctuationSensitive
				}));
		const adjustedWordInSentence = adjustedSeparatedWords[currentWordIndex];

		// do nothing if no search term or only one character
		if (adjustedSearchWord == null || adjustedSearchWord.length <= 1) {
			return false;
		}

		// If search term is a single word
		if (adjustedSearchWord.indexOf(' ') === -1) {
			// For search fields, we can do partial word matches
			if (strategyType === SearchCriteriaType.SEARCH_FIELD) {
				return adjustedWordInSentence.includes(adjustedSearchWord);
			}
			// For other strategies, we only want to match full words
			// Ex: Filler word "so" should not match to "Minnesota"
			return adjustedWordInSentence === adjustedSearchWord;
		}

		// Else search term is a phrase
		const splitSearch = adjustedSearchWord.split(' ');

		// Find all occurrences of the first word in the search term
		const startIndexes: number[] = [];
		adjustedSeparatedWords.join(' ').split(' ').forEach((w, i) => {
			if (w.startsWith(splitSearch[0])) {
				startIndexes.push(i);
			}
		});

		// Check if the current word is part of any complete phrase
		return startIndexes.some((startIndex) =>
			currentWordIndex - startIndex >= 0 &&
			currentWordIndex - startIndex < splitSearch.length &&
			adjustedSeparatedWords.slice(startIndex, startIndex + splitSearch.length).join(' ').includes(adjustedSearchWord)
		);
	}

	public runAI () {
		if (!(this.session.media as any).isReady()) {
			this.ngxGoToastService.createToast({type: 'warning', message: 'transcript-viewer_run-ai_media-not-ready'});
			return;
		}

		this.isRunningAI$.next(true);
		this.loadAndParseTranscription();
	}

	public shouldShowLaunchAIZeroState () {
		return this.ngxSessionService.mayEdit(this.session) &&
			!!this.selectedService.getLicense().salesforce_license?.transcriptions_enabled;
	}

	public editSpeakers (e: MouseEvent) {
		// dont want to start playing video
		e.preventDefault();
		e.stopPropagation();
		this.modal.open(EditTranscriptionSpeakersDialogComponent, true, {
			data: {
				transcriptionId: this.transcriptionId,
				speakers: this.uniqueSpeakers
			}
		}).afterClosed().pipe(
			filter((namesUpdated: boolean) => namesUpdated)
		).subscribe(() => {
			this.loadAndParseTranscription();
		});
	}

	public showBetaLabel () {
		return !this.featureFlagService.isAvailable('LICENSE_UPGRADE_PURCHASE');
	}

	private loadAndParseTranscription () {
		const isRunningAI = this.isRunningAI$.value;
		const mediaTranscription$ = isRunningAI ?
			this.transcriptionService.createTranscription(this.media) :
			this.transcriptionService.getTranscription(this.media, true);

		// Reset state when reloading
		if (!isRunningAI) {
			this.requestErrorOccurred$.next(false);
			this.isLoading$.next(true);
		}

		this.parsedMediaTranscript$ = mediaTranscription$.pipe(
			takeUntil(this.destroyed$),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 404) {
					this.shouldTease = this.determineIfShouldTease();
					if (this.shouldTease) {
						this.initMockData();
					}
				}

				if (this.isRunningAI$.value === true) {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: 'request-monitor_controller-something-wrong'
					});
				}
				this.requestErrorOccurred$.next(true);
				return EMPTY;
			}),
			finalize(() => {
				if (this.isRunningAI$.value) {
					this.isRunningAI$.next(false);
				} else {
					this.isLoading$.next(false);
				}
			}),
			map((response: MediaTranscription): ParsedMediaTranscript => {
				// note: we don't want to hide the launch ai screen until the request is successful
				if (this.isRunningAI$.value) {
					this.requestErrorOccurred$.next(false);
				}

				this.transcriptionId = response.id;

				switch (response.status) {
					case TranscriptionStatus.ERROR:
					case TranscriptionStatus.PROCESSING:
					case TranscriptionStatus.QUEUED:
						return { status: response.status };
					case TranscriptionStatus.COMPLETED:
						if (response.analytics) {
							this.analytics = response.analytics;
							this.analyticsConfig = response.analytics_config;
							this.hasAnalytics$.next(true);
						}

						this.handlePlayerSync(response.parsed_utterances);
						this.parsedUtterances = response.parsed_utterances;
						const sentences = this.createSentencesFromUtterances(this.parsedUtterances);
						this.checkAndUpdateMultiSpeaker(sentences);
						this.scrollStrategy.updateSentences(sentences);

						return {
							status: TranscriptionStatus.COMPLETED,
							sentences
						};
					default:
						// In the rare chance a status isn't provided, default to failed network state
						this.requestErrorOccurred$.next(true);
				}
			})
		);
	}

	/**
	 * This function has two main jobs that need to happen simultaneously:
	 * 1. Conditionally generate search results for a single transcript sentence (a sentence can have multiple results)
	 * 2. Cache words that need to be styled using its unique sentence id and word index pair
	 * This allows us to query any word's styles in the template without having to constantly iterate
	 * through all sentences. Otherwise, performance issues will impact virtual scrolling
	 */
	private processSentenceByCriteriaAndGetResults (
		sentence: Word[], sentenceId: number, searchCriteria: SearchCriteria
	): HighlightedSearchResults[] {
		// Convert word objects to sentence string list
		const separateWords = sentence.map((w) => w.text);

		let isBold = false;
		let isHighlighted = false;
		let startIndex = -1;
		const results: HighlightedSearchResults[] = [];

		// We want to loop through each word in the sentence to get its index
		// But the search word we care about is in the criteria, and we compare that to the entire sentence so that we
		// can handle phrase matching
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		separateWords.forEach((_, wordIdx) => {
			searchCriteria.wordCriteria.forEach((criteria) => {
				const isWordHighlighted = this.isWordOrPhraseStyled(
					criteria, separateWords, wordIdx, searchCriteria.type
				);

				if (isWordHighlighted) {
					// Save the styles for this highlighted word based on its search criteria
					// We only need to store words that are styled in the map to avoid allocating too much memory
					isBold = searchCriteria.styleStrategy.bold;
					isHighlighted = searchCriteria.styleStrategy.highlight;
					const key = this.createWordKey(sentenceId, wordIdx);
					if (searchCriteria.type === SearchCriteriaType.SEARCH_FIELD) {
						this.searchFieldStyledWords.set(key, { bold: isBold, highlight: isHighlighted });
					} else if (searchCriteria.type === SearchCriteriaType.API_FIELD) {
						this.apiFieldStyledWords.set(key, { bold: isBold, highlight: isHighlighted });
						// We don't need to accumulate search result phrases for api fields
						return;
					}

					// Mark the start of a highlighted segment
					if (startIndex === -1) startIndex = wordIdx;

					// Check if it's the end of a sentence or phrase
					const isEndOfSegment = wordIdx === sentence.length - 1 ||
						!this.isWordOrPhraseStyled(criteria, separateWords, wordIdx + 1, searchCriteria.type);

					// If the next word is not a highlighted segment, then we reached the end of the word/phrase
					if (isEndOfSegment) {
						results.push({ sentenceId, startIndex, endIndex: wordIdx });
						// Reset for the next highlighted word/phrase
						startIndex = -1;
					}
				}
			});
		});
		return results;
	}

	private updateApiFieldsCache (parsedUtterances: Utterance[]): void {
		// Clear existing values before recalculating
		this.apiFieldStyledWords.clear();
		const apiFieldCriteria = this.getApiFieldCriteria();

		// If no api criteria (eg: default dropdown option), then no need to update the cache
		if (apiFieldCriteria == null) return;

		parsedUtterances.forEach((utterance, sentenceId) => {
			// We don't need to store any apiField results
			this.processSentenceByCriteriaAndGetResults(utterance.words, sentenceId, apiFieldCriteria);
		});

	}

	private updateSearchFieldCacheAndGetSearchResults (parsedUtterances: Utterance[]): HighlightedSearchResults[] {
		// Clear existing values before recalculating
		this.searchFieldStyledWords.clear();
		const searchTextCriteria = this.getSearchTextCriteria();

		const accumulatedSearchResults: HighlightedSearchResults[] = [];

		// If no search term criteria (ex: empty search input), then no need to update the cache
		if (searchTextCriteria == null) return accumulatedSearchResults;

		parsedUtterances.forEach((utterance, sentenceId) => {
			const searchResults =
				this.processSentenceByCriteriaAndGetResults(utterance.words, sentenceId, searchTextCriteria);
			accumulatedSearchResults.push(...searchResults);
		});
		return accumulatedSearchResults;
	}

	/**
	 *  Join the words for each utterance to get the full sentence, and append other properties for the template
	 *  An id is applied to each sentence to improve the performance of repetitive virtual scroll calculations
	 */
	private createSentencesFromUtterances (utterances: Utterance[]): Sentence[] {
		return utterances.map((utterance, idx) => ({
			id: idx,
			speaker: utterance.speaker,
			start: utterance.start,
			parsedText: utterance.words.map((word) => word.text).join(' '),
			separateWords: utterance.words.map((word) => word.text)
		}));
	}

	private adjustWord (criteria: WordCriteria): string {
		let adjustedWord = criteria.value.trim();
		if (!criteria.caseSensitive) {
			adjustedWord = adjustedWord.toLowerCase();
		}
		if (!criteria.punctuationSensitive) {
			// https://www.geeksforgeeks.org/how-to-remove-punctuation-from-text-using-javascript/
			adjustedWord = adjustedWord.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '');
		}
		return adjustedWord;
	}

	/**
	 * Each word in a sentence is uniquely identified by its sentenceId and wordIndex
	 * This should be used when querying a styled word map
	 */
	private createWordKey (sentenceId: number, wordIndex: number): string {
		return `${sentenceId}-${wordIndex}`;
	}

	private getSearchTextCriteria (): SearchCriteria | null {
		if (this.searchText == null || this.searchText.length <= 1) return null;
		return {
			wordCriteria: [{ value: this.searchText, ...this.SearchWordCriteria }],
			styleStrategy: this.SearchStylesCriteria.searchText,
			type: SearchCriteriaType.SEARCH_FIELD
		};
	}

	private getApiFieldCriteria (): SearchCriteria | null {
		switch (this.selectedTranscription) {
			case TranscriptSelectOptions.FILLER:
				return {
					wordCriteria: this.analyticsConfig.filler,
					styleStrategy: this.SearchStylesCriteria.filler,
					type: SearchCriteriaType.API_FIELD
				};
			case TranscriptSelectOptions.HEDGING:
				return {
					wordCriteria: this.analyticsConfig.hedging,
					styleStrategy: this.SearchStylesCriteria.hedging,
					type: SearchCriteriaType.API_FIELD
				};
			default:
				return null;
		}
	}

	/**
	 * Check if the transcript has multiple speakers and update the scroll strategy
	 */
	private checkAndUpdateMultiSpeaker (sentences: Sentence[]): void {
		const speakers = sentences.map((sentence) => sentence.speaker);
		this.uniqueSpeakers = [...new Set(speakers)];
		this.isMultiSpeaker$.next(this.uniqueSpeakers.length > 1);
		this.scrollStrategy.updateLayout(this.isMultiSpeaker$.getValue());
	}

	/**
	 * Create Event listeners to each sentences
	 * and when the event emitted we will apply styles to that sentence
	 * by calling the onTimeReached method
	 */

	private handlePlayerSync (parsedUtterances: Utterance[]): void {
		// clear events before starting a new one.
		this.clearPlayerSyncEvents();

		// We save this in the variable so it will be the same instance
		// When we call playerSync.off in the clearPlayerSyncEvents
		// Or it there will be memory leak
		this.playerSyncCallback = this.onTimeReached.bind(this);

		parsedUtterances.forEach((sentence) => {
			this.playerSync.on(this.millisecondsToSeconds(sentence.start), this.playerSyncCallback);
		});
	}

	/**
	 * Clear player sync event to avoid memory leaks
	 */
	private clearPlayerSyncEvents () {
		this.sentenceStyles.clear();

		if (!this.playerSyncCallback || !this.parsedUtterances) return;

		this.parsedUtterances.forEach((utterance) => {
			this.playerSync.off(this.millisecondsToSeconds(utterance.start), this.playerSyncCallback);
		});
	}

	/**
	 * Apply Highlight styles to the sentence
	 */
	private onTimeReached (time: number) {
		// Ensure the component is not destroyed before we proceed
		if (!this.cdr || (this.cdr as ViewRef).destroyed) return;

		const utteranceIndex = this.parsedUtterances
			.findIndex((item) => (this.millisecondsToSeconds(item.start) === this.millisecondsToSeconds(time)));

		let scrollIndex = utteranceIndex;
		if (utteranceIndex > 0) scrollIndex = utteranceIndex - 1;

		this.scrollStrategy.scrollToIndex(scrollIndex, 'auto');
		this.sentenceStyles.set(utteranceIndex, {bold: false, highlight: true});
		this.cdr.detectChanges();

		interval(1500 /** Css Transition */)
			.pipe(take(1)).subscribe(() => {
				if (this.cdr && !(this.cdr as ViewRef).destroyed ) {
					this.sentenceStyles.delete(utteranceIndex);
					this.cdr.detectChanges();
				}
			});
	}

	private millisecondsToSeconds (ms: number) {
		return Math.floor(ms / 1000);
	}

	private requestBetaAction () {
		const group = this.selectedService.getGroup();

		if (!group) return null;

		return this.selfPayService.requestBeta(group.group_id).pipe(
			map(()=> {
				// On success, need to map response to translation key for tease wall
				return 'tease-wall_request-sent-message';
			}),
			catchError(() => {
				this.ngxGoToastService.createToast({
					type: GoToastStatusType.ERROR,
					message: 'tease-wall_request-failed-message'
				});
				return EMPTY;
			})
		);
	}
}
