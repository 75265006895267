import template from './panel.component.html';

export default {
	transclude: {
		heading: '?panelHeading',
		body: 'panelBody',
		footer: '?panelFooter'
	},
	template
};
