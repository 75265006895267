import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UADetect as UADetectClass, uaDetectToken } from 'go-modules/detect/ua-detect.service';
import type { GoToastOptions } from 'ngx/go-modules/src/interfaces/go-toast-options';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'ngx-go-toast',
	template: require('./go-toast.component.html'),
	styles: [require('./go-toast.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxGoToastComponent implements OnInit, OnDestroy {

	public toastDetails: GoToastOptions;
	public ToastStatusTypes = GoToastStatusType;
	public hideToastTime: null|number;

	private toastTimeout;
	private readonly HIDE_TOAST_TIME = 5000;
	private destroyed$ = new Subject();

	constructor (
		@Inject(uaDetectToken) public UADetect: UADetectClass,
		private cdr: ChangeDetectorRef,
		private goToastService: NgxGoToastService
	) {}

	public ngOnInit () {
		this.goToastService.toastObserver
			.pipe(takeUntil(this.destroyed$))
			.subscribe((toastOptions: GoToastOptions|null) => {
				if (!toastOptions) {
					this.closeToast();
				} else {
					this.setToastDetails(toastOptions);
				}
			});
	}

	public ngOnDestroy () {
		this.destroyed$.next(null);
		this.destroyed$.complete();
		if (this.toastTimeout) {
			clearTimeout(this.toastTimeout);
		}
	}

	public shouldShowToast () {
		return !!this.toastDetails;
	}

	public undoAction () {
		this.toastDetails.undo();
		this.closeToast();
	}

	public closeToast (): void {
		this.toastDetails = null;
		this.cdr.detectChanges();
	}

	private setToastDetails (data: GoToastOptions): void {
		this.hideToastTime = data.hideToastTime ?? this.HIDE_TOAST_TIME;

		if (this.toastTimeout) {
			clearTimeout(this.toastTimeout);
		}

		this.toastDetails = data;
		this.cdr.detectChanges();

		if (this.hideToastTime) {
			this.toastTimeout = setTimeout(() => {
				this.closeToast();
			}, this.hideToastTime);
		}
	}
}
