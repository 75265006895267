import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import type { LicensesResponse } from 'ngx/go-modules/src/interfaces/licenses';
import { Observable } from 'rxjs';
import type { NotificationSettings } from 'ngx/go-modules/src/interfaces/notification-settings';
import type { EmailVerificationResponse } from 'ngx/go-modules/src/enums/email-verification-response';
import type { User } from 'ngx/go-modules/src/interfaces/user';
import { CourseFolder } from 'ngx/go-modules/src/services/course/interfaces/course-folder.interface';
@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor (private http: HttpClient) {}

	public getCurrentLicenses (): Observable<LicensesResponse>
	{
		return this.http.get<LicensesResponse>(`${clientSettings.GoReactV2API}/users/self/current-licenses`);
	}

	public getExpiredLicenses (): Observable<LicensesResponse>
	{
		return this.http.get<LicensesResponse>(`${clientSettings.GoReactV2API}/users/self/expired-licenses`);
	}

	public removeFromGroup (userId: number, groupId: number) {
		return this.http.delete<void>(`${clientSettings.GoReactV2API}/users/${userId}/groups/${groupId}`);
	}

	public getSelf (): Observable<any> {
		return this.http.get<any>(`${clientSettings.GoReactV2API}/users/self`);
	}

	public updateSelf (user: User) {
		return this.http.put<User>(`${clientSettings.GoReactV2API}/users/self`, user);
	}

	public getLanguage () {
		return this.http.get<any>(`${clientSettings.GoReactV2API}/users/self/language`);
	}

	public getNotificationSettings (userId: number) {
		return this.http.get<NotificationSettings>(`${clientSettings.GoReactV2API}/users/${userId}/notification-settings`);
	}

	public saveNotificationSettings (userId: number, notificationSettings: NotificationSettings) {
		return this.http.put<NotificationSettings>(`${clientSettings.GoReactV2API}/users/${userId}/notification-settings/${notificationSettings.id}`, notificationSettings);
	}

	public validateResetCode (email: string, code: string): Observable<EmailVerificationResponse> {
		return this.http.post<EmailVerificationResponse>(`${clientSettings.GoReactV2API}/users/validate-reset`, {
			email,
			code
		});
	}

	public getUserGroups (
		userId: number,
		onlyLeaf: boolean = true,
		explicitAccessOnly: boolean = false
	): Observable<any> {
		return this.http.get<CourseFolder>(`${clientSettings.GoReactV2API}/users/${userId}/groups?explicitAccessOnly=${explicitAccessOnly}&onlyLeaf=${onlyLeaf}`);
	}

	public requiresTermsUpdate (): Observable<any> {
		return this.http.get<any>(`${clientSettings.GoReactV2API}/users/accept-new-terms`);
	}

	public sendFeedback (rating: number, feedback: string) {
		return this.http.post<void>(`${clientSettings.GoReactV2API}/customer-feedback`, {
			rating,
			feedback
		});
	}
}
