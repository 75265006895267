export function goPdfLoader () {
	const pdfCache = {};

	async function load (url: string): Promise<any> {
		const cacheKey = url.split('?')[0];
		// Re-enable this line once app.goreact.com is pointing to S3, not V1
		// const pdfjsModule = await import(/* webpackChunkName: "pdfjs" */ 'pdfjs-dist/webpack');
		const pdfjsModule = await import(/* webpackChunkName: "pdfjs" */ './go-pdf-worker-loader');
		const pdfjs = pdfjsModule.default;

		return new Promise((resolve, reject) => {
			if (pdfCache[cacheKey]) {
				return resolve(pdfCache[cacheKey]);
			}

			const loadingTask = pdfjs.getDocument(url);
			loadingTask.promise.then((pdf) => {
				pdfCache[cacheKey] = pdf;
				return resolve(pdf);
			}, reject);
		});
	}

	return { load };
}
