import * as angular from 'angular';

export const UserActivityHistoryItemRendererController = function () {
	const vm = this;

	vm.$onInit = () => {
		// Whether or not additional data is expanded
		vm.expanded = false;

		// Supported item types
		vm.ItemType = {
			CREATE: 'create',
			DELETE: 'delete'
		};
	};


	/**
	 * Returns a template url for the given activity history event
	 *
	 * @returns {string}
	 */
	vm.getTemplateUrl = function () {
		return 'user-activity-history/item-renderer/templates/' +
			vm.item.data_type + '.html';
	};

	/**
	 * Returns the appropriate action word for this event
	 *
	 * @returns {string}
	 */
	vm.actionWord = function () {
		switch (vm.item.type) {
			case vm.ItemType.CREATE:
				return 'Created';
			case vm.ItemType.DELETE:
				return 'Deleted';
			default:
				throw new Error('User activity history event type: ' + vm.item.type + ' not supported');
		}
	};

	/**
	 * Hides / shows additional data about the event
	 *
	 * @param value
	 */
	vm.toggleExpand = function (value) {
		if (angular.isUndefined(value)) {
			value = !vm.expanded;
		}

		vm.expanded = !!value;
	};
};
