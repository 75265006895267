import {
	audioDescriptionBlacklist,
	captionOnlyWhiteList,
	dangerousFileBlackList,
	libraryDocumentBlacklist,
	pdfOnlyWhiteList,
	previewOnlyBlackList,
	previewOnlyWhiteList,
	videoOnlyWhiteList
} from './resources';

/* @ngInject */
export function cacheResources ($templateCache) {
	$templateCache.put('fine-uploader/src/partials/audio-description-blacklist.json', audioDescriptionBlacklist);
	$templateCache.put('fine-uploader/src/partials/caption-only-whitelist.json', captionOnlyWhiteList);
	$templateCache.put('fine-uploader/src/partials/dangerous-file-blacklist.json', dangerousFileBlackList);
	$templateCache.put('fine-uploader/src/partials/library-document-blacklist.json', libraryDocumentBlacklist);
	$templateCache.put('fine-uploader/src/partials/pdf-only-whitelist.json', pdfOnlyWhiteList);
	$templateCache.put('fine-uploader/src/partials/previewable-only-blacklist.json', previewOnlyBlackList);
	$templateCache.put('fine-uploader/src/partials/previewable-only-whitelist.json', previewOnlyWhiteList);
	$templateCache.put('fine-uploader/src/partials/video-only-whitelist.json', videoOnlyWhiteList);
}
