import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'twoLetters'
})
export class TwoLettersPipe implements PipeTransform {
	public transform (value: string): string {
		return value.substring(0,2);
	}
}
