import * as angular from 'angular';

import { common } from '../common';

import { TagModel } from './tag.factory';
import { featureFlagModule } from 'go-modules/feature-flag';

export const tagModel = angular.module('go.models.tag', [
	common,
	featureFlagModule
])
	.factory(TagModel.NG1_INJECTION_NAME, TagModel)
	.name;
