import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';
import { MessageModal } from './modal.factory';

export const messageModalModule = angular.module('modal.message', [
	goModalBootstrapModule,
	angularTranslate
])
	.service('messageModal', MessageModal)
	.name;
