import * as angular from 'angular';
import * as ngCookies from 'angular-cookies';

import { globalEvent } from '../global-event';
import { SessionManagerProvider } from './session-manager.provider';
import { sessionManagerConfig } from './session-manager.config';
import { SessionManagerService } from './session-manager.service';
import { SessionStorageService } from './session-storage.service';
import { UserActivityDetectionServiceDef } from './user-activity-detection.service';
import { authServiceModule } from 'go-modules/services/auth';

export const sessionManager = angular.module('go.session-manager', [
	authServiceModule,
	globalEvent,
	ngCookies
])
	.config(sessionManagerConfig)
	.provider('sessionManagerConfig', SessionManagerProvider)
	.service(SessionManagerService.NG1_INJECTION_NAME, SessionManagerService)
	.service(SessionStorageService.NG1_INJECTION_NAME, SessionStorageService)
	.service('UserActivityDetectionService', UserActivityDetectionServiceDef)
	.name;
