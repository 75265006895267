import template from './modal.html';

export enum CloseTypeEnum {
	DISMISS,
	DISCARD,
	RESTART,
	SAVE_AND_DRAFT,
	POST
}

export class PostDiscardModal {
	/* @ngInject */
	constructor (private goModalBootstrap) {}

	public open (options) {
		options.template = template;
		options.controller = () => {};
		options.windowClass = options.name = 'post-discard-modal';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		options.modalData.closeTypeEnum = CloseTypeEnum;

		return this.goModalBootstrap.open(options);
	}
}
