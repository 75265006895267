import template from './attachments.component.html';
import { SessionAttachmentsController } from './attachments.controller';

export default {
	bindings: {
		session: '<',
		group: '<',
		user: '<',
		readonlyMode: '<',
		analyticsCategory: '@',
		analyticsLabel: '@',
		activityName: '<',
		options: '<'
	},
	controller: SessionAttachmentsController,
	template
};
