import template from './time-remaining-countdown.component.html';
import { TimeRemainingCountdownController as controller } from './time-remaining-countdown.controller';
import './style.less';

const bindings = {
	timer: '<',
	timeLimit: '<'
};

export const TimeRemainingCountdownComponent = {
	bindings,
	controller,
	template
};
