import * as angular from 'angular';

import { uiDecimal } from '../ui-decimal';

import { labelInput } from './label-input.directive';
import { labelWrapper } from './label-wrapper/label-wrapper.component';
import { labelInputTextareaAutosize } from './editor/text-area-autosize.directive';
import { labelInputBehavior } from './editor/input-behavior.directive';
import { labelInputFormatterDirective } from './editor/input-formatter.directive';
import { labelInputEditor } from './editor/editor.directive';

import 'bootstrap-css-only';
import './style.less';

export const labelInputModule = angular.module('labelInput', [
	uiDecimal
])
	.directive('labelInputEditor', labelInputEditor)
	.directive('labelInputBehavior', labelInputBehavior)
	.directive('labelInputFormatter', labelInputFormatterDirective)
	.directive('labelInputTextareaAutosize', labelInputTextareaAutosize)
	.component('labelWrapper', labelWrapper)
	.directive('labelInput', labelInput)
	.name;
