import type { SessionCreationWizardOptions } from '../options';
import type { SessionCreationWizardStep } from './';

export class StartTestStep implements SessionCreationWizardStep {

	/* @ngInject */
	constructor (
		private startTestActivityModal: any,
		private Session: any
	) {}

	/**
	 * Run this step
	 */
	public run (options: SessionCreationWizardOptions): ng.IPromise<void> {
		return this.startTestActivityModal.open({
			modalData: {
				activity: options.activity
			}
		}).result
			.then(() => {
				// When the test is officially started, move the session into an awaiting start status
				// so that the user cannot exit after seeing the instructions and start a new test later.
				options.session.setStatus(this.Session.WAITING);
				options.session.setViewedRecordingInstructions();
				return options.session.save();
			});
	}

	/**
	 * Determine whether this step is required
	 */
	public isRequired (options: SessionCreationWizardOptions): boolean {
		return options.activity.has_single_recording_attempt;
	}
}
