import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { NgxGoToastComponent } from 'ngx/go-modules/src/components/go-toast/go-toast.component';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		NgxGoToastComponent
	],
	entryComponents: [
		NgxGoToastComponent
	],
	exports: [
		NgxGoToastComponent
	],
	providers: [
		NgxGoToastService
	]
})
export class GoToastModule {}
