import * as angular from 'angular';
import { goTourModule } from 'go-modules/go-tour';
import { common } from 'go-modules/models/common';
import { FeedbackGraphTour } from './feedback-graph-tour.service';

export const FeedbackGraphTourModule = angular.module('feedback-graph-tour', [
	goTourModule,
	common
])
	.service('feedbackGraphTour', FeedbackGraphTour)
	.name;
