import { noop } from 'angular';
import { UMC_ERROR_REASONS } from 'go-modules/universal-media-chooser/error';
import { SessionCreationIntent } from 'go-modules/session-creation-wizard/intent';
import { FeatureSupport } from 'go-modules/feature-support/feature-support';
import { UmcInstancesService } from 'go-modules/services/umc-instances/umc-instances.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

/* @ngInject */
export function UmcModalController (
	$scope,
	$uibModalInstance,
	UniversalMediaChooser,
	helpUrls,
	messageModal,
	zoomService,
	featureSupport: FeatureSupport,
	$document,
	umcInstancesService: UmcInstancesService,
	eventService: EventService
) {

	const vm = this;
	vm.TAB_CLOUD_RECORDINGS = 'cloud recordings';
	vm.TAB_COMPUTER_RECORDINGS = 'computer recordings';

	vm.$onInit = () => {
		if (!$scope.options || !$scope.options.headerOptions) {
			throw new Error('umcModalController::must supply options and options.headerOptions');
		}

		$scope.UMC_ERROR_REASONS = UMC_ERROR_REASONS;
		$scope.umc = UniversalMediaChooser.get('umcModal');
		$scope.$modalInstance = $uibModalInstance;
		$scope.helpUrls = helpUrls;
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.GO_MODAL_CLOSED))
			.subscribe(() => $scope.umc.selectedZoomTab = vm.TAB_COMPUTER_RECORDINGS);
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	$scope.submit = (form) => {
		if (form.$invalid) {
			const focusable = $document[0].querySelector('input.ng-invalid') as HTMLElement;
			focusable.focus();
			return;
		}

		if ($scope.showSelectMediaButton() || $scope.showZoomSelectMediaButton()) {
			$scope.chooseMedia();
		} else {
			$scope.recordMedia();
		}
	};

	$scope.chooseMedia = function () {
		eventService.broadcast(EVENT_NAMES.UMC_CHOSEN); // TODO: user of umc shouldnt have to know this
		$uibModalInstance.close($scope.umc.selectedMedia);
	};

	$scope.recordMedia = function () {
		if ($scope.umc.currentSection === $scope.umc.MEDIA_TYPE.RECORD_SESSION) {
			if (featureSupport.areBrowserRequirementsMetForRecording()) {
				$uibModalInstance.close(SessionCreationIntent.CONTINUE);
			} else {
				messageModal.open({
					modalData: {
						message: 'browser-unsupported-body',
						title: 'browser-unsupported-heading'
					}
				});
			}
		}
	};

	$scope.showSelectMediaButton = function () {
		return $scope.umc.currentSection === $scope.umc.MEDIA_TYPE.YOUTUBE;
	};

	$scope.showZoomSelectMediaButton = function () {
		return $scope.umc.currentSection === $scope.umc.MEDIA_TYPE.ZOOM
			&& $scope.umc.isZoomCloudRecordingsTabReady
			&& $scope.umc.selectedZoomTab === vm.TAB_CLOUD_RECORDINGS
			&& zoomService.isAuthenticated()
			&& !!$scope.umc.selectedMedia;
	};

	$scope.closeModal = () => {
		umcInstancesService.canChangeTab()
			.then(() => {
				$scope.$dismiss();
			}).catch(noop);
	};
}
