
export interface TransactionModel {
	trans_id: number;
	user_id: number;
	group_id: number;
	billing_entity_id: number;
	created_at: Date;
	subtotal: string;
	tax_amount: string;
	total?: string;
	method: TransactionMethod;
	payment_type: TransactionPaymentType;
	memo?: string;
	ref_id?: string;
	modified_at: Date;
	created_by?: number;
	related_trans_id?: number;
	deleted_by?: number;
	deleted_at?: Date;
	emailSending?: boolean;
}

export enum TransactionMethod {
	CARD = 'card',
	CHECK = 'check',
	CODE = 'code',
	CREDIT = 'credit'
}

export enum TransactionPaymentType {
	PURCHASE = 'purchase',
	REFUND = 'refund',
	REDEEM = 'redeem',
	ISSUE = 'issue'
}
