import { IAugmentedJQuery } from 'angular';
import { isNumber } from 'lodash';
import { AverageScore } from './average-score';
import { RubricFeedbackViewerController } from '../rubric-feedback-viewer.controller';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

interface Bindings {
	userGroup: any;
	rubricList: any[];
	rubricFeedbackViewerController: RubricFeedbackViewerController;
};

export class RubricGradeTotalsBarController implements Bindings {
	public rubricFeedbackViewerController: RubricFeedbackViewerController;
	public userGroup: any;
	public rubricList: any[];
	public instructorScore: AverageScore;
	public peerScore: AverageScore;
	public selfScore: AverageScore;
	public fractionSize: number = 2;
	public isVisible: boolean;
	private eventSubscription: any;

	/* @ngInject */
	constructor (
		private $element: IAugmentedJQuery,
		private Group: any,
		private eventService: EventService
	) {}

	/**
	 * Handles controller init life-cycle hook
	 */
	public $onInit (): void {
		this.isVisible = false;

		// When we have the rubric feedback viewer controller we
		// know that the rubric list change events will be available.
		if (this.rubricFeedbackViewerController instanceof RubricFeedbackViewerController) {
			if (this.rubricList) {
				this.calculateTotals(this.rubricList);
			}
			this.eventSubscription = this.eventService.events
				.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.RUBRIC_FEEDBACK_VIEWER_RUBRIC_LIST_CHANGE))
				.subscribe(() => {
					this.calculateTotals(this.rubricList);
				});
		} else {
			throw new Error('RubricGradeTotalsBarController requires parent controller `RubricFeedbackViewerController`');
		}
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	/**
	 * Handles changes life-cycle hook
	 */
	public $onChanges (changes: any): void {
		// Re-caluclate totals whenever the reference to the rubric list changes.
		if (changes.rubricList) {
			if (!Array.isArray(changes.rubricList.currentValue)) {
				throw new Error('RubricGradeTotalsBarController requires `rubricList` to be of type array');
			}
			this.calculateTotals(changes.rubricList.currentValue);
		}
	}

	/**
	 * Whether there are at least two scores given
	 */
	public hasAtLeastTwoScores (a: AverageScore, b: AverageScore, c: AverageScore): boolean {
		const result: AverageScore = a ? (b || c) : (b && c);
		return !!result;
	}

	/**
	 * Calculate all the average scores for instructor, peer, and self evaluations
	 */
	private calculateTotals (rubrics: any[]): void  {
		// All instructor/reviewer rubrics
		const instructorRubrics: any[] = rubrics.filter((rubric) => {
			return rubric.role !== this.Group.role.PRESENTER;
		});

		// All rubrics where peers evaluated the current user.
		const peerRubrics: any[] = rubrics.filter((rubric) => {
			const rubricUser = {user_id: rubric.created_by};
			return rubric.role === this.Group.role.PRESENTER
				&& !this.rubricFeedbackViewerController.session.isOwner(rubricUser);
		});

		// All rubrics where the current user evaluated themselves
		const selfRubrics: any[] = rubrics.filter((rubric) => {
			const rubricUser = {user_id: rubric.created_by};
			return rubric.role === this.Group.role.PRESENTER
				&& this.rubricFeedbackViewerController.session.isOwner(rubricUser);
		});

		this.instructorScore = this.calculateAverageScore(instructorRubrics);
		this.peerScore = this.calculateAverageScore(peerRubrics);
		this.selfScore = this.calculateAverageScore(selfRubrics);

		this.isVisible = this.hasAtLeastTwoScores(this.instructorScore, this.peerScore, this.selfScore);
		this.$element.toggleClass('visible', this.isVisible);
	}

	/**
	 * Calculate average score given a set of rubrics
	 */
	private calculateAverageScore (rubrics: any[]): AverageScore|null {
		let averageScore: AverageScore|null = null;
		const sourceRubrics: any[] = rubrics
			.filter((rubric) => {
				// If the rubric doesn't have total points, then don't include
				// it in the results since some rubrics have no items with point values.
				return isNumber(rubric.total);
			})
			.filter((rubric) => {
				// If the rubric doesn't have points, then don't include
				// it in the results since it has not been scored yet.
				return isNumber(rubric.points);
			});

		if (sourceRubrics.length > 0) {
			const sum: number = sourceRubrics
				.map((rubric: any) => rubric.points)
				.reduce((total, score) => total + score);

			averageScore = {
				value: parseFloat((sum / sourceRubrics.length).toFixed(this.fractionSize)),
				total: parseFloat(sourceRubrics[0].total.toFixed(this.fractionSize))
			};
		}

		return averageScore;
	}
}
