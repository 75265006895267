/* @ngInject */
export function activityFilter ($filter) {
	return (array, group, eliminateFutureOverride) => {
		return $filter('filter')(array, (activity) => {
			let show = true;

			// Users with a presenter role in the group should
			// not see activities that aren't available yet.
			if (group.hasPresenterRole() || eliminateFutureOverride) {
				show = activity.isAvailable(); // is the activity available?
			}

			return show && !activity.isDefault();
		});
	};
}
