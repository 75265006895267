import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import { NgxFeatureFlagService } from './feature-flag.service';
import { ngxLoggerServiceModule } from 'ngx/go-modules/src/services/logger';

export const ngxFeatureFlagServiceModule = angular.module('ngxFeatureFlagServiceModule', [
	ngxLoggerServiceModule
])
	.factory('ngxFeatureFlagService', downgradeInjectable(NgxFeatureFlagService) as any)
	.name;
