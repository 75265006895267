import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';

import { confirmModal } from './modal.factory';


import './modal.less';

export const confirmModalModule = angular.module('modal.confirm', [
	goModalBootstrapModule,
	angularTranslate
])
	.factory('confirmModal', confirmModal)
	.name;
