import * as angular from 'angular';

import { timerModule } from '../../timer';
import { goCache } from '../../go-cache';

import { MediaPlayerTimerFactory } from './media-player-timer.factory';
import { mediaPlayerCacheService } from './media-player.service';

export const mediaPlayerServicesModule = angular.module('mediaPlayer.services', [
	goCache,
	timerModule
])
	.factory('MediaPlayerTimer', MediaPlayerTimerFactory)
	.service('mediaPlayer', mediaPlayerCacheService)
	.name;
