import template from './media-player.component.html';
import { GoMediaPlayerController as controller } from './media-player.controller';

export default {
	bindings: {
		id: '@?',
		options: '<?',
		playlistItem: '<',
		onInit: '&',
		onInitError: '&',
		collapseAudio: '<',
		minimizedControls: '<',
		allowDownload: '<'
	},
	controller,
	template
};
