import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TermsService } from 'ngx/go-modules/src/services/terms/terms.service';

@Component({
	selector: 'terms-of-use-dialog',
	template: require('./terms-of-use-dialog.component.html'),
	styles: [require('./terms-of-use-dialog.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfUseDialogComponent implements OnInit {

	public form: FormGroup;

	constructor (
		private dialogRef: MatDialogRef<TermsOfUseDialogComponent>,
		private termsService: TermsService
	) {
		this.dialogRef.disableClose = true;
	}

	public ngOnInit () {
		this.form = new FormGroup({
			acceptTerms: new FormControl(false, [Validators.requiredTrue])
		});
	}

	public accept () {
		if (this.form.valid) {
			this.termsService.accept();
			this.dialogRef.close();
		}
	}
}
