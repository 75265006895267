import { RubricGradeTotalsBarController } from './rubric-grade-totals-bar.controller';
import template from './rubric-grade-totals-bar.component.html';

export const rubricGradeTotalsBarComponent = {
	bindings: {
		userGroup: '<',
		rubricList: '<'
	},
	controller: RubricGradeTotalsBarController,
	require: {
		rubricFeedbackViewerController: '?^rubricFeedbackViewer'
	},
	template
};
