import template from './toolbar.component.html';
import { ToolbarController as controller } from './toolbar.controller';

export default {
	bindings: {
		disabled: '@'
	},
	controller,
	template,
	transclude: true
};
