export const VERTICAL_ARROW_HEIGHT = 8;
export const VERTICAL_ARROW_WIDTH = 16;

export const HORIZONTAL_ARROW_WIDTH = 16;
export const HORIZONTAL_ARROW_HEIGHT = 8;

export const TRIGGER_ELEMENT_MIN_SIZE = 28;

export const TOOLTIP_POSITIONS = {
	'top|left': {
		originX: 'start',
		originY: 'top',
		overlayX: 'start',
		overlayY: 'bottom',
		offsetX: -VERTICAL_ARROW_WIDTH,
		offsetY: 1 - VERTICAL_ARROW_HEIGHT
	},
	'top|right': {
		originX: 'end',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'bottom',
		offsetX: VERTICAL_ARROW_WIDTH,
		offsetY: 1 - VERTICAL_ARROW_HEIGHT
	},
	'top|center': {
		originX: 'center',
		originY: 'top',
		overlayX: 'center',
		overlayY: 'bottom',
		offsetY: 1 - VERTICAL_ARROW_HEIGHT
	},
	'bottom|left': {
		originX: 'start',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'top',
		offsetX: -VERTICAL_ARROW_WIDTH,
		offsetY: VERTICAL_ARROW_HEIGHT
	},
	'bottom|right': {
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'top',
		offsetX: VERTICAL_ARROW_WIDTH,
		offsetY: VERTICAL_ARROW_HEIGHT
	},
	'bottom|center': {
		originX: 'center',
		originY: 'bottom',
		overlayX: 'center',
		overlayY: 'top',
		offsetY: VERTICAL_ARROW_HEIGHT
	},
	'left|top': {
		originX: 'start',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'top',
		offsetY: -HORIZONTAL_ARROW_WIDTH,
		offsetX: 1 - HORIZONTAL_ARROW_HEIGHT
	},
	'left|bottom': {
		originX: 'start',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'bottom',
		offsetY: HORIZONTAL_ARROW_WIDTH,
		offsetX: 1 - HORIZONTAL_ARROW_HEIGHT
	},
	'left|center': {
		originX: 'start',
		originY: 'center',
		overlayX: 'end',
		overlayY: 'center',
		offsetX: 1 - HORIZONTAL_ARROW_HEIGHT
	},
	'right|top': {
		originX: 'end',
		originY: 'top',
		overlayX: 'start',
		overlayY: 'top',
		offsetY: -HORIZONTAL_ARROW_WIDTH,
		offsetX: HORIZONTAL_ARROW_HEIGHT
	},
	'right|bottom': {
		originX: 'end',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'bottom',
		offsetY: HORIZONTAL_ARROW_WIDTH,
		offsetX: HORIZONTAL_ARROW_HEIGHT
	},
	'right|center': {
		originX: 'end',
		originY: 'center',
		overlayX: 'start',
		overlayY: 'center',
		offsetX: HORIZONTAL_ARROW_HEIGHT
	}
};
