import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	selector: '[goAnchor]'
})
export class GoAnchorDirective {
	constructor (
		public viewContainerRef: ViewContainerRef
	) {
	}
}
