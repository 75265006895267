/* @ngInject */
export function onImgLoad () {
	return {
		restrict: 'A',
		link (scope, elem, attr) {
			elem.bind('load', function () {
				scope.$apply(attr.onImgLoad);
			});
		}
	};
}
