import template from './bg-handle.component.html';
import { BgSplitterHandleController as controller } from './bg-handle.controller';

export const bgHandle: ng.IComponentOptions = {
	require: {
		bgSplitterController: '^bgSplitter'
	},
	template,
	controller
};
