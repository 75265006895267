import { IPromise } from 'angular';
import { VideoSceneController } from 'go-modules/video-scene/video-scene.controller';

export class DeferredGoRecorder {
	public $resolved: boolean;

	constructor (public $promise: IPromise<VideoSceneController>) {
		this.$resolved = false;
		this.$promise.then(() => {
			this.$resolved = true;
		});
	}
}
