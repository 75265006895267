/* @ngInject */
export function progressDirective (EventChannel) {
	return {
		restrict: 'A',
		require: '^fineUploader',
		scope: true,
		link (scope, element, attrs, uploadController) {
			const progressEventChannel = EventChannel.get(uploadController.getId()),
				fileId = scope.$eval(attrs.fineUploaderProgress);

			// initialize progress to zero
			element.text(0);

			// subscribe to file progress event
			progressEventChannel.subscribe(fileId, onProgress);

			/**
			 * Destroy event handler
			 */
			scope.$on('$destroy', function () {
				progressEventChannel.unsubscribe(fileId, onProgress);
			});

			/**
			 * On progress event handler
			 *
			 * @param uploadedBytes
			 * @param totalBytes
			 */
			function onProgress (uploadedBytes, totalBytes) {
				element.text(Math.round(uploadedBytes / totalBytes * 100));
			}
		}
	};
}
