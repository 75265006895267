
import { UniversalMediaChooserController } from '../umc.controller';
import { MODES, CONTENT_TYPES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

export interface Bindings {
	umcController: UniversalMediaChooserController;
	options: any;
	umcInstanceName: string;
}

export class UniversalMediaChooserLibraryController implements Bindings {

	// Bindings
	public umcController: UniversalMediaChooserController;
	public options: any;
	public umcInstanceName: string;

	public filterType: any = [CONTENT_TYPES.MEDIA];
	public mode: string = MODES.SELECT;
	public previewable: boolean;
	public collectionManagement: boolean = true;
	private umc: any;

	/* @ngInject */
	constructor (
		private MediaModel,
		private UniversalMediaChooser: any,
		private $q: ng.IQService,
		private eventService: EventService
	) {
	}

	public $onInit () {
		if (!this.options || !this.umcInstanceName) {
			throw new Error('Must provide all required bindings');
		}

		this.umc = this.UniversalMediaChooser.get(this.umcInstanceName);

		this.filterType = this.options.libraryOptions.filterType;
		this.previewable = this.options.libraryOptions.previewable;
		this.collectionManagement = this.options.libraryOptions.collectionManagement;
	}

	public selectItem (item) {
		const media = this.MediaModel.model(item);
		this.umcController.handleMediaDetermined(media);
		this.umc.selectedMedia = this.$q.when(media);
		this.eventService.broadcast(EVENT_NAMES.UMC_CHOOSE, media);
	}
}
