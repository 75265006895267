import * as angular from 'angular';
import 'angulartics';

import { sessionModel } from '../models/session';
import { attachmentModel } from '../models/attachment';
import componentDefinition from './attachments.component';
import { attachmentServiceModule } from '../services/attachment';

import './style.less';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// import /*{ goAttachment } from*/ '../attachment'; -- commented to satisfy eslint
import '../attachment';

export const sessionAttachments = angular.module('go.sessionAttachments', [
	'angulartics',
	sessionModel,
	attachmentModel,
	attachmentServiceModule,
	// TODO: use module strings directly until circular dependency is fixed
	'go.attachment'
])
	.component('sessionAttachments', componentDefinition)
	.name;
