import * as angular from 'angular';
import * as angulartics from 'angulartics';
import * as ngCookies from 'angular-cookies';
import * as angularTranslate from 'angular-translate';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as angularMaterial from 'angular-material';
import * as angularAria from 'angular-aria';
import * as angularSanitize from 'angular-sanitize';
import uiRouter from '@uirouter/angularjs';

import { sessionManager } from '../session-manager';
import { sessionEditorModule } from '../session-editor';
import { goModal } from '../modals';
import { confirmModalModule } from '../modals/confirm';
import { userModel } from '../models/user';
import { groupDepModel } from '../models/group-dep';
import { sessionModel } from '../models/session';
import { commentModel } from '../models/comment';
import { attachmentModel } from '../models/attachment';
import { rubricSessionModel } from '../models/rubric-session';
import { syncEventModel } from '../models/sync-event';
import { mediaModel } from '../models/media';
import { fineUploaderModule } from '../fine-uploader';
import { richDropdownModule } from '../rich-dropdown';
import { goNoUISliderModule } from '../go-nouislider';
import { goPopoverModule } from '../go-popover';
import { sessionAttachments } from '../session-attachments';
import { activityInfoModule } from '../activity-info';
import { mediaPreviewModule } from '../media-preview';
import { presenterListModule } from 'go-modules/presenter-list';
import { event } from '../event-channel';
import { mediaPlayerModule } from '../media-player';
import { playerSyncModule } from '../player-sync';
import { detectModule } from '../detect';
import { goPubnubModule } from 'go-modules/go-pubnub';
import { goStorageModule } from '../go-storage';
import { time } from '../time';
import { timerModule } from '../timer';
import { commonFiltersModule } from '../common-filters';
import { rubricModule } from '../rubric';
import { helpUrlsModule } from '../help-urls';
import { labelInputModule } from '../label-input';
import { feedbackTreeModule } from './feedback-tree';
import { slideToggleModule } from '../slide-toggle';
import { activityTemplateModel } from 'go-modules/models/activity-template';

import { sessionEditorModalModule } from '../modals/session-editor';
import { activityInstructionsModalModule } from '../modals/activity-instructions';
import { rubricFeedbackViewerModule } from '../rubric-feedback-viewer/index';
import { messageModalModule } from 'go-modules/modals/message';
import { ngxUnsavedDataManagerServiceModule } from 'ngx/go-modules/src/services/unsaved-data-manager';
import { postDiscardSessionModalModule } from 'go-modules/modals/post-discard-session';
import { featureFlagModule } from 'go-modules/feature-flag';
import { sanitizerServiceModule } from 'go-modules/services/xss';
import { $debounceModule } from 'go-modules/services/$debounce';

import {FeedbackSessionController} from './feedback-session.controller';
import feedbackSessionComponentDefinition from './feedback-session.component';
import { FeedbackSessionProvider } from './feedback-session.provider';
import {bgSplitter} from './bg-splitter/bg-splitter.component';
import {bgHandle} from './bg-splitter/bg-handle/bg-handle.component';
import {commentCaptions} from './comment-captions/comment-captions.component';
import {templates} from './templates';
import {commentSyncEventViewer} from './comment-sync-event-viewer/comment-sync-event-viewer.component';
import {FeedbackSessionContentController} from './content/content.controller';
import {feedbackSessionContent} from './content/content.component';
import {feedbackDisplay} from './feedback-display/feedback-display.directive';
import { FeedbackDisplayPanelComponent } from './feedback-display-panel/feedback-display-panel.component';
import {feedbackSessionStatus} from './filters/status.filter';
import {feedbackSessionHeader} from './header/header.component';
import {mediaDisplay} from './media-display/media-display.component';
import {multiMediaDisplay} from './multi-media-display/multi-media-display.component';
import {notificationRibbonDirective} from './notification-ribbon/notification-ribbon.directive';
import {DocumentMediaPlayer} from './services/document-media-player.factory';
import {featureCache} from './services/feature-cache.service';
import {feedbackSettings} from './services/feedback-settings.service';
import {SessionFeedback} from './services/session-feedback.factory';
import {TimingOrchestratorService} from './services/timing-orchestrator.service';
import {GoRecorderManagerService} from './services/go-recorder-manager.service';
import {sessionMediaDisplay} from './session-media-display/session-media-display.component';
import {recordViewComponent} from './session-media-display/record-view/view.component';
import {playbackViewComponent} from './session-media-display/playback-view/view.component';
import {liveViewComponent} from './session-media-display/live-view/view.component';
import {singleMediaDisplay} from './single-media-display/single-media-display.component';
import {slidePresentationViewer} from './slide-presentation-viewer/slide-presentation-viewer.component';
import {slideDeckViewer} from './slide-presentation-viewer/slide-deck-viewer/slide-deck-viewer.component';
import {slideDeckItemRenderer} from './slide-presentation-viewer/slide-deck-viewer/item-renderer/item-renderer.component';
import {slideDeckInstructions} from './slide-presentation-viewer/slide-deck-viewer/slide-deck-instructions/slide-deck-instructions.component';
import {SlideDeckViewerSyncEventToastController} from './slide-presentation-viewer/slide-deck-viewer/sync-event-toast/toast.controller';
import {stimulusResponseViewer} from './stimulus-response-viewer/stimulus-response-viewer.component';
import {stimulusDocumentViewer} from './stimulus-response-viewer/stimulus-document-viewer/stimulus-document-viewer.component';
import {stimulusMediaDisplay} from './stimulus-response-viewer/stimulus-media-display/stimulus-media-display.component';
import {stimulusVideoViewer} from './stimulus-response-viewer/stimulus-video-viewer/stimulus-video-viewer.component';
import { responsiveViewModule } from 'go-modules/responsive-view';
import { ngxFeedbackCreatorModule } from 'ngx/go-modules/src/components/feedback-session/feedback-creator';
import { ngxTranscriptViewerModule } from 'ngx/go-modules/src/components/feedback-session/transcript-viewer';
import { ngxSessionAnalyticsModule } from 'ngx/go-modules/src/components/feedback-session/session-analytics';
import { SelectedModule } from 'go-modules/services/selected';
import { ngxSpeedGraderModule } from 'ngx/go-modules/src/components/feedback-session/speed-grader';

import 'angular-material/modules/layouts/angular-material.layouts.css';
import 'angular-material/modules/js/core/core.css';
import 'angular-material/modules/js/button/button.css';
import 'angular-material/modules/js/switch/switch.css';
import 'angular-material/modules/js/toast/toast.css';
import 'nouislider/distribute/nouislider.css';
import 'bootstrap-css-only';

import './style.less';
import { goTablistKeyboardNavigationModule } from 'go-modules/go-tablist-keyboard-navigation';
import { ngxCommentReactionsModule } from 'ngx/go-modules/src/components/feedback-session/comment-reactions';
import { ngxDowngradeModalServiceModule } from 'ngx/go-modules/src/services/downgrade-modal';
import { ngxMarkerModule } from 'ngx/go-modules/src/components/marker';
import { ngxGoModalServiceModule } from 'ngx/go-modules/src/services/go-modal';

// Circular dependency
// tslint:disable-next-line:import-spacing
import /*{ universalMediaChooserModule } from*/ '../universal-media-chooser';
import { FeedbackGraphTourModule } from './feedback-display-panel/feedback-graph-tour';
import { videoShareModalModule } from 'go-modules/modals/video-share';
import { ngxCommentFilterDropdownModule } from 'ngx/go-modules/src/components/feedback-session/feedback-filters/comment-filter';
import { ngxCommentFilterServiceModule } from 'ngx/go-modules/src/services/comment-filter';
import { ngxMarkerFilterDropdownModule } from 'ngx/go-modules/src/components/feedback-session/feedback-filters/marker-filter';
import { goTourModule } from 'go-modules/go-tour';
import { transcriptionServiceModule } from 'ngx/go-modules/src/services/transcription';

export const feedbackSessionModule = angular.module('go.feedbackSession', [
	sessionManager,
	ngCookies,
	sessionEditorModule,
	goModal,
	confirmModalModule,
	userModel,
	groupDepModel,
	sessionModel,
	commentModel,
	attachmentModel,
	rubricSessionModel,
	syncEventModel,
	mediaModel,
	fineUploaderModule,
	richDropdownModule,
	goNoUISliderModule,
	goPopoverModule,
	sessionAttachments,
	activityInfoModule,
	mediaPreviewModule,
	presenterListModule,
	event,
	mediaPlayerModule,
	playerSyncModule,
	detectModule,
	goPubnubModule,
	goStorageModule,
	time,
	featureFlagModule,
	timerModule,
	commonFiltersModule,
	rubricModule,
	helpUrlsModule,
	labelInputModule,
	feedbackTreeModule,
	angularAria,
	angularSanitize,
	uiTooltip,
	angulartics,
	angularTranslate,
	angularMaterial,
	sessionEditorModalModule,
	activityInstructionsModalModule,
	rubricFeedbackViewerModule,
	slideToggleModule,
	messageModalModule,
	ngxUnsavedDataManagerServiceModule,
	postDiscardSessionModalModule,
	activityTemplateModel,
	sanitizerServiceModule,
	$debounceModule,
	goTablistKeyboardNavigationModule,
	uiRouter,
	responsiveViewModule,
	ngxFeedbackCreatorModule,
	ngxTranscriptViewerModule,
	ngxSessionAnalyticsModule,
	SelectedModule,
	ngxCommentReactionsModule,
	ngxSpeedGraderModule,
	ngxDowngradeModalServiceModule,
	ngxMarkerModule,
	FeedbackGraphTourModule,
	videoShareModalModule,
	ngxCommentFilterDropdownModule,
	ngxMarkerFilterDropdownModule,
	ngxCommentFilterServiceModule,
	ngxGoModalServiceModule,
	goTourModule,
	transcriptionServiceModule,

	'universal-media-chooser'
])
	.run(templates)
	.controller('FeedbackSessionController', FeedbackSessionController)
	.component('feedbackSession', feedbackSessionComponentDefinition)
	.provider('feedbackSession', FeedbackSessionProvider)
	.component('bgSplitter', bgSplitter)
	.component('bgHandle', bgHandle)
	.component('commentCaptions', commentCaptions)
	.component('commentSyncEventViewer', commentSyncEventViewer)
	.controller('FeedbackSessionContentController', FeedbackSessionContentController)
	.component('feedbackSessionContent', feedbackSessionContent)
	.directive('feedbackDisplay', feedbackDisplay)
	.filter('feedbackSessionStatus', feedbackSessionStatus)
	.component('feedbackSessionHeader', feedbackSessionHeader)
	.component('mediaDisplay', mediaDisplay)
	.component('multiMediaDisplay', multiMediaDisplay)
	.directive('notificationRibbon', notificationRibbonDirective)
	.factory('DocumentMediaPlayer', DocumentMediaPlayer)
	.service('featureCache', featureCache)
	.service('feedbackSettings', feedbackSettings)
	.factory('SessionFeedback', SessionFeedback)
	.service('TimingOrchestrator', TimingOrchestratorService)
	.service('goRecorderManagerService', GoRecorderManagerService)
	.component('sessionMediaDisplay', sessionMediaDisplay)
	.component('feedbackDisplayPanel',FeedbackDisplayPanelComponent)
	.component('feedbackSessionRecordView', recordViewComponent)
	.component('feedbackSessionPlaybackView', playbackViewComponent)
	.component('feedbackSessionLiveView', liveViewComponent)
	.component('singleMediaDisplay', singleMediaDisplay)
	.component('slidePresentationViewer', slidePresentationViewer)
	.component('slideDeckViewer', slideDeckViewer)
	.component('slideDeckItemRenderer', slideDeckItemRenderer)
	.component('slideDeckInstructions', slideDeckInstructions)
	.controller('SlideDeckViewerSyncEventToastController', SlideDeckViewerSyncEventToastController)
	.component('stimulusResponseViewer', stimulusResponseViewer)
	.component('stimulusDocumentViewer', stimulusDocumentViewer)
	.component('stimulusMediaDisplay', stimulusMediaDisplay)
	.component('stimulusVideoViewer', stimulusVideoViewer)
	.name;
