import { Transform } from 'readable-stream';

export class AudioBufferTransformStream extends Transform {
	// AssemblyAI stream demands a minimum buffer of 100ms and a maximum buffer of 450ms at 16kHz
	private static readonly SAMPLE_RATE_HZ = 16000;
	private static readonly MIN_BUFFER_MS = 0.100;
	private static readonly MAX_BUFFER_MS = 450;

	private buffer: Int16Array;

	constructor (options?) {
		super(options);
		this.buffer = new Int16Array(0);
	}

	public _transform (chunk: Uint8Array, _encoding, callback) {

		// Concatenate the new chunk to the existing buffer, casting it back to int16
		const newBuffer = new Int16Array(this.buffer.length + chunk.length / 2);
		newBuffer.set(this.buffer);
		newBuffer.set(new Int16Array(chunk.buffer), this.buffer.length);
		this.buffer = newBuffer;

		let bufferDuration = this.buffer.length / AudioBufferTransformStream.SAMPLE_RATE_HZ;

		while (bufferDuration >= AudioBufferTransformStream.MIN_BUFFER_MS) {
			// Determine the chunk duration to pass on
			const chunkDuration = Math.min(bufferDuration, AudioBufferTransformStream.MAX_BUFFER_MS);
			const chunkSize = chunkDuration * AudioBufferTransformStream.SAMPLE_RATE_HZ;

			// Slice the buffer and push the chunk.
			this.push(new Uint8Array(this.buffer.subarray(0, chunkSize).buffer));

			// Update the buffer to remove the processed chunk
			this.buffer = this.buffer.slice(chunkSize);
			bufferDuration = this.buffer.length / AudioBufferTransformStream.SAMPLE_RATE_HZ;
		}

		callback();
	}

	public _flush (callback) {
		// Push any remaining data in the buffer if it meets the minimum size
		if (this.buffer.length > 0) {
			this.push(new Uint8Array(this.buffer));
		}
		callback();
	}
}
