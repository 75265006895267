import { ActivityInfoController as controller } from './activity-info.controller';
import template from './activity-info.component.html';

export const activityInfo = {
	template,
	controller,
	require: {
		// Include this controller for analytics purposes only
		feedbackSessionController: '?^feedbackSession'
	},
	bindings: {
		activity: '<',
		group: '<',
		hideName: '<',
		hideSourceMedia: '<',
		hideRecordingInstructions: '<',
		hideFeedbackInstructions: '<',
		isOnDashboard: '<',
		session: '<'
	}
};
