import template from './modal.html';
import { MessageRememberingModalController } from './modal.controller';
import type { Scope } from './modal.controller';
import type { GoModalBootstrap, GoModalSettings } from '../go-modal-bootstrap.factory';
import type { Bindings } from './modal.controller';

export interface MessageRememberingModalSettings extends GoModalSettings {
	rememberKey: string;
	modalData: Bindings;
	scope?: Scope;
};

/* @ngInject */
export class MessageRememberingModal {

	/* @ngInject */
	constructor (
		private $rootScope: ng.IRootScopeService,
		private $q: ng.IQService,
		private $window: ng.IWindowService,
		private goModalBootstrap: GoModalBootstrap
	) {}

	public open (options: MessageRememberingModalSettings): ng.ui.bootstrap.IModalInstanceService {
		const memory = this.$window.localStorage.getItem(options.rememberKey);
		if (memory != null) {
			// If memory serves us right, then make a fake IModalInstanceService with the last result
			return {
				// Resolve with the answer from last time
				result: memory === 'true' ? this.$q.resolve() : this.$q.reject(),

				// Resolve what would have been the result if the modal opened/rendered/closed
				// Note we don't guarantee order, but could be later added.
				opened: this.$q.when(true),
				rendered: this.$q.when(),
				closed: this.$q.when(),

				// Emulate IModalInstanceService calling close on a closed modal
				close: () => true,
				dismiss: () => true
			} as Partial<ng.ui.bootstrap.IModalInstanceService> as ng.ui.bootstrap.IModalInstanceService;
		}

		options.scope = Object.assign(this.$rootScope.$new(), {
			options: {
				remember: false
			}
		});
		options.template = template;
		options.controller = MessageRememberingModalController;
		options.windowClass = options.name = 'message-remembering-modal';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		const goModal = this.goModalBootstrap.open(options);

		goModal.result.then(() => {
			// Modal closed sucessfully
			if (options.scope.options.remember) {
				this.$window.localStorage.setItem(options.rememberKey, 'true');
			}
		}, () => {
			// fail function (modal dismissed promise rejected)
			if (options.scope.options.remember) {
				this.$window.localStorage.setItem(options.rememberKey, 'false');
			}
		});

		return goModal;
	}
}
