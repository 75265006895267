import * as angular from 'angular';

export declare abstract class FeedbackBaseModelClass {
	public time: number;
	public saving: boolean;
	public saved: boolean;
	public isSaved (): boolean;
	public isSaving (): boolean;
	public setSaving (value: boolean): boolean;
	public copy (): FeedbackBaseModelClass;
	public abstract setOwner (user): void;
	public abstract isOwner (user): boolean;
	public abstract save (): ng.IPromise<FeedbackBaseModelClass>;
	public abstract remove (): FeedbackBaseModelClass;
	public abstract setTime (value): void;
}

export function baseModel () {

	function FeedbackBaseModel () {}

	// properties
	FeedbackBaseModel.prototype.saving = false;
	FeedbackBaseModel.prototype.saved = false;

	FeedbackBaseModel.prototype.isSaved = function () {
		return this.saved;
	};

	FeedbackBaseModel.prototype.isSaving = function () {
		return this.saving;
	};

	FeedbackBaseModel.prototype.setSaving = function (value) {
		this.saving = value;
	};

	/**
	 * Copy this item
	 *
	 * @returns {Object}
	 */
	FeedbackBaseModel.prototype.copy = function () {
		return angular.copy(this);
	};

	FeedbackBaseModel.prototype.setOwner = function (_user) {};
	FeedbackBaseModel.prototype.isOwner = function (_user) {
		return false;
	};
	FeedbackBaseModel.prototype.save = function () {};
	FeedbackBaseModel.prototype.remove = function () {};

	return FeedbackBaseModel;
}
