import { MediaCardController as controller } from './media-card.controller';
import template from './media-card.component.html';

/* @ngInject */
export const mediaCardComponent = {
	template,
	controller,
	bindings: {
		media: '<',
		ariaLabelTitleKey: '<',
		label: '<'
	}
} as ng.IComponentOptions;
