import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';
import { RubricEditorModalController } from './modal.controller';
import template from './modal.html';

/* @ngInject */
export function rubricEditorModal (goModalBootstrap) {

	function open (options) {
		options.template = template;
		options.controller = RubricEditorModalController;
		options.controllerAs = '$ctrl';
		options.size = 'full';
		options.windowClass = options.name = 'rubric-editor-modal';
		options.keyboard = false;
		options.animation = false;
		options.backdrop = 'static';
		return goModalBootstrap.open(options);
	}

	return {
		open
	};
}

rubricEditorModal.NG1_INJECTION_NAME = 'rubricEditorModal' as const;
export const rubricEditorModalToken = upgradeNg1Dependency(rubricEditorModal);
