/* @ngInject */
export function addLabel () {
	return function (val, isEditable, label) {
		if (!val || !val.trim()) {
			label = label || 'Add label';
			return isEditable ? label : '';
		}
		return val;
	};
}
