import { isFunction, isUndefined } from 'lodash';
import { EVENTS } from '../events';
import { STATES } from '../states';
import { GoMediaPlayer } from '../';

/* @ngInject */
export function MediaPlayerTimerFactory ($log, $q, Timer, GoCache) {
	let instanceCount = 0;

	// Timer events mapped to media player events
	MediaPlayerTimer.EVENT_MAP = {};
	MediaPlayerTimer.EVENT_MAP[Timer.EVENT.START] = [EVENTS.PLAY];
	MediaPlayerTimer.EVENT_MAP[Timer.EVENT.PAUSE] = [EVENTS.PAUSE, EVENTS.BUFFER];
	MediaPlayerTimer.EVENT_MAP[Timer.EVENT.TIME] = [EVENTS.TIME];
	MediaPlayerTimer.EVENT_MAP[Timer.EVENT.TIME_UPDATE] = [EVENTS.SEEK];
	MediaPlayerTimer.EVENT_MAP[Timer.EVENT.COMPLETE] = [EVENTS.COMPLETE];

	/**
	 * Constructor
	 *
	 * @param player
	 * @returns {*}
	 * @constructor
	 */
	function MediaPlayerTimer (playerOrPromise: ng.IPromise<GoMediaPlayer>|GoMediaPlayer) {
		let player: GoMediaPlayer = null;
		const timer = Timer.get(`media-player-timer-${++instanceCount}`),
			promise = Promise.resolve(playerOrPromise),
			deferred = $q.defer(),
			eventMap = MediaPlayerTimer.EVENT_MAP,
			callbackCache = new GoCache();

		timer.$promise = deferred.promise;

		timer.toggleCaptions = function (enabled) {
			promise.then(function () {
				player.toggleCaptions(enabled);
			});
		};

		timer.hasCaptions = function () {
			return player?.hasCaptions() === true;
		};

		timer.captionsEnabled = function () {
			return player?.captionsEnabled() === true;
		};

		timer.start = function () {
			$log.warn('MEDIA PLAYER TIMER', 'start request');
			promise.then(function () {
				player.play();
			});
		};

		timer.pause = function () {
			$log.warn('MEDIA PLAYER TIMER', 'pause request');
			promise.then(function () {
				player.pause();
			});
		};

		timer.resume = function () {
			timer.start();
		};

		timer.stop = function () {
			$log.warn('MEDIA PLAYER TIMER', 'stop request');
			timer.pause();
		};

		timer.isPaused = function () {
			let result = true;
			if (player) {
				result = player.isPaused() || player.isBuffering();
			}
			return result;
		};

		timer.setTime = function (time) {
			$log.warn('MEDIA PLAYER TIMER', 'set time request');
			promise.then(function () {
				player.once(EVENTS.SEEK, () => {
					player.play();
				});
				player.seek(time);
			});
		};

		timer.getTime = function () {
			let result = 0;
			if (player) {
				result = player.getTime();
			}
			return result;
		};

		timer.getDuration = function () {
			let result = 0;
			if (player) {
				result = player.getDuration();
			}
			return result;
		};

		timer.isComplete = function () {
			let result = false;
			if (player) {
				result = player.isComplete();
			}
			return result;
		};

		const timerDestroy = timer.destroy.bind(timer);
		timer.destroy = function () {
			timer.off();
			timerDestroy();
		};

		// Override timer on function and
		// map to equivalent player events.
		timer.on = function (event, callback) {
			if (!Array.isArray(eventMap[event]) || !isFunction(callback)) {
				return;
			}
			promise.then(function () {
				const playerCallback = function (playerEvent) {
					playerEvent = playerEvent || {};
					const wasBuffering = playerEvent.oldstate === STATES.BUFFERING;
					callback(player.getTime(), wasBuffering);
				};

				eventMap[event].forEach(function (eventName) {
					player.on(eventName, playerCallback);
				});

				callbackCache.put(callback, {
					event: eventMap[event],
					callback: playerCallback
				});
			});
		};

		// Override timer once function and
		// map to equivalent player events.
		timer.once = function (event, callback) {
			if (!Array.isArray(eventMap[event]) || !isFunction(callback)) {
				return;
			}
			promise.then(function () {
				eventMap[event].forEach(function (eventName) {
					player.once(eventName, function (playerEvent) {
						playerEvent = playerEvent || {};
						const wasBuffering = playerEvent.oldstate === STATES.BUFFERING;
						callback(player.getTime(), wasBuffering);
					});
				});
			});
		};

		// Override timer off function and
		// map to equivalent player events.
		timer.off = function (event, callback) {
			promise.then(function () {
				if (isUndefined(event) && isUndefined(callback)) {
					// Un-subscribe from all callbacks in the cache
					callbackCache.all().forEach(function (item) {
						item.event.forEach(function (eventName) {
							player.off(eventName, item.callback);
						});
					});
					// Clear the callback cache
					callbackCache.clear();
				} else if (Array.isArray(eventMap[event]) && isFunction(callback)) {
					const item = callbackCache.get(callback);
					if (item) {
						eventMap[event].forEach(function (eventName) {
							player.off(eventName, item.callback);
						});
						callbackCache.remove(callback);
					}
				}
			});
		};

		// Before we can resolve the timer promise,
		// we need to first ensure that the player is ready.
		promise.then((_player: GoMediaPlayer) => {
			player = _player;
			deferred.resolve(timer);
		});

		return timer;
	}

	return MediaPlayerTimer;
}
