import * as angular from 'angular';
import template from './notification-ribbon.directive.html';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';

/* @ngInject */
export const notificationRibbonDirective = ($timeout, $document, eventService: EventService) => {
	return {
		restrict: 'E',
		template,
		scope: true,
		link (scope, element) {
			const queue = [];

			scope.id = null;
			scope.dismiss = hide;

			scope.eventSubscription = eventService.events
				.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.FS_NOTIFICATION_ADD))
				.subscribe((ev: GoEvent) => {
					const id = ev.data;
					if (id) {// add to queue
						add(id);
					} else if (queue.length) {// hide
						hide();
					}
				});

			function add (id) {
				if (queue.indexOf(id) >= 0) {
					return;
				}
				queue.push(id);
				if (queue.length === 1) {
					notify(queue[0]);
				}
			}

			function remove (index?) {
				scope.id = null;
				if (queue.length) {
					if (index >= 0) {
						queue.splice(index, 1);
					} else {
						queue.shift();
					}
					if (queue.length) {
						notify(queue[0]);
					}
				}
			}

			function notify (id) {
				if ($document[0].readyState === 'complete') {
					if (!id) {
						return remove();
					}
					scope.id = id;

					$timeout(() => {
						show();
					}, 300);

				} else {
					angular.element(window).on('load', function windowHandler () {
						angular.element(window).off('load', windowHandler);
						notify(id);
					});
				}
			}

			function show () {
				element.addClass('active');
			}

			function hide () {
				element.removeClass('active');
				remove();
			}

			scope.$on('$destroy', function () {
				scope.eventSubscription?.unsubscribe();
			});
		}
	};
};
