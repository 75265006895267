import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

import { SelfPayService } from './self-pay.service';
export { SelfPayService as NgxSelfPayService } from './self-pay.service';

export const ngxSelfPayServiceModule = angular.module('ngxSelfPayServiceModule', [])
	.factory('ngxSelfPayService', downgradeInjectable(SelfPayService) as any)
	.name;

