import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';
import { LibraryCollectionPermission } from 'ngx/go-modules/src/interfaces/library/library-collection';

export interface ShareLibraryItemResponse {
	invalidUsers: string[];
}

export interface CollectionPermission {
	id: number;
	email: string;
}

export interface LibraryCollectionFolder {
	id: number;
	name: string;
	library_collection_id: number;
	created_at: string;
	updated_at: string;
	created_by: number;
}

@Injectable({
	providedIn: 'root'
})
export class LibraryService {
	constructor (
		private http: HttpClient
	) {}

	public getRubricItemUsage (rubricId: number, orgId: number): Observable<any> {
		return this.http.get<any>(`${clientSettings.GoReactV2API}/collection-items/rubric/${rubricId}/usage?org_id=${orgId}`);
	}

	public copyCollectionItem (payload: any): Observable<any>  {
		return this.http.post<any>(
			`${clientSettings.GoReactV2API}/collection-items/batch-copy`,
			payload
		);
	}

	public moveCollectionItem (payload: any): Observable<any>  {
		return this.http.put<any>(
			`${clientSettings.GoReactV2API}/collection-items/move`,
			payload
		);
	}

	public removeCollectionItem (collectionId, items): Observable<any> {
		const payload = {
			collectionId,
			itemIds: []
		} as any;

		items.forEach( (item) => payload.itemIds.push(item.id));

		const headers = new HttpHeaders();

		return this.http.delete<any>(`${clientSettings.GoReactV2API}/collection-items/delete`, {headers, body: payload});
	}

	public checkRubricUsage (rubricId: number): Observable<any> {
		return this.http.get<any>(`${clientSettings.GoReactV2API}/rubric_templates/${rubricId}/usage`);
	}

	public shareCollectionItem (payload: any): Observable<ShareLibraryItemResponse> {
		return this.http.post<ShareLibraryItemResponse>(
			`${clientSettings.GoReactV2API}/collection-items/batch-share`,
			payload
		);
	}

	public removeFolder (folderId: number): Observable<any> {
		return this.http.delete(`${clientSettings.GoReactV2API}/collection-folders/${folderId}`);
	}

	public updateCollection (collectionID: number, payload: any): Observable<any> {
		return this.http.put(
			`${clientSettings.GoReactV2API}/library-collections/${collectionID}`,
			payload
		);
	}

	public removeCollectionPermission (permissions: LibraryCollectionPermission[]): Observable<any> {
		const payload = {
			permissionIds: []
		} as any;

		permissions.forEach( (permission) => {
			payload.permissionIds.push(permission.id);
		});

		const headers = new HttpHeaders();

		return this.http.delete(`${clientSettings.GoReactV2API}/collection-permissions/batch-remove`, {
			headers,
			body: payload
		});
	}

	public inviteToCollection (collectionID: number, payload: any): Observable<any> {
		return this.http.post(
			`${clientSettings.GoReactV2API}/library-collections/${collectionID}/invite`,
			payload
		);
	}

	public createCollection (payload: any): Observable<any> {
		return this.http.post(
			`${clientSettings.GoReactV2API}/library-collections`,
			payload
		);
	}

	public updateCollectionPermission (payload: any): Observable<any> {
		return this.http.put(
			`${clientSettings.GoReactV2API}/collection-permissions/batch-update`,
			payload
		);
	}

	public removeCollection (collection): Observable<any> {
		return this.http.delete(`${clientSettings.GoReactV2API}/library-collections/${collection.id}`);
	}

	public getCollections (): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/library-collections`);
	}

	public getCollectionItemTypes (): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/library-collection-item-types`);
	}

	public getCollectionItems (collection, type): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/collection-items/${collection}/${type}`);
	}

	public getCollectionFolders (collectionID: number): Observable<any> {
		return this.http.get(`${clientSettings.GoReactV2API}/collection-folders/${collectionID}`);
	}

	public createFolder (collectionId: number, folderName: string): Observable<any> {
		return this.http.post(`${clientSettings.GoReactV2API}/collection-folders/${collectionId}`, {
			name: folderName
		});
	}

	public editFolder (folderId: number, folderName: string): Observable<any> {
		return this.http.put(`${clientSettings.GoReactV2API}/collection-folders/${folderId}`, {
			name: folderName
		});
	}
}
