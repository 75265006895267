import * as noUiSlider from 'nouislider';

export interface NoUiSliderOptionsWithEvents extends Omit<noUiSlider.Options, 'start'> {
	disabled?: boolean | boolean[];
	ariaLabel?: string | string[];
	onStart?: noUiSlider.Callback;
	onSlide?: noUiSlider.Callback;
	onUpdate?: noUiSlider.Callback;
	onChange?: noUiSlider.Callback;
	onSet?: noUiSlider.Callback;
	onEnd?: noUiSlider.Callback;
}

interface GoNoUISliderScope extends ng.IScope {
	goNouislider: NoUiSliderOptionsWithEvents;
}

export function goNoUISliderDirective () {
	return {
		restrict: 'A',
		require: 'ngModel',
		link (scope: GoNoUISliderScope,
			element: ng.IAugmentedJQuery,
			attrs: ng.IAttributes,
			ngModelController: ng.INgModelController
		) {
			let slider: noUiSlider.noUiSlider = null;
			let settingViewValue = false;

			const updateDisabled = (newVal: null | boolean | boolean[]) => {
				if (newVal == null) {
					return;
				}
				if (Array.isArray(newVal)) {
					const handleParents = element[0].getElementsByClassName('noUi-origin');
					newVal.forEach((val, index) => handleParents[index].toggleAttribute('disabled', val));
				} else {
					element[0].toggleAttribute('disabled', newVal);
				}
			};

			// Watch go-nouislider (byVal) for changes and update
			scope.$watch(attrs.goNouislider, (options: NoUiSliderOptionsWithEvents) => {
				if (options != null) {
					init(options);
				}
			}, true);

			function init (options: NoUiSliderOptionsWithEvents) {
				slider?.destroy();

				// Instantiate slider
				slider = noUiSlider.create(element[0], {
					...options,
					// Initial start value should equal model value
					start: ngModelController.$modelValue
				});

				if (options.ariaLabel != null) {
					const ariaLabels = Array.isArray(options.ariaLabel) ? options.ariaLabel : [options.ariaLabel];
					slider.target.querySelectorAll('.noUi-handle').forEach((handle, index) => {
						handle.setAttribute('aria-label', ariaLabels[index]);
					});
				}

				updateDisabled(options.disabled);

				const updateModel: noUiSlider.Callback = (_values: any[], _handle: number, unencoded: number[]) => {
					if (!settingViewValue) {
						settingViewValue = true;
						ngModelController.$setViewValue(
							Array.isArray(ngModelController.$modelValue) ? unencoded : unencoded[0]
						);
						settingViewValue = false;
					}
				};
				slider.on('set', updateModel);

				['onStart','onSlide','onUpdate','onChange','onSet','onEnd'].forEach((eventType) => {
					if (options[eventType] != null) {
						slider.on(eventType.slice(2).toLowerCase(), function (...args) {
							scope.$applyAsync(() => {
								options[eventType].apply(this, args);
							});
						});
					}
				});
			}

			// We want this to be ngModelController.$render, but $render doesn't do deep equality
			// Instead we do a watch, and ensure it isn't from $setViewValue above
			scope.$watch(attrs.ngModel, function (value: number | number[]) {
				if (!settingViewValue) {
					settingViewValue = true;
					slider?.set(value);
					settingViewValue = false;
				}
			}, true);

			element.on('$destroy', function () {
				slider?.destroy();
			});
		}
	};
}
