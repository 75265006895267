/* @ngInject */
export function rubricWidget ($templateCache, $compile) {
	return {
		restrict: 'AE',
		scope: {
			type: '=type',
			element: '=element'
		},
		require: '^rubric',
		controller: () => {},
		link (scope, el, _attr, _rubricController) {
			const template = $templateCache.get('rubric/widget/' + scope.type + '/widget.html');
			el.html(template);
			$compile(el.contents())(scope);
		}
	};
}
