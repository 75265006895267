import { Subject } from 'rxjs';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

export class UserService {
	public static readonly NG1_INJECTION_NAME = 'userService' as const;

	public userSubject = new Subject();
	private user: any = null;

	/**
	 * @param user
	 *
	 * @returns {void}
	 */
	public setCurrentUser (user: any): void {
		this.user = user;
		this.userSubject.next(null);
	}

	/**
	 *
	 * @readonly
	 *
	 * @type {*}
	 */
	public get currentUser (): any {
		return this.user;
	}

	/**
	 * clears user
	 *
	 * @returns {void}
	 */
	public clear (): void {
		this.user = null;
	}
}

export const userServiceToken = upgradeNg1Dependency(UserService);
