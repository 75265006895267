import template from './feedback-display.directive.html';
import { FeedbackDisplayController } from './feedback-display.controller';

export interface FeedbackDisplayComponentScope extends ng.IScope {
	feedbackDisplayController: FeedbackDisplayController;
}

export const feedbackDisplay = () => {
	return {
		restrict: 'E',
		controller: FeedbackDisplayController,
		controllerAs: 'feedbackDisplayController',
		template,
		scope: true
	};
};
