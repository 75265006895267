/* @ngInject */
export function StimulusVideoViewerController (feedbackSession, mediaPlayer, PlayerSync) {
	const vm = this;

	/**
	 * Controller init handler
	 */
	vm.$onInit = function () {
		// Fetch media previewer id
		vm.mediaPreviewId = feedbackSession.sourcePlayerId(vm.mainController.session);

		// Options for the media preview
		vm.mediaPreviewOptions = {
			playbackRateDisabled: true,
			hideControls: feedbackSession.isPlaybackMode(),
			disableControlsForSingleRecording: vm.mainController.activity.has_single_recording_attempt,
			isVideoSeekingDisabled: vm.mainController.activity.is_video_seeking_disabled,
			isClosedCaptionsDisabled: vm.mainController.activity.is_source_media_cc_disabled,
			autoPlay: false
		};

		// Add stimulus track
		vm.addTrack();

		// Determine whether capture mode should be turned on
		if (feedbackSession.isRecordMode()) {
			vm.track.setMode(PlayerSync.MODE.SYNCHRONOUS_CAPTURE);
		}
	};

	/**
	 * Controller destroy handler
	 */
	vm.$onDestroy = function () {
		vm.removeTrack();
	};

	/**
	 * Add track
	 */
	vm.addTrack = function () {
		const session = vm.mainController.session,
			trackNumber = vm.multiMediaDisplayController.getTrackNumber(vm.mediaPreviewId);

		if (vm.track) {
			vm.removeTrack();
		}

		// Add stimulus track to player sync
		vm.track = vm.mainController.playerSync.addTrack(
			trackNumber,
			mediaPlayer.get(vm.mediaPreviewId),
			session.getSyncEventsByTrack(trackNumber)
		);

		// Add track event listeners
		vm.track.on(PlayerSync.EVENT.SYNC_EVENT_ADDED, syncEventSavedEventHandler);
		vm.track.on(PlayerSync.EVENT.SYNC_EVENT_UPDATED, syncEventSavedEventHandler);
	};

	/**
	 * Remove track
	 */
	vm.removeTrack = function () {
		// Remove track event listeners
		vm.track.off(PlayerSync.EVENT.SYNC_EVENT_ADDED, syncEventSavedEventHandler);
		vm.track.off(PlayerSync.EVENT.SYNC_EVENT_UPDATED, syncEventSavedEventHandler);

		// Remove stimulus track
		vm.mainController.playerSync.removeTrack(vm.track);
		vm.track = null;
	};

	/**
	 * Sync event saved event handler
	 *
	 * @param syncEvent
	 */
	function syncEventSavedEventHandler (syncEvent) {
		syncEvent.setSessionId(vm.mainController.session.getId());
		syncEvent.save();
	}
}
