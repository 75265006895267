import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import ngMessages from 'angular-messages';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import { VideoShareModal } from './modal.service';
import { videoShareEmailErrorModalModule } from '../video-share-email-error';
import { emailList } from 'go-modules/email-list';
import { formValidationModule } from 'go-modules/form-validation';
import { goListModule } from 'go-modules/go-list';
import { ngxGoToastModule } from 'ngx/go-modules/src/components/go-toast';
import { messageModalModule } from 'go-modules/modals/message';
import { sessionModel } from 'go-modules/models/session';
import './style.less';

export const videoShareModalModule = angular.module('modal.video-share', [
	goModalBootstrapModule,
	angularTranslate,
	ngMessages,
	emailList,
	formValidationModule,
	goListModule,
	messageModalModule,
	sessionModel,
	videoShareEmailErrorModalModule,
	ngxGoToastModule
])
	.service(VideoShareModal.NG1_INJECTION_NAME, VideoShareModal)
	.name;
