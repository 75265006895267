import template from './media-thumbnail.component.html';
import { MediaThumbnailController as controller } from './media-thumbnail.controller';

export const MediaThumbnailComponentDef = {
	template,
	controller,
	bindings: {
		media: '<',
		options: '<',
		title: '@?',
		onPreview: '&',
		onPreviewDone: '&',
		onDownload: '&',
		onEdit: '&',
		onEditDone: '&',
		onDelete: '&'
	}
};
