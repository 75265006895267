import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as uiTooltip from 'angular-ui-bootstrap/src/tooltip';
import * as angularMessages from 'angular-messages';

import { groupDepModel } from '../models/group-dep';
import { userGroupModel } from '../models/user-group';
import { goModal } from '../modals';
import { formValidationModule } from '../form-validation';
import { goDateTimePickerModule } from 'go-modules/datetimepicker';

import { courseEditorComponent } from './course-editor.component';

import './style.less';
import { groupServiceModule } from '../services/group';
import { richDropdownModule } from 'go-modules/rich-dropdown';
import { userModel } from 'go-modules/models/user';
import { ngxGroupServiceModule } from 'ngx/go-modules/src/services/group';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { lazyPaymentPanelModule } from 'go-modules/payment-panel/lazy-index';

import { templates } from './templates';

export const courseEditorModule = angular.module('course-editor', [
	groupDepModel,
	userGroupModel,
	uiTooltip,
	goModal,
	formValidationModule,
	angularMessages,
	angularTranslate,
	goDateTimePickerModule,
	groupServiceModule,
	richDropdownModule,
	userModel,
	goLocalizeHelperModule,
	ngxGroupServiceModule,
	lazyPaymentPanelModule,
	ngxGroupServiceModule
])
	.run(templates)
	.component('courseEditor', courseEditorComponent)
	.name;
