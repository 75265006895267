import * as angular from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { filter } from 'rxjs/operators';

/* @ngInject */
export function MediaPreviewModalController (
	$scope,
	MediaModel,
	$filter,
	$analytics,
	helpUrls,
	messageModal,
	eventService: EventService
) {
	const vm = this;
	/**
	 * Handles init life cycle hook
	 */
	vm.$onInit = () => {
		vm.audioDescriptionMedia = null;
		vm.mediaVTT = null;
		vm.helpUrls = helpUrls;

		if (!($scope.media instanceof MediaModel)) {
			throw new Error('Parameter `media` is required by MediaPreviewModalController');
		}

		// Expose media to controller scope
		vm.media = $scope.media;

		// For download callback support
		vm.onDownload = angular.isFunction($scope.onDownload)
			? $scope.onDownload : angular.noop;

		// Media preview player options
		$scope.mediaPreviewOptions = {
			autoPlay: !$scope.edit,
			hideControls: $scope.media.isDocument()
		};

		$scope.canZoom = $scope.media.canZoom();
		$scope.MediaModel = MediaModel;

		if ($scope.edit) {
			$scope.copiedMedia = angular.copy($scope.media);
			$scope.form = {};
		}

		if ($scope.allowDownload && !$scope.media.download_link) {
			MediaModel.getDownloadData({media_id: $scope.media.media_id}, (link) => {
				vm.media.download_link = link.link;
			});
		}

		// Media sync event handler
		vm.eventSubscription = eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				// Sync media on the media modal itself if needed
				if ($scope.media && parseInt($scope.media.media_id, 10) === parseInt(ev.data.media_id, 10)) {
					return Object.assign($scope.media, ev.data);
				}
			});

		vm.broadcast = (name) => {
			eventService.broadcast(name);
		};

		$scope.mediaUrlChange = (url) => {
			if (!MediaModel.isValidYoutubeUrl(url)) {
				$scope.copiedMedia.error = true;
			} else {
				$scope.copiedMedia.error = false;
				$scope.previewItem.file = $scope.copiedMedia.media_url;
				$scope.previewItem.image = MediaModel.makeYoutubeThumbnail($scope.copiedMedia.media_url);
			}
		};

		$scope.isPreviewable = () => {
			return $scope.edit ?
				$scope.copiedMedia.isPreviewable() :
				$scope.media.isPreviewable();
		};
	};

	vm.$onDestroy = () => {
		vm.eventSubscription?.unsubscribe();
	};

	/**
	 * Whether media attachments feature is enabled
	 *
	 * @returns {boolean}
	 */
	vm.isMediaAttachmentsFeatureEnabled = () => {
		return ($scope.media.isVideo() && !$scope.media.isYoutube()) ||
			$scope.media.isAudio();
	};

	/**
	 * Whether group context is available
	 *
	 * @returns {boolean}
	 */
	vm.hasGroupContext = () => {
		return !!$scope.groupId;
	};

	vm.getMediaPreviewTitle = () => {
		const translationKey = $scope.media.isDocument() ? 'library-collections_details-document' : 'modal-media-preview_action-media';
		return translationKey;
	};

	vm.getMediaDownloadText = () => {
		const translationKey = $scope.media.isDocument() ? 'modal-media-preview_download-document' : 'modal-media-preview_download-media';
		return translationKey;
	};

	/**
	 * Download media event handler
	 */
	vm.onDownloadMedia = () => {
		vm.onDownload(vm.media);

		MediaModel.download($scope.media.media_id);

		// Track download analytics event
		$analytics.eventTrack('download from preview', {
			category: 'media'
		});
	};

	/**
	 * Close the modal
	 *
	 * @returns {*}
	 */
	vm.close = () => {
		if (!$scope.edit) {
			return $scope.$close();
		}

		// Did they change a youtube video to a bad one?
		if (
			MediaModel.isValidYoutubeUrl($scope.media.media_url) &&
			!MediaModel.isValidYoutubeUrl($scope.copiedMedia.media_url)
		) {
			return messageModal.open({
				modalData: {
					title: 'modal-media-preview_controller-bad-url',
					message: 'modal-media-preview_controller-bad-url-message'
				}
			});
		}

		$scope.copiedMedia.$save()
			.then((m) => {
				const status = $scope.media.media_status === MediaModel.READY ?
					$scope.media.media_status :
					m.media_status;
				angular.extend($scope.media, m);
				$scope.media.media_status = status;
				$scope.$close($scope.media);
			}).catch((result) => {
				messageModal.open({
					modalData: {
						type: 'danger',
						title: 'modal-media-preview_controller-oops',
						message: $filter('translate')('modal-media-preview_controller-something-wrong', '{ message: "' + result.message + '"}')
					}
				}).result.finally($scope.$close.bind($scope));
			});
	};
}
