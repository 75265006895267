import template from './slide-presentation-viewer.component.html';
import { SlidePresentationViewerController as controller } from './slide-presentation-viewer.controller';

export const slidePresentationViewer = {
	template,
	require: {
		mainController: '^feedbackSession',
		contentController: '^feedbackSessionContent',
		multiMediaDisplayController: '^multiMediaDisplay'
	},
	controller,
	bindings: {
		presentationMedia: '<?',
		slideMedia: '<'
	}
};
