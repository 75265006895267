import { IPromise } from 'angular';
import { TimerBase,TimerEvent } from 'go-modules/timer';
import { DeferredGoRecorder } from './go-recorder-deferred';
import { VideoSceneController } from 'go-modules/video-scene/video-scene.controller';
import { Events, States } from 'go-modules/video-scene/state-emitter/state-emitter';
import { Timer } from 'go-modules/timer/timer';

export class GoRecorderTimer extends TimerBase {
	public $promise: IPromise<VideoSceneController>;
	private recorder: VideoSceneController;

	constructor (deferredRecorder: DeferredGoRecorder) {
		super();
		this.$promise = deferredRecorder.$promise;
		this.$promise.then((recorder: VideoSceneController) => {
			this.setupEvents(recorder);
			this.recorder = recorder;
		});
	}

	public start (): void {}
	public pause (): void {}
	public resume (): void {}
	public stop (): void {}
	public setTime (_value: number): void {}

	public isPaused (): boolean {
		if (!this.recorder) {
			return true;
		}
		return [
			States.STARTING,
			States.RECORDING,
			States.PAUSING
		].indexOf(this.recorder.state) === -1;
	}

	public isComplete (): boolean {
		return this.recorder && this.recorder.state === States.PAUSED;
	}

	public getTime (): number {
		return this.getDuration();
	}

	public getDuration (): number {
		if (!this.recorder) {
			return 0;
		}
		return this.recorder.getDuration();
	}

	public destroy () {
		super.destroy();

		if (this.recorder) {
			this.tearDownEvents(this.recorder);
		}
	}

	private setupEvents (recorder: VideoSceneController): void {
		recorder.on(Events.STATE_CHANGE, this.handleStateChange);
		recorder.timer.on(Timer.EVENT.TIME, this.onTime);
	}

	private onTime = (): void => {
		this.emit(TimerEvent.TIME, this.getTime());
	};

	private handleStateChange = (state: States): void => {
		switch (state) {
			case States.RECORDING:
				this.emit(TimerEvent.START, this.getTime());
			case States.PAUSED:
				this.emit(TimerEvent.PAUSE, this.getTime());
				break;
			case States.STOPPED:
				this.emit(TimerEvent.COMPLETE, this.getTime());
		}
	};

	private tearDownEvents (recorder: VideoSceneController): void {
		recorder.timer.removeListener(Timer.EVENT.TIME, this.onTime);
		recorder.removeListener(Events.STATE_CHANGE, this.handleStateChange);
	}
}
