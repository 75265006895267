/* @ngInject */
export function commentModelSetOwnerService () {
	return function setOwner (comment, user) {
		if (user.hasOwnProperty('user_id')) {
			comment.created_by = user.user_id;
		}

		if (user.hasOwnProperty('first_name')) {
			comment.first_name = user.first_name;
		}

		if (user.hasOwnProperty('last_name')) {
			comment.last_name = user.last_name;
		}

		if (user.hasOwnProperty('email')) {
			comment.email = user.email;
		}

		const array = [];
		if (user.first_name) {
			array.push(user.first_name);
		}
		if (user.last_name) {
			array.push(user.last_name);
		}

		comment.fullname = array.join(' ');
	};
}
