import * as angular from 'angular';
import { goTypaheadSelectComponent, transcludeThisScopeDirective } from './go-typeahead-select.component';
import { formValidationModule } from 'go-modules/form-validation';
import * as angularTranslate from 'angular-translate';

import * as uiDropdown from 'angular-ui-bootstrap/src/dropdown';

import './style.less';

export const goTypeaheadSelectModule = angular.module('go.typeaheadSelect', [
	uiDropdown,
	formValidationModule,
	angularTranslate
])
	.component('goTypeaheadSelect', goTypaheadSelectComponent)
	.directive('transcludeThisScope', transcludeThisScopeDirective)
	.name;
