import { MODES, CONTENT_TYPES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';
import { LibraryCollectionViewerModal } from 'go-modules/modals/library-collection-viewer/modal.service';
import { noop } from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { VideoQualityUtil } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';

export interface Bindings {
	attachments: any[];
	onAdded: (attachment: any) => void;
	onDownload: (attachment: any) => void;
	onDelete: (attachment: any) => void;
	onDeleted: (attachment: any) => void;
	onEdit: (attachment: any) => void;
	onEdited: (attachment: any) => void;
	onPreview: (attachment: any) => void;
	onUmcOpen: () => void;
	onUmcClose: (attachment: any) => void;
	options: any;
}

export class AttachmentsController implements Bindings {

	// Bindings
	public attachments: any[];
	public onAdded: (attachment: any) => void;
	public onDownload: (attachment: any) => void;
	public onDelete: (attachment: any) => void;
	public onDeleted: (attachment: any) => void;
	public onEdit: (attachment: any) => void;
	public onEdited: (attachment: any) => void;
	public onPreview: (attachment: any) => void;
	public onUmcOpen: () => void;
	public onUmcClose: (attachment: any) => void;
	public options: any;
	private eventSubscription: any;
	private umc: any;

	/* @ngInject */
	constructor (
		private $scope: ng.IScope,
		private $q: ng.IQService,
		private AttachmentModel: any,
		private UniversalMediaChooser: any,
		private goModal: any,
		private ActivityModel: any,
		private libraryCollectionViewerModal: LibraryCollectionViewerModal,
		private MediaModel,
		private eventService: EventService,
		private selectedService: SelectedService
	) {}

	/**
	 * Handles init life cycle hook
	 */
	public $onInit () {
		// listen for attachment sync events
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => ev.name === EVENT_NAMES.MEDIA_SYNC))
			.subscribe((ev: GoEvent) => {
				// use for loop to modify reference in place
				this.attachments.forEach((currentAttachment: any) => {
					if (currentAttachment.media &&
						parseInt(currentAttachment.media.media_id, 10) === parseInt(ev.data.media_id, 10)) {
						Object.assign(currentAttachment.media, ev.data); // find match by same attachment id
					}
				});
			});

		const defaultOptions = {};
		const options = {
			...defaultOptions,
			...this.options
		};
		this.options = options;

		if (!Array.isArray(this.attachments)) {
			throw new Error('Binding `attachments` must be of type Array');
		} else if (this.options.showAdd) {
			if (!this.options.groupId) {
				throw new Error('Option `groupId` is required when `showAdd` option is enabled');
			}
		}

		this.umc = this.UniversalMediaChooser.get('umcModal');
	}

	public $onDestroy (): void {
		this.eventSubscription?.unsubscribe();
	}

	/**
	 * Add an attachment
	 */
	public addAttachment (attachment: any): void {
		this.attachments.push(attachment);
	}

	/**
	 * Remove an attachment
	 */
	public removeAttachment (attachment: any): void {
		const index = this.attachments.indexOf(attachment);
		if (index !== -1) {
			this.attachments.splice(index, 1);
		}
	}

	/**
	 * Handles attachment preview event
	 */
	public onAttachmentPreview (attachment: any): void {
		this.onPreview({
			attachment,
			attachments: this.attachments
		});
	}

	/**
	 * Handles attachment edit event
	 */
	public onAttachmentEdit (attachment: any): void {
		this.onEdit({
			attachment,
			attachments: this.attachments
		});
	}

	/**
	 * Handles attachment edited event
	 */
	public onAttachmentEdited (attachment: any): void {
		this.onEdited({
			attachment,
			attachments: this.attachments
		});
	}

	/**
	 * Handles attachment download event
	 */
	public onAttachmentDownload (attachment: any): void {
		this.onDownload({
			attachment,
			attachments: this.attachments
		});
	}

	/**
	 * Handles attachment delete event
	 */
	public onAttachmentDelete (attachment: any): void {
		this.onDelete({
			attachment,
			attachments: this.attachments
		});
	}

	/**
	 * Handles attachment deleted event
	 */
	public onAttachmentDeleted (attachment: any): void {
		this.removeAttachment(attachment);
		this.onDeleted({
			attachment,
			attachments: this.attachments
		});
	}

	/**
	 * Opens the UMC modal for attaching resources to the activity
	 */
	public attachMedia (): void {
		if (this.options.resourceType === 'activity') {
			this.libraryCollectionViewerModal.open({
				modalData:{
					filterType: [CONTENT_TYPES.MEDIA, CONTENT_TYPES.DOCUMENTS],
					mode: MODES.SELECT,
					previewable: false,
					collectionManagement: true
				}
			}).result
				.then(this.onMediaChosen.bind(this))
				.catch(noop);
		} else {
			this.onUmcOpen();

			this.goModal.open({
				modal: 'umc',
				modalData: {
					options: this.getUmcOptions(this.options)
				},
				animation: false
			}).result
				.then(this.onMediaChosen.bind(this))
				.catch(noop)
				.finally(this.onUmcClose.bind(this));
		}
	}

	/**
	 * Whether attachments are loading
	 */
	public isLoading (): boolean {
		return !this.options.hideLoading && this.attachments.$resolved === false;
	}

	/**
	 * Handles media chosen event
	 */
	 public onMediaChosen (media: any): void {
		if (!(media instanceof this.MediaModel)) {
			media = this.MediaModel.model(media);
		}
		const attachment = new this.AttachmentModel({
			resource_type: this.options.resourceType,
			resource_id: this.options.resourceId,
			media,
			media_id: media.getId()
		});

		let promise: ng.IPromise<any>;
		if (this.options.disableAutoSave) {
			promise = this.$q.resolve();
		} else {
			promise = attachment.$save();
		}

		promise.then(() => {
			attachment.media = media;
			this.addAttachment(attachment);
			this.onAdded({
				attachment,
				attachments: this.attachments
			});
		});
	}

	/**
	 * Get UMC options
	 */
	private getUmcOptions (options: any): any {
		const headerOptions = [
			this.umc.MEDIA_TYPE.RECORD_VIDEO,
			this.umc.MEDIA_TYPE.UPLOAD,
			this.umc.MEDIA_TYPE.YOUTUBE,
			this.umc.MEDIA_TYPE.RECORD_AUDIO
		];

		// Build media title
		let mediaTitle: string;
		if (options.resourceType === this.ActivityModel.RESOURCE_TYPE) {
			mediaTitle = `${this.options.groupName}-${this.options.activityName}-resource`;
		} else {
			mediaTitle = `${this.options.userFirstName}_${this.options.userLastName}-${this.options.activityName}-presenter-attachment`;
		}

		const umcOptions = {
			headerOptions,
			defaultSection: this.umc.MEDIA_TYPE.UPLOAD,
			groupId: options.groupId,
			mediaTitle,
			resourceType: this.AttachmentModel.RESOURCE_TYPE,
			resourceId: options.resourceId,
			uploadMessageFn: options.uploadMessageFn,
			goRecorder: {
				videoQuality: this.selectedService.getLicense()?.salesforce_license.video_quality ??
				VideoQualityUtil.MINIMUM_RESOLUTION
			},
			libraryOptions: {
				filterType: [CONTENT_TYPES.MEDIA, CONTENT_TYPES.DOCUMENTS],
				//allow any document to be attachment
				previewable: false,
				collectionManagement: true
			},
			chooseAutomatically: true,
			blacklist: 'fine-uploader/src/partials/library-document-blacklist.json',
			uploadMinSizeLimit: 1
		};

		return umcOptions;
	}
}
