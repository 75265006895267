import * as angular from 'angular';
import * as uiPopover from 'angular-ui-bootstrap/src/popover';

import { goPopoverComponent } from './go-popover.component';

import 'bootstrap-css-only';

import { detectModule } from 'go-modules/detect';
import './style.less';

export const goPopoverModule = angular.module('go.popover', [
	uiPopover,
	detectModule
])
	.component('goPopover', goPopoverComponent)
	.name;
