/**
 * When loading unposted synchronous recordings for playback,
 * we need to know some data about who stopped the recording
 * so we can show post/discard to the right users.
 *
 * NOT_STOPPED or STOPPED_BY_ME will show post/discard.
 * STOPPED_BY_SOMEONE_ELSE will not show post/discard.
 */
export enum RecordingStoppedState {
	NOT_STOPPED,
	STOPPED_BY_ME,
	STOPPED_BY_SOMEONE_ELSE
};
