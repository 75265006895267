import * as angular from 'angular';

import { AttachmentService } from './attachment.service';
import { attachmentModel } from '../../models/attachment';

export const attachmentServiceModule = angular.module('attachmentService', [
	attachmentModel
])
	.service('AttachmentService', AttachmentService)
	.name;
