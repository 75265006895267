export const logError = function (...params) {
	if(!params[0]) return;
	params.push((new Error()).stack);
	// eslint-disable-next-line no-console
	console.error(...params);
};

export default {
	logError
};
