import { AnnouncementType } from '../models/announcement/announcement.type';
import { AnnouncementSchema } from 'go-modules/models/announcement/announcement.schema';
import * as dayjs from 'dayjs';

export class GoBannerHelper {
	private supportedTypes: string[];

	constructor () {
		this.supportedTypes = Object.keys(AnnouncementType).map((key) => AnnouncementType[key]);
	}

	public isSupportedType (type: string): boolean {
		return this.supportedTypes.indexOf(type) > -1;
	}

	public setViewedStatus (banner: AnnouncementSchema) {
		const viewCountStr = localStorage.getItem(`banner-${banner.name}`);
		const viewCount = viewCountStr ? parseInt(viewCountStr, 10) + 1 : 1;
		localStorage.setItem(`banner-${banner.name}`, viewCount.toString());
	}

	public hasViewedStatus (banner: AnnouncementSchema): boolean {
		const viewCountStr = localStorage.getItem(`banner-${banner.name}`);
		const viewCount = viewCountStr ? parseInt(viewCountStr, 10) : 0;
		return viewCount >= banner.maxViews && banner.isDismissable;
	}

	/**
	 * Decides whether or not the banner is ready to displayed.
	 * @param banner contains metadata on how to display the banner.
	 * @returns boolean
	 */
	public shouldDisplayBanner (banner: AnnouncementSchema): boolean {

		// If its already been viewed, don't show
		if (this.hasViewedStatus(banner)) {
			return false;
		}

		// If it's too early
		if (this.hasStartDate(banner) && dayjs().isBefore(dayjs(banner.startDisplayAt))) {
			return false;
		}

		// If it's too late
		if (this.hasEndDate(banner) && dayjs().isAfter(dayjs(banner.stopDisplayAt))) {
			return false;
		}

		return true;
	}

	private hasStartDate (banner: AnnouncementSchema): boolean {
		return banner.hasOwnProperty('startDisplayAt') && !!banner.startDisplayAt;
	}

	private hasEndDate (banner: AnnouncementSchema): boolean {
		return banner.hasOwnProperty('stopDisplayAt') && !!banner.stopDisplayAt;
	}
}
