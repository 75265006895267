import { InviteModel } from './invite.schema';
import { DataTransformer } from '../common/data-transformer';
import { IHttpService } from 'angular';
import { UserRoleLocalizationUtil } from 'ngx/go-modules/src/utilities/user-role-localization/user-role-localization.util';

const responseTransformRemoveFields = [
	'invite_id',
	'group_id',
	'user_id',
	'last_sent'
];

export class InviteTransformer extends DataTransformer {

	public saveInviteTransformers: ng.IHttpRequestTransformer[];

	/* @ngInject */
	constructor ($http: IHttpService) {
		super($http);
	}

	public static response (data: any): InviteModel {
		data.inviteId = DataTransformer.transformToInt(data, 'invite_id');
		data.groupId = DataTransformer.transformToInt(data, 'group_id');
		data.userId = DataTransformer.transformToInt(data, 'user_id');
		data.lastSent = DataTransformer.transformToDate(data, 'last_sent') || new Date(); // Always set
		data.active = DataTransformer.transformToBoolean(data, 'active');
		data.role_localized = UserRoleLocalizationUtil.localize(data.role);
		responseTransformRemoveFields.forEach((field) => delete data[field]);

		return data;
	}

	public static request (data: InviteModel) {
		return {
			invite_id: data.inviteId,
			group_id: data.groupId,
			user_id: data.userId,
			uuid: data.uuid,
			active: data.active,
			email: InviteTransformer.cleanEmail(data.email),
			role: data.role,
			last_sent: DataTransformer.transformFromDate(data, 'lastSent'),
			message: data.message
		};
	}

	/**
	 * Format emails, including removing any formatting
	 * that could have been brought over from an email client, i.e.
	 * Dallin Scherbel <scherpa.bball@gmail.com>, dscherbel@hotmail.com
	 */
	public static cleanEmail (email: string) {
		return email.replace(/<\s*\w.*?>/, (reformEmail) => {
			reformEmail = reformEmail.replace('<', '');
			reformEmail = reformEmail.replace('>', '');
			return reformEmail;
		}).replace(/^\s\s*/, '').replace(/\s\s*$/, '');
	}

	protected transformRequestData (invite: InviteModel): any {
		return InviteTransformer.request(invite);
	}

	protected transformResponseData (data: any): InviteModel {
		return InviteTransformer.response(data);
	}
}
