import { Injectable } from '@angular/core';
import * as Fullstory from '@fullstory/browser';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';

@Injectable({
	providedIn: 'root'
})
export class NgxFullstoryService {
	public createEvent (eventName: FULLSTORY_EVENTS, payload: object) {
		Fullstory.event(eventName, payload);
	}

	/**
	 * Polls and resolves when Fullstory is initialized
	 */
	public async isReady (): Promise<void> {
		return new Promise((resolve) => {
			// Immediately resolve if initialized
			if (Fullstory.isInitialized()) {
				resolve();
				return;
			}

			const intervalId = setInterval(() => {
				if (Fullstory.isInitialized()) {
					clearInterval(intervalId);
					resolve();
				}
			}, 100);
		});
	}
}
