import type { AriaSortableOptions } from 'go-modules/aria-decorator/aria-ng-sortable.decorator';
import * as Sortable from 'sortablejs';
import elements from './elements.json';

export interface Bindings {
	rubricController: any;
}

export class RubricWidgetPanelController implements Bindings {

	// Bindings
	public rubricController: any;

	public elements: any[];
	public sortableOptions: AriaSortableOptions;

	/* @ngInject */
	constructor (private $scope: ng.IScope, private $translate) {}

	public $onInit (): void {
		this.elements = Array.from(elements);
		this.sortableOptions = {
			group: {
				name: 'rubric-group',
				pull: 'clone',
				put: false
			},
			putSpeakerAdditionalText: {
				prepend: this.$translate.instant('rubric-element_rubric-element-added')
			},
			sort: false,
			scroll: false,
			ghostClass: 'rb-element-placeholder',

			// drag start
			onStart: () => {
				// angular-legacy-sortable expects `Sortable.active.lastPullMode` to be
				// defined but this property is no longer supported by the SortableJS.
				// https://github.com/SortableJS/Sortable/commit/073a57458dfd24e00826a02917cc49eb7c2f06fb
				(Sortable.active as any).lastPullMode = 'clone';

				this.rubricController.setCanvasActive(true);
			},

			// drag end
			onEnd: () => {
				delete (Sortable.active as any).lastPullMode;
				this.rubricController.setCanvasActive(false);
			},

			onRemove: () => {
				// This resets the template elements back to the original order.
				// After a template element is added to the canvas, angular-legacy-sortable
				// modifies the elements array and moves the item to the beginning.
				this.elements = Array.from(elements);
				this.$scope.$digest();
			}
		};
	}
}
