import * as angular from 'angular';

import { common } from '../common';

import { TagSetModel } from './tag-set.factory';

export const tagSetModel = angular.module('go.models.tagSet', [
	common
])
	.factory(TagSetModel.NG1_INJECTION_NAME, TagSetModel)
	.name;
