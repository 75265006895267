import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import * as angulartics from 'angulartics';
import * as angularAnimate from 'angular-animate';
import 'bootstrap-css-only';

import './style.less';
import { VideoSceneComponent } from './video-scene.component';
import { aspectRatioContainerModule } from 'go-modules/aspect-ratio-container';
import { globalEvent } from 'go-modules/global-event';
import { detectModule } from 'go-modules/detect';
import { TimeRemainingCountdownComponent } from './time-remaining-countdown/time-remaining-countdown.component';
import { VolumeIndicatorComponent } from './volume-indicator/volume-indicator.component';
import { featureFlagModule } from 'go-modules/feature-flag';
import { time } from 'go-modules/time';
import { helpUrlsModule } from 'go-modules/help-urls';
import { ngxUnsavedDataManagerServiceModule } from 'ngx/go-modules/src/services/unsaved-data-manager';
import { messageModalModule } from 'go-modules/modals/message';
import { messageRememberingModalModule } from 'go-modules/modals/message-remembering';
import { featureSupportModule } from 'go-modules/feature-support';
import { realTimeCaptionsModule } from 'go-modules/real-time-captions';
import { fullstoryModule } from 'go-modules/services/fullstory';
import { authServiceModule } from 'go-modules/services/auth';
import { trapTabIndexModule } from 'go-modules/trap-tab-index';
import { ngxGoToastModule } from 'ngx/go-modules/src/components/go-toast';
import { uploadManagerModule } from 'go-modules/services/upload-manager';
import { ngxFeatureFlagServiceModule } from 'ngx/go-modules/src/services/feature-flag';
import { downgradeComponent } from '@angular/upgrade/static';
import { TimeDisplayComponent } from 'ngx/go-modules/src/components/time-display/time-display.component';
import { StatusOverlayComponent } from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay.component';
import { SelectedModule } from 'go-modules/services/selected';

export const videoSceneModule = angular
	.module('video-scene', [
		angularTranslate,
		aspectRatioContainerModule,
		angulartics,
		globalEvent,
		detectModule,
		featureFlagModule,
		time,
		helpUrlsModule,
		ngxUnsavedDataManagerServiceModule,
		messageModalModule,
		messageRememberingModalModule,
		angularAnimate,
		featureSupportModule,
		realTimeCaptionsModule,
		fullstoryModule,
		trapTabIndexModule,
		authServiceModule,
		ngxGoToastModule,
		uploadManagerModule,
		ngxFeatureFlagServiceModule,
		SelectedModule
	])
	.component('videoScene', VideoSceneComponent)
	.directive('ngxTimeDisplay', downgradeComponent({ component: TimeDisplayComponent }))
	.component('timeRemainingCountdown', TimeRemainingCountdownComponent)
	.component('volumeIndicator', VolumeIndicatorComponent)
	.directive('ngxStatusOverlay', downgradeComponent({component: StatusOverlayComponent}))
	.name;
