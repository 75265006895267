import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';
import { DownloadPWAModal } from './modal.factory';
import { detectModule } from 'go-modules/detect';

export const downloadPWAModalModule = angular.module('download.pwa', [
	goModalBootstrapModule,
	angularTranslate,
	detectModule
])
	.service(DownloadPWAModal.NG1_INJECTION_NAME, DownloadPWAModal)
	.name;
