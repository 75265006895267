import * as angular from 'angular';

import { event } from '../event-channel';
import { mediaPlayerServicesModule } from '../media-player/services';
import { syncEventModel } from '../models/sync-event';

import { PlayerSyncTrack } from './player-sync-track.factory';
import { PlayerSync } from './player-sync.factory';

export const playerSyncModule = angular.module('playerSync', [
	event,
	mediaPlayerServicesModule,
	syncEventModel
])
	.factory('PlayerSyncTrack', PlayerSyncTrack)
	.factory('PlayerSync', PlayerSync)
	.name;
