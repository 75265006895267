import * as angular from 'angular';
import { clientSettings } from '../common/client.settings';

import { UserTourSchema } from './tour.schema';
import { UserTourTransformer } from './tour.transformer';

export class UserTour implements UserTourSchema {
	public userId: number;
	public id: number;
	public createdAt: Date;
	public name: string;
	public views: number;

	constructor (userTourData: UserTourSchema) {
		angular.extend(this, UserTourTransformer.response(userTourData));
	}
}

export type UserTourType = UserTour & ng.resource.IResourceClass<ng.resource.IResource<UserTour>> & {
	viewTour: ng.resource.IResourceMethod<ng.resource.IResource<UserTour>>;
	$viewTour: ng.resource.IResourceInstanceMethod<ng.IPromise<UserTour>>;
};

/* @ngInject */
export function UserTourFactory (
	$resource,
	userTourTransformer: UserTourTransformer
) {
	const resourceUrl = `${clientSettings.GoReactV2API}/tours`;

	const resource = $resource(resourceUrl, {}, {
		viewTour: {
			method: 'PUT',
			url: `${resourceUrl}/:name`,
			transformRequest: userTourTransformer.requestTransformers,
			transformResponse: userTourTransformer.responseTransformers,
			params: {
				name: '@name'
			}
		},
		query: {
			method: 'GET',
			transformResponse: userTourTransformer.responseTransformers,
			isArray: true
		},
		get: {
			method: 'GET',
			url: `${resourceUrl}/:name`,
			transformResponse: userTourTransformer.responseTransformers,
			params: {
				name: '@name'
			}
		}
	});

	angular.extend(UserTour, resource);
	angular.extend(UserTour.prototype, resource.prototype);

	return UserTour;
}
