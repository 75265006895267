import * as angular from 'angular';
import { noop } from 'angular';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';

/* @ngInject */
export const FeedbackTreeItemController = function (
	$scope,
	$q,
	$timeout,
	eventService: EventService,
	confirmModal
) {
	const vm = this;
	const environmentVarsService = EnvironmentVarsService.getInstance();

	vm.$onInit = () => {
		// TODO: We need to refactor to use parent controllers
		$scope.feedbackItem = $scope.family;
		// TODO: We need to refactor to use parent controllers
		vm.mode = '';
		vm.seekOffset = 5000; // default to 5 second offset

		// Add initial time reached event listener
		if ($scope.feedbackItem.time !== null && $scope.feedbackItem.isGeneration(1)) {
			$scope.settings.mainController.playerSync.on(Math.floor($scope.feedbackItem.time / 1000), onTimeReached);
		}

		// If element is in the process of saving (it has been added, but is not saved yet)
		// immediately set it active and scroll to it
		if ($scope.feedbackItem.isSaving()) {
			// TODO: We need to refactor to use parent controllers
			$scope.setActive();
			$timeout(() => {
				// TODO: We need to refactor to use parent controllers
				$scope.scrollWithin();
			}, 100);
		}
	};

	/**
	 * Set the mode and with a promise that will resolve when the mode changes
	 *
	 * @type {Deferred}
	 */
	let deferred = $q.defer();
	vm.setMode = (mode) => {
		if (vm.mode !== mode) {// mode changed
			vm.mode = mode;

			// Make sure the element is within view,
			// timeout will make sure scroll is done after mode change
			if (mode === 'edit') {
				const timeout = $timeout(() => {
					$timeout.cancel(timeout);
					// TODO: We need to refactor to use parent controllers
					$scope.scrollWithin();
				});
			}

			deferred.resolve(mode); // resolve promise
			deferred = $q.defer(); // set next deferred
		}

		return deferred.promise;
	};

	/**
	 * Whether feedback item may be edited
	 *
	 * @returns {boolean}
	 */
	vm.mayEdit = () => {
		if (environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		return $scope.settings.mainController.userGroup.hasInstructorRole(true) ||
			$scope.feedbackItem.isOwner($scope.settings.mainController.user);
	};

	/**
	 * Set default mode
	 *
	 * @returns {*}
	 */
	vm.defaultMode = () => {
		$scope.settings.mainController.playerSync.resume();

		return vm.setMode('');
	};

	/**
	 * Set before edit mode
	 *
	 * @returns {*}
	 */
	vm.beforeEdit = () => {
		$scope.settings.mainController.playerSync.resume();

		return vm.setMode('beforeEdit');
	};

	/**
	 * Set edit mode
	 *
	 * @returns {*}
	 */
	vm.edit = () => {


		// Make this feedback item active
		// TODO: We need to refactor to use parent controllers
		$scope.setActive();

		return vm.setMode('edit');
	};

	/**
	 * Cancel edit
	 */
	vm.cancelEdit = () => {
		if (!$scope.feedbackItem.isSaving() && !$scope.feedbackItem.isSaved()) {// unsaved feedback

			// if this is the only child remove it
			if ($scope.feedbackItem.isOnlyChild()) {
				vm.remove();
			}
			vm.beforeEdit();
		} else {
			vm.defaultMode();
		}
	};

	/**
	 * Set confirm removal mode
	 *
	 * @returns {*}
	 */
	vm.confirmRemove = () => {
		confirmModal.open({
			size: 'sm',
			modalData: {
				title: 'feedback-session-feedback-tree-comment_sure-remove-comment-title',
				message: 'feedback-session-feedback-tree-comment_sure-remove-comment',
				yes: 'common_remove',
				no: 'common_cancel'
			}
		}).result.then(() => {
			vm.remove();
		}).catch(noop);
	};

	/**
	 * Remove feedback item
	 */
	vm.remove = () => {

		// remove feedback item
		$scope.feedbackItem.remove();
		eventService.broadcast(EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_REMOVED, $scope.feedbackItem);

		if (!$scope.feedbackItem.isSaved()) {
			return;
		}

		// delete feedback item
		$scope.feedbackItem.delete().then(() => {
			eventService.broadcast(EVENT_NAMES.FEEDBACK_TREE_FEEDBACK_DELETED, $scope.feedbackItem);
		});
	};

	/**
	 * Whether or not the feedback item time code should be displayed to the user
	 */
	vm.shouldDisplayTimeCode = (): boolean => {
		return $scope.feedbackItem.time !== null &&
			!$scope.feedbackItem.parent_id &&
			$scope.settings.mainController.hasOrRequiresResponseMedia();
	};

	/**
	 * Seek to time in external player
	 *
	 * @param feedbackItem
	 */
	vm.seekTo = (feedbackItem) => {
		feedbackItem = feedbackItem || $scope.feedbackItem;

		if (feedbackItem && feedbackItem.time !== null) {
			const time = Math.max(0, feedbackItem.time - vm.seekOffset);
			$scope.settings.mainController.playerSync.seek(time);
		}
	};

	/**
	 * Disable skipping by clicking the time code badge if video seeking is disabled, the session
	 * starts w/ 0 comments (new session), and the session owner is the current user.  Then, when the
	 * session is rewatched or viewed by the instructor, skipping still works
	 */
	vm.isTimeCodeClickDisabled = () => {
		return $scope.settings.mainController.activity.is_video_seeking_disabled
			&& $scope.settings.mainController.session.num_my_comments === 0
			&& $scope.settings.mainController.session.isOwner($scope.settings.mainController.user);
	};

	/**
	 * Do seek only if target element isn't a button or i tag
	 * or somewhere in the player controls for a media comment
	 *
	 * @param evt
	 */
	vm.seekIf = (evt) => {
		const tagName = evt.target.tagName.toLowerCase();
		if (tagName !== 'i' &&
			!evt.target.closest('player-controls') &&
			tagName !== 'button' &&
			!angular.element(evt.target).hasClass('btn-bar') &&
			!vm.isTimeCodeClickDisabled()) {
			vm.seekTo();
		}
	};

	/**
	 * Reset time reached listener
	 *
	 * @param oldTime
	 * @param newTime
	 */
	vm.resetTimeReachedListener = (oldTime, newTime) => {
		$scope.settings.mainController.playerSync.off(Math.floor(oldTime / 1000), onTimeReached);
		$scope.settings.mainController.playerSync.on(Math.floor(newTime / 1000), onTimeReached);
	};

	vm.viewComment = () => {
		// this property is set to true on the backend the moment the page loads
		// however it remains false on the frontend so that the styles for
		// an unviewed comment are still applied
		// we're setting this to true here when the user clicks on a comment
		// so that those styles are removed indicating the comment has been viewed
		// the styles for an unviewed comment are also removed once the page reloads
		$scope.feedbackItem.viewed_by_me = true;
	};

	/**
	 * On time reached event handler
	 */
	function onTimeReached () {
		// Broadcast time reached event
		eventService.broadcast(EVENT_NAMES.FEEDBACK_TREE_TIME_REACHED, $scope.feedbackItem);

		// TODO DEV-16029
		// If there are a lot of comments, calling $scope.$apply() will significantly slow down the app. It is
		// especially bad here when scrubbing through the player. Comments that share the same/similar timestamps
		// will repeatedly trigger app wide change detection all at once which can cause the app to crash/hang.
		// This is a band-aid solution until we can refactor the comments view
		if ($scope.settings.mainController.degraded) {
			return;
		}

		// auto scroll to element
		if ($scope.settings.autoScrollEnabled()) {

			// because feedback are on the same timestamp,
			// we need to first check to see if we are already scrolling
			if (!$scope.isScrolling()) {
				// TODO: We need to refactor to use parent controllers
				$scope.scrollWithin();
			}
		}

		// this property is set to true on the backend the moment the page loads
		// however it remains false on the frontend so that the styles for
		// an unviewed comment are still applied
		// we're setting this to true here when the comment timestamp is reached
		// so that those styles are removed indicating the comment has been viewed
		// the styles for an unviewed comment are also removed once the page reloads
		$scope.feedbackItem.viewed_by_me = true;
		if ($scope.feedbackItem.children?.length) {
			$scope.feedbackItem.children.forEach((child) => {
				child.viewed_by_me = true;
			});
		}
		$scope.setActive();

		// highlight element
		if ($scope.settings.highlightEnabled()) {
			// TODO: We need to refactor to use parent controllers
			$scope.highlight();
		}

		$scope.$apply();
	}

	/**
	 * Destroy event handler
	 */
	$scope.$on('$destroy', () =>  {
		$scope.settings.mainController.playerSync.off(Math.floor($scope.feedbackItem.time / 1000), onTimeReached);
	});

};
