import { Injectable } from '@angular/core';
import { Types } from './media-types';

@Injectable({
	providedIn: 'root'
})
export class NgxMediaService {
	public AUDIO_EXTS = [
		'm4a', 'mp3', 'flac', 'ram', 'mp2'
	];
	public VIDEO_EXTS = [
		'mp4', 'm4v', 'wav', 'mov',
		'flv', 'wmv', 'f4v', 'asf',
		'mpg', 'avi', 'webm', 'ogg',
		'ogv', 'r3d', 'rm', 'qt'
	];
	public DOCUMENT_EXTS = [
		'pdf', 'doc', 'docx', 'odt', 'txt'
	];
	public IMAGE_EXTS = [
		'jpeg', 'jpg', 'gif', 'png', 'bmp', 'svg'
	];
	public PREVIEWABLE_DOC_EXTS = ['pdf', 'jpeg', 'jpg', 'gif', 'png', 'bmp', 'svg'];
	public PREVIEWABLE = this.PREVIEWABLE_DOC_EXTS
		.concat(this.VIDEO_EXTS)
		.concat(this.AUDIO_EXTS);

	constructor () {}

	/**
	 * Determine media type given a filename
	 *
	 * @param filename
	 * @returns {string}
	 */
	 public determineMediaType (filename) {
		let result = '';
		if (typeof filename !== 'string') {
			return result;
		}
		const extension = filename.substring(filename.lastIndexOf('.') + 1);
		if (this.DOCUMENT_EXTS.indexOf(extension) >= 0) {
			result = Types.DOCUMENT;
		} else if (this.VIDEO_EXTS.indexOf(extension) >= 0) {
			result = Types.VIDEO;
		} else if (this.AUDIO_EXTS.indexOf(extension) >= 0) {
			result = Types.AUDIO;
		} else if (this.IMAGE_EXTS.indexOf(extension) >= 0) {
			result = Types.IMAGE;
		}
		return result;
	}

	public isPreviewable (media) {
		if (media.media_status !== 'ready') {
			return false;
		}

		if (media.media_type === Types.DOCUMENT && media.filename) {
			return this.PREVIEWABLE_DOC_EXTS.indexOf(media.filename.split('.').pop().toLowerCase()) !== -1;
		}

		if (media.media_type === Types.VIDEO || media.media_type === Types.AUDIO) {
			return true;
		}

		return false;
	};
}
