import { VideoQuality } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';

export interface OpenTokSettings {
	session_id: string;
	api_key: string;
	token: string;
	hasUsedScreenCapture?: boolean;
}

export interface VideoSceneOptions {
	params?: any;
	opentok?: OpenTokSettings;
	mediaRecorder?: boolean;
	timeLimit?: number;
	stateLabels?: any;
	audioOnly?: boolean;
	autoResumeAfterDisconnect?: boolean;
	goReactToken?: string;
	disableResume?: boolean;
	warnOnExit?: boolean;
	displayName?: string;
	equipmentOnly?: boolean;
	connectionTimeout?: number;
	showCaptionButton?: boolean;
	overlayNameEnabled?: boolean;
	isMultiCam?: boolean;
	opentokHack: boolean;
	videoQuality: VideoQuality;
}

export const SESSION_RESTART = 'session-restart';
