import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { RubricTemplateModel } from './rubric-template.factory';
import { RubricTemplateDataTransformerService } from './rubric-template-transformer.service';

export const rubricTemplateModel = angular.module('go.models.rubricTemplate', [
	angularResource
])
	.factory('RubricTemplateModel', RubricTemplateModel)
	.service('rubricTemplateDataTransformerService', RubricTemplateDataTransformerService)
	.name;
