import { Injectable } from '@angular/core';
import { EVENTS } from 'go-modules/media-player/events';
import { STATES } from 'go-modules/media-player/states';

@Injectable({
	providedIn: 'root'
})
export class AllPlayersService {
	public players: any[] = [];

	constructor () {}

	//register player
	public register (player, mediaId) {
		player.id = mediaId;
		this.players.push(player);
	}

	//unregister player
	public unregister (player, mediaId) {
		player.id = mediaId;
		const index = this.players.indexOf(player);
		this.players.splice(index, 1);
	}

	//move player to end of array
	public setLastPlayed (player) {
		const index = this.players.indexOf(player);
		this.players.push(this.players.splice(index, 1)[0]);
	}

	//play last player played
	public playLastPlayed = () => {
		this.players[this.players.length - 1].play();
	};

	//pause all playing players
	public pauseAll () {
		this.players.map((player) => {
			if (player.state === STATES.PLAYING){
				player.pause();
			}
		});
	}

	// play list of media players in order
	public playCommentResources (mediaResources) {
		return mediaResources.reduce((previousPromise: Promise<void>, media) => {
			return previousPromise.then(() => {
				if (media.item.media_type === 'video' || media.item.media_type === 'audio') {
					const player = this.players.find((play) => {
						return parseInt(play.id, 10) === parseInt(media.resource_id, 10);
					});
					return this.playPlayer(player);
				} else {
					return Promise.resolve();
				}
			});
		}, Promise.resolve());
	}

	private playPlayer (player): Promise<void> {
		return new Promise((resolve) => {
			player.play();
			player.once(EVENTS.COMPLETE, () => {
				resolve();
			});
		});
	}
}
