import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const InvalidValueValidator = (existingValues: string[]): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) return null;
		const value = control.value.toLowerCase().trim();
		if (existingValues.includes(value)) {
			return { duplicate: { value: control.value } };
		}
		return null;
	};
};

export { InvalidValueValidator };
