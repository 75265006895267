/* @ngInject */
export function RubricElementController ($scope) {

	$scope.isNumber = function (value) {
		return !isNaN(parseFloat(value));
	};

	$scope.isNegative = function () {
		return $scope.element.points < 0;
	};

	$scope.isPositive = function () {
		return parseFloat($scope.element.points) >= 0;
	};
}
