import { NgModule } from '@angular/core';
import { GoAnchorDirective } from './go-anchor.directive';

@NgModule({
	declarations: [
		GoAnchorDirective
	],
	exports: [
		GoAnchorDirective
	]
})
export class GoAnchorModule {}
