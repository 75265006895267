import { debounce } from 'lodash';

export const SCROLL_PERCENT = .5;
export const DEBOUNCE_TIME = 50;

export function umcInfiniteScrollDirective () {
	return {
		restrict: 'A',
		scope: {
			onScroll: '&'
		},
		link: (scope, elem) => {
			const scrollHandler = (e) => {
				const t = e.target;
				const scrollPercent = t.scrollTop / (t.scrollHeight - t.clientHeight);
				if (scrollPercent > SCROLL_PERCENT) {
					scope.onScroll();
				}
			};

			elem.on('scroll', debounce((e) => scrollHandler(e), DEBOUNCE_TIME));
		}
	};
}
