import * as angular from 'angular';
import { VideoQualityUtil } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';

/* @ngInject */
export const sessionEditor = (UniversalMediaChooser, goModal, $filter, Session, selectedService) => {
	// Add a stimulus to a session
	const addStimulus = (session, activity, group) => {
		const umc = UniversalMediaChooser.get('umcModal');
		const uploadSizeLimit: number = group && group.upload_limit > 0 ?
			group.upload_limit * 1024 * 1024 : 0;

		const defaultUmcModalOptions = {
			headerOptions: [
				umc.MEDIA_TYPE.RECORD_VIDEO,
				umc.MEDIA_TYPE.UPLOAD,
				umc.MEDIA_TYPE.YOUTUBE,
				umc.MEDIA_TYPE.RECORD_AUDIO
			],
			defaultSection: umc.MEDIA_TYPE.UPLOAD,
			chooseAutomatically: true,
			blacklist: 'fine-uploader/src/partials/previewable-only-blacklist.json',
			uploadMinSizeLimit: 1,
			uploadSizeLimit
		};

		const umcModalOptions = {
			groupId: session.group_id,
			resourceId: session.getId(),
			resourceType: Session.SLIDES_RESOURCE_TYPE,
			goRecorder: {
				videoQuality: selectedService.getLicense()?.salesforce_license.video_quality ??
				VideoQualityUtil.MINIMUM_RESOLUTION
			}
		} as any;

		if (activity.isSlidesEnabled()) {
			umcModalOptions.headerOptions = [
				umc.MEDIA_TYPE.UPLOAD
			];
			umcModalOptions.blacklist = 'fine-uploader/src/partials/pdf-only-whitelist.json';
			umcModalOptions.supportUrl = 'https://help.goreact.com/hc/en-us/articles/360004267391';
			umcModalOptions.supportLinkText = $filter('translate')('session-editor_converting-your-slides-to-pdf');
		}

		return goModal.open({
			modal: 'umc',
			modalData: {options: angular.extend({}, defaultUmcModalOptions, umcModalOptions)},
			animation: false
		});
	};

	return {addStimulus};
};
