import template from './umc-record.component.html';

export const UMCRecordComponent = {
	bindings: {
		options: '<',
		umcInstanceName: '@'
	},
	require: {
		umcController: '^universalMediaChooser'
	},
	template
};
