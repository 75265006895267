/* @ngInject */
export function LabelWrapperController ($element, $window) {
	const vm = this;

	vm.$onInit = () => {
		$element.on('label-input:focus', function () {
			vm.isFocused = true;
		});

		$element.on('label-input:blur', function () {
			vm.isFocused = false;
		});
	};


	vm.$postLink = function () {
		// Style our parent $element display to match child element
		const content = $element[0].querySelector('[ng-transclude] > *');
		const displayStyle = $window.getComputedStyle(content).getPropertyValue('display');
		$element.css('display', displayStyle);
	};
}
