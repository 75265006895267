import { SessionStorageService } from 'go-modules/session-manager/session-storage.service';
import { SessionCreationIntent } from '../intent';
import type { SessionCreationWizardOptions } from '../options';
import type { SessionCreationWizardStep } from './';

export class EquipmentCheckStep implements SessionCreationWizardStep {

	/** @ngInject */
	constructor (
		private equipmentCheckModal: any,
		private sessionStorage: SessionStorageService
	) {}

	/**
	 * Run this step
	 */
	public run (options: SessionCreationWizardOptions): ng.IPromise<void> {
		return this.equipmentCheckModal.open(options).result;
	}

	/**
	 * Determine whether this step is required
	 */
	public isRequired (options?: SessionCreationWizardOptions): boolean {
		return (options.intent === SessionCreationIntent.RECORD
			|| options.intent === SessionCreationIntent.CONTINUE)
			&& options.activity.isEquipmentCheckRequired(this.sessionStorage);
	}
}
