import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { clientSettings } from 'go-modules/models/common/client.settings';

export interface QueueImportParams {
	group_id: number;
	download_url: string;
	resource_id?: number;
	resource_type?: string;
}

@Injectable({
	providedIn: 'root'
})
export class ZoomService {
	constructor (
		private http: HttpClient
	) {}

	public queueImport (mediaId: number, params: QueueImportParams): Observable<any> {
		return this.http.post(`${clientSettings.GoReactV2API}/zoom/media/${mediaId}/queue-import`, params);
	}
}
