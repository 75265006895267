import * as angular from 'angular';
import * as dayjs from 'dayjs';

/* @ngInject */
export function userActivityHistoryModelTransformer ($http, Session) {

	/**
	 * Transform request data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformRequestData (data) {
		const payload = angular.extend({}, data);
		return payload;
	}

	/**
	 * Transform request
	 *
	 * @param request
	 * @returns {*}
	 */
	function transformRequest (request) {
		let requestData;

		if (angular.isArray(request)) {
			requestData = [];
			angular.forEach(request, (data) => {
				requestData.push(transformRequestData(data));
			});
		} else {
			requestData = transformRequestData(request);
		}

		return angular.toJson(requestData);
	}

	/**
	 * Transform response data
	 *
	 * @param event
	 * @returns {Object}
	 */
	function transformResponseData (event) {
		event.created_at = event.created_at ? dayjs.utc(event.created_at).toDate() : null;
		event.data.created_at = event.data.created_at ? dayjs.utc(event.data.created_at).toDate() : null;
		event.data.modified_at = event.data.modified_at ? dayjs.utc(event.data.modified_at).toDate() : null;
		event.data.updated_at = event.data.updated_at ? dayjs.utc(event.data.updated_at).toDate() : null;
		event.data.deleted_at = event.data.deleted_at ? dayjs.utc(event.data.deleted_at).toDate() : null;

		switch (event.data_type) {
			case 'session':
				event.data.started_at = event.data.started_at ? dayjs.utc(event.data.started_at).toDate() : null;
				event.data.started_at = event.data.started_at ? dayjs.utc(event.data.started_at).toDate() : null;
				event.data.viewed_recording_instructions_at = event.data.viewed_recording_instructions_at ?
					dayjs.utc(event.data.viewed_recording_instructions_at).toDate() : null;
				event.data.posted_at = event.data.posted_at ? dayjs.utc(event.data.posted_at).toDate() : null;
				event.data.readable_status = Session.getReadableStatus(event.data);
				break;
			case 'comment':
				event.data.posted_at = event.data.posted_at ? dayjs.utc(event.data.posted_at).toDate() : null;
				break;
			case 'course':
				event.data.start_date = event.data.start_date ? dayjs.utc(event.data.start_date).toDate() : null;
				event.data.end_date = event.data.end_date ? dayjs.utc(event.data.end_date).toDate() : null;
				break;
			case 'activity':
				event.data.available_at = event.data.available_at ? dayjs.utc(event.data.available_at).toDate() : null;
				event.data.due_at = event.data.due_at ? dayjs.utc(event.data.due_at).toDate() : null;
				break;
		}
	}

	/**
	 * Transform response
	 *
	 * @param response
	 * @returns {*}
	 */
	function transformResponse (response) {
		if (angular.isArray(response)) {
			angular.forEach(response, (data) => {
				transformResponseData(data);
			});
		} else {
			transformResponseData(response);
		}

		return response;
	}

	return {
		request: transformRequest,
		response: [$http.defaults.transformResponse[0], transformResponse]
	};
}
