import * as angular from 'angular';

import slideToggleComponent from './slide-toggle.component';
import slideToggleControlComponent from './control/control.component';
import slideToggleThumbComponent from './thumb/thumb.component';


import './style.less';

export const slideToggleModule = angular.module('go.slideToggle', [])
	.component('slideToggle', slideToggleComponent)
	.component('slideToggleControl', slideToggleControlComponent)
	.component('slideToggleThumb', slideToggleThumbComponent)
	.name;
