import { Pipe, PipeTransform } from '@angular/core';

export interface TimeDuration {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	milliseconds: number;
	microseconds: number;
	nanoseconds: number;
}

@Pipe({
	name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
	public transform (ms: number, version: 'default' | 'timer' = 'default'): string {
		if (version === 'timer') {
			return this.formatTimer(ms);
		}

		const parsed = this.parseDuration(ms);

		// We don't care about days, roll them into hours and get rid of them
		parsed.hours += parsed.days * 24;
		delete parsed.days;

		const hours: string = Math.abs(parsed.hours).toString();
		let minutes: string = Math.abs(parsed.minutes).toString();
		if (hours !== '0') {
			minutes = minutes.padStart(2, '0');
		}
		const seconds: string = Math.abs(parsed.seconds).toString().padStart(2, '0');

		// Format timestamp string
		let formatted = `${minutes}:${seconds}`;

		// Only add hours if needed
		if (hours !== '0') {
			formatted = `${hours}:${formatted}`;
		}

		// Correctly handle negative inputs
		if (ms < 0) {
			formatted = `-${formatted}`;
		}

		return formatted;
	}

	public parseDuration (ms: number): TimeDuration {
		const roundTowardsZero = ms > 0 ? Math.floor : Math.ceil;

		return {
			days: roundTowardsZero(ms / 86400000),
			hours: roundTowardsZero(ms / 3600000) % 24,
			minutes: roundTowardsZero(ms / 60000) % 60,
			seconds: roundTowardsZero(ms / 1000) % 60,
			milliseconds: roundTowardsZero(ms) % 1000,
			microseconds: roundTowardsZero(ms * 1000) % 1000,
			nanoseconds: roundTowardsZero(ms * 1e6) % 1000
		};
	}

	private formatTimer (ms: number): string {
		const parsed = this.parseDuration(ms);
		parsed.hours += parsed.days * 24;
		delete parsed.days;

		const hours: string = Math.abs(parsed.hours).toString().padStart(2, '0');
		const minutes: string = Math.abs(parsed.minutes).toString().padStart(2, '0');
		const seconds: string = Math.abs(parsed.seconds).toString().padStart(2, '0');

		let formatted = `${minutes}:${seconds}`;
		const zeroHours = '00';
		if (hours !== zeroHours) {
			formatted = `${hours}:${formatted}`;
		}
		if (ms < 0) {
			formatted = `-${formatted}`;
		}

		return formatted;
	}
}
