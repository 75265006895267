import { ActivityInstructionModel } from 'go-modules/models/activity';

export class RichInstructionsController {

	// bindings
	public instruction: any;
	public ariaLabelTitleKey: string;
	public media: any;

	/* @ngInject */
	constructor (private $sanitize) {}

	public $onInit () {
		this.ariaLabelTitleKey = this.instruction.type === ActivityInstructionModel.TYPE.RECORDING ?
			'activity-info_recording-instructions' :
			'activity-info_feedback-instructions';
	}

	public getRichText (): string {
		if (this.instruction) {
			const hasHtmlTag = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
			if (!hasHtmlTag(this.instruction.text)) {
				// added to keep multi-line format from old instructions texts
				this.instruction.text = this.instruction.text.replace(/\r?\n|\r|\n/g, '<br/>');
			}
			return this.$sanitize(this.instruction.text);
		}
	}
}
