import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import { mediaModel } from '../models/media';
import opentokSessionModule from '../models/opentok-session';
import { goPlayerControls } from '../player-controls';

import { featureFlagModule } from 'go-modules/feature-flag';
import { mediaPlayerServicesModule } from './services';
import { GoMediaPlayer } from './adapters/media-player';
import type { PlaylistItem as ExportPlaylistItem } from './playlist-item';
import { EVENTS } from './events';
import { STATES } from './states';
import { PROVIDERS } from './providers';
import type { Options as ExportOptions } from './options';
import goMediaPlayerComponent from './media-player.component';
import { realTimeCaptionsModule } from 'go-modules/real-time-captions';
import { mediaAnalyticsModule } from 'go-modules/services/media-analytics';
import { allPlayersServiceModule } from 'ngx/go-modules/src/services/all-players';

import './style.less';
import { ngxMediaAnalyticsServiceModule } from 'ngx/go-modules/src/services/media/analytics';
import { ngxFeatureFlagServiceModule } from 'ngx/go-modules/src/services/feature-flag';

export {
	GoMediaPlayer,
	EVENTS,
	STATES,
	PROVIDERS
};

export type Options = ExportOptions;
export type PlaylistItem = ExportPlaylistItem;

export const mediaPlayerModule = angular.module('mediaPlayer', [
	angularTranslate,
	mediaPlayerServicesModule,
	goPlayerControls,
	mediaModel,
	opentokSessionModule,
	featureFlagModule,
	realTimeCaptionsModule,
	mediaAnalyticsModule,
	allPlayersServiceModule,
	ngxMediaAnalyticsServiceModule,
	ngxFeatureFlagServiceModule
])
	.component('goMediaPlayer', goMediaPlayerComponent)
	.name;
