import { AnnouncementSchema } from './announcement.schema';
import { DataTransformer } from '../common/data-transformer';

import * as dayjs from 'dayjs';
import { IHttpService } from 'angular';

export class AnnouncementTransformer extends DataTransformer {

	/* @ngInject */
	constructor ($http: IHttpService) {
		super($http);
	}

	public static response (data: any): AnnouncementSchema {
		data.id = parseInt(data.id, 10);
		data.isDismissable = data.is_dismissable;
		data.name = data.title;
		data.content = data.message;
		data.maxViews = data.max_views || 1; // Defaults to 1
		data.startDisplayAt = DataTransformer.propertyExists(data, 'start_display_at', 'startDisplayAt') ?
			dayjs.utc(data.start_display_at || data.startDisplayAt).toDate() : null;
		data.stopDisplayAt = DataTransformer.propertyExists(data, 'stop_display_at', 'stopDisplayAt') ?
			dayjs.utc(data.stop_display_at || data.stopDisplayAt).toDate() : null;

		delete data.is_dismissable;
		delete data.start_display_at;
		delete data.message;
		delete data.title;

		return data;
	}

	protected transformRequestData (_announcement: AnnouncementSchema) {
		throw new Error('Method not implemented.');
	}

	protected transformResponseData (data: any): AnnouncementSchema {
		return AnnouncementTransformer.response(data);
	}
}
