import { noop } from 'angular';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { EnvironmentVarsService } from 'ngx/go-modules/src/services/environment-vars/environment-vars.service';

/* @ngInject */
export function SlidePresentationViewerController (
	$scope,
	feedbackSession,
	sessionEditor,
	$q,
	$analytics,
	$filter,
	messageModal,
	$translate: ng.translate.ITranslateService,
	ngxDowngradeModalService: DowngradeModalService
) {
	const vm = this;
	const environmentVarsService = EnvironmentVarsService.getInstance();
	vm.editMode = false;
	vm.slideTrack = null;
	vm.instructionsDeferred = $q.defer();

	/**
	 * Handle controller init
	 */
	vm.$onInit = function () {
		// We need to initially resolve the instructions deferred object
		// so that the instructions don't show until edit mode is activated.
		vm.discardInstructions();

		// Edit mode should mirror the slide track capture mode
		$scope.$watch(() => {
			return !!vm.slideTrack && vm.slideTrack.isCaptureMode();
		}, (mode: boolean) => {
			vm.editMode = mode;
			onEditModeChange();
		});
	};

	/**
	 * Register the slide track
	 *
	 * @param track
	 */
	vm.registerSlideTrack = function (track) {
		vm.slideTrack = track;
	};

	/**
	 * Toggle edit mode on / off
	 *
	 * @param mode
	 */
	vm.toggleEditMode = function (mode) {
		vm.slideTrack.toggleAsyncCaptureMode(mode);
		$analytics.eventTrack('slide-deck-toggle', {
			category: 'session',
			label: vm.editMode ? 'on' : 'off'
		});
	};

	/**
	 * Whether header should be shown
	 *
	 * @returns {boolean}
	 */
	vm.shouldShowHeader = function () {
		if (environmentVarsService.get(EnvironmentVarsService.VAR.READONLY)) {
			return false;
		}

		return feedbackSession.isPlaybackMode()
			&& vm.mainController.session.isOwner(vm.mainController.user)
			&& vm.mainController.activity.isSlidesEnabled();
	};

	/**
	 * Show slide deck instructions
	 */
	vm.showInstructions = function () {
		vm.instructionsDeferred = $q.defer();
		vm.instructionsDeferred.$resolved = false;
	};

	/**
	 * Discard slide deck instructions
	 */
	vm.discardInstructions = function () {
		vm.instructionsDeferred.$resolved = true;
		vm.instructionsDeferred.resolve();
	};

	/**
	 * Upload slide deck
	 */
	vm.addSlideDeck = function () {
		sessionEditor.addStimulus(
			vm.mainController.session,
			vm.mainController.activity,
			vm.mainController.userGroup
		).result.then(function (sourceMedia) {
			vm.mainController.session.setSourceMedia(sourceMedia);
			vm.mainController.session.save();
			vm.mainController.validateSourceMedia();
		});
	};

	/**
	 * Prompt to delete slide deck
	 */
	vm.deleteSlideDeck = function () {
		return ngxDowngradeModalService.openConfirmDialog({
			title: $translate.instant('modal-confirm-delete-slide-deck_remove-slide-deck'),
			message: $translate.instant('modal-confirm-delete-slide-deck_remove-slide-deck-warning'),
			confirmText: $translate.instant('common_remove')
		}).then(() => {
			vm.mainController.session.deleteSlideDeck()
				.then(function () {
					// Remove comments in memory
					vm.contentController.removeSyncEventComments();
					// Remove track sync events from memory
					vm.slideTrack.removeSyncEvents();
					// Update display
					vm.mainController.validateSourceMedia(null);
					$analytics.eventTrack('remove-deck', {
						category: 'dashboard'
					});
				}).catch(function () {
					messageModal.open({
						modalData: {
							type: 'error',
							title: 'Oops',
							message: $filter('translate')('feedback-session-slide-presentation-viewer_unable-to-delete-slide-deck')
						}
					});
				});
		}).catch(noop);
	};

	/**
	 * Handle edit mode change
	 */
	function onEditModeChange () {
		if (!vm.slideTrack) {
			return;
		}

		// If edit mode is enabled and no sync events
		// have been created and media is not failed, we need to show the instructions.
		// Otherwise, make sure that instructions are not showing.
		// This also resolves the instructions deferred object.
		if (vm.editMode && !vm.slideTrack.getSyncEvents().length && !vm.slideMedia.isFailed()) {
			vm.showInstructions();
		} else {
			vm.discardInstructions();
		}

		if (vm.editMode) {
			// If we are in record mode, we need to disable
			// the session recording. Once the instructions are closed,
			// then we can re-enable session recording.
			if (feedbackSession.isRecordMode()) {
				feedbackSession.deferRecordingReEnablement(vm.instructionsDeferred.promise);
			} else if (feedbackSession.isPlaybackMode()) {
				// When edit mode is turned on and we are in playback mode,
				// pause the playback. Once the instructions have been viewed,
				// we need to seek back to the beginning of the video.
				vm.mainController.playerSync.pause();
				vm.instructionsDeferred.promise.then(function () {
					if (vm.editMode) {
						vm.mainController.playerSync.seek(0);
					}
				});
			}
		}

		if (!vm.editMode) {
			vm.contentController.getFeedback();
		}
	}
}

