import template from './media-preview.component.html';
import { MediaPreviewController } from './media-preview.controller';

export const MediaPreviewComponentDef = {
	template,
	controller: MediaPreviewController,
	bindings: {
		previewerId: '@',
		media: '=',
		initialOffset: '=',
		onSeek: '&',
		options: '<',
		onInit: '&',
		onInitError: '&',
		collapseAudio: '<',
		minimizedControls: '<',
		allowDownload: '<'
	}
};
