import { Events } from './events';
import { EventEmitter } from 'events';
import * as angular from 'angular';

export class InternetStatus extends EventEmitter {
	private container: ng.IWindowService;
	private onlineListener: () => void;
	private offlineListener: () => void;

	constructor () {
		super();
		const $window = angular.injector(['ng']).get('$window');
		this.container = $window;
		this.onlineListener = () => this.emit(Events.ONLINE);
		this.offlineListener = () => this.emit(Events.OFFLINE);
		this.container.addEventListener(Events.ONLINE, this.onlineListener);
		this.container.addEventListener(Events.OFFLINE, this.offlineListener);
	}

	public isOnline (): boolean {
		return this.container.navigator.onLine;
	}

	public destroy (): void {
		this.container.removeEventListener(Events.ONLINE, this.onlineListener);
		this.container.removeEventListener(Events.OFFLINE, this.offlineListener);
		this.removeAllListeners();
	}
}
