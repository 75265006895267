import { isUndefined, isNull } from 'lodash';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';

interface Bindings {
	mainController: any;
	singleMediaDisplayController: any;
	mediaDisplayController: any;
	media: any;
}

export class PlaybackViewController implements Bindings {
	public mainController: any;
	public singleMediaDisplayController: any;
	public mediaDisplayController: any;
	public media: any;

	public currentMedia: any = null;
	public playerId: string;
	public mediaPreviewOptions: any;
	public isPostDiscardMode: boolean;
	public stillLoading: boolean;
	public isLoading: boolean;

	/* @ngInject */
	constructor (
		public featureFlag: FeatureFlag,
		private feedbackSession,
		private $filter,
		private $analytics,
		private $timeout
	) {}

	/**
	 * Handles controller init lifecycle hook
	 */
	public $onInit (): void {
		this.currentMedia = this.media;
		this.playerId = this.feedbackSession.sessionPlayerId(this.mainController.session);
		this.isPostDiscardMode = this.checkIsPostDiscardMode();
		this.mediaPreviewOptions = this.getMediaPreviewOptions(this.isPostDiscardMode);
		this.mediaPreviewOptions.isVideoSeekingDisabled = this.mainController.activity.is_video_seeking_disabled;
		this.mediaPreviewOptions.isClosedCaptionsDisabled = this.mainController.activity.is_source_media_cc_disabled;
		this.mediaPreviewOptions.disableControlsForSingleRecording = this.disableControlsForSingleRecording();
		this.mediaPreviewOptions.isCommentOnlySingleAttempt = this.mainController.activity.isCommentOnlySingleAttempt();
		// Attach some meta data for analytics
		this.mediaPreviewOptions.sessionId = this.mainController.session.session_id;
		this.mediaPreviewOptions.activityId = this.mainController.activity.activity_id;
		this.$timeout(() => this.stillLoading = true, 5000);
	}

	/**
	 * Handles controller bindings change lifecycle hook
	 */
	public $onChanges (changes: any): void {
		if (changes.media) {
			this.mediaChangeHandler(changes.media.currentValue);
		}
	}

	/**
	 * Whether post/discard controls should be displayed
	 */
	public shouldDisplayPostDiscardControls (): boolean {
		return this.isPostDiscardMode
			&& !!this.currentMedia
			&& this.currentMedia.isReady()
			&& !this.mediaDisplayController.wasRecordingStoppedBySomeoneElse();
	}

	public postButtonText (): string {
		let postText = 'feedback-session_controller-media-post';
		const trimPoints = this.mediaPreviewOptions.trimPoints;

		if (!isUndefined(trimPoints) && !isNull(trimPoints.start) && !isNull(trimPoints.end)) {
			postText = 'feedback-session_controller-media-save-and-post';
		}

		return this.$filter('translate')(postText);
	}

	public getDiscardedMessage (): string {
		if (!this.mainController.session.archived_by_user) {
			return 'big-blue-button_recording-discarded';
		}

		return 'big-blue-button_recording-discarded-by-user';
	}

	public getPostedMessage (): string {
		if (!this.mainController.session.posted_by_user) {
			return 'big-blue-button_recording-posted';
		}

		return 'big-blue-button_recording-posted-by-user';
	}

	/**
	 * Set trim params before posting
	 */
	public postSession (): void {
		// Conditionally include trim params
		const trimParams = this.mediaPreviewOptions.trimPoints;
		if (!isUndefined(trimParams) && !isNull(trimParams.start) && !isNull(trimParams.end)) {
			this.mainController.session.trim = trimParams;
			this.$analytics.eventTrack('trim-video', {
				category: 'session'
			});
		}

		this.mainController.postSession().then(() => {
			this.mainController.exit();
		});
	}

	public discardSessionConfirm () {
		this.mainController.discardSessionConfirm().then(() => {
			this.mainController.exit();
		});
	}

	public wasRecordingStoppedByMe (): boolean {
		return this.mediaDisplayController.wasRecordingStoppedByMe();
	}

	public shouldAdjustLayout (): boolean {
		const containerWidth = document.querySelector<HTMLElement>('.media-processing-message')?.offsetWidth;
		if (containerWidth) {
			return containerWidth < 480;
		}
		return false;
	}

	public shouldShowLinkButtonAsLabel (): boolean {
		const containerWidth = document.querySelector<HTMLElement>('.media-container')?.offsetWidth;
		if (containerWidth) {
			return containerWidth > 480;
		}
		return false;
	}

	public canRestart (): boolean {
		return this.mainController.activity.allowsPractice();
	}

	public restartSession () {
		this.isLoading = true;
		this.mainController.restartSession()
			.finally(() => this.isLoading = false);
	}

	/**
	 * Check if media is comment only
	 */
	public disableControlsForSingleRecording (): boolean {
		return this.mainController.activity.isCommentOnlySingleAttempt()
			&& this.mainController.session.isPresenter(this.mainController.user);
	}

	private getMediaPreviewOptions (isPostDiscardMode: boolean): any {
		const options: any = { showTrim: this.mediaDisplayController.wasRecordingStoppedByMe() };
		// Auto play for single videos where autoplay wasnt disabled
		options.autoPlay = !!this.singleMediaDisplayController && this.mediaDisplayController.shouldAutoPlay();
		options.hideControls = !this.singleMediaDisplayController; // Hide controls for multi videos
		if (isPostDiscardMode) {
			options.trimPoints = {
				start: null,
				end: null
			};
		} else {
			options.toggleCommentCaptionsCallback = this.feedbackSession.toggleCommentCaptions;
		}

		return options;
	}

	/**
	 * Whether or not it is post / discard mode
	 */
	private checkIsPostDiscardMode (): boolean {
		const mayPost = !this.mainController.session.isPosted() &&
			this.mainController.session.isOwner(this.mainController.user);
		return mayPost &&
			this.feedbackSession.options.manualPostDiscard &&
			this.singleMediaDisplayController;
	}

	/**
	 * Handles media changes
	 */
	private mediaChangeHandler (media: any): void {
		if (!media) {
			return;
		}

		this.currentMedia = media;
	}
}
