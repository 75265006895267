import template from './course-editor.component.html';
import { CourseEditorController as controller } from './course-editor-controller';

export const courseEditorComponent = {
	template,
	controller,
	bindings: {
		course: '=',
		options: '<',
		addDates$: '=',
		duplicateFromGroup: '<',
		isLoading: '='
	},
	require: {
		formController: '^form'
	}
};
