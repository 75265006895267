import * as angular from 'angular';

/* @ngInject */
export function syncEventServiceInterceptor ($q, cacheManager) {

	/**
	 * Check cache
	 *
	 * @param resource
	 * @returns {SyncEvent}
	 */
	function cache (resource) {
		let instance = resource;
		if (instance.getId()) {
			const cachedInstance = cacheManager('syncEvent').get(instance.getId());
			if (cachedInstance) {
				angular.extend(cachedInstance, resource);
				instance = cachedInstance;
			} else {
				cacheManager('syncEvent').put(instance.getId(), instance);
			}
		}

		return instance;
	}

	return {

		response (response) {
			// Check cache for resource
			if (angular.isArray(response.resource)) {
				angular.forEach(response.resource, function (resource, index) {
					response.resource[index] = cache(resource);
				});
			} else {
				response.resource = cache(response.resource);
			}

			return response.resource;
		},

		responseError (rejection) {
			return $q.reject(rejection);
		}
	};
}
