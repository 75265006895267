export enum TranscriptSelectOptions {
	DEFAULT = 'default',
	FILLER = 'filler',
	HEDGING = 'hedging'
}

export const TranscriptSelectOptionsTranslations: Record<TranscriptSelectOptions, string> = {
	default: 'feedback-session-transcript_default',
	filler: 'feedback-session-transcript_filler',
	hedging: 'feedback-session-transcript_hedging'
};
