export interface MediaAnalyticsInterface {
	duration: number;
	playCount: number;
	userId: number;
	mediaId: number;
}

export interface MediaAnalyticsRequest {
	context: null;
	play_duration: number;
	media_id: number;
	user_id: number;
	play_count: number;
}

export class MediaAnalytics implements MediaAnalyticsInterface {
	public duration: number = 0;
	public playCount: number = 0;

	// markers
	public start: number = 0;
	public stop: number = 0;

	constructor (
		public userId: number,
		public mediaId: number,
		public totalDuration: number
	) {}

	public toPayload (): MediaAnalyticsRequest {
		return {
			context: null,
			play_duration: this.getDuration(),
			media_id: this.mediaId,
			user_id: this.userId,
			play_count: this.playCount
		};
	}

	public setStart (start): void {
		this.start = start;
	}

	public incrementPlayCount (): void {
		this.playCount = this.playCount + 1;
	}

	public setDuration (stop): void {
		this.stop = stop;
		this.duration = this.duration + (this.stop - this.start);
	}

	public getDuration (): number {
		return Math.round((this.stop === this.totalDuration) ? this.stop : this.duration);
	}
}
