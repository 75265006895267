import { Injectable } from '@angular/core';

export interface UnsavedDataRegistration {
	isInUnsavedState: () => boolean;
	resolve: () => ng.IPromise<void>;
}

@Injectable({
	providedIn: 'root'
})
export class NgxUnsavedDataManagerService {
	private registrations: UnsavedDataRegistration[];

	constructor () {
		this.registrations = [];
	}

	// hostlistener doesnt work here so must check from component
	public checkUnload (event: BeforeUnloadEvent) {
		if (this.isInUnsavedState()) {
			// Cross browser way to prompt the user to stay
			event.preventDefault();
			event.returnValue = '';
			return '';
		}
	}

	public register (registration: UnsavedDataRegistration): void {
		this.registrations.push(registration);
	}

	public unregister (registration: UnsavedDataRegistration): void {
		const index = this.registrations.indexOf(registration);

		if (index < 0) {
			throw new Error('UnsavedDataManager: Tried to unregister a non-registered entity');
		}

		this.registrations.splice(index, 1);
	}

	public isInUnsavedState (): boolean {
		return this.registrations.some((registration) => registration.isInUnsavedState());
	}

	public resolve (): Promise<void> {
		const unResolvedEntities = this.registrations.filter((registration) => registration.isInUnsavedState());

		// Allow each unresolved entity to have its chance
		return unResolvedEntities.reduce((promiseChain: Promise<void>, registration: UnsavedDataRegistration) => {
			return promiseChain.then(registration.resolve);
		}, Promise.resolve());
	}
}
