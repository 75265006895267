import template from './stimulus-media-display.component.html';
import { FeedbackSessionStimulusMediaDisplayController as controller } from './stimulus-media-display.controller';

export const stimulusMediaDisplay = {
	template,
	require: {mainController: '^feedbackSession'},
	controller,
	bindings: {media: '<'}
};

