import * as angular from 'angular';

/* @ngInject */
export function LabelInputController ($timeout, $log, $q) {
	const vm = this;

	vm.$onInit = () => {
		vm.editing = false;
		vm.modelCopy = undefined;
		vm.originalValue = undefined;
		vm.disabled = false;
		vm.tabindex = 0;

		if (!vm.ariaLabel) {
			$log.warn('DEPRECATION: label-input directive requires an aria-label');
			vm.ariaLabel = 'item'; // Default for now
		}
	};


	/**
	 * Toggle edit mode
	 *
	 * @param editing
	 */
	vm.edit = function (editing) {
		if (angular.isUndefined(editing)) {
			editing = !vm.editing;
		}

		editing = !!editing;

		// Ignore everything else if
		// the state has not changed.
		if (vm.editing === editing) {
			return;
		}

		// Set editing state
		vm.editing = editing;

		// When editing tab focus must be disabled
		// so that users can tab in the reverse direction.
		vm.toggleTabFocus(!editing);

		if (editing) {
			vm.originalValue = angular.copy(vm.value);
			vm.modelCopy = angular.copy(vm.value);
		} else if (!angular.equals(vm.modelCopy, vm.value)) {
			// If change has occurred, assign value
			vm.value = angular.copy(vm.modelCopy);

			// Broadcast input change event
			vm.saving = true;
			$timeout(function () {
				$q.when(vm.onInputChange({ value: vm.value })).then(() => {
					vm.saving = false;
				});
			});
		}
	};

	/**
	 * Undo any editing / exit edit mode
	 */
	vm.revert = function () {
		vm.modelCopy = angular.copy(vm.originalValue);
	};

	/**
	 * When this component is disabled,
	 * tab focusing is also disabled
	 *
	 * @param value
	 */
	vm.toggleDisabled = function (value) {
		if (angular.isUndefined(value)) {
			value = !vm.disabled;
		}
		vm.disabled = !!value;
		vm.toggleTabFocus(!value);
	};

	/**
	 * When tab focus is turned off,
	 * the element can no longer
	 * be tabbed to.
	 *
	 * @param value
	 */
	vm.toggleTabFocus = function (value) {
		if (angular.isUndefined(value)) {
			value = vm.tabindex !== 0;
		}
		vm.tabindex = value ? 0 : -1;
	};

	/**
	 * Whether original value differs from the value that is being edited
	 *
	 * @returns {boolean}
	 */
	vm.isDirty = function () {
		return !angular.equals(vm.value, vm.modelCopy);
	};

	/**
	 * Whether value is empty
	 *
	 * @returns {boolean}
	 */
	vm.isEmpty = function () {
		return !vm.value && vm.value !== 0;
	};

	/**
	 * Handle element focus events
	 */
	vm.onFocus = function () {
		// Don't allow editing when
		// the element is disabled
		if (!vm.disabled) {
			vm.edit(true);
		}
	};
}
