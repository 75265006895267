import template from './time-slider.component.html';
import { TimeSliderController } from './time-slider.controller';

export const TimeSliderComponent = {
	bindings: {
		value: '=ngModel',
		max: '<',
		min: '<'
	},
	controller: TimeSliderController,
	require: {
		ngModelController: '^ngModel'
	},
	template
} as ng.IComponentOptions;
