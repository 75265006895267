import * as angular from 'angular';
import { confirmModalModule } from 'go-modules/modals/confirm';
import { mediaModel } from 'go-modules/models/media';
import { UmcInstancesService } from './umc-instances.service';

export const umcInstancesServiceModule = angular.module('UmcInstancesServiceModule', [
	mediaModel,
	confirmModalModule
])
	.service('umcInstancesService', UmcInstancesService)
	.name;
