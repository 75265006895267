import type { Transaction } from 'go-modules/braintree/braintree.service';

export enum PURCHASE_LICENSE_STATUS {
	PENDING = 'pending',
	COMPLETED = 'completed',
	FAILED = 'failed',
}

export interface LicensePurchaseStatus  {
	status: PURCHASE_LICENSE_STATUS;
	transaction: Transaction;
}
