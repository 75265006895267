// Pulled from Rob--W/grab-to-pan

// License
// Copyright 2013 Rob Wu <rob@robwu.nl>

// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at

//     http://www.apache.org/licenses/LICENSE-2.0

// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export class GrabToPan {

	private element: HTMLElement;
	private document: Document;
	private onActiveChanged: (isActive: boolean) => void;
	private overlay: HTMLDivElement|null = null;
	private active: boolean = false;

	private scrollLeftStart: number = 0;
	private scrollTopStart: number = 0;
	private clientXStart: number = 0;
	private clientYStart: number = 0;

	private CSS_CLASS_GRAB: 'grab-to-pan-grab';

	constructor (options) {
		this.element = options.element;
		this.document = options.element.ownerDocument;
		if (typeof options.ignoreTarget === 'function') {
			this.ignoreTarget = options.ignoreTarget;
		}
		this.onActiveChanged = options.onActiveChanged;

		// This overlay will be inserted in the document when the mouse moves during
		// a grab operation, to ensure that the cursor has the desired appearance.
		this.overlay = document.createElement('div');
		// Inline styles to get maximum CSS specifity
		this.overlay.style.position = 'fixed';
		this.overlay.style.background = 'transparent';
		this.overlay.style.display = 'block';
		this.overlay.style.top = '0';
		this.overlay.style.left = '0';
		this.overlay.style.right = '0';
		this.overlay.style.bottom = '0';
		this.overlay.style.height = 'auto';
		this.overlay.style.width = 'auto';
		this.overlay.style.overflow = 'hidden';
		this.overlay.style.zIndex = '2000000000';
		// Inherit cursor style via CSS style sheet:
		this.overlay.className = 'grab-to-pan-grabbing';
	}

	public activate = () => {
		if (!this.active) {
			this.active = true;
			this.element.addEventListener('mousedown', this.onmousedown, true);
			this.element.classList.add(this.CSS_CLASS_GRAB);
			if (this.onActiveChanged) {
				this.onActiveChanged(true);
			}
		}
	};

	public deactivate = () => {
		if (this.active) {
			this.active = false;
			this.element.removeEventListener('mousedown', this.onmousedown, true);
			this.endPan();
			this.element.classList.remove(this.CSS_CLASS_GRAB);
			if (this.onActiveChanged) {
				this.onActiveChanged(false);
			}
		}
	};

	public toggle = () => {
		if (this.active) {
			this.deactivate();
		} else {
			this.activate();
		}
	};

	public ignoreTarget = (node: HTMLElement) => {
		// Use matchesSelector to check whether the clicked element
		// is (a child of) an input element / link
		return node.matches(
			'a[href], a[href] *, input, textarea, button, button *, select, option'
		);
	};

	public onmousedown = (event: MouseEvent) => {
		if (event.button !== 0 || this.ignoreTarget(event.target as HTMLElement)) {
			return;
		}

		this.scrollLeftStart = this.element.scrollLeft;
		this.scrollTopStart = this.element.scrollTop;
		this.clientXStart = event.clientX;
		this.clientYStart = event.clientY;
		this.document.addEventListener('mousemove', this.onmousemove, true);
		this.document.addEventListener('mouseup', this.endPan, true);
		// When a scroll event occurs before a mousemove, assume that the user
		// dragged a scrollbar (necessary for Opera Presto, Safari and IE)
		// (not needed for Chrome/Firefox)
		this.element.addEventListener('scroll', this.endPan, true);
		event.preventDefault();
		event.stopPropagation();

		const focusedElement = document.activeElement as HTMLElement;
		if (focusedElement && !focusedElement.contains(event.target as HTMLElement)) {
			focusedElement.blur();
		}
	};

	public onmousemove = (event: MouseEvent) => {
		this.element.removeEventListener('scroll', this.endPan, true);
		const xDiff = event.clientX - this.clientXStart;
		const yDiff = event.clientY - this.clientYStart;
		const scrollTop = this.scrollTopStart - yDiff;
		const scrollLeft = this.scrollLeftStart - xDiff;
		if (this.element.scrollTo) {
			this.element.scrollTo({
				top: scrollTop,
				left: scrollLeft
			});
		} else {
			this.element.scrollTop = scrollTop;
			this.element.scrollLeft = scrollLeft;
		}
		if (!this.overlay.parentNode) {
			document.body.appendChild(this.overlay);
		}
	};

	public endPan = () => {
		this.element.removeEventListener('scroll', this.endPan, true);
		this.document.removeEventListener('mousemove', this.onmousemove, true);
		this.document.removeEventListener('mouseup', this.endPan, true);
		if (this.overlay.parentNode) {
			this.overlay.parentNode.removeChild(this.overlay);
		}
	};
}
