import * as angular from 'angular';
import { SessionInterceptor } from './session-manager.interceptor';

/* @ngInject */
export function sessionManagerConfig ($httpProvider) {

	// Register the auth interceptor
	$httpProvider.interceptors.push(function ($q, $templateCache, sessionManager) {
		const config = sessionManager.getConfig();

		return {
			request (request) {
				const deferred = $q.defer();
				request.headers = request.headers || {};
				// Check the template cache first
				if (request.method === 'GET' && $templateCache.get(request.url)) {
					deferred.resolve(request);
				} else if (request.skipAuthorization) {
					delete request.headers[config.authHeader];
					deferred.resolve(request);
					return request;
				} else if (angular.isObject(request.params) && request.params.skipAuthorization) {
					delete request.headers[config.authHeader];
					deferred.resolve(request);
				} else if (angular.isObject(request.data) && request.data.skipAuthorization) {
					delete request.headers[config.authHeader];
					deferred.resolve(request);
				} else {
					const interceptedRequest = SessionInterceptor.request(request, sessionManager);
					deferred.resolve(interceptedRequest);
				}

				return deferred.promise;
			},
			response (response) {
				return SessionInterceptor.response(response, sessionManager);
			},
			responseError (response) {
				return $q.reject(SessionInterceptor.responseError(response, sessionManager));
			}
		};
	});
}
