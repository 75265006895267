import * as angular from 'angular';

import { time } from '../time';
import { event } from '../event-channel';
import { Timer } from './timer';
import { timerDirective } from './timer.directive';

export { TimerBase } from './timer-base';
export { TimerEvent } from './event';
export { Timer };

export const timerModule = angular.module('timer', [
	event,
	time
])
	.factory('Timer', () => Timer)
	.directive('uiTimer', timerDirective)
	.name;
