/* @ngInject */
export function AudioDescriptionController (PlayerSync, MediaModel, mediaPlayer) {
	const vm = this;

	/**
	 * Handles controller init life cycle hook
	 */
	vm.$onInit = function () {
		if (!(vm.audioMedia instanceof MediaModel)) {
			throw new Error('Controller binding `audioMedia` required by AudioDescriptionController');
		}

		// Generate unique audio player id
		vm.playerId = 'supplementary-audio-id-' + Math.floor(Math.random() * 1000);

		// Setup audio description player options
		vm.options = {
			autoPlay: false,
			hideControls: true
		};

		// Setup playlist item
		vm.playlistItem = vm.mediaPreviewController.mediaToPlaylistItem(vm.audioMedia);

		// Add audio description track.
		vm.track = vm.mediaPreviewController.playerSync.addTrack(
			PlayerSync.generateTrackNumber(),
			mediaPlayer.get(vm.playerId),
			[]
		);
	};

	/**
	 * Handles destroy life cycle hook
	 */
	vm.$onDestroy = function () {
		if (vm.mediaPreviewController.playerSync) {
			vm.mediaPreviewController.playerSync.removeTrack(vm.track);
		}
		vm.track = null;
	};
}
