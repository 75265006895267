import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';
import 'angulartics';

import { mediaModel } from '../../models/media';
import { helpUrlsModule } from '../../help-urls';
import { confirmModalModule } from '../confirm';
import { messageModalModule } from '../message';
import { panel } from 'go-modules/panel';
import { toolbarModule } from 'go-modules/toolbar';

import { mediaPreviewModal } from './modal.factory';

import editForm from './edit-form.html';

import './modal.less';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ universalMediaChooserModule } from*/ '../../universal-media-chooser';

/* @ngInject */
function templateCache ($templateCache) {
	$templateCache.put('modals/media-preview/edit-form.html', editForm);
}

export const mediaPreviewModalModule = angular.module('modal.media-preview', [
	goModalBootstrapModule,
	angularTranslate,
	helpUrlsModule,
	mediaModel,
	'angulartics',
	confirmModalModule,
	messageModalModule,
	panel,
	toolbarModule,

	// TODO: use module strings directly until circular dependency is fixed
	'universal-media-chooser'
])
	.factory(mediaPreviewModal.NG1_INJECTION_NAME, mediaPreviewModal)
	.run(templateCache)
	.name;
