import { EVENTS as MediaPlayerEvent, GoMediaPlayer, PROVIDERS } from '../../../media-player';
import { LiveHelper } from './live-helper';
import { Timer } from '../../../timer/timer';
import { MediaSource } from 'go-modules/models/media';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import type { GoEvent } from 'ngx/go-modules/src/services/event/event.service';
import { filter } from 'rxjs/operators';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { OpenTokStreamSubscriberAdapter } from 'go-modules/media-player/adapters/opentok-adapter';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';

interface Bindings {
	mainController: any;
	singleMediaDisplayController: any;
	media: any;
}

export class LiveViewController implements Bindings {
	public mainController: any;
	public singleMediaDisplayController: any;
	public media: any;

	public livePlayer: GoMediaPlayer;
	public liveMedia: any;
	public mediaPreviewOptions: any;
	public recordingManuallyEnded: boolean|null;
	public mediaPreviewError: Error;

	private timer: Timer;
	private liveStreamPlayerId: string;
	private modal: any;
	private liveHelper: LiveHelper;
	private eventSubscription: any;
	private eventNames: string[];

	/* @ngInject */
	constructor (
		private feedbackSession,
		private $scope: ng.IScope,
		private $timeout: ng.ITimeoutService,
		private Session,
		private Timer,
		private MediaModel,
		private $translate,
		private $analytics,
		public featureFlag: FeatureFlag,
		private eventService: EventService,
		private ngxDowngradeModalService: DowngradeModalService
	) {}

	/**
	 * Handles controller init lifecycle hook
	 */
	public $onInit () {
		const liveTimerId: string = this.feedbackSession.liveTimerId(this.mainController.session);
		this.liveStreamPlayerId = this.feedbackSession.sessionPlayerId(this.mainController.session);
		this.timer = this.Timer.get(liveTimerId, Number.POSITIVE_INFINITY);
		this.liveHelper = new LiveHelper(this.mainController.session);
		this.recordingManuallyEnded = null;
		this.liveMedia = null;

		// Options for previewing a live stream
		this.mediaPreviewOptions = {
			hideControls: false, // show controls
			autoPlay: this.liveHelper.isLiveStreamPlaybackAdvised(),
			liveStream: true,
			liveStreamTimerId: liveTimerId,
			// Attach some meta data for analytics
			sessionId: this.mainController.session.session_id,
			activityId: this.mainController.activity.activity_id
		};

		// Handle some external events
		this.eventNames = [
			EVENT_NAMES.FEEDBACK_SESSION_TIME_SYNC,
			EVENT_NAMES.FEEDBACK_SESSION_STATUS_CHANGE,
			EVENT_NAMES.FEEDBACK_SESSION_RECORDING_MANUALLY_ENDED
		];
		this.eventSubscription = this.eventService.events
			.pipe(filter((ev: GoEvent) => this.eventNames.includes(ev.name)))
			.subscribe((ev: GoEvent) => {
				switch (ev.name) {
					case EVENT_NAMES.FEEDBACK_SESSION_TIME_SYNC:
						this.timeSyncHandler(ev.data);
						break;
					case EVENT_NAMES.FEEDBACK_SESSION_STATUS_CHANGE:
						this.statusChangeHandler(ev.data);
						break;
					case EVENT_NAMES.FEEDBACK_SESSION_RECORDING_MANUALLY_ENDED:
						this.recordingManuallyEndedHandler(ev.data);
						break;
				}
			});

		// Handle scenario where user joins session before it has started,
		// as well as when it has already started.
		if (this.mainController.session.isAwaitingStart()) {
			// Show session awaiting start modal
			this.showAwaitingStartModal();
		} else if (this.mainController.session.isLive()) {
			this.initLiveStream();

			// Start the live timer
			this.timer.start();
		}
	}

	public $onChanges (changes: ng.IOnChangesObject): void {
		if (changes.media && changes.media.currentValue && changes.media.currentValue.source === MediaSource.OPENTOK) {
			this.liveMedia = changes.media.currentValue;
			// Ensure the media status is ready so that the media
			// preview component will try to play the live stream.
			this.liveMedia.media_status = this.MediaModel.READY;
		}
	}

	/**
	 * Handles controller destroy lifecycle hook
	 */
	public $onDestroy (): void {
		this.timer.destroy();
		this.hideAwaitingStartModal(false);
		this.eventSubscription?.unsubscribe();
	}

	public onMediaPreviewInit (player: GoMediaPlayer): void {
		this.livePlayer = player;

		// Handle some live stream players events
		this.livePlayerReadyHandler(player);
	}

	/**
	 * Handles media preview component initialization error
	 */
	public onMediaPreviewInitError (error: Error): void {
		this.mediaPreviewError = error;
	}

	public isStatusOverlayEnabled (): boolean {
		if (this.isMobileSession()) {
			return true;
		}

		return !!this.livePlayer &&
			!this.livePlayer.isPlaying() &&
			!this.mainController.session.isRecorded() &&
			!this.mediaPreviewError;
	}

	public isTimeDisplayEnabled (): boolean {
		return this.isMobileSession() && this.mainController.session.isLive();
	}

	/**
	 * Whether or not the live stream has ended
	 */
	public hasLiveStreamEnded (): boolean {
		return this.mainController.session.isRecorded() && this.recordingManuallyEnded !== null;
	}

	/**
	 * Go to playback mode after the live stream is ended
	 */
	public gotoPlaybackMode (): void {
		this.feedbackSession.setMediaDisplayMode(this.feedbackSession.MediaDisplayMode.PLAYBACK);
	}

	/**
	 * At the moment, the only way to detect that the session is being
	 * recorded on the GoReact mobile app is to see if it is missing media.
	 */
	public isMobileSession (): boolean {
		return !this.mainController.session.media;
	}

	/**
	 * Shows the awaiting start modal
	 */
	private showAwaitingStartModal (): void {
		this.modal = this.ngxDowngradeModalService.openMessageDialog(
			{
				title: this.$translate.instant('modal-session-await-start_waiting-begin'),
				message: this.$translate.instant('modal-session-await-start_presentation-not-begun')
			}
		);
		this.modal.afterClosed().subscribe((res: boolean) => {
			// close button closes true x button closes null
			// if we pass false then we dont want to exit
			if (res !== false) {
				this.mainController.exit();
			}
		});
	}

	/**
	 * Hides the awaiting start modal
	 */
	private hideAwaitingStartModal (stayOnPage: boolean): void {
		if (this.modal) {
			if (stayOnPage) {
				this.modal.close(false);
			} else {
				this.modal.close(true);
			}
		}
	}

	private initLiveStream (): void {
		// Analytics for live sessions
		this.$analytics.eventTrack('join-live', {
			category: 'session',
			label: this.mainController.activity.activity_id + '-' + this.mainController.session.session_id
		});

		// Mobile recordings don't currently publish a video stream so there is no streaming url to fetch.
		if (this.isMobileSession()) {
			return;
		}

		this.liveMedia = this.mainController.session.media;
		// Ensure the media status is ready so that the media
		// preview component will try to play the live stream.
		this.liveMedia.media_status = this.MediaModel.READY;
	}

	/**
	 * Live stream player ready handler
	 */
	private livePlayerReadyHandler (player: GoMediaPlayer): void {
		// This gets called when a live stream ends
		player.on(MediaPlayerEvent.COMPLETE, () => {
			this.timer.pause();
		});

		if (player.getProvider().name === PROVIDERS.OPENTOK) {
			player.on(MediaPlayerEvent.TIME, () => {
				this.timer.setTime(player.getTime());
			});

			// Why? Because we insisted on building our players and everything
			// using no angular, but we also want to build our UI in a way where it's dependent
			// on angular scope digests updating values. We can't have it both ways
			// people!!! Just freaking use angular.
			player.on(MediaPlayerEvent.STATE_CHANGE, () => this.$scope.$evalAsync());

			this.setLiveViewerCount((player as OpenTokStreamSubscriberAdapter).getNumSubscriberConnections());
			player.on(MediaPlayerEvent.SUBSCRIBER_CONNECTION_COUNT, (event) => {
				this.setLiveViewerCount(event.count);
				this.$scope.$evalAsync();
			});
		}
	}

	/**
	 * Time sync event handler
	 */
	private timeSyncHandler (time: number): void {
		const isLiveStreamActive: boolean = !!this.livePlayer && this.livePlayer.isPlaying();
		// account for live stream buffer time
		const liveStreamBufferTime: number = isLiveStreamActive ? 1000 : 0;
		const currentTime: number = time - liveStreamBufferTime;
		this.timer.setTime(currentTime);
	}

	/**
	 * Status change event handler
	 */
	private statusChangeHandler (data): void {
		const status = data.status;
		const oldStatus = data.oldStatus;
		switch (status) {
			case this.Session.LIVE:
				this.timer.start();
				this.recordingManuallyEnded = null;
				break;
			case this.Session.RECORDED:
				this.timer.pause();

				this.$timeout(() => {
					if (this.recordingManuallyEnded === null) {
						this.recordingManuallyEnded = false;
					}
				}, 1000);

				break;
		}

		// Go live means that the session hadn't start yet until now
		const goingLive = oldStatus === this.Session.WAITING && status === this.Session.LIVE;
		if (goingLive) {
			this.initLiveStream();

			// Hide session awaiting start modal
			this.hideAwaitingStartModal(true);

			// Analytics for live sessions
			this.$analytics.eventTrack('join-live', {
				category: 'session',
				label: this.mainController.activity.activity_id + '-' + this.mainController.session.session_id
			});
		}
	}

	/**
	 * Recording manually ended event handler
	 */
	private recordingManuallyEndedHandler (data): void {
		this.recordingManuallyEnded = data;
	}

	/**
	 * Handles live viewer count changes
	 */
	private setLiveViewerCount (count: number): void {
		this.mediaPreviewOptions.liveStreamCount = count;
	}
}
