import type { CreateUpdateMediaParameters } from '../models/media';
import { isEqual, pick } from 'lodash';
import { UmcInstancesService } from 'go-modules/services/umc-instances/umc-instances.service';

interface UMCScope extends ng.IScope {
	disabled: boolean;
	onEngineInit: ({engine: any}) => void;
	onMediaDetermined: ({media: any}) => void;
	close: () => void;
}

export class UniversalMediaChooserController {
	private media: any;
	private pendingMediaSaveRequests: ng.IPromise<any>[];

	/* @ngInject */
	constructor (
		private $scope: UMCScope,
		private MediaModel: any,
		private umcInstancesService: UmcInstancesService,
		private $q: ng.IQService,
		private messageModal
	) {
		this.pendingMediaSaveRequests = [];
	}

	public $onDestroy (): void {
		this.umcInstancesService.unregisterMedia(this.media);
	}

	// Allow getting state of current media without giving the instance to be mutated
	public getMedia (): any {
		return Object.assign({}, this.media);
	}

	/**
	 * Whether the UMC as a whole is a "disabled" state
	 */
	public isDisabled (): boolean {
		return !!this.$scope.disabled;
	}

	/**
	 * Each UMC section has an engine that powers the creation/selection of media content.
	 * When this happens a callback is invoked to give outside scopes access to the engine.
	 */
	public handleEngineInit (engine: any): void {
		this.$scope.onEngineInit({engine});
	}

	/**
	 * Once the user has committed to a particular UMC section,
	 * we can then safely assume that the media has been determined.
	 * We then invoke a callback so that outside scopes are made aware.
	 */
	public handleMediaDetermined (media: any): void {
		this.umcInstancesService.setMediaSpokenFor(media);
		this.$scope.onMediaDetermined({media});
		this.umcInstancesService.cleanupOrphanedMedia();
	}

	/**
	 * Create a single media instance and update as parameters change
	 */
	public createOrUpdatePlaceholderMedia (params: CreateUpdateMediaParameters): ng.IPromise<any> {
		// Create the media if one has not yet been created
		if (!this.media || this.umcInstancesService.isMediaSpokenFor()) {
			if (this.media) {
				this.umcInstancesService.unregisterMedia(this.media);
			}
			this.media = this.MediaModel.model(params);
		}

		// Wait for all pending requests to resolve
		return this.$q.all(this.pendingMediaSaveRequests)
			.then(() => {
				const media: any = pick(this.media, Object.keys(params));
				if (!isEqual(media, params) || !this.media.media_id) {
					Object.assign(this.media, params);
					this.pendingMediaSaveRequests.push(this.media.$save());
				}

				return this.$q.all(this.pendingMediaSaveRequests)
					.then(() => {
						this.media.resource_type = this.media.resource_type || params.resource_type;
						this.umcInstancesService.registerMedia(this.media, params.resource_type);

						if (params.resource_type === 'session') {
							this.umcInstancesService.setMediaSpokenFor(this.media);
						}

						return this.media;
					})
					.catch((error) => {
						let errorMessage;

						if (error.data.errors.hasOwnProperty('add_to_collection')) {
							errorMessage = {
								title: 'modal-umc_add-item-error-title',
								message: 'modal-umc_add-item-to-collection-error-message'
							};
						} else if(error.data.errors.hasOwnProperty('add_to_folder')) {
							errorMessage = {
								title: 'modal-umc_add-item-error-title',
								message: 'modal-umc_add-item-to-collection-folder-error-message'
							};
						}

						const modalData = {
							title: errorMessage.title,
							message: errorMessage.message,
							resolveBtnClass: 'primary-btn'
						};
						this.messageModal.open({
							modalData
						})
							.result.then(() => {
								this.$scope.close();
							});
						return error;
					});
			});
	}
}
