import * as angular from 'angular';
import * as angularResource from 'angular-resource';
import * as angularTranslate from 'angular-translate';

import { common } from '../common';
import { sessionManager } from '../../session-manager';

import { User } from './user.factory';
import { userModelTransformer } from './user-transformer.service';
import { UserService } from './user.service';

export const userModel = angular.module('go.models.user', [
	angularResource,
	angularTranslate,
	common,
	sessionManager
])
	.service('userModelTransformer', userModelTransformer)
	.service(UserService.NG1_INJECTION_NAME, UserService)
	.factory(User.NG1_INJECTION_NAME, User)
	.name;
