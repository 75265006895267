export const TEASE_WALL_CONFIG = 'TEASE_WALL_CONFIG';
export const TEASE_DATA_LOADING = 'TEASE_DATA_LOADING';
export const TEASE_WALL_HIDDEN = 'TEASE_WALL_HIDDEN';
export const TEASE_WALL_WRAPPER_CLASS = 'tease-wall-wrapper';
export const TEASE_WALL_INDEX_ATTRIBUTE = 'tease-wall-blur';
export const TEASE_WALL_BLUR_CLASS = 'tease-wall-blur';
export const TEASE_WALL_BLUR_CLASS_SELECTOR = '.tease-wall-blur';
export const TEASE_WALL_BETA_REQUEST_REMEMBER_KEY= 'ai-beta-request';
export const TEASE_WALL_UPGRADE_REMEMBER_KEY= 'ai-upgrade-request';
export const TEASE_WALL_PURCHASE_REMEMBER_KEY = 'ai-purchase-request';
