import * as Mousetrap from 'mousetrap';

/* @ngInject */
export const goHotKeysDef = function ($rootScope) {
	//should return false if you want to stop propagation of other events
	const add = function add (combo, propagate: boolean, callback) {
			Mousetrap.bind(combo, function () {
				$rootScope.$apply(callback);
				return propagate;
			});
		},

		factory = {
			add
		};

	return factory;
};
