import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';

export class FeatureSupport {

	/** @ngInject */
	constructor (private UADetect: UADetectClass) {}

	public areBrowserRequirementsMetForRecording (): boolean {
		return !this.UADetect.browserDetector.isSafari(/* minVersion */ null, /* maxVersion */ '12.1')
			&& !this.UADetect.browserDetector.isMobileSafari(/* minVersion */ null, /* maxVersion */ '12.1')
			&& !this.UADetect.browserDetector.isIOSNonSafari(/* No need for min or max version here */)
			&& !this.UADetect.browserDetector.isOldEdge();
	}

	public isIOSBrowserNonSafari (): boolean {
		return this.UADetect.browserDetector.isIOSNonSafari();
	}

	public get browser () {
		return this.UADetect.browserDetector.browser;
	}
}
