import {
	Input,
	OnChanges,
	ChangeDetectionStrategy,
	Component,
	Injector,
	ViewChild,
	SimpleChanges,
	OnInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { CommentFilterService } from 'ngx/go-modules/src/services/comment-filter/comment-filter.service';

@Component({
	selector: 'comment-filter-dropdown',
	template: require('./comment-filter-dropdown.component.html'),
	styles: [require('./comment-filter-dropdown.component.scss')],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentFilterDropdownComponent implements OnInit, OnChanges {
	@Input() public uniqueUsers = [];
	public form = new FormGroup({
		selectedUsers: new FormControl([0])
	});
	@ViewChild('allSelected') private allSelected: MatOption;

	constructor (
		public commentFilterService: CommentFilterService,
		private injector: Injector
	) {}

	public ngOnInit () {
		// select all when unique users are first populated
		this.form.controls.selectedUsers.patchValue([
			...this.uniqueUsers.map((user) => user.id), 0
		]);
		this.commentFilterService.selectedUserIds = [];
	}

	public ngOnChanges (changes: SimpleChanges) {
		if (changes.uniqueUsers) {
			// as well as automatically select new users when new comments are added
			if (changes.uniqueUsers.currentValue.length > changes.uniqueUsers.previousValue.length) {
				const newUsers = changes.uniqueUsers.currentValue.filter((user) => {
					return !changes.uniqueUsers.previousValue.some((prevUser) => prevUser.id === user.id);
				});
				this.form.controls.selectedUsers.patchValue([
					...this.form.controls.selectedUsers.value,
					...newUsers.map((user) => user.id)
				]);
				this.commentFilterService.selectedUserIds = this.form.controls.selectedUsers.value;
			// remove users from selected users when unique users are removed
			} else if (changes.uniqueUsers.currentValue.length < changes.uniqueUsers.previousValue.length) {
				const removedUsers = changes.uniqueUsers.previousValue.filter((user) => {
					return !changes.uniqueUsers.currentValue.some((currentUser) => currentUser.id === user.id);
				});
				this.form.controls.selectedUsers.patchValue(
					this.form.controls.selectedUsers.value.filter((id) => !removedUsers.some((user) => user.id === id))
				);
				// if no user is selected now, then select the first one
				if (this.form.controls.selectedUsers.value.length === 0) {
					this.form.controls.selectedUsers.patchValue([this.uniqueUsers[0].id]);
				}
				this.commentFilterService.selectedUserIds = this.form.controls.selectedUsers.value;
			}
		}
	}

	public toggleOne () {
		if (this.allSelected.selected) {
			this.allSelected.deselect();
			this.commentFilterService.selectedUserIds = this.form.controls.selectedUsers.value;
		} else {
			if (this.form.controls.selectedUsers.value.length === this.uniqueUsers.length ||
				this.form.controls.selectedUsers.value.length === 0) {
				this.allSelected.select();
				this.form.controls.selectedUsers
					.patchValue([...this.uniqueUsers.map((user) => user.id), 0]);
				this.commentFilterService.selectedUserIds = [];
			} else {
				this.commentFilterService.selectedUserIds = this.form.controls.selectedUsers.value;
			}
		}
		// trigger ng1 digest cycle
		this.injector.get('$rootScope').$new().$evalAsync();
	}

	public toggleAll () {
		if (this.allSelected.selected) {
			this.form.controls.selectedUsers
				.patchValue([...this.uniqueUsers.map((user) => user.id), 0]);
			this.commentFilterService.selectedUserIds = [];
		} else {
			// we dont allow users to unselect all so select first user
			this.form.controls.selectedUsers.patchValue([this.uniqueUsers[0].id]);
			this.commentFilterService.selectedUserIds = [this.uniqueUsers[0].id];
		}
		// trigger ng1 digest cycle
		this.injector.get('$rootScope').$new().$evalAsync();
	}
}
