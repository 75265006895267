import * as angular from 'angular';
import { PresenterListComponent } from './presenter-list.component';
import { userInitialsFormatFilter } from './user-initials-format.filter';
import { userFirstNameFormatFilter } from './user-firstname-format.filter';
import { templates } from './templates';
import { userModel } from '../models/user';

export const presenterListModule = angular.module('go.presenter-list', [
	userModel
])
	.run(templates)
	.component('presenterList', PresenterListComponent)
	.filter('userInitialsFormat', userInitialsFormatFilter)
	.filter('userFirstNameFormat', userFirstNameFormatFilter)
	.name;
