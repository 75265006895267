import { SessionEditorModalController } from './modal.controller';
import template from './modal.html';

/* @ngInject */
export function sessionEditorModal (goModalBootstrap) {

	function open (options: any = {}) {
		options.template = template;
		options.controller = SessionEditorModalController;
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.windowClass = options.name = 'session-editor-modal';
		options.keyboard = false;
		options.backdrop = 'static';
		options.goreact = true; // For new modal styles
		return goModalBootstrap.open(options);
	}

	return {
		open
	};
}
