import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { common } from '../common';
import { UserTourTransformer } from './tour.transformer';
import { UserTourFactory } from './tour.factory';

export const tourModelModule = angular.module('go.models.userTour', [
	angularResource,
	common
])
	.factory('UserTour', UserTourFactory)
	.service('userTourTransformer', UserTourTransformer)
	.name;
