import template from './player-controls-settings.component.html';
import { PlayerControlsSettingsController as controller } from './player-controls-settings.controller';
import './style.less';

export const playerControllsSettingsComponent = {
	template,
	controller,
	require: {
		playerControlsCtrl: '^player-controls'
	}
};
