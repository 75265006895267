export class ActivityInfoInstructionsController {

	public more: boolean;
	public showMoreLabel: boolean;
	public charLimit: number;

	// bindings
	public text: string;
	public kind: string;

	/* @ngInject */
	constructor (
		private activityInfoConfig,
		private $element,
		private $sanitize
	) {}

	public $onInit () {
		this.more = false;
		this.charLimit = this.activityInfoConfig.instructions.charLimit;
		this.showMoreLabel = this.text.length > this.charLimit;
	}

	public toggle () {
		this.more = !this.more;
		if (this.more) {
			this.charLimit = this.text.length;
		} else {
			this.charLimit = this.activityInfoConfig.instructions.charLimit;
		}

		this.$element[0].querySelector('.instructions-text').focus();
	}

	public returnAsTrusted (): string {
		if (this.text) {
			return this.$sanitize(this.text);
		}
	}

	public containsHtml (): boolean {
		return this.text && this.text.includes('<p');
	}

}
