import { Injectable } from '@angular/core';
import { ENVIRONMENTS } from './environments';

enum EnvironmentVars {
	READONLY = 'readonly',
	ENVIRONMENT = 'environment',
	WHITELABEL = 'whitelabel',
	DISABLE_ACTIVITY_EDITING = 'disableActivityEditing',
	EXTRA_SESSION_DATA = 'extraSessionData',
	USER_PUBNUB_CHANNEL = 'userPubnubChannel',
	CUSTOM_PARAMS = 'customParams',
	FOLLOW_LTI_RETURN_URL = 'followLtiReturnUrl',
	LAUNCH_PRESENTATION_RETURN_URL = 'launchPresentationReturnUrl'
}

@Injectable({
	providedIn: 'root'
})
export class EnvironmentVarsService {
	public static VAR = EnvironmentVars;
	private static instance: EnvironmentVarsService;

	constructor () { }

	public static getInstance (): EnvironmentVarsService {
		if (!EnvironmentVarsService.instance) {
			EnvironmentVarsService.instance = new EnvironmentVarsService();
		}
		return EnvironmentVarsService.instance;
	}

	public set (key: string, value: any) {
		const environmentVars = this.getEnvironmentVars();
		environmentVars[key] = value;
		this.setEnvironmentVars(environmentVars);
	}

	public get (key: string) {
		const environmentVars = this.getEnvironmentVars();
		return environmentVars[key];
	}

	public reset () {
		const environmentVars = this.getEnvironmentVars();
		Object.keys(environmentVars).forEach( (key) => {
			this.set(key, null);
		});
	}

	public environmentIs (environment: ENVIRONMENTS) {
		const environmentVars = this.get(EnvironmentVarsService.VAR.ENVIRONMENT);

		return environment === environmentVars?.name;
	}

	private getEnvironmentVars () {
		const environmentVars = sessionStorage.getItem('environment-vars');
		return environmentVars ? JSON.parse(environmentVars) : {};
	}

	private setEnvironmentVars (updatedValues: any) {
		sessionStorage.setItem('environment-vars', JSON.stringify(updatedValues));
	}
}
