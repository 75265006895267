import { clientSettings } from 'go-modules/models/common/client.settings';
import { UserService } from 'go-modules/models/user/user.service';
import { Recording, ZoomRecordingsResponse } from './recording';

export interface QueueImportParams {
	group_id: number;
	download_url: string;
	resource_id?: number;
	resource_type?: string;
}

/* @ngInject */
export class ZoomService {
	private authenticated: boolean = false;
	private recordings: Recording[] = [];

	constructor (
		private $http: ng.IHttpService,
		private userService: UserService
	) {}

	public isAuthenticated (): boolean {
		return this.authenticated;
	}

	public getLoginUrl (): ng.IPromise<any> {
		return this.$http.get(`${clientSettings.GoReactV2API}/zoom/login-url`);
	}

	public getRecordings (nextPageToken: string = ''): ng.IPromise<any> {
		return this.$http.get(`${clientSettings.GoReactV2API}/zoom/recordings?next_page_token=${nextPageToken}`)
			.then((response: ng.IHttpResponse<ZoomRecordingsResponse>) => {
				this.authenticated = true;
				this.recordings.push(...response.data.recordings);
				response.data.recordings = this.recordings;
				return response.data;
			}).catch((error) => {
				this.authenticated = false;
				throw error;
			});
	}

	public queueImport (mediaId: number, params: QueueImportParams): ng.IPromise<any> {
		return this.$http.post(`${clientSettings.GoReactV2API}/zoom/media/${mediaId}/queue-import`, params);
	}

	public disconnect (): ng.IPromise<void> {
		return this.$http.delete(`${clientSettings.GoReactV2API}/zoom/token`)
			.then(() => {
				this.clearRecordings();
				this.authenticated = false;
			});
	}

	public clearRecordings (): void {
		this.recordings = [];
	}
}
