import template from './slide-deck-viewer.component.html';
import { SlideDeckViewerController as controller } from './slide-deck-viewer.controller';

export const slideDeckViewer = {
	template,
	require: {
		mainController: '^feedbackSession',
		contentController: '^feedbackSessionContent',
		multiMediaDisplayController: '^multiMediaDisplay',
		slidePresentationViewerController: '^slidePresentationViewer'
	},
	controller,
	bindings: {
		media: '<',
		uploadAgain: '&'
	}
};
