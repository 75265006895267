import template from './rubric-feedback-viewer.component.html';
import { RubricFeedbackViewerController } from './rubric-feedback-viewer.controller';

export const rubricFeedbackViewerComponent = {
	bindings: {
		session: '<',
		rubricList: '<',
		userGroup: '<',
		activity: '<',
		onRubricAdded: '&',
		onRubricSaved: '&',
		onRubricRemoved: '&'
	},
	controller: RubricFeedbackViewerController,
	template,
	require: {
		contentController: '^feedbackSessionContent'
	}
};
