import * as angular from 'angular';
import * as dayjs from 'dayjs';
import { isEmpty, isObject, cloneDeep, omit } from 'lodash';
import { ActivityInstructionModel } from './activity-instruction';
import { ActivitySourceMediaModel } from './activity-source-media';

/* @ngInject */
export function activityModelTransformer (
	$http,
	ActivityTemplateModel,
	AttachmentModel,
	MediaModel
) {
	const booleanFields = [
		'use_comments',
		'use_score',
		'live_session_enabled',
		'peer_review',
		'public_feedback',
		'has_self_select_source_media',
		'has_response_media',
		'is_individual_graded',
		'is_slides_enabled',
		'is_video_seeking_disabled',
		'is_video_share_enabled',
		'ai_prompts_enabled',
		'ai_prompts_comment_visible',
		'peer_rubric_critique_enabled',
		'self_rubric_critique_enabled',
		'is_points_possible_readonly',
		'is_conversation',
		'is_feedback_published',
		'has_single_recording_attempt',
		'can_reviewers_see_grades',
		'is_source_media_cc_disabled'
	];

	const intFields = [
		'activity_id',
		'sort_index',
		'num_ungraded',
		'num_graded',
		'num_users_submitted',
		'rubric_template_id',
		'peer_rubric_id',
		'time_limit'
	];

	const dateFields = [
		'created_at',
		'available_at',
		'due_at',
		'deleted_at'
	];

	const nonNullOverwriteableFields = [
		'sort_index'
	];

	/**
	 * Transform request data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformRequestData (data) {
		const payload = cloneDeep(
			omit({...data}, ['sessions', 'template'])
		);

		// if recording instructions don't have media id or text, set to false
		if (payload.hasOwnProperty('recording_instructions')) {
			if (isEmpty(payload.recording_instructions)) {
				payload.recording_instructions = false;
			} else if (!payload.recording_instructions.media_id && !payload.recording_instructions.text) {
				payload.recording_instructions = false;
			}
		}

		// if feedback instructions don't have media id or text, set to false
		if (payload.hasOwnProperty('feedback_instructions')) {
			if (isEmpty(payload.feedback_instructions)) {
				payload.feedback_instructions = false;
			} else if (!payload.feedback_instructions.media_id && !payload.feedback_instructions.text) {
				payload.feedback_instructions = false;
			}
		}

		// if source media doesn't have media, set to false
		if (payload.hasOwnProperty('source_media')) {
			if (isEmpty(payload.source_media)) {
				payload.source_media = false;
			} else if (!payload.source_media.media_id) {
				payload.source_media = false;
			}
		}

		// format dates
		angular.forEach(dateFields, (key) => {
			if (payload.hasOwnProperty(key)) {
				if (angular.isDate(payload[key])) {
					payload[key] = dayjs(payload[key]).utc().format('YYYY-MM-DD HH:mm:ss');
				} else {
					payload[key] = '';
				}
			}
		});

		// Strip attachment media object from request
		if (Array.isArray(payload.attachments)) {
			payload.attachments = payload.attachments.map((attachment) => {
				return omit(attachment, 'media');
			});
		}

		return payload;
	}

	/**
	 * Transform request
	 *
	 * @param request
	 * @returns {*}
	 */
	function transformRequest (request) {
		let requestData;

		if (angular.isArray(request)) {
			requestData = [];
			angular.forEach(request, (data) => {
				requestData.push(transformRequestData(data));
			});
		} else {
			requestData = transformRequestData(request);
		}

		return angular.toJson(requestData);
	}

	/**
	 * Transform response data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformResponseData (data) {
		data.num_sessions = data.num_sessions > 0 ? data.num_sessions : 0;

		// convert to booleans
		angular.forEach(booleanFields, (key) => {
			if (data.hasOwnProperty(key)) {
				data[key] = data[key] > 0;
			}
		});

		// Transform the recording instructions into the correct data model
		if (isObject(data.recording_instructions)) {
			data.recording_instructions = ActivityInstructionModel.create(data.recording_instructions);

			if (isObject(data.recording_instructions.media)) {
				data.recording_instructions.media = MediaModel.model(data.recording_instructions.media);
			}
		}

		// Transform the feedback instructions into the correct data model
		if (isObject(data.feedback_instructions)) {
			data.feedback_instructions = ActivityInstructionModel.create(data.feedback_instructions);

			if (isObject(data.feedback_instructions.media)) {
				data.feedback_instructions.media = MediaModel.model(data.feedback_instructions.media);
			}
		}

		// Transform the source media into the correct data model
		if (isObject(data.source_media)) {
			data.source_media = ActivitySourceMediaModel.create(data.source_media);

			if (isObject(data.source_media.media)) {
				data.source_media.media = MediaModel.model(data.source_media.media);
			}
		}

		// Convert each attachment into attachment models
		if (Array.isArray(data.attachments)) {
			data.attachments = data.attachments.map((attachmentData: any) => {
				return new AttachmentModel({
					...attachmentData,
					media: MediaModel.model(attachmentData.media)
				});
			});
		} else {
			data.attachments = [];
		}

		// convert to dates
		angular.forEach(dateFields, (key) => {
			if (data.hasOwnProperty(key)) {
				data[key] = data[key] ? dayjs.utc(data[key]).toDate() : null;
				// handle date_at before 9/4 with 00:00:00 time, set to 11:59:59
				if (key === 'due_at' && dayjs(data[key]).isBefore('2015-09-04') &&
					dayjs(data[key]).hour() === 0 && dayjs(data[key]).minute() === 0) {
					data[key] = dayjs(data[key]).hour(23).minute(59).toDate();
				}
			}
		});

		// convert to ints
		angular.forEach(intFields, (key) => {
			if (data.hasOwnProperty(key) && data[key] != null) {
				data[key] = parseInt(data[key], 10);
			} else if (data.hasOwnProperty(key) && nonNullOverwriteableFields.indexOf(key) >= 0) {
				delete data[key];
			}
		});

		// ensure activity template is an activity template model
		if (angular.isObject(data.template)) {
			data.template = ActivityTemplateModel.model(data.template);
		}

		// ensure total points is a float or null
		if (data.total_score != null) {
			data.total_score = parseFloat(data.total_score);
		}

		return data;
	}

	/**
	 * Transform response
	 *
	 * @param response
	 * @returns {*}
	 */
	function transformResponse (response) {
		if (angular.isArray(response)) {
			angular.forEach(response, (data) => {
				transformResponseData(data);
			});
		} else {
			transformResponseData(response);
		}

		return response;
	}

	return {
		request: transformRequest,
		response: [$http.defaults.transformResponse[0], transformResponse]
	};
}
