import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { time } from '../time';
import { mediaModel } from '../models/media';
import { goPdfModule } from '../go-pdf';
import { MediaThumbnailComponentDef } from './media-thumbnail.component';
import { fallbackIconDirective } from './fallback-icon.directive';
import { mediaThumbnailCoverComponent } from './thumbnail-cover/thumbnail-cover.component';
import { fitTextDirective } from './fit-text.directive';

// TODO: fix circular dependency.
// Until then, we need to import directly to force side-effect of angular module
// loading. If we leave the `fooModule from` part, webpack will use tree-shaking
// and the module will not be loaded.
// eslint-disable-next-line @typescript-eslint/tslint/config
import /*{ mediaPreviewModalModule } from*/ '../modals/media-preview';

import 'bootstrap-css-only';
import './style.less';

export const mediaThumbnailModule = angular.module('go.mediaThumbnail', [
	mediaModel,
	angularTranslate,
	goPdfModule,
	time,

	'modal.media-preview'
])
	.component('mediaThumbnail', MediaThumbnailComponentDef)
	.component('mediaThumbnailCover', mediaThumbnailCoverComponent)
	.directive('fallbackIcon', fallbackIconDirective)
	.directive('fitText', fitTextDirective)
	.name;
