import template from './umc-library.component.html';
import { UniversalMediaChooserLibraryController as controller } from './umc-library.controller';

export default {
	bindings: {
		options: '<',
		umcInstanceName: '@'
	},
	controller,
	require: {
		umcController: '^universalMediaChooser'
	},
	template
};
