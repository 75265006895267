import template from './comment.directive.html';

export const feedbackTreeCommentDirective: ng.IDirectiveFactory = () => {
	return {
		restrict: 'E',
		template,
		controller: 'FeedbackTreeCommentController',
		controllerAs: 'commentController',
		scope: {
			feedbackItem: '=',
			settings: '=',
			feedbackItemController: '=',
			setActive: '&',
			shared: '='
		}
	};
};
