import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';

import { InstructionsPreviewModal } from './modal.service';

import './modal.less';

export const instructionsPreviewModalModule = angular.module('modal.instructions-preview', [
	goModalBootstrapModule,
	angularTranslate
])
	.service('instructionsPreviewModal', InstructionsPreviewModal)
	.name;
