import { clientSettings } from 'go-modules/models/common/client.settings';
import { upgradeNg1Dependency } from 'ngx/go-modules/src/common/ng1-upgrade-factory';

/* @ngInject */
export function TagModel ($resource: ng.resource.IResourceService) {
	const basePath = `${clientSettings.GoReactV2API}/tag-sets/:tag_set_id/tags/:tag_id`;

	const tagModel = $resource<any, any>(basePath, {
		tag_id: '@tag_id', tag_set_id: '@tag_set_id'
	}, {});

	/**
	 * Creates a new tag instance
	 *
	 * @param data
	 * @returns {TagModel}
	 */
	tagModel.model = function (data) {
		return new tagModel(data || {});
	};

	/**
	 *
	 * Get id
	 *
	 * @returns {String}
	 */
	tagModel.prototype.getId = function () {
		return this.tag_id || this.id;
	};

	return tagModel;
}

TagModel.NG1_INJECTION_NAME = 'TagModel' as const;
export const tagModelToken = upgradeNg1Dependency(TagModel);
