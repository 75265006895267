import * as angular from 'angular';
import * as uiDropdown from 'angular-ui-bootstrap/src/dropdown';

import { rubricModule } from '../rubric';
import { richDropdownModule } from '../rich-dropdown';
import { rubricSessionModel } from '../models/rubric-session';
import { rubricTemplateModel } from '../models/rubric-template';
import { groupDepModel } from '../models/group-dep';
import { goStorageModule } from '../go-storage';
import { confirmModalModule } from '../modals/confirm';
import { goPrintModule } from '../go-print';
import { ngxZeroStateModule } from 'ngx/go-modules/src/components/zero-state';

import { rubricFeedbackViewerComponent } from './rubric-feedback-viewer.component';
import { rubricGradeTotalsBarComponent } from './rubric-grade-totals-bar/rubric-grade-totals-bar.component';
import { ngxRubricFilterDropdown } from 'ngx/go-modules/src/components/feedback-session/feedback-filters/rubric-filter';


import './style.less';

export const rubricFeedbackViewerModule = angular.module('rubric-feedback-viewer', [
	richDropdownModule,
	rubricModule,
	rubricSessionModel,
	rubricTemplateModel,
	uiDropdown,
	groupDepModel,
	goStorageModule,
	confirmModalModule,
	goPrintModule,
	ngxRubricFilterDropdown,
	ngxZeroStateModule
])
	.component('rubricFeedbackViewer', rubricFeedbackViewerComponent)
	.component('rubricGradeTotalsBar', rubricGradeTotalsBarComponent)
	.name;
