import { UserNotification, UserNotificationType, UserNotificationHandler } from '../user-notifications.service';
import { IModalInstanceService } from 'angular-ui-bootstrap';

export class GenericEventHandler {

	constructor (protected handler: UserNotificationHandler) {}

	public execute (notification: UserNotification): void {
		switch (notification.type) {
			case UserNotificationType.MODAL:
				this.handleModal(notification);
				break;
		}
	}

	protected handleModal (notification: UserNotification): IModalInstanceService {
		const modalData = notification.data.modalData;
		modalData.message = notification.message;
		return this.handler.openModal(modalData);
	}
}
