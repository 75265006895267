import * as angular from 'angular';
import * as angularCookies from 'angular-cookies';

import { GoStorageFactory } from './storage.factory';

export const goStorageModule = angular.module('storage', [
	angularCookies
])
	.factory('WebStorage', GoStorageFactory)
	.name;
