import './style.less';
import template from './feedback-display-panel.component.html';
import { FeedbackDisplayPanelController as controller } from './feedback-display-panel.controller';

export const FeedbackDisplayPanelComponent = {
	controller,
	template,
	bindings: {
		activity: '<',
		media: '<',
		playerSync: '<',
		sourceMedia: '<',
		canSeeCommentKind: '<',
		canSeeRubricKind: '<',
		canSeeTranscriptions: '<',
		canSeeAnalytics: '<'
	}
};
