import * as angular from 'angular';
import {
	EVENTS as MediaPlayerEvents,
	STATES as MediaPlayerStates
} from '../../media-player';

/* @ngInject */
export const DocumentMediaPlayer = function (EventChannel) {
	function documentMediaPlayer (id, documentViewer) {
		if (!angular.isString(id)) {
			throw new Error('Id must be a string value and cannot be empty');
		} else if (!documentViewer || !angular.isFunction(documentViewer.goto)) {
			throw new Error('A valid document viewer must be supplied');
		}

		const eventChannel = new EventChannel();
		let state = MediaPlayerStates.PLAYING;

		this.id = id;
		this.isDocumentPlayer = true;
		this.play = angular.noop;
		this.pause = angular.noop;
		this.stop = angular.noop;

		// This is the only function that really maps over
		// to the document viewer render page functionality.
		this.seek = (pageNumber) => {
			documentViewer.goto(pageNumber);
			this.trigger(MediaPlayerEvents.REQUEST_SEEK, {
				offset: documentViewer.page,
				duration: this.getDuration()
			});
		};

		this.isBuffering = function () {
			return this.getState() === MediaPlayerStates.BUFFERING;
		};

		this.isPlaying = function () {
			return this.getState() === MediaPlayerStates.PLAYING;
		};

		this.isComplete = function () {
			return documentViewer.page === documentViewer.doc.numPages;
		};

		this.isPaused = function () {
			return false;
		};

		this.getTime = function () {
			return documentViewer.page;
		};

		this.getState = function () {
			return state;
		};

		this.getDuration = function () {
			return documentViewer.doc.numPages;
		};

		this.getPlaylistItem = function () {
			return {file: documentViewer.url};
		};

		this.trigger = eventChannel.broadcast.bind(eventChannel);
		this.on = eventChannel.subscribe.bind(eventChannel);
		this.once = eventChannel.subscribeOnce.bind(eventChannel);
		this.off = eventChannel.unsubscribe.bind(eventChannel);

		/**
		 * Page change event handler
		 */
		const onPageChange = (event) => {
			this.trigger(MediaPlayerEvents.REQUEST_SEEK, {
				offset: event.page,
				duration: event.numPages
			});
		};

		/**
		 * Page rendering event handler
		 */
		const onPageRendering = () => {
			const oldState = state;
			state = MediaPlayerStates.BUFFERING;
			this.trigger(MediaPlayerEvents.BUFFER, {
				oldstate: oldState,
				newstate: state
			});
		};

		/**
		 * Page changed event handler
		 */
		const onPageChanged = () => {
			const oldState = state;
			state = MediaPlayerStates.PLAYING;
			this.trigger(MediaPlayerEvents.PLAY, {
				oldstate: oldState,
				newstate: state
			});

			this.trigger(MediaPlayerEvents.SEEK, {});
		};

		// Clean up events
		this.destroy = function () {
			documentViewer.off('pageChange', onPageChange);
			documentViewer.off('pageRendering', onPageRendering);
			documentViewer.off('pageChanged', onPageChanged);
		};

		// Add page render and change event listeners
		documentViewer.on('pageChange', onPageChange);
		documentViewer.on('pageRendering', onPageRendering);
		documentViewer.on('pageChanged', onPageChanged);
	};

	return documentMediaPlayer;
};
