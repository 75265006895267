export function rubricPlaceholder () {
	return function (val, placeholder) {
		val = (val || '') + '';
		if (!val.length || !val.trim()) {
			placeholder = placeholder || '';
			return placeholder || 'Add label';
		}
		return val + '';
	};
}
