import * as angular from 'angular';
import * as dayjs from 'dayjs';

/* @ngInject */
export function commentModelTransformer (
	$http,
	BaseModel,
	FeedbackNodeModel,
	commentModelSetOwnerService,
	CommentResourceTypes,
	MediaModel,
	TagModel,
	SyncEvent
) {
	/**
	 * Transform request data
	 *
	 * @param data
	 * @returns {Object}
	 */
	function transformRequestData (data) {

		const payload = angular.extend({}, data);

		// Parents and children are recursive structures that
		// cannot be JSON stringified
		if (payload.hasOwnProperty('parent')) {
			delete payload.parent;
		}

		if (payload.hasOwnProperty('children')) {
			delete payload.children;
		}

		// remove irrelevant properties
		angular.forEach([BaseModel.prototype, FeedbackNodeModel.prototype], function (obj) {
			angular.forEach(obj, function (_value, key) {
				if (payload.hasOwnProperty(key)) {
					delete payload[key];
				}
			});
		});

		return payload;
	}

	/**
	 * Transform request
	 *
	 * @param request
	 * @returns {*}
	 */
	function transformRequest (request) {
		let requestData;

		if (angular.isArray(request)) {
			requestData = [];
			angular.forEach(request, function (data) {
				data.push(transformRequestData(data));
			});
		} else {
			requestData = transformRequestData(request);
		}

		return angular.toJson(requestData);
	}

	/**
	 * Transform response data
	 *
	 * @param data
	 * @returns {object}
	 */
	function transformResponseData (data) {

		// add other properties
		data.id = data.comment_id;

		// set owner
		commentModelSetOwnerService(data, data);

		// make sure time is integer
		if (data.time !== null) {
			data.time = parseInt(data.time, 10);
		}

		// created at date string to date object
		if (data.created_at && !angular.isDate(data.created_at)) {
			data.created_at = dayjs.utc(data.created_at).toDate();
		}

		if (data.posted_at && !angular.isDate(data.posted_at)) {
			data.posted_at = dayjs.utc(data.posted_at).toDate();
		}

		if (data.last_modified && !angular.isDate(data.last_modified)) {
			data.last_modified = dayjs.utc(data.last_modified).toDate();
		}

		if (data.deleted_at && !angular.isDate(data.deleted_at)) {
			data.deleted_at = dayjs.utc(data.deleted_at).toDate();
		}

		// When a comment has a resource, ensure that the
		// resource is wrapped with the correct model.
		if (data.resource && data.resource.length) {
			data.resource.forEach((res) => {
				if (res.resource_type === CommentResourceTypes.MEDIA) {
					res.item = MediaModel.model(res.item);
				} else if (res.resource_type === CommentResourceTypes.TAG) {
					res.item = TagModel.model(res.item);
				} else if (res.resource_type === CommentResourceTypes.SYNC_EVENT) {
					res.item = SyncEvent.model(res.item);
				}
			});
		}

		return data;
	}

	/**
	 * Transform response
	 *
	 * @param response
	 * @returns {*}
	 */
	function transformResponse (response) {
		if (angular.isArray(response)) {
			angular.forEach(response, function (data) {
				transformResponseData(data);
			});
		} else {
			transformResponseData(response);
		}
		return response;
	}

	return {
		request: transformRequest,
		response: [$http.defaults.transformResponse[0], transformResponse]
	};
}
