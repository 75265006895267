import { ActivityInfoController } from '../activity-info.controller';
import { ActivityInstructionModel } from 'go-modules/models/activity';

export class ActivityInfoMediaPreviewController {

	// bindings
	public media: any;
	public activityInfoController: ActivityInfoController;
	public kind: string;
	public ariaLabelKey: string;

	/* @ngInject */
	constructor (private MediaModel) {}

	/**
	 * Handles init life cycle hook
	 */
	public $onInit () {
		if (!(this.media instanceof this.MediaModel)) {
			throw new Error('Parameter `media` is required by ActivityInfoMediaPreviewController');
		}

		this.ariaLabelKey = ActivityInstructionModel.TYPE.RECORDING === this.kind ?
			'activity-info_recording-instructions' : ActivityInstructionModel.TYPE.FEEDBACK === this.kind ?
				'activity-info_feedback-instructions' :
				null;
	}

	/**
	 * Whether media preview should be prevented
	 *
	 * @returns {boolean}
	 */
	public shouldPreventMediaPreview (): boolean {
		return this.activityInfoController.activity.has_single_recording_attempt &&
			!this.activityInfoController.group.hasInstructorRole(true);
	}

	/**
	 * Whether media download should be prevented
	 *
	 * @returns {boolean}
	 */
	public shouldPreventMediaDownload (): boolean {
		return !this.activityInfoController.group.hasInstructorRole(true);
	}
}
