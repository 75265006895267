import template from './modal.html';

export class VideoShareEmailErrorModal {

	/* @ngInject */
	constructor (private goModalBootstrap) {}

	public open (options) {
		options.template = template;
		options.controller = function () {};
		options.windowClass = options.name = 'video-share-email-error';
		options.keyboard = false;
		options.backdrop = 'static';
		options.controllerAs = '$ctrl';
		options.bindToController = true;
		options.goreact = true; // For new modal styles
		return this.goModalBootstrap.open(options);
	}
}
