import * as angular from 'angular';

/* @ngInject */
export const fallbackIconDirective: ng.IDirectiveFactory = ($animate, $interpolate, $compile) => {

	function compile (_tElem, tAttrs) {
		let interpolateFn = angular.noop;
		if (tAttrs.ngSrc) {
			interpolateFn = $interpolate(tAttrs.ngSrc);
		} else if (tAttrs.src) {
			interpolateFn = $interpolate(tAttrs.src);
		}

		return function link (scope, elem, attr, _ctrls, transcludeFn) {
			let transElem, transScope;
			function removePrevTransclude () {
				if (transElem) {
					$animate.leave(transElem);
					transElem = null;
				}

				if (transScope) {
					transScope.$destroy();
					transScope = null;
				}
			}

			function transcludeFallback () {
				removePrevTransclude();
				transElem = $compile('<span fit-text></span>')(scope);
				transElem.addClass(attr.fallbackIcon);
				transElem.addClass('fallback-icon');
				$animate.enter(transElem, elem.parent(), elem);
			}

			function transcludeOriginal (newValue) {
				if (!newValue) {
					return transcludeFallback();
				}

				removePrevTransclude();
				transScope = scope.$new();
				transcludeFn(transScope, function (clone) {
					transElem = clone;
					transElem[0].style.display = 'none';
					transElem.on('load', function () {
						transElem[0].style.display = 'block';
						scope.$evalAsync(tAttrs.onLoaded);
					});
					transElem.bind('error', function () {
						transcludeFallback();
					});
					$animate.enter(transElem, elem.parent(), elem);
				});
			}

			scope.$watch(interpolateFn, transcludeOriginal);
		};
	}

	return {
		restrict: 'A',
		priority: 101, // it needs to run before the attributes are interpolated
		transclude: 'element',
		compile
	} as ng.IDirective;
};
