import type { HttpErrorResponse } from '@angular/common/http';
import * as angular from 'angular';
import { EmailVerificationDialogComponent } from 'ngx/go-modules/src/components/email-verification/email-verification-dialog/email-verification-dialog.component';
import { NgxAuthService } from 'ngx/go-modules/src/services/auth/auth.service';
import { GoModalService } from 'ngx/go-modules/src/services/go-modal/go-modal.service';
import { AccountLockoutModal } from 'go-modules/modals/account-lockout/modal.factory';

export class ChangeEmailModalController {

	public user: any;
	public $close: (user) => void;
	public error: any;
	public loading: boolean = false;
	public currentEmail = null;
	public emailSignature = null;

	/* @ngInject */
	constructor (
		private $document: ng.IDocumentService,
		private ngxGoModalService: GoModalService,
		private ngxAuthService: NgxAuthService,
		private $translate: ng.translate.ITranslateService,
		private $q: angular.IQService,
		private $scope,
		public accountLockoutModal: AccountLockoutModal
	) {}

	/**
	 * Handles form submission
	 */
	public onSubmit (form: ng.IFormController) {
		// Don't submit when form is invalid
		if (form.$invalid) {
			const input = this.$document[0].querySelector('.modal.change-email input.ng-invalid') as HTMLInputElement;
			input.focus();
			return;
		}

		this.error = null;
		if(this.currentEmail !== form.email) {
			this.ngxAuthService.checkPassword(form.password).then((result) => {
				if (!result.passwordMatched) {
					this.error = { message: 'common-validation_incorrect-password' };
					this.$document[0].querySelector<HTMLInputElement>('.modal.change-email input#current-password').focus();
					this.$scope.$evalAsync();
					return;
				}

				const dialogRef = this.ngxGoModalService.open(
					EmailVerificationDialogComponent,
					false,
					{ data: {email: form.email} }
				);

				const overlayContainer = angular.element(document.querySelector('.cdk-overlay-container'));
				// This class is for a problem when different kinds of modals overlap but the right one isn't on top
				overlayContainer.addClass('bring-to-top');

				dialogRef.afterClosed().subscribe((value) => {
					if (value) {
						this.changeEmail(form, value.email_signature);
					}
					overlayContainer.removeClass('bring-to-top');
				});
			}).catch((error) => {
				if (error.error?.remainingAccountLockSeconds > 0) {
					const modalData = {
						message: this.$translate.instant('modal-account-locked-message', {
							number: Math.ceil(error.error.remainingAccountLockSeconds / 60)
						})
					};
					this.accountLockoutModal.open({ modalData });
				}
			});
		} else {
			this.changeEmail(form, this.emailSignature);
		}
	};

	public changeEmail (form, emailSignature) {
		this.loading = true;
		this.user.email = form.email;
		this.user.password = form.password;
		this.currentEmail = form.email;
		this.emailSignature = emailSignature;
		this.$scope.$evalAsync();

		this.$q.when(this.ngxAuthService.changeEmail(this.user.getId(), {
			email: form.email,
			password: form.password,
			email_signature: emailSignature
		})).then(() => {
			this.$close(this.user);
		}).catch((httpError: HttpErrorResponse) => {
			this.error = this.getError(httpError);
		}).finally(() => {
			this.loading = false;
		});
	}

	public getError (httpError: HttpErrorResponse) {
		const response = httpError.error;
		const errorKeys = Object.keys(response.errors ?? {});
		let message = errorKeys.length > 0 ? response.errors[errorKeys[0]][0] : response.message;

		switch(message) {
			case 'The email has already been taken.':
				message = 'account-editor_duplicate-email';

				break;
			case 'The email must be a valid email address.':
				message = 'common-validation_email';

				break;
			default:
				return {unknown: response.message};
		}

		return {message};
	}
}
