import { SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import { logError } from 'go-modules/logger/logger';

export const enum SegmentationStates {
	UNINITIALIZED = 'uninitialized',
	INITIALIZING = 'initializing',
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	FAILED = 'failed'
}

export interface BackgroundBlurConfig {
	video: HTMLVideoElement;
	width: number;
	height: number;
}

export class BackgroundBlur {
	private config: BackgroundBlurConfig;
	private video: HTMLVideoElement;
	private segmentationState: SegmentationStates = SegmentationStates.UNINITIALIZED;
	private segmentation: SelfieSegmentation;
	private isBlurred: boolean = false;
	private canvas: OffscreenCanvas;
	private ctx: OffscreenCanvasRenderingContext2D;
	private interval: NodeJS.Timeout;

	constructor (config: BackgroundBlurConfig) {
		this.config = config;
	}

	public destroy (): void {
		clearInterval(this.interval);
		this.segmentation?.close();
	}

	public async toggleBlur (): Promise<void> {
		this.isBlurred = !this.isBlurred;
		if (this.isBlurred) {
			if (this.segmentationState === SegmentationStates.UNINITIALIZED) {
				try {
					await this.init();
				} catch (error) {
					this.isBlurred = false;
					this.segmentationState = SegmentationStates.FAILED;
					return;
				}
			}
			this.segmentationState = SegmentationStates.ACTIVE;
			await this.segmentation.send({ image: this.video });
			this.render();
		} else {
			this.stopRender();
		}
	}

	public getSegmentationState (): SegmentationStates {
		return this.segmentationState;
	}

	public getCanvas (): OffscreenCanvas {
		return this.canvas;
	}

	private async init (): Promise<void> {
		this.segmentationState = SegmentationStates.INITIALIZING;
		this.video = this.config.video;
		this.canvas = new OffscreenCanvas(this.config.width, this.config.height);
		this.ctx = this.canvas.getContext('2d');
		this.segmentation = this.getSegmentation();
		this.segmentation.setOptions({ modelSelection: 1 });
		this.segmentation.onResults((results) => this.onResults(results));
		await this.segmentation.initialize();
	}

	// This method exists so I can replace the return value with a mock in test contexts
	private getSegmentation (): SelfieSegmentation {
		return new SelfieSegmentation({locateFile: (file) => {
			return `https://staticassets.goreact.com/media-pipe/selfie-segmentation/${file}`;
		}});
	}

	/**
	 * Backgrounded tabs throttle requestAnimationFrame
	 * even when that tab has audio playing.
	 * setInterval does not have this issue.
	 */
	private async render () {
		this.interval = setInterval(async () => {
			try {
				await this.segmentation.send({ image: this.video });
			} catch (error) {
				this.stopRender();
				logError(error);
			}
		}, 1000 / 30);
	}

	private stopRender () {
		clearInterval(this.interval);
		this.segmentationState = SegmentationStates.INACTIVE;
	}

	private onResults (results): void {
		// Save the context's state
		this.ctx.save();

		// Draw the raw frame
		this.ctx.drawImage(results.image, 0, 0, this.canvas.width, this.canvas.height);

		// Make all pixels not in the segmentation mask transparent
		this.ctx.globalCompositeOperation = 'destination-atop';
		this.ctx.drawImage(results.segmentationMask, 0, 0, this.canvas.width, this.canvas.height);

		// Blur the context for all subsequent draws then set the raw image as the background
		this.ctx.filter = 'blur(16px)';
		this.ctx.globalCompositeOperation = 'destination-over';
		this.ctx.drawImage(results.image, 0, 0, this.canvas.width, this.canvas.height);

		// Restore the context's state
		this.ctx.restore();
	}
}
