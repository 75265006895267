import { pipe, scan, startWith } from 'rxjs';
import type { OperatorFunction } from 'rxjs';


/**
 * Allows you to accumulate emitted changes into an array
 *
 * For example, we have an observable called add$. By doing:
 * switchMap((existing) => {
 *     return add$.pipe(
 *         accumulate(),
 *         map((added) => {
 *             existing.concat(added);
 *         })
 *     );
 * });
 * Then each time the add$ observable has a value sent through, the value is
 * accumulated in the added list, which is then mapped to be combined with the existing list.
 * Each time the existing observable changes, this accumulation will reset. This allows
 * the client side to modify existing observable to match any backend actions until the backend is reloaded.
 */
export function accumulateArr<T> (): OperatorFunction<T, T[]> {
	return pipe(
		scan((acc: T[], value: T) => acc.concat(value), [] as T[]),
		startWith([] as T[])
	);
}

// Make sure we are keying by a property who's value can also be a key
type PropertyValueKeys<T> = {[K in keyof T]: T[K] extends PropertyKey ? K : never}[keyof T];

/**
 * Allows you to accumulate emitted changes into a map
 *
 * For example, we have an observable called edit$. By doing:
 * switchMap((existing) => {
 *     return edit$.pipe(
 *         accumulate('id'),
 *         map((editedMap) => {
 *             existing.map((e) => editedMap[e.id] ?? e)
 *         })
 *     );
 * });
 * Then each time the edit$ observable has a value sent through, the value is
 * accumulated in the edited map, by id, which is then mapped to be combined with the existing list.
 * Each time the existing observable changes, this accumulation will reset. This allows
 * the client side to modify existing observable to match any backend actions until the backend is reloaded.
 */
export function accumulateMap<T extends Record<PropertyKey, any>, K extends PropertyValueKeys<T>> (
	keyBy: K
): OperatorFunction<T, Record<T[K], T>> {
	return pipe(
		scan((acc: Record<T[K], T>, value: T) => ({
			...acc,
			[value[keyBy]]: value
		}), {} as Record<T[K], T>),
		startWith({} as Record<T[K], T>)
	);
}
