/* eslint-disable @typescript-eslint/no-use-before-define */
import * as angular from 'angular';
import { debounce } from 'lodash';
import { TimerBase } from '../timer';
import {
	EVENTS as MediaPlayerEvents,
	STATES as MediaPlayerStates,
	GoMediaPlayer
} from '../media-player';

/**
 * This represents an individual track in player sync.
 * A track consists of a player and set of sync events
 * with a master timer to uphold the contract between
 * the player and the individual sync events.
 *
 * TODO: Some of the track logic checks for the existence of a
 * document player. In the future, it would be great to accomplish
 * this task using polymorphism.
 */

/* @ngInject */
export const PlayerSyncTrack = function ($q, $log, SyncEvent, Timer, Time, EventChannel) {
	// Out of sync threshold (in milliseconds)
	Track.VIDEO_OUT_OF_SYNC_THRESHOLD = 650;
	Track.DOCUMENT_OUT_OF_SYNC_THRESHOLD = 0;

	// Supported modes.
	// For now asynchronous capture mode
	// just means that we can capture events
	// while the master timer is paused.
	Track.MODE = {
		PLAYBACK: 'playback',
		SYNCHRONOUS_CAPTURE: 'synchronousCapture',
		ASYNCHRONOUS_CAPTURE: 'asynchronousCapture'
	};

	// List of supported modes
	Track.MODES = [];
	for (const key in Track.MODE) {
		if (Track.MODE.hasOwnProperty(key)) {
			Track.MODES.push(Track.MODE[key]);
		}
	}

	// Events
	Track.EVENT = {
		INTERRUPT: 'interrupt',
		INTERRUPT_COMPLETE: 'interruptComplete',
		SYNC_EVENT_EXECUTED: 'syncEventExecuted',
		SYNC_EVENT_ADDED: 'syncEventAdded',
		SYNC_EVENT_UPDATED: 'syncEventUpdated',
		SYNC_EVENT_REMOVED: 'syncEventRemoved'
	};

	/**
	 * Track
	 *
	 * @param number
	 * @param masterTimer
	 * @param player
	 * @param syncEvents
	 * @constructor
	 */
	function Track (number, masterTimer, playerOrPromise: ng.IPromise<GoMediaPlayer>|GoMediaPlayer, syncEvents) {
		const vm = this,
			deferred = $q.defer();

		if (!isInteger(number)) {
			throw new Error('Track number must be an integer');
		} else if (number < 0) {
			throw new Error('Track number must be greater than zero');
		} else if (!(masterTimer instanceof Timer) && !(masterTimer instanceof TimerBase)) {
			throw new Error('Invalid master timer instance');
		} else if (!angular.isArray(syncEvents)) {
			throw new Error('Sync events must be an array');
		}

		this.number = number;
		this.masterTimer = masterTimer;
		this.player = playerOrPromise;

		// Create a new array reference with the same items.
		// This will prevent writing back to the supplied instance.
		this.syncEvents = [].concat(syncEvents);

		// Publish / subscribe
		const eventChannel = new EventChannel();
		this.trigger = eventChannel.broadcast.bind(eventChannel);
		this.on = eventChannel.subscribe.bind(eventChannel);
		this.once = eventChannel.subscribeOnce.bind(eventChannel);
		this.off = eventChannel.unsubscribe.bind(eventChannel);

		// For determining if track is ready
		this.$promise = deferred.promise;
		this.$resolved = false;

		// Immediately validate the order of the sync events
		this.validateSortOrder();

		// Start out in playback mode
		this.setMode(Track.MODE.PLAYBACK);

		// Set instance-safe validate and executeSyncEvent method
		this.validate = debounce(validate.bind(this));
		this.executeSyncEvent = debounce(executeSyncEvent.bind(this));

		// Wait for master timer and player to
		// resolve before we set capture mode
		// and resolve track promise.
		$q.all({
			masterTimer: masterTimer.$promise,
			player: playerOrPromise
		}).then((result) => {
			this.player = result.player;
			vm.trackEndTime = getMasterTimeTrackShouldEndAt(vm);
			vm.$resolved = true;
			deferred.resolve(vm);
		});
	}

	// Current sync event
	Track.prototype.currentSyncEvent = null;

	// Upcoming sync event index that will get executed
	Track.prototype.nextSyncEventIndex = 0;

	// Current mode
	Track.prototype.mode = undefined;

	// Dummy sync event
	Track.prototype.dummySyncEvent = null;

	// For cleaning up event handlers
	Track.prototype.cleanupEvents = angular.noop;

	/**
	 * Capture mode change event handler.
	 *
	 * This function is debounced to prevent race conditions.
	 * Every time the mode changes we have to initialize event
	 * handlers that change the state of the track. i.e. before
	 * it was debounced, the mode would get set to playback mode
	 * and then immediately to capture mode which would cause
	 * the validate function below to get called and fiddle with
	 * capture mode.
	 */
	const onModeChange = debounce(function (track) {
		$log.info('MODE CHANGE', track.mode, track.syncEvents);

		// Clean up existing events
		track.cleanupEvents();

		// Immediately hard validate the track
		track.validate(true);

		// Initialize event handlers
		if (track.isCaptureMode()) {
			// Add capture mode event listeners
			track.cleanupEvents = initCaptureModeEventHandlers(track);
		} else {
			// Add playback mode event listeners for handling synchronization
			track.cleanupEvents = initPlaybackModeEventHandlers(track);
		}
	});

	/**
	 * Set the current mode.
	 *
	 * If set to one of the capture modes, this means that we will be
	 * listening for events that change the state of the player while
	 * simultaneously recording synchronization events that can be used
	 * later to play back the original events that occurred.
	 *
	 * @param mode
	 */
	Track.prototype.setMode = function (mode) {
		const vm = this;

		// Check to see if requested capture mode is valid
		if (Track.MODES.indexOf(mode) === -1) {
			throw new Error('The supplied mode is not supported!');
		}

		// First check to make sure that we
		// aren't already in capture mode.
		if (vm.mode !== mode) {
			vm.mode = mode;
			vm.$promise.then(function () {
				onModeChange(vm);
			});
		}
	};

	/**
	 * A helper function for determining the mode
	 *
	 * @returns {boolean}
	 */
	Track.prototype.isMode = function (name) {
		return this.mode === name;
	};

	/**
	 * Whether one of the supported capture modes is enabled
	 *
	 * @returns {boolean}
	 */
	Track.prototype.isCaptureMode = function () {
		return this.isMode(Track.MODE.SYNCHRONOUS_CAPTURE) || this.isMode(Track.MODE.ASYNCHRONOUS_CAPTURE);
	};

	/**
	 * Toggle between playback and asynchronous capture mode.
	 *
	 * By setting asynchronous capture mode, this will allow this track
	 * to capture events that occur when the master timer isn't running.
	 *
	 * @param mode
	 */
	Track.prototype.toggleAsyncCaptureMode = function (mode) {
		if (angular.isUndefined(mode)) {
			mode = !this.isCaptureMode() ? Track.MODE.ASYNCHRONOUS_CAPTURE : Track.MODE.PLAYBACK;
		}
		this.setMode(mode);
	};

	/**
	 * Returns the dummy sync event.
	 *
	 * The dummy sync event is essentially a "start" sync event.
	 * It should exist in the sync events array at the beginning
	 * of the sync events array.
	 *
	 * @returns {SyncEvent}
	 */
	Track.prototype.getDummySyncEvent = function () {
		if (!this.dummySyncEvent) {
			if (this.player.isDocumentPlayer) {
				this.dummySyncEvent = SyncEvent.model({
					track_number: this.number,
					action: SyncEvent.ACTION.SEEK,
					master_time: 0,
					time: 1 // Start out on slide 1
				});
			} else {
				this.dummySyncEvent = SyncEvent.model({
					track_number: this.number,
					action: SyncEvent.ACTION.PLAY,
					master_time: 0,
					time: 0
				});
			}
			this.dummySyncEvent.isDummy = true;
		}

		return this.dummySyncEvent;
	};

	/**
	 * Whether a given sync event is in fact the dummy sync event
	 *
	 * @param syncEvent
	 * @returns {boolean}
	 */
	Track.prototype.isDummySyncEvent = function (syncEvent) {
		return this.getDummySyncEvent() === syncEvent;
	};

	/**
	 * The dummy sync event always acts as a start event if none
	 * is present at master timer position 0. This function ensures
	 * that the dummy sync event is there when it is required.
	 */
	Track.prototype.validateDummySyncEvent = function () {
		// Fetch dummy sync event
		const dummySyncEvent = this.getDummySyncEvent(),
			dummySyncEventIndex = this.syncEvents.indexOf(dummySyncEvent);

		// First, remove the dummy sync event if it exists in the array
		if (dummySyncEventIndex >= 0) {
			this.syncEvents.splice(dummySyncEventIndex, 1);
		}

		// Second, determine whether the dummy sync event
		// should be added. Note, the goal is to always
		// have a sync event at master time zero.
		if (!this.syncEvents.length) {
			// For video players only. When there are no sync events,
			// the start event should always be a play action.
			if (!this.player.isDocumentPlayer) {
				dummySyncEvent.setAction(SyncEvent.ACTION.PLAY);
			}
			this.syncEvents.unshift(dummySyncEvent);
		} else if (this.syncEvents[0].master_time !== 0) {
			// For video players only. When there are no
			// sync events at master time zero, the start
			// event should always be a pause action.
			if (!this.player.isDocumentPlayer) {
				dummySyncEvent.setAction(SyncEvent.ACTION.PAUSE);
			}
			this.syncEvents.unshift(dummySyncEvent);
		}
	};

	/**
	 * Returns list of sync events
	 *
	 * @param includeDummy
	 * @returns {Array}
	 */
	Track.prototype.getSyncEvents = function (includeDummy) {
		const vm = this;
		let syncEvents = this.syncEvents;

		if (!includeDummy) {
			syncEvents = syncEvents.filter(function (syncEvent) {
				return !vm.isDummySyncEvent(syncEvent);
			});
		}

		return syncEvents;
	};

	/**
	 * Add sync event
	 *
	 * @param syncEvent Sync event instance
	 * @param suppress Suppress event broadcast
	 */
	Track.prototype.addSyncEvent = function (syncEvent, suppress) {
		if (!(syncEvent instanceof SyncEvent)) {
			throw new Error('Parameter `syncEvent` must be an instance of SyncEvent');
		} else if (SyncEvent.ACTIONS.indexOf(syncEvent.action) === -1) {
			throw new Error('Invalid sync event action');
		} else if (!(syncEvent.time >= 0) || syncEvent.time === null) {
			throw new Error('Time value must be greater than or equal to zero');
		}

		syncEvent.track_number = this.number;
		if (syncEvent.master_time == null) {
			syncEvent.master_time = this.masterTimer.getTime();
		}

		// In order to determine if we should update an existing
		// sync event, we need to compare the master time and action
		// of this newly created sync event to all others. When comparing,
		// we will convert the master time to seconds so that each sync
		// event can take up an entire second. When we find a match, we
		// need to update the existing sync event instead of adding it.
		// The most common case for updating a sync event is when the master
		// timer is paused and a sync event is added that overlaps another.
		// Also, we have had issues in the past where the player will fire
		// multiple events during the same millisecond. This mechanism is
		// thus meant to catch this scenario as well. Since we are using
		// the action paired with the master time as a composite key, there
		// could also be a scenario in which a sync event gets add at the
		// same master time (in seconds) as another but both don't have the
		// same action. This case is handled below after we determine that
		// we are going to add the sync event and not update it. Finally,
		// we also don't allow the dummy sync event to be updated.
		const filteredSyncEvents = this.getSyncEvents().filter(function (item) {
			return syncEvent.action === item.action &&
				Math.floor(syncEvent.master_time / 1000) === Math.floor(item.master_time / 1000) &&
				syncEvent.master_time >= item.master_time;
		});

		// If we find a match, then we just need to update
		// the matching sync event with the new information.
		let eventType = Track.EVENT.SYNC_EVENT_ADDED;
		if (filteredSyncEvents.length) {
			// Update the sync event in the list with the latest info
			const eventToBeUpdated = filteredSyncEvents[filteredSyncEvents.length - 1];
			$log.info(`Current Master Time: ${this.masterTimer.getTime()}`);
			$log.info(`Previous Event: \n ${eventToBeUpdated.getInfo()}`);
			$log.info(`New Event: \n ${syncEvent.getInfo()}`);
			syncEvent = angular.extend(eventToBeUpdated, syncEvent);
			eventType = Track.EVENT.SYNC_EVENT_UPDATED;
		} else {

			// Add the sync event to the list in the correct position
			// by finding the closest one to the sync event's master time.
			// We often get bursts of sync events that occur at the same millisecond.
			// Adjust this sync event's master time by 1 millisecond so that we can
			// still order sync events by master time ascending during playback.
			while (this.getSyncEvents().some((event) => event.master_time === syncEvent.master_time)) {
				syncEvent.master_time++;
			}

			this.syncEvents.splice(this.getClosestSyncEventIndex(syncEvent.master_time) + 1, 0, syncEvent);
		}

		// Broadcast sync event added / updated event
		if (!suppress) {
			this.trigger(eventType, syncEvent);
		}

		$log.info('SYNC EVENT ' + (eventType === Track.EVENT.SYNC_EVENT_UPDATED ? 'UPDATED' : 'ADDED') + '\n' + syncEvent.getInfo());

		// Since a new sync event has been added,
		// run validation to ensure that the track
		// continues to operate correctly.
		this.validate(true);

		// Update track end time
		this.trackEndTime = getMasterTimeTrackShouldEndAt(this);
	};

	/**
	 * Remove sync event
	 *
	 * @param syncEvent Sync event instance
	 * @param suppress Suppress event broadcast
	 */
	Track.prototype.removeSyncEvent = function (syncEvent, suppress) {
		if (!(syncEvent instanceof SyncEvent)) {
			throw new Error('Parameter `syncEvent` must be an instance of SyncEvent');
		}

		// Remove sync event from the list
		const index = this.syncEvents.indexOf(syncEvent);
		if (index >= 0) {
			this.syncEvents.splice(index, 1);
		}

		$log.info('SYNC EVENT REMOVED\n' + syncEvent.getInfo());

		// Broadcast sync event removed event
		if (!suppress) {
			this.trigger(Track.EVENT.SYNC_EVENT_REMOVED, syncEvent);
		}

		// Since a sync event has been removed,
		// run validation to ensure that the track
		// continues to operate correctly.
		this.validate(true);

		// Update track end time
		this.trackEndTime = getMasterTimeTrackShouldEndAt(this);
	};

	/**
	 * Removes all sync events
	 */
	Track.prototype.removeSyncEvents = function () {
		// Remove all sync events from the array
		this.syncEvents.splice(0, this.syncEvents.length);
		// Run a soft validate to ensure that
		// the dummy sync event gets added.
		this.validate();
	};

	/**
	 * Get sync event by index
	 *
	 *  @param index Index of the sync event in the array
	 *  @return {object} sync event
	 */
	Track.prototype.getSyncEventByIndex = function (index) {
		if (!isInteger(index) || index < 0) {
			throw new Error('Index must be an integer greater than or equal to zero');
		}

		let syncEvent = null;
		if (this.syncEvents.length > index) {
			syncEvent = this.syncEvents[index];
		}
		return syncEvent;
	};

	/**
	 * Find sync event closest (less than or equal to) to specified place in time.
	 *
	 * @param time The nearest sync event to this time
	 * @return {int} sync event index ( -1 if not found )
	 */
	Track.prototype.getClosestSyncEventIndex = function (time) {
		let targetIndex = -1,
			i = this.syncEvents.length;
		while (i--) {
			if (this.syncEvents[i].master_time <= time) {
				targetIndex = i;
				break;
			}
		}
		return targetIndex;
	};

	/**
	 * Execute a sync event
	 *
	 * @param syncEvent
	 */
	const executeSyncEvent = function executeSyncEvent (syncEvent) {
		if (this.syncEvents.indexOf(syncEvent) === -1) {
			throw new Error('Cannot execute a sync event that has not already been added to the list');
		}

		$log.info('EXECUTE SYNC EVENT\n' + syncEvent.getInfo());

		// Set the current sync event
		this.currentSyncEvent = syncEvent;

		// Check to see if player is out of sync with master timer
		if (this.isOutOfSync()) {
			this.synchronize();
		} else {
			this.validateAction();
		}

		this.trigger(Track.EVENT.SYNC_EVENT_EXECUTED, syncEvent);
	};

	/**
	 * Ensure that the track is doing what
	 * the current sync event action demands.
	 */
	Track.prototype.validateAction = function () {
		const action = shouldBePaused(this) ? SyncEvent.ACTION.PAUSE : SyncEvent.ACTION.PLAY;
		executePlayerEvent(this.player, action);
	};

	/**
	 * Ensure that sync events are sorted by master time ascending
	 */
	Track.prototype.validateSortOrder = function () {
		this.syncEvents.sort(function (a, b) {
			return a.master_time - b.master_time;
		});
	};

	/**
	 * Ensure that this track is in sync with the
	 * master timer and the current sync event.
	 * A hard validate will force the execution
	 * of the current sync event.
	 *
	 * @param hard
	 */
	const validate = function validate (hard) {
		// Validate sync event sort order
		this.validateSortOrder();
		// Ensure that the dummy sync event is
		// there if it is supposed to be.
		this.validateDummySyncEvent();
		// Only allow the rest of the validations to be performed during
		// playback mode, otherwise, this could lead to sync events being
		// captured that shouldn't be. This function should rarely be called
		// outside of this track. In scenarios where a sync event is updated
		// outside of this track, this function should be used. i.e. when a
		// comment sync event's master time gets updated. The goal is to make
		// the player sync robust enough to know when it needs to validate itself.
		if (this.isMode(Track.MODE.PLAYBACK)) {
			// Find sync event index closest to the master time
			const time = this.masterTimer.getTime(),
				index = this.getClosestSyncEventIndex(time);

			// Offset next sync event index by 1
			// from the closest sync event index
			this.nextSyncEventIndex = index + 1;

			// Update the current sync event
			this.currentSyncEvent = this.getSyncEventByIndex(index);

			// A hard validation involves forcing the
			// player to get back in sync with the with
			// the master timer / current sync event.
			// A soft validation will only ensure that
			// current sync event action is being performed.
			if (!hard) {
				this.validateAction();
			} else {
				$log.info('HARD VALIDATE', this.nextSyncEventIndex, '\n' + this.currentSyncEvent.getInfo());
				const shouldSync = !(this.currentSyncEvent.action === MediaPlayerEvents.PAUSE
					|| (this.player.isPaused() && this.currentSyncEvent.action === MediaPlayerEvents.SEEK));
				if (this.isOutOfSync() && shouldSync) {
					this.synchronize();
				} else {
					this.validateAction();
				}
			}
		}
	};

	/**
	 * Synchronize this track with the master timer.
	 *
	 * If the master timer and player are out-of-sync,
	 * bring the player back in-sync with the master timer.
	 * Synchronizing is only supported in playback mode.
	 */
	Track.prototype.synchronize = function () {
		if (this.isMode(Track.MODE.PLAYBACK) && this.isOutOfSync()) {
			$log.info('SYNCHRONIZE', this.getOutOfSyncTime(), shouldBePaused(this));
			this.player.seek(this.getOutOfSyncTime(), shouldBePaused(this));
		}
	};

	/**
	 * Determines if this track is out of sync
	 * with the master timer.
	 *
	 * @returns {Boolean}
	 */
	Track.prototype.isOutOfSync = function () {
		const difference = this.player.getTime() - this.getOutOfSyncTime(),
			threshold = this.player.isDocumentPlayer ?
				Track.DOCUMENT_OUT_OF_SYNC_THRESHOLD : Track.VIDEO_OUT_OF_SYNC_THRESHOLD;
		return Math.abs(difference) > threshold;
	};

	/**
	 * Returns the amount of time (in milliseconds) by which
	 * this track is out of sync with the master timer.
	 *
	 * @returns {Number}
	 */
	Track.prototype.getOutOfSyncTime = function () {
		let time = this.currentSyncEvent.time;

		if (!this.player.isDocumentPlayer) {
			// Compare the master time to the sync event master time,
			// and add the difference to get the actual time the player should be at.
			time += this.masterTimer.getTime() - this.currentSyncEvent.master_time;
		}

		return time > 0 ? time : 0;
	};

	/**
	 * Resets the properties required for sync event
	 * execution back to their original state.
	 *
	 * When the master timer is complete, meaning it has
	 * reached its duration, this resets everything back
	 * so that sync event execution can begin anew.
	 */
	Track.prototype.reset = function () {
		this.nextSyncEventIndex = 0;
		this.currentSyncEvent = null;
	};

	/**
	 * Whether this track is currently interrupted (buffering)
	 *
	 * @returns {Boolean}
	 */
	Track.prototype.isInterrupted = function () {
		return this.player.isBuffering();
	};

	/**
	 * Destroy track
	 */
	Track.prototype.destroy = function () {
		this.cleanupEvents();
		this.off();
	};

	/**
	 * Returns a summary of the track
	 *
	 * @returns {string}
	 */
	Track.prototype.getInfo = function () {
		let result = '';

		// Log playlist item
		result += 'Provider: ' + this.player.getProvider().name;
		result += '\n';

		// Log duration
		if (this.player.isDocumentPlayer) {
			result += 'Pages: ' + this.player.getDuration();
		} else {
			result += 'Duration: ' + Time.formatTime(this.player.getDuration());
		}
		result += '\n';

		// Log file url
		const playlistItem = this.player.getPlaylistItem();
		if (angular.isObject(playlistItem)) {
			result += 'Url: ' + playlistItem.file;
			result += '\n';
		}

		// Log sync events
		result += 'Sync Events: ' + this.syncEvents.length;
		if (this.syncEvents.length) {
			result += '\n';
			angular.forEach(this.syncEvents, function (syncEvent, index) {
				result += '\n';
				result += 'Index: ' + index;
				result += '\n';
				result += syncEvent.getInfo();
				result += '\n';
			});
		}

		return result;
	};

	/**
	 * Whether player should be paused based on a given sync event.
	 * If no current sync event is empty, then it should be paused
	 *
	 * @param track
	 * @returns {boolean}
	 */
	function shouldBePaused (track) {
		return track.currentSyncEvent.action === SyncEvent.ACTION.PAUSE ||
			track.masterTimer.isPaused() ||
			hasTrackReachedEnd(track);
	}

	function getMasterTimeTrackShouldEndAt (track) {
		// A stimulus shouldn't always end when the master time
		// reaches the player duration of the stimulus.  In cases
		// where a recorder was pausing/playing/seeking the stimulus, it should
		// actually be later.  Here we determine how much later by looking
		// at the last event and what state it was in when it got there.

		const wasPlayingOnLastEvent = track.syncEvents.reduce((playing, nextEvent) => {
			if (playing) {
				return playing && nextEvent.action !== SyncEvent.ACTION.PAUSE;
			} else {
				return nextEvent.action === SyncEvent.ACTION.PLAY;
			}
		}, true);

		// Look at last event, and see how much longer a stimulus could play
		const lastEvent = track.syncEvents[track.syncEvents.length - 1];

		// If no events, assume dummy event and end at duration
		if (!lastEvent) {
			return track.player.getDuration();
		}

		// If last event was in paused state, ending time is last pauses master time
		if (!wasPlayingOnLastEvent) {
			return lastEvent.master_time;
		}

		// Otherwise end with playback continuing from last event til end of player
		return (track.player.getDuration() - lastEvent.time) + lastEvent.master_time;
	}

	function hasTrackReachedEnd (track) {
		if (track.player.isDocumentPlayer) {
			return false;
		}

		return track.masterTimer.getTime() > track.trackEndTime;
	}

	/**
	 * Only execute play / pause events and treat a seek like a play event.
	 * The execute sync event function will handle seeking
	 *
	 * @param player
	 * @param eventType
	 */
	function executePlayerEvent (player, eventType) {
		switch (eventType) {
			case SyncEvent.ACTION.PAUSE:
				if (player.isPlaying()) {
					player.pause();
				}
				break;
			case SyncEvent.ACTION.PLAY:
			case SyncEvent.ACTION.SEEK:
				if (player.isPaused()) {
					player.play();
				}
				break;
		}
	}

	/**
	 * Internal add sync event (action i.e. play, pause, seek)
	 *
	 * @param track
	 * @param action
	 * @param time
	 * @param reason
	 */
	function addSyncEvent (track, action, time, reason) {
		// Only add sync events when we are in capture mode. When
		// the master timer is paused, we have to be in asynchronous
		// capture mode to allow sync events to be added.
		if (!track.isCaptureMode()) {
			return false;
		} else if (track.masterTimer.isPaused() &&
			!track.isMode(Track.MODE.ASYNCHRONOUS_CAPTURE)) {
			return false;
		}

		track.addSyncEvent(SyncEvent.model({
			track_number: track.number,
			action,
			time,
			master_time: track.masterTimer.getTime(),
			reason: reason || ''
		}));
	}

	/**
	 * Whether value is an integer
	 *
	 * @param value
	 * @returns {boolean}
	 */
	function isInteger (value) {
		return value !== null &&
			!angular.isString(value) &&
			value % 1 === 0;
	}

	/**
	 * Initializes the event handlers for capture mode
	 * and returns a destroy function.
	 *
	 * @param track
	 * @returns {Function}
	 */
	function initCaptureModeEventHandlers (track) {
		if (track.player.isDocumentPlayer) {
			return initCaptureModeEventHandlersForDocumentPlayer(track);
		}
		return initCaptureModeEventHandlersForVideoPlayer(track);
	}

	/**
	 * Initializes the event handlers for capture mode
	 * for the video player and returns a destroy function.
	 *
	 * @param track
	 * @returns {Function}
	 */
	function initCaptureModeEventHandlersForVideoPlayer (track) {
		let timeAfterPause = track.player.getTime(),
			wasPlayingBeforePause, lastSyncOffset, wasPlayingLast;

		// Add event listeners. Note, we are using the custom
		// play and pause listeners because they are guaranteed
		// to fire right when the request is made. Then we don't
		// record all the play and pause events that happen
		// i.e. after a seek has occurred, the video may start
		// buffering and then a play event will be triggered.
		// Normally when this occurs, we end up getting a seek
		// event followed by a buffer followed by a play.
		track.masterTimer.on(Timer.EVENT.START, onMasterTimerStart);
		track.masterTimer.on(Timer.EVENT.PAUSE, onMasterTimerPause);
		track.player.on(MediaPlayerEvents.REQUEST_SEEK, onSeek);
		track.player.on(MediaPlayerEvents.SEEK, onSeeked);
		track.player.on(MediaPlayerEvents.BUFFER, onBuffer);
		track.player.on(MediaPlayerEvents.PLAY, onPlay);
		track.player.on(MediaPlayerEvents.PAUSE, onPause);

		/**
		 * When the master timer starts, sync events
		 * need to be added as described below.
		 */
		function onMasterTimerStart () {
			$log.info('MASTER TIMER', 'started');
			// If the player is in the complete state, meaning the
			// current time is equal to the video duration,
			// we need to seek back to the beginning.
			if (track.player.isComplete()) {
				// This seek will cause a sync event to get added.
				track.player.seek(0);
			} else if (Math.floor(timeAfterPause / 1000) !== Math.floor(track.player.getTime() / 1000)) {
				// If there is a time difference before the last
				// pause and now. This means that the user has
				// fiddled with the time while in the paused state.
				addSyncEvent(track, SyncEvent.ACTION.SEEK, track.player.getTime(), 'onMasterTimerStart');
				// If the player is in a paused state, we need to capture the pause
				// as well. We don't have an event that represents seek and pause.
				if (track.player.isPaused()) {
					addSyncEvent(track, SyncEvent.ACTION.PAUSE, track.player.getTime(), 'onMasterTimerStart');
				}
			} else if (track.player.isPlaying()) {
				// If player is currently playing.
				// This means that the user intentionally started to play the
				// video again after the master timer paused because we pause
				// the video for them when the the master timer pauses.
				addSyncEvent(track, SyncEvent.ACTION.PLAY, track.player.getTime(), 'onMasterTimerStart');
			} else if (angular.isUndefined(wasPlayingBeforePause) || wasPlayingBeforePause) {
				// If player was playing before master timer pause, continue playing.
				executePlayerEvent(track.player, SyncEvent.ACTION.PLAY);
			}
		}

		/**
		 * When the master timer pauses,
		 * all playback should be paused as well.
		 */
		function onMasterTimerPause () {
			$log.info('MASTER TIMER', 'paused');
			timeAfterPause = track.player.getTime();
			wasPlayingBeforePause = track.player.isPlaying();
			executePlayerEvent(track.player, SyncEvent.ACTION.PAUSE);
		}

		/**
		 * Handles player buffer events
		 */
		function onBuffer () {
			addSyncEvent(track, SyncEvent.ACTION.PAUSE, track.player.getTime(), 'onBuffer');
		}

		function onPlay () {
			wasPlayingLast = true;
			// May end up with too many of these, but thats ok
			addSyncEvent(track, SyncEvent.ACTION.PLAY, track.player.getTime(), 'onPlay');
		}

		/**
		 * Pause event handler.
		 * A pause will equal a pause during playback.
		 */
		function onPause () {
			wasPlayingLast = false;
			addSyncEvent(track, SyncEvent.ACTION.PAUSE, track.player.getTime(), 'onPause');
		}

		/**
		 * Seek event handler.
		 * A seek will equal a seek during playback.
		 */
		function onSeek (event) {
			lastSyncOffset = event.offset;
			addSyncEvent(track, SyncEvent.ACTION.SEEK, lastSyncOffset, 'onSeek');
			if (wasPlayingLast) {
				addSyncEvent(track, SyncEvent.ACTION.PAUSE, lastSyncOffset + 1, 'onSeek');
			}
		}

		function onSeeked (_event) {
			if (wasPlayingLast) {
				addSyncEvent(track, SyncEvent.ACTION.PLAY, lastSyncOffset + 1, 'onPlay');
			}
		}

		return function () {
			track.masterTimer.off(Timer.EVENT.START, onMasterTimerStart);
			track.masterTimer.off(Timer.EVENT.PAUSE, onMasterTimerPause);
			track.player.off(MediaPlayerEvents.REQUEST_SEEK, onSeek);
			track.player.off(MediaPlayerEvents.SEEK, onSeeked);
			track.player.off(MediaPlayerEvents.BUFFER, onBuffer);
			track.player.off(MediaPlayerEvents.PLAY, onPlay);
			track.player.off(MediaPlayerEvents.PAUSE, onPause);
		};
	}

	/**
	 * Initializes the event handlers for capture mode
	 * for the document player and returns a destroy function.
	 *
	 * @param track
	 * @returns {Function}
	 */
	function initCaptureModeEventHandlersForDocumentPlayer (track) {
		let pageNumberAfterPause = track.player.getTime();

		// Add seek event listener
		track.masterTimer.on(Timer.EVENT.START, onMasterTimerStart);
		track.masterTimer.on(Timer.EVENT.PAUSE, onMasterTimerPause);
		track.player.on(MediaPlayerEvents.REQUEST_SEEK, onSeek);

		/**
		 * If the page number changes while the master
		 * timer is paused, we need to capture the event.
		 */
		function onMasterTimerStart () {
			$log.info('MASTER TIMER', 'started');
			// Don't capture this event in asynchronous mode; we allow
			// events to be captured in asynchronous mode when the master
			// timer is paused. See the internal `addSyncEvent` function.
			if (!track.isMode(Track.MODE.ASYNCHRONOUS_CAPTURE) &&
				pageNumberAfterPause !== track.player.getTime()) {
				addSyncEvent(track, SyncEvent.ACTION.SEEK, track.player.getTime(), 'onMasterTimerStart');
			}
		}

		/**
		 * When the master timer pauses, record
		 * the page number that we were on.
		 */
		function onMasterTimerPause () {
			$log.info('MASTER TIMER', 'paused');
			pageNumberAfterPause = track.player.getTime();
		}

		/**
		 * Seek event handler.
		 * A seek will equal a seek during playback.
		 */
		function onSeek (event) {
			addSyncEvent(track, SyncEvent.ACTION.SEEK, event.offset, 'onSeek');
		}

		return function () {
			track.masterTimer.off(Timer.EVENT.START, onMasterTimerStart);
			track.masterTimer.off(Timer.EVENT.PAUSE, onMasterTimerPause);
			track.player.off(MediaPlayerEvents.REQUEST_SEEK, onSeek);
		};
	}

	/**
	 * Initializes the event handlers for playback mode
	 * and returns a destroy function.
	 *
	 * @param track
	 * @returns {Function}
	 */
	function initPlaybackModeEventHandlers (track) {
		// Add master timer event handlers
		track.masterTimer.on(Timer.EVENT.START, onMasterTimerStart);
		track.masterTimer.on(Timer.EVENT.PAUSE, onMasterTimerPause);
		track.masterTimer.on(Timer.EVENT.TIME, onMasterTimerTick);
		track.masterTimer.on(Timer.EVENT.TIME_UPDATE, onMasterTimerUpdate);
		track.masterTimer.on(Timer.EVENT.COMPLETE, onMasterTimerComplete);

		// Add player event handlers
		track.player.on(MediaPlayerEvents.BUFFER, onBuffer);
		track.player.on(MediaPlayerEvents.PLAY, onPlay);
		track.player.on(MediaPlayerEvents.PAUSE, onPause);
		track.player.on(MediaPlayerEvents.COMPLETE, onComplete);

		/**
		 * When the master timer starts,
		 * playback should resume as usual.
		 */
		function onMasterTimerStart () {
			$log.info('MASTER TIMER', 'started');
			track.validate();
		}

		/**
		 * When the master timer pauses,
		 * all playback should be paused as well.
		 */
		function onMasterTimerPause (_time, wasBuffering) {
			$log.info('MASTER TIMER', 'paused');
			// Normal timers don't have a buffering state, but if it is a player
			// posing as a timer, then we have to account for the scenario where
			// the master timer (player) was in a buffer state before the pause occurred.
			if (wasBuffering) {
				track.trigger(Track.EVENT.INTERRUPT_COMPLETE, track);
			} else {
				executePlayerEvent(track.player, SyncEvent.ACTION.PAUSE);
			}
		}

		/**
		 * Runs the track validator as well as
		 * sync events when the master time ticks.
		 *
		 * @param time
		 */
		function onMasterTimerTick (time) {
			// Ensure that the track is performing as expected.
			// ups nextSyncEventIndex by 1 if the next track is
			// closer to master time
			track.validate();

			// Won't execute the next sync event unless
			// it's master time is less than the current master time.
			const nextSyncEvent = track.getSyncEventByIndex(track.nextSyncEventIndex);
			if (nextSyncEvent && nextSyncEvent.master_time <= time) {
				track.executeSyncEvent(nextSyncEvent);
			}
		}

		/**
		 * When the master timer gets updated,
		 * find the closest sync event and update
		 * the current sync event index.
		 */
		function onMasterTimerUpdate (time) {
			$log.info('MASTER TIMER', 'updated', Time.formatTime(time), track.nextSyncEventIndex, track.currentSyncEvent);
			track.validate(true);
		}

		/**
		 * When the master timer completes,
		 * reset playback to original state.
		 */
		function onMasterTimerComplete () {
			$log.info('MASTER TIMER', 'completed');

			// Ensure player is paused
			executePlayerEvent(track.player, SyncEvent.ACTION.PAUSE);

			// Reset sync index / event
			track.reset();
		}

		/**
		 * Handles player buffer events
		 */
		function onBuffer () {
			$log.info('BUFFER');
			track.trigger(Track.EVENT.INTERRUPT, track);
		}

		/**
		 * Handles play events
		 *
		 * @param event
		 */
		function onPlay (event) {
			$log.info('PLAY', event.oldstate);
			if (event.oldstate === MediaPlayerStates.BUFFERING) {
				track.trigger(Track.EVENT.INTERRUPT_COMPLETE, track);
			}
		}

		/**
		 * Handles player pause events
		 *
		 * @param event
		 */
		function onPause (event) {
			$log.info('PAUSE', event.oldstate);
			if (event.oldstate === MediaPlayerStates.BUFFERING) {
				track.trigger(Track.EVENT.INTERRUPT_COMPLETE, track);
			}
		}

		/**
		 * Handle player complete events
		 */
		function onComplete () {
			$log.info('COMPLETE');
			executePlayerEvent(track.player, SyncEvent.ACTION.PAUSE);
		}

		return function () {
			track.masterTimer.off(Timer.EVENT.START, onMasterTimerStart);
			track.masterTimer.off(Timer.EVENT.PAUSE, onMasterTimerPause);
			track.masterTimer.off(Timer.EVENT.TIME, onMasterTimerTick);
			track.masterTimer.off(Timer.EVENT.TIME_UPDATE, onMasterTimerUpdate);
			track.masterTimer.off(Timer.EVENT.COMPLETE, onMasterTimerComplete);
			track.player.off(MediaPlayerEvents.BUFFER, onBuffer);
			track.player.off(MediaPlayerEvents.PLAY, onPlay);
			track.player.off(MediaPlayerEvents.PAUSE, onPause);
			track.player.off(MediaPlayerEvents.COMPLETE, onComplete);
		};
	}

	return Track;
};
