import * as angular from 'angular';
import * as angularResource from 'angular-resource';

import { MediaModel } from './media.factory';
import { mediaServiceTransformer } from './media-transformer.service';
import { SupplementaryMedia } from './supplementary-media/supplementary-media.factory';
import { MediaVTTFactory } from './media-vtt/media-vtt.factory';
import opentokSessionModule from '../opentok-session';
import { mediaModelInterceptorService } from './media-interceptor.service';
import { messageModalModule } from 'go-modules/modals/message';
import type { CreateUpdateMediaParameters as ExportCreateUpdateMediaParameters } from './create-update-media-parameters.interface';
export type CreateUpdateMediaParameters = ExportCreateUpdateMediaParameters;
import type { MediaSchema as ExportMediaSchema } from './media-schema.interface';
export type MediaSchema = ExportMediaSchema;
import type { MediaFactory as ExportMediaFactory } from './media-factory.interface';
export type MediaFactory = ExportMediaFactory;
export { MediaSource } from './media-source.enum';
import type { MediaResource as ExportMediaResource } from './resource.interface';
export type MediaResource = ExportMediaResource;

import type { HasMedia as ExportHasMedia } from './has-media';
export type HasMedia = ExportHasMedia;
import type { StreamingConnectionParameters as ExportStreamingConnectionParameters } from './media-streaming';
export type StreamingConnectionParameters = ExportStreamingConnectionParameters;

export const mediaModel = angular.module('go.models.media', [
	angularResource,
	opentokSessionModule,
	messageModalModule
])
	.service('mediaServiceTransformer', mediaServiceTransformer)
	.service('mediaModelInterceptor', mediaModelInterceptorService)
	.factory('MediaModel', MediaModel)
	.factory('SupplementaryMedia', SupplementaryMedia)
	.factory('MediaVTT', MediaVTTFactory)
	.name;
