import * as angular from 'angular';
import { goModalBootstrapModule } from '../go-modal-bootstrap.factory';
import * as angularTranslate from 'angular-translate';

import { commonFiltersModule } from '../../common-filters';

import { equipmentCheckModal } from './modal.factory';
import { helpUrlsModule } from '../../help-urls';

import { activityModel } from '../../models/activity';
import { sessionModel } from '../../models/session';
import { groupDepModel } from '../../models/group-dep';
import { userModel } from '../../models/user';
import { mediaModel } from '../../models/media';
import { videoSceneModule } from '../../video-scene';


import './modal.less';

export const equipmentCheckModalModule = angular.module('modal.equipment-check', [
	goModalBootstrapModule,
	angularTranslate,
	commonFiltersModule,
	helpUrlsModule,
	activityModel,
	sessionModel,
	groupDepModel,
	userModel,
	mediaModel,
	videoSceneModule
])
	.factory(equipmentCheckModal.NG1_INJECTION_NAME, equipmentCheckModal)
	.name;
