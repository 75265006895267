import { CONTENT_TYPES, MODES } from 'ngx/go-modules/src/components/library/library-collections-viewer/library-collection-viewer.constants';

export class LibraryCollectionViewerModalController implements ng.IOnInit {

	public filterType: CONTENT_TYPES[];
	public mode: string;
	public previewable: boolean;
	public collectionManagement: boolean = true;
	public $close: (result?: any) => boolean;
	public $dismiss: (result?: any) => boolean;

	/** @ngInject */
	constructor (private $translate: angular.translate.ITranslateService) {}

	public $onInit () {

	}

	public selectItem (item) {
		this.$close(item);
	}

	public cancel () {
		this.$dismiss();
	}

	public modalTitleValue () {
		if (this.mode && this.mode === MODES.SELECT) {
			const typeOrTypes = this.filterType.length > 1 ? this.filterType.join(' or ') : this.filterType[0];
			const assignment = this.$translate.instant('library-collections_add-activity');
			return this.$translate.instant('library-collection-viewer_select-mode-title', { type: typeOrTypes.replace('activity', assignment) });
		} else {
			return this.$translate.instant('common_library');
		}
	}
}
