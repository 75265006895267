import { noop } from 'angular';

export interface MediaThumbnailOptions {
	groupId?: string | number;
	actions?: boolean;
	preventPreview?: boolean;
	preventEdit?: boolean;
	preventDelete?: boolean;
	preventDownload?: boolean;
	hideDuration?: boolean;
	highlightDuration?: boolean;
	hideTitle?: boolean;
}

interface Bindings {
	title?: string;
	media: any;
	options: MediaThumbnailOptions;
	onPreview: () => void;
	onPreviewDone: () => void;
	onDownload: () => void;
	onEdit: () => void;
	onEditDone: () => void;
	onDelete: () => void;
}

export class MediaThumbnailController implements Bindings {

	public isActionFocused: boolean;
	public isImageLoaded: boolean;

	// Bindings
	public title?: string;
	public media: any;
	public options: MediaThumbnailOptions;
	public onPreview: () => void;
	public onPreviewDone: () => void;
	public onDownload: () => void;
	public onEdit: () => void;
	public onEditDone: () => void;
	public onDelete: () => void;

	/* @ngInject */
	constructor (
		private $attrs: ng.IAttributes,
		private mediaPreviewModal: any,
		private MediaModel: any,
		private translateFilter: ng.translate.ITranslateFilter
	) {}

	/**
	 * Handles init life cycle hook
	 */
	public $onInit () {
		this.isActionFocused = false;
		this.isImageLoaded = false;
		if (!(this.media instanceof this.MediaModel)) {
			throw new Error('Parameter `media` is required by MediaThumbnailController');
		}
	}

	/**
	 * Get the thumbnail title
	 *
	 * @returns {string}
	 */
	public getTitle () {
		return this.title || this.media.title || this.media.filename_original || this.translateFilter('common_untitled');
	}

	/**
	 * Get the aria label for the main preview button
	 *
	 * @returns {string}
	 */
	public getAriaLabel () {
		const isVideo = this.media.isVideo();
		const isAudio = this.media.isAudio();
		const translationKey = isVideo ?
			'media-thumbnail_aria-label-play-video' : isAudio ?
				'media-thumbnail_aria-label-play-audio' :
				'media-thumbnail_aria-label-open';
		return this.translateFilter(translationKey, { title: this.getTitle() });
	}

	/**
	 * Whether actions are enabled
	 *
	 * @returns {boolean}
	 */
	public isActionsEnabled () {
		return this.options.actions &&
			(this.isPreviewEnabled() || this.isEditEnabled() || this.isDeleteEnabled());
	}

	/**
	 * Whether preview is enabled
	 *
	 * @returns {boolean}
	 */
	public isPreviewEnabled () {
		return !this.options.preventPreview && this.media.isReady();
	}

	/**
	 * Whether edit is enabled
	 *
	 * @returns {boolean}
	 */
	public isEditEnabled () {
		return !this.options.preventEdit && this.media.isReady();
	}

	/**
	 * Whether delete is enabled
	 *
	 * @returns {boolean}
	 */
	public isDeleteEnabled () {
		return this.$attrs.onDelete != null && !this.options.preventDelete;
	}

	/**
	 * Handle preview event
	 */
	public handlePreview () {
		if (this.$attrs.onPreview) {
			this.onPreview();
		} else {
			this.openMediaPreviewModal(false)
				.catch(noop)
				.finally(() => {
					this.onPreviewDone();
				});
		}
	}

	/**
	 * Handles main preview click event
	 */
	public onMainPreviewClick () {
		if (this.isActionFocused || !this.isPreviewEnabled()) {
			return;
		}

		this.handlePreview();
	}

	/**
	 * Handles preview click event
	 *
	 * @param event
	 */
	public onPreviewClick (event: Event) {
		event.stopPropagation();
		if (!this.isPreviewEnabled()) {
			return;
		}
		this.handlePreview();
	};

	/**
	 * Handles edit click event
	 *
	 * @param event
	 */
	public onEditClick (event: Event) {
		event.stopPropagation();
		if (this.$attrs.onEdit) {
			this.onEdit();
		} else {
			this.openMediaPreviewModal(true)
				.finally(() => {
					this.onEditDone();
				});
		}
	}

	/**
	 * Handles delete click event
	 *
	 * @param event
	 */
	public onDeleteClick (event: Event) {
		event.stopPropagation();
		if (this.$attrs.onDelete) {
			this.onDelete();
		}
	};

	/**
	 * Handles action focus event
	 */
	public onActionFocus () {
		this.isActionFocused = true;
	}

	/**
	 * Handles action blur event
	 */
	public onActionBlur () {
		this.isActionFocused = false;
	}

	/**
	 * Preview media
	 *
	 * @param edit
	 */
	public openMediaPreviewModal (edit: boolean) {
		const modalData = {
			media: this.media,
			edit,
			allowDownload: !this.options.preventDownload,
			onDownload: this.onDownload.bind(this),
			groupId: this.options.groupId
		};

		return this.mediaPreviewModal.open({
			modalData
		}).result;
	}

	/**
	 * Whether the duration label should be displayed
	 */
	public shouldDisplayDurationLabel (): boolean {
		return this.media.duration > 0 && !this.options.hideDuration;
	}
}
