import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { GoMaterialModule } from 'ngx/go-modules/src/go-material.module';
import { EditTranscriptionSpeakersDialogComponent } from './edit-transcription-speakers-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusOnFirstInvalidFieldModule } from 'ngx/go-modules/src/directives/focus-on-first-invalid-field';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		GoMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		FocusOnFirstInvalidFieldModule,
		A11yModule
	],
	declarations: [
		EditTranscriptionSpeakersDialogComponent
	],
	entryComponents: [
		EditTranscriptionSpeakersDialogComponent
	],
	exports: [
		EditTranscriptionSpeakersDialogComponent
	],
	providers: [
		TranslatePipe
	]
})
export class EditTranscriptionSpeakersDialogModule {}
