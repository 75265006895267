import template from './feedback-session.component.html';
import { FeedbackSessionController as controller, FeedbackSessionControllerScope, FeedbackSessionControllerClass } from './feedback-session.controller';

export interface FeedbackSessionComponentScope extends FeedbackSessionControllerScope {
	mainController: FeedbackSessionControllerClass;
}

export default {
	controller,
	controllerAs: 'mainController',
	bindings: {
		feedbackMode: '<',
		viewMode: '<',
		user: '<',
		userGroup: '<',
		license: '<',
		activity: '<',
		session: '=',
		options: '<',
		orgSettings: '<?',
		viewFrom: '<?'
	},
	template,
	transclude: {
		header: '?feedbackSessionHeader'
	}
} as ng.IComponentOptions;
