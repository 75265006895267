import * as angular from 'angular';
import * as ngAria from 'angular-aria';
import * as ngAnimate from 'angular-animate';
import * as angularMaterial from 'angular-material';
import { ariaNgClickDecorator } from './aria-ng-click.decorator';
import { ariaNgSortableDecorator } from './aria-ng-sortable.decorator';
import { ariaChipsDecorator } from './aria-label-chips.decorator';

export const ariaDecoratorModule = angular
	.module('aria-decorator', [ngAnimate, ngAria, 'ng-sortable', angularMaterial])
	.decorator(...ariaNgClickDecorator)
	.decorator(...ariaNgSortableDecorator)
	.decorator(...ariaChipsDecorator)
	.name;
