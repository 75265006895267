import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';
import { SelectedService } from './selected.service';
import { goPageModule } from 'go-modules/go-page';
import { goAppEnvModule } from 'go-modules/go-app-env';
import { goLocalizeHelperModule } from 'go-modules/translation-helper';
import { groupDepModel } from 'go-modules/models/group-dep';
import { activityModel } from 'go-modules/models/activity';
import { sessionModel } from 'go-modules/models/session';
import { goBannerModule } from 'go-modules/go-banner';

export const SelectedModule = angular.module('SelectedModule', [
	goPageModule,
	angularTranslate,
	goAppEnvModule,
	goBannerModule,
	goLocalizeHelperModule,
	groupDepModel,
	activityModel,
	sessionModel
])
	.service(SelectedService.NG1_INJECTION_NAME, SelectedService)
	.name;
