import * as angular from 'angular';

/* @ngInject */
export function labelInputBehavior ($document, $timeout) {

	/**
	 * Places the cursor after the last character
	 *
	 * @param element
	 */
	function putCursorAtEnd (element) {

		element.focus();

		// If this function exists...
		if (element.setSelectionRange) {
			// ... then use it (Doesn't work in IE)

			// Double the length because Opera is inconsistent about whether a
			// carriage return is one character or two. Sigh.
			const len = (angular.element(element).val() as string).length * 2;

			try {
				element.setSelectionRange(len, len);
			} catch (error) { }

		} else {
			// ... otherwise replace the contents with itself
			// (Doesn't work in Google Chrome)
			angular.element(element).val(angular.element(element).val());
		}

		// Scroll to the bottom, in case we're in a tall textarea
		// (Necessary for Firefox and Google Chrome)
		element.scrollTop = 999999;
	}

	return {
		restrict: 'A',
		require: ['^labelInput', '^labelInputEditor'],
		link: function link (scope, element, _attrs, controllers) {
			const labelInputController = controllers[0];

			// Immediately focus on the element
			// and place cursor at the end of the text.
			// If this isn't called before the `blur`
			// event is registered, some versions of
			// firefox will immediately trigger a `blur`
			// event which causes havoc.
			putCursorAtEnd(element[0]);

			// Select all of the text if
			// the select all option is on.
			if (labelInputController.selectAll) {
				element.select();
			}

			/**
			 * Trigger input change event
			 */
			const triggerEvent = function (type) {
				const event = $document[0].createEvent('Event');
				event.initEvent(type, true, false);// can bubble, and is not cancellable
				element[0].dispatchEvent(event);
			};

			/**
			 * Handle blur events
			 */
			element.on('change', function (evt) {
				// Prevent `change` event propagation while editing.
				if (labelInputController.editing) {
					evt.stopPropagation();
				}
			});

			/**
			 * Handle blur events
			 */
			element.on('blur', function () {
				// Delaying the edit mode toggle allows the input's `change`
				// event to fire before it gets removed from the DOM.
				$timeout(function () {
					const wasDirty = labelInputController.isDirty();
					labelInputController.edit(false);
					if (wasDirty) {
						triggerEvent('change');
					}
				});
			});

			// emit focus/blur events custom to allow for propagation to label-wrapper
			element.on('blur', function () {
				triggerEvent('label-input:blur');
			});
			triggerEvent('label-input:focus');

			/**
			 * Handle key down events
			 *
			 * @param evt
			 */
			element.on('keydown', function onKeyDown (evt) {
				// Delaying the edit mode toggle allows the input's `change`
				// event to fire before it gets removed from the DOM.
				if (evt.keyCode === 13) { // ENTER
					evt.preventDefault();
					evt.target.blur();
				} else if (evt.keyCode === 27) { // ESC
					labelInputController.revert();
					evt.target.blur();
				}
			});

			/**
			 * Handles any clean up after scope destroy event
			 */
			scope.$on('$destroy', function () {
				element.off();
			});
		}
	};
}
