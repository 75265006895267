import * as angular from 'angular';
import * as angularTranslate from 'angular-translate';

import toolbarComponent from './toolbar.component';
import toolbarButtonComponent from './button/button.component';


import './style.less';

export const toolbarModule = angular.module('go.toolbar', [
	angularTranslate
])
	.component('toolbar', toolbarComponent)
	.component('toolbarButton', toolbarButtonComponent)
	.name;
